import React, {useMemo} from 'react';
import SyCheckbox from "../Fields/SyCheckbox";
import SyText from "../Fields/SyText";
import SySelect from "../Fields/SySelect";
import SyNumberInteger from "../Fields/SyNumberInteger";
import SyTextArea from "../Fields/SyTextArea";
import SyDate from "../Fields/SyDate";
import SyDateTime from "../Fields/SyDateTime";
import SyTime from "../Fields/SyTime";
import SyDecimal from "../Fields/SyDecimal";
import SyEmail from "../Fields/SyEmail";
import SyImage from "../Fields/SyImage";
import SyRadio from "../Fields/SyRadio";

const InputType = ({ children }) => {};

const Text = (props) => useMemo(() => <SyText data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo])
InputType.Text = Text;

const Select = (props) => useMemo(() => <SySelect data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]) ;
InputType.Select = Select;

const Radio = (props) => useMemo(() => <SyRadio data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]) ;
InputType.Radio = Radio;


const Checkbox = (props) => useMemo(() =>  <SyCheckbox data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]);
InputType.Checkbox = Checkbox;

const NumberInteger = (props) => useMemo(() => <SyNumberInteger data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]);
InputType.NumberInteger = NumberInteger;

const TextArea = (props) => useMemo(() => <SyTextArea data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]);
InputType.TextArea = TextArea;

const Date = (props) => useMemo(() => <SyDate data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]);
InputType.Date = Date;

const DateTime = (props) => useMemo(() => <SyDateTime data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]);
InputType.DateTime = DateTime;

const Time = (props) => useMemo(() => <SyTime data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]);
InputType.Time = Time;

const Decimal = (props) => useMemo(() => <SyDecimal data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]);
InputType.Decimal = Decimal;

const Email = (props) => useMemo(() => <SyEmail data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]);
InputType.Email = Email;

const Image = (props) => useMemo(() => <SyImage data={props.propertyField} value={props.updatedValueRunMemo} setValue={props.setValue}/>,[props.updatedValueRunMemo]);
InputType.Image = Image;

export default InputType;
