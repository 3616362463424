import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Col, Icon, List, message, Row, Skeleton, Spin} from "antd";

import moment from "moment";

import {
    FaCalendar,
    FaCalendarCheck,
    FaClipboardCheck,
    FaClipboardList,
    FaUserAltSlash,
    FaUserCheck,
    FaUsers
} from "react-icons/fa";
import Loading from "../../../Components/Generic/Loading";
import {Images} from "../../../Helpers/Images";
import useApiClub from "../../../Hooks/ApiClub";

Spin.setDefaultIndicator(<Loading/>);

const ExternalVacancyListComponent = (props) => {

    const nextPageRef = useRef(props.nextPage);
    const [loading, setLoading] = useState(false);
    const api = useApiClub();

    const infiniteScroll = () => {
        let bodyAux = document.getElementById(`vacancyScroll`);
        if(nextPageRef.current) {
            if (bodyAux.scrollTop + bodyAux.clientHeight >= bodyAux.scrollHeight) {
                props.loadMore();
            }
        }
    }

    const handleAddTime = (item, index) => {
        setLoading(true)
        message.loading(`Adiando vaga`)
        api.put(`formal-working/job-opportunity/${item.id}/extend-date`, {extend_days: 15},(res) => {
            let aux = props.vacancies;
            aux[index].end_date = moment(item.end_date, "YYYY-MM-DD").add(15, 'days').format("YYYY-MM-DD");
            props.setVacancyList(aux);
            setLoading(false);
            message.destroy();
            message.success(`Vaga Adiada`)
        },(e) => {
            message.error(e.message || 'Ops, não foi possível adiar a data de publicação')
            setLoading(false);
            console.log(e)
        });
    };

    useEffect(() => {
        nextPageRef.current = props.nextPage;
    },[props.nextPage])

    useEffect(() => {
        if(document.getElementById(`vacancyScroll`)) document.getElementById(`vacancyScroll`).addEventListener('scroll', infiniteScroll)
        return () => {
            if(document.getElementById(`vacancyScroll`)) document.getElementById(`vacancyScroll`).removeEventListener(`scroll`, infiniteScroll);
        }
    },[])

    return (
        <div  className={'mainComponentPanel'} style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 0, borderRadius:5, backgroundColor: "white"}}>
            <Row>
                <Col xs={24} style={{display: "flex" ,padding: 10, borderBottom: "1px solid gainsboro", alignItems: "center"}}>
                    {props.ended ?
                        <FaClipboardCheck type={"file-done"} style={{marginRight: 8, fontSize: 18, marginTop: -4}} className={"secondary-color"}/>
                        :
                        <FaClipboardList style={{marginRight: 8, fontSize: 18, marginTop: -4}} className={"secondary-color"}/>
                    }
                    <span style={{fontSize: 18}}>{props.ended? 'Suas divulgações encerradas' : 'Suas divulgações em andamento'}</span>
                </Col>

                <Col xs={24} style={{padding: 5, minHeight: 500, maxHeight: window.innerHeight - 170, overflowY: `scroll`}} id={`vacancyScroll`}>
                    {(props.vacancies?.length > 0) &&<List
                        className='listAnt'
                        style={{overflow: 'hidden'}}
                        itemLayout="horizontal"
                        dataSource={props.vacancies}
                        loading={props.loading}
                        renderItem={(item, index) => (
                            <List.Item  style={{position: 'relative',  padding: 0, backgroundColor: "white"}}>
                                <Skeleton avatar title={false} loading={false} active>
                                    <div style={{width: "100%"}}>
                                        <div onClick={() => props.handleVacancySelect(item)} style={{display: "flex", cursor: 'pointer', padding: 15, alignItems: `center`}}>
                                            <Avatar style={{width: 60, height: 60}} size='default' src={props.companyLogo? `https://crafty-api.s3.amazonaws.com/public/logos/${props.companyLogo}` : Images.user_place_holder}> </Avatar>
                                            <div style={{marginLeft: 10}}>
                                                <div>
                                                    <span style={{fontSize: '18px'}}>{item.occupation_title? item.occupation_title : item.occupation_name}  </span> <br/>
                                                    <span style={{fontSize: 13, color: "#9e9e9e"}}> {item.occupation_title? (item.occupation_name + " / " + item.job_type) : item.job_type }</span>
                                                </div>
                                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                                    <div style={{marginRight: 10, display: 'flex', borderRadius: 5, paddingLeft: 5, paddingRight: 5, color: 'rgb(10, 121, 202)', alignItems: 'center'}}>
                                                        <FaUsers style={{marginBottom: 2}}/>
                                                        <strong style={{marginRight: 4, marginLeft: 2}}>
                                                            {item.pending_count + item.interview_count + item.selected_count}
                                                        </strong>
                                                        <span style={{fontSize: 14}}> Pendente(s) </span>
                                                    </div>
                                                    <div style={{margin: 5, marginRight: 10, display: 'flex', borderRadius: 5, paddingLeft: 5, paddingRight: 5, color: 'rgb(84, 183, 35)', alignItems: 'center'}}>
                                                        <FaUserCheck style={{marginBottom: 2}}/>
                                                        <strong style={{marginRight: 4, marginLeft: 2}}>
                                                            {item.approved_count}
                                                        </strong>
                                                        <span style={{fontSize: 14}}> Aprovado(s) </span>
                                                    </div>
                                                    <div style={{margin: 5, marginRight: 10, display: 'flex', borderRadius: 5,paddingLeft: 5, paddingRight: 5, color: 'rgb(239, 28, 28)', alignItems: 'center'}}>
                                                        <FaUserAltSlash style={{marginBottom: 2}}/>
                                                        <strong style={{marginRight: 4, marginLeft: 2}}>
                                                            {item.rejected_count}
                                                        </strong>
                                                        <span style={{fontSize: 14}}> Reprovado(s) </span>
                                                    </div>

                                                </div>
                                             </div>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", color: "#9e9e9e",justifyContent: "space-between", padding: 10, borderTop: "1px solid gainsboro"}}>
                                            {(item.end_date && !item.closed_at) &&<div style={{marginRight: 10, marginLeft: 'auto', textAlign: "right", alignItems: "center"}}>
                                                {moment(item.end_date).diff(moment(), 'day') > 7 ?
                                                    <div>
                                                        <span> Encerramento em {moment(item.end_date).format("DD/MM/YYYY")} </span>
                                                    </div>
                                                    :
                                                    <div>
                                                        <span> Encerramento próximo <strong style={{color: '#d48c8c'}}> {moment(item.end_date).format("DD/MM/YYYY")} </strong></span>
                                                    </div>}
                                                {moment(item.end_date).diff(moment(), 'day') < 7 && <div style={{color: '#03a9f4'}}>
                                                    <a onClick={() => !loading && handleAddTime(item, index)} style={{fontSize: 12}}>Adiar encerramento da vaga por 15 dias</a>
                                                </div>}
                                            </div>}

                                            {item.closed_at &&<div style={{color: '#d48c8c'}}>
                                                <FaCalendarCheck/>
                                                <span> {moment(item.closed_at).format("DD/MM/YYYY")}</span>
                                            </div>}
                                        </div>
                                    </div>
                                </Skeleton>
                            </List.Item>
                        )}
                    /> }
                    {props.vacancies?.length === 0 && !props.loading &&
                    <div  style={{backgroundColor: "white"}}>
                        <div style={{color: '#545a5d'}}>
                            <div style={{textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                                {!props.ended ? <span style={{fontSize: 19}}> Você não possui nenhuma divulgação encerrada  </span> : <span style={{fontSize: 19}}> Você não possui nenhuma divulgação em andamento </span>}
                            </div>
                        </div>
                    </div>

                    }
                    {props.nextPage&&
                        <div style={{textAlign: 'right', padding: 5}}>
                            <Button className={"ant-btn-primary"} style={{width: "fit-content"}} onClick={() => props.loadMore()}> Mostrar mais </Button>
                        </div>
                    }
                </Col>
            </Row>
        </div>
    );
};

ExternalVacancyListComponent.propTypes = {
    establishments: PropTypes.any,
    loading: PropTypes.any
};

export default ExternalVacancyListComponent;
