import React, {useEffect, useRef, useState} from "react";
import useApi from "../../../../Hooks/Api";
import {Env} from "../../../../Helpers/Env";
import {
    AiTwotoneCalendar,
    AiOutlineUser,
    AiOutlineArrowRight,
    AiOutlineArrowLeft,
    FaBookOpen, AiOutlineHistory,
} from "react-icons/all";
import moment from "moment";
import Slider from "react-slick";
import {Col} from "antd";
import {Images} from "../../../../Helpers/Images";
import DividerCustom from "../../../../Pages/Components/DividerCustom"
import {globalMaskReal} from "../../../../Helpers/Functions";
const CourseInformation = () => {

    const api = useApi()
    const paginate = useRef({page: 1, application_alias: Env.application_alias, application: Env.application_alias});
    const qtdOp = useRef(undefined);

    const [opportunities,setOpportunities] = useState([])

    const getData = () => {
        api.post(`courses/search`, paginate.current, (res) => {
            setOpportunities(res.courses)
            paginate.current.nextPage = res.next_page;
            qtdOp.current = res?.total_count
        }, (e) => {

        });
    }

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 2900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    function SampleNextArrow(props) {
        const {className, onClick} = props;
        return (
            <AiOutlineArrowRight className={className} onClick={onClick} size={40} style={{color: "#3b5183"}}/>
        );
    }

    function SamplePrevArrow(props) {
        const {className, onClick} = props;
        return (
            <AiOutlineArrowLeft className={className} onClick={onClick} size={40} style={{color: "#3b5183"}}/>
        );
    }

    const handleClick = (index) => {
        let id = opportunities[index].course_id
        window.location.assign(`/buscar-cursos/?id=${id}`);
    }


    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <div style={{marginTop:82, textAlign:'center'}}>
                <div>
                    <i>
                        <FaBookOpen size={40} color="#333333"/>
                    </i>
                </div>
                <div>
                    <h1 style={{fontSize:25, margin:'16px 0'}}>Cursos disponíveis</h1>
                </div>
                <div style={{marginBottom:24}}>
                    <DividerCustom/>
                </div>
            </div>

            <div className="constainer-courses">
            <Col xs={20} md={22}>
                <Slider {...settings} >
                    {opportunities.map((item, index) =>
                        <div className="card-course" key={index} onClick={() => handleClick(index)}>
                            <div>
                                <img src={item.image ? item.image : Images.ImageStandardCourse} alt='banner-course'/>
                            </div>
                            <div className="container-descriptions">
                                <div>
                                    <div className='title-course limit-rows clamp-2'>
                                        <span>{item.title}</span>
                                    </div>
                                    <div className='container-category-name'>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexWrap: 'wrap',
                                            minHeight: 25,
                                            backgroundColor: 'rgb(52 124 195 / 18%)',
                                            borderRadius: 4,
                                            textAlign: "center",
                                            padding: 6,
                                            fontSize: 12,
                                            lineHeight: 1
                                        }}>
                                            <span style={{fontWeight: 500, color: 'rgb(52 178 195)'}}>{item?.category_name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="description limit-rows clamp-3">
                                    <span>
                                        {item.description}
                                    </span>
                                </div>
                                <div className="container-infos">
                                    <div>
                                        <div className='da-flex' style={{gap:5}}>
                                            <span className='da-flex'>
                                                <AiOutlineHistory size={16} style={{marginRight: "2px"}} className={'primary-color'}/> Duração:
                                            </span>
                                            <span style={{ color:'rgb(34 76 112)', display: "flex", alignItems: "center"}}>
                                                 <strong>{item.hours_total === 0 ? " Não informado" : item.hours_total + " horas"}</strong>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="container-dates">
                                        <div style={{display: "flex", fontSize: "14px", justifyContent: "flex-start", alignItems: "center"}}>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <AiTwotoneCalendar size={16} className={'primary-color'}/><span style={{marginRight: "5px", marginLeft: "2px"}}>Inscrições até:</span>
                                            </div>
                                            <div style={{display: "flex", borderRadius: "50px"}}>
                                                <div>
                                                    <span style={{color: "#D32F2F", fontWeight: 700}}>{moment(item.subscription_limit_date).format("DD/MM/YYYY")}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <div style={{display: "flex", alignItems: "center", paddingRight: "2px"}}>
                                                    <AiOutlineUser size={16} className={'primary-color'}/> <span style={{marginLeft: "2px"}}>Inscritos: </span>
                                                </div>
                                                <div>
                                                    <span style={{color: "#18506f", fontWeight: 700}}>
                                                        {item.total_interested}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Slider>
            </Col>
        </div>
        </div>
    )
}
export default CourseInformation
