import React from 'react'
import {Checkbox} from "antd";

const SyCheckbox = ({data, setValue, value}) => {

    const handleCheckbox = () => {
        let checkboxSelected = {}
        let checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

        for (let i = 0; i < checkboxes.length; i++) {
            checkboxSelected[checkboxes[i].id] = true
        }
        if (Object.keys(checkboxSelected).length === 0) {
            checkboxSelected = undefined
        }

        setValue(checkboxSelected)
    }

    return (
        <div>
            {data?.form_section_field_options.map((item, index) => (
                <Checkbox id={`field_${data.form_section_field_id}_${item.form_section_field_option_id}`}
                          value={item?.form_section_field_option_id}
                          checked={value !== undefined && Object?.keys(value)?.includes(`field_${data.form_section_field_id}_${item.form_section_field_option_id}`)}
                          onChange={(e) => handleCheckbox(e.target.value, index)}>
                    {item.answer}
                </Checkbox>
            ))}
        </div>
    )
}

export default SyCheckbox
