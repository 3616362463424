import React, {useEffect} from 'react'
import ComponentsFaq from "./Components/components-faq";


const FaqPage = () => {

    useEffect(() => {
        window.scrollToElement('body');
    }, []);

    return (
        <div style={{paddingBottom: "50px", background:"rgb(248, 249, 252)"}}>
            <div className="ant-container-faq">
                <ComponentsFaq/>

            </div>
        </div>
    )
}

export default FaqPage