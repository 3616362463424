import React, { useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Col, Icon, List, Row, Skeleton, Spin, Tooltip} from "antd";
import Loading from "../../../../Generic/Loading";
import {Images} from "../../../../../Helpers/Images";
import moment from "moment";
import user_place_holder from '../../../../../Img/jobSearch.png'
import {FaCalendarCheck, FaClipboardCheck, FaClipboardList, FaUserAltSlash, FaUserCheck, FaUsers} from "react-icons/fa";
import {FiPauseCircle, MdThumbUp} from "react-icons/all";
import CandidateSearchModal from "../CandidateSearchModal";
import useApi from "../../../../../Hooks/Api";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import useAuth from "../../../../../Hooks/Auth";
import {FaCheckCircle, FaHourglassHalf} from 'react-icons/fa'
import ShareTo from "../../../../../Pages/Components/ShareTo";


Spin.setDefaultIndicator(<Loading/>);

const VacancyListComponent = (props) => {

    const [showApproveModal, setShowApproveModal] = useState(false);
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const [rightLoading, setRightLoading] = useState(false);
    const vacancyList = useRef([]);
    const [loadingVacancy, setLoadingVacancy] = useState(false);
    const {storeEnterpriseOpportunities} = useAuth();
    const paginate = useRef({
        page: 1,
    });
    const nextPage = useRef(false);
    const history = useHistory();
    const routerParams = useParams();


    const getVacancies = async (status, more) => {
        if(more) {
            setLoadingVacancy(true)
            paginate.current.page = paginate.current.page + 1;
        } else {
            setRightLoading(true);
            paginate.current.page = 1;
        }
        api.get(`sine/company_opportunities/${routerParams.company_id}?page=${paginate.current.page}&limit=20&closed=${status !== 'open'}`,(res) => {
            res.next_page? nextPage.current = true : nextPage.current = false;
            if(status === 'open') {  storeEnterpriseOpportunities(res.opportunities); }
            vacancyList.current = (more? vacancyList.current.concat(res.opportunities) : res.opportunities);
            setRightLoading(false);
            setLoadingVacancy(false);
        },(e) => {
            setLoadingVacancy(false);
            setRightLoading(false);
            console.log(e);
        });
    };

    const infiniteScroll = () => {
        let bodyAux = document.getElementById(`vacancyScroll`);
        if(nextPage.current) {
            if (bodyAux.scrollTop + bodyAux.clientHeight >= bodyAux.scrollHeight) {
                getVacancies(props.ended? 'closed' : 'open', true)
            }
        }
    }

    const addTime = (item, index) => {
        vacancyList.current.splice(index, 1);
        props.handleAddTime(item, index)
    }

    useEffect(() => {
        if(routerParams.company_id) getVacancies(props.ended? 'closed' : 'open');
        if(document.getElementById(`vacancyScroll`)) document.getElementById(`vacancyScroll`).addEventListener('scroll', infiniteScroll)
        return () => {
            if(document.getElementById(`vacancyScroll`)) document.getElementById(`vacancyScroll`).removeEventListener(`scroll`, infiniteScroll);
        }
    },[])

    useEffect(() => {
        if (props.refetch) {
            getVacancies('open');
        }
    }, [props.refetch]);

    return (
        <div  className={'mainComponentPanel'} style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 0, borderRadius:5, backgroundColor: "white"}}>
            {showApproveModal &&<CandidateSearchModal visible={showApproveModal} setVisible={() => setShowApproveModal(false)} user={props.user} enterprise={routerParams.company_id}/>}
            {/*{!props.ended &&vacancyList.current.map((item, index) => {*/}
            {/*    if(item.end_date && moment(item.end_date).diff(moment(), 'day') <= 15) {*/}
            {/*        return <div style={{padding: 5, paddingLeft: 10, alignItems: 'center', paddingRight: 10, borderRadius: 5, display: 'flex', justifyContent: 'space-between', color: '#03a9f4', marginBottom: 3, backgroundColor: "#fff4f4", border: '1px solid #ffc7c7'}}>*/}
            {/*            <span style={{color: '#595959'}}> Vaga - <strong> {item.occupation_title? item.occupation_title : item.occupation_name} </strong> com encerramento próximo - <strong style={{color: '#d48c8c'}}> {moment(item.end_date).format("DD/MM/YYYY")}</strong> </span>*/}
            {/*            <a onClick={() => !props.smallLoading && addTime(item, index)} style={{fontSize: 12}}>Adiar encerramento da vaga por 15 dias</a>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*})}*/}
            <Row>
                <Col xs={24} style={{display: "flex" ,padding: 10, borderBottom: "1px solid gainsboro", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display: "flex" , alignItems: "center"}}>
                        {props.ended ?
                            <FaClipboardCheck type={"file-done"} style={{marginRight: 8, fontSize: 18, marginTop: -4, color: "indianred"}} />
                            :
                            <FaClipboardList style={{marginRight: 8, fontSize: 18, marginTop: -4}} className={"secondary-color"}/>
                        }
                        <span style={{fontSize: 18}}>{props.ended? 'Suas divulgações encerradas' : 'Suas divulgações em andamento'}</span>
                    </div>

                    {!props.ended &&<Button style={{boxShadow: `0px 2px 5px 1px #bbbbbb`, paddingRight: 15, borderBottomLeftRadius: `50px`, marginRight: -10}} className={"ant-btn-green"}  onClick={() => setShowApproveModal(true)}>
                       <div style={{display: "flex", alignItems: "center"}}>
                           <MdThumbUp style={{marginRight: 4, }}/>
                            <span>
                                 Informar Contratação
                            </span>
                       </div>
                    </Button>}
                </Col>

                {rightLoading?
                    <Col xs={24}> <Loading/> </Col>
                    :
                    <Col xs={24} style={{padding: 5, minHeight: 500, maxHeight: window.innerHeight - 170, overflowY: `scroll`}} id={`vacancyScroll`}>
                        {(vacancyList.current?.length > 0) &&<List
                            className='listAnt'
                            style={{overflow: 'hidden'}}
                            itemLayout="horizontal"
                            dataSource={vacancyList.current}
                            loading={loadingVacancy}
                            renderItem={(item,index) => (
                                <List.Item  style={{position: 'relative',  padding: 0, backgroundColor: "white", borderLeft:'3px solid transparent'}}>
                                    <Skeleton avatar title={false} loading={false} active>
                                        <div style={{width: "100%"}}>
                                            <div style={{display: "flex", padding: '10px 5px 10px 10px', alignItems:'flex-start', justifyContent:"space-between"}}>
                                                <div onClick={() => history.push('/painel/cliente/vagas/' + props.enterprise.id + '/detalhes-da-vaga/' + item.id)}
                                                     style={{display:'flex', alignItems:'center', cursor: "pointer", gap:10,marginTop:8, flex:1}}>
                                                    <Avatar style={{width: 60, height: 60}} size='default' src={item.job_avatar || user_place_holder}/>

                                                    <div>
                                                        <div>
                                                            <span style={{fontSize: '18px'}}>{item.occupation_title? item.occupation_title : item.occupation_name}  </span> <br/>
                                                            <span style={{fontSize: 13, color: "#9e9e9e"}}> {item.occupation_title? (item.occupation_name + " / " + item.job_type[0]) : item.job_type[0] }</span>
                                                        </div>
                                                        <div style={{display: "flex", flexWrap: "wrap", gap:24, marginTop:10}}>
                                                            <div className='da-flex' style={{color: 'rgb(10, 121, 202)', gap:5}}>
                                                                <FaUsers size={16}/>
                                                                <strong>
                                                                    {item.summary?.pending + item.summary?.interview + item.summary?.selected}
                                                                </strong>
                                                                <span> Pendente(s) </span>
                                                            </div>
                                                            <div className='da-flex' style={{color: 'rgb(84, 183, 35)', gap:5}}>
                                                                <FaUserCheck size={16}/>
                                                                <strong>
                                                                    {item.summary?.approved}
                                                                </strong>
                                                                <span> Aprovado(s) </span>
                                                            </div>
                                                            <div className='da-flex' style={{color: 'rgb(239, 28, 28)', gap:5}}>
                                                                <FaUserAltSlash size={16}/>
                                                                <strong>
                                                                    {item.summary?.rejected}
                                                                </strong>
                                                                <span> Reprovado(s) </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {!props.ended &&
                                                    <div className='da-flex' style={{position:'absolute', right:4, top:4}}>
                                                        {(item?.audited_at !== null ) ?
                                                            item?.hidden ?
                                                                <Tooltip placement="topLeft" title={'A vaga está em andamento, porém, não recebe novas candidaturas.'}>
                                                                    <div className='badge-status-opportunity paused' style={{marginRight:4, background:'#f8f9fa'}}>
                                                                        <FiPauseCircle/>
                                                                        <span>Pausada</span>
                                                                    </div>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip placement="topLeft" title={'A vaga foi liberada para publicação!'}>
                                                                <span>
                                                                    <div style={{color:'#34c38f'}} className='badge-status-opportunity'>
                                                                        <FaCheckCircle/>Liberada
                                                                    </div>
                                                                </span>
                                                                </Tooltip>
                                                            : <Tooltip placement={"topLeft"} title={'Os detalhes da vaga estão sendo analisados por nossa equipe, se estiver tudo OK, em menos de 24 horas ela será liberada para o público.'}>
                                                                <div style={{color:'#f1b44c'}} className='badge-status-opportunity'>
                                                                    <FaHourglassHalf/>Em análise
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                        {(item?.audited_at !== null && !item?.hidden) && <ShareTo type={'jobInformation'} idVacancy={item.id}/>}
                                                    </div>
                                                }

                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between", padding: 10, borderTop: "1px solid gainsboro"}}>
                                                <div>
                                                    <Icon type={"environment"}/>
                                                    <span style={{color: "#757575", marginLeft: 5}}>{item.work_place === 'mobile'? "Trabalho remoto / Home office" : (item.work_place === 'itinerant' ? "Sem endereço fixo" : (item.work_address?  item.work_address.street + " / " + item.work_address.city + ' - ' +  item.work_address.postal_code : null))} </span>
                                                </div>

                                                {(item.end_date && !item.closed_at) &&<div style={{marginRight: 10,color: 'gray', marginLeft: 'auto', textAlign: "right", alignItems: "center"}}>
                                                    { moment(item.end_date).diff(moment(), 'day') > 10 ?
                                                        <div>
                                                            <span> Encerramento em {moment(item.end_date).format("DD/MM/YYYY")} </span>
                                                        </div>
                                                        :
                                                        <div>
                                                            <span> Encerramento próximo <strong style={{color: '#d48c8c'}}> {moment(item.end_date).format("DD/MM/YYYY")} </strong></span>
                                                            {/*<div style={{color: '#03a9f4'}}>*/}
                                                            {/*    <a onClick={() => !props.smallLoading && addTime(item, index)} style={{fontSize: 12}}>Adiar encerramento da vaga por 15 dias</a>*/}
                                                            {/*</div>*/}
                                                        </div>}
                                                </div>}

                                                {item.closed_at &&<div style={{color: '#d48c8c'}}>
                                                    <FaCalendarCheck/>
                                                    <span> {moment(item.closed_at).format("DD/MM/YYYY")}</span>
                                                </div>}
                                            </div>
                                        </div>
                                    </Skeleton>
                                </List.Item>
                            )}
                        /> }
                        {(vacancyList.current.length === 0) &&
                        <div  style={{backgroundColor: "white"}}>
                            <div style={{color: '#545a5d'}}>
                                <div style={{textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                                    {props.ended ? <span style={{fontSize: 19}}> Você não possui nenhuma divulgação em andamento </span> : <span style={{fontSize: 19}}>Você não possui nenhuma divulgação encerrada</span>}
                                        <span> Gostaria de divulgar uma vaga em sua empresa? divulgue agora! </span>
                                    <Button className={"ant-btn-primary"} style={{marginTop: 40, width: "fit-content"}} onClick={() => history.push('/painel/cliente/vagas/' + props.enterprise.id + '/criar-vaga')}> Divulgar vaga </Button>
                                </div>
                            </div>
                        </div>
                        }
                        {nextPage.current&&
                            <div style={{textAlign: 'right', padding: 5}}>
                                <Button className={"ant-btn-primary"} style={{width: "fit-content"}} onClick={() => getVacancies(props.ended?'closed' : 'open', true)}> Mostrar mais </Button>
                            </div>
                        }
                    </Col>
                    }
            </Row>
        </div>
    );
};

VacancyListComponent.propTypes = {
    establishments: PropTypes.any,
    loading: PropTypes.any
};

export default VacancyListComponent;
