import React, {useCallback, useState,} from 'react';
import {Env} from "../Helpers/Env";
import useLocalStorage from "./Storage";
import {StorageVariables} from "../Helpers/StoragesVariables";
import {useHistory} from "react-router-dom";


const useApiClub = (config?: { apiUrl?: string, customReturn?: boolean, navigation?: any, survey?: boolean}) => {
    const externAccessUser = useLocalStorage(StorageVariables.externUserAccess)
    const history = useHistory();

    if (!config) {
        config = {};
    }

    config.apiUrl = config.apiUrl || Env.api_bluetrix;

    const put = (path, data, cb, cbe) => {
        _fetch(path, 'PUT', data, (res) => cb(res), (res) => cbe(res))
    };

    const post = (path, data, cb, cbe) => {
        _fetch(path, 'POST', data, (res) => cb(res), (res) => cbe(res))
    };

    const get = (path, cb, cbe) => {
        _fetch(path, 'GET', null, (res) => cb(res), (res) => cbe(res))
    };

    const del = (path, data, cb, cbe) => {
        _fetch(path, 'DELETE', data || null, (res) => cb(res), (res) => cbe(res))
    };

    const _fetch = (path, method, data, cb, cbe) => {
        const obj = {
            method: method,
            headers: config.survey? {...Env.header_club, ...{userToken: Env.header[`X-Api-Key`],  'X-App-Alias': Env.application_alias}} : Env.header_club,
        };

        if (data) {
            obj.body = JSON.stringify(data);
        }

        fetch(`${config.apiUrl}/${path}`, obj)
            .then((response) => {
                response.json().then(res => {
                    if (res.HTTPStatus === 200 || res.HTTPStatus === 206 || res.HTTPStatus === 201) {
                        cb(res);
                    } else {
                        console.log(res);
                        if(res.message === 'Token já desabilitado' && !config.survey) {
                            history.push('/acesso-externo/logout-externo')
                        }
                        if(res?.validator) {
                            const validators = Object.keys(res?.validator);
                            if(validators.length > 0) {
                                cbe({message: res.validator[validators[0]][0]});
                            }
                            else {
                                cbe(res);
                            }
                        } else {
                            cbe(res);
                        }
                    }
                }).catch(err => {
                    console.log(err);
                    cbe({error: 'Erro de aplicação'}) //TODO ERRO MESMO
                });

            })
            .catch(err => {
                cbe({message: 'Não foi possível se conectar com o servidor, tente novamente'});
            });
    };

    return {post, get, del, put};
};

export default useApiClub;
