import React, {useEffect, useRef, useState} from 'react'
import {Col, Dropdown, Icon, Menu, Row} from "antd";
import {Images} from "../Helpers/Images";
import useApi from "../Hooks/Api";
import {globalMaskReal, maskCnpj, maskReal} from "../Helpers/Functions";
import moment from "moment";
import WorkerOpportunityDrawer from "./Components/WorkerOpportunityDrawer";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import Loading from "../Components/Generic/Loading";
import {Env} from "../Helpers/Env";
import {FaAngleDown} from 'react-icons/fa'
import useLocalStorage from "../Hooks/Storage";
import {StorageVariables} from "../Helpers/StoragesVariables";

const CompanyProfile = (props) => {
  const api = useApi();
  const activeOpportunity = useRef();
  const [enterprise, setEnterprise] = useState()
  const [enterpriseInfo, setEnterpriseInfo] = useState()
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false)
  const [loadingOpportunity, setLoadingOpportunity] = useState(true);
  const filters = useRef({vacancies: "all", order_by:"newest", page:1})
  const idEnterprise = useRef()
  const user = useSelector((state) => state).authReducer;
  const [queryObj, setQueryObj] = useState(window.queryStringToObject())
  const history = useHistory();
  const companyStorage = useLocalStorage(StorageVariables.enterpriseData).getObject();

  const getData = () => {
    setLoading(true)
    let linkEndPoint = `sine/company_opportunities/${idEnterprise.current}?page=${filters.current.page}&limit=50${'&order_by=' + filters.current.order_by}&closed=false${filters.current.vacancies !== 'all' ? (filters.current.vacancies === 'vacancies-candidate' ? '&is_candidate=true' : '&is_candidate=false') : ''}${'&application=' + Env.application_alias}`

    api.get(`${linkEndPoint}`,
        (res)=>{
          filters.current.next_page = res.next_page
          filters.current.page = res.page

          setEnterprise(res)
          setLoading(false)
        }, (err) => {
          console.log(err)
          setLoading(false)
        }
    )
  }
  const getDataEnterpriseInfo = (id) => {
    api.get(`sine/company-info/${id}`,
        (res) => {
          setEnterpriseInfo(res)
        }, (err) => {
          console.log(err)
        }
      )
  }

  const openOpportunity = (id, index, candidate) => {
    queryObj.id= id
    const urlParams = new URLSearchParams(queryObj);
    history.push(`/empresa/${idEnterprise.current}/?${urlParams}`);
    getOpportunity(id,index, candidate)
  }


  const removeFromList = (e) => {
    setLoading(true);
    setShowDetails(false);
    if(activeOpportunity.current?.index) {
      let aux = enterprise;
      aux.opportunities[activeOpportunity.current.index].is_candidate = true;
      setEnterprise(aux);
    }
    setLoading(false);
  };

  const closeOpportunity = () => {
    if(queryObj.id) {
      delete queryObj.id
    }

    if(queryObj) {
      const urlParams = new URLSearchParams(queryObj);
      history.push(`/empresa/${idEnterprise.current}/?${urlParams}`);
    } else {
      history.push(`/empresa/${idEnterprise.current}`);
    }

    setShowDetails(false);
  };

  const getOpportunity = (id, index, candidate) => {
    activeOpportunity.current = null;
    setShowDetails(true);
    setLoadingOpportunity(true);
    api.get(user?.user_id? `sine/user/${user.user_id}/job-opportunity/${id}` : `/sine/job-opportunity/${id}`, (res) => {
      activeOpportunity.current = res;
      activeOpportunity.current.id = id;
      activeOpportunity.current.index = index;
      activeOpportunity.current.is_candidate = candidate;
      setLoadingOpportunity(false);
    }, (err) => {
      console.log(err)
      setLoadingOpportunity(false);
      setShowDetails(false);
    });
  }

  const checkActive = (e) => {
    filters.current.vacancies = e
    getData()
  }

  useEffect(() => {
    if (props.match.params.id) {
      idEnterprise.current = props.match.params.id;
      getData();
      getDataEnterpriseInfo(props.match.params.id);
    }

    if(queryObj?.id) {
      getOpportunity(queryObj?.id, undefined, false);
    }
  },[])

  return (
      <Row style={{display:"flex",alignItems:"center", flexDirection:"column", gap:'2rem', minHeight: 500, backgroundRepeat: 'repeat-y', background: `url(${Images.background_4})`}}>
        {enterpriseInfo && <Col xs={23}  lg={21} className='company-card' style={{display:"flex", justifyContent:"center"}}>
              <Col lg={24} style={{display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                  <Col lg={24}>
                    <img src={enterpriseInfo?.company_banner || Images.fakeBanner} style={{width:'100%', borderTopLeftRadius: 10, borderTopRightRadius:10}}/>
                  </Col>
                  {idEnterprise.current == companyStorage?.id && <span style={{color: '#1680ce', textAlign: 'right', paddingRight: 5}}><a onClick={() => history.push(`/painel/cliente/vagas/${idEnterprise.current}/editar-empresa/`)} style={{fontSize: 12}}>(Clique aqui para adicionar outro banner)</a></span>}
                  <Col lg={20} className='company-img-profile'>
                    <img src={enterpriseInfo?.company_logo ? enterpriseInfo?.company_logo : Images.user_place_holder}/>
                  </Col>
                </div>
                <Row style={{display:"flex", flexWrap:"wrap", marginLeft:'2rem', marginBottom:'2rem'}}>
                  <Col md={7} lg={9} xl={8} style={{display:"flex", flexDirection:"column", gap:6}}>
                    <span style={{color: "#212020", fontSize: 24, fontWeight:600}}> {enterpriseInfo?.business_name} </span>
                    {enterpriseInfo?.category ? <span style={{color: "#9e9e9e", fontSize: 16}}> {enterpriseInfo?.category?.description} </span> : (idEnterprise.current == companyStorage?.id && <span style={{color: '#1680ce'}}><a onClick={() => history.push(`/painel/cliente/vagas/${idEnterprise.current}/editar-empresa/`)} style={{fontSize: 12}}>(Clique aqui para adicionar uma categoria)</a></span>)}
                    <span style={{color: "#9e9e9e", fontSize: 16}}> {maskCnpj(enterpriseInfo?.cnpj)} </span>
                    <span style={{color: "#9e9e9e", fontSize: 16}}> {enterpriseInfo?.address.city} </span>
                  </Col>
                  <Col xs={0} md={1} lg={1} xl={1} xxl={1} style={{borderRight:'1px solid gainsboro', marginRight:'1rem'}}/>
                  <Col xs={24} md={0} lg={0} xl={0} xxl={0} style={{borderBottom:'1px solid gainsboro', margin:'1rem -1rem'}}/>
                  <Col xs={22} md={14} lg={8} style={{textAlign:"justify"}}>
                   <span style={{fontSize: 18, fontWeight:600}}>
                     Sobre nós
                   </span>
                    <p style={{color: "#9e9e9e", fontSize: 15}}>
                      {enterpriseInfo?.description || 'Nenhuma descrição informada'} {idEnterprise.current == companyStorage?.id && <span style={{color: '#1680ce'}}>
                      <a onClick={() => history.push(`/painel/cliente/vagas/${idEnterprise.current}/editar-empresa/`) } style={{fontSize: 12}}>(Toque aqui para editar a descrição)</a>
                      </span>
                      }
                    </p>
                  </Col>
                </Row>
              </Col>
            </Col>}
            <WorkerOpportunityDrawer
                applied={activeOpportunity.current?.is_candidate}
                vacancy={activeOpportunity.current}
                removeFromList={(e) => removeFromList(e)}
                setVisible={() => closeOpportunity()}
                loading={loadingOpportunity}
                visible={showDetails}
            />

            <Col className='company-card' style={{padding:'1rem'}} span={20}>
                <Row  style={{display:"flex", alignItems:"center", gap:10, flexWrap: 'wrap', padding: '6px'}}>

                  <Col xs={24}  md={7} lg={5} xl={4}>
                    <Dropdown overlayStyle={{minWidth:200}} overlay={
                      <Menu>
                        <Menu.Item>
                          <a rel="noopener noreferrer" onClick={()=> {filters.current.order_by = 'newest' ; getData()}}>
                            Mais recentes
                          </a>
                        </Menu.Item>
                        <Menu.Item>
                          <a rel="noopener noreferrer" onClick={()=> {filters.current.order_by = 'distance' ; getData()}}>
                            Mais próximos de mim
                          </a>
                        </Menu.Item>
                      </Menu>}>

                      <div className='dropdown__custom'>
                        <span style={{color: '#707c88', fontSize: 12, marginLeft: 2, marginRight:2}}>
                          {filters.current.order_by === 'newest'? 'Mais recentes' : 'Mais próximos de mim'}
                        </span>
                        <FaAngleDown className='angle-down-rotate'/>
                      </div>
                    </Dropdown>
                  </Col>


                  {user?.user_id && (
                    <Col xs={24} md={8} lg={7} xl={5}>
                      <Dropdown overlayStyle={{minWidth:200}} overlay={
                        <Menu>
                          <Menu.Item>
                            <a rel="noopener noreferrer" onClick={()=> checkActive("all")}>
                              Todos as vagas
                            </a>
                          </Menu.Item>
                          <Menu.Item>
                            <a rel="noopener noreferrer" onClick={()=> checkActive("vacancies-candidate")}>
                              Vagas que me candidatei
                            </a>
                          </Menu.Item>
                          <Menu.Item>
                            <a rel="noopener noreferrer" onClick={()=> checkActive("vacancies-no-candidate")}>
                              Vagas que não me candidatei
                            </a>
                          </Menu.Item>
                        </Menu>}>
                        <div className='dropdown__custom'>
                          <span style={{color: '#707c88', fontSize: 12, marginLeft: 2, marginRight:2}}>
                               {filters.current.vacancies === 'all' ? 'Todas as vagas' :
                                   (filters.current.vacancies === 'vacancies-candidate' ? 'Vagas que me candidatei' : 'Vagas que não me candidatei')}
                          </span>
                          <FaAngleDown className='angle-down-rotate'/>
                        </div>
                      </Dropdown>
                    </Col>
                  )}
                </Row>
              {loading === true ? <div style={{marginTop:32}}><Loading/></div> :
                  <Row>
                    {filters.current.vacancies === "vacancies-candidate" ?
                        (enterprise?.entries === 0 &&
                            <div style={{display:"flex", alignItems:"center", padding:'2rem', justifyContent:'center', fontSize: 16}}>
                              Você ainda não se candidatou a nenhuma vaga da empresa!
                            </div>
                        ) :
                        ((filters.current.vacancies === "vancancies-no-candidate" && filters.current.vacancies !== 'all') &&
                            <div style={{display:"flex", alignItems:"center", padding:'2rem', justifyContent:'center', fontSize: 16}}>
                              Você se candidatou a todas as vagas da empresa!
                            </div>
                        )
                    }
                    {enterprise?.opportunities.map((item, index) => {
                      return (
                          <Col md={12} lg={8}
                               onClick={() => openOpportunity(item.id, index, item.is_candidate)}
                               style={{padding: '1rem'}}
                               key={index}>
                            <div style={{
                              backgroundColor: 'white',
                              cursor: 'pointer',
                              overflow: 'hidden',
                              height: 200,
                              display: "flex",
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              borderRadius: 5,
                              padding: 10,
                              paddingBottom: 5,
                              boxShadow: '0px 0px 3px #e3e3e3'
                            }}>
                              {item.is_candidate && <img style={{
                                position: 'absolute',
                                right: 20,
                                opacity: 0.1,
                                bottom: 20,
                                width: 80,
                                height: 80
                              }} src={Images.checked}/>}

                              <div style={{display: 'flex', alignItems: 'center'}}>

                                <img style={{backgroundColor: 'white', borderRadius: 30, width: 60, height: 60}}
                                     src={item.job_avatar ? item.job_avatar : Images.case_img}
                                />

                                <div style={{flex: 1, marginLeft: 10}}>
                        <span style={{flex: 1, fontWeight: 'bold', fontSize: 16, marginBottom: 3}}>
                          {item.occupation_title ? item.occupation_title : item.occupation_name}
                        </span>

                                  <div>
                                    <div>
                                      <span style={{color: 'green', fontWeight: "bold"}}>R$ </span>
                                      <span style={{fontSize: 13, color: 'green', flex: 1, maxHeight: 86}}>
                              {item.pay ? globalMaskReal(item.pay) : 'Não Informado'}
                            </span>
                                    </div>

                                    {item.job_type.length > 0 &&
                                        <div style={{flexDirection: "row", alignItems: "center", flex: 1}}>
                                          <Icon color={'grey'} type={'clock-circle'}/>
                                          <span style={{fontSize: 14, color: 'grey', marginLeft: 5, flex: 1,}}>
                                {item.job_type[0]}
                               </span>
                                        </div>
                                    }
                                  </div>
                                  <div style={{display: "flex", justifyContent: 'space-between'}}>
                          <span style={{fontSize: 14, color: 'grey', flex: 1,}}>
                            <span style={{fontWeight: 'bold', marginRight: 4}}>
                              {item.job_openings}
                            </span>
                            Vaga(s)
                          </span>
                                    {item.is_candidate &&
                                        <div style={{flexDirection: 'row', alignItems: 'center', flex: 1,}}>
                                          <Icon color={'grey'} size={15} name={'check-all'}/>
                                          <span style={{fontSize: 14, fontWeight: 'bold', color: 'grey', flex: 1,}}>
                                CURRÍCULO ENVIADO
                              </span>
                                        </div>
                                    }
                                  </div>
                                </div>
                              </div>

                              {item.job_description && <div style={{
                                borderTop: '1px solid gainsboro',
                                width: '100%',
                                borderTopColor: 'gainsboro',
                                marginTop: 5,
                                paddingTop: 5
                              }}>
                      <span style={{color: '#808080c7', fontSize: 13,}}>
                        {item.job_description?.length > 100 ? item.job_description?.slice(0, 100) + ' ... ' : item.job_description}
                      </span>
                              </div>}

                              <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 5}}>
                      <span style={{flex: 1, letterSpacing: 1, fontSize: 12, color: '#9e9e9e'}}>
                        {item.work_place === 'mobile' ? "Trabalho remoto/Home office" :
                            (item.work_place === 'itinerant' ? "Sem endereço fixo" : (item.work_address ? item.work_address.city : null))
                        }
                      </span>

                                <span style={{textAlign: 'right', flex: 1, fontSize: 12, color: 'gainsboro'}}>
                        Publicado em {moment(item.start_date, 'YYYY-MM-DD').format('DD/MM')}
                      </span>
                              </div>
                            </div>
                          </Col>
                      )
                    })}
                  </Row>
              }
            </Col>
      </Row>
      )
}

export default CompanyProfile
