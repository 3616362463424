import React, {useRef, useState} from 'react';
import {Button, Col, Icon, Layout, Row} from 'antd';
import {useHistory} from "react-router-dom";

import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";
import RegisterPageModal from "../../../../Pages/RegisterPageModal";

const IdealCollaboratorCard = () => {

    const history = useHistory();
    const [registerModal, setRegisterModal] = useState(false);
    const profileType = useRef('');

    return(
        <div>
            <Row className="cardHome" style={{marginLeft:40, marginRight:6, marginTop:80}}>
                <Col xs={23} lg={22} xl={21} xxl={15} style={{textAlign: 'center', justifyContent: 'space-around'}}>
                        <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                            <Col sm={20} lg={20} xl={19} xxl={16} className='headerHowTo' style={{padding: 5, textAlign: 'center'}}>
                                <img style={{filter: 'brightness(0.2)', width: 50, marginBottom: 30}} src={Images.home.resume_card_icon}/>
                                <h1 style={{position: 'relative'}} className={'title title-line'}> {Texts.howItWorksSine.mainTitle} </h1>
                            </Col>
                        </Row>
                    <Row gutter={[32,32]} className="cardHomeRowServiceAndIdeal">
                        <Col xs={24} md={24} lg={8}>
                            <div className='idealCollaboratorCard'>
                                <div style={{width: '100%'}}>
                                    <img className="idealCollaboratorImg" src={Images.VacancyHomeCard}/>
                                </div>

                                <div className="idealCollaboratorText">
                                    <h2> {Texts.home_page.register.divulge_vacancy_title} </h2>
                                    {Texts.home_page.register.divulge_vacancy_list}
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={24} lg={8}>
                            <div className='idealCollaboratorCard'>
                                <div style={{width: '100%'}}>
                                    <img className="idealCollaboratorImg" src={Images.SearchHomeCard}/>
                                </div>
                                <div className="idealCollaboratorText">
                                    <h2>{Texts.home_page.register.curriculos_title}  </h2>
                                    {Texts.home_page.register.curriculos_list}
                                </div>
                                <div>
                                    <Button onClick={() => history.push('/curriculos')} className={'ant-btn-purple'} style={{display:"flex", alignItems:"center", margin:"auto", marginBottom:16}}> <Icon type='search' style={{margin: 0}}/> Buscar </Button>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={24} lg={8}>
                            <div className='idealCollaboratorCard'>
                                <div style={{width: '100%'}}>
                                    <img className="idealCollaboratorImg" src={Images.FeedbackHomeCard}/>
                                </div>
                                <div className="idealCollaboratorText">
                                    <h2>{Texts.home_page.register.feedback_title}  </h2>
                                    {Texts.home_page.register.feedback_list}
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Col>
                <RegisterPageModal profile={profileType.current} visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />
            </Row>
        </div>
    )
}

export default IdealCollaboratorCard
