import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Divider, Drawer, Icon, message, Modal, notification, Row, Spin} from 'antd';
import useApi from "../../Hooks/Api";
import * as moment from "moment";
import {globalMaskReal} from "../../Helpers/Functions";
import {useSelector} from "react-redux";
import {
    FaBriefcase,
    FaCalendarCheck,
    FaCar,
    FaArrowRight,
    FaRegClock,
    FaTools,
    FaStream,
    FaRocket,
    FaMedal,
    FaBusinessTime, FaWifi, FaBookOpen, FaClock
} from "react-icons/fa";
import {
    FaCodepen,
    FaDollarSign, FaGraduationCap,
    FaIdCardAlt, FaLanguage, FaRegAddressBook,
    FaRegCalendarAlt, FaRegFileAlt, FaRegMap,
    FaRegThumbsUp, FaWheelchair, MdMore, RiShareBoxFill
} from "react-icons/all";
import {Images} from "../../Helpers/Images";
import RegisterPageModal from "../RegisterPageModal";
import {Env} from "../../Helpers/Env";
import useLocalStorage from "../../Hooks/Storage";
import {StorageVariables} from "../../Helpers/StoragesVariables";
import {Link, useHistory} from "react-router-dom";
import {isEmpty} from "lodash";
import ShareTo from "./ShareTo";
import ModalAlertRegisterCurriculum from "../Modal/ModalAlertRegisterCurriculum";
import {eventVisible} from "../../Hooks/Helpers";


const WorkerOpportunityDrawer = (props, ref) => {
    const [loading, setLoading] = useState(false);
    const [recruitLoading, setRecruitLoading] = useState(false);
    const [workerPendingData, setWorkerPendingData] = useState(false);
    const api = useApi({customReturn: true, loadingControl: false});
    const [visible, setVisible] = useState(false);
    const user = useSelector((state) => state).authReducer;
    const [registerModal, setRegisterModal] = useState(false);
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);
    const lastStep = useLocalStorage(StorageVariables.curriculumStep);
    const completProfile = useLocalStorage(StorageVariables.completProfile)
    const fullDataCurriculum = useLocalStorage(StorageVariables.updatedProfile);
    const history = useHistory();
    const [recommendedCourse, setRecommendedCourse] = useState(null);
    const [showCourses, setShowCourses] = useState(false);
    const [alertRegisterCurriculum, setAlertRegisterCurriculum] = useState(false);

    const payType = [{label: 'Mensal', value:'Mês'}, {label:'Quinzenal', value:'Quinzena'}, {label: 'Diária', value:'Dia'}, {label:'Hora', value: 'Hora'}, {label: 'Por produção' , value: 'Por Produção'} , {label:'Por tarefa', value:'Por Tarefa'}]


    const handleApply = async (id) => {

        if(user?.user_id && hasProfileStorage?.getPrimitive()) {
            setVisible(false);
            setLoading(true);
            api.post(`sine/user/${user.user_id}/apply-opportunity/${props.vacancy.id}`, null, async (res) => {
                setLoading(false);
                message.success('Você candidatou-se a esta vaga!');
                window.gtag && window.gtag('event', 'opportunity_applied_site');
                if (recommendedCourse?.have_on_the_plataform) {
                    setShowCourses(true);
                } else {
                    props.removeFromList();
                    checkPendingResume();

                    if (eventVisible()) {
                        history.push(`/vagas-do-evento`)

                    } else {
                        history.push(`/vagas-de-emprego/`)
                    }
                }
            }, async (e) => {
                setLoading(false);
                if(fullDataCurriculum.getObject()?.user_id === null) {
                    setAlertRegisterCurriculum(true)
                } else {
                     message.error(e.message || "Não foi possível enviar seu currículo à vaga, tente novamente");
                }
                window.gtag && window.gtag('event', 'opportunity_applied_error_site');
            });
        } else {
            setVisible(false);
            setRegisterModal('login');
        }

    };

    const redirectToIncompleteResume = (step) => {
        lastStep.setPrimitive(step)
        window.gtagService('event', 'redirectWorker');
    }

    const checkPendingResume = () => {
        api.get(`sine/user/${user?.user_id}/full`, (res) => {
            if (res.user_id !== null) {
                let aux = Object.entries(res?.profile?.social_media)

                if (aux[0][1] === null && aux[1][1] === null &&aux[2][1] === null) {
                    res.profile.social_media = null
                }

                if ((res.academic.length === 0 && res.courses?.length === 0 ) || res.work_experience?.length === 0 || res.knowledge?.length === 0 || isEmpty(res?.profile?.about_me ) || res?.profile?.social_media === null) {
                    if (completProfile.getPrimitive()) {
                        completProfile.getPrimitive() <= 3 && completProfile.setPrimitive(Number(completProfile.getPrimitive()) + 1 )
                    } else {
                        completProfile.setPrimitive(1)
                    }
                   completProfile.getPrimitive() <= 3 && setWorkerPendingData(res)
                }
            }

        },() => {});
    }

    const getCategories = () => {
        let filter = {
            page: 1,
            application_alias: Env.application_alias,
            application: Env.application_alias,
            category_id: props?.vacancy?.desired_courses_categories[0].id,
            city : fullDataCurriculum.getObject()?.profile?.address?.city,
            state_abbrev: fullDataCurriculum.getObject()?.profile?.address?.state_abbrev,
        }
        api.post(`courses/search`, filter, (res) => {
            let aux = {}

            if (res.courses.length > 0) {
                aux.name = res.courses[0]?.category_name
                aux.id = res.courses[0].category_id
                aux.have_on_the_plataform = true
                setRecommendedCourse(aux)
            } else {
                aux = props?.vacancy?.desired_courses_categories[0]
                aux.have_on_the_plataform = false
                setRecommendedCourse(aux)
            }
        }, (err) => {})
    }

    const actionModalCourses = (e) => {
        if (e) {
            window.open(`/buscar-cursos/?category_id=${recommendedCourse?.id}`, "_blank") ;
        }
        setShowCourses(false) ;
        setRecommendedCourse(null);
        props.removeFromList();
        history.push(`/vagas-de-emprego/`)
    }

    const payPeriod = () => {
        if (props?.vacancy) {
            let found = payType.find(el => el.value === props?.vacancy?.pay_period)
            return found?.label
        }
    }

    const openNotification = () => {
        notification.open({
            message: <strong style={{color: '#3e3e3e'}}>Ops, parece que você não vai conseguir se aplicar a esta vaga )=</strong>,
            description: <span style={{color: '#7c7c7c'}}>Esta vaga é uma vaga de jovem aprendiz, apenas menores de 24 anos podem se aplicar</span>,
            duration: 0,
            style: {background: "#fff1f1"}
        });
    };
    const youngApprenticeVacancy = () => {
        if(user) {
            let user_age = moment().diff(user.birthday, 'years');
            if(user_age > 24 && props.vacancy.job_type.includes("Aprendiz")) {
                openNotification()
            }
        }
    }

    useEffect(() => {
        if (props?.vacancy?.desired_courses_categories?.length > 0) {
            getCategories()
        }
        if (props.vacancy?.id) {
            youngApprenticeVacancy()
        }
    }, [props.vacancy?.id])

    return (
        <>
            <ModalAlertRegisterCurriculum visible={alertRegisterCurriculum} close={() => setAlertRegisterCurriculum(false)}/>
            <Drawer
                className="profile-drawer"
                placement="right"
                closable={false}
                onClose={() => {props.setVisible(false); setRecommendedCourse(null) } }
                visible={props.visible}
            >

                <RegisterPageModal profile={'worker'} visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />

                <div style={{overflow: 'auto', padding: 24, height: window.innerHeight}} id={'scrollDivDrawer'}>
                    <Spin spinning={props.loading || loading} tip="Carregando...">
                        <React.Fragment>
                            <div className={'mainComponentPanel'} style={{backgroundColor: "white"}}>
                                <div>
                                    <div>
                                        <span style={{fontSize: 19}}> Informações da Publicação de Vaga </span>
                                    </div>

                                    <div style={{borderBottomWidth: 1, marginTop: 10, borderBottomColor: "gainsboro"}}>
                                        <div style={{alignItems: "center", display: 'flex'}}>
                                            <img style={{width: 60, height: 60, borderRadius: 30}} src={props.vacancy?.job_avatar? props.vacancy.job_avatar : Images.case_img}/>
                                            <div style={{marginLeft: 15}}>
                                                <div>
                                                    <span style={{fontSize: 17, color: "black"}}>Empresa </span>
                                                </div>
                                                <div>
                                                    <a href={`/empresa/${props.vacancy?.company_id}`} target={"_blank"} className='da-flex' style={{fontSize: 16, gap:5, color: '#000000b8'}}>
                                                        <span>{props.vacancy?.business_name}</span>
                                                        <RiShareBoxFill/>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider/>

                                    {props.vacancy?.job_image && <div style={{width: "100%", marginBottom: 20, display: 'flex', justifyContent: 'center'}}>
                                        <img src={props.vacancy?.job_image} style={{maxWidth: "100%", borderRadius: 5, boxShadow: "1px 1px 5px 0px #75757540"}}/>
                                    </div>}

                                    {props.vacancy?.job_video && <div className='iframeDiv'>
                                        <iframe src={props.vacancy?.job_video}
                                                allow='autoplay; encrypted-media'
                                                allowFullScreen
                                                title='video'
                                        />
                                    </div>}

                                    {props.vacancy?.closed_at &&<Row gutter={[16,32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                                        <Col xs={24} className='vacancyResumeTags' >
                                            <FaCalendarCheck style={{color: 'red'}}/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Encerrado em  </span>
                                                <span className='tagAnswer' style={{color: 'red'}}> {moment(props.vacancy?.closed_at).format("DD/MM/YYYY")} </span>
                                            </div>
                                        </Col>
                                    </Row>}
                                    {props.vacancy?.postponed &&
                                        <div className="d-flex">
                                            <div className="d-flex align-items-center" style={{margin: 8, background: "#e7f0ff", padding: 5, borderRadius: 5, color: "#1c69b8"}}>
                                                <FaClock size={18} style={{marginRight: 5}}/>
                                                <span style={{fontSize: 16}}>
                                                    {props.vacancy?.postponed ? "Vaga adiada" : ""}
                                                </span>
                                            </div>
                                        </div>
                                    }

                                    <Row gutter={[16,32]} style={{margin: 0}}>
                                        <Col xs={24} style={{paddingTop:0}} className='vacancyResumeTags'>
                                            <FaIdCardAlt style={{marginTop:5}}/>
                                            <div style={{display: 'flex', flexFlow: 'column', width: "100%"}}>
                                                <div className='da-flex justify-content-between'>
                                                    <span className='tagQuestion'> Cargo </span>
                                                    <ShareTo idVacancy={props?.vacancy?.id} type={'jobInformation'} sizeIcon={18}/>
                                                </div>

                                                <span style={{textOverflow: 'ellipsis', color: "#52c41a"}}> {props.vacancy?.occupation_title? props.vacancy?.occupation_title : props.vacancy?.occupation_name} </span>
                                                <div style={{color: "gray"}}>
                                                    {props.vacancy?.occupation_title&& "(" + props.vacancy?.occupation_name + ")" }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16,32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                                        <Col xs={24} lg={12} className='vacancyResumeTags' >
                                            <FaCodepen/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Tipo de cargo </span>
                                                <span className='tagAnswer'> {props.vacancy?.job_type} </span>
                                            </div>
                                        </Col>

                                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaBriefcase/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Quantidade de vagas </span>
                                                <span className='tagAnswer'> {props.vacancy?.job_openings} </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16,32]} style={{margin: 0}}>
                                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaDollarSign/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Salário </span>
                                                <div>
                                                    <span className='tagAnswer'> {props.vacancy?.pay? globalMaskReal(props.vacancy?.pay) : "Não informado"} </span>
                                                    {props.vacancy?.pay && <span style={{fontSize:12, color: '#919191'}}> / {payPeriod()}</span>}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaRegCalendarAlt/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Data da publicação </span>
                                                <span className='tagAnswer'> {moment(props.vacancy?.start_date).format("DD/MM/YYYY")}</span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{backgroundColor: "#fafafad4", margin: 0}} gutter={[16,32]}>
                                        <Col xs={24} className='vacancyResumeTags'>
                                            <FaRegThumbsUp/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Benefícios  </span>
                                                <div style={{display: "flex", flexWrap: 'wrap'}}>
                                                    {props.vacancy?.incentives.map((item, index) =>
                                                            <div key={index} style={{padding: 3, paddingRight: 8, paddingLeft: 8, backgroundColor: "#dbfae2 ", border: "1px solid #1f9738", color: "#1f9738", borderRadius: 5, margin: 5}}>
                                            <span>
                                                {item}
                                            </span>
                                                            </div>
                                                    )}
                                                    {props.vacancy?.incentives.length === 0 &&
                                                        <div style={{padding: 4, paddingRight: 8, paddingLeft: 8, backgroundColor: "#d3d3d36e ", color: "#9e9e9e", borderRadius: 5, margin: 5}}>
                                                            Nenhum beneficio informado
                                                        </div>}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16,32]} style={{margin: 0}}>
                                        <Col xs={24}  className='vacancyResumeTags'>
                                            <FaRegFileAlt/>
                                            <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Descrição da vaga </span>
                                                <span className='tagAnswer'> {props.vacancy?.job_description? props.vacancy?.job_description : "Nenhuma descrição inserida" } </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16,32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                                        <Col xs={24} className='vacancyResumeTags'>
                                            <FaRegMap/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Endereço a ser realizado </span>
                                                {props.vacancy?.work_place === 'mobile'?
                                                    <span> Trabalho Remoto / Home Office </span>
                                                    :
                                                    (props.vacancy?.work_place === 'itinerant' ?
                                                        <span> Sem endereço fixo </span>
                                                        :
                                                        (props.vacancy?.work_address?
                                                            <div>
                                                                {props.vacancy?.hide_address === true ?
                                                                    <span> <strong> Cidade - </strong> {props.vacancy?.work_address.city} </span>
                                                                    :
                                                                    <div>
                                                                        <span> <strong> Rua - </strong> {props.vacancy?.work_address.street} </span> <br/>
                                                                        <span> <strong> Nº - </strong> {props.vacancy?.work_address.street_number} </span><br/>
                                                                        <span> <strong> Bairro - </strong> {props.vacancy?.work_address.district} </span><br/>
                                                                        <span> <strong> Cidade - </strong> {props.vacancy?.work_address.city} </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                            : null))}
                                            </div>
                                        </Col>
                                    </Row>

                                    {props.vacancy?.schedule&&<Row gutter={[16,32]} style={{margin: 0}}>
                                        <Col xs={24}className='vacancyResumeTags'>
                                            <FaRegClock/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Horário de trabalho </span>
                                                <span className='tagAnswer'> {props.vacancy?.schedule}</span>
                                            </div>
                                        </Col>
                                    </Row>}
                                </div>


                                {props.vacancy?.apply_message&&<Row gutter={[16,32]} style={{margin: 0}}>
                                    <Col xs={24}  className='vacancyResumeTags'>
                                        <MdMore/>
                                        <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                            <span className='tagQuestion'> Informação adicional da vaga </span>
                                            <span className='tagAnswer'> {props.vacancy?.apply_message} </span>
                                        </div>
                                    </Col>
                                </Row>}

                                <div style={{padding: 15, width: "100%"}}>
                                    <div> <span style={{fontSize: 19}}> Requisito aos candidatos </span> <Divider style={{marginTop: 10}}/> </div>

                                    <Row style={{backgroundColor: "#fafafad4", margin: 0}} gutter={[16,32]}>
                                        <Col xs={24} className='vacancyResumeTags'>
                                            <FaTools/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Habilidades desejadas  </span>
                                                <div style={{display: "flex", flexWrap: 'wrap'}}>
                                                    {props.vacancy?.desired_skills?.map((item, index) =>
                                                            <div key={index} style={{padding: 3, paddingRight: 8, paddingLeft: 8, backgroundColor: "#e7f3fb ", border: "1px solid #005c9f", color: "#005c9f", borderRadius: 5, margin: 5}}>
                                            <span>
                                                {item}
                                            </span>
                                                            </div>
                                                    )}
                                                    {props.vacancy?.desired_skills?.length === 0 &&
                                                        <div style={{padding: 4, paddingRight: 8, paddingLeft: 8,backgroundColor: "#d3d3d36e ", color: "#9e9e9e", borderRadius: 5, margin: 5}}>
                                                            Nenhum habilidade informada
                                                        </div>}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    {props?.vacancy?.desired_courses_categories?.length > 0 &&
                                        <Row style={{margin:0}} gutter={[16,32]}>
                                            <Col xs={24} className='vacancyResumeTags'>
                                                <FaBookOpen/>
                                                <div>
                                                    <div style={{display:"flex", gap:5}}>
                                                    <span className={'tagQuestion'}>
                                                        Desejado capacitação em cursos de
                                                    </span>
                                                    </div>
                                                    <div style={{color:'#808080'}}>
                                                        {props?.vacancy?.desired_courses_categories[0].name}
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    }

                                    <Row gutter={[16,32]} style={{margin: 0}}>
                                        <Col xs={24} lg={12} className='vacancyResumeTags' >
                                            <FaGraduationCap/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Escolaridade mínima </span>
                                                <span className='tagAnswer'> {props.vacancy?.schooling? props.vacancy?.schooling : "Nenhuma"} </span>
                                            </div>
                                        </Col>

                                        <Col xs={24} lg={12}  className='vacancyResumeTags'>
                                            <FaWheelchair/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Vaga destinada a PcD. </span>
                                                <span className='tagAnswer'> {props.vacancy?.disabilities.length > 0? "Sim" : "Não"} </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row gutter={[16,32]} style={{margin: 0,}}>
                                        {props.vacancy?.driver_license &&<Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaCar/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'>Deve possuir CNH?  </span>
                                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                                    <span>{props.vacancy?.driver_license}</span>
                                                </div>
                                            </div>
                                        </Col>}
                                    </Row>
                                    <Row gutter={[16,32]} style={{margin: 0, backgroundColor: "#fafafad4", }}>
                                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaLanguage/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'>Deve possuir fluência em outro Idioma?  </span>
                                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                                    {props.vacancy?.languages.map((item, index) => <span key={index} className='tagAnswer'> {item}{index === (props.vacancy?.languages.length - 1)? '.' : ','}</span>)}
                                                    {props.vacancy?.languages.length === 0&& "Não"}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaRegAddressBook/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Possuir experiência no cargo. </span>
                                                <span className='tagAnswer'> {props.vacancy?.occupation_cbo_req ? "Sim" : "Não"}</span>
                                            </div>
                                        </Col>
                                    </Row>


                                    {props.vacancy?.available_travel &&<Row gutter={[16,32]} style={{margin: 0}}>
                                        <Col xs={24}  className='vacancyResumeTags'>
                                            <Icon type='caret-right'/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Ter disponibilidade para realizar viagens </span>
                                                <span className='tagAnswer'> {props.vacancy?.available_travel? "Sim" : "Não"} </span>
                                            </div>
                                        </Col>
                                    </Row>}

                                    {props.vacancy?.available_sleep_duty&&<Row gutter={[16,32]} style={{margin: 0, backgroundColor: "#fafafad4", }}>
                                        <Col xs={24}  className='vacancyResumeTags'>
                                            <Icon type='caret-right'/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Ter disponibilidade para dormir no local de trabalho </span>
                                                <span className='tagAnswer'> {props.vacancy?.available_sleep_duty? "Sim" : "Não"} </span>
                                            </div>
                                        </Col>
                                    </Row>}

                                    {props.vacancy?.available_leave_home&&<Row gutter={[16,32]} style={{margin: 0}}>
                                        <Col xs={24}  className='vacancyResumeTags'>
                                            <Icon type='caret-right'/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'>Ter disponibilidade para ausentar-se de seu domicílio por longos períodos </span>
                                                <span className='tagAnswer'> {props.vacancy?.available_leave_home? "Sim" : "Não"} </span>
                                            </div>
                                        </Col>
                                    </Row>}

                                </div>
                            </div>

                            <Row>
                                <Button onClick={() => !props.applied && setVisible(true)} className="gx-btn" style={{backgroundColor:  props.applied ? '#1680ce' : "#68bb23", borderColor:  props.applied ? '#1680ce' : "#68bb23", color: 'white', fontWeight: 'bold'}} block>
                                    {props.applied? 'Currículo já enviado' : 'Enviar meu Currículo'}
                                </Button>
                            </Row>

                        </React.Fragment>
                    </Spin>
                </div>

                {workerPendingData?.user_id !== null &&
                    <Modal className='complet-your-profile' visible={workerPendingData} width={620} closable={false} style={{top:20}} okText={<span style={{display:"flex", alignItems:"center", gap:10}}>Aumentar minhas chances<FaRocket/></span>} cancelText='Talvez depois' onOk={()=> (window.open("/painel/prestador/editar-meu-curriculo", "_blank"))} onCancel={()=> setWorkerPendingData(false)}>
                        <Row>
                            <h2 style={{fontWeight:600, textAlign:"center", marginBottom:0}}>Aumente suas chances de contratação!</h2>
                            <div style={{display:"flex", justifyContent:"center", height:220}}>
                                <img src={Images.completProfile} alt='Complet your profile'/>
                            </div>
                            <div style={{padding:16}}>
                                <div style={{textAlign:"justify", fontSize:16}}>
                                    Parece que seu currículo <strong style={{color:'#000000a6'}}>não está completo</strong>, um currículo mais completo <strong style={{color:'#000000a6'}}>aumenta suas chances de contratação!</strong>
                                </div>
                                <div style={{margin:'1em 0px', fontSize:16}} >
                                    Turbine seu currículo:
                                </div>
                                <div className='d-flex flex-wrap'>
                                    {workerPendingData?.work_experience?.length === 0 &&
                                        <Col xs={24} onClick={()=> redirectToIncompleteResume(1)} className='is-missing-card'>
                                            <Link to="/painel/prestador/editar-meu-curriculo" target='_blank'>
                                                <Col md={2}>
                                                    <FaBusinessTime className='icon-card' size={24}/>
                                                </Col>

                                                <Col md={18}>
                                                    <div className='text-card primary-color'>Experiência ou Pretensão</div>
                                                    <div className='text-subtitle'>Conte sobre suas experiências profissionais ou informe as suas pretensões</div>
                                                </Col>
                                                <Col md={2}>
                                                    <FaArrowRight className='rotate-arrow' size={20}/>
                                                </Col>
                                            </Link>
                                        </Col>
                                    }
                                    {(workerPendingData?.academic?.length === 0 && workerPendingData?.courses?.length === 0) &&
                                        <Col xs={24} onClick={()=> redirectToIncompleteResume(2)} className='is-missing-card'>
                                            <Link to="/painel/prestador/editar-meu-curriculo" target='_blank'>
                                                <Col md={2}>
                                                    <FaTools className='icon-card' size={24}/>
                                                </Col>

                                                <Col md={18}>
                                                    <div className='text-card primary-color'>Formação Acadêmica ou curso técnico</div>
                                                    <div className='text-subtitle'>Informe se tem algum curso ou faculdade</div>
                                                </Col>
                                                <Col md={2}>
                                                    <FaArrowRight className='rotate-arrow'  size={20}/>
                                                </Col>
                                            </Link>
                                        </Col>
                                    }
                                    {workerPendingData?.knowledge?.length === 0 &&
                                        <Col xs={24} onClick={()=> redirectToIncompleteResume(5)} className='is-missing-card'>
                                            <Link to="/painel/prestador/editar-meu-curriculo" target='_blank'>
                                                <Col md={2}>
                                                    <FaMedal className='icon-card' size={24}/>
                                                </Col>
                                                <Col md={18}>
                                                    <div>
                                                        <div className='text-card primary-color'>Competências</div>
                                                        <div className='text-subtitle'>Informe alguns de seus conhecimentos e habilidades!</div>
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <FaArrowRight className='rotate-arrow' size={20}/>
                                                </Col>
                                            </Link>
                                        </Col>
                                    }
                                    {workerPendingData?.profile?.about_me === "" &&
                                        <Col xs={24} onClick={()=> redirectToIncompleteResume(6)} className='is-missing-card'>
                                            <Link to="/painel/prestador/editar-meu-curriculo" target='_blank'>
                                                <Col md={2}>
                                                    <FaStream className='icon-card' size={24}/>
                                                </Col>

                                                <Col md={18}>
                                                    <div className='text-card primary-color'>Sobre mim</div>
                                                    <div className='text-subtitle'>Escreva um pouco sobre você!</div>
                                                </Col>
                                                <Col md={2}>
                                                    <FaArrowRight className='rotate-arrow'  size={20}/>
                                                </Col>
                                            </Link>
                                        </Col>
                                    }

                                    {workerPendingData?.profile?.social_media === null &&
                                        <Col xs={24} onClick={()=> redirectToIncompleteResume(7)} className='is-missing-card'>
                                            <Link to="/painel/prestador/editar-meu-curriculo" target='_blank'>
                                                <Col md={2}>
                                                    <FaWifi className='icon-card' size={24}/>
                                                </Col>

                                                <Col md={18}>
                                                    <div className='text-card primary-color'>Mídias sociais</div>
                                                    <div className='text-subtitle'>Caso queira, adicione suas mídias sociais em seu currículo </div>
                                                </Col>
                                                <Col md={2}>
                                                    <FaArrowRight className='rotate-arrow' size={20}/>
                                                </Col>
                                            </Link>
                                        </Col>
                                    }
                                </div>
                            </div>
                        </Row>
                    </Modal>
                }

                <Modal className='requestModal'
                       width={620}
                       style={{padding:"0 20px 20px "}}
                       visible={showCourses}
                       destroyOnClose
                       title={'Aumente suas chances!'}
                       onOk={() => actionModalCourses(true)}
                       onCancel={() => actionModalCourses(false)}
                       okText={'Ver cursos'}
                       cancelText={'Talvez depois'}
                >
                    <div style={{width:250, margin: "auto"}}>
                        <img src={Images.course} alt="course"/>
                    </div>
                    <span style={{fontSize: 15, textAlign: "justify"}}>
                    Temos disponível em nossa plataforma cursos da área de <strong>{recommendedCourse&& recommendedCourse.name}</strong> que o contratante gostaria que os candidatos desta vaga tivessem capacitação, veja agora e aumente sua chance de contratação!
                </span>
                </Modal>

                <Modal className={'requestModal'}
                       width={620}
                       visible={visible}
                       title={'Enviar Currículo'}
                       confirmLoading={recruitLoading}
                       onOk={() => handleApply()}
                       onCancel={() => setVisible(false)}
                       cancelText={'Cancelar'}
                       okText={(user?.user_id && hasProfileStorage.getPrimitive()) ? 'Enviar meu Currículo' : 'Cadastrar pelo Site'}>

                    {(user?.user_id && hasProfileStorage?.getPrimitive()) ?
                        <div>
                            <span style={{fontSize: 17, fontWeight: 'bold'}}> Deseja enviar seu currículo para esta vaga? </span> <br/> <br/>
                            <span style={{fontSize: 15}}> Ao enviar seu currículo para uma vaga, seus dados de contato serão disponibilizados ao recrutador </span>
                        </div>

                        : (user?.user_id)?
                            <div>
                                <span style={{fontSize: 17, fontWeight: 'bold'}}> Falta só o cadastro de seu currículo! </span> <br/> <br/>
                                <span style={{fontSize: 15}}>Realize o cadastro do seu currículo para enviar! </span><br/> <br/>
                                <Button className={'ant-btn-primary'} onClick={() => history.push('/painel/prestador/editar-meu-curriculo')} style={{height: 35, marginBottom: 10, boxShadow: '0 2px 0 rgba(0, 0, 0, 0.06)'}}>
                                    Cadastrar Currículo
                                </Button>
                            </div>
                            :
                            <div>
                                <span style={{fontSize: 17, fontWeight: 'bold'}}> Se interessou por esta vaga? </span> <br/> <br/>
                                <span style={{fontSize: 15}}>Cadastre seu currículo em nossa plataforma e envie para as vagas que se interessar. </span><br/> <br/>

                                <Row>
                                    <Col xs={24} md={8} style={{textAlign: 'center'}}>
                                        <img style={{height: 140}} src={Images.app_img}/>
                                    </Col>
                                    <Col xs={24} md={16} style={{margin: 'auto', marginBottom: 20}}>
                                        <div style={{textAlign: 'center'}}>
                                            <span style={{fontSize: 15}}> Você tambem pode fazer isso pelo nosso aplicativo! </span>

                                            <div style={{display: 'flex', flexFlow: 'column', marginTop: 20, justifyContent: 'center', }}>
                                                <Button onClick={() => window.redirectWorker(Env.worker_play)} style={{height: 35, marginBottom: 10, boxShadow: '0 2px 0 rgba(0, 0, 0, 0.06)'}}>
                                                    <div>
                                                        <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.gplay_icon}/> Disponível no <strong> Google Play </strong>
                                                    </div>
                                                </Button>

                                                <Button onClick={() => window.redirectWorker(Env.worker_apple)} style={{height: 35,  boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 3px 2px'}}>
                                                    <div>
                                                        <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.apple_icon}/> Disponível na <strong> App Store </strong>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </div> }
                </Modal>
            </Drawer>
        </>

    );
};

WorkerOpportunityDrawer.propTypes = {
    cboId: PropTypes.any,
    id: PropTypes.any,
    setVisible: PropTypes.any,
    visible: PropTypes.any,
    recruited: PropTypes.any,
    favorite: PropTypes.any,
    handleFavorite: PropTypes.any,
    disregardCurriculum: PropTypes.any
};

export default WorkerOpportunityDrawer;
