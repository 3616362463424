import React, {useEffect, useRef, useState} from 'react'
import {Images} from "../../Helpers/Images";
import {Button, Col, message} from "antd";
import NumberFormat from "react-number-format";
import useApi from "../../Hooks/Api";
import {useDispatch, useSelector} from "react-redux";
import {maskPhone} from "../../Helpers/Functions";
import {FaArrowLeft, FaRedo} from "react-icons/all";
import {Env} from "../../Helpers/Env";
import Loading from "../../Components/Generic/Loading";

const VerificationCodeComponent = ({setShowScreen, showScreen,  resumeRegister, changeNumber}) => {
    const api = useApi();
    const user = useSelector(state => state).authReducer;
    const userPhone = window.getPhoneNumbersWithoutCodeCountry(user.phone);
    const [data, setData] = useState({cellphone_number: changeNumber? '' : window.getPhoneNumbersWithoutCodeCountry(user.phone), application: Env.application_alias});

    const [resend, setResend] = useState(false);
    const [stepVerification, setStepVerification] = useState(`number`);
    const [seconds, setSeconds] = useState(15);
    const [countHandleResend, setCountHandleResend] = useState(0);
    const dispatch = useDispatch();
    const intervalDecreaseRef = useRef()

    const validateNumber = () => {
        if(!user.mobile_verified || (user.mobile_verified && userPhone !== data?.cellphone_number)) { // só chamar se o cara nao tiver validado o telefone ou se o telefone validado for diferente do q ele esta tentando agora
            if(window.validateMobilePhone(data.cellphone_number) && data.cellphone_number.length === 11) {
                setResend(true);
                message.loading('Enviando SMS', 2.5);

                api.post('access/mobile-verify', {cellphone_number: `+55${data.cellphone_number}`, application: Env.application_alias}, (res) => {
                    setStepVerification(`code`);
                    decreaseSeconds();
                    setData({...data, token: res.token})
                }, (e) => {
                    message.error(e.message || 'Não foi possível validar, tente novamente');
                });

            } else {
                message.warning('Digite um número válido');
            }
        } else {
            message.warning('O número de telefone ' + userPhone + ' já está validado!');
        }
    };

    const handleResend = async () => {
        let sendData = {}
        const {cellphone_number, token, application} = data

        sendData.cellphone_number = `+55${cellphone_number}`
        sendData.token = token
        sendData.application = application

        setCountHandleResend(countHandleResend + 1)

        if (!resend) {
            setResend(true);
            decreaseSeconds();
            api.post('access/mobile-verify', sendData, (res) => {
                message.success('Código reenviado para o número ' + maskPhone(data.cellphone_number));
                setData({...data, token: res.token})

            }, (e) => {
                message.error(e.message || 'Não foi possível reenviar o código, tente novamente');
            });
        }
    };

    const validateCode = (code) => {
        setStepVerification('loading')
        let sendData = {}
        let {cellphone_number, application, token} = data

        sendData.cellphone_number = `+55${cellphone_number}`
        sendData.code = code
        sendData.token = token
        sendData.application = application

        api.post('access/mobile-confirm', sendData, () => {
            window.gtagService('event', 'phone_verification_confirmed')
            dispatch({type: 'update_user', data: {mobile_verified: true}});
            setTimeout(() => {
                resumeRegister()
            },[400]);
        }, (e) => {
            message.destroy();
            message.error(e.message || 'Código inválido');
            window.gtagService('event', 'phone_verification_failed')
            setStepVerification(`code`)
        });
    };

    const decreaseSeconds = () => {
        intervalDecreaseRef.current = setInterval(() => {
            setSeconds((value) => {
                if (value === 1) {
                    setResend(false);
                    setSeconds(15);
                    clearInterval(intervalDecreaseRef.current);
                    return 0;
                }
                return value - 1
            })
          }, 1000)
    }

    const handleBack = () => {
        if (stepVerification === `code`) {
            setStepVerification(`number`);
        }
        else if (showScreen) {
            setShowScreen();
        }
    }

    useEffect(() => {
        if(showScreen) {
            window.gtagService('event', 'phone_verification_start')
        }
    },[])

    return (
        <div style={{paddingTop: 20, paddingBottom: 20}}>
            {(stepVerification === 'number' || stepVerification === 'code') ?
                <>
                    <div style={{display:'flex', flexWrap: "wrap", padding:'0 16px'}}>
                        <Col xs={24} md={8}>
                            <div style={{textAlign:'center'}}>
                                <img style={{width:'100%', maxWidth: 250}} src={Images.codeImg} alt={'validation step'}/>
                            </div>
                        </Col>

                        <Col xs={24} md={16} style={{alignSelf:'center'}}>
                            {stepVerification === `number` &&
                                <div>
                                    <h3 style={{fontSize: 20, textAlign: 'center'}}>
                                       Informe seu número de telefone
                                    </h3>
                                    <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                                        <Col xs={24} md={24}>
                                            <NumberFormat
                                                placeholder={'(99) 99999-9999'}
                                                value={data?.cellphone_number}
                                                onValueChange={(e) => setData({...data, cellphone_number: e.value})}
                                                style={{width: '100%', textAlign: 'center'}}
                                                className='ant-input'
                                                format={'(##) #####-####'} mask="_"
                                            />
                                        </Col>
                                        {userPhone !== data?.cellphone_number && <div style={{paddingLeft: 10, paddingRight: 10, textAlign: 'right'}}> <span style={{color: '#ff5722', fontWeight: '300', fontSize: 12, fontStyle: 'italic'}}>Seu número de telefone será alterado para o digitado acima</span> </div>}
                                    </div>
                                </div>
                            }

                            {stepVerification === `code` &&
                                <div>
                                    <div style={{textAlign:'center'}}>
                                        <h3 style={{fontSize:20}}>
                                            Informe o código enviado para o número <br/> <span className={"primary-color"}> {window.maskViewPhone(data?.cellphone_number)} </span>
                                        </h3>
                                    </div>
                                    <div style={{display:'flex', justifyContent: 'center'}}>
                                        <Col xs={24} md={24}>
                                            <NumberFormat
                                                className='ant-input'
                                                format={'### ###'}
                                                value={data?.code}
                                                onValueChange={(e) => {
                                                    setData({...data, code: e.value})
                                                    e.value.length === 6 && validateCode(e.value)
                                                }}
                                                style={{width:'100%', textAlign: 'center'}}
                                                placeholder={'Digite aqui'}
                                            />
                                        </Col>
                                    </div>
                                </div>
                            }

                            <div style={{display:"flex", justifyContent:"space-between", padding: 10, marginTop:10}}>
                                <Button className='ant-btn-blue-ghost' onClick={handleBack}>
                                    <div style={{display: 'flex', alignItems:'center', gap: 5}}>
                                        <FaArrowLeft/>
                                        <span>Voltar</span>
                                    </div>
                                </Button>
                                {stepVerification === `number` &&
                                    <Button loading={api.loading} style={{opacity: resend && '0.6'}} disabled={resend} type={"primary"} onClick={() => validateNumber()}>
                                        Enviar código
                                    </Button>
                                }
                                {(stepVerification === `code` && countHandleResend <= 3) &&
                                    <Button disabled={resend} onClick={() => handleResend()}>
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems:'center', gap:10}}>
                                            <FaRedo size={12}/>
                                            <span>Reenviar código</span>
                                        </div>
                                    </Button>
                                }
                            </div>
                            {(countHandleResend >= 1 && (stepVerification === `code` || stepVerification === `number`)) &&<div style={{display:'flex', justifyContent:'end', marginTop:10}}>
                                <a onClick={() => {
                                    window.gtagService('event', 'phone_verification_help')
                                }} target={'_blank'} rel="noreferrer" href={window.innerWidth < 768? 'https://wa.me/' + Env.number : 'https://web.whatsapp.com/send?phone=' + Env.number} >
                                    <p style={{color:'#3d8bfd', margin:0, textAlign: "end", fontSize:13}}>
                                        Não recebeu o código? Entre em contato conosco!
                                    </p>
                                </a>
                            </div> }

                            {resend &&<div style={{minHeight:24, marginTop:10}}>
                                <p style={{color:'#3d8bfd', margin:0, textAlign: "end", fontSize:13}}>
                                    Você poderá reenviar o código novamente dentro de <strong>{seconds}</strong> segundos.
                                </p>
                            </div> }

                        </Col>
                    </div>
                </>
                :
                <div style={{marginBottom:'15px'}}>
                    <p style={{fontSize:20, textAlign: 'center'}}>Finalizando cadastro...</p>
                    <Loading/>
                </div>
            }
        </div>
    )
}

export default VerificationCodeComponent
