import React, {useEffect, useState} from "react";
import {Icon, Row} from "antd";
import useLocalStorage from "../../../../Hooks/Storage";
import AboutComponent from "./WorkerResumeComponent/AboutComponent";
import ExperienceComponent from "./WorkerResumeComponent/ExperienceComponent";
import GradComponent from "./WorkerResumeComponent/GradComponent";
import CoursesComponent from "./WorkerResumeComponent/CoursesComponent";
import LocationsComponent from "./WorkerResumeComponent/LocationsComponent";
import ContactMeComponent from "./WorkerResumeComponent/ContactMeComponent";
import {
    FaAlignLeft, FaAngleRight, FaArrowLeft,
    FaBook,
    FaBusinessTime,
    FaMap,
    FaMedal,
    FaPhone,
    FaTools,
    FaUserTie
} from "react-icons/fa";
import {useHistory} from "react-router-dom";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";
import AboutMeComponent from "./WorkerResumeComponent/AboutMeComponent";
import SkillsComponent from "./WorkerResumeComponent/SkillsComponent";
import StepCustom from "./Components/StepCustom";
import {useSelector} from "react-redux";
import useApi from "../../../../Hooks/Api";


const WorkerResumeComponent = () => {

    const [step, setStep] = useState(-1);
    const [hasProfile, setHasProfile] = useState(false);
    const lastStep = useLocalStorage(StorageVariables.curriculumStep);
    const history = useHistory();
    const user = useSelector((state) => state).authReducer;
    const api = useApi()

    const handleChangeStep = (e) => {
        setStep(e);
        lastStep.setPrimitive(e);
        window.scrollToElement('body');
    };

    useEffect(() => {
        if(lastStep.getPrimitive()) {
            setStep(Number(lastStep.getPrimitive()))
            if (Number(lastStep.getPrimitive()) !== 0) {
                api.get(`sine/user/${user?.user_id}/full`, (res) => {
                    if (res.user_id !== null) {
                        setHasProfile(true)
                    }
                },(e) => {});
            }
        } else {
            setStep(0);
        }
    },[]);

    return <Row justify={'center'} className={'mainComponentPanel'} >
        <div style={{display:'inline-block'}}>
            <div className={'back-button'} onClick={() => history.push('/painel')}>
                <FaArrowLeft/>
                <span> Voltar ao Painel </span>
            </div>
        </div>

        <div className='steps-custom'>
            <StepCustom stepId={0} useStep={step} hasProfile={hasProfile} icon={<FaUserTie size={18}/>} changeStep={(e) => handleChangeStep(e)} className={`step `} title={"Geral"} md={4} chevron={<FaAngleRight />}/>
            <StepCustom stepId={1} useStep={step} hasProfile={hasProfile} icon={<FaBusinessTime size={18}/>} changeStep={(e) => handleChangeStep(e)} className={'step'} title={"Experiências"} md={5} chevron={<FaAngleRight/>}/>
            <StepCustom stepId={2} useStep={step} hasProfile={hasProfile} icon={<FaTools size={18}/>} changeStep={(e) => handleChangeStep(e)} className={'step'} title={"Graduações"} md={5} chevron={<FaAngleRight />}/>
            <StepCustom stepId={3} useStep={step} hasProfile={hasProfile} icon={<FaBook size={18}/>} changeStep={(e) => handleChangeStep(e)} className={'step'} title={"Cursos"} md={4} chevron={<FaAngleRight />}/>
            <StepCustom stepId={4} useStep={step} hasProfile={hasProfile} icon={<FaMap size={18}/>} changeStep={(e) => handleChangeStep(e)} className={'step'} title={"Locais"} md={4} chevron={<FaAngleRight/>}/>
            <StepCustom stepId={5} useStep={step} hasProfile={hasProfile} icon={<FaMedal size={18}/>} changeStep={(e) => handleChangeStep(e)} className={'step'} title={"Competências"} md={5} chevron={<FaAngleRight />}/>
            <StepCustom stepId={6} useStep={step} hasProfile={hasProfile} icon={<FaAlignLeft size={18}/>} changeStep={(e) => handleChangeStep(e)} className={'step'} title={"Sobre"} md={4} chevron={<FaAngleRight/>}/>
            <StepCustom stepId={7} useStep={step} hasProfile={hasProfile} icon={<FaPhone size={18}/>} changeStep={(e) => handleChangeStep(e)} className={'step'} title={"Contato"} md={5}/>
        </div>

        {(step === 0) &&<AboutComponent handleChangeStep={(e) => handleChangeStep(e)} hasProfile={hasProfile} setHasProfile={(item) => setHasProfile(item)} />}
        {(step === 1) &&<ExperienceComponent handleChangeStep={(e) => handleChangeStep(e)} setHasProfile={(item) => setHasProfile(item)}/>}
        {(step === 2) &&<GradComponent handleChangeStep={(e) => handleChangeStep(e)} setHasProfile={(item) => setHasProfile(item)}/>}
        {(step === 3) &&<CoursesComponent handleChangeStep={(e) => handleChangeStep(e)} setHasProfile={(item) => setHasProfile(item)}/>}
        {(step === 4) &&<LocationsComponent handleChangeStep={(e) => handleChangeStep(e)} setHasProfile={(item) => setHasProfile(item)}/>}
        {(step === 5) &&<SkillsComponent handleChangeStep={(e) => handleChangeStep(e)} setHasProfile={(item) => setHasProfile(item)}/>}
        {(step === 6) &&<AboutMeComponent handleChangeStep={(e) => handleChangeStep(e)} setHasProfile={(item) => setHasProfile(item)}/>}
        {(step === 7) &&<ContactMeComponent handleChangeStep={(e) => handleChangeStep(e)} setHasProfile={(item) => setHasProfile(item)}/>}
    </Row>
}

export default WorkerResumeComponent;
