import React, {useState} from "react";

import {Button, Carousel, Col, Row} from "antd";
import {GoChecklist} from "react-icons/go";
import {BsPersonLinesFill} from "react-icons/bs";
import {Images} from "../../../../Helpers/Images";
import RegisterPageModal from "../../../../Pages/RegisterPageModal";
import {Env} from "../../../../Helpers/Env";


const TopComponent = (props) => {
    const [registerModal, setRegisterModal] = useState(false);

    return <Row justify={'center'} style={{position: 'relative', display: 'flex', zIndex: 1}} className={'workerPageContent'}>
        <Carousel effect="fade" autoplay={true} autoplaySpeed={5000} className={'bannersImages hideMobile500'}>
            <div>
                <div style={{backgroundSize: 'cover', height: 360, width: '100%', position: 'relative', backgroundImage: `url(${Images.workerHomePage.worker_banner_1})`}}/>
            </div>
            <div>
                <div style={{backgroundSize: 'cover', height: 360, width: '100%', position: 'relative', backgroundImage: `url(${Images.workerHomePage.worker_banner_2})`}}/>
            </div>
            <div>
                <div style={{backgroundSize: 'cover', height: 360, width: '100%', position: 'relative', backgroundImage: `url(${Images.workerHomePage.worker_banner_3})`}}/>
            </div>
        </Carousel>
        <div style={{display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'stretch'}}>
            <div className={'bannerContent'}>
                <img style={{maxHeight: 100, margin: 'auto'}} src={Images.workerHomePage.worker_register}/>
                <span className={'bigTitle'}> Busca uma nova oportunidade? </span>
                <span style={{fontSize: 16, marginBottom: 10}}> Cadastre agora seu currículo no {Env.application_title} e amplie suas possibilidades de contratação! </span>
                <div className={'topComponentButtonDiv'}>
                    <Button icon={<GoChecklist/>} size={'large'} onClick={() => setRegisterModal('regType')} style={{fontSize: 20, marginBottom: 10}} shape="round" className={'ant-btn-green'}>Cadastre-se </Button>
                </div>
            </div>
        </div>


        <RegisterPageModal profile={'worker'} visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />

    </Row>
};

export default TopComponent;
