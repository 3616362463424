import React, {useEffect, useState} from 'react';
import useLocalStorage from "../Hooks/Storage";
import {useSelector} from "react-redux";
import Loading from "../Components/Generic/Loading";
import {useHistory} from "react-router-dom";
import {StorageVariables} from "../Helpers/StoragesVariables";
import {Env} from "../Helpers/Env";


const PanelPage = (props) => {

    const preferences = useSelector(state => state).preferencesReducer;
    const panelStorage = useLocalStorage(StorageVariables.lastPanel);
    const history = useHistory();

    useEffect(() => {
        if(preferences?.panel_profile === 'client') {
            if(Env.show_client_panel) {
                history.push('/painel/cliente')
            } else {
                history.push('/painel/cliente/vagas')
            }
        } else {
            history.push('/painel/prestador')
        }
    },[]);

    return (<> <Loading/> </>)
};
export default PanelPage;
