import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    AutoComplete,
    Button,
    Carousel,
    Col,
    Collapse,
    Form,
    Icon,
    message,
    Row
} from "antd";

import useApi from "../Hooks/Api";
import {useHistory} from "react-router-dom";

import useLocalStorage from "../Hooks/Storage";
import SideBarFilter from "../Components/PageComponents/Public/SearchResumePage/SideBarFilter";
import ListHeader from "../Components/PageComponents/Public/SearchResumePage/ListHeader";
import MainListComponent from "../Components/PageComponents/Public/SearchResumePage/MainListComponent";
import {Fade} from "react-reveal";
import MapComponent from "../Components/PageComponents/Public/SearchResumePage/MapComponent";
import ResumeDrawer from "../Components/PageComponents/Public/SearchResumePage/ResumeDrawer";
import {useDispatch, useSelector} from "react-redux";
import {Images} from "../Helpers/Images";
import {StorageVariables} from "../Helpers/StoragesVariables";
import _, {debounce } from "lodash";
import {checkStateRestriction, showNotification} from "../Hooks/Helpers";
import PropTypes from "prop-types";
import moment from "moment/moment";

const google = window.google;

const SearchResumePage = (props) => {
    const history = useHistory();
    const carouselRef = useRef();
    const paginate = useRef({page: 1});
    const [loading, setLoading] = useState(false);
    const resumesList = useRef([]);
    const storage = useLocalStorage(StorageVariables.geolocation);
    let filtersQuery = window.queryStringToObject();
    const [toggle, setToggle] = useState(false);
    const lastSearch = useLocalStorage(StorageVariables.lastCurriculumParam);
    const [count, setCount] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const actualUserId = useRef('');
    const sawMapStorage = useLocalStorage(StorageVariables.sawMap);
    const dispatch = useDispatch();
    const shrinkState = useRef(false);
    const [smallLoading, setSmallLoading] = useState(false);
    const [resultSearch, setResultSearch] = useState([]);
    const [initialSearch, setInitialSearch] = useState({});
    const [formattedAddress, setFormattedAddress] = useState('')
    const [fullAddress, setFullAddress] = useState(null);
    const geocode = useRef();
    const api = useApi({customReturn: true, loadingControl: true});
    const checkLocation = useLocalStorage(StorageVariables.checkLocation);
    const user = useSelector(state => state).authReducer;
    const getLocation = () => {
        if (user) {
            if (!checkLocation?.getObject()) {
                api.get(`user/locations`, (res) => {
                    console.log(filtersQuery)
                    if (!res.exists) {
                        if (filtersQuery?.lat && filtersQuery?.long) {
                            let objToSend = {
                                "locations": [{
                                    "latitude": filtersQuery.lat,
                                    "longitude": filtersQuery.long,
                                    "time": moment()
                                }]
                            }
                            api.post(`user/locations`, objToSend, () => {
                                checkLocation.setPrimitive(true)
                            }, (e) => {});
                        }
                    } else {
                        checkLocation.setPrimitive(true)
                    }
                }, () => {

                })
            }

        }
    }
    const getData = async (loadMore, query) => {

        window.gtagService('event', 'searchSuccess');
        setLoading(true);
        const filters = {};
        if(filtersQuery.cbo_occupation_id) filters.cbo_occupation_id = filtersQuery.cbo_occupation_id; else {setLoading(false); return 1;}
        if(filtersQuery.lat) filters.latitude = filtersQuery.lat; else {setLoading(false); return 2;}
        if(filtersQuery.long) filters.longitude = filtersQuery.long; else {setLoading(false); return 2;}
        filters.page = paginate.current.page;
        filters.filters = formatValues();
        query&& (filters.filters.query = query)

        api.post('sine/search', filters, (res) => {
            getLocation()
            setCount(undefined)
            paginate.current = {nextPage: res.next_page, page: res.page};
            let aux;
            if (loadMore) {
                aux = resumesList.current.concat(res.workers);
            } else {
                aux = res.workers;
            }
            lastSearch?.setObject({cbo_name: filtersQuery.cbo_name, cbo_occupation_id: filtersQuery.cbo_occupation_id});

            resumesList.current = aux;
            setLoading(false);

            api.post('sine/search', {...filters, count_only: true},(countRes) => {
                setCount(countRes.total_count);
            },(e) => {});

        },(e) => {
            setLoading(false);
        });

        !loadMore&& window.scrollToElement('body')
    };

    const handleRecruited = () => {
        setLoading(true);
        let aux = resumesList.current;
        if((actualUserId.current?.index !== undefined && actualUserId.current?.index !== null) && aux[actualUserId.current.index]) {
            aux[actualUserId.current.index].has_feedback = true;
        }
        resumesList.current = aux;
        setLoading(false);
    }

    const handleShowDrawer = (id,recruited, index) => {
        setSmallLoading(true);
        actualUserId.current = {id: id, recruited: recruited, index: index};
        setShowModal(true);
        setTimeout(() => {
            setSmallLoading(false);
        },[500])
    };

    const addToFilter = (name,value) => {

        paginate.current.page = 1;
        if (value === null) {
            delete filtersQuery[name];
        } else {
            filtersQuery[name] = value;
        }
        const urlParams = new URLSearchParams(filtersQuery);
        history.push(`/curriculos/?${urlParams}`);
    }

    const clearFilters = () => {
        let auxStorage = storage?.getObject();
        if(auxStorage) {
            delete auxStorage.userLat;

            filtersQuery = {...auxStorage, ...lastSearch?.getObject()};
            const urlParams = new URLSearchParams(filtersQuery);
            history.push(`/curriculos/?${urlParams}`);
            getData();
        }
    }

    const addLocToFilter = () => {
        let aux = window.queryStringToObject();
        let auxStorage = null ;

        // HOTFIX PQ NO STORAGE ESTAVA SALVANDO UM ARRAY COM A LOCALIZACAO E O JEITO NOVO SALVA UM OBJETO DIRETO
        if (storage?.getObject()?.lat && storage?.getObject()?.long) {
            auxStorage = storage?.getObject() || fullAddress ;
        } else if (_.isArray(storage?.getObject()?.userLat)) {
            auxStorage = storage?.getObject()?.userLat[0] || fullAddress;
            if (storage?.getObject()?.userLat[0]?.name) {
                auxStorage.loc = storage?.getObject()?.userLat[0].name;
                delete auxStorage.name;
            }

        }
        // delete auxStorage.userLat;

        filtersQuery = {...aux, ...auxStorage, ...lastSearch?.getObject(), ...initialSearch};
        const urlParams = new URLSearchParams(filtersQuery);
        history.push(`/curriculos/?${urlParams}`);
        getData();
    };

    const handleSearch = () => {
        storage?.setObject(fullAddress)
        addToFilter("cbo_occupation_id", initialSearch.cbo_occupation_id)
        addToFilter("cbo_name", initialSearch.cbo_name)
        addLocToFilter()
    }

    const infiniteScroll = () => {
        if (paginate.current.nextPage === true) {
            paginate.current.page = paginate.current.page + 1;
            getData(true).then((item) => {
                if (item === 1) {
                    message.info({icon: <Icon type={'exclamation'}/>, content: 'Escolha uma Ocupação para buscar'})
                }
                else if (item === 2) {
                    message.info({icon: <Icon type={'exclamation'}/>, content: 'Escolha um Endereço para buscar'})
                }
            });
        }

    }

    const loadMore = () => {
        paginate.current.page = paginate.current.page + 1;
        getData(true).then((item) => {
            if (item === 1) {
                message.info({icon: <Icon type={'exclamation'}/>, content: 'Escolha uma Ocupação para buscar'})
            }
            else if (item === 2) {
                message.info({icon: <Icon type={'exclamation'}/>, content: 'Escolha um Endereço para buscar'})
            }
        });
    };

    const handleToggle = () => {
        sawMapStorage?.setPrimitive(true);
        if(toggle) {
            setToggle(!toggle);
            carouselRef.current.prev();
        } else {
            setToggle(!toggle);
            carouselRef.current.next()
        }
    };

    const formatValues = () => {
        let formattedFilters = {};
        filtersQuery = window.queryStringToObject();
        if(filtersQuery.internship) formattedFilters.internship = true;
        if(filtersQuery.is_student) formattedFilters.is_student = true;
        if(filtersQuery.courses) formattedFilters.courses = true;
        if(filtersQuery.grads) formattedFilters.grads = true;
        if(filtersQuery.post_grads) formattedFilters.post_grads = true;
        if(filtersQuery.available_sleep_duty) formattedFilters.available_sleep_duty = true;
        if(filtersQuery.available_leave_home) formattedFilters.available_leave_home = true;
        if(filtersQuery.available_travel) formattedFilters.available_travel = true;
        if(filtersQuery.exclude_imported) formattedFilters.exclude_imported = false; else formattedFilters.exclude_imported = true; //TODO TROCAR DEPOIS

        if(filtersQuery.formal_experience_min) formattedFilters.formal_experience_min = Number(filtersQuery.formal_experience_min);
        if(filtersQuery.formal_experience_max) formattedFilters.formal_experience_max = Number(filtersQuery.formal_experience_max);
        if(filtersQuery.informal_experience_min) formattedFilters.informal_experience_min = Number(filtersQuery.informal_experience_min);
        if(filtersQuery.informal_experience_max) formattedFilters.informal_experience_max = Number(filtersQuery.informal_experience_max);
        if(filtersQuery.age_min) formattedFilters.age_min = Number(filtersQuery.age_min);
        if(filtersQuery.age_max) formattedFilters.age_max = Number(filtersQuery.age_max);

        if(filtersQuery.schooling) formattedFilters.schooling = filtersQuery.schooling.replace(/\+/ig, " ").split(",");
        if(filtersQuery.disabilities === 'Alguma'){
             let aux = []
             aux.push('Alguma')
             formattedFilters.disabilities = aux
        } else {
            let aux = []
            aux.push('Nenhuma')
            formattedFilters.disabilities = aux
        }
        if(filtersQuery.vehicles) formattedFilters.vehicles = filtersQuery.vehicles.replace(/\+/ig, " ").split(",");
        if(filtersQuery.languages) formattedFilters.languages = filtersQuery.languages.replace(/\+/ig, " ").split(",");
        if(filtersQuery.driver_license) formattedFilters.driver_license = filtersQuery.driver_license;
        if(filtersQuery.availability) formattedFilters.availability = filtersQuery.availability.replace(/\+/ig, " ").split(",");
        if(filtersQuery.gender) formattedFilters.gender = filtersQuery.gender.replace(/\+/ig, " ").split(",");
        return formattedFilters;
    }

    const handleScroll = (e) => {
        if (!shrinkState.current && window.scrollY > 200) {
            shrinkState.current = true;
            dispatch({type: 'shrink_scroll'})
        } else if(shrinkState.current && window.scrollY <= 200) {
            shrinkState.current = false;
            dispatch({type: 'expand_scroll'});
        }
    };
    const getGeolocation = () => {
        const place = geocode.current.getPlace();
        if(checkStateRestriction(place)){
            setFullAddress({lat: place.geometry.location.lat(), long: place.geometry.location.lng(), loc: place.formatted_address, userLat: storage?.getObject()?.userLat});
            setFormattedAddress(place.formatted_address)
        } else {
            setFormattedAddress('')
            showNotification();
        }

    }
    const onSelectOccupation = (e) => {
        let aux = e.split(',')
        if (aux.length > 0) {
            setInitialSearch({...initialSearch, cbo_occupation_id:aux[0], cbo_name:aux[1]})
        }
    }

    const onSearchAutoComplete = (e) => {
        setInitialSearch({...initialSearch, cbo_name:e})
        autoCompleteSearch(e)
    };

    const autoCompleteSearch = useCallback(debounce( (query) => {
        if (query.length < 3) {
            if (storage?.getObject()) {
                storage.remove()
            }
            setInitialSearch({})
        }

        if (!api.loading) {
            api.get(`sine/cbo-occupations/autocomplete?query=${query}&limit=25`, (res) => {
                setResultSearch(res);
            }, (e) => {

            });
        }
    }, 700), []);

    useEffect(() => {
        if(window.location.search) {
            if (filtersQuery.lat && filtersQuery.long && filtersQuery.loc && filtersQuery.cbo_occupation_id) {
                getData();
            }
        } else if(storage?.getObject()?.lat && storage?.getObject()?.long) {
            addLocToFilter();
        } else if (_.isArray(storage?.getObject()?.userLat)) {
            // HOTFIX PQ NO STORAGE ESTAVA SALVANDO UM ARRAY COM A LOCALIZACAO E O JEITO NOVO SALVA UM OBJETO DIRETO
            if (storage?.getObject()?.userLat[0]?.lat && storage?.getObject()?.userLat[0]?.long) {
                addLocToFilter();
            }
        }

        if(window.innerWidth > 768) window.addEventListener('scroll', handleScroll, { passive: true });

        if(window.innerWidth > 768) return () => {window.removeEventListener('scroll', handleScroll);  dispatch({type: 'expand_scroll'});}

    }, []);

    useEffect(() => {
        if(!window.location.search) {
            setLoading(true);
            resumesList.current = [];
            setLoading(false);
        }
    },[window.location.search]);

    useEffect(() => {
        setTimeout(() => {
        window.scrollToElement('body');
        if(window.google) {
            geocode.current = new window.google.maps.places.Autocomplete(document.querySelector('#geocodeField'), {types: ['geocode']});
            geocode.current.addListener('place_changed', getGeolocation);
            geocode.current.setComponentRestrictions({'country': ['br']});
            geocode.current.setFields(['formatted_address', 'geometry', 'address_components']);
        }
        }, [1000])
    },[window.google]);


    return (
        <div className={`search-resume-page`} style={{ backgroundSize: '100%', backgroundRepeat: 'repeat-y', background: `url(${Images.background_1})`}}>

            {(resumesList?.current?.length === 0 && !loading && window?.location?.search?.length === 0 &&  ((storage?.getObject() && storage?.getObject()?.userLat?.length === 0) || !storage?.getObject())) ?
               <div>
                   <div>
                       <Fade top>
                           <h1 style={{fontSize:'1.75rem', fontWeight:600, textAlign:'center', margin:'32px 0'}}>Buscar Currículos</h1>
                       </Fade>
                   </div>
                   <Fade bottom delay={400}>
                       <div className='srp-initial-search'>
                           <Col xs={22} md={22} lg={18} xl={18} xxl={16} className='srp-card'>
                               <Col xs={24} md={10} lg={9} xl={8}  className='srp-title'>
                                   <div>
                                       <h2>Vamos começar!</h2>
                                   </div>

                                   <div>
                                       <img src={Images.worker} alt="workers" />
                                   </div>

                                   <Col span={24} style={{textAlign: 'center'}}>
                                       <p>Busque por currículos de todo o estado!</p>
                                   </Col>
                               </Col>

                               <Col md={14} lg={15} xl={16} className='srp-inputs'>
                                   <div>
                                       <div style={{position:'relative', marginBottom: '1.5rem'}}>
                                           <label htmlFor={'search-occupation'}>
                                               <h5 style={{color: '#455a65', fontWeight: 'bold'}}>Primeiro nos diga, qual profissão está buscando?</h5>
                                           </label>
                                           <AutoComplete
                                               size="large"
                                               value={initialSearch?.cbo_name}
                                               style={{ width: '100%'}}
                                               dataSource={resultSearch.map(item => {
                                                   return (
                                                       <AutoComplete.Option key={item.occupation_id+","+item.name} text={item.name}>
                                                           {item.name}
                                                       </AutoComplete.Option>
                                                   )
                                               })}
                                               onSearch={(e) => onSearchAutoComplete(e)}
                                               onSelect={onSelectOccupation}
                                               optionLabelProp="text"
                                           >
                                               <input type='text'
                                                      id={'search-occupation'}
                                                      placeholder={'Qual perfil você busca? Ex: Vendedor'}
                                               />
                                           </AutoComplete>
                                           {api.loading &&<Icon style={{right: 5, bottom: 14, position: 'absolute', fontSize:20}} type={"loading"} />}
                                       </div>

                                       <div style={{opacity:(!initialSearch?.cbo_occupation_id) && '0.2'}}>
                                           <label htmlFor={'geocodeField'}>
                                               <h5>Agora, para qual localidade você precisa contratar?</h5>
                                           </label>
                                           <div>
                                               <input id='geocodeField'
                                                      type='text'
                                                      onChange={(e) => setFormattedAddress(e.target.value)}
                                                      value={formattedAddress}
                                                      placeholder='Digite um Endereço'
                                                      disabled={!initialSearch?.cbo_occupation_id}
                                               />
                                           </div>
                                       </div>
                                   </div>


                                   <div>
                                       <Button className={`ant-btn-secondary ${(!initialSearch?.cbo_name || !fullAddress) ? 'opacity' :''}`}
                                               htmlType={'button'}
                                               style={{width:'100%', color:'white'}}
                                               onClick={() => handleSearch()}
                                               disabled={!initialSearch?.cbo_name || !fullAddress}>
                                           Buscar currículos
                                       </Button>
                                   </div>
                               </Col>
                           </Col>
                       </div>
                   </Fade>
               </div>
                :
                <Row style={{display: 'flex', marginTop: 35, justifyContent: 'center'}}>
                    <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
                        <Row>
                            {window.innerWidth >= 768 &&<Col  xs={0} sm={0} md={8} lg={6} style={{paddingRight: 10, paddingLeft: 10}}>
                                <SideBarFilter clearFilters={() => clearFilters()}
                                               cboName={filtersQuery.cbo_name}
                                               search={() => getData()}
                                               loading={api.loading}
                                               addLocToFilter={() => addLocToFilter()}
                                               location={filtersQuery.loc}
                                               filter={(name,value) => addToFilter(name,value)}
                                               form={props.form}
                                />
                            </Col>}
                            <Col xs={24} sm={24} md={16} lg={18} className={'listHeaderFilters'}>
                                {window.innerWidth < 768 ?
                                    <Collapse>
                                        <Collapse.Panel header={
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <span> Filtros </span>
                                                <Fade bottom when={count !== undefined}>
                                                    <div>
                                                        <span>
                                                            <strong style={{fontSize: 15}}>
                                                                {count}
                                                            </strong>
                                                            &nbsp;
                                                            resultados encontrados.
                                                        </span>
                                                    </div>
                                                </Fade>
                                            </div> } key="1">
                                            <ListHeader
                                                cboName={filtersQuery.cbo_name}
                                                addLocToFilter={() => addLocToFilter()}
                                                location={filtersQuery.loc}
                                                clearFilters={() => clearFilters()}
                                                count={count}
                                                toggle={toggle}
                                                setToggle={() => handleToggle()}
                                                form={props.form}
                                                loading={api.loading}
                                                search={(searchText) => getData(false, searchText)}
                                                filter={(name,value) => addToFilter(name,value)}
                                            />
                                        </Collapse.Panel>
                                    </Collapse>
                                    :
                                    <ListHeader
                                        cboName={filtersQuery.cbo_name}
                                        addLocToFilter={() => addLocToFilter()}
                                        location={filtersQuery.loc}
                                        clearFilters={() => clearFilters()}
                                        count={count}
                                        toggle={toggle}
                                        setToggle={() => handleToggle()}
                                        form={props.form}
                                        search={(searchText) => getData(false, searchText)}
                                        loading={api.loading}
                                        filter={(name,value) => addToFilter(name,value)}/>
                                    }
                                {(resumesList.current.length === 0 && !loading)&&
                                    <Fade>
                                        <div className={'notFoundComponent'} style={{marginTop: 30}}>
                                            <div style={{marginBottom: 0}}>
                                                <span style={{fontSize: 25}}>Não há profissionais disponíveis para essa pesquisa </span> <br/>
                                                <span style={{fontSize: 22}}>Busque por outro cargo ou busque por profissionais em outra região </span>
                                            </div>
                                            <div className={'imgDiv'}>
                                                <img src={Images.resume_not_found}/>
                                            </div>
                                        </div>
                                    </Fade>}
                                <Carousel ref={carouselRef}>
                                    <div>
                                        <MainListComponent showDrawer={(id,recruited,index) => handleShowDrawer(id,recruited,index)}
                                                           nextPage={paginate.current.nextPage}
                                                           loadMore={() => loadMore()}
                                                           cboId={filtersQuery.cbo_occupation_id}
                                                           loading={loading}
                                                           infiniteScroll={() =>infiniteScroll()}
                                                           resumesList={resumesList.current}
                                                           paginate={paginate.current}

                                        />
                                        {(paginate.current.nextPage === false && !loading) && (
                                            <div style={{display: "flex", justifyContent:"center"}}>
                                                <div style={{fontSize:20}}>
                                                    <span>Não há mais resultados de busca.</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {toggle&& <MapComponent showDrawer={(id,recruited) => handleShowDrawer(id,recruited)} loading={loading} nextPage={paginate.current.nextPage} cboId={filtersQuery.cbo_occupation_id} loadMore={() => loadMore()}  google={google} data={resumesList.current}/>}
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }

            <ResumeDrawer
                arrowShortCut={true}
                hideStepFeedback={true}
                currentIndex={actualUserId.current?.index}
                candidatesList={resumesList.current}
                updateFeedbackUser={(item) => handleShowDrawer(item.id, !!item.name, item.index)}
                handleRecruited={() => handleRecruited()}
                id={actualUserId.current.id}
                recruited={actualUserId.current.recruited}
                cboId={filtersQuery.cbo_occupation_id}
                visible={showModal}
                setVisible={(item) => setShowModal(item)}
            />
        </div>
    );
};

SearchResumePage.propTypes = {
    location: PropTypes.any,
    categoriesTreeList: PropTypes.any,
    searchAction: PropTypes.any,
    toggle: PropTypes.any,
    addLocToFilter: PropTypes.any
};

export default Form.create({name: 'searchResumePage'})(SearchResumePage);
