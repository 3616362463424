import React, {useEffect} from 'react';
import {Env} from "../../Helpers/Env";
import {useHistory} from "react-router-dom";
import useLocalStorage from "../../Hooks/Storage";
import {StorageVariables} from "../../Helpers/StoragesVariables";

const ExternalLogout = props => {

    const history = useHistory();
    const externAccessUser = useLocalStorage(StorageVariables.externUserAccess)

    const doLogout = () => {
        externAccessUser?.remove();
        delete Env.header_club[`userToken`];
        console.log('iashfa')
        history.push('/acesso-externo/acesso-da-empresa');
    }

    useEffect(() => {
        doLogout()
    },[])

    return (
        <div/>
    );
};

ExternalLogout.propTypes = {};

export default ExternalLogout;
