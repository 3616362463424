import React from 'react'
import {Input} from "antd";

const SyTextArea = ({data, setValue, value}) => {

    return (
        <div>
            <Input.TextArea maxLength={500}
                            value={value}
                            id={data.label_key}
                            onChange={(e) => setValue(e.target.value)}
                            autosize={{minRows:4, maxRows:5}}
            />
        </div>
    )
}

export default SyTextArea
