import React from 'react'
import {Modal} from "antd";
import {Images} from "../../../../../../Helpers/Images";

const PausePublishModal = ({showModal, closeModal, handlePause}) => {

    return (
        <Modal visible={showModal}
               onCancel={closeModal}
               okText={'Ocultar vaga'}
               cancelText={'Cancelar'}
               onOk={handlePause}
        >
            <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                <img src={Images.pause_image} style={{height: 300}}/>
                <div>
                    <h3 style={{color:'#212f35'}}>Gostaria de parar a divulgação de sua vaga?</h3>
                </div>
                <div>
                    <span>
                        A sua vaga <strong>não será mais divulgada</strong> nos aplicativos e sites, mas não se preucupe você poderá divulgar novamente se precisar!
                    </span>
                </div>
            </div>
        </Modal>
    )
}

export default PausePublishModal
