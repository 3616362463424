import React, {useState} from 'react';
import {Avatar, Button, Icon, Input, List, message, Popover, Rate, Skeleton,} from "antd";
import moment from "moment";
import {Fade} from "react-reveal";
import {Link} from "react-router-dom";
import {Images} from "../../../../../../Helpers/Images";

const ReceivedCard = props => {
    const [reply, setReply] = useState("");
    const [loading, setLoading] = useState();

    const proceedToReply = () => {
        if (!reply) {
            message.warning('Digite sua resposta antes de enviar')
        } else {
            setLoading(true);
            props.doReply(props.item, reply, props.index);
        }
    }

    return (
        <List.Item key={props.index} style={{padding: 10, display: 'flex', flexFlow: 'column'}}>
            <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                    style={{width: '100%'}}
                    avatar={
                        <Link to={`/ordem-de-servico/` + props.item.work_order_id}>
                            <Avatar style={{width: 64, height: 64}} size='default' src={props.item.user?.profile_picture?.thumbnail || Images.user_place_holder}/>
                        </Link>
                    }
                    title={<>
                        <div style={{display: 'flex', marginTop: 5, justifyContent: 'space-between'}}>
                            <div>
                                <Link to={`/ordem-de-servico/` + props.item.work_order_id}>
                                    <span style={{fontWeight: "bold", fontSize: 15}}>
                                        {props.item.user?.name}
                                    </span>
                                </Link>
                            </div>
                            <div className={'hideMobile768'}>
                                <span className={'primary-sub-color'} style={{marginRight: 5}}>
                                    <Icon type='calendar'/> {moment.utc(props.item.created_at).local().format('DD/MM/YYYY')}
                                </span>
                            </div>
                        </div>
                        <Rate style={{fontSize: 14}} disabled className={'rating'} allowHalf defaultValue={props.item.rating}/>
                    </>}
                    description={<div>
                        <div>
                            <span style={{fontSize: 14, color: '#929191'}}>
                                {props.item.review}
                            </span>
                            {props.item.reply && <div style={{borderTop: '1px solid gainsboro', paddingTop: 20, marginTop: 20}}>
                                 Sua resposta <Icon style={{fontSize: 13}} type={'arrow-right'}/>
                                <span style={{color: "#00a5d3", marginLeft: 5}}>{props.item.reply.content}</span>
                            </div>}
                        </div>
                    </div>}
                />
                {(!props.item.reply && props.item.review)&&
                <div style={{width: '100%', transition: '300ms', marginTop: props.commentToReply?.work_order_id === props.item.work_order_id? 20 : -25, display: 'flex', justifyContent: 'space-between'}}>
                    <Fade when={props.commentToReply?.work_order_id === props.item.work_order_id} delay={400}>
                        <div style={{width: '100%', marginRight: 20, position: 'relative'}}>
                            <Input value={reply} style={{border: 'unset', boxShadow: 'unset', paddingRight: 75, borderRadius: "0", borderBottomStyle: 'solid', borderBottomWidth: 2}} className={'primary-sub-color-border'} onChange={(e) => {if(e.target.value?.length <= 200) setReply(e.target.value)}} placeholder={'Digite aqui sua resposta'}/>
                            <span style={{position: 'absolute', right: 0, bottom: 20, color: 'gainsboro'}}> {reply?.length} (200) </span>
                        </div>
                    </Fade>
                    {props.commentToReply?.work_order_id === props.item.work_order_id ?
                        <Button loading={loading} disabled={loading} onClick={() => proceedToReply()}  className={'ant-btn-primary2'} type={'primary'}> Enviar </Button>
                        :
                        <Button onClick={() => props.setCommentToReply(props.item)}  className={'ant-btn-primary2'} type={'primary'}> Responder</Button>
                    }
                </div> }
            </Skeleton>
        </List.Item>
    );
};

ReceivedCard.propTypes = {};

export default ReceivedCard;
