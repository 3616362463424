import React from 'react';
import {Avatar, Icon, List, Popover, Rate, Skeleton,} from "antd";
import moment from "moment";
import {Link} from "react-router-dom";
import {Images} from "../../../../../../Helpers/Images";

const SubmittedCard = props => {

    return (
        <List.Item key={props.index} style={{padding: 20}}>
            <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                    avatar={
                        <Avatar style={{width: 64, height: 64}} size='default' src={props.item.user?.profile_picture?.thumbnail || Images.user_place_holder}/>
                    }
                    title={<>
                        <div style={{
                            display: 'flex',
                            marginTop: 5,
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                <span style={{fontWeight: "bold", fontSize: 15}}>
                                        {props.user.name}
                                </span>
                            </div>
                            <div className={'hideMobile768'}>
                                <span className={'primary-sub-color'} style={{marginRight: 5}}>
                                    <Icon type='calendar'/> {moment.utc(props.item.created_at).local().format('DD/MM/YYYY')}
                                </span>
                            </div>
                        </div>
                        <div> <strong style={{color: 'limegreen'}}> {props.item.price === 1? '$' :props.item.price === 3? '$$' : '$$$'} </strong>  <strong style={{marginRight: 5, marginLeft: 5}}> / </strong> <Rate style={{fontSize: 14}} disabled className={'rating'} allowHalf defaultValue={props.item.rating}/> </div>
                    </>}
                    description={<span>
                        <span style={{fontSize: 14, color: '#6a6969'}}>
                            {props.item.review}
                        </span>
                        {props.item.reply &&
                        <div style={{ borderTop: '1px solid gainsboro', marginTop: 20}}>
                            <br/> Resposta em {moment.utc(props.item.reply.created_at).local().format('DD/MM/YYYY')}  <Icon style={{fontSize: 13}} type={'arrow-right'}/>
                            <span style={{color: "#00a5d3", marginLeft: 5}}>{props.item.reply.content}</span>
                        </div>}
                    </span>}
                />
            </Skeleton>
        </List.Item>

    );
};

SubmittedCard.propTypes = {};

export default SubmittedCard;
