import React from 'react'
import {Input} from "antd";

const SyText = ({data, setValue, value}) => {

    return(
        <div>
            <Input id={data.label_key} value={value} onChange={(e) => setValue(e.target.value)}/>
        </div>
    )
}

export default SyText
