import React from 'react';
import {Env} from "../Helpers/Env";
import useLocalStorage from "./Storage";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {StorageVariables} from "../Helpers/StoragesVariables";


const useLogout = () => {
    const history = useHistory();
    const userStorage = useLocalStorage(StorageVariables.userProfile);
    const panelStorage = useLocalStorage(StorageVariables.lastPanel);
    const fcmStorage = useLocalStorage(StorageVariables.fcm);
    const geoLocation = useLocalStorage(StorageVariables.geolocation);
    const hasProfile = useLocalStorage(StorageVariables.hasSineProfile);
    const enterpriseStorage = useLocalStorage(StorageVariables.enterprise);
    const enterpriseDataStorage = useLocalStorage(StorageVariables.enterpriseData);
    const sineUserStorage = useLocalStorage(StorageVariables.updatedProfile);
    const filtersSearchVacancy = useLocalStorage(StorageVariables.filters_search_opportunity);
    const blackListID = useLocalStorage(StorageVariables.hide_notification);
    const previousDay = useLocalStorage(StorageVariables.previousDay)

    const dispatch = useDispatch();
    const step = useLocalStorage(StorageVariables.curriculumStep);

    const logoutWithoutApi = (force) => {
        let aux = userStorage.getObject();
        if(!aux.gov_br_access || force) {
            userStorage.remove();
            fcmStorage.remove();
            panelStorage.remove();
            geoLocation.remove();
            sineUserStorage.remove();
            filtersSearchVacancy.remove();
            blackListID.remove();
            step.remove();
            hasProfile.remove();
            enterpriseStorage.remove();
            enterpriseDataStorage.remove();
            previousDay.remove();
            dispatch({type: 'logout'});
            delete Env.header[`X-Api-Key`];
            history.push('/');
        } else {
            window.open('https://sso.staging.acesso.gov.br/logout?post_logout_redirect_uri=https%3A//dev.mscontratamais.ms.gov.br/remover-acesso-gov')
        }
    };

    return {logoutWithoutApi};
};

useLogout.propTypes = {};

export default useLogout;
