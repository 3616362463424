import React, {useEffect, useRef, useState} from 'react'
import {Col, Row, message} from "antd";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaPlus,
  FaTrashAlt
} from "react-icons/fa";
import InputCustom from "../Components/InputCustom";
import useApi from "../../../../../Hooks/Api";
import Button from "antd/es/button";
import {useSelector} from "react-redux";
import Icon from "antd/es/icon";
import _ from "lodash";
import {Env} from "../../../../../Helpers/Env";

const SocialMediaComponent = () => {
  const data = useRef({})
  const [socialList, setSocialList] = useState()
  const [fieldsSocial, setFieldsSocial] = useState(false)
  const [handleButtonAddSocial, setHandleButtonAddSocial] = useState(false)
  const user = useSelector((state) => state).authReducer;
  const api = useApi()

  const getSocialMedia = () => {
    api.get(`sine/user/${user.user_id}/social-media`,
        (res) => {
          setSocialList(res)
          data.current = res
          if (data.current.instagram_link && data.current.facebook_link && data.current.linkedin_link) {
            setHandleButtonAddSocial(true)
          }
          if (data.current.instagram_link === null && data.current.facebook_link === null && data.current.linkedin_link === null) {
            setFieldsSocial(true)
          }
          if (data.current.instagram_link === null || data.current.facebook_link === null || data.current.linkedin_link === null) {
            setHandleButtonAddSocial(false)
          }
    }, (err) => {
      console.log(err)
    })
  }


  const getUsername = () => {

    if (data.current.instagram_link) {
      let filterUsernameInstagram = data.current.instagram_link.split('/');

      filterUsernameInstagram = filterUsernameInstagram.filter((el, index) => {
        return filterUsernameInstagram[index] !== ''
      })

      data.current.instagram_link = filterUsernameInstagram[filterUsernameInstagram.length -1]

      if (data.current.instagram_link.includes('@')) {
        data.current.instagram_link = data.current.instagram_link.replace('@', '').trim()
      }
    }

    if (data.current.facebook_link) {
      let filterUsernameFacebook = data.current.facebook_link.split('/');

      filterUsernameFacebook = filterUsernameFacebook.filter((el, index) => {
        return filterUsernameFacebook[index] !== ''
      })

      data.current.facebook_link = filterUsernameFacebook[filterUsernameFacebook.length -1]
    }

    if (data.current.linkedin_link) {
      let filterUsernameLinkedin = data.current.linkedin_link.split('/');

      filterUsernameLinkedin = filterUsernameLinkedin.filter((el, index) => {
        return filterUsernameLinkedin[index] !== ''
      })

      data.current.linkedin_link = filterUsernameLinkedin[filterUsernameLinkedin.length -1]
    }
  }

  const handleSaveSocialMedia = () => {
    getUsername()
    let aux = Object.assign({},data.current)

    data.current.facebook_link === '' && (data.current.facebook_link = null)
    data.current.instagram_link === '' && (data.current.instagram_link = null)
    data.current.linkedin_link === '' && (data.current.linkedin_link = null)

    if (socialList?.instagram_link) {
      delete aux.instagram_link
    }
     if (socialList?.facebook_link) {
       delete aux.facebook_link
    }
     if (socialList?.linkedin_link) {
       delete aux.linkedin_link
    }

    let keys = Object.entries(aux)
    let lengthKeys = keys.length

    let validationFields =
        lengthKeys === 3 ? keys[0][1] === null && keys[1][1] === null && keys[2][1] === null :
        lengthKeys === 2 ? keys[0][1] === null && keys[1][1] === null :
        lengthKeys === 1 && keys[0][1] === null

    if (validationFields) {
        message.warning(lengthKeys === 1 ? 'Preencha o campo para salvar' : 'Preencha um dos campos para salvar')
    } else {
      api.post( `sine/user/${user.user_id}/social-media`, data.current , () => {
        message.success('Sucesso!')
        getSocialMedia()
        setFieldsSocial(false)
      }, (err)=>{
        console.log(err)
      })
    }
  }

  const handleDeleteSocialMedia = (label) => {
    data.current = {...data.current, [label]: null}
    api.post( `sine/user/${user.user_id}/social-media`, data.current , () => {
      message.success('Removido!')
      getSocialMedia()
      setFieldsSocial(false)
    }, (err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getSocialMedia()
  },[])

  return (
      <div>
        <div className={'formTitle'} style={{display: 'flex', justifyContent: 'space-between'}}>
          <span style={{fontSize: 20}}> Redes sociais </span>
          {(!handleButtonAddSocial && !fieldsSocial) &&
            <Button className={'ant-btn-green'} shape={"circle"} style={{padding: 5}} onClick={() => setFieldsSocial(!fieldsSocial)}>
              <FaPlus style={{color: 'white', fontSize: 17, marginLeft: 1, marginTop: 1}}/>
            </Button>
          }
        </div>
        {socialList?.instagram_link &&
          <Row gutter={[0,24]} style={{marginBottom: 30, backgroundColor: 'white', marginLeft: 0}} className={'formRow'}>
            <Col span={20} className={'listView'}>
              <FaInstagram style={{fontSize:20}}/>
              <span>
                <a target={`_blank`} href={`https://www.instagram.com/${socialList?.instagram_link}`}>
                  www.instagram.com/{socialList?.instagram_link }
                </a>
              </span>
            </Col>

            <Col span={4}
                 onClick={() => handleDeleteSocialMedia('instagram_link')}
                 style={{justifyContent: 'flex-end', padding: 0, fontSize: 20, display: 'flex', alignItems: 'center'}}>
              <FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}}/>
            </Col>
          </Row>
        }

        {socialList?.facebook_link &&
          <Row gutter={[0,24]} style={{marginBottom: 30, backgroundColor: 'white', marginLeft: 0}} className={'formRow'}>
            <Col span={20} className={'listView'}>
              <FaFacebookSquare style={{fontSize:20}}/>
              <span>
                <a target={'_blank'} href={`https://www.facebook.com/${socialList?.facebook_link }`}>
                  www.facebook.com/{socialList?.facebook_link}
                </a>
              </span>
            </Col>

            <Col span={4}
                 onClick={() => handleDeleteSocialMedia('facebook_link')}
                 style={{justifyContent: 'flex-end', padding: 0, fontSize: 20, display: 'flex', alignItems: 'center'}}>
              <FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}}/>
            </Col>
          </Row>
        }

        {socialList?.linkedin_link &&
          <Row gutter={[0,24]} style={{marginBottom: 30, backgroundColor: 'white', marginLeft: 0}} className={'formRow'}>
            <Col span={20} className={'listView'}>
              <FaLinkedin style={{fontSize:20}}/>
              <span>
                <a target={'_blank'} href={`https://www.linkedin.com/in/${socialList?.linkedin_link}`}>
                  www.linkedin.com/in/{socialList?.linkedin_link }
                </a>
              </span>
            </Col>

            <Col span={4}
                 onClick={() => handleDeleteSocialMedia('linkedin_link')}
                 style={{justifyContent: 'flex-end', padding: 0, fontSize: 20, display: 'flex', alignItems: 'center'}}>
              <FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}}/>
            </Col>
          </Row>
        }

        {fieldsSocial &&
          <Row style={{ marginLeft: 0, backgroundColor: 'white'}} className={'formRow'}>
            <div onClick={() => setFieldsSocial(!fieldsSocial)} style={{cursor:"pointer", textAlign:"end"}}>
              <Icon type='close'/>
            </div>
            <Col lg={24} xl={24} style={{display:"flex", flexDirection:"column"}}>
              {_.isEmpty(socialList?.instagram_link) &&
                <Col xs={24} className='form-social-media' style={{marginTop:12}}>
                  <div style={{paddingBottom:16}}>
                    <FaInstagram style={{fontSize:20}}/>
                  </div>
                  <InputCustom textField={"Instagram"} xs={22} maxLength={65}
                               valueField={(e)=> {data.current = {...data.current, instagram_link: e}}}/>
                </Col>
              }
              {_.isEmpty(socialList?.facebook_link) &&
                <Col xs={24} className='form-social-media'>
                  <div style={{paddingBottom:16}}>
                    <FaFacebookSquare style={{fontSize:20}}/>
                  </div>
                  <InputCustom textField={"Facebook"} xs={22} maxLength={65}
                               valueField={(e)=> {data.current = {...data.current, facebook_link: e}}}/>
                </Col>
              }

              {_.isEmpty(socialList?.linkedin_link) &&
                <Col xs={24} className='form-social-media'>
                  <div style={{paddingBottom:16}}>
                    <FaLinkedin style={{fontSize:20}}/>
                  </div>
                  <InputCustom textField={"LinkedIn"} xs={22} maxLength={65}
                               valueField={(e)=> {data.current = {...data.current, linkedin_link: e}} }/>
                </Col>
              }

              <div style={{fontSize:12, fontStyle:"italic", color: '#aeaeae', marginBottom:12}}>
                Insira o link do seu perfil - Exemplo: https://www.instagram.com/{Env.application_name}/
              </div>

              <div className='listButtons' style={{paddingRight:0}}>
                <Button type={'primary'} onClick={()=> {handleSaveSocialMedia()}}>
                  Salvar
                </Button>
              </div>
            </Col>
          </Row>
        }
      </div>
  )
}

export default SocialMediaComponent
