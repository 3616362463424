import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Avatar, Button, Checkbox, Col, Icon, Input, List, Modal, Row, Skeleton} from "antd";
import {FaTrash, MdSearch} from "react-icons/all";
import useApi from "../../../../../Hooks/Api";
import {debounce} from "lodash";
import {Env} from "../../../../../Helpers/Env";
import {Images} from "../../../../../Helpers/Images";
import {maskCpf} from "../../../../../Helpers/Functions";
import Loading from "../../../../Generic/Loading";
import Spin from "antd/lib/spin";
import {useParams} from "react-router-dom";
import {FaTrashAlt} from "react-icons/fa";


const UserAccessPage = props => {

    const routerParams = useParams();
    const [newPersonModal, setNewPersonModal] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const [permissionType, setPermissionType] = useState(undefined)
    const [userAvailable, setUserAvailable] = useState(undefined);
    const [dataUsers, setDataUsers] = useState([]);
    const userId = useRef(undefined);


    const getData = () => {
        api.post(`sine/user/${props.user.user_id}/company-list-permissions/${routerParams.company_id}`, null, (res) => {
            setDataUsers(res.users);
        }, (e) => console.log(e))
    }

    useEffect(debounce(async (query) => {
        if(window.getNumbers(searchText)?.length === 11) {
            setLoading(true);
            api.get('search/user?cpf=' + window.getNumbers(searchText), (res) => {
                console.log(res);
                userId.current = res.user_id;
                setUserAvailable(true);
                setLoading(false);
            },(e) => {
                userId.current = undefined;
                setUserAvailable(false)
                setLoading(false)
            })
        }
    }, 300), [searchText]);

    const handleRevoke = (id) => {
        setLoading(true);
        api.post(`sine/user/${props.user.user_id}/company-revoke-permissions/${routerParams.company_id}`, {
            "user_id": id
        }, (res) => {
            getData()
            setLoading(false)
        }, (e) => console.log(e))
    }

    const handleSendInvite = (id) => {
        setLoading(true);
        let permissionTypeAux = {};
        if(permissionType === 'admin') {
            permissionTypeAux = {
                "feedback_permission": true,
                "opportunity_permission": true,
                "enterprise_permission": true
            }
        } else if(permissionType === 'coord') {
            permissionTypeAux = {
                "feedback_permission": true,
                "opportunity_permission": true,
                "enterprise_permission": false
            }
        } else if(permissionType === 'recruiter') {
            permissionTypeAux = {
                "feedback_permission": true,
                "opportunity_permission": false,
                "enterprise_permission": false
            }
        }

        api.post(`sine/user/${props.user.user_id}/company-permissions/${routerParams.company_id}`, {
            "user_id": userId.current, ...permissionTypeAux
        }, (res) => {
            console.log(res)
            setLoading(false);
            setNewPersonModal(false);
            getData();
        }, (e) => {
            console.log(e)
            setLoading(false);
        })
    }

    useEffect(() => {
        getData();
    },[]);

    console.log(dataUsers)

    return (
        <div style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 0, borderRadius:5, backgroundColor: "white"}}>
            <Row>
                <Modal
                    visible={newPersonModal}
                    footer={null}
                    bodyStyle={{padding: 0}}
                    // width={'80%'}
                    onCancel={() => {setNewPersonModal(false)}}
                    className={'registerModalWidth'}
                    destroyOnClose={true}
                    style={{}}
                >
                    <div style={{}}>
                        <div style={{padding: 20, borderBottom: "1px solid gainsboro",}}>
                            <span style={{fontSize: 18, }}>Informe o <strong>CPF</strong> do usuário que gostaria de convidar</span> <br/>
                            <span style={{fontSize: 12, }}>Convide usuários para ajudar na seleção de currículos, cadastramento de vagas e retorno da situação dos candidatos.</span>

                        </div>

                        <form style={{display: 'flex', alignItems: 'center', padding: 20}} onSubmit={(event) => {
                            event.preventDefault();
                        }}>
                            <Input  value={searchText} onChange={(e) => setSearchText(maskCpf(e.target.value))} style={{flex: 1, textAlign: 'center', borderColor: 'whitesmoke', height: 35, marginRight: 4,}}  placeholder={'XXX.XXX.XXX-XX'} />
                        </form>
                        {loading &&
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Spin/>
                        </div>}

                        {userAvailable === true &&<div style={{padding: 20, paddingTop: 0,}}>
                            <div style={{display: 'flex', flexDirection: 'column', padding: 5}}>
                                <div onClick={() => setPermissionType('admin')} style={{display: 'flex', boxShadow: '0px 0px 2px 1px #e5e5e5', borderRadius: 5, padding: 5, marginBottom: 10}}>
                                    <div>
                                        <Checkbox checked={permissionType === 'admin'} defaultChecked={false} onChange={() => setPermissionType('admin')}/>
                                    </div>
                                    <div style={{display: 'flex', cursor: 'pointer',  flexFlow: 'column'}}>
                                        <span style={{marginLeft: 5, fontSize: 14, fontWeight: 'bold'}}>Administrador</span>
                                        <span style={{marginLeft: 5, fontSize: 12}}>Pode editar os dados da empresa, convidar outros usuários, criar vagas, informar retorno dos candidatos etc...</span>
                                    </div>
                                </div>

                                <div onClick={() => setPermissionType('coord')} style={{display: 'flex', cursor: 'pointer', boxShadow: '0px 0px 2px 1px #e5e5e5', borderRadius: 5, padding: 5, marginBottom: 10}}>
                                    <div>
                                        <Checkbox checked={permissionType === 'coord'} defaultChecked={false} onChange={() => setPermissionType('coord')}/>
                                    </div>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span style={{marginLeft: 5, fontSize: 14, fontWeight: 'bold'}}>Coordenador de vagas</span>
                                        <span style={{marginLeft: 5, fontSize: 12}}>Pode criar e editar vagas, ver os candidatos, informar o retorno etc.. </span>
                                    </div>
                                </div>

                                <div onClick={() => setPermissionType('recruiter')} style={{display: 'flex', cursor: 'pointer', boxShadow: '0px 0px 2px 1px #e5e5e5', borderRadius: 5, padding: 5}}>
                                    <div>
                                        <Checkbox checked={permissionType === 'recruiter'} defaultChecked={false} onChange={() => setPermissionType('recruiter')}/>
                                    </div>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span style={{marginLeft: 5, fontSize: 14, fontWeight: 'bold'}}>Recrutador</span>
                                        <span style={{marginLeft: 5, fontSize: 12}}>Pode ver os candidatos e informar retorno da situação dos candidatos </span>
                                    </div>
                                </div>
                            </div>

                            {permissionType &&<div style={{padding: 5, textAlign: "right"}}>
                                <Button loading={loading} onClick={() => handleSendInvite()} className={'ant-btn-sub-primary2'}> Convidar </Button>
                            </div>}
                        </div>}

                        {userAvailable === false &&
                            <div style={{padding: 20}}>
                                <div style={{padding: 20, backgroundColor: '#fff9f0', borderRadius: 5, display: 'flex', border: '1px solid #ff9800'}}>
                                    <div style={{flex: 1}}>
                                        <h3 style={{color: '#ff9800'}}>Usuário não cadastrado no {Env.application_title} </h3>
                                        <span>É necessário uma conta no {Env.application_title} para ser adicionado como um usuário de sua empresa/estabelecimento, convide-o para a plataforma!</span> <br/>
                                    </div>
                                </div>
                            </div>
                            }

                    </div>
                </Modal>
                <Col xs={24} style={{display: "flex" ,padding: 10, borderBottom: "1px solid gainsboro", alignItems: "center", marginBottom: 10, justifyContent: "space-between",}}>
                    <div>
                        <span style={{fontSize: 18}}>Acessos de usuários</span><br/>
                        <span style={{fontSize: 12}}>Convide usuários para ajudar na seleção de currículos, cadastramento de vagas e retorno da situação dos candidatos</span>

                    </div>
                    <Button type={'primary'} shape="round" style={{width: "fit-content", borderColor: 'red', color: 'red'}} onClick={() => {setNewPersonModal(true)}}> Adicionar Novo </Button>


                </Col>
                <Col xs={24} style={{padding: 10}}>
                    <List
                        className='listAnt'
                        style={{overflow: 'hidden'}}
                        itemLayout="horizontal"
                        dataSource={dataUsers}
                        loading={loading}
                        renderItem={item => (
                            <List.Item onClick={() => {}} style={{position: 'relative', borderLeft: '3px solid #005c9f', padding: 0, backgroundColor: "white"}}>
                                <Skeleton avatar title={false} loading={false} active>
                                    <div style={{width: "100%"}}>
                                        <div style={{display: "flex", padding: 10}}>
                                            <div>
                                                <div>
                                                    <span style={{fontSize: '18px'}}>{item.first_name} {item.last_name}</span>
                                                </div>
                                                <div>
                                                    <span style={{color: "#757575"}}>{item.email}</span>
                                                </div>
                                                <div style={{color: '#9e9e9e', marginTop: 5}}>
                                                    {window.getCompanpanyPermission(item.permissions)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {item.is_owner && <div style={{position: 'absolute', top: 10, right: 10}}>
                                        <strong> Master </strong>
                                    </div>}

                                    {!item.is_owner && <div style={{position: 'absolute', bottom: 10, right: 10, cursor: 'pointer'}} onClick={() => handleRevoke(item.user_id)}>
                                        <FaTrashAlt style={{color: '#da5252'}}/>
                                    </div>}
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </Col>

            </Row>
        </div>
    )
}

UserAccessPage.propTypes = {};

export default UserAccessPage;
