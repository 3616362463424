import React from 'react'

const SyDateTime = ({data, setValue, value}) => {

    return (
        <div>
            <input type={'datetime-local'}
                   id={data.label_key}
                   value={value}
                   onChange={(e) => setValue(e.target.value)}
                   className='ant-input ant-input-lg'
            />
        </div>
    )
}

export default SyDateTime
