import React, {useEffect, useRef, useState} from 'react';
import {Col, Icon, Menu, message, Row} from "antd";
import {useSelector} from "react-redux";

import {FaClipboardCheck, FaClipboardList, FaDotCircle, FaPlus} from "react-icons/fa";

import SubMenu from "antd/es/menu/SubMenu";
import {useHistory} from 'react-router-dom'
import useApiClub from "../../../Hooks/ApiClub";
import Loading from "../../../Components/Generic/Loading";
import {maskCnpj} from "../../../Helpers/Functions";
import {Images} from "../../../Helpers/Images";
import ExternalVacancyListComponent from "./ExternalVacancyListComponent";
import ExternalVacancyDetailsComponent from "./ExternalVacancyDetailsComponent";
import ExternalCandidatesListComponent from "./ExternalCandidatesListComponent";
import RegisterPageModal from "../../RegisterPageModal";
import {Env} from "../../../Helpers/Env";
import useLocalStorage from "../../../Hooks/Storage";
import {StorageVariables} from "../../../Helpers/StoragesVariables";


const ExternalVacancyManagePage = (props) => {

    const api = useApiClub();
    const user = useSelector((state) => state).authReducer;
    const [loading, setLoading] = useState(false);
    const [rightLoading, setRightLoading] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const enterprise = useRef(null)
    const vacancyList = useRef([]);
    const [menuSelected, setMenuSelected] = useState("sub")
    const [subExpanded, setSubExpanded] = useState([]);
    const vacancy = useRef(null);
    const endedVacancies = useRef([]);
    const history = useHistory();
    const paginate = useRef({
        page: 1,
    });
    const nextPage = useRef(false);
    const [loadingVacancy, setLoadingVacancy] = useState(false);
    const externAccessUser = useLocalStorage(StorageVariables.externUserAccess)

    const logout = () => {
        externAccessUser.remove();
        delete Env.header_club[`userToken`];
    }

    const getVacancies = async (status, more) => {
        if(more) {
            setLoadingVacancy(true)
            paginate.current.page = paginate.current.page + 1;
        } else {
            setRightLoading(true);
            paginate.current.page = 1;
        }
        api.get(`formal-working/job-opportunity/paginate?search_closed=${status === 'open' ? 0 : 1}`,(res) => {
            console.log(res);
            res.object.next_page_url? nextPage.current = true : nextPage.current = false;
            if(status === 'open') {
                vacancyList.current = (more? vacancyList.current.concat(res.object.data) : res.object.data);
            } else {
                endedVacancies.current = (more? vacancyList.current.concat(res.object.data) : res.object.data);
            }
            setRightLoading(false);
            setLoadingVacancy(false)
        },(e) => {
            setRightLoading(false);
            setLoadingVacancy(false)
            console.log(e);
        });
    };

    const getEnterprise = async () => {
        setLoading(true)

        api.get(`formal-working/company`,(res) => {
            console.log(res);
            if(res.object) {
                enterprise.current = res.object;
                setLoading(false);
                getVacancies('open');
            }
        },(e) => {
            setLoading(false);
        });
    }


    const handleSubMenus = (e) => {
        const aux = subExpanded.findIndex((item) => item === e);
        const listAux = subExpanded;

        if(aux !== -1) {
            listAux.splice(aux, 1);
            setSubExpanded(listAux);
        } else {
            listAux.push(e);
            setSubExpanded(listAux);
        }
    }

    const getVacancy = async (e) => {
        setMenuSelected(e);
        setRightLoading(true);
        api.get(`formal-working/job-opportunity/${e.slice(10)}`, (res) => {
            console.log(res)
            vacancy.current = res.object;
            vacancy.current.id = e.slice(10);
            setRightLoading(false)
        },(e) => {
            setRightLoading(false);
        });
    }

    const handleRegister = (apikey) => {
        setShowRegisterModal(false);
        message.loading('Estamos vinculando a empresa à sua nova conta!...')
        api.put('setting/global-user', {
            ['x-api-key'] : apikey
        }, (res) => {
            message.success('Tudo certo!')
            window.gtagService('event', 'migrationSuccess');
            history.push('/painel/cliente/vagas');
        }, (e) => {
            logout();
            history.push('/painel/cliente/vagas');
            window.gtagService('event', 'migrationFailed');
            message.destroy();
            message.error(e.message || 'Não foi possível migrar, entre em contato conosco que podemos te ajudar!')
        })
    }

    const changeTab = (e) => {
        if(enterprise.current) {
            window.scrollToElement('body');
            if(e === "sub") {
                handleSubMenus(e)
                setMenuSelected(e);
            } else if(e.includes("subdetails")) {
                getVacancy(e);
            }
            else if(e.includes("subcandidates")) {
                if(vacancy.current) {
                    vacancy.current.id = e.slice(13);
                } else {
                    vacancy.current = {id : e.slice(13) }
                }
                setMenuSelected(e)
            }
            else if(e.includes("sub")) {
                handleSubMenus(e);
            }
            else {
                if(e === '5') {
                    getVacancies('closed')
                }
                setMenuSelected(e)
            }
        } else {
            if(e === "4") {
                setMenuSelected(e)
            }
        }
    }

    useEffect(() => {
        if(user?.token) {
            handleRegister(user?.token)
        }
    },[user?.token])

    useEffect(() => {
        getEnterprise();
        window.scrollToElement('body');
    },[]);


    return(
        <Row style={{display: 'flex', justifyContent: 'center', paddingTop: 25}}>
            <RegisterPageModal externalRegisterIntegration={true}  profile={'client'} visible={showRegisterModal} type={'regNumber'} setVisible={() => setShowRegisterModal(!showRegisterModal)} />
            <Col xs={23} lg={22} xl={20} xxl={16}>
                <Row gutter={[16,16]} style={{minHeight: 500}} className={'aboutCss'}>
                    <Col xs={24} sm={7}>
                        {(loading) ? <div style={{height: 400, display: "flex"}}>
                                {rightLoading &&<Loading/>}
                            </div>
                            :
                            <div style={{boxShadow: "0 2px 8px rgb(0 0 0 / 8%)", borderRadius: 5, backgroundColor: "white"}}>
                                <div onClick={() => enterprise.current&& changeTab('3')} style={{
                                    alignItems: "center",
                                    textAlign: "center",
                                    marginBottom: 5,
                                    borderRadius: 5,
                                    padding: 20,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    borderBottom: "1px solid gainsboro"
                                }}>
                                    <img src={enterprise.current?.company_logo? `https://crafty-api.s3.amazonaws.com/public/logos/${enterprise.current?.company_logo}` : Images.user_place_holder}
                                         style={{height: 80, cursor: 'pointer', width: 80, borderRadius: 40}}/>
                                    <span style={{color: "#212020", fontSize: 20}}> {enterprise.current?.business_name} </span>
                                    <span style={{color: "#9e9e9e"}}> {maskCnpj(enterprise.current?.cnpj)}</span>
                                </div>

                                <div>
                                    <Menu
                                        onClick={(e) => changeTab(e.key)}
                                        selectedKeys={[menuSelected]}
                                        openKeys={subExpanded}
                                        mode="inline"
                                    >
                                        <Menu.ItemGroup key="g1" title="Minha empresa">
                                            {enterprise.current && <SubMenu
                                                className={'vacancyMenuItem'}
                                                key="sub"
                                                onTitleClick={() => changeTab('sub')}
                                                title={
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <FaClipboardList style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/>
                                                        <span>Vagas publicadas</span>
                                                    </div>
                                                }
                                            >
                                                {vacancyList.current.map((item, index) =>
                                                    <SubMenu
                                                        key={"sub"+item.id}
                                                        onTitleClick={() => changeTab("sub"+item.id)}
                                                        title={
                                                            <span>
                                                                <Icon type="caret-right"/>
                                                              <span>{item.occupation_title? item.occupation_title : item.occupation_name}</span>
                                                            </span>
                                                        }
                                                    >
                                                        <Menu.Item key={"subdetails"+item.id}><Icon type="edit"/>Detalhes</Menu.Item>
                                                        <Menu.Item key={"subcandidates"+item.id}><Icon type="team"/>Candidatos</Menu.Item>
                                                    </SubMenu>
                                                )}
                                            </SubMenu>}
                                            {enterprise.current && <Menu.Item className={'vacancyMenuItem'} key="5" style={{display: 'flex', alignItems: 'center'}}> <FaClipboardCheck style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/>Vagas encerradas</Menu.Item>}
                                        </Menu.ItemGroup>
                                    </Menu>
                                </div>

                            </div>
                        }
                    </Col>
                    <Col xs={24} sm={17}>
                        {!user?.user_id&&<div className={'rocketdiv'} onClick={() => setShowRegisterModal(true)} style={{padding: 20, cursor: 'pointer', alignItems: "center", backgroundColor: '#fffaec', marginBottom: 20, borderRadius: 5, display: 'flex', border: '1px solid orange'}}>
                            <img className={'rocket'} src={Images.rocket} style={{height: 50, marginRight: 20, width: 50}} />
                            <div style={{flex: 1}}>
                                <h3 style={{color: 'orange', marginBottom: 0}}>Quer ter acesso a todas as funcionalidades? </h3>
                                <span style={{color: 'grey', paddingLeft: 1}}>Complete seu cadastro e tenha acesso a uma versão mais completa</span> <br/>
                                <div style={{display: 'flex', color: 'grey', marginTop: 6, borderTop: '1px solid #ffeaca', flexWrap: "wrap", justifyContent: `space-between`}}>
                                    <div style={{margin: 3, marginLeft: 0}}> <FaDotCircle style={{fontSize: 10, color: "orange"}}/> Publique e edite suas vagas do seu jeito</div>
                                    <div style={{margin: 3, marginLeft: 0}}> <FaDotCircle style={{fontSize: 10, color: "orange"}}/> Controle os dados de sua empresa</div>
                                    <div style={{margin: 3, marginLeft: 0}}> <FaDotCircle style={{fontSize: 10, color: "orange"}}/> Adicione usuários </div>
                                    <div style={{margin: 3, marginLeft: 0}}> <FaDotCircle style={{fontSize: 10, color: "orange"}}/> Organize os currículos por etapas</div>
                                    <div style={{margin: 3, marginLeft: 0}}> <FaDotCircle style={{fontSize: 10, color: "orange"}}/> Mais filtros a sua disposição </div>
                                </div>

                            </div>
                        </div>}
                        {(rightLoading || loading) ? <div style={{height: 400, display: "flex"}}>
                                <Loading/>
                            </div>
                            :
                            <>
                                {menuSelected === "sub" && <ExternalVacancyListComponent setVacancyList={(item) => {vacancyList.current = item}}  loading={loadingVacancy} companyLogo={enterprise.current?.company_logo}  nextPage={nextPage.current} loadMore={() => getVacancies('open', true)} handleVacancySelect={(e) => {
                                    (subExpanded.findIndex((item) => item === "sub") === -1 && handleSubMenus("sub"))
                                    handleSubMenus("sub"+e.id);
                                    getVacancy("subdetails" + e.id);
                                }} changeMenu={(e) => changeTab(e)} vacancies={vacancyList.current} enterprise={enterprise.current}/>}
                                {(menuSelected.includes("subdetails"))  && <ExternalVacancyDetailsComponent companyLogo={enterprise.current?.company_logo} changeMenu={(e) => {changeTab(e); getVacancies('open');}} vacancy={vacancy.current} user={user} enterprise={enterprise.current}/>}
                                {(menuSelected.includes("subcandidates"))  && <ExternalCandidatesListComponent vacancy={vacancy.current.id} user={user} enterprise={enterprise.current}/>}
                                {menuSelected === "5" && <ExternalVacancyListComponent  loading={loadingVacancy} companyLogo={enterprise.current?.company_logo}  loadMore={() => getVacancies('closed', true)} handleVacancySelect={(e) => {
                                    getVacancy("subdetails" + e.id);
                                }} changeMenu={(e) => changeTab(e)} vacancies={endedVacancies.current} ended={true} enterprise={enterprise.current}/>}
                            </>
                        }
                    </Col>

                </Row>
            </Col>
        </Row>)
};
export default ExternalVacancyManagePage;


