import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Checkbox, Input, List, Menu, message, Popover, Select, Spin} from "antd";
import {FaBook, FaBookmark, FaClock, FaMapMarkerAlt, FaPhone, FaThumbsDown, FaUser, FaUsers} from "react-icons/fa";
import {
    BiBriefcaseAlt,
    FaThumbsUp,
    FaUserCheck,
    GoEyeClosed,
    IoMdEye,
    MdArrowDownward,
    MdBook,
    MdSearch,
    MdStar,
    MdStarBorder,
    MdStarHalf,
    MdThumbDown,
    MdThumbUp
} from "react-icons/all";
import {Badge} from "antd/es";
import Icon from "antd/es/icon";
import {CityList} from "../../../Helpers/CityList";
import {Env} from "../../../Helpers/Env";
import ExternalResumeDrawer from "./ExternalResumeDrawer";
import Loading from "../../../Components/Generic/Loading";
import {Images} from "../../../Helpers/Images";
import useApiClub from "../../../Hooks/ApiClub";


const schoolingList = ['Analfabeto', 'Fundamental Incompleto', 'Fundamental Completo', 'Médio Incompleto', 'Médio Completo', 'Superior Incompleto', 'Superior Completo', 'Mestrado', 'Doutorado', 'Especialização'];

Spin.setDefaultIndicator(<Loading/>);

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}
const statusTypes = {
    pending: ['pending'],
    selected: ['selected'],
    interview: ['interview'],
    approved: ['approved'],
    rejected: ['rejected'],
    all_not_finalized: ['selected', 'pending', 'interview'],
    finished_approved_rejected: ['approved', 'rejected']
}
const statusInterpreter = {
    pending: 'Pendentes',
    selected: 'Analise',
    interview: 'Entrevista',
    approved: 'Aprovados',
    rejected: 'Reprovados',
}



const AllCandidatesListComponent = (props) => {
    const defaultFilters = {
        "page": 1,
        "order": "desc",
        "order_field": undefined,
        "search_global": undefined,
        "search_status": 'all_not_finalized',
        "search_view": undefined,
        "search_favorite": undefined,
        "opportunity_id": props.opportunity_id || undefined,
        "search_schooling": [],
        "search_city": undefined,
        "open_opportunity": undefined
    }

    const [filters,setFilters] = useState(defaultFilters);
    const [cityListState, setCityListState] = useState(CityList);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const nextPage = useRef(false);
    const candidatesList = useRef([]);
    const api = useApiClub();
    const [starHover, setStarHover] = useState(false);
    const [smallLoading, setSmallLoading] = useState(false);
    const [searchText, setSearchText] = useState({
        type: undefined,
        value: ""
    });
    const loadingRef = useRef(true);
    const filtersRef = useRef(defaultFilters); // Para controle dentro do infinite scroll
    const feedbackUser = useRef(null);
    const [showCandidateDetails, setShowCandidateDetails] = useState(false);
    const showFeedback = useRef(undefined);
    const [checkedCount, setCheckedCount] = useState(0);
    const [checkedList, setCheckedList] = useState([]);
    const [allowSelect, setAllowSelect] = useState(false);

    const getCandidates = async () => {
        filters.page > 1? setLoadingMore(true) : setLoading(true);
        loadingRef.current = true;

        let aux = Object.assign({}, filters);
        aux.search_status = statusTypes[filters.search_status];
        api.get(`formal-working/job-opportunity/${props.vacancy}/interest/paginate${window.objectToQueryString(aux)}`, (res) => {
            res.object.next_page_url? nextPage.current = true : nextPage.current = false;
            if(filters.page > 1) {
                candidatesList.current = candidatesList.current.concat(res.object.data);
            } else {
                candidatesList.current = res.object.data;
            }
            filters.page > 1? setLoadingMore(false) : setLoading(false);
            loadingRef.current = false;
        }, (e) => {
            filters.page > 1? setLoadingMore(false) : setLoading(false);
            loadingRef.current = false;
        });
    }

    const changeFilter = (field, value, reset) => {
        let aux = filters;
        aux.page = 1;
        if(field === 'status' && value === 'finished_approved_rejected') aux.open_opportunity = undefined;
        if(reset) {
            aux.search_global = undefined;
        }

        if(field === 'order_field' && value === 'distance') { //TODO
            aux.order = 'asc'
        } else {
            aux.order = 'desc'
        }
        setFilters({...aux, [field]: value});
        filtersRef.current = {...aux, [field]: value};
    }

    const handleFavorite = (id, index) => {
        setSmallLoading(true);
        setStarHover(index);
        let safeGuard = Object.assign([], candidatesList.current);
        api.get(`formal-working/job-opportunity/${props.vacancy}/interest/${id}/${candidatesList.current[index].favorite? 'unlike' : 'like'}`, (res) => {
            setStarHover(false);
            setSmallLoading(false);
        }, (err) => {
            setSmallLoading(false);
            setStarHover(index);
            candidatesList.current = safeGuard;
            setStarHover(false);
        })

        candidatesList.current[index].favorite = !candidatesList.current[index].favorite;
        if(candidatesList.current[index].favorite) {
            let aux = candidatesList.current[index];
            candidatesList.current.splice(index, 1);
            let listAux = [aux];
            candidatesList.current = listAux.concat(candidatesList.current);
        }
        setStarHover(false);
    }

    const infiniteScroll = () => {
        let bodyAux = document.getElementById(`candidatesScrollList`);
        if(nextPage.current && !loadingRef.current) {
            if (bodyAux.scrollTop + bodyAux.clientHeight >= bodyAux.scrollHeight - 20) {
                setFilters({...filtersRef.current, page: filtersRef.current.page + 1});
                filtersRef.current = {...filtersRef.current, page: filtersRef.current.page + 1};
            }
        }
    }

    const handleShowCandidate = (item,index, mode) => {
        showFeedback.current = mode;
        setSmallLoading(true);
        feedbackUser.current = Object.assign({}, item);
        feedbackUser.current.index = index;
        candidatesList.current[index].viewed = true;
        setShowCandidateDetails(true);
        setTimeout(() => {
            setSmallLoading(false);
        },[1000])
    }

    const disregardCurriculum = (item, index) => {
        setSmallLoading(true);
        let objToSend = {};
        objToSend.feedback_result = "Não aprovado no processo seletivo";
        objToSend.last_event = 3;
            objToSend.observation = 'Desconsiderado na primeira etapa';
        let safeGuard = Object.assign([], candidatesList.current);

        candidatesList.current.splice(index, 1);
        setShowCandidateDetails(false);

        api.post(`formal-working/job-opportunity/${props.vacancy}/interest/${item.id}/feedback`, objToSend, (res) => {
            window.gtagService('event', 'externalFastFeedbackSuccess');
            setSmallLoading(false);
        }, (e) => {
            candidatesList.current = safeGuard;
            setSmallLoading(false);
            message.destroy();
            if(e.message === 'Request failed') {
                message.error('Não foi possível enviar esse feedback para o SINE, tente novamente.');
            } else {
                message.error(e.message || 'Não foi possivel enviar o feedback deste profissional');
            }
            window.gtagService('event', 'externalFastFeedBackFail');
        });
    }

    const handleStepChange = (item,status) => {
        if(status !== item.status && !smallLoading) {
            updateCurriculumStatus(item,status);
            setSmallLoading(true);
            api.put(`formal-working/job-opportunity/${props.vacancy}/interest/${item.id}/set-status/${status}`, null, (e) => {
                    feedbackUser.current&& (feedbackUser.current.status = status)
                    setSmallLoading(false);
                    window.gtagService('event', 'externalStepChangeSuccess');
                },
                (err) => {
                    window.gtagService('event', 'externalStepChangeError');
                    message.error('Ocorreu um problema ao mover o currículo de etapa, tente novamente');
                    console.log(err);
                    setSmallLoading(false);
                })
        }
    }

    const handleSingleCheck = (item) => {
        let aux = checkedList;
        if(aux.indexOf(item) !== -1) {
            aux.splice(aux.indexOf(item), 1);
        } else {
            aux.push(item)
        }
        setCheckedList(aux);
        setCheckedCount(checkedList.length);
    }


    const updateCurriculumStatus = (item, status) => {
        message.destroy();
        candidatesList.current[item.index].status = status;
        if((candidatesList.current[item.index].status !== filters.search_status) && filters.search_status !== `all_not_finalized`) {
            candidatesList.current.splice(item.index, 1);
        }
        message.success('Curriculo movido para - ' + statusInterpreter[status]);
    }


    useEffect(() => {
        getCandidates();
    },[filters]);

    useEffect(() => {
        if(document.getElementById(`candidatesScrollList`)) document.getElementById(`candidatesScrollList`).addEventListener('scroll', infiniteScroll)
        return () => {
            if(document.getElementById(`candidatesScrollList`)) document.getElementById(`candidatesScrollList`).removeEventListener(`scroll`, infiniteScroll);
        }
    },[])

    return (
        <div className={'mainComponentPanel'} style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 0, marginBottom: 10, borderRadius:5, backgroundColor: "white"}}>

            {showCandidateDetails &&<ExternalResumeDrawer
                handleStepChange={(item,status) => handleStepChange({...item, id: item.interest_id},status)}
                arrowShortCut={true}
                currentIndex={feedbackUser.current.index}
                candidatesList={candidatesList.current}
                updateFeedbackUser={(item) => handleShowCandidate(item, item.index)}
                handleFavorite={() => handleFavorite(feedbackUser.current.id, feedbackUser.current.index)}
                favorite={feedbackUser.current.favorite}
                viewed={feedbackUser.current.viewed}
                getData={() => getCandidates()}
                vacancyFeedback={true}
                vacancy_id={props.vacancy}
                interest_id={feedbackUser.current.id}
                id={feedbackUser.current.contact_id}
                opportunity_id={props.vacancy}
                status={feedbackUser.current.status}
                opportunity_name={feedbackUser.current?.occupation_title || feedbackUser.current?.occupation_name}
                disregardCurriculum={() => disregardCurriculum(feedbackUser.current, feedbackUser.current.index)}
                recruited={true}
                cboId={false}
                visible={showCandidateDetails}
                showFeedback={showFeedback.current}
                setVisible={(item) => setShowCandidateDetails(item)} />}

            <Menu selectedKeys={filters.search_status} onClick={(e) => changeFilter('search_status',e.key)} mode="horizontal" style={{display: "flex", marginBottom: 5, justifyContent: "space-between"}}>
                <Menu.Item key="all_not_finalized">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FaUsers style={{marginRight: 10}}/>
                        <span>Em andamento </span>
                        {props.summary && <Badge style={{marginLeft: 5}} count={props.summary.selected + props.summary.pending + props.summary.interview}/>}
                    </div>
                </Menu.Item>
                <Menu.Item key="pending">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FaClock style={{marginRight: 10}}/>
                        <span>Pendentes </span>
                        {props.summary && <Badge style={{marginLeft: 5}} count={props.summary.pending}/>}
                    </div>
                </Menu.Item>
                <Menu.Item key="selected">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FaBookmark style={{marginRight: 10}}/>
                        <span>Em análise </span>
                        {props.summary && <Badge style={{marginLeft: 5}} count={props.summary.selected}/>}
                    </div>
                </Menu.Item>
                <Menu.Item key="interview">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FaPhone style={{marginRight: 10}}/>
                        <span>Entrevista </span>
                        {props.summary && <Badge style={{marginLeft: 5}} count={props.summary.interview}/>}
                    </div>
                </Menu.Item>
                <Menu.Item key="finished_approved_rejected" ke>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FaUserCheck style={{marginRight: 10}}/>
                        <span>Finalizados </span>
                    </div>
                </Menu.Item>
            </Menu>

            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', margin: 10, paddingTop: 5, paddingLeft: 5, paddingRight: 10, justifyContent: 'space-between', marginRight: 5}} className={'selectionFilter'}>
                {smallLoading &&<div style={{position: "absolute", right: 20, top: 20}}>
                    <Icon style={{marginLeft: 10, marginTop: 5, color: '#1680ce'}} type={'loading'}/>
                </div>}

                <div style={{display: 'flex'}}>
                    <Select
                        mode={'default'}
                        className={'dropdown200'}
                        onChange={(value) => {changeFilter("search_city", value);}}
                        allowClear={true}
                        showSearch
                        onSearch={(e) => setCityListState(CityList.filter((item) => item?.toLowerCase().search(e.toLowerCase()) !== -1 ))}
                        placeholder={<span> <FaMapMarkerAlt/> {Env.state_full} </span>}>
                        {cityListState?.map((item, index) =>
                            <Select.Option key={index} value={item}><FaMapMarkerAlt/> {item || Env.state_full}</Select.Option>
                        )}
                    </Select>
                </div>

                <div style={{display: 'flex'}}>
                    <Select
                        mode={'multiple'}
                        allowClear={true}
                        className={'dropdown200'}
                        onChange={(value) => {changeFilter("search_schooling", value, false)}}
                        placeholder={<span><MdBook style={{marginBottom: -2}}/> Escolaridade </span>}>
                        {schoolingList.map((item, index) =>
                            <Select.Option value={item}><FaBook style={{marginBottom: -2}}/> {item}</Select.Option>
                        )}
                    </Select>
                </div>

                <div style={{display: 'flex'}}>
                    <Select
                        mode={'default'}
                        className={'dropdown200'}
                        onChange={(value) => {changeFilter("search_favorite", value, false)}}
                        placeholder={<span><MdStarHalf style={{marginBottom: -2}}/> Todos </span>}>
                        <Select.Option value={undefined}><MdStarHalf style={{marginBottom: -2}}/> Todos</Select.Option>
                        <Select.Option value={true}><MdStar style={{marginBottom: -2}}/> Favoritos </Select.Option>
                        <Select.Option value={false}><MdStarBorder style={{marginBottom: -2}}/> Não Favoritos </Select.Option>
                    </Select>
                </div>

                <div style={{display: 'flex'}}>
                    <Select
                        mode={'default'}
                        onChange={(value) => {changeFilter("search_view", value, false)}}
                        placeholder={<span><IoMdEye style={{marginBottom: -2}}/> Todos </span>}>
                        <Select.Option value={true}><IoMdEye style={{marginBottom: -2}}/> Já visualizados </Select.Option>
                        <Select.Option value={false}><GoEyeClosed style={{marginBottom: -2}}/> Não visualizados </Select.Option>
                        <Select.Option value={undefined}><IoMdEye style={{marginBottom: -2}}/> Todos </Select.Option>
                    </Select>
                </div>

            </div>


            <form style={{display: 'flex', alignItems: 'center', paddingLeft: 10, paddingTop: 10, paddingRight: 10, marginBottom: 15}} onSubmit={(event) => {
                event.preventDefault();
                changeFilter(searchText.type, searchText.value, true)
            }}>
                <Input  value={searchText.value} onChange={(e) => setSearchText({value: e.target.value, type: 'search_global' })} style={{flex: 1, borderColor: 'whitesmoke', height: 35, marginRight: 4,}}  placeholder={'Busque pelo nome'} />
                <div onClick={() => changeFilter(searchText.type, searchText.value, true)} style={{cursor: 'pointer', display: 'flex', backgroundColor: '#f5f5f5', marginLeft: 5, borderRadius: 20, padding: 5, color: '#bfbfbf'}}>
                    <MdSearch style={{fontSize: 23, color: '#9e9e9e'}}/>
                </div>
            </form>

            <div id={`candidatesScrollList`} style={{maxHeight: window.innerHeight - 270, overflowY: `scroll`, borderTop: `1px solid #f1f1f1`, padding: 5}}>
                <div style={{display: 'flex', alignItems: 'center', flexWrap:"wrap", padding: 5, justifyContent: "space-between", paddingRight: 0, gap:16}}>

                    {(filters.search_status === 'finished_approved_rejected' || filters.search_status  === 'approved' || filters.search_status  === 'rejected') ?<div style={{display: 'flex'}}>
                            <div onClick={() => changeFilter('search_status','approved')} style={{padding: 3, cursor: 'pointer', borderRadius: 20, marginRight: 10, fontSize: 12, display: 'flex', paddingLeft: 10, paddingRight: 10, alignItems: 'center', border:  '1px solid #89c189', background:   (filters.search_status === 'approved' || filters.search_status === 'finished_approved_rejected')&& '#e9ffdb'}}>
                                <MdThumbUp style={{marginRight: 2}}/>
                                <span> Aprovados </span>
                            </div>

                            <div onClick={() => changeFilter('search_status','rejected')} style={{padding: 3, cursor: 'pointer', borderRadius: 20, marginRight: 10, fontSize: 12, display: 'flex', paddingLeft: 10, paddingRight: 10, alignItems: 'center', border: '1px solid #ff8383', background: (filters.search_status === 'rejected' || filters.search_status === 'finished_approved_rejected') && 'rgb(255 230 228)'}}>
                                <MdThumbDown style={{marginRight: 2}}/>
                                <span> Reprovados </span>
                            </div>

                        </div>
                        :
                        <div style={{display: `flex`, alignItems: `center`}}>
                            {props.opportunity_id ?
                                <>
                                    <div onClick={() => setAllowSelect(!allowSelect)}
                                         style={{borderRadius: 3, marginRight: 5, cursor: 'pointer', marginLeft: 5, height: 15, width: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: allowSelect ? '#1680ce' : 'white', border: allowSelect ? '1px solid #1680ce' : '1px solid #9e9e9e'}}>
                                        {allowSelect &&<Icon style={{color: 'white', fontSize: 12 }} type="check" />}
                                    </div>
                                    <span>{checkedCount > 0 ? `${checkedCount} Currículos selecionados` : 'Habilitar para selecionar'}</span>
                                </>
                                : null
                               /* <div style={{marginLeft: 3}}>
                                    <Checkbox
                                        onChange={()=> changeFilter("open_opportunity", filters.open_opportunity? undefined : true  )}>
                                        Somente currículos de vagas abertas
                                    </Checkbox>
                                </div>*/
                            }
                        </div>}

                    <div style={{display: 'flex', gap:10}}>

                        <div onClick={() =>changeFilter("order_field", filters.order_field === 'name' ? undefined : 'name', false)}
                             style={{padding: 3, cursor: 'pointer', borderRadius: 20, fontSize: 12, display: 'flex', paddingLeft: 10, paddingRight: 10, alignItems: 'center', border: '1px solid gainsboro', background:  filters.order_field === 'name' && '#eaeaea'}}>
                            <MdArrowDownward style={{marginRight: 2}}/>
                            <span> Nome </span>
                        </div>

                        <div onClick={() => changeFilter("order_field", filters.order_field === 'age' ? undefined : 'age', false)}
                             style={{padding: 3, cursor: 'pointer', borderRadius: 20, fontSize: 12, display: 'flex', paddingLeft: 10, paddingRight: 10, alignItems: 'center', border: '1px solid gainsboro', background:  filters.order_field === 'age' && '#eaeaea'}}>
                            <MdArrowDownward style={{marginRight: 2}}/>
                            <span> Idade </span>
                        </div>
                    </div>

                </div>

                {(candidatesList.current?.length > 0) &&<List
                    className='listAnt listAntCandidates'
                    style={{overflow: 'auto', width: '100%'}}
                    itemLayout="horizontal"
                    dataSource={candidatesList.current}
                    loading={loading || loadingMore}
                    renderItem={(item, index) => (
                        <List.Item key={index} style={{padding: 0, position: 'relative', backgroundColor: 'white', border: checkedList.includes(item.interest_id)? '1px solid #1680ce' : 'unset'}} >
                            {/*{(item.gender !== null && allowSelect) &&<div onClick={() => handleSingleCheck(item.interest_id)} style={{borderRadius: 3, cursor: 'pointer', marginLeft: 5, height: 18, width: 18, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: checkedList.includes(item.interest_id) ? '#1680ce' : 'white', border: checkedList.includes(item.interest_id) ? '1px solid #1680ce' : '1px solid #9e9e9e'}}>
                                {checkedList.includes(item.interest_id) &&<Icon style={{color: 'white' }} type="check" />}
                            </div>}
                            <div style={{
                                backgroundColor: '#ededed',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                border: '1px solid #f7f7f7',
                                paddingLeft: 10,
                                paddingRight: 10,
                                fontSize: 12,
                                borderRadius: 4,
                                borderBottomLeftRadius: 0,
                                borderTopRightRadius: 0
                            }}>
                                {item.occupation_title || item.occupation_name} - #{item.opportunity_id}
                            </div>*/}
                            <List.Item.Meta
                                onClick={() => {
                                    handleShowCandidate(item, index, undefined)
                                }}
                                style={{alignItems: 'center', padding: 10, paddingTop: 10, cursor: 'pointer', position: 'relative'}}
                                avatar={
                                    item.profile_picture?
                                        <img style={{width: 64, height: 64, borderRadius: '50%', objectFit: "cover", backgroundColor: '#f5f5f5'}} src={'https://crafty-api.s3.amazonaws.com/public/worker_full/' + item.profile_picture}/>
                                        :
                                        <Avatar style={{width: 64, height: 64, padding: 10, backgroundColor: '#f5f5f5'}} size='default' src={item.profile_picture || Images.lettersPack[item.name[0].toLowerCase()]}/>
                                }
                                title={<div style={{display: 'flex', flexDirection: 'column'}}>
                                    <div style={{display: `flex`}}>
                                        <span style={{fontWeight: "bold"}}>{item.name}  </span>
                                        {!item.viewed &&<div style={{ marginLeft: 10, fontSize: 10, borderRadius: 5, paddingLeft: 4, paddingRight: 4, background: `#c3ffc3`}}>
                                            <span style={{color: "#6bbe1d"}}> Novo </span>
                                        </div>}
                                    </div>
                                    <div>
                                        {item.idade && <span style={{color: "darkgray", fontSize: 13, fontWeight: 400, marginRight: 20}}> <FaUser style={{color: "darkgray", marginBottom: -2}}/> {item.idade + ' Anos'} </span>}
                                        {item.schooling && <span style={{color: "darkgray", fontSize: 13, fontWeight: 400, marginRight: 20}}> <FaBook style={{color: "darkgray", marginBottom: -2}}/> {item.schooling} </span>}

                                    </div>
                                </div>}
                                description={<div>
                                    {/*<div style={{display: 'flex'}}>
                                        <div>
                                            <span>
                                                <BiBriefcaseAlt style={{color: "darkgray", marginBottom: -2}}/> {item.occupation} {(item.total_experience > 11)? ` - ${(item.total_experience/12).toFixed(0)} ano(s) de experiência` : (item.total_experience > 0) && ` - ${item.total_experience} meses de experiencia` }
                                            </span>
                                        </div>
                                    </div>*/}

                                    <div>
                                        <span>
                                            <FaMapMarkerAlt style={{color: "darkgray", marginBottom: -2}}/> {item.city}
                                        </span>
                                    </div>
                                </div>}
                            />


                            {(item.status !== 'approved' && item.status !== 'rejected') &&<div style={{display: 'flex', flexFlow: 'column', marginTop: 10, marginRight: 20}}>
                                <div style={{display: `flex`, justifyContent: `center`}}>
                                    <Popover placement={"topRight"} content={'Enviar currículo para a etapa APROVADOS'} title={'Aprovar Candidato'}>
                                        <div className={`miniStatusButtons`} onClick={() => {
                                            handleShowCandidate(item, index, 'approved')
                                        }} style={{padding: 7, cursor: `pointer`, marginRight: 10, borderRadius: '50%', backgroundColor: item.status === `approved`? `#1680ce` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                            <FaThumbsUp style={{color: 'rgb(134 214 95)'}}/>
                                        </div>
                                    </Popover>
                                    <Popover placement={"topRight"} content={'Enviar currículo para a etapa REPROVADOS'} title={'Reprovar Candidato'}>
                                        <div className={`miniStatusButtons`} onClick={() => {
                                            handleShowCandidate(item, index, 'rejected')
                                        }} style={{padding: 7, cursor: `pointer`, borderRadius: '50%', backgroundColor: item.status === `rejected`? `#1680ce` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                            <FaThumbsDown  style={{color: 'rgb(243 135 114)', transform: 'rotateY(180deg)'}}/>
                                        </div>
                                    </Popover>
                                </div>
                                <div style={{display: 'flex', marginTop: 10 }}>
                                    <Popover placement={"topRight"} content={'Enviar currículo para a etapa PENDENTES'} title={'Enviar para PENDENTES'}>
                                        <div className={`miniStatusButtons`} onClick={() => handleStepChange({...item, index},`pending`)} style={{padding: 7, cursor: `pointer`, borderRadius: '50%', backgroundColor: item.status === `pending`? `#1680ce` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                            <FaClock style={{color: item.status === 'pending' ? `white` : '#bdbdbd'}}/>
                                        </div>
                                    </Popover>
                                    <Popover placement={"topRight"} content={'Enviar currículo para a etapa EM ANÁLISE'} title={'Enviar para EM ANÁLISE'}>
                                        <div className={`miniStatusButtons`} onClick={() => handleStepChange({...item, index},`selected`)} style={{padding: 7, cursor: `pointer`, borderRadius: '50%', marginLeft: 5, marginRight: 5, backgroundColor: item.status === `selected`? `#673ab7` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                            <FaBookmark  style={{color: item.status === 'selected' ? `white` : '#bdbdbd'}}/>
                                        </div>
                                    </Popover>
                                    <Popover placement={"topRight"} content={'Enviar currículo para a etapa ENTREVISTA'} title={'Enviar para ENTREVISTA'}>
                                        <div className={`miniStatusButtons`} onClick={() => handleStepChange({...item, index},`interview`)} style={{padding: 7, cursor: `pointer`, borderRadius: '50%', backgroundColor: item.status === `interview`? `#009688` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                            <FaPhone  style={{color: item.status === 'interview' ? `white` : '#bdbdbd'}}/>
                                        </div>
                                    </Popover>
                                </div>
                            </div>}


                            <div onClick={() => handleFavorite(item.id, index)} style={{fontSize: 20, alignItems: 'center', display: 'flex', position: "absolute", top: 5, right: 5}} onMouseLeave={() => setStarHover(false)} onMouseEnter={() => setStarHover(index)} >
                                {(starHover === index || item.favorite) ? <MdStar style={{color: "gold"}} className={'candidateStar'}/> : <MdStarBorder style={{color: 'gainsboro'}}/>}
                            </div>
                        </List.Item>
                    )}
                />}
            </div>

            {(candidatesList.current.length === 0 && !loading) &&
                <div className={'empty-card'} style={{boxShadow: "unset"}}>
                    <div style={{marginLeft: '20px', color: '#545a5d'}}>
                        <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                            <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                            <span> Você não possui nenhum candidato nesta etapa ou com o filtro informado </span>
                        </div>
                    </div>
                </div>
            }

          {/*  {checkedCount > 0 &&<FeedbackStepsMultipleModal getCandidates={() => getCandidates()} setCheckedList={() => {
                setCheckedList([])
                setCheckedCount(0);
            }} userId={props.user.user_id} vacancyId={props.opportunity_id} checkedCount={checkedCount} checkedList={checkedList}/>}*/}
        </div>
    );
};

AllCandidatesListComponent.propTypes = {
    loading: PropTypes.any,
    resumesList: PropTypes.any,
    cboId: PropTypes.any,
    showDrawer: PropTypes.any
};

export default AllCandidatesListComponent;
