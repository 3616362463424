import React, {useCallback, useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Form,
    Icon,
    Input,
    Menu,
    message,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
    Tooltip
} from "antd";
import moment from "moment";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";
import useApi from "../../../../../Hooks/Api";
import {showNotification, useValidationShorts} from "../../../../../Hooks/Helpers";
import useLocalStorage from "../../../../../Hooks/Storage";
import {FaEdit, FaForward} from "react-icons/fa";
import {StorageVariables} from "../../../../../Helpers/StoragesVariables";
import {countries} from "../../../../../ApplicationAssets/Nationalities";
import {ethnicityList} from "../../../../../ApplicationAssets/indigenousRO"
import {Env} from "../../../../../Helpers/Env";
import {BsUpload} from "react-icons/all";
import {CgClose} from "react-icons/cg";
import {BsCheck} from "react-icons/bs";
import {debounce} from "lodash";
import useAuth from "../../../../../Hooks/Auth";


const disabilitiesList = [{label: 'Surdez bilateral parcial', value: 'Surdez bilateral parcial'}, {label: 'Surdez bilateral total', value: 'Surdez bilateral total'}, {label: 'Amputação', value: 'Amputação'}, {label: 'Cadeirante', value: 'Cadeirante'}, {label: 'Membros Inferiores', value: 'Membros Inferiores'}, {label: 'Um membro Inferior', value: 'Um membro Inferior'}, {label: 'Membros Superiores', value: 'Membros Superiores'}, {label: 'Um membro superior', value: 'Um membro superior'}, {label: 'Nanismo', value: 'Nanismo'}, {label: 'Ostomia', value: 'Ostomia'}, {label: 'Paralisia Cerebral', value: 'Paralisia Cerebral'}, {label: 'Parcial', value: 'Parcial'}, {label: 'Mental/Intelectual', value: 'Mental/Intelectual'}, {label: 'Baixa Visão', value: 'Baixa Visão'}, {label: 'Cegueira', value: 'Cegueira'}, {label: 'Auditiva não informada', value: 'Auditiva não informada'}, {label: 'Física não informada', value: 'Física não informada'}, {label: 'Mental não informada', value: 'Mental não informada'}, {label: 'Visual não informada', value: 'Visual não informada'}, {label: 'Múltipla não informada', value: 'Múltipla não informada'}];
const schoolingList = ['Analfabeto', 'Fundamental Incompleto', 'Fundamental Completo', 'Médio Incompleto', 'Médio Completo', 'Superior Incompleto', 'Superior Completo', 'Mestrado', 'Doutorado', 'Especialização'];
const driversLicenseList = ['A', 'AB', 'AC', 'AD', 'AE', 'B', 'C', 'D', 'E'];
const languagesList = [{label: 'Alemão', value: 'Alemão'}, {label: 'Espanhol', value: 'Espanhol'}, {label: 'Francês', value: 'Francês'}, {label: 'Inglês', value: 'Inglês'}, {label: 'Italiano', value: 'Italiano'}];
const familyIncome = [{label:'Abaixo de 1 salário mínimo', value:'below_one'},{label:'Entre 1 a 2 salários mínimos', value:'one_to_two'},{label:'Entre 2 a 5 salários mínimos', value:'two_to_five'},{label:'Entre 5 a 10 salários mínimos', value:'five_to_ten'},{label:'Acima de 10 salários mínimos', value:'above_ten'},{label:'Prefiro não responder', value: undefined}]

const vehiclesList = [ {label: 'Bicicleta', value: 'Bicicleta'},{label: 'Caminhão', value: 'Caminhão'}, {label: 'Carro', value: 'Carro'}, {label: 'Moto', value: 'Moto'},{label: 'Outro Veículo motorizado', value: 'Outro Veículo motorizado'}, {label: 'Outro Veículo não motorizado', value: 'Outro Veículo não motorizado'}, {label: 'Veículo de Tração Animal', value: 'Veículo de Tração Animal'}];
const childrenQtd = [{label: '1', value: '1'}, {label: '2', value: '2'}, {label: '3', value: '3'}, {label: '4', value: '4'}, {label: '5', value: '5'}, {label: '6', value: '6'}, {label: '7', value: '7'}, {label: '8', value: '8'}, {label: '9', value: '9'}, {label: '10', value: '10'}, {label: '11', value: '11'}, {label: '12', value: '12'}, {label: '13', value: '13'}, {label: '14', value: '14'}, {label: '15', value: '15'}, {label: 'Mais de 16', value: '16'},]
const scholarship = [{label: 'Infantil', value: 'Infantil'}, {label: 'Fundamental', value: 'Fundamental'}, {label: 'Médio', value: 'Médio'}, {label: 'Já passaram da fase escolar', value: 'Já passaram da fase escolar'},]

const AboutComponent = (props) => {

    const {getFieldDecorator, getFieldsValue,setFieldsValue, validateFields, getFieldsError} = props.form;
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const {isValidDate, numMinWithMask, minAge} = useValidationShorts();
    const [initialLoading, setInitialLoading] = useState(true);
    const [basicData, setBasicData] = useState({});
    const [loading, setLoading] = useState(false);
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);
    const {storeSineProfile} = useAuth()

    const [showPcd, setShowPcd] = useState(false);
    const [showVehicles, setShowVehicles] = useState(false);
    const [showLanguages, setShowLanguages] = useState(false);
    const [showCourses, setShowCourses] = useState(false);
    const [showCadunico, setShowCadunico] = useState(false);
    const [showIndigenous, setShowIndigenous] = useState(false);
    const [cepLoading, setCepLoading] = useState(false);
    const [coursesCategories, setCoursesCategories] = useState([])
    const [imagePreview, setImagePreview] = useState(null)
    const [loadingPreview,setLoadingPreview] = useState(false)
    const [showMessageSaved, setShowMessageSaved] = useState(false)
    const [editNationality,setEditNationality] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [showSocialName, setShowSocialName] = useState(false)

    let messageTip = <span>Por que pedimos isso - Para te oferecer benefícios e soluções que se aplicam ao seu perfil, esses dados não serão divulgados com os empregadores.</span>

    const getFullData = () => {
        api.get(`sine/user/${user?.user_id}/full`, (res) => {
            if(res.user_id !== null) {
                props.setHasProfile(true);

                if(res.profile.vehicles.length > 0) setShowVehicles(true);
                if(res.profile.disabilities.length > 0) setShowPcd(true);
                if(res.profile.languages.length > 0) setShowLanguages(true);
                if(res.profile.training_interests_categories.length > 0) {setShowCourses(true); getCoursesCategories();}
                if(res.profile.indigenous_person) setShowIndigenous(true);
                if(res.profile.has_cadunico) setShowCadunico(true);
                if (res.profile.pcd_report) setImagePreview(res.profile.pcd_report)
                if (res.profile.is_immigrant === true) {
                    if (res.profile.nationality !== null) {
                        setEditNationality(false)
                    }
                }
                //if(res.profile.is_immigrant) setShowImmigrant(true);
                //if(res.profile.nationality) setNationality(res.profile.nationality)

                if (res.profile.social_name) {
                    setShowSocialName(true)
                }

                setBasicData(res.profile);
            }
            setInitialLoading(false);
        },(e) => {
            console.log(e);
        });
    };

    const registerContacts = async () => {
        api.post(`sine/user/${user.user_id}/phone-number`, {number: user.phone, primary: true}, (res) => {}, (e) => {});
        api.post(`sine/user/${user.user_id}/email`, {email: user.email, primary: true},(res) => {}, (e) => {});
    };

    const handleSubmit = async () => {
        validateFields(async (error, values) => {
            if (!showCourses) values.training_interests_ids = []
            if (error) { message.warning('Preencha os campos corretamente'); return 0;}
            setLoading(true);

            //values.nationality = nationality;
            //values.is_immigrant = showImmigrant;

            message.loading('Adicionando...');
            if(values.wage_claim === "") {
                delete values.wage_claim;
            } else if(values.wage_claim !== undefined) {
                values.wage_claim = values.wage_claim.replace(/\./g, "").replace(",", ".").replace('R$',"");
            }
            if(!showVehicles) values.vehicles = [];
            if(!showLanguages) values.languages = [];
            if(!showPcd) values.disabilities = [];
            if (!showPcd && basicData?.pcd_report !== null) {
                api.del(`sine/user/${user.user_id}/pcd-report`, null, () => {
                }, ()=> {
                })
            }
            if(!showCourses) values.training_interests = [];
            if(!showIndigenous) {
                values.ethnicity = undefined;
                values.native_village = undefined;
            } else {
                if(!values.ethnicity || !values.native_village) {
                    message.destroy();
                    message.warning('Informe sua Aldeia e Etnia');
                    setLoading(false);
                    return;
                }
                values.indigenous_person = true;
            }
            if(showCadunico) {values.has_cadunico = true;} else {values.has_cadunico = false; values.cadunico_number = undefined}

            values.address.postal_code = window.getNumbers(values.address.postal_code);

            if (!showSocialName && values.social_name) {
                values.social_name = ""
            }

            if(moment().diff(moment(values.birthday, 'DD/MM/YYYY'), 'years') <= 13) {
                message.destroy();
                message.warning('Preencha sua data de nascimento corretamente/ Você deve ter no mínimo 14 anos de idade');
                setLoading(false);
                return;
            }

            if (!values?.has_children) {
                values.children = 0
                values.children_schooling = null
            }

            if(values.is_immigrant === false) {
                values.is_refugee = false;
            }
            if (basicData.nationality_id && values.is_immigrant === false) {
                values.nationality_id = null
            } else {
                values.nationality_id = basicData?.nationality_id
            }

            values.birthday = moment(values.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');
            values.about_me = basicData?.about_me || undefined;

            api.post(`sine/user/${user.user_id}/profile`,values, (res) => {
                message.destroy();
                message.success('Sucesso!');

                api.get(`sine/user/${user.user_id}/full`, (sine) => {
                    if(sine.user_id !== null) {
                        hasProfileStorage.setPrimitive(true)
                    } else {
                        hasProfileStorage.setPrimitive(false);
                    }
                    storeSineProfile(sine);

                }, ()=> {})
                if(!props.hasProfile) {
                    props.setHasProfile(true);
                    registerContacts();
                    hasProfileStorage.setPrimitive(true);
                }
                props.handleChangeStep(1);
                setLoading(false);
            }, (e) => {
                message.error('Não foi possivel adicionar seus dados, tente novamente');
                setLoading(false);
            });
        })
    };

    const onSearchNationality = useCallback(debounce((text) => {
        api.get(`/sine/search_nationalities?query=${text}`, (res) => {
            setCountryList(res)
            setLoadingSearch(false)
        }, (err) =>{
            console.log(err)
            setLoadingSearch(false)
        })
    }, 700), []);

    const countryOptions = () => {
        return (
            <Menu style={{maxHeight: 300, overflow:"auto"}}>
                {countryList.length === 0 ?
                    <Menu.Item disabled={true} style={{textAlign:'center'}}>
                        Nenhum resultado
                    </Menu.Item>
                    :
                    countryList.map((item) =>
                        <Menu.Item onClick={() => {
                            setEditNationality(false)
                            setBasicData({
                                ...basicData,
                                nationality_id: item.id,
                                country: '',
                                nationality: item.demonym
                            })
                        }} >
                            {item?.country_name}
                        </Menu.Item>
                    )
                }
            </Menu>
        )
    }

    const onCepChange = async (e) => {
        const cep = window.getNumbers(e.target.value);
        if (cep?.length === 8) {
            setCepLoading(true);
            api.get(`search/postal-code?cep=${cep}`, (res) => {
                if(res) {
                   /* if (res.state !== Env.state_restriction) {
                        setFieldsValue({address: {district: "", street: "", city: "", state_abbrev: "", street_number:"", postal_code: ""}});
                        showNotification()
                    } else {
                        setFieldsValue({address: { district: res.district, street: res.street_name, city: res.city, state_abbrev: res.state}});
                    }*/
                    setFieldsValue({address: { district: res.district, street: res.street_name, city: res.city, state_abbrev: res.state}});
                }
                setCepLoading(false);
            }, (e) => {
                message.warning('CEP inválido');
                setFieldsValue({address: {postal_code: undefined}});
                console.log(e);
                setCepLoading(false);
            });
        }
    }

    const getCoursesCategories = () => {
        if (coursesCategories.length === 0) {
            api.get(`courses/categories`, (res)=> {
                setCoursesCategories(res.map((item) => {return {value: item.id, label: item.name}}))
            }, (err) => {})
        }
    }

    const handleFileChange = (e) => {
        e.preventDefault()
        document.getElementById('fileInput').click()
    }

    const fileChangedHandler =  async (event) => {
        message.loading('Salvando...')
        setLoadingPreview(true)
        setShowMessageSaved(true)
        let file = (event.target.files[0]);
        let formData = new FormData();
        formData.append("pcd-report", file);

        api.post(`sine/user/${user.user_id}/pcd-report`,formData, (res) => {
            message.destroy()
            setImagePreview(res)
            previewImageConvertBase64(file)
            setTimeout(() => {setShowMessageSaved(false);},[3000])
            setLoadingPreview(false)
            message.success('Imagem salva!')

        }, (err)=> {
            message.error(err.message)
            setLoadingPreview(false)
        }, true)
    };

    const handleClearImage = () => {
        message.loading('Excluindo...')
        setImagePreview(null)
        document.getElementById('fileInput').value = ''

        api.del(`sine/user/${user.user_id}/pcd-report`, null, () => {
            message.destroy()
            message.success('Imagem deletada!')
        }, (err)=> {
            message.error(err.message)
        })
    }

    const previewImageConvertBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImagePreview(reader.result)
        }
    }

    const selectAllCourses = (label) => {
        let aux = []
        if (label === 'check_all') {
            coursesCategories.forEach((item) => (
                aux.push(item.value)
            ))
        } else if (label === 'check_all') {
            aux = []
        }
        setFieldsValue({training_interests_ids: aux})

    }

    useEffect(() => {
        getFullData();
        onSearchNationality('');
        window.scrollToElement('body');

    },[]);


    return (<div style={{minHeight: 500}} className={'aboutCss'}>
        {initialLoading&&<div className={'loadingResume'}> <Spin/> </div>}

        {!initialLoading && <>
        <Row className={'formTitle'}> Dados pessoais </Row>
        <Row gutter={[24,24]} className={'formRow'} style={{backgroundColor: 'white', margin: 0}}>
            <div style={{display:"flex", flexWrap:"wrap"}}>
                <Col xs={24} sm={12} lg={8} className={'formItem'}>
                    <Form.Item>
                        <div className='resumeLabel'>
                            <label>
                                <span> * </span>
                                Nome
                                {getFieldDecorator('name',
                                    {
                                        rules: [{
                                            required: true,
                                            message: 'Nome é obrigatório',
                                        }],
                                        initialValue: basicData.name || user.name || '',
                                    }
                                )(
                                    <Input maxLength={80}
                                           style={{marginBottom: 5}}
                                           placeholder='Digite seu nome'
                                           size={'large'}/>
                                )}
                            </label>
                            <div style={{display:"flex", justifyContent:"end", lineHeight:1}}>
                                <small onClick={() => setShowSocialName(!showSocialName)} style={{marginBottom:0, fontSize:12, cursor:"pointer", transition:".2s all"}} className={'ant-btn-ghost'}>Gostaria de informar um nome social?</small>
                            </div>
                        </div>

                    </Form.Item>
                    {/*<span style={{color: 'red', fontSize: 13}}> {getFieldsError().name} </span>*/}
                </Col>

                {(showSocialName) &&
                    <Col xs={24} sm={12} lg={8} className={'formItem'}>
                        <Form.Item>
                            <div className='resumeLabel'>
                                <label>
                                    Nome social (opcional)
                                    {getFieldDecorator('social_name',
                                        {
                                            initialValue: basicData.social_name,
                                        }
                                    )(
                                        <Input maxLength={80}
                                               style={{marginBottom: 5}}
                                               placeholder='Informe outro nome para exibição'
                                               size={'large'}
                                        />
                                    )}
                                </label>
                            </div>

                        </Form.Item>
                        {/*<span style={{color: 'red', fontSize: 13}}> {getFieldsError().name} </span>*/}
                    </Col>
                }

                <Col xs={24} sm={12} lg={8} className={'formItem'}>
                    <Form.Item>
                        <label>
                            <span style={{color:'#D1392FFF'}}> * </span>
                            Gênero
                            {getFieldDecorator('gender',
                                {
                                    rules: [{
                                        required: true,
                                        message: 'Gênero é obrigatório'
                                    }],
                                    initialValue: basicData.gender || user.gender || undefined
                                }
                            )(
                                <Select
                                    showSearch
                                    style={{marginBottom:5}}
                                    placeholder="Selecione seu gênero"
                                    optionFilterProp="children"
                                    size={'large'}
                                >
                                    <Select.Option value={'male'}> Masculino </Select.Option>
                                    <Select.Option value={'female'}> Feminino </Select.Option>
                                    <Select.Option value={'transgender_male'}> Homem transgênero </Select.Option>
                                    <Select.Option value={'transgender_female'}> Mulher transgênero </Select.Option>
                                    <Select.Option value={'transsex_male'}> Homem Transexual </Select.Option>
                                    <Select.Option value={'transsex_female'}> Mulher Transexual </Select.Option>
                                    <Select.Option value={'unknown'}> Não sei responder </Select.Option>
                                    <Select.Option value={'not_anwswered'}> Prefiro não responder </Select.Option>
                                    <Select.Option value={'other'}> Outros </Select.Option>
                                </Select>
                            )}
                        </label>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} lg={8} className={'formItem'}>
                    <Form.Item>
                        <div className='resumeLabel'>
                            <label>
                                <span> * </span>
                                Data de nascimento
                                {getFieldDecorator('birthday',
                                {
                                    rules: [{
                                        required: true,
                                        message: 'Data de nascimento é obrigatório',
                                    },
                                        isValidDate(),
                                        minAge(moment().locale(`pt-br`))
                                    ],
                                    initialValue: basicData.birthday? (moment(basicData.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY') || undefined) : (user.birthday? moment(user.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined)
                                }
                                )(
                                    <NumberFormat type={"tel"}
                                                  style={{textAlign: 'center', marginBottom:5}}
                                                  className={'ant-input ant-input-lg'}
                                                  format="##/##/####"
                                                  placeholder="DD/MM/YYYY"/>
                                )}
                            </label>
                        </div>
                    </Form.Item>
                    {/*<div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().birthday} </span> </div>*/}
                </Col>

                <Col xs={24} sm={12} lg={8} xl={6} className={'formItem'}>
                    <Form.Item>
                        <>
                            <label>
                                <span style={{color:'#D1392FFF'}}> * </span>
                                Qual sua escolaridade ?
                            </label>

                            {getFieldDecorator('schooling',
                                {
                                    rules: [{required: true, message: 'Escolaridade é obrigatório'}],
                                    initialValue: basicData.schooling || undefined,
                                }
                            )(
                                <Select
                                    placeholder="Grau de escolaridade"
                                    style={{marginBottom:5}}
                                    size={'large'}
                                >
                                    {schoolingList.map((item, index) =>
                                        <Select.Option value={item} key={index}>{item}</Select.Option>
                                    )}
                                </Select>
                            )}
                        </>
                    </Form.Item>

                    {/*<div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().schooling} </span> </div>*/}
                </Col>

                <Col xs={24} sm={12} lg={8} xl={6} className={'formItem'}>
                    <Form.Item>
                        <div className='resumeLabel'>
                            <label>
                                Pretensão Salarial
                                {getFieldDecorator('wage_claim',
                                    {
                                        rules: [],
                                        initialValue: basicData.wage_claim? basicData.wage_claim.toString().replace('.', ",") : undefined,
                                    }
                                )(
                                    <NumberFormat maxLength={14}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  className='ant-input ant-input-lg'
                                                  thousandSeparator={'.'}
                                                  decimalSeparator={','}
                                                  prefix={'R$ '}
                                                  style={{textAlign: "right", marginBottom:5}}
                                                  placeholder={'Valor pretendido'}
                                                  size={'large'}/>
                                )}
                            </label>
                        </div>
                    </Form.Item>
                </Col>


                <Col xs={24} sm={12} lg={8} xl={6} className={'formItem'}>
                    <Form.Item>
                        <div>
                            <label>
                                Possui CNH ?
                                {getFieldDecorator('driver_license',
                                    {
                                        rules: [],
                                        initialValue: basicData.driver_license || undefined,

                                    }
                                )(
                                    <Select
                                        placeholder="Categoria da habilitação"
                                        style={{marginBottom:5}}
                                        size={'large'}
                                        allowClear={true}
                                    >
                                        {driversLicenseList.map((item, index) =>
                                            <Select.Option key={index} value={item}>{item}</Select.Option>
                                        )}
                                    </Select>
                                )}
                            </label>
                        </div>
                    </Form.Item>
                </Col>

            </div>
            {/*<Col xs={24} sm={12} lg={8} className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span> Qual sua nacionalidade ?</label></div>
                <AutoComplete
                    disabled={props.edit}
                    size="large"
                    onSelect={(value, option) => setNationality(value)}
                    style={{width: '100%', display: autocompleteSelected && 'none'}}
                    dataSource={countriesList?.map(item => {
                        return (<AutoComplete.Option key={item.value} label={item.label} value={item.value}>
                                {item.label}
                            </AutoComplete.Option>
                        )
                    })}
                    onSearch={(query) => autoCompleteSearch(query)}
                    optionLabelProp="text"
                >
                    <Input size={'large'} placeholder={nationality ? nationality : 'Selecione a nacionalidade'}/>
                </AutoComplete>
            </Col>*/}
           {/* <Col  xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>

                <div className='resumeLabel'
                     style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}><label> Você é imigrante? </label>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showImmigrant}>
                            <Radio value={true} onChange={() => {
                                setShowImmigrant(true)
                            }}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {
                                setShowImmigrant(false)
                            }}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
            </Col>*/}
            <div>
                <Col xs={24} className={'formItem'} style={{paddingTop: 0}} >
                    <div className='resumeLabel' style={{marginBottom: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Fala outro idioma? </label>
                        <div>
                            <Radio.Group className={'radio'} name="languageRadio" defaultValue={showLanguages}>
                                <Radio value={true} onChange={() => {setShowLanguages(true)}}>
                                    Sim
                                </Radio>
                                <Radio value={false} onChange={() => {setShowLanguages(false)}}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    {getFieldDecorator('languages',
                        {
                            rules: [],
                            initialValue: basicData.languages || undefined,
                        }
                    )(
                        <Checkbox.Group
                            style={{display: !showLanguages&& "none"}}
                            options={languagesList}
                        />
                    )}
                </Col>

                <Col xs={24} className={'formItem'} >
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Possui algum veículo ? </label>
                        <div>
                            <Radio.Group className={'radio'} name="languageRadio" defaultValue={showVehicles}>
                                <Radio value={true} onChange={() => {setShowVehicles(true)}}>
                                    Sim
                                </Radio>
                                <Radio value={false} onChange={() => {setShowVehicles(false)}}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        </div>
                    </div>

                    {getFieldDecorator('vehicles',
                        {
                            rules: [],
                            initialValue: basicData.vehicles || undefined,
                        }
                    )(
                        <Checkbox.Group
                            style={{display: !showVehicles&& "none"}}
                            options={vehiclesList}
                        />
                    )}
                </Col>
                <Col xs={24} className={'formItem'} >
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> É PcD ? (Pessoa com deficiência) </label>
                        <div>
                            <Radio.Group className={'radio'} name="languageRadio" defaultValue={showPcd}>
                                <Radio value={true} onChange={() => {setShowPcd(true)}}>
                                    Sim
                                </Radio>
                                <Radio value={false} onChange={() => {setShowPcd(false)}}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    {getFieldDecorator('disabilities',
                        {
                            rules: [{
                                required: showPcd,
                                message:'Selecione uma das opções'
                            }],
                            initialValue: basicData.disabilities || undefined,

                        }
                    )(
                        <Checkbox.Group
                            style={{display: !showPcd&& "none"}}
                            className={'disabilitiesGroup'}
                            options={disabilitiesList }
                        />
                    )}
                    {(getFieldsError().disabilities && showPcd) &&
                        <>
                            <div style={{marginTop:10}}>
                                <span style={{color:'#f5222d'}}>{getFieldsError().disabilities[0]}</span>
                            </div>
                        </>
                    }
                    <div style={{display:!showPcd && "none", background:'#ffe5d033', marginTop:10, padding:'0 15px'}}>
                        <div style={{display: !showPcd ? "none" : "flex", flexWrap:"wrap", alignItems:'center', padding:'10px 0', justifyContent:'space-between'}}>
                            <Col xs={24} md={10} lg={8} xl={12} style={{padding:'12px 0'}}>
                                <div>
                                    <p style={{fontSize:16, marginBottom:10, color:'#ca6510'}}>
                                       Anexe o seu comprovante PcD
                                    </p>
                                </div>
                                <input id={"fileInput"} type={"file"} accept={'image/*'} onChange={fileChangedHandler} style={{display:"none"}}/>
                                <button className='btn-selected-image' type={"button"} onClick={(e) => handleFileChange(e)}>
                                    <BsUpload size={18}/> <span style={{fontSize:14}}>Selecionar imagem</span>
                                </button>
                            </Col>
                            {loadingPreview  &&
                                <div className='d-flex align-items-center' style={{gap:10, padding:10, margin:0, background:'#d2f4ea', color:'#0d503c', border:"none"}}>
                                    <Icon type="loading-3-quarters" spin={true} style={{fontSize:20}}/>
                                    <span style={{fontSize:14}}>Carregando...</span>
                                </div>
                            }

                            {(imagePreview && !loadingPreview) &&
                                <Col xs={24} md={10} lg={8} xl={8} className='image-preview' style={{margin:0, background:'#d2f4ea', color:'#0d503c', border:"none"}}>
                                    <div className='overflow-text'>
                                        <img src={imagePreview} width={50} alt={`comprovante`}/>
                                        <span style={{display:'flex', alignItems:'center', gap:5}}>
                                            {showMessageSaved ?  <><BsCheck size={18}/> <span>Imagem salva</span></> : <>Imagem selecionada</>}
                                        </span>
                                    </div>
                                    <div className='icon-close' onClick={handleClearImage}>
                                        <CgClose size={20}/>
                                    </div>
                                </Col>
                            }
                        </div>
                    </div>
                </Col>

                <Col xs={24} className={'formItem'} >
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> É estudante? </label>
                        <div>
                            {getFieldDecorator('is_student',
                                {
                                    rules: [],
                                    initialValue: basicData.is_student || false,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio" >
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                </Col>

                <Col xs={24} className={'formItem'} >
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Possui interesse em realizar cursos grátis? </label>
                        <div>
                            <Radio.Group className={'radio'} name="languageRadio" defaultValue={showCourses}>
                                <Radio value={true} onChange={() => {setShowCourses(true); getCoursesCategories()}}>
                                    Sim
                                </Radio>
                                <Radio value={false} onChange={() => {setShowCourses(false)}}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        </div>
                        {showCourses &&
                            <div className={'da-flex'} style={{gap:10, marginTop:10, justifyContent:'end'}}>
                                <Button type={'primary'} onClick={() => selectAllCourses('check_all')}>
                                    <>Selecionar todas</>
                                </Button>
                                <Button onClick={() => selectAllCourses('uncheck_all')}>
                                    <>Desmarcar todas</>
                                </Button>
                            </div>
                        }
                    </div>
                    {getFieldDecorator('training_interests_ids',
                        {
                            rules: [],
                            initialValue: basicData?.training_interests_categories?.map(value => value.id) || undefined,
                        }
                    )(
                        <Checkbox.Group
                            style={{display: !showCourses&& "none"}}
                            className={'disabilitiesGroup'}
                            options={coursesCategories }
                        />
                    )}
                </Col>

                <Col xs={24} className={'formItem'}>
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Tem interesse em participar de grupo de empreendedores ou economia solidária?</label>
                        <div>
                            {getFieldDecorator('entrepreneur_interest',
                                {
                                    rules: [],
                                    initialValue: basicData.entrepreneur_interest || false,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio" >
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                </Col>

                <Col xs={24} className={'formItem'}>
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Disponibilidade para realizar viagens? </label>
                        <div>
                            {getFieldDecorator('available_travel',
                                {
                                    rules: [],
                                    initialValue: basicData.available_travel || false,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio" >
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                </Col>

                <Col xs={24} className={'formItem'}>
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Disponibilidade para dormir no local de trabalho? </label>
                        <div>
                            {getFieldDecorator('available_sleep_duty',
                                {
                                    rules: [],
                                    initialValue: basicData.available_sleep_duty || false,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio" >
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                </Col>

                <Col xs={24} className={'formItem'} >
                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Disponibilidade para ausentar-se de seu domicílio por longos períodos? </label>
                        <div>
                            {getFieldDecorator('available_leave_home',
                                {
                                    rules: [],
                                    initialValue: basicData.available_leave_home || false,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio" >
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>
                </Col>

            </div>
        </Row>


        <Row className={'formTitle'} style={{display: 'flex', flexFlow: "column"}}>
            <span>
                Outras Informações
            </span>
            <span style={{fontSize: 13, color: "darkgray"}}>As perguntas opcionais abaixo tem como intuito oferecer benefícios e soluções que podem se aplicar ao seu perfil. Estes dados não serão divulgados para os empregadores. </span>
        </Row>

        <Row gutter={[24,24]} className={'formRow'} style={{backgroundColor: 'white', margin: 0}}>

            <Col xs={24} sm={12} lg={8} xl={9} className={'formItem'}>
                <Form.Item>

                    <Tooltip title={messageTip} placement={'topRight'} trigger={['hover']}>
                        <label className='tooltip-message'> Renda familiar (opcional) &nbsp; <Icon  style={{color: "#9e9e9e", fontSize:14}}  type='question-circle'/></label>
                    </Tooltip>
                    {getFieldDecorator('family_income',
                        {
                            rules: [],
                            initialValue: basicData?.family_income || undefined,

                        }
                    )(
                        <Select placeholder="Renda familiar"
                                size={'large'}
                                allowClear={true}
                        >
                            {familyIncome.map((item, index) =>
                                <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                            )}
                        </Select>
                    )}
                </Form.Item>
            </Col>

            <Col xs={24} className={'formItem'} style={{paddingTop: 0}}>
                <div style={{marginBottom: 10}}>
                    <Tooltip title={messageTip} placement={'topRight'}  trigger={['hover']}>
                        <label className='tooltip-message'> Possui CADUNICO? <Icon  style={{color: "#9e9e9e", fontSize:14}}  type='question-circle'/></label>
                    </Tooltip>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showCadunico}>
                            <Radio value={true} onChange={() => {setShowCadunico(true)}}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {setShowCadunico(false)}}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                {getFieldDecorator('cadunico_number',
                    {
                        rules: [],
                        initialValue: basicData.cadunico_number || undefined,
                    }
                )(

                    <Input style={{display: !showCadunico&& "none"}} placeholder={'Informe o número'} size={'large'}/>
                )}
            </Col>

            <Col xs={24} className={'formItem'}>
                <div style={{marginBottom: 10, marginTop: 10}}>
                    <Tooltip title={messageTip} placement={'topRight'}  trigger={['hover']}>
                        <label className='tooltip-message'> Você é indígena? <Icon  style={{color: "#9e9e9e", fontSize:14}}  type='question-circle'/></label>
                    </Tooltip>
                    <div>
                        <Radio.Group className={'radio'} name="languageRadio" defaultValue={showIndigenous}>
                            <Radio value={true} onChange={() => {setShowIndigenous(true)}}>
                                Sim
                            </Radio>
                            <Radio value={false} onChange={() => {setShowIndigenous(false)}}>
                                Não
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, paddingRight: 20, display: !showIndigenous&& "none"}}>
                        <div className='resumeLabel'> <label><span> * </span>Qual sua Etnia?</label> </div>
                        {getFieldDecorator('ethnicity',
                            {
                                rules: [],
                                initialValue: basicData.ethnicity || undefined,
                            }
                        )(
                            <Select
                                placeholder="Informe sua Etnia"
                                size={'large'}
                                allowClear={true}
                            >
                                {Object.keys(ethnicityList).map((item, index) =>
                                    <Select.Option key={index} value={item}>{item}</Select.Option>
                                )}
                            </Select>
                        )}
                    </div>
                    <div style={{flex: 1, display: (!getFieldsValue()?.ethnicity || !showIndigenous)&& "none"}}>
                        <div className='resumeLabel'> <label><span> * </span>Selecione sua Aldeia</label> </div>

                        {getFieldDecorator('native_village',
                            {
                                rules: [],
                                initialValue: basicData.native_village || undefined,
                            }
                        )(

                            <Select
                                placeholder="Selecione sua Aldeia"
                                size={'large'}
                                allowClear={true}
                            >
                                {getFieldsValue()?.ethnicity&& ethnicityList[getFieldsValue()?.ethnicity] ? ethnicityList[getFieldsValue()?.ethnicity]?.map((item, index) =>
                                    <Select.Option key={index} value={item}>{item}</Select.Option>
                                ) : []}
                            </Select>
                        )}
                    </div>
                </div>
            </Col>

            <Col xs={24} className={'formItem'}>
                <div style={{marginBottom: 10, marginTop: 10}}>
                    <Tooltip title={messageTip} placement={'topRight'}  trigger={['hover']}>
                        <label className='tooltip-message'>Você é o responsável financeiro em sua residência? <Icon  style={{color: "#9e9e9e", fontSize:14}}  type='question-circle'/></label>
                    </Tooltip>
                    <div>
                        {getFieldDecorator('financial_responsible',
                            {
                                rules: [],
                                initialValue: basicData.financial_responsible || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="languageRadio" >
                                <Radio value={true}>
                                    Sim
                                </Radio>
                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>


            <Col xs={24} className={'formItem'}>
                <div style={{marginTop: 10, display: 'flex', flexFlow:'row wrap', alignItems:'center', gap:16}}>
                    <Col xs={24} md={5} lg={6} xl={4} xxl={3} style={{padding:0}}>
                        <Tooltip title={messageTip} placement={'topRight'} trigger={['hover']}>
                            <label className='tooltip-message'>Você tem filhos? <Icon  style={{color: "#9e9e9e", fontSize:14}}  type='question-circle'/></label>
                        </Tooltip>

                        <div>
                            {getFieldDecorator('has_children',
                                {
                                    rules: [],
                                    initialValue: basicData?.worker_children > 0,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio">
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </Col>

                    {getFieldsValue()?.has_children &&
                        <Col xs={24} md={5} lg={4} xl={5} xxl={3} style={{padding:0}}>
                            <Form.Item>
                                <div>
                                    <label>
                                        <span style={{color:'#D1392FFF'}}> * </span>Quantos filhos?
                                    </label>
                                </div>

                                {getFieldDecorator('children',
                                    {
                                        rules:[{
                                            required: true,
                                            message: 'Campo obrigatório'
                                        }],
                                        initialValue: basicData?.worker_children > 0 ? String(basicData?.worker_children) :  undefined
                                    }
                                )(
                                    <Select className='w-100' placeholder={"Selecione..."}>
                                        {childrenQtd?.map((item, index) => (
                                            <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    }

                    {(getFieldsValue()?.children && getFieldsValue()?.has_children) &&
                        <>
                            <div>
                                <Form.Item>
                                    <div>
                                        <label>
                                            <span style={{color: '#D1392FFF'}}> * </span>Qual a escolaridade?
                                        </label>
                                    </div>
                                    {getFieldDecorator('children_schooling',
                                        {
                                            rules: [{
                                                required: getFieldsValue()?.has_children,
                                                message: 'Selecione uma das opções'
                                            }],
                                            initialValue: basicData?.worker_children_schooling !== null ? basicData?.worker_children_schooling : []
                                        }
                                    )(
                                        <Checkbox.Group
                                            className={'disabilitiesGroup'}
                                            options={scholarship}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                            <div className={'formItem'}>
                                <div className='resumeLabel'
                                     style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}>
                                    <label>Tenho filhos ou outros dependentes que são pessoas com deficiência</label>
                                    <div>
                                        {getFieldDecorator('has_disabled_children',
                                            {
                                                rules: [],
                                                initialValue: basicData?.has_disabled_children || false,
                                            }
                                        )(
                                            <Radio.Group className={'radio'} name="languageRadio">
                                                <Radio value={true}>
                                                    Sim
                                                </Radio>
                                                <Radio value={false}>
                                                    Não
                                                </Radio>
                                            </Radio.Group>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </div>
            </Col>

            <Col xs={24} className={'formItem'}>
                <div style={{marginTop: 10}}>
                    <div>
                        <Tooltip
                            title={<span>Um migrante é uma pessoa que se muda de um País para outro para viver.</span>}
                            trigger={['hover']}>
                            <label>
                                Você é imigrante? <Icon style={{color: "#9e9e9e", fontSize: 14}}
                                                        type='question-circle'/>
                            </label>
                        </Tooltip>
                        <div>
                            {getFieldDecorator('is_immigrant',
                                {
                                    rules: [],
                                    initialValue: basicData?.is_immigrant || false,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio"
                                             onChange={(e) => setFieldsValue({is_immigrant: e.target.value})}>
                                    <Radio value={true}>
                                        Sim
                                    </Radio>

                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>

                    {getFieldsValue()?.is_immigrant && (basicData?.nationality === null || editNationality) &&
                        getFieldDecorator('nationality',
                            {
                                rules: [{
                                    required: basicData?.nationality === null,
                                    message: 'Campo obrigatório'
                                }],
                            }
                        )(
                            <Form.Item style={{marginBottom:5, marginTop: 10}}>
                                <div>
                                    <label>
                                        <span style={{color: '#D1392FFF'}}> * </span>Qual o seu país de origem?
                                    </label>
                                </div>

                                <Col xs={24} sm={12} lg={8} xl={9}>
                                    <Dropdown overlay={countryOptions} trigger={['click']}>
                                        <div>
                                            <Input value={basicData?.country}
                                                   placeholder={'Selecione seu País'}
                                                   onChange={(e) => {
                                                       setLoadingSearch(true)
                                                       setBasicData({...basicData, country: e.target.value})
                                                       onSearchNationality(e.target.value)}}
                                            />
                                            {loadingSearch && <Icon type={'loading'} style={{position:'absolute', right:14, top:12, fontSize:16}}/>}
                                        </div>
                                    </Dropdown>
                                </Col>
                            </Form.Item>
                        )
                    }

                    {(getFieldsValue()?.is_immigrant && basicData?.nationality !== null) &&
                        <div style={{marginTop: 30}}>
                            <Tooltip title={messageTip} trigger={['hover']}>
                                <label>
                                    Você é refugiado? <Icon style={{color: "#9e9e9e", fontSize: 14}} type='question-circle'/>
                                </label>
                            </Tooltip>
                            <div>
                                {getFieldDecorator('is_refugee',
                                    {
                                        rules: [],
                                        initialValue: basicData?.is_refugee || false,
                                    }
                                )(
                                    <Radio.Group className={'radio'} name="isRefugeeRadio"
                                                 onChange={(e) => setFieldsValue({is_refugee: e.target.value})}>
                                        <Radio value={true}>
                                            Sim
                                        </Radio>

                                        <Radio value={false}>
                                            Não
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </div>
                        </div>
                    }

                    {getFieldsError().nationality &&
                        <div>
                            <span style={{color: 'red'}}>{getFieldsError().nationality}</span>
                        </div>
                    }

                    {(basicData.nationality && getFieldsValue()?.is_immigrant) &&
                        <div className='d-flex align-items-center' style={{gap:5, marginTop:!editNationality && 10}}>
                            <span style={{textTransform:'capitalize'}}>Nacionalidade: {basicData.nationality}</span><i style={{cursor:'pointer', display:'flex'}} onClick={() => setEditNationality(!editNationality)}><FaEdit size={16}/></i>
                        </div>
                    }
                </div>
            </Col>
            <Col xs={24} className={'formItem'}>
                <div style={{marginBottom: 10, marginTop: 10}}>
                    <Tooltip title={messageTip} placement={'topRight'}  trigger={['hover']}>
                        <label className='tooltip-message'> Você é Campesino(a)? <Icon  style={{color: "#9e9e9e", fontSize:14}}  type='question-circle'/></label>
                    </Tooltip>
                    <div>
                        {getFieldDecorator('is_rural_worker',
                            {
                                rules: [],
                                initialValue: basicData?.is_rural_worker || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="isRuralWorkerRadio"
                                         onChange={(e) => setFieldsValue({is_rural_worker: e.target.value})}>
                                <Radio value={true}>
                                    Sim
                                </Radio>

                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>
            <Col xs={24} className={'formItem'}>
                <div style={{marginBottom: 10, marginTop: 10}}>
                    <Tooltip title={messageTip} placement={'topRight'}  trigger={['hover']}>
                        <label className='tooltip-message'> Você é Quilombola? <Icon  style={{color: "#9e9e9e", fontSize:14}}  type='question-circle'/></label>
                    </Tooltip>
                    <div>
                        {getFieldDecorator('is_quilombola',
                            {
                                rules: [],
                                initialValue: basicData?.is_quilombola || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="isQuilombolaRadio"
                                         onChange={(e) => setFieldsValue({is_quilombola: e.target.value})}>
                                <Radio value={true}>
                                    Sim
                                </Radio>

                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>
            <Col xs={24} className={'formItem'}>
                <div style={{marginBottom: 10, marginTop: 10}}>
                    <Tooltip title={<span>Um migrante é uma pessoa que se muda de um estado para outro para viver.</span>} placement={'topRight'}  trigger={['hover']}>
                        <label className='tooltip-message'> Você é Migrante? <Icon  style={{color: "#9e9e9e", fontSize:14}}  type='question-circle'/></label>
                    </Tooltip>
                    <div>
                        {getFieldDecorator('is_migrant',
                            {
                                rules: [],
                                initialValue: basicData?.is_migrant || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="isMigrantRadio"
                                         onChange={(e) => setFieldsValue({is_migrant: e.target.value})}>
                                <Radio value={true}>
                                    Sim
                                </Radio>

                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>

            <Col xs={24} className={'formItem'} >
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Vivo em situação de rua</label>
                    <div>
                        {getFieldDecorator('is_homeless',
                            {
                                rules: [],
                                initialValue: basicData?.is_homeless || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="languageRadio" >
                                <Radio value={true}>
                                    Sim
                                </Radio>
                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>

            <Col xs={24} className={'formItem'} >
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Sou egressa(o) do sistema prisional</label>
                    <div>
                        {getFieldDecorator('is_ex_prisoner',
                            {
                                rules: [],
                                initialValue: basicData?.is_ex_prisoner || false,
                            }
                        )(
                            <Radio.Group className={'radio'} name="languageRadio" >
                                <Radio value={true}>
                                    Sim
                                </Radio>
                                <Radio value={false}>
                                    Não
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>


            <Col xs={24} className={'formItem'} >
                <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Minha cor</label>
                    <div>
                        {getFieldDecorator('skin_color',
                            {
                                rules: [],
                                initialValue: basicData?.skin_color,
                            }
                        )(
                            <Radio.Group className={'radio'} name="languageRadio" >
                                <Radio value={"branca"}>
                                    Branca
                                </Radio>
                                <Radio value={"parda"}>
                                    Parda
                                </Radio>
                                <Radio value={"preta"}>
                                    Preta
                                </Radio>
                            </Radio.Group>
                        )}
                    </div>
                </div>
            </Col>

            {getFieldsValue()?.gender === "female" &&
                <Col xs={24} className={'formItem'} style={{ backgroundColor: getFieldsValue()?.is_domestic_violence_victim && "rgb(255 0 0 / 10%)", borderRadius: 10,}}>
                    <div className='resumeLabel'
                         style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}><label>Sou mulher e
                        sou vítima de violência doméstica e familiar, no contexto da Lei Maria da Penha</label>
                        <div>
                            {getFieldDecorator('is_domestic_violence_victim',
                                {
                                    rules: [],
                                    initialValue: basicData?.is_domestic_violence_victim,
                                }
                            )(
                                <Radio.Group className={'radio'} name="languageRadio">
                                    <Radio value={true}>
                                        Sim
                                    </Radio>
                                    <Radio value={false}>
                                        Não
                                    </Radio>
                                </Radio.Group>
                            )}
                        </div>
                    </div>

                    {getFieldsValue()?.is_domestic_violence_victim &&

                        <>
                            <div className='resumeLabel' style={{
                                marginBottom: 10,
                                marginTop: 10,
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}><label> Sou mulher e
                                denunciei a violência vivenciada através do:</label>
                                <div>
                                    {getFieldDecorator('domestic_violence_victim_reported',
                                        {
                                            rules: [],
                                            initialValue: basicData?.domestic_violence_victim_reported,
                                        }
                                    )(
                                        <Radio.Group className={'radio'} name="languageRadio">
                                            <Radio value={"180"}>
                                                180
                                            </Radio>
                                            <Radio value={"190"}>
                                                190
                                            </Radio>
                                            <Radio value={"Polícia Cívil"}>
                                                Polícia Cívil
                                            </Radio>
                                            <Radio value={"Ministério Público"}>
                                                Ministério Público
                                            </Radio>
                                            <Radio value={"Outro"}>
                                                Outro canal de atendimento
                                            </Radio>
                                            <Radio value={null}>
                                                Não denunciei
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </div>
                            </div>

                            <div className='resumeLabel' style={{
                                marginBottom: 10,
                                marginTop: 10,
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}><label> Sou mulher, vítima de violência doméstica e familiar, no contexto da Lei Maria da
                                Penha, e
                                denunciei a violência vivenciada através do:</label>
                                <div>
                                    {getFieldDecorator('has_protected_woman_income',
                                        {
                                            rules: [],
                                            initialValue: basicData?.has_protected_woman_income,
                                        }
                                    )(
                                        <Radio.Group className={'radio'} name="languageRadio">
                                            <Radio value={true}>
                                                Sim
                                            </Radio>
                                            <Radio value={false}>
                                                Não
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </div>
                            </div>
                        </>
                    }


                </Col>
            }

        </Row>

            <Row className={'formTitle'}> Endereço residencial </Row>

            <Row gutter={[24, 24]} className={'formRow'} style={{backgroundColor: 'white', margin: 0}}>
                <div>
                    <Col xs={24} sm={12} lg={8} className={'formItem'}>
                        <Form.Item>
                            <div className='resumeLabel'>
                                <label>
                                    <span> * </span>
                                    CEP
                                    {getFieldDecorator('address.postal_code', {
                                        rules: [
                                            numMinWithMask(),
                                            {
                                                required: true,
                                                message: 'CEP é obrigatório',
                                            },
                                        ],
                                        initialValue: basicData?.address?.postal_code || '',
                                    })(
                                        <NumberFormat type={"tel"}
                                                      disabled={cepLoading}
                                                      onChange={(e) => onCepChange(e)}
                                                      style={{marginBottom: 5, width: '100%', textAlign: 'center'}}
                                                      placeholder='Digite seu CEP'
                                                      className='ant-input ant-input-lg'
                                                      format={'## ###-###'}
                                                      mask="_"
                                        />
                                    )}
                                </label>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={8} className={'formItem'}>
                        <Form.Item>
                            <div className='resumeLabel'>
                                <label>
                                    <span> * </span>
                                    Estado
                                    {getFieldDecorator('address.state_abbrev',
                                        {
                                            rules: [{
                                                required: true,
                                                message: 'Sigla do Estado é obrigatório'
                                            }],
                                            initialValue: basicData?.address?.state_abbrev || '',
                                        }
                                    )(
                                        <Input prefix={cepLoading&& <Icon style={{color:'#005c9f'}} type="loading"/>}
                                           disabled={true}
                                           placeholder={'Informe seu estado'}
                                           size={'large'}
                                           style={{marginBottom:5}}
                                    />

                                )}
                            </label>
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8} className={'formItem'}>
                    <Form.Item>
                        <div className='resumeLabel'>
                            <label>
                                <span> * </span>
                                Cidade
                                {getFieldDecorator('address.city',
                                    {
                                        rules: [{
                                            required: true,
                                            message: 'Cidade é obrigatório'
                                        }],
                                        initialValue: basicData?.address?.city || '',
                                    }
                                )(
                                    <Input prefix={cepLoading&& <Icon style={{color:'#005c9f'}} type="loading"/>}
                                           disabled={true}
                                           placeholder={'Sua cidade'}
                                           size={'large'}
                                           style={{marginBottom:5}}
                                    />
                                )}
                            </label>
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8} className={'formItem'}>
                    <Form.Item>
                        <div className='resumeLabel'>
                            <label>
                                <span> * </span>
                                Rua
                                {getFieldDecorator('address.street',
                                    {
                                        rules: [{
                                            required: true,
                                            message: 'Rua é obrigatório'
                                        }],
                                        initialValue: basicData?.address?.street || '',
                                    }
                                )(
                                    <Input prefix={cepLoading&& <Icon style={{color:'#005c9f'}} type="loading"/>}
                                           placeholder={'Sua rua'} size={'large'}
                                           style={{marginBottom:5}}
                                    />
                                )}
                            </label>
                        </div>
                    </Form.Item>

                    {/*<div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().address?.street} </span> </div>*/}
                </Col>
                <Col xs={24} sm={12} lg={8} className={'formItem'}>
                    <Form.Item>
                        <div className='resumeLabel'>
                            <label>
                                Bairro
                                {getFieldDecorator('address.district',
                                    {
                                        rules: [],
                                        initialValue: basicData?.address?.district || '',
                                    }
                                )(
                                    <Input prefix={cepLoading&& <Icon style={{color:'#005c9f'}} type="loading"/>}
                                           placeholder={'Seu bairro'}
                                           size={'large'}
                                           style={{marginBottom:5}}
                                    />
                                )}
                            </label>
                        </div>
                    </Form.Item>

                </Col>
                <Col xs={24} sm={12} lg={8} className={'formItem'}>
                    <Form.Item>
                        <div className='resumeLabel'>
                            <label>
                                <span> * </span> Número
                                {getFieldDecorator('address.street_number',
                                    {
                                        rules: [{
                                            required: true,
                                            message: 'Número é obrigatório'
                                        }],
                                        initialValue: basicData?.address?.street_number || '',
                                    }
                                )(
                                    <Input type={'number'}
                                           style={{marginBottom:5}}
                                           placeholder={'Número da residência'}
                                           size={'large'}
                                    />
                                )}
                            </label>
                        </div>
                    </Form.Item>
                    {/*<div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().address?.street_number} </span> </div>*/}
                </Col>
            </div>
        </Row>

        <Row justify={'end'} style={{marginBottom: 20, textAlign: 'right', marginTop: 20}}>
            <Button shape={'round'} loading={loading} disabled={loading} onClick={() => handleSubmit()} className={'ant-btn-sub-primary'}> Próximo <FaForward className={'exportedIcons'}/>  </Button>
        </Row> </>}
    </div>)
};
export default Form.create({name: 'aboutForm'})(AboutComponent);

