import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Icon, Input, message, Row} from "antd";
import ProfilePersonalForm from "./ProfilePage/ProfilePersonalForm";
import ProfileContactForm from "./ProfilePage/ProfileContactForm";
import useApi from "../../../../Hooks/Api";
import useAuth from "../../../../Hooks/Auth";
import Loading from "../../../Generic/Loading";
import moment from "moment";
import Modal from "antd/es/modal";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Images} from "../../../../Helpers/Images";
import GenericInput from "../../../Generic/GenericInput";
import * as md5 from "md5";
import {Fade} from "react-reveal";
import VerificationCodeComponent from "../../../../Pages/Components/VerificationCodeComponent";
import {useVerifyShowPanel} from "../../../../Hooks/Helpers";
import useLogout from "../../../../Hooks/LogoutAuth";


const ProfilePage = props => {
    const api = useApi();
    const [user, setUser] = useState({});
    const [loading,setLoading] = useState(true);
    const {validateFields} = props.form;
    const {store} = useAuth();
    const {logoutWithoutApi} = useLogout();

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [oldPassword, setOldPassword] = useState(undefined);
    const [newPassword, setNewPassword] = useState(undefined);
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState(undefined);

    const [showDisableAccountModal, setShowDisableAccountModal] = useState(false);
    const [cpf, setCpf] = useState(undefined);
    const profile = useSelector(state => state).authReducer;
    const preferences = useSelector(state => state).preferencesReducer;

    const history = useHistory();
    const [changePhoneModal, setChangePhoneModal] = useState(false);
    const {redirectToPanel} = useVerifyShowPanel(history, preferences.panel_profile)

    const getData = async () => {
        setLoading(true);
        api.get(`user/${profile.user_id}/full`,(res) => {
            store(res);
            setUser(res);
            setLoading(false);
        }, (e) => {
            message.error('Ops, não foi possível recuperar seu perfil, tente novamente');
        });
    }

    const handleSubmit = async () => {
        validateFields(async (error, values) => {
            if (error) return 0;
            else {
                values.birthday = moment(values.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');
                api.post(`user/${profile.user_id}/profile`, values, (res) => {
                    message.success('Seus dados foram alterados com sucesso!');
                    history.goBack();
                }, (e) => {
                    message.error(e.message || 'Ops!, não foi possível salvar seus dados')
                });
            }
        });
    };

    const changePassword = async () => {
        if(newPassword === newPasswordConfirmation) {
            if (oldPassword && newPassword) {
                api.post('user/change-password', {
                    old_password: md5(oldPassword),
                    password: md5(newPassword)}, (res) => {
                    message.success('Senha alterada com sucesso!');
                    setShowChangePasswordModal(false);
                }, (e) => {
                    message.error(e.message || 'Ops!, não foi possível salvar seus dados')
                });
            } else {
                message.error( 'Ops!, insira a senha atual e a nova senha')
            }
        } else {
            message.error( 'Ops!, sua nova senha e a confirmação da nova senha devem ser iguais')
        }

    };

    const disableAccount = async () => {
        if(cpf === profile.cpf) {
            api.post('access/disable-account', {cpf}, (res) => {
                message.success('Sua conta foi desabilitada.', 'Para habilitar novamente entre em contato conosco!');
                logoutWithoutApi();
            }, (e) => {
                console.log(e);
                message.error( e.message || 'Não foi possível desabilitar sua conta, tente novamente');
            });
        } else {
            message.error('Por favor, para desabilitar insira o seu CPF');
        }
    };


    useEffect(() => {
        getData()
       /* return GlobalVariables.sidebar = false;*/
    },[]);


    return (
        <Row className={'mainComponentPanel'} style={{display: 'flex', flexFlow: 'column', justifyContent: 'center'}}>
            <Modal
                visible={changePhoneModal}
                footer={null}
                bodyStyle={{padding: 0,  display: 'flex', flexDirection: 'column'}}
                width={'80%'}
                onCancel={() => {setChangePhoneModal(false)}}
                className={'registerModalWidth'}
                destroyOnClose={true}
                style={{maxWidth: '800px',}}
            >
                <Col xs={24} className={'sideLogin'} style={{backgroundColor: "#315dc3"}} >
                    <div style={{padding: 20}}>
                        <Fade left>
                            <div className={'logTitle'} style={{fontSize: 18}}>
                                <span> <strong> Vamos trocar seu número de telefone? </strong> </span>
                                <div className={'line'} style={{backgroundColor: "#173277", marginBottom: 10}}/>
                            </div>
                        </Fade>
                    </div>
                </Col>
                <VerificationCodeComponent changeNumber={true} showScreen={changePhoneModal}
                                            setShowScreen={() => setChangePhoneModal(false)}
                                            resumeRegister={() => {
                                                setChangePhoneModal(false)
                                                getData()
                                            }}
                />
            </Modal>
            <Modal
                className={'defaultModal defaultModalBlue'}
                destroyOnClose
                style={{textAlign: 'center'}}
                visible={showChangePasswordModal}
                onCancel={() => {setShowChangePasswordModal(false)}}
                footer={null}>
                <div>
                    <div style={{marginBottom: 30}}>
                        <span style={{fontSize: 25, textAlign: 'center', fontWeight: 'bold'}}> Você está prestes a trocar a sua senha. </span>
                    </div>


                    <Form.Item
                        className={'hideDots'}
                        style={{borderBottom: '1px solid gainsboro', paddingBottom: 25}}
                        label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}><strong>Sua senha atual </strong> </span>}
                    >
                        <Input.Password value={oldPassword}  onChange={(e) => {setOldPassword(e.target.value)}} placeholder={'Senha atual'} size={'large'}/>
                    </Form.Item>

                    <Form.Item
                        className={'hideDots'}
                        label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}><strong>Sua nova senha</strong> </span>}
                    >
                        <Input.Password value={newPassword} onChange={(e) => {setNewPassword(e.target.value)}} placeholder={'Nova senha'} size={'large'}/>
                    </Form.Item>

                    <Form.Item
                        className={'hideDots'}
                        label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}><strong>Confirme sua nova senha</strong> </span>}
                    >
                        <Input.Password value={newPasswordConfirmation} onChange={(e) => {setNewPasswordConfirmation(e.target.value)}} placeholder={'Confirme a nova senha'} size={'large'}/>
                    </Form.Item>

                    <Button icon={'check'} disabled={api.loading} loading={api.loading} style={{marginTop: 30}} onClick={() => changePassword()}
                            className={'saveButton'} size={'large'} type={'primary'}>
                        Trocar senha
                    </Button>
                </div>
            </Modal>

            <Modal
                className={'defaultModal defaultModalBlue'}
                destroyOnClose
                style={{textAlign: 'center'}}
                visible={showDisableAccountModal}
                onCancel={() => {setShowDisableAccountModal(false)}}
                footer={null}>
                <div>
                    <div style={{marginBottom: 30}}>
                        <span style={{fontSize: 25, textAlign: 'center', fontWeight: 'bold', }}> Você está prestes a desabilitar a sua conta, você perderá acesso a todos os seus dados. </span>
                    </div>

                    <Input value={cpf} onChange={(e) => setCpf(e.target.value)} placeholder={'Seu CPF'} size={'large'}/>

                    <Button icon={'check'} disabled={api.loading} loading={api.loading} style={{marginTop: 30}} onClick={() => disableAccount()}
                            className={'saveButton'} size={'large'} type={'danger'}>
                        Desabilitar
                    </Button>
                </div>
            </Modal>


            <div className={'back-side-button'} onClick={() => redirectToPanel()}>
                <Icon className={'craftyIcon'} type={'arrow-left'}/>
                <span> Voltar </span>
            </div>
            {loading?
                <div style={{margin: "auto"}}> <Loading/> </div>
                :
                <>
                    <Col span={24} style={{padding: 10}}>
                        <ProfilePersonalForm getData={() => getData()} user={user.profile} form={props.form}/>
                    </Col>
                    <Col span={24}  style={{padding: 10}}>
                        <ProfileContactForm changePhone={() => setChangePhoneModal(true)} user={user.profile} form={props.form}/>
                    </Col>
                    <div style={{display: 'flex', justifyContent: 'space-between', flexWrap:"wrap", padding: 10}}>
                        <Col xs={24} md={12} lg={9} style={{display:"flex", justifyContent:"space-between"}}>
                            <Col xs={12}>
                                <Button color={'red'} icon={'alert'} style={{marginRight: 10, marginBottom:10}} disabled={api.loading} loading={api.loading} onClick={() => setShowDisableAccountModal(true)}
                                        className={'saveButton w-100'}>
                                    Desabilitar Perfil
                                </Button>
                            </Col>

                            <Col xs={11}>
                                <Button icon={'lock'} disabled={api.loading} loading={api.loading} onClick={() => setShowChangePasswordModal(true)}
                                        className={'saveButton w-100'} type={'primary'}>
                                    Trocar senha
                                </Button>
                            </Col>
                        </Col>

                        <Col xs={24} md={7} lg={5}>
                            <Button icon={'check'} disabled={api.loading} loading={api.loading} onClick={() => handleSubmit()}
                                    className={'saveButton w-100'} type={'secondary'}>
                                Salvar alterações
                            </Button>
                        </Col>
                    </div>
                </>
            }
        </Row>
    );
};

ProfilePage.propTypes = {};

export default Form.create({name: 'form_profile'})(ProfilePage);
