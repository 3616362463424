import React, {useState} from 'react';
import {Badge, Button, Col, Divider, Drawer, Icon, Menu, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../Hooks/Auth";
import {useHistory} from "react-router-dom";
import useVerifyServices from "../../../Hooks/VerifyServices";
import {FaBook, FaBriefcase, FaCalendarCheck, FaFilePdf, FaHome, FaSearch, FaBookOpen, FaSignature, FaBuilding} from "react-icons/fa";
import {AiFillClockCircle,AiOutlineFileSearch, AiTwotoneShop, AiTwotoneStar} from "react-icons/ai";
import {RiFileUserFill, RiCloseFill} from "react-icons/ri";
import RegisterPageModal from "../../../Pages/RegisterPageModal";
import {StorageVariables} from "../../../Helpers/StoragesVariables";
import useLocalStorage from "../../../Hooks/Storage";
import {Images} from "../../../Helpers/Images";
import {FiLogOut, FiRefreshCw} from "react-icons/fi";
import {BsHouse} from "react-icons/all";
import {eventVisible, useVerifyShowPanel} from "../../../Hooks/Helpers";
import {Env} from "../../../Helpers/Env";
import useLogout from "../../../Hooks/LogoutAuth";

const Sidebar = props => {
    const history = useHistory();
    const preferences = useSelector(state => state).preferencesReducer;
    const profile = useSelector(state => state).authReducer;
    const notificationsCount = useSelector(state => state).notificationsReducer;
    const {redirectToPanel, changePanelProfile, typePanel, textPanel} = useVerifyShowPanel(history, preferences.panel_profile)
    const {hasSineService, hasCraftyService, hasClubService} = useVerifyServices();
    const hasSineProfile = useLocalStorage(StorageVariables.hasSineProfile);
    const {isLogged, getUser} = useAuth();
    const {logoutWithoutApi} = useLogout();
    const dispatch = useDispatch();
    const [registerModal, setRegisterModal] = useState(false);

    const goTo = (path) => {
        history.push(path);
        props.setSidebarCollapsed(!props.collapsed)
    };

    return (
        <Drawer
            placement="left"
            onClose={() => props.setSidebarCollapsed(!props.collapsed)}
            visible={props.collapsed}
            closable={false}
            maskClosable={true}
            bodyStyle={{padding: 0, height:'100%'}}
            headerStyle={{padding: 0}}
        >
            <aside className="sidebar d-flex flex-column justify-content-between" >
                <div>
                    {isLogged()&&
                        <header>
                            <Row style={{textAlign: 'center', marginTop: 10, position:'relative'}}>
                                <Col className='badge-sidebar' onClick={() => goTo('/notificacoes')}>
                                    <Icon type='bell' style={{fontSize:27, color:'white'}}/>
                                    <Badge count={notificationsCount}/>
                                </Col>
                                <Col span={24}>
                                    <Badge offset={[-8,50]} style={{height: 9, width: 9}} status="success">
                                        <img className='user-avatar'
                                             style={{backgroundSize: 'cover', height: 60, width: 60, borderRadius: '50%', backgroundImage: profile?.avatar? `url(${profile?.avatar})` : `url(${Images.user_place_holder})`}}/>
                                    </Badge>
                                </Col>

                                <Col span={24}>
                                    <div>
                                        <h4 className='ant-list-item-meta-title' style={{lineHeight: '25px', fontSize: 18, fontWeight: 'bold'}}>{getUser().name}</h4>
                                        <h4 className='ant-list-item-meta-title' style={{lineHeight: '25px'}}>{getUser().email}</h4>
                                    </div>
                                </Col>

                            </Row>

                            <Row>
                                {isLogged() &&
                                    <>
                                        <Col span={12} >
                                            <div onClick={() => {redirectToPanel(); props.setSidebarCollapsed(!props.collapsed)}} className='side-bar-option-header'>
                                                <BsHouse size={21}/>
                                                <span style={{marginTop:1, marginBottom:-3}}>
                                                    Painel
                                                </span>
                                            </div>
                                        </Col>
                                        <Col span={12} >
                                            <div className='side-bar-option-header' onClick={() => goTo('/painel/prestador/perfil')}>
                                                <Icon type='user' style={{padding:0, fontSize:20}}/>
                                                <span style={{marginTop:1, marginBottom:-3}}>
                                                    Perfil
                                                </span>
                                            </div>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </header>
                    }
                    <nav className="sidebar-nav" style={{position:'initial', height:'initial', paddingBottom:0}}>
                        <ul>

                            {isLogged()&& <>
                                {typePanel === 'client' ?
                                    <>
                                        {hasSineService()&&
                                            <li>
                                                <a onClick={() => goTo('/curriculos')}>
                                                    <FaSearch className={'exportedIconsLeft primary-sub-color'}/>
                                                    <span>
                                                        Buscar currículos
                                                    </span>
                                                </a>
                                            </li>
                                        }
                                        {hasCraftyService() &&
                                            <>
                                                {Env.show_client_panel ?
                                                    <>
                                                        <li>
                                                            <a href="#" onClick={() => goTo('/painel/cliente/contatos')}>
                                                                <AiFillClockCircle className={'exportedIconsLeft primary-sub-color'}/>
                                                                <span>
                                                                    Contatos solicitados
                                                                </span>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a onClick={() => goTo('/quero-contratar')}>
                                                                <FaSearch className={'exportedIconsLeft primary-sub-color'}/>
                                                                <span>
                                                                    Buscar serviços
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </> : <></>
                                                }
                                            </>
                                        }
                                    </> :
                                    <>
                                        {hasSineService() && <li>
                                            <a onClick={() => goTo('/vagas-de-emprego')}>
                                                <FaSearch className={'exportedIconsLeft green'}/>
                                                <span>Vagas</span>
                                            </a>
                                        </li>}

                                        {eventVisible() &&
                                            <li className={'event-header'}>
                                                {((!isLogged() || preferences?.panel_profile === 'worker'))&&
                                                    <a onClick={() => goTo('/vagas-do-evento')}>
                                                        <FaSearch className={'exportedIconsLeft secondary-sub-color'}/>
                                                        <span className={' secondary-sub-color'}>
                                                        Mulheres
                                                        </span>
                                                    </a>
                                                }
                                            </li>
                                        }

                                        {hasSineService() && <li>
                                            <a onClick={() => goTo('/buscar-cursos')}>
                                                <FaBook className={'exportedIconsLeft green'}/>
                                                <span>Cursos</span>
                                            </a>
                                        </li>}

                                        {hasSineService() && <li>
                                            <a onClick={() => goTo('/painel/prestador/editar-meu-curriculo')}>
                                                <FaSignature className={'exportedIconsLeft green'}/>
                                                <span>
                                                    {hasSineProfile.getPrimitive()? 'Editar meu currículo': 'Cadastrar meu currículo'}
                                                </span>
                                            </a>
                                        </li>}

                                        <li>
                                            <a onClick={() => goTo('/editais')}>
                                                <FaFilePdf className={'exportedIconsLeft green'}/>
                                                <span> Editais </span>
                                            </a>
                                        </li>

                                    </>

                                }

                                <li>
                                    <a onClick={() => {changePanelProfile(); props.setSidebarCollapsed(!props.collapsed)}}>
                                        <FiRefreshCw className={`exportedIconsLeft ${typePanel === 'worker' ? 'green' : 'primary-sub-color'}`} />
                                        <span>
                                            Alterar p/ painel {textPanel}
                                        </span>
                                    </a>
                                </li>


                                {hasClubService()&&
                                    <li>
                                        <a onClick={() => goTo('/estabelecimentos')}>
                                            <AiTwotoneShop className={'exportedIconsLeft primary-sub-color'}/>
                                            <span>
                                                Buscar estabelecimentos
                                            </span>
                                        </a>
                                    </li>
                                }

                                {hasClubService() && <li>
                                    <a><Icon type='star'/> <span>Cupons</span></a>
                                </li>}

                                {hasClubService() && <li>
                                    <a><Icon type='star'/> <span>Compras</span></a>
                                </li>}
                            </>}

                            {!isLogged() &&<li style={{padding: 10, marginTop: 20}}>
                                <Button onClick={() => setRegisterModal('login')} style={{ width: '100%'}}>Entre</Button>
                                <Divider> ou </Divider>
                                <Button  onClick={() => setRegisterModal('regType')} className={'ant-btn-sub-primary'} style={{ width: '100%'}}>Cadastre-se</Button>
                            </li>}

                            {!isLogged() &&
                                <>
                                    <li>
                                        <a onClick={() => goTo('/')}>
                                            <FaHome className={'exportedIconsLeft primary-sub-color'}/>
                                            <span>
                                                Home
                                            </span>
                                        </a>
                                    </li>
                                    {eventVisible() &&
                                        <li className={'event-header'}>
                                            <a onClick={() => goTo('/vagas-do-evento')}>
                                                <FaSearch className={'exportedIconsLeft secondary-sub-color'}/>
                                                <span className={' secondary-sub-color'}>
                                                    Mulheres
                                                </span>
                                            </a>
                                        </li>
                                    }

                                    <li>
                                        <a onClick={() => goTo('/vagas-de-emprego')}>
                                            <AiOutlineFileSearch size={24} className={'exportedIconsLeft primary-sub-color'}/>
                                            <span>
                                                Buscar vagas
                                            </span>
                                        </a>
                                    </li>

                                    {hasCraftyService() &&<li>
                                        <a onClick={() => goTo('/quero-contratar')} >
                                            <FaSearch className={'exportedIconsLeft primary-sub-color'}/>
                                            <span>
                                                Buscar serviços
                                            </span>
                                        </a>
                                    </li>}
                                    {hasClubService()&&
                                        <li>
                                            <a onClick={() => goTo('/estabelecimentos')}>
                                                <AiTwotoneShop className={'exportedIconsLeft primary-sub-color'}/>
                                                <span>
                                                    Buscar estabelecimentos
                                                </span>
                                            </a>
                                        </li>}

                                    {hasSineService()&&
                                        <li>
                                            <a onClick={() => goTo('/curriculos')}>
                                                <RiFileUserFill className={'exportedIconsLeft primary-sub-color'}/>
                                                <span>
                                                    Buscar currículos
                                                </span>
                                            </a>
                                        </li>}

                                    <li>
                                        <a onClick={() => goTo('/trabalhar')}>
                                            <FaBriefcase className={'exportedIconsLeft primary-sub-color'}/>
                                            <span>
                                                Quero trabalhar
                                            </span>
                                        </a>
                                    </li>
                                </>
                            }
                            <li>
                                <a onClick={() => goTo('/empresas')}>
                                    <FaBuilding className={'exportedIconsLeft primary-sub-color'}/>
                                    <span>
                                        Empresas
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                {isLogged() &&
                    <div className='d-flex justify-content-end'>

                            <div className='logout-sidebar' onClick={() => {logoutWithoutApi(); dispatch({type: 'logout'});}}>
                                <FiLogOut size={24}/>
                                <span>
                                    Sair
                                </span>
                            </div>
                    </div>
                }
            </aside>

            <RegisterPageModal visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />
        </Drawer>

    );
};

Sidebar.propTypes = {};

export default Sidebar;
