import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Col, Form, Icon, Input, message, Row, Select} from "antd";
import {Fade} from "react-reveal";
import GenericCropImage from "../../../Generic/GenericCropImage";
import GenericInput from "../../../Generic/GenericInput";
import NumberFormat from "react-number-format";
import {useValidationShorts} from "../../../../Hooks/Helpers";
import * as moment from "moment";
import useApi from "../../../../Hooks/Api";
import useAuth from "../../../../Hooks/Auth";
import {Env} from "../../../../Helpers/Env";
import {Images} from "../../../../Helpers/Images";
import useLocalStorage from "../../../../Hooks/Storage";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";
import useApiClub from "../../../../Hooks/ApiClub";

moment.locale('pt-br');
const RegisterFinalForm = props => {

    const [loading,setLoading] = useState();
    const {getFieldError, getFieldsValue, setFieldsValue, getFieldDecorator, validateFields} = props.form;
    const {numMinWithMask, isEmail, required, passwordStrength, isValidDate, minAge} = useValidationShorts();
    const api = useApi();
    const apiWhiteLabel = useApiClub();
    const imageApi = useApi();
    let md5 = require('md5');
    const [thirdPartyLogin, setThirdPartyLogin] = useState(false);
    const image = useRef(null);
    const {login} = useAuth();
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);
    const [allow, setAllow] = useState(false);
    const token = useRef(undefined);
    const campaign = useLocalStorage(StorageVariables.campaign);
    const [attendantField, setAttendantField] = useState(false);

    const doAfterRegister = async (res) => {
        message.success({
            icon: <> </>,
            content: <div style={{display: 'flex', fontWeight: 'bold', flexFlow: 'column'}}>
                <img style={{height: 45, marginBottom: 10}} src={Images.logo}/> <span> Seja bem-vindo(a). </span>
            </div>
        });
        thirdPartyLogin&& (res.govbr_id = true)
        login({
            token: res.api_key,
            user_id: res.user_id,
            email: res.profile.email,
            nickname: res.profile.nickname,
            avatar: res.profile.profile_picture?.thumbnail,
            addresses: res.addresses,
            name: (res.profile.first_name + ' ' + res.profile.last_name),
            last_profile: props.profile,
            gender: res.profile.gender,
            cpf: res.profile.cpf,
            phone: res.profile.cellphone_number,
            birthday: res.profile.birthday,
            govbr_id: res.govbr_id,
            mobile_verified: res.profile?.mobile_verified
        });
       /* if (!res.crafty_club) {
            const res = await api.post(`crafty-club/register`, {});
        }*/
        hasProfileStorage.setPrimitive(false); // TODO retirar depois do bessa arrumar
        props.setStep();
    }


    const handleSubmit = () => {
        setLoading(true);
        validateFields(async (error, values) => {
            if (error) {
                setLoading(false);
                message.warning('Preencha os campos corretamente');
            } else {
                if(props.mobileVerified) {
                    values.mobile_verified = props.mobileVerified;
                    values.mobile_token = props.mobileToken;
                }
                if (image.current) {
                    values.profile_picture = image.current
                }
                if (!thirdPartyLogin) {
                    values.password = md5(values.password);
                    values.confirm_password = md5(values.confirm_password);
                } else {
                    values.govbr_id = props.initialData.sub;
                    values.govbr_token = props.session_token;
                }
                values.cellphone_number = '+55' + props.userCell.replace(/\D/ig, '');
                values.birthday = moment(values.birthday,'DD/MM/YYYY').format('YYYY-MM-DD');
                values.cpf = values.cpf.replace(/\D/ig, '');
                values.application_name = Env.application_name;
                values.fcm_token = token.current || 'undefinedToken';

                let urlAux = '';
                urlAux = props.profile === 'worker'? 'register/worker' : 'register/customer';
                let campaignName = campaign?.getPrimitive();
                api.post(urlAux, values, (res) => {
                    setLoading(false);
                    doAfterRegister(res);
                    if(campaignName) {
                        apiWhiteLabel.post('campaign/email-marketing', {
                            campaign_name: campaignName,
                            user_email: values.email
                        },(res) => {}, (e) => console.log(e))
                    }
                }, (e) => {
                    setLoading(false);
                    window.gtagService('event', 'registerFailure');
                    message.error(e.message || 'Não foi possivel registar, tente novamente mais tarde');
                });
            }
        })
    }

    const passwordMatch = () => {
        return   {
            validator: (rule, value) => getFieldsValue().password === getFieldsValue().confirm_password,
            message: ' As senhas devem ser Iguais'
        }
    }

    const getToken = () => {
        window.getMessagingTokenService((currentToken) => {
            token.current = currentToken;
        })
    }

    const getInitialData = () => {
        setThirdPartyLogin(true);
        let nameArr = props.initialData.name.split(' ');

        setFieldsValue({
            first_name: nameArr[0],
            last_name:  nameArr.length > 1? nameArr[nameArr.length - 1] : undefined,
            cpf: props.initialData.sub,
            email: props.initialData.email,
        });
    }

    useEffect(() => {
        getToken();
        props.initialData && getInitialData();
    },[])

    return (
        <div>
            <Fade mountOnEnter>
                <div className='loginFormMobile' style={{width: '100%', margin: "auto", padding: '15px', background: 'white', borderRadius: '10px'}}>
                    <Row gutter={16} style={{marginBottom: 10, display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Col xs={24} md={24}>
                            <Row>
                                <Col span={24}>
                                    <GenericInput suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: '17px'}}/>} required={true} form={props.form} error={getFieldError('first_name')} name='first_name' label='Nome' placeholder='Digite seu Nome' valueForMemo={getFieldsValue().first_name} />
                                </Col>
                                <Col span={24}>
                                    <GenericInput suffix={<Icon type="team" className={'primary-sub-color'} style={{fontSize: '17px'}}/>} required={true} form={props.form} error={getFieldError('last_name')} name='last_name' label='Sobrenome' placeholder='Digite seu Sobrenome' valueForMemo={getFieldsValue().last_name} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={'genericInput'}>
                        <Form.Item
                            className={'hideDots'}
                            label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '16px'}}><strong > Telefone </strong> </span>}>
                            <Input suffix={<Icon type="phone" className={'primary-sub-color'} style={{fontSize: '24px'}}/>} disabled={true} value={props.userCell} size={"large"} />
                        </Form.Item>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} className={'genericInput'}>
                            <Form.Item className='hideDots' required={true} validateStatus={getFieldError('cpf')&& 'error'} help={getFieldError('cpf')} label={<span style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> Seu CPF </span>}>
                                {getFieldDecorator('cpf', {
                                    rules: [
                                        numMinWithMask(11),
                                        {
                                            required: true,
                                             message: 'Campo Obrigatório',
                                        },
                                    ],
                                })(
                                    <NumberFormat disabled={thirdPartyLogin} suffix={<Icon type="number" className={'primary-sub-color'} style={{fontSize: '16px'}}/>} style={{width: '100%'}} placeholder={'Seu CPF'} className='ant-input ant-input-lg' format={'###.###.###-##'} mask="_"/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} lg={24}>
                            <GenericInput disabled={thirdPartyLogin} rules={[isEmail(), required()]} suffix={<Icon type="mail" className={'primary-sub-color'} style={{fontSize: '17px'}}/>} required={true} form={props.form} error={getFieldError('email')} name='email' label='Email' placeholder='Digite seu email' valueForMemo={getFieldsValue().email} />
                        </Col>
                    </Row>
                    <Row gutter={16}>

                        <Col xs={24} sm={12} className={'genericInput'}>
                            <Form.Item required={true} className={'datePickerWidth hideDots'} validateStatus={getFieldError('birthday')&& 'error'} help={getFieldError('birthday')} label={<span style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> Data de Nascimento </span>}>
                                {getFieldDecorator('birthday', {
                                    validateTrigger: false,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Obrigatório',
                                        },
                                        isValidDate(),
                                        minAge(moment().locale(`pt-br`))
                                    ],
                                })(
                                    <NumberFormat className={'ant-input ant-input-lg'} style={{marginTop: 5}} format="##/##/####" placeholder="DD/MM/AAAA"/>
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} className={'genericInput'}>
                            <Form.Item required={true} className='hideDots' validateStatus={getFieldError('gender')&& 'error'} help={getFieldError('gender')} label={<span style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> Seu Gênero</span>}>
                                {getFieldDecorator('gender', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Obrigatório',
                                        },
                                    ],
                                })(<Select
                                    mode="default"
                                    style={{ width: '100%',marginTop: 5 }}
                                    placeholder={"Escolha um Gênero"}
                                    allowClear={false}
                                    size={'large'}
                                    suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}
                                >

                                    <Select.Option value={'male'}> Masculino </Select.Option>
                                    <Select.Option value={'female'}> Feminino </Select.Option>
                                    <Select.Option value={'transgender_male'}> Homem transgênero </Select.Option>
                                    <Select.Option value={'transgender_female'}> Mulher transgênero </Select.Option>
                                    <Select.Option value={'transsex_male'}> Homem Transexual </Select.Option>
                                    <Select.Option value={'transsex_female'}> Mulher Transexual </Select.Option>
                                    <Select.Option value={'unknown'}> Não sei responder </Select.Option>
                                    <Select.Option value={'not_anwswered'}> Prefiro não responder </Select.Option>
                                    <Select.Option value={'other'}> Outros </Select.Option>
                                </Select>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    {!thirdPartyLogin && <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <GenericInput type='password' rules={[passwordStrength()]} suffix={<Icon type="lock" className={'primary-sub-color'} style={{fontSize: '16px'}}/>} required={true} form={props.form} error={getFieldError('password')} name='password' label='Senha' placeholder='Digite sua Senha' valueForMemo={getFieldsValue().password} />
                        </Col>
                        <Col xs={24} sm={12}>
                            <GenericInput type='password' rules={[passwordMatch(), required()]} suffix={<Icon type="lock" className={'primary-sub-color'} style={{fontSize: '16px'}}/>} required={true} form={props.form} error={getFieldError('confirm_password')} name='confirm_password' label='Confirme sua Senha' placeholder='Confirme sua Senha' valueForMemo={getFieldsValue().confirm_password} />
                        </Col>
                    </Row>}

                    <Row style={{display: 'flex', flexFlow: 'row', marginTop: 10}}>
                        <div>
                            <Checkbox defaultChecked={false} onChange={() => setAllow(!allow)}/>
                        </div>
                        <div >
                            <span style={{marginLeft: 5, fontSize: 12}}>
                            Ao clicar em Inscrever-se você concorda com nossos
                            <a target={'_blank'} href={window.location.origin + '/termos'}> termos </a> e
                            <a target={'_blank'} href={window.location.origin + '/termos'}> políticas de privacidade </a>
                            </span>
                        </div>
                    </Row>

                    <Row style={{textAlign: 'right', display: 'flex', flexFlow: 'column', alignItems: 'end'}}>
                        <div style={{marginBottom: 10}}>
                            <span onClick={() => setAttendantField(!attendantField)} style={{fontSize: 12, color: '#1890ff', cursor: 'pointer'}}> Foi auxiliado por algum atendente? </span>
                        </div>
                        {attendantField&&<Col xs={24} sm={12}>
                            {getFieldDecorator('attendant_code')(<Input style={{marginTop: 5}}  type={'text'} size={"small"} placeholder={"Insira o código do atendente"} />)}
                        </Col>}
                    </Row>
                    <Button className={'ant-btn-sub-primary'} loading={loading || imageApi.loading} disabled={loading || imageApi.loading || !allow} onClick={() => handleSubmit()} style={{width: '100%', marginTop: 20}} size={'large'}> Inscrever-se </Button>
                </div>
            </Fade>
        </div>
    );
};

RegisterFinalForm.propTypes = {
    mobileVerified: PropTypes.any,
    mobileToken: PropTypes.any
};

export default Form.create({name: 'RegisterForm'})(RegisterFinalForm);
