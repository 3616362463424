import React, {forwardRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Button,
    Col,
    Collapse,
    Divider,
    Drawer,
    Form,
    Icon,
    message,
    Modal,
    notification, Popover,
    Row,
    Select,
    Spin,
    Tag
} from 'antd';

import {Fade, Slide} from 'react-reveal';
import useApi from "../../../../Hooks/Api";

import {useValidationShorts} from "../../../../Hooks/Helpers";
import NumberFormat from "react-number-format";
import useAuth from "../../../../Hooks/Auth";
import * as moment from "moment";
import {maskPhone} from "../../../../Helpers/Functions";
import {Images} from "../../../../Helpers/Images";
import RegisterPageModal from "../../../../Pages/RegisterPageModal";
import {useSelector} from "react-redux";
import {Env} from "../../../../Helpers/Env";
import * as Sentry from "@sentry/react";
import {
    FaCheckCircle,
    FaFacebookSquare,
    FaInstagram,
    FaLinkedin,
} from "react-icons/fa";
import FeedbackForm from "../../Restrict/RecruitmentsPage/FeedbackForm";
import FeedbackStepsModal from "../../Restrict/RecruitmentsPage/FeedbackStepsModal";
import RichTextEditor from "../../Restrict/RecruitmentsPage/RichTextEditor";
import VerificationCodeComponent from "../../../../Pages/Components/VerificationCodeComponent";
import {AiFillCloseCircle, FiPauseCircle} from "react-icons/all";
import {Link, useHistory} from "react-router-dom";
import ouro from "../../../../Img/badges/ouro.png";
import prata from "../../../../Img/badges/prata.png";
import bronze from "../../../../Img/badges/bronze.png";

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}

const pStyle = {
    fontSize: 16,
    color: 'rgba(0,0,0,0.85)',
    lineHeight: '24px',
    display: 'block',
    marginBottom: 16,
};

const DescriptionItem = ({ title, content }) => (
    <div className="profile-description-item">
        <p>
            {title}:
        </p>
        {content}
    </div>
);

const ResumeDrawer = forwardRef((props, ref) => {
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const {getFieldsValue, getFieldDecorator, validateFields, getFieldError} = props.form;
    const [recruitLoading, setRecruitLoading] = useState(false);
    const api = useApi({customReturn: true, loadingControl: false});
    const [privateProfile, setPrivateProfile] = useState(false);
    const [visible, setVisible] = useState(false);
    const {required} = useValidationShorts();
    const {isLogged, getEnterpriseOpportunities,getEnterprise} = useAuth();
    const opportunitiesList = useRef(getEnterpriseOpportunities() || []);
    const [isFirstJob, setIsFirstJob] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const user = useSelector((state) => state).authReducer;
    const [showFeedback, setShowFeedback] = useState(false);
    const [showFirstViewModal, setShowFirstViewModal] = useState(props?.viewed === false)
    const [favorite, setFavorite] = useState(props?.favorite)
    const [hideNote, setHideNote] = useState(false)
    const [validatePhoneModal, setValidatePhoneModal] = useState(false);
    const [opportunitiesInterests, setOpportunitiesInterests] = useState([])
    const history = useHistory();
    const disableArrows = useRef(false);

    const getData = async (id, cboId, recruited, verified) => {

        setLoading(true);
        let endpointAux = '';
        if(recruited) {
            setPrivateProfile(true);
            if(user.mobile_verified || verified) {
                endpointAux = `sine/contact/${id}`;
            } else {
                setValidatePhoneModal(true);
                return 0;
            }
        } else {
            endpointAux = `sine/profile/${id}?cbo_occupation_id=${cboId}`;
        }
        api.get(endpointAux, (initialRes) => {
            let res = initialRes;
            setOpportunitiesInterests(res.opportunities_interests)
            res.occupations_interest.push(res.occupation?.name);
            res.occupation&& res.occupations_details.push(res.occupation);
            if (res.extra?.knowledge?.length > 0) {
                res.extra.knowledge_order_desc = {level_1:[], level_2:[], level_3:[]}

                for (let i = 0 ; i < res.extra.knowledge.length ; i++) {
                    if (res.extra.knowledge[i].level === '1') {
                        res.extra.knowledge_order_desc?.level_1.push(res.extra.knowledge[i])
                    } else if (res.extra.knowledge[i].level === '2') {
                        res.extra.knowledge_order_desc?.level_2.push(res.extra.knowledge[i])
                    } else {
                        res.extra.knowledge_order_desc?.level_3.push(res.extra.knowledge[i])
                    }
                }
            }
            try {
                if(res.extra.work_experience.length > 0) {
                    for(let i = 0; i < res.extra.work_experience.length; i ++) {
                        if(res.extra.work_experience[i].start_date !== res.extra.work_experience[i].end_date) {
                            break;
                        }
                        if(i + 1 === res.extra.work_experience.length) {
                            setIsFirstJob(true);
                        }
                    }
                } else {
                    for(let i = 0; i < res.occupations_details.length; i ++) {
                        if(res.occupations_details[i].formal_experience !== "Nenhuma" || res.occupations_details[i].informal_experience !== "Nenhuma") {
                            break;
                        }
                        if(i + 1 === res.occupations_details.length) {
                            setIsFirstJob(true);
                        }
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
            setProfile(res);
            setLoading(false);
        }, (e) => {
            setProfile({});
            setLoading(false);
            message.warning('Ops, não foi possível recuperar os dados do currículo, tente novamente mais tarde')
        });
    };

    const doRecruit = async () => {
        setRecruitLoading(true);
        message.loading('Recuperando dados de contato', 10);
        let auxPrivateProfile = {};

        validateFields(async (error, values) => {
            if (error) {
                setRecruitLoading(false);
                message.destroy();
                message.warning('Digite o CPNJ corretamente');
            } else {
                let objToSend = {cbo_occupation_id: props.cboId};
                let apiUrl = '';
                if(values.cnpj) objToSend.cnpj = values.cnpj;

                if(values.type !== 'contrato' && values.type !== 'empresarial') {
                    apiUrl = `sine/user/${user.user_id}/recruitment-opportunity/${props.idVacancy || values.type}`;
                    objToSend.worker_id = profile.id;
                } else {
                    apiUrl = `sine/profile/${profile.id}/personal-data`;
                }
                objToSend.application = Env.application_alias

                api.post(apiUrl, objToSend, (item) => {
                    api.get(`sine/contact/${item.contact_id}`, (res) => {
                        document.getElementById('scrollDivDrawer').scrollTop = 0;
                        const privateProfile = res;
                        setProfile(privateProfile);
                        auxPrivateProfile = privateProfile;
                        auxPrivateProfile.id = item.contact_id;
                        setPrivateProfile(true);

                        message.destroy();
                        message.success('Dados de contato agora disponíveis!');
                        setRecruitLoading(false);
                        setVisible(false);
                        props.handleRecruited();

                    }, (e) => {
                        message.destroy();
                        message.success('Dados de contato agora disponíveis!');
                        setRecruitLoading(false);
                        setVisible(false);
                        props.handleRecruited();
                        props.setVisible(false)
                    });
                },(e) => {
                    message.destroy();
                    message.error(e.message || 'Não foi possível obter os dados deste profissional');
                    setRecruitLoading(false);
                });
            }
        })
    };

    const convertDate = (end, start) => {
        let years;
        let months;
        if(end) {
            years = moment(end,'YYYY-MM').diff(start, 'years');
            months = moment(end,'YYYY-MM').diff(start, 'months');
        } else {
            years = moment().diff(start, 'years');
            months = moment().diff(start, 'months');
        }

        if(years < 1) {
            return <span> {months % 12 + ' meses'} </span>
        } else if (months % 12 > 0){
            return <span> {years + ' ano(s) e ' + months % 12 + ' mês(es)'} </span>
        } else {
            return <span> {years + ' ano(s)'} </span>
        }

    };

    const handleRecruit = () => {
        if(isLogged()) {
            if(user.mobile_verified) {
                setVisible(true);
            } else {
                setValidatePhoneModal(true);
            }
        }
        else {
            const key = 'notificationLocalKey';
            notification.open({
                duration: 8,
                description: <div>
                    <div style={{marginBottom: 20, textAlign: 'center'}}>
                        <img src={Images.logo_header} style={{height: 100}}/>
                    </div>
                    <div style={{marginBottom: 20}}> Para solicitar os dados de contato de um profissional é necessário realizar o cadastro!</div>
                    <div style={{textAlign: 'center'}}> <Button onClick={() => {
                        notification.close('notificationLocalKey');
                        setRegisterModal('login')
                    }} icon={'caret-right'} type={'primary'}> Cadastre-se </Button> </div>

                </div>,
                key,
                placement: 'topLeft'
            });
        }
    };

    const handleArrowClick = (event) => {
        if(!disableArrows.current) {
            const key = event.key; // "ArrowRight", "ArrowLeft", "ArrowUp", or "ArrowDown"
            if(key === 'ArrowRight') {
                document.getElementById('scrollDivDrawer').scrollTop = 0;
                if(props.candidatesList[props.currentIndex + 1]) {
                    let aux = props.candidatesList[props.currentIndex + 1];
                    aux.index = props.currentIndex + 1;
                    props.updateFeedbackUser(aux);
                } else {
                    let aux = props.candidatesList[0];
                    aux.index = 0;
                    props.updateFeedbackUser(aux);
                }
            } else if(key === 'ArrowLeft') {
                document.getElementById('scrollDivDrawer').scrollTop = 0;
                if(props.candidatesList[props.currentIndex - 1]) {
                    let aux = props.candidatesList[props.currentIndex - 1];
                    aux.index = props.currentIndex - 1;
                    props.updateFeedbackUser(aux);
                } else {
                    let aux = props.candidatesList[props.candidatesList.length - 1];
                    aux.index = props.candidatesList.length - 1;
                    props.updateFeedbackUser(aux);
                }
            }
        }
    };

    useEffect(() => {
        if(props.arrowShortCut) {
            if(props.visible) {
                setFavorite(props.favorite);
                setShowFirstViewModal(props?.viewed === false)
                window.addEventListener('keydown', handleArrowClick);
            }
            return () => window.removeEventListener('keydown', handleArrowClick);
        }
    }, [props.id]);

    useEffect(() => {
        setProfile({});
        setPrivateProfile(false);
        setIsFirstJob(false);
        if(props.id) {
            getData(props.id, props.cboId, props.recruited);
        }
    },[props.id]);

    return (
        <Drawer
            className="profile-drawer resume-drawer"
            placement={'right'}
            closable={false}
            onClose={() => {props.setVisible(false); props.onClose&& props.onClose()} }
            visible={props.visible}
        >
            <RegisterPageModal profile={'client'} visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />
            {showFeedback &&<FeedbackForm profile={{...profile, ...{interest_id: props?.interest_id}}} vacancyFeedback={props.vacancyFeedback} id={user.user_id} getData={() => {props.getData()}} showFeedback={showFeedback} setShowFeedback={(item) => {
                setShowFeedback(item);
                props.setVisible(false);
            }}/>}


            <div style={{overflow: 'auto', padding: 24, height: window.innerHeight}} id={'scrollDivDrawer'}>
                {(profile.status && props.visible && !props.hideStepFeedback && !props.idVacancy) &&
                    <div className='d-flex flex-column control-recruiter' style={{gap:4, height: window.innerWidth <= 1200 && 'unset'}}>
                        <FeedbackStepsModal handleStepChange={(status) => props.handleStepChange({...profile, ...{interest_id: props?.interest_id, index: props.currentIndex, opportunity_id: props?.opportunity_id, status: props?.status}},status)} profile={{...profile, ...{interest_id: props?.interest_id,  opportunity_name: props?.opportunity_name, opportunity_id: props?.opportunity_id, status: profile.interest_status || props?.status}}} sine_id={props.id} vacancyFeedback={props.vacancyFeedback} id={user.user_id} getData={() => {props.getData()}} showFeedback={props.showFeedback} setShowFeedback={(item) => {
                            setShowFeedback(item);
                            props.setVisible(false);
                        }}/>
                        {(window.innerWidth > 1200 && profile.sine_user_id && props.company_id) && <RichTextEditor setDisableArrows={(item) => disableArrows.current = item} sine_id={profile.sine_user_id} company_id={props.company_id}/>}
                    </div>
                }
                <Spin spinning={loading} tip="Carregando...">
                    <React.Fragment>

                        <Row style={{ marginBottom: 24, marginTop: 20}}>
                            <Col span={24} style={{display: "flex", justifyContent: 'space-between', flexWrap: "wrap"}}>
                                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                    <div style={{width: 'fit-content', display: 'flex', flex: 1}}>

                                        {profile?.badges?.length> 0 &&<div style={{display: 'flex', width: '100%', position: 'absolute', top: 65, left: 20, zIndex: 15 }}>
                                            {profile?.badges?.length >= 10 ? <div  >
                                                <Popover placement={"topRight"} content={'Este currículo possui selo Ouro, representando a conclusão de mais de 11 cursos através do Geração Emprego!'} title={'Selo Ouro'}>
                                                    <img src={ouro} style={{borderRadius: '50%', height: 41}}/>
                                                </Popover>
                                            </div> : profile?.badges?.length >= 6 ? <div  >
                                                <Popover placement={"topRight"} content={'Este currículo possui selo Prata, representando a conclusão de mais de 5 cursos através do Geração Emprego! '} title={'Selo Prata'}>
                                                    <img src={prata} style={{borderRadius: '50%', height: 41}}/>
                                                </Popover>
                                            </div> : <div  style={{marginRight: 5}}>
                                                <Popover placement={"topRight"} content={'Este currículo possui selo Bronze, representando a conclusão de ao menos 1 curso através do Geração Emprego!'} title={'Selo Bronze'}>
                                                    <img src={bronze} style={{borderRadius: '50%', height: 41}}/>
                                                </Popover>
                                            </div>}
                                        </div>}

                                        <Avatar style={{minWidth: 80, minHeight: 80}} src={props.avatar || profile.profile_picture} size="large" />
                                        <div>
                                            {props.username && <h3 style={{
                                                marginLeft: 12,
                                                fontSize: 22,
                                                marginBottom: 0
                                            }}>{props.username}</h3>}
                                            <h2 style={{
                                                verticalAlign: "middle",
                                                display: 'inline-block',
                                                margin: '0 0 0 12px'
                                            }}> {props.titleVacancy ? <span style={{fontSize: 16, color: '#757575'}}>Recomendação para a vaga <span
                                                className='title-vacancy-recommended'>{props.titleVacancy}</span></span> : (profile.name ? profile.name : profile.occupation?.name)} </h2>
                                            <div style={{margin:"-4px 0px 10px 14px"}}>
                                                <span className={'ant-badge'} style={{fontSize: 11, color:'#aeaeae'}}>
                                                     Recebido em: {moment(profile?.date).format('L')}
                                                </span>
                                            </div>
                                            {!!profile.name && <><Tag color="blue"
                                                                           style={{marginLeft: 11}}> {profile.occupation?.name}</Tag></>}
                                        </div>
                                    </div>
                                    {privateProfile && <div style={{display: 'flex'}}>
                                        <Button shape={"circle"} icon={'printer'} style={{
                                            height: 45,
                                            width: 45,
                                            marginRight: 5,
                                            color: 'gray',
                                            fontSize: 20
                                        }}
                                                onClick={() => window.open(window.location.origin + '/curriculo/privado/' + (profile.id || props.id))}/>
                                        {!props.cboId && <>
                                            {props?.handleFavorite &&<Button className={favorite ? 'ant-btn-gold' : 'ant-btn-gold-ghost'}  shape={"circle"} icon={'star'} style={{height: 45, width: 45, marginRight: 5, color: 'gray', fontSize: 20}} onClick={() => {
                                                setFavorite(!favorite);
                                                props?.handleFavorite()
                                            }} />}
                                        </>}
                                    </div>}
                                </div>
                            </Col>
                        </Row>

                        {profile.name?
                            <Fade left>
                                <div>
                                    <p style={{...pStyle, color: "#2593fc"}}><Icon type="lock" /> Informações Privadas</p>
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="Nome" content={profile.name} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="CEP" content={profile.postal_code} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <DescriptionItem title="Endereço" content={profile.address} />
                                        </Col>
                                    </Row>
                                    {profile.emails?.length > 0 &&<Row>
                                        <Col span={24}>
                                            <DescriptionItem title="Emails" content={profile.emails?.join(", ")} />
                                        </Col>
                                    </Row>}
                                    {profile.phone_numbers?.length > 0 &&<Row>
                                        <Col span={24}>

                                            <div className="profile-description-item">
                                                <p>
                                                    Telefones:
                                                </p>
                                                {profile.phone_numbers.map((item, index) => {
                                                    let aux = item.replace(/\D/g, "");
                                                    return <span key={index} style={{marginRight: 10}}> <a target={'_blank'} href={'https://web.whatsapp.com/send?phone=+' + aux + '&text=Olá, ' + profile.name + '! Selecionei seu currículo pelo ' + Env.application_title + ', tudo bem?'}> <img style={{height: 20, marginBottom: 5, cursor: 'pointer'}}  src={Images.whats}/> </a>  {maskPhone(item)} </span>
                                                })}
                                            </div>
                                        </Col>
                                    </Row>}

                                    {(profile.linkedin_link || profile.instagram_link || profile.facebook_link) &&
                                        <Row>
                                            <div style={{display:"flex",alignItems:"center", gap: 14, flexWrap:'wrap'}}>
                                                <div>
                                                    <span style={{fontWeight:"bold", color: 'rgba(0, 0, 0, 0.85)'}}>
                                                        Rede social:
                                                    </span>
                                                </div>

                                                {profile?.instagram_link &&
                                                    <div>
                                                        <a style={{display: "flex", alignItems:"center", gap:6}} target={`_blank`} href={`https://www.instagram.com/${profile.instagram_link}`}>
                                                            <FaInstagram size={20}/> @{profile.instagram_link}
                                                        </a>
                                                    </div>
                                                }
                                                {profile?.facebook_link &&
                                                    <div>
                                                        <a style={{display: "flex", alignItems:"center", gap:6}} target={`_blank`} href={`https://www.facebook.com/${profile.facebook_link}`}>
                                                            <FaFacebookSquare size={20} /> {profile.facebook_link}
                                                        </a>
                                                    </div>
                                                }
                                                {profile?.linkedin_link &&
                                                    <div>
                                                        <a style={{display: "flex", alignItems:"center", gap:6}} target={`_blank`} href={`https://www.linkedin.com/in/${profile.linkedin_link}`}>
                                                            <FaLinkedin size={20}/> {profile.linkedin_link}
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        </Row>
                                    }

                                    {profile?.extra?.knowledge?.length > 0 &&
                                        <>
                                            <Row type='flex' style={{margin:'16px 0' , gap:10}}>
                                                <strong style={{fontWeight: 'bold', fontSize: 14, color: 'rgba(0,0,0,0.85)'}}>Habilidades:</strong>
                                                {profile?.extra?.knowledge_order_desc?.level_3?.length > 0 &&
                                                    <div>
                                                        {profile.extra.knowledge_order_desc.level_3.map((item) => (
                                                            <Tag color='#34c38f2e' style={{fontSize:14, color:'#34c38f'}}>
                                                                {item.title}
                                                            </Tag>
                                                        ))}
                                                    </div>
                                                }

                                                {profile?.extra?.knowledge_order_desc?.level_2?.length > 0 &&
                                                    <div>
                                                        {profile.extra.knowledge_order_desc.level_2.map((item) => (
                                                            <Tag color='#50a5f12e' style={{fontSize:14, color:'#50a5f1'}}>
                                                                {item.title}
                                                            </Tag>
                                                        ))}
                                                    </div>
                                                }

                                                {profile?.extra?.knowledge_order_desc?.level_1?.length > 0 &&
                                                    <div>
                                                        {profile.extra.knowledge_order_desc.level_1.map((item) => (
                                                            <Tag color='#f1b44c2e' style={{fontSize:14, color:'#f1b44c'}}>
                                                                {item.title}
                                                            </Tag>
                                                        ))}
                                                    </div>
                                                }
                                            </Row>
                                            <div className='d-flex align-items-center' style={{gap:10, justifyContent:"end"}}>
                                                <div className='knowledge-level'>
                                                    <div className='lv-3' />
                                                    Avançado
                                                </div>
                                                <div className='knowledge-level'>
                                                    <div className='lv-2' />
                                                    Intermediário
                                                </div>
                                                <div className='knowledge-level'>
                                                    <div className='lv-1' />
                                                    Básico
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <Divider />
                                </div>
                            </Fade>
                            :
                            null
                        }

                        {opportunitiesInterests?.length > 0 &&<Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'bgred'} header={<span style={{fontWeight: 'bold', color: '#fe7979'}}> <Icon type="solution" /> Este trabalhador já se candidatou em outras vagas </span>} key="1">
                                <div style={{background: "white"}}>
                                    {opportunitiesInterests?.map((item, index) =>
                                        <div key={index} style={{width: '100%', marginBottom: 15}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <Link target="_blank" to={'/painel/cliente/vagas/' + getEnterprise().id + '/detalhes-da-vaga/' + item.opportunity_id} style={{fontSize: '18px', cursor: 'pointer'}}>{item.occupation_title? item.occupation_title : item.occupation_name}  </Link>
                                                {item.closed !== true ? <span>
                                                                <div style={{color:'#34c38f'}} className='badge-status-opportunity'>
                                                                    <FaCheckCircle/>Vaga aberta
                                                                </div>
                                                            </span>
                                                    :
                                                    <div className='badge-status-opportunity paused' style={{marginRight:4, background:'#fff2f3', color: '#fe7979'}}>
                                                        <AiFillCloseCircle/>
                                                        <span>Encerrada</span>
                                                    </div>}
                                            </div>
                                            <div style={{display: 'flex', marginTop: 10, justifyContent: 'space-between'}}>
                                                <span style={{fontSize: 13, color: "#9e9e9e"}}> {item.occupation_title? (item.occupation_name + " / " + item.job_type[0]) : item.job_type[0] }</span>


                                                <span style={{color: 'darkgray'}}>

                                                    Se inscreveu em
                                                    <strong style={{color: 'gray'}}>
                                                       {" " + moment(item.date).format("DD/MM/YYYY")}
                                                    </strong>
                                                </span>


                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Collapse.Panel>
                        </Collapse>}

                        {profile?.about_me &&<Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greyBg'} header={<span style={{fontWeight: 'bold', color: '#1a1a1a'}}> <Icon type="align-left" /> Sobre mim </span>} key="1">
                                <span>
                                    {profile.about_me}
                                </span>
                            </Collapse.Panel>
                        </Collapse>}

                        {profile.profile_video &&<Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume'
                                  defaultActiveKey={['1']} expandIcon={({isActive}) =>
                            <div className={isActive ? "circle-plus closed" : "circle-plus opened"}>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div>}>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="video-camera"/> Vídeo de apresentação</span>} key="1">
                                <div style={{justifyContent: 'center', display: 'flex'}}>
                                    <video className="video_curriculum" src={profile.profile_video} width="320" height="240" controls/>
                                </div>
                            </Collapse.Panel>
                        </Collapse>}

                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel header={<span style={{fontWeight: 'bold', color: '#0072d6'}}> <Icon type="audit" /> Experiências </span>} key="1">
                                {profile?.extra?.work_experience?.length > 0? profile?.extra?.work_experience?.map((item,index) =>
                                    {if((item.start_date !== item.end_date)) {
                                        return <Row key={index} className='details-experience' style={{ borderBottom: profile?.extra?.work_experience?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#4caf50'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Cargo: </strong>{item.occupation}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Empresa: </strong> {privateProfile? item.company :  <span className='select-curriculum-badge'>Selecione este currículo para ver</span>}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Último salário: </strong>{privateProfile? (item.last_wage? <span>R$ {item.last_wage}</span> : 'Não informado') :  <span className='select-curriculum-badge'>Selecione este currículo para ver</span> }</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Tipo de trabalho: </strong>{item.work_type}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Local: </strong>{item.location}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Descrição do serviço: </strong>
                                                    <p>
                                                        {item.description? item.description : 'Não informado'}
                                                    </p>
                                                </label>
                                            </Col>

                                            {(item.work_type !== 'Freelance' && item.work_type !== 'Autônomo') ?
                                                <Col xs={24} md={24}>
                                                    <span>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Formal: </strong>
                                                        <span>{convertDate(item.end_date, item.start_date)}</span>
                                                        <span>( Inicio - {moment(item.start_date, 'YYYY-MM').format('MM/YYYY')}) </span>
                                                        <span>{item.end_date ? <span>( Fim - {moment(item.end_date, 'YYYY-MM').format('MM/YYYY')})</span> : <span style={{color: "#1b429c", fontWeight: 600}}>Trabalhando atualmente</span>}</span>
                                                    </span>
                                                </Col>
                                                :
                                                <Col xs={24} md={24}>
                                                    <span>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Informal: </strong>
                                                        <span>( Inicio - {moment(item.start_date, 'YYYY-MM').format('MM/YYYY')}) </span>
                                                        <span>{item.end_date ? <span>( Fim - {moment(item.end_date, 'YYYY-MM').format('MM/YYYY')})</span> : <span style={{color: "#1b429c", fontWeight: 600}}>Trabalhando atualmente</span>}</span>
                                                    </span>
                                                </Col>
                                            }
                                        </Row>
                                        }
                                    })
                                    : ((profile?.occupations_details?.length > 0) ? profile.occupations_details.map((item, index) =>
                                        {if(item?.formal_experience !== 'Nenhuma' || item?.informal_experience !== 'Nenhuma') {
                                            return <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.occupations_details?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#4caf50'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Cargo: </strong>{item.name}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Era estágio? </strong>{item.internship? 'Sim' : 'Não'}</label>
                                            </Col>

                                            <Col xs={24} md={24}>
                                                <span>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Formal: </strong>
                                                    <span>{item.formal_experience}</span>
                                                </span>
                                            </Col>

                                            <Col xs={24} md={24}>
                                                <span>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Informal: </strong>
                                                    <span>{item.informal_experience}</span>
                                                </span>
                                            </Col>
                                        </Row>}})
                                        :
                                        <span> Não possui </span>
                                    )
                                }

                                {isFirstJob&&
                                <Row type={'flex'}>
                                    <Col span={8}>
                                        <img src={Images.search_resumes} style={{maxWidth: '100%'}}/>
                                    </Col>
                                    <Col span={16} style={{alignSelf: 'center'}}>
                                        <h1 style={{fontSize: 23, textAlign: 'center'}}> Profissional em busca de sua primeira colocação </h1>
                                    </Col>
                                </Row>
                                }
                            </Collapse.Panel>
                        </Collapse>


                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel header={<span style={{fontWeight: 'bold', color: '#0072d6'}}> <Icon type="audit" /> Outros Interesses </span>} key="1">
                                {profile?.extra?.work_experience?.length > 0? profile?.extra?.work_experience?.map((item,index) =>
                                    {if((item.start_date === item.end_date)) {
                                        return <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.extra?.work_experience?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> {item.occupation} </strong>
                                                </label>
                                            </Col>
                                        </Row>
                                    }}
                                )
                                : (profile?.occupations_interest?.length > 0 ? profile.occupations_interest.map((item, index) =>
                                    {if(item?.formal_experience === 'Nenhuma' && item?.informal_experience === 'Nenhuma') {
                                        return <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.occupations_interest?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> {item} </strong>
                                                </label>
                                            </Col>
                                        </Row>
                                    }})
                                    :
                                    <span> Não possui </span>
                                )}
                            </Collapse.Panel>
                        </Collapse>

                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="book" /> Graduações ({profile?.extra?.academic?.length > 0? profile?.extra?.academic?.length : profile?.grads?.length })</span>} key="1">
                                {profile?.extra?.academic?.length > 0? profile?.extra?.academic?.map((item,index) =>
                                    <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.extra?.academic?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                        <Col xs={24}>
                                            <label style={{fontWeight: 'bold', color: '#0072d6'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item.course}</label>
                                        </Col>

                                        <Col xs={24}>
                                            <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Instituição: </strong>{item.university}</label>
                                        </Col>

                                        <Col xs={24}>
                                            <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Nível: </strong>{item.academic_degree}</label>
                                        </Col>

                                        <Col xs={24} md={12}>
                                            <span> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Inicio: </strong> {item.start_date? moment(item.start_date, 'YYYY-MM').format('MM/YYYY'): 'Não informado' }</span>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <span> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Fim: </strong> {item.end_date? moment(item.end_date, 'YYYY-MM').format('MM/YYYY'): 'Não informado' }</span>
                                        </Col>
                                    </Row>
                                    )
                                    : (profile?.grads?.length > 0 ? profile.grads.map((item, index) =>
                                        <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.grads?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#0072d6'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item}</label>
                                            </Col>
                                        </Row>)
                                        :
                                        <span> Não possui </span>
                                    )
                                }
                            </Collapse.Panel>
                        </Collapse>



                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={[`1`]} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="control" /> Cursos e capacitações ({profile.badges !== null ? profile?.courses?.length + profile?.badges?.length : profile?.courses?.length}) </span>} key="1">

                                {profile?.extra?.courses.length > 0 ? profile?.extra?.courses?.map((item,index) =>
                                        <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.extra?.courses?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#259a90'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item.name}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Instituição: </strong>{item.institution}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Duração: </strong>{item.duration? <span> {item.duration} meses </span> : 'Não informado'} </label>
                                            </Col>
                                        </Row>
                                    )
                                    : (profile?.courses?.length > 0 ? profile.courses.map((item, index) =>
                                            <Row key={index} style={{marginBottom: 10, paddingBottom: 10,  borderBottom: profile?.courses?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                                <Col xs={24}>
                                                    <label style={{fontWeight: 'bold', color: '#259a90'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item}</label>
                                                </Col>
                                            </Row>)
                                        :
                                        <span> Não possui </span>
                                    )
                                }

                                {profile.badges?.length> 0 &&<div style={{paddingTop: 10, borderTop: '1px solid gainsboro'}}>

                                    <div style={{display: 'flex', width: '100%', overflow: 'hidden'}}>
                                        {profile.badges.map((item, index) => <div style={{marginRight: 5, alignItems: `center`, display: "flex"}}>
                                            <img src={item.image} style={{borderRadius: '50%', height: 45, width: 45}}/>
                                            <div style={{display: "flex", flexFlow: "column", marginLeft: 15}}>
                                                <label style={{fontWeight: 'bold', color: '#259a90'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Capacitação: </strong>{item.title}</label>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Instituição: </strong>{item.institution}</label>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Descrição: </strong>{item.description}</label>
                                                {item.teachers &&<label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Certificado por: </strong>{item.teachers?.toString()}</label>}
                                            </div>
                                        </div>)}
                                    </div>
                                </div>}
                            </Collapse.Panel>
                        </Collapse>

                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="contacts" /> Outras Informações</span>} key="1">
                                <Row>
                                    {profile.age &&<Col span={12}>
                                        <DescriptionItem title="Idade" content={profile.age + ' Anos'} />
                                    </Col>}
                                    {profile.gender &&<Col span={12}>
                                        <DescriptionItem title="Gênero" content={genders[profile.gender]} />
                                    </Col>}
                                </Row>
                                {profile.wage_claim &&<Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Pretensão salarial" content={privateProfile? 'R$ ' + profile.wage_claim: <span className='select-curriculum-badge'>Selecione este currículo para ver</span>} />
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col span={12}>
                                        <DescriptionItem title="Estudante" content={profile.is_student ? "Sim" : "Não"} />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem title="Habilitação" content={profile.driver_license || 'Não tem'} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Escolaridade" content={profile.schooling} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Endereço" content={profile.address} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Disponibilidade para realizar viagens" content={profile.available_travel ? "Sim" : "Não"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Disponibilidade para dormir no local de trabalho" content={profile.available_sleep_duty ? "Sim" : "Não"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Disponibilidade para ausentar-se do domicílio por longo período" content={profile.available_leave_home ? "Sim" : "Não"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>

                                        <div className="profile-description-item">
                                            <p>
                                                Outras Idiomas:
                                            </p>
                                            {profile.languages?.length !== 0?
                                                <ul style={{ lineHeight: '22px' }}>
                                                    { profile.languages?.map((el, index) => <li key={index}>{el}</li>) }
                                                </ul>
                                                :
                                                'Nenhum'
                                            }
                                        </div>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24}>

                                        <div className="profile-description-item">
                                            <p>
                                                Veículos:
                                            </p>
                                            {profile.vehicles?.length !== 0 ?
                                                <ul style={{lineHeight: '22px'}}>
                                                    {profile.vehicles?.map((el, index) => <li key={index}>{el}</li>)}
                                                </ul> :
                                                'Nenhum'
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>

                                        <div className="profile-description-item">
                                            <p>
                                                Deficiências:
                                            </p>
                                            {profile.disabilities?.length !== 0 ?
                                                <ul style={{lineHeight: '22px'}}>
                                                    {profile.disabilities?.map((el, index) => <li key={index}>{el}</li>)}
                                                </ul> :
                                                'Nenhuma'
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>

                        {(profile.status && props.visible && window.innerWidth < 1201 && profile.sine_user_id && props.company_id) &&
                            <div style={{height: hideNote ? 'auto' : 250, boxShadow: '0 2px 12px rgb(0 0 0 / 9%)', borderRadius:'5px'}}>
                                <RichTextEditor setDisableArrows={(item) => disableArrows.current = item} setHideAnnotation={setHideNote} sine_id={profile.sine_user_id} company_id={props.company_id}/>
                            </div>
                        }

                        {!privateProfile &&<Row>
                            <Button onClick={() => handleRecruit()} className="gx-btn" style={{background:"orange", borderColor: 'orange', fontWeight: 'bold'}} type="primary" block>
                                VER CONTATOS PARA ENTREVISTAR
                            </Button>
                        </Row>}

                        {props.arrowShortCut &&<div style={{display: 'flex', marginTop: 20, flexDirection: 'row', justifyContent: 'space-around'}}>
                            {props.candidatesList?.length > 0 && <div style={{cursor: 'pointer'}} onClick={() => handleArrowClick({key: 'ArrowLeft'})}>
                                <Icon type="arrow-left" />
                                <span style={{marginLeft: 5, fontWeight: 'bold'}}>Anterior</span>
                            </div>}
                            {props.candidatesList?.length > 0 && <div style={{cursor: 'pointer'}} onClick={() => handleArrowClick({key: 'ArrowRight'})}>
                                <span style={{marginRight: 5, fontWeight: 'bold'}}>Próximo</span>
                                <Icon type="arrow-right" />
                            </div>}
                        </div>}
                    </React.Fragment>
                </Spin>

                {(showFirstViewModal && profile.gender) &&<div style={{padding: 15, flexDirection: 'column', backgroundColor: 'white', justifyContent: 'center', boxShadow:'rgb(236 236 236) 0px 3px 6px 1px', borderRadius: 5,
                    border: '1px solid #dcdcdc94',
                    position: 'fixed',
                    bottom: 10,
                    right: 10
                }}>
                    <div style={{textAlign: 'center', fontSize: 17, marginBottom: 20}}>
                        <strong>Deseja manter esse currículo?</strong>
                    </div>
                    <div style={{justifyContent: 'center', display: "flex"}}>
                        <Button style={{marginRight: 10, flex: 1}} type={"primary"} icon={'check'} onClick={() => setShowFirstViewModal(false)}> Sim </Button>
                        <Button type={"danger"} ghost={true} icon={'close'} style={{flex: 1}} onClick={() => props?.disregardCurriculum()}> Não </Button>
                    </div>
                </div>}
            </div>
            <Modal className={'requestModal resume-drawer'}
                   width={620}
                   visible={visible}
                   title={'Solicitar contato'}
                   confirmLoading={recruitLoading}
                   onOk={() => doRecruit()}
                   onCancel={() => setVisible(false)}
                   cancelText={'Cancelar'}
                   okText={'Solicitar dados'}>
                <div>
                    <div style={{fontSize: 17}}>
                        <span>
                            Este currículo será adicionado à sua lista de recrutamentos. Não se esqueça de enviar um feedback para nós informando-nos do resultado da entrevista :)
                        </span>
                    </div>

                    <Divider/>
                    <div className={'mainDrawerModalRequest'}>
                        <div style={{textAlign: 'center', marginBottom: 20}}>
                            <img style={{width: '240px'}} src={Images.vacancy_img}/>
                        </div>
                        {props.idVacancy ?
                            <div style={{textAlign:'center'}}>
                                <span style={{fontSize:'1rem'}}>
                                    Os dados do usuário ficarão disponíveis na vaga:
                                </span>
                                <div>
                                    <span style={{fontSize:'1.25rem'}} className='title-vacancy-recommended'>{props.titleVacancy}</span>
                                </div>
                            </div>
                            :
                            <>
                                <Form.Item required={true}
                                           className='hideDots'
                                           validateStatus={getFieldError('type')&& 'error'}
                                           help={getFieldError('type')}
                                           label={<span style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}>Para onde será realizado o recrutamento?</span>}
                                >
                                    {getFieldDecorator('type', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Campo Obrigatório',
                                            },
                                        ],
                                        initialValue: props.idVacancy && props.idVacancy
                                    })(<Select
                                        mode="default"
                                        style={{ width: '100%',marginTop: 5 }}
                                        placeholder={"Escolha aqui"}
                                        allowClear={false}
                                        size={'large'}
                                        suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}
                                    >

                                        <Select.OptGroup label={'Não relacionar a nenhuma vaga'}>
                                            <Select.Option value={'contrato'}> Para mim mesmo (pessoa física) </Select.Option>
                                            <Select.Option value={'empresarial'}> Para minha empresa (pessoa jurídica) </Select.Option>
                                        </Select.OptGroup>
                                        <Select.OptGroup label={'Adicionar currículo como candidato à vaga'}>
                                            {opportunitiesList.current.map((item, index) =>
                                                <Select.Option value={item.id} key={index}> {item.occupation_title? item.occupation_title : item.occupation_name} </Select.Option>
                                            )}
                                        </Select.OptGroup>
                                    </Select>)}
                                </Form.Item>

                                {getFieldsValue().type === 'empresarial'&& <Slide right> <Form.Item label="Informe o CNPJ">
                                    {getFieldDecorator('cnpj', {
                                        rules: [required()],
                                        initialValue: getEnterprise()?.cnpj || undefined
                                    })(
                                        <NumberFormat style={{width: '100%', fontSize: 17, fontWeight: 'bold', textAlign: 'center'}} placeholder={'##.###.###/####-##'} className={' ant-input ant-input-lg cellPhone'} format={'##.###.###/####-##'} mask="_"/>
                                    )}
                                </Form.Item>  </Slide>}
                            </>
                        }

                    </div>

                </div>
            </Modal>
            <Modal
                visible={validatePhoneModal}
                footer={null}
                bodyStyle={{padding: 0,  display: 'flex', flexDirection: 'column'}}
                width={'80%'}
                closable={privateProfile} //se for privado entao nao eh pra deixar fechar pra ele nao conseguir ver o perfil
                onCancel={() => {setValidatePhoneModal(false)}}
                className={'registerModalWidth'}
                destroyOnClose={true}
                style={{maxWidth: '800px',}}
            >
                <Col xs={24} className={'sideLogin'} style={{backgroundColor: "#315dc3"}} >
                    <div style={{padding: 20}}>
                        <Fade left>
                            <div className={'logTitle'} style={{fontSize: 18}}>
                                <span> <strong> Para ver os dados do currículo você precisa validar seu número </strong> </span>
                                <div className={'line'} style={{backgroundColor: "#173277", marginBottom: 10}}/>
                                <span style={{color: '#fdfdfd', fontSize: 16}}> É rapidinho! </span>

                            </div>
                        </Fade>
                    </div>
                </Col>
                <VerificationCodeComponent showScreen={validatePhoneModal}
                   setShowScreen={() => setValidatePhoneModal(false)}
                   resumeRegister={() => {
                       setValidatePhoneModal(false)
                       if(privateProfile) {
                           if(props.id) {
                               getData(props.id, props.cboId, props.recruited, true);
                           }
                       } else {
                           setVisible(true);
                       }
                   }}
                />
            </Modal>
        </Drawer>
    );
});

ResumeDrawer.propTypes = {
    cboId: PropTypes.number,
    id: PropTypes.number,
    idVacancy: PropTypes.number,
    setVisible: PropTypes.func,
    visible: PropTypes.bool,
    recruited: PropTypes.any,
    favorite: PropTypes.any,
    handleFavorite: PropTypes.any,
    disregardCurriculum: PropTypes.any,
    titleVacancy: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.string,
};

export default Form.create({name: 'requestForm'})(ResumeDrawer);
