import React from 'react'
import NumberFormat from "react-number-format";

const SyDate = ({data, setValue, value}) => {

    return (
        <div>
            <div>
                <NumberFormat className={'ant-input ant-input-lg'}
                              id={data.label_key}
                              value={value}
                              onValueChange={(event) => setValue(event.formattedValue)}
                              format="##/##/####"
                              placeholder="DD/MM/AAAA"
                />
            </div>
        </div>
    )
}

export default SyDate
