import React, {useEffect, useRef, useState} from 'react';
import {AutoComplete, Button, Col, Form, Icon, Input, message, Row, Select, Spin} from "antd";
import {useSelector} from "react-redux";
import useApi from "../../../../../Hooks/Api";
import {FaBackward, FaForward, FaPlus, FaTrashAlt, FaUniversity} from "react-icons/fa";
import {MdMap, MdWork} from "react-icons/md";
import {Env} from "../../../../../Helpers/Env";
import {cities} from "../../../../../ApplicationAssets/CitiesRO";

const LocationsComponent = (props) => {

    const locationsList = useRef([]);
    const [locationSelected, setLocationSelected] = useState(null);
    const {getFieldDecorator, getFieldsValue, setFieldsValue, validateFields, getFieldsError} = props.form;
    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState();
    const geocode = useRef();
    const mapObj = useRef(null);
    const [block, setBlock] = useState();
    const [autocompleteText, setAutocompleteText] = useState('');
    const [cityList, setCityList] = useState(cities)

    const getLocations = async () => {

        setInitialLoading(true);
        api.get(`sine/user/${user.user_id}/regions-interest`, (res) => {
            if (res.user_id !== null) {
                props.setHasProfile(true);
                if (res.region_interest?.length > 0) {
                    locationsList.current = res.region_interest;
                } else {
                    locationsList.current = [];
                }
                setInitialLoading(false);
            }
        }, (e) => {
            console.log(e);
            setInitialLoading(false);
        });

        window.scrollToElement('body')
    }


    const handleAddLocation = () => {

        setLocationSelected({});
        /*setTimeout(() => {
            let bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(-25.179550, -53.300886),
                new window.google.maps.LatLng(-19.273674, -44.136429)
            );

            geocode.current = new window.google.maps.places.Autocomplete(document.querySelector('#geocodeField0'), {bounds: bounds, strictBounds:true , types: ['geocode']});
            geocode.current.addListener('place_changed', handleLocationSelected);
            geocode.current.setComponentRestrictions({'country': ['br']});
        },[200])*/


    };

    const handleSaveNewLocation = async (index) => {
        setLoading(true);
        if (location && block) {
            let objToSend = {
                region: block,
                city: location,
                state_abbrev: Env.state_restriction !== 'none' ? Env.state_restriction : 'MS',
                latitude: 0,
                longitude: 0
            };

            message.loading('Adicionando...');
            api.post(`sine/user/${user.user_id}/region-interest`, objToSend, (res) => {
                setLocationSelected(null);
                mapObj.current = null;
                setLocation('');
                setBlock('');
                message.destroy();
                message.success('Sucesso!');
                getLocations();
            }, (e) => {
                message.warning(e.message || 'Não foi possível salvar, tenha certeza que inseriu um endereço válido!');
                console.log(e);
            });
        }
        setLoading(false);
    };

    const autoCompleteSearch = (query) => {
        setAutocompleteText(query);
        const aux = []
        for (let i = 0; i < cities.length; i++) {
            if (cities[i].value.toLowerCase().includes(query.toLowerCase())) {
                aux.push(cities[i])
            }
        }
        setCityList(aux)
    }

    const handleDeleteLocation = async (id) => {
        if (!loading) {
            setLoading(true);

            message.loading('Removendo...');
            api.del(`sine/user/${user.user_id}/region-interest/${id}`, null, (res) => {
                setLocationSelected(null);
                message.destroy();
                message.success('Sucesso!');
                getLocations();
                setLoading(false);
            }, (e) => {
                message.warning(e.message || 'Não foi possível deletar, tente novamente!');
                console.log(e);
                setLoading(false);
            });
        }
    };

    const handleLocationSelected = () => {
        setTimeout(() => {
            const place = geocode.current.getPlace();
            let city = undefined;
            let state = undefined;
            let simpleName = place.vicinity || place.name;

            dance:
                for (let i = 0; i < place.address_components.length; i++) {
                    for (let j = 0; j < place.address_components[i].types.length; j++) {
                        if (place.address_components[i].types[j] === "administrative_area_level_2") {
                            city = place.address_components[i].long_name;
                        }
                        if (place.address_components[i].types[j] === "administrative_area_level_1") {
                            state = place.address_components[i].short_name;
                        }
                        if (city !== undefined && state !== undefined) {
                            break dance;
                        }
                    }
                }
            if (city === undefined || state === undefined) {
                message.warning('Não foi possível encontrar essa região');
                setLocation('');
                return 0;
            } else {
                setLocation(simpleName);
            }

            mapObj.current = {
                region: simpleName,
                city: city,
                state_abbrev: state,
                latitude: place().geometry.location.lat(),
                longitude: place().geometry.location.lng()
            }
        }, [200])
    };

    useEffect(() => {
        getLocations();
        window.scrollToElement('body');
    }, []);

    return (<div style={{marginBottom: 200}} className={'experienceForm aboutCss'}>

        <div style={{paddingBottom: 35, borderBottom: '1px solid gainsboro', textAlign: 'center', marginTop: 50}}>
            <h1>
                <strong style={{color: '#242424', fontSize: 25}}> Zona de interesse </strong>
            </h1>
            <span style={{fontSize: 20, color: '#9E9E9E'}}>   Escolha aqui caso tenha preferência em alguma localidade para trabalhar <small
                style={{fontSize: 13}}> (Máximo 2) </small> </span> <br/>
            <span style={{fontSize: 16, color: '#1890ff9e'}}>   O seu endereço cadastrado já é uma zona de interesse! Não há necessidade de registrar novamente </span>
            <br/>
        </div>

        <Row className={'formTitle'} style={{display: 'flex', marginBottom: 35, justifyContent: 'flex-end'}}>
            <Button className={'ant-btn-green'} style={{
                display: 'flex',
                fontSize: 16,
                alignItems: 'center',
                visibility: (initialLoading || locationSelected !== null || locationsList.current.length >= 2) && 'hidden'
            }} onClick={() => handleAddLocation()}> Adicionar <FaPlus
                style={{color: 'white', fontSize: 17, marginLeft: 5}}/> </Button>
        </Row>

        {locationSelected &&
        <Row gutter={[24, 24]} style={{backgroundColor: 'white', marginBottom: 30}} className={'formRow'}>
            <Col xs={24} md={12} className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span> Escolha a cidade </label></div>
                <AutoComplete
                    disabled={props.edit}
                    size="large"
                    onSelect={(value, option) => {
                        setLocation(value)
                        setAutocompleteText(value);
                    }}
                    value={autocompleteText}
                    style={{width: '100%'}}
                    dataSource={cityList?.map(item => {
                        return (<AutoComplete.Option key={item.value} label={item.label} value={item.value}>
                                {item.label}
                            </AutoComplete.Option>
                        )
                    })}
                    onSearch={(query) => autoCompleteSearch(query)}
                >
                    <Input size={'large'} placeholder={'Selecione a cidade'}/>
                </AutoComplete>
            </Col>

            <Col xs={24} md={12} className={'formItem'}>
                <div className='resumeLabel'><label><span>*</span> Digite o bairro </label></div>
                <Input placeholder={'Ex: Monte Castelo'} maxLength={100} className='ant-input ant-input-lg' id='geocodeField0'
                       value={block} onChange={(e) => setBlock(e.target.value)}/>
            </Col>

            <Col style={{alignItems: 'flex-end'}} className={'listButtons'} span={24}>
                {!locationSelected.id &&
                <Button loading={loading} disabled={loading || (!location || !block)} className={'ant-btn-secondary'}
                        onClick={() => handleSaveNewLocation(locationSelected.id)}> Salvar </Button>}
                {locationSelected.id && <Button loading={loading} disabled={loading} className={'ant-btn-red'}
                                                onClick={() => handleDeleteLocation(locationSelected.id)}> Remover </Button>}
            </Col>
        </Row>}

        {locationsList.current?.map((item, index) =>
            <Row key={index} gutter={[24, 24]} style={{backgroundColor: 'white', display: 'flex', marginBottom: 30}}
                 className={'formRow'}>
                {locationSelected?.id !== item.id && <>
                    <Col xs={10} md={10} className={'listView'}>
                        <div><FaUniversity/></div>
                        <span> {locationsList.current[index]?.city ? locationsList.current[index]?.city :
                            <Icon type="loading"/>} </span>
                    </Col>
                    <Col xs={10} md={10} className={'listView'}>
                        <div><MdMap/></div>
                        <span> {locationsList.current[index]?.region ? locationsList.current[index]?.region :
                            <Icon type="loading"/>} </span>
                    </Col>
                    <Col xs={4} md={4} style={{
                        justifyContent: 'flex-end',
                        padding: 0,
                        fontSize: 20,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <div>
                            <FaTrashAlt className={'removeButtonIcon'} style={{marginLeft: 20}}
                                        onClick={() => handleDeleteLocation(item.id)}/>
                        </div>
                    </Col>
                </>}
            </Row>
        )}
        {(initialLoading && locationsList.current.length === 0) ? <div className={'loadingResume'}><Spin/></div> :
            <div className={'buttonRow'} justify={'center'}>
                <Button shape={'round'} onClick={() => props.handleChangeStep(3)} type={'default'}> <FaBackward
                    className={'exportedIconsLeft'}/> Voltar </Button>
                <Button shape={'round'} onClick={() => props.handleChangeStep(5)}
                        className={'ant-btn-sub-primary'}> Próximo <FaForward className={'exportedIcons'}/> </Button>
            </div>
        }

    </div>)
};
export default Form.create({name: 'locationForm'})(LocationsComponent);
