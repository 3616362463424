import {notification} from "antd";
import {Env} from "../Helpers/Env";
import React from "react";


const useNotifications = () => {

    const stateRestrictionNotification = () => {
        window.gtagService('event', 'vacancy_blocked_state_restriction');
        notification.error({
            message: <span style={{fontWeight:'bold', color: '#2c2c2c'}}>Não foi possível criar esta vaga!</span>,
            description: <span style={{fontSize:14}}>É necessário que a vaga seja localizada no Estado de <strong>{Env.state_restriction}</strong> para ser publicada no <strong className={'primary-color'}>{Env.application_title}</strong> .</span>,
            duration:0,
            placement:'topRight',
            className:'notification-state-restriction',
        })
    }
    return {stateRestrictionNotification}
}

export default useNotifications;
