import _ from "lodash";

window.scrollToElement = (elementID) => {
    try {
        window?.scroll({
            top: document.querySelector(elementID).offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    } catch (e) {
        console.log(e)
    }
};

window.gtagService = (event, name, param) => {
    try {
        if(name) {
            param ? window.gtag(event, name, param) : window.gtag(event, name)
        }
    } catch (e) {
        console.log(e);
    }
}

window.getCompanpanyPermission = (permissions) => {
    if(permissions.enterprise_permission) {
        return 'Administrador'
    } else if(permissions.feedback_permission) {
        return 'Coordenador de vagas'
    } else if(permissions.opportunity_permission) {
        return 'Recrutador'
    }
}

window.onMessageService = (cb) => {
    try {
        if(window.firebase.messaging?.isSupported()) {
            window.firebase.messaging().onMessage((payload) => {
                cb(payload)
            })
        } else {
            return null
        }
    } catch (e) {
        console.log(e);
        return null
    }
}

window.getMessagingTokenService = (cb) => {
    try {
        if(window.firebase.messaging?.isSupported()) {
            window.firebase.messaging().getToken().then((token) => {
                cb(token)
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                return null
            });
        } else {
            return null
        }
    } catch (e) {
        console.log(e);
        return null
    }
}

export const removeAccents = (text) => {

    if(text) {
        text = text.replace(/(á|à|ã|â|ä|Á|À|Ã|Â|Ä)/ig,'A');
        text = text.replace(/(é|è|ê|ë|É|È|Ê|Ë)/ig,'E');
        text = text.replace(/(í|ì|î|ï|Í|Ì|Î|Ï)/ig,'I');
        text = text.replace(/(ó|ò|õ|ô|ö|Ó|Ò|Õ|Ô|Ö)/ig,'O');
        text = text.replace(/(ú|ù|û|ü|Ú|Ù|Û|Ü)/ig,'U');
        text = text.replace(/(ñ|Ñ)/ig,'N');
        text = text.replace(/(ç|Ç)/ig,'SS');
        return text
    } else return ""
}

export const encode = (text) => {
    text = removeAccents(text);
    let codes = [];



    codes.push([/[^a-z0-9_ ]/gi,'']);
    codes.push([/(CA|CO|CU|QU)/ig, 'K']);
    codes.push([/BR/ig, 'B']);
    codes.push([/PH/ig, 'F']);
    codes.push([/(GL|GR|MG|NG|RG)/ig, 'G']);
    codes.push([/Y/ig, 'I']);
    codes.push([/(GE|GI|RJ|MJ)/ig, 'J']);
    codes.push([/(N|AO|AUM|GM|MD|OM|ON)/ig, 'M']);
    codes.push([/PR/ig, 'P']);
    codes.push([/L/ig, 'R']);
    codes.push([/(CE|CI|CH|CS|RS|TS|X|Z)/ig, 'S']);
    codes.push([/(TR|TL|CT|RT|ST|PT)/ig, 'T']);
    codes.push([/\b[UW]/, 'V']);
    codes.push([/RM/ig, 'SM']);
    codes.push([/[MRS]+\b/, '']);
    codes.push([/(A|E|I|O|U|H)/ig, '']);
    for (let i = 0; i < codes.length; i++) {
        text = text.replace(codes[i][0],codes[i][1])
        // text = text.split(codes[i][0]).join(codes[i][1])
    }
    text = squeeze(text);

    return text;
}

const squeeze = (text) => {
    let last    = '';
    let newText = '';
    for(let i =  0 ; i < text.length; i++)
    {
        last = text.charAt(i+1);
        if(text.charAt(i) !== last)
        {
            newText += text.charAt(i);
        }
    }
    return newText;
    // text = text || '';
    // return text.replace(/(.)(?=\1)/g, '');
}

export const encodeWorkerCategories = (res) => {
    res.forEach((item) => {
        item.occupations.forEach((subItem) => {
            subItem.name_phonetic = encode(subItem.name);
            subItem.tags = subItem.tags.map((subSubItem) => {
                return {
                    name: subSubItem, name_phonetic: encode(subSubItem)
                }
            })
        })
    });
    return res;
}

export const filterWorkerSearch = (auxText, list) => {
    let aux = []
    let text = auxText.toLowerCase();
    let encoded_text = encode(text).toLowerCase();
    let priorityList = [];
    if(text.length > 3) {
        list.forEach((item) => {
            item.occupations.forEach((subItem) => {
                if(removeAccents(subItem.name.toLowerCase()).match(text)) {
                    priorityList.push(subItem);
                } else if(removeAccents(subItem.name_phonetic.toLowerCase()).match(encoded_text)) {
                    aux.push(subItem);
                } else {
                    for(let i = 0; i < subItem.tags.length; i++) {
                        if(removeAccents(subItem.tags[i].name.toLowerCase()).match(text) || removeAccents(subItem.tags[i].name_phonetic.toLowerCase()).match(encoded_text)) {
                            aux.push(subItem);
                            break;
                        }
                    }
                }
            })
        });
        aux = priorityList.concat(aux);
        return aux;
    } else {
        return [];
    }
}

export const formatDateNativeDDMM = (date) => {
    return date.slice(8, 10) + '/' + date.slice(5,7);
};

export const formatDateNativeHHMM = (date) => {
    return  date.substring(11, 16)
};

export const formatDateNativeDDMMYYYY = (date) => {
    return date.slice(8, 10) + '/' + date.slice(5,7) + '/' + date.slice(0,4)
};

export const formatDateNativeDDMMYYYYHHMM = (date) => {
    return date.slice(8, 10) + '/' + date.slice(5,7) + '/' + date.slice(0,4) + ' ' +  date.substring(11, 16)
};

export const maskPhone = (item) => {
    if(item) {
        if(item.substring(0,3) === '+55') {
            item = item.substring(3);
        } else if(item.substring(0,2) === '55') {
            item = item.substring(2);
        }
        var x = item.replace(/\D/g, '').match(/(\d{2})(\d{5})(\d{4})/);
        if(x && x[1] && x[2] && x[3]) {
            item = '(' + x[1] + ') ' + x[2] + '-' + x[3];
        }
        return item;
    } return null;
};


window.getNumbers = (v) => {
    if (!v || !v.trim()) {
        return undefined;
    }
    let numb = v.match(/\d/g);
    if(numb) {
        return numb.join("");
    } else return ''

};

window.random = (min, max) => {
    return Math.ceil(Math.random() * (max - min + 1)) + min;
};

window.firstAndLastName = (name, onlyFirst) => {
    if(typeof name === "string") {
        const nameArr = name.split(' ');
        return (nameArr.length === 1 || !!onlyFirst) ? nameArr[0] : nameArr[0] + " " + nameArr[nameArr.length-1];
    } else {
        return "";
    }
}

window.getPercent = (current, total) => {
    const percent = ((parseInt(current) * 100) / parseInt(total));
    return isNaN(percent) ? 0 : (percent < 1 ? 1 : percent.toFixed(0)) ;
}

window.oneOf = (value, posibilites: Array) => {
    return posibilites.indexOf(value) > -1;
    //["estadual", "municipal"].indexOf(fields.esfera.value) === -1
}

/**
 * Corta a imagem passada e retorna o base64
 * @param image
 * @param cropObj
 * @param width
 * @param height
 * @returns {string}
 */
window.cropImageAndGetBase64 = (image: string, cropObj: {x: number, y: number, width: number, height: number}, width: number, height: number) => {

    image.setAttribute('crossOrigin', 'anonymous');

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
        image,
        cropObj.x * scaleX,
        cropObj.y * scaleY,
        cropObj.width * scaleX,
        cropObj.height * scaleY,
        0,
        0,
        width,
        height,
    );

    // As Base64 string
    return canvas.toDataURL('image/jpeg', 1);
}

window.getCropSizeBasedOnImage = (image, aspectRatio) => {
    const imageAspectRation = image.width/image.height;

    let width, height;

    if(imageAspectRation > aspectRatio) {
        width = image.height * aspectRatio;
        height = image.height;
    } else if(imageAspectRation < aspectRatio) {
        width = image.width;
        height = image.width/aspectRatio;
    } else {
        width = image.width;
        height = image.height;
    }

    return { width: width, height: height};
}

export const maskLogin = (value) => {
    console.log(value.length)
    console.log(isNaN(value))
    if(!value) return "";
    if(value.length > 5 && !isNaN(value)){
        console.log('cpf')
        maskCpf(value)
    }else{
        return value
    }
}

export const maskCpf = (value) => {
    if(!value) return "";
    let a = value.replace(/[^0-9]/g, '');
    switch (a.length) {
        case 0:
            a = '';
            break;
        case 1:
            a = a.charAt(0);
            break;
        case 2:
            a = a.charAt(0) + a.charAt(1);
            break;
        case 3:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2);
            break;
        case 4:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3);
            break;
        case 5:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4);
            break;
        case 6:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5);
            break;
        case 7:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6);
            break;
        case 8:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7);
            break;
        case 9:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7) + a.charAt(8);
            break;
        case 10:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7) + a.charAt(8) + '-' + a.charAt(9);
            break;
        case 11:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7) + a.charAt(8) + '-' + a.charAt(9) + a.charAt(10);
            break;
        default:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7) + a.charAt(8) + '-' + a.charAt(9) + a.charAt(10);

            break;
    }

    return a;
}

export const maskCnpj = (value) => {
    if(!value) return "";
    let a = value.replace(/[^0-9]/g, '');
    switch (a.length) {
        case 0:
            a = '';
            break;
        case 1:
            a = a.charAt(0);
            break;
        case 2:
            a = a.charAt(0) + a.charAt(1);
            break;
        case 3:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2);
            break;
        case 4:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3);
            break;
        case 5:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4);
            break;
        case 6:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5);
            break;
        case 7:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5) + a.charAt(6);
            break;
        case 8:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5) + a.charAt(6) + a.charAt(7);
            break;
        case 9:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5) + a.charAt(6) + a.charAt(7) + '/' + a.charAt(8);
            break;
        case 10:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5) + a.charAt(6) + a.charAt(7) + '/' + a.charAt(8) + a.charAt(9);
            break;
        case 11:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5) + a.charAt(6) + a.charAt(7) + '/' + a.charAt(8) + a.charAt(9) + a.charAt(10);
            break;
        case 12:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5) + a.charAt(6) + a.charAt(7) + '/' + a.charAt(8) + a.charAt(9) + a.charAt(10) + a.charAt(11);
            break;
        case 13:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5) + a.charAt(6) + a.charAt(7) + '/' + a.charAt(8) + a.charAt(9) + a.charAt(10) + a.charAt(11) + '-' + a.charAt(12);
            break;
        case 14:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5) + a.charAt(6) + a.charAt(7) + '/' + a.charAt(8) + a.charAt(9) + a.charAt(10) + a.charAt(11) + '-' + a.charAt(12) + a.charAt(13);
            break;
        default:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + '.' + a.charAt(5) + a.charAt(6) + a.charAt(7) + '/' + a.charAt(8) + a.charAt(9) + a.charAt(10) + a.charAt(11) + '-' + a.charAt(12) + a.charAt(13);
            break;
    }
    return a;
}

export const maskRealNu = (value) => {
    if(value) {
        let a = value?.toString();
        a = value.replace(/[^0-9]/g, '');
        switch (a.length) {
            case 0:
                a = '';
                break;
            case 1:
                a = '0,0' + a.charAt(0);
                break;
            case 2:
                a = '0' + ',' + a.charAt(0) + a.charAt(1);
                break;
            case 3:
                a = a.charAt(0) + ',' + a.charAt(1) + a.charAt(2);
                break;
            case 4:
                if(a.charAt(0) === '0' && a.charAt(1) === '0') {
                    a = a.charAt(0) + ',' + a.charAt(2) + a.charAt(3);
                } else if(a.charAt(0) === '0') {
                    a = a.charAt(1) + ',' + a.charAt(2) + a.charAt(3);
                } else {
                    a = a.charAt(0) + a.charAt(1) + ',' + a.charAt(2) + a.charAt(3);
                }
                break;
            case 5:
                a = a.charAt(0) + a.charAt(1) + a.charAt(2) + ',' + a.charAt(3) + a.charAt(4);
                break;
            case 6:
                a = a.charAt(0) + '.' + a.charAt(1) + a.charAt(2) + a.charAt(3) + ',' + a.charAt(4) + a.charAt(5);
                break;
            case 7:
                a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + ',' + a.charAt(5) + a.charAt(6);
                break;
            default:
                a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + ',' + a.charAt(5) + a.charAt(6);
                break;
        }
        return a;
    } else return value

}

export const globalMaskReal = (a) => {

    if(a) {
        let aux = a;
        if(typeof a === 'string') {
            aux = a.replace(',','.');
        }
        if(isNaN(aux)) {
            aux = aux.replace(/\./g, '');
        }
        aux = Number(aux).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        return aux.toString();
    } else {
        return '---';
    }
}
export const maskReal = (value) => {
    let aux = value;
    if(value !== '') {
        if(value[value.length - 3] === ',') {
            aux = value.replace(',0', '');
            aux = aux.slice(0, -1);
        } else if(value[value.length - 3] === '.'){
            aux = value.replace('.0', '');
            aux = aux.slice(0, -1);
        } else {
            aux = value.replace(',00', '');
        }
    }
    let a = aux.replace(/[^0-9]/g, '');

    switch (a.length) {
        case 0:
            a = '';
            break;
        case 1:
            a = a.charAt(0) + ',00';
            break;
        case 2:
            a = a.charAt(0) + a.charAt(1) + ',00';
            break;
        case 3:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + ',00';
            break;
        case 4:
            a = a.charAt(0) + '.' + a.charAt(1) + a.charAt(2) + a.charAt(3) + ',00';
            break;
        case 5:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + ',00';
            break;
        case 6:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + ',00';
            break;
        default:
            a =  a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + ',00';
            break;
    }
    return a;
};

window.validateMobilePhone = (item) => {

    let auxPhone = item.replace(/\D/ig, '');

    if(auxPhone.length === 11) {
        if (['68', '82', '96', '92', '97', '71', '72', '73', '74', '75', '77', '85', '88', '61', '27', '28', '62', '64', '98', '99', '65', '66', '67', '31', '32', '33', '34', '35', '37', '38', '91', '93', '94', '83', '41', '42', '43', '44', '45', '46', '81', '87', '86', '89', '21', '22', '24', '84', '51', '53', '54', '55', '69', '95', '47', '48', '49', '11', '12', '13', '14', '15', '16', '17', '18', '19', '79', '63'].includes(auxPhone.substr(0, 2))) {
            if (['99', '98', '97', '96', '95', '94', '93'].includes(auxPhone.substr(2,2))) {
                return true;
            }
        }
    }
    return false;
};


window.categorySlugAndTextSeparator = (slug, text) => {
    const slugData = slug ? slug.toString().split("|") : "";
    const categoryDescriptionData = text ? text.toString().split(" > ") : "";

    return [slugData, categoryDescriptionData];
}

window.displayTextBasedOnLength = (length, textWithZeroLength, textWithOneLength, textMoreLength) => {
    const _ = require('lodash');
    const compiled_textWithZeroLength = _.template(textWithZeroLength);
    const compiled_textWithOneLength = _.template(textWithOneLength);
    const compiled_textMoreLength = _.template(textMoreLength);
    return length > 0 ? (length === 1 ? compiled_textWithOneLength({length: length}) : compiled_textMoreLength({length: length}) ) : compiled_textWithZeroLength({length: length});
}


window.redirectWorker = (item) => {
    window.gtagService('event', 'redirectWorker');
    window.open(item)
};

window.maskViewPhone = (item) => {
    if(item) {
        if(item.substring(0,3) === '+55') {
            item = item.substring(3);
        } else if(item.substring(0,2) === '55') {
            item = item.substring(2);
        }
        var x = item.replace(/\D/g, '').match(/(\d{2})(\d{5})(\d{4})/);
        if(x && x[1] && x[2] && x[3]) {
            item = '(' + x[1] + ') ' + x[2] + '-' + x[3];
        } else {
            var y = item.replace(/\D/g, '').match(/(\d{2})(\d{4})(\d{4})/);
            if(y && y[1] && y[2] && y[3]) {
                item = '(' + y[1] + ') ' + y[2] + '-' + y[3];
            }
        }
        return item;
    } return null;
};

window.getPhoneNumbersWithoutCodeCountry = (item) => {
    let aux = window.getNumbers(item.toString());
    let auxFinal = '';
    if(aux.substring(0,3) === '+55') {
        auxFinal = aux.slice(3, aux.length);
        return auxFinal;
    } else if(aux.substring(0,2) === '55') {
        auxFinal = aux.slice(2, aux.length);
        return auxFinal;
    } else return aux;
}

window.objectToQueryString = (filters, without) => {
    let query = "?";
    Object.keys(filters).forEach((item) => {
        if(_.isArray(filters[item])) {
            if(filters[item].length > 0) {
                filters[item].forEach((subItem) => {
                    query = query + item + `${without ? '=' : '[]='}` + subItem + '&';
                })
            }
        } else {
            if(filters[item] !== undefined && filters[item] !== null && filters[item] !== '') query = query + item + '=' + filters[item] + '&';
        }
    });

    if (query.slice(-1) === '&'){
        query = query.substring(0, query.length -1)
    }

    return query;
}

window.queryStringToObject = () => {
    var pairs = window.location.search.slice(1).split('&');

    var result = {};
    pairs.forEach(function(pair) {
        pair = pair.split('=');
        if (pair[0].endsWith("[]")) {
            let key = pair[0].slice(0, -2)
            if (result[key]?.length > 0) {
                result[key].push(decodeURIComponent(pair[1] || ''))
            } else {
                result[key] = [decodeURIComponent(pair[1] || '')]
            }
        } else {
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        }
    });

    const realResult = JSON.stringify(result);

    return realResult === "{\"\":\"\"}" ? {} : JSON.parse(realResult);

}

window.getDistance = (distance) => {
    let distanceString = distance?.toString();

    if (distance < 100) {
        return `Próximo de você`
    } else if(distance < 1000) {
        return `A ${distanceString[0]}00 metros de você`
    } else if (distance < 10000) {
        return `A ${distanceString[0]} km de você`
    } else if (distance <= 100000) {
        return `A ${distanceString[0]}0 km de você`
    }
}


window.firstAndLastName = (name) => {
    if(typeof name === "string") {
        const nameArr = name.split(' ');
        if (nameArr.length === 1) {
            return nameArr[0];
        } else {
            return nameArr[0] + " " + nameArr[nameArr.length-1];
        }
    } else {
        return "";
    }
}


export function sha256(plain) {
    // returns promise ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
}
export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


export function base64urlencode(a) {
    var str = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
}

export async function generateCodeChallengeFromVerifier(v) {
    var hashed = await sha256(v);
    var base64encoded = base64urlencode(hashed);
    return base64encoded;
}

export function dec2hex(dec) {
    return ("0" + dec.toString(16)).substr(-2);
}

export function generateCodeVerifier() {
    var array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join("");
}
