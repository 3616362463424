import React, {useEffect, useRef, useState} from "react";
import {Avatar, Button, Col, Collapse, Icon, message, Popconfirm, Row} from "antd";
import useApi from "../Hooks/Api";
import moment from "moment";
import Spin from "antd/lib/spin";
import {maskPhone} from "../Helpers/Functions";
import {Images} from "../Helpers/Images";
import {FaFacebookSquare, FaInstagram, FaLinkedin, FaMedal, FaUser, FaUserGraduate} from "react-icons/fa";
import {BsFillBriefcaseFill} from "react-icons/bs";
import {MdClass, MdPrint} from "react-icons/md";
import {RiFileUserFill, RiToolsFill} from "react-icons/ri";

const pStyle = {
    fontSize: 16,
    color: 'rgba(0,0,0,0.85)',
    lineHeight: '24px',
    display: 'block',
    marginBottom: 16,
};

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}


const DescriptionItem = ({ title, content }) => (
    <div className="profile-description-item">
        <p style={{marginBottom: 3, fontWeight: 'bold'}}>
            {title}:
        </p>
        {content}
    </div>
);

const FullResumePage = (props) => {

    const [profile, setProfile] = useState();
    const api = useApi();
    const typeResume = useRef(null)
    const [takePictureType, setTakePictureType] = useState(undefined)
    const [typePrintButton, setTypePrintButton] = useState()
    const [initialLoading, setInitialLoading] = useState(true);
    const [isFirstJob, setIsFirstJob] = useState(false);

    const getFullData = async () => {
        api.get(`sine/contact/${props.match.params.id}`, (res) => {
            if(res.occupation) {
                res.occupations_interest.push(res.occupation.name);
                res.occupations_details.push(res.occupation);
            }

            if(res.extra.work_experience.length > 0) {
                for(let i = 0; i < res.extra.work_experience.length; i ++) {
                    if(res.extra.work_experience[i].start_date !== res.extra.work_experience[i].end_date) {
                        break;
                    }
                    if(i + 1 === res.extra.work_experience.length) {
                        setIsFirstJob(true);
                    }
                }
            } else {
                for(let i = 0; i < res.occupations_details.length; i ++) {
                    if(res.occupations_details.formal_experience !== "Nenhuma" || res.occupations_details.informal_experience !== "Nenhuma") {
                        break;
                    }
                    if(i + 1 === res.occupations_details.length) {
                        setIsFirstJob(true);
                    }
                }
            }

            setProfile(res);
            typeResume.current = res.profile_picture
            typeResume.current = Array.isArray(typeResume.current)

            if (typeResume.current) {
                setTypePrintButton("withPicture")
            } else {
                setTypePrintButton("withOUT")
            }
            setInitialLoading(false);
        },(e) => {
            setProfile({});
            message.warning('Ops, não foi possível recuperar os dados do currículo, tente novamente mais tarde')
        });
    };

    const convertDate = (end, start) => {
        let years;
        let months;
        if(end) {
            years = moment(end,'YYYY-MM').diff(start, 'years');
            months = moment(end,'YYYY-MM').diff(start, 'months');
        } else {
            years = moment().diff(start, 'years');
            months = moment().diff(start, 'months');
        }

        if(years < 1) {
            return <span> {months % 12 + ' meses'} </span>
        } else if (months % 12 > 0){
            return <span> {years + ' ano(s) e ' + months % 12 + ' mês(es)'} </span>
        } else {
            return <span> {years + ' ano(s)'} </span>
        }

    };

    const printResume = (bool) => {
        if (bool) {
            typeResume.current = true;
            setTakePictureType('withProfilePicture')

        } else {
            typeResume.current = false;
            setTakePictureType('withoutProfilePicture')

        }

    }

    useEffect(() => {
        if (typeResume.current === null) {
            getFullData();
        }
        if(takePictureType !== undefined) {
            window.print();
            setTakePictureType(undefined)
        }
    }, [takePictureType]);

    return (
        <Col xs={24} sm={24} md={24} lg={24} style={{display: "flex", justifyContent: "center", paddingTop: 20}}>
            {initialLoading && <Spin style={{marginTop: 50}}/>}
            {!initialLoading &&
                <Row style={{ maxWidth: '1000px', display: 'flex', minHeight: 800, justifyContent: 'center'}}>
                    <Row className={'buttonToHide'} style={{position: 'fixed', zIndex: 100,  bottom: 30, left: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                        {typePrintButton === 'withPicture' ?
                            <Popconfirm title={'Deseja imprimir o currículo: '}
                                        okText='Com foto'
                                        icon={<Icon type="question-circle" />}
                                        onConfirm={() => printResume(true)}
                                        onCancel={() => printResume(false)}
                                        cancelText='Sem foto'
                                        placement='topRight'>
                                <Button type={'primary'} >
                                  <span style={{display:"flex", alignItems:"center", gap:8}}>
                                    <MdPrint/> Imprimir/Salvar currículo
                                  </span>
                                </Button>
                            </Popconfirm> :
                            <Button onClick={()=> window.print()} type={'primary'} >
                              <span style={{display:"flex", alignItems:"center", gap:8}}>
                                <MdPrint/> Imprimir/Salvar currículo
                              </span>
                            </Button>
                        }
                        {/*<Button style={{boxShadow: '0 2px 5px 1px rgb(0 0 0 / 28%)', marginLeft: 10, borderColor: '#d93b26', backgroundColor: '#d93b26'}} type={'primary'} onClick={() => setPdfTut(true)}>Salvar em PDF</Button>*/}
                    </Row>
                    <Col span={18} style={{display:"flex", flexDirection:"column"}}>
                        <Col span={24} style={{gap:10}} className='d-flex flex-column-reverse align-items-center'>
                            <Col xs={24} style={{display:"flex",flexDirection: "column", gap:8}}>
                                <Col span={24} style={{fontSize:32,borderBottom: '1px solid gainsboro', textAlign:"center"}}>
                                    <div>
                                        <span>{profile.name}</span>
                                    </div>
                                    <div style={{fontSize:14, marginBottom:10}}>
                                        <span>{profile?.address}</span>
                                    </div>
                                </Col>

                                <Col span={24} style={{display:"flex", alignItems:"center", flexWrap:"wrap",justifyContent:"space-evenly", gap:16}}>
                                    <div className='font-size-16'>
                                        <Icon type="star" theme="filled" /> {profile.age} Anos
                                    </div>
                                    <div className='font-size-16'>
                                        <FaUserGraduate/> {profile?.schooling}
                                    </div>
                                    {profile?.emails.map((item) => {
                                        return (
                                            <div className='font-size-16'>
                                                <span>
                                                    <Icon type="mail" theme="filled"/> {item}
                                                </span>
                                            </div>
                                        )
                                    })}

                                    {profile.phone_numbers.length > 0 &&
                                        <div className='font-size-16'>
                                            <span>
                                                {profile.phone_numbers.map((item) => (
                                                    <div>
                                                        <Icon type="phone" theme="filled" />{maskPhone(item)}
                                                    </div>
                                                ))}
                                            </span>
                                        </div>

                                    }
                                </Col>
                                {(profile?.instagram_link || profile?.facebook_link || profile?.linkedin_link) &&
                                    <Col style={{display:"flex", gap:16, flexWrap: "wrap", justifyContent:'center'}}>
                                        {profile?.instagram_link &&
                                            <div>
                                                <a style={{display: "flex", alignItems:"center", gap:6}} target={`_blank`} href={`https://www.instagram.com/${profile.instagram_link}`}>
                                                    <FaInstagram size={20}/> @{profile.instagram_link}
                                                </a>
                                            </div>
                                        }
                                        {profile?.facebook_link &&
                                            <div>
                                                <a style={{display: "flex", alignItems:"center", gap:6}} target={`_blank`} href={`https://www.facebook.com/${profile.facebook_link}`}>
                                                    <FaFacebookSquare size={20} /> {profile.facebook_link}
                                                </a>
                                            </div>
                                        }
                                        {profile?.linkedin_link &&
                                            <div>
                                                <a style={{display: "flex", alignItems:"center", gap:6}} target={`_blank`} href={`https://www.linkedin.com/in/${profile.linkedin_link}`}>
                                                    <FaLinkedin size={20}/> {profile.linkedin_link}
                                                </a>
                                            </div>
                                        }
                                    </Col>
                                }

                            </Col>
                            {typeResume.current &&
                                <Col xs={12} className={ 'align-items-center'} style={{display: 'flex', justifyContent: 'center'}}>
                                    <Avatar style={{width:'8rem', height: '8rem'}} src={profile.profile_picture[0]}/>
                                </Col>
                            }
                        </Col>
                        {profile?.about_me &&
                            <div>
                                <Row type={'flex'} style={{marginTop: 16, marginBottom: 20, flexFlow: 'row'}}>
                                    <div>
                                    <span style={{color: "#0f75bc", fontSize: 18, whiteSpace: 'nowrap'}}>
                                      <FaUser/> Sobre mim
                                    </span>
                                    </div>
                                    <div style={{width: '100%', marginBottom: 6, marginLeft: 5, borderBottom: '1px solid #0f75bc'}} />
                                </Row>
                                <Row>
                                    <Col className='font-size-16' style={{textAlign:'justify'}} span={24}>
                                        <div>
                                            <p>{`${profile.about_me}.`}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }

                        {profile?.extra?.work_experience?.length > 0&& <> <Row type={'flex'} style={{marginTop: 30, marginBottom: 20, flexFlow: 'row'}}>
                            <div>
                                <span style={{color: "#0f75bc", fontSize: 18, whiteSpace: 'nowrap'}}>
                                   <BsFillBriefcaseFill/> Experiências
                                </span>
                            </div>
                            <div style={{width: '100%', marginBottom: 6, marginLeft: 5, borderBottom: '1px solid #0f75bc'}} />
                        </Row>
                        <Row gutter={[12,12]} style={{flexWrap: "wrap", display: "flex"}}>
                            {profile?.extra?.work_experience?.length > 0? profile?.extra.work_experience?.map((item, index) => {
                                if ((item.start_date !== item.end_date)) {
                                    return <Col span={12}>
                                        <Row key={index} style={{marginBottom: 10, paddingBottom: 10}}>
                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Cargo: </strong>
                                                    <strong style={{color: '#4caf50'}}>{item.occupation} </strong>
                                                </label>
                                            </Col>

                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Empresa: </strong>
                                                    {item.company}
                                                </label>
                                            </Col>

                                            {item.last_wage &&
                                                <Col xs={24}>
                                                    <label>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Último salário: </strong>
                                                        {item.last_wage ?
                                                        <span>R$ {item.last_wage}</span> : 'Não informado'}
                                                    </label>
                                                </Col>
                                            }

                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Tipo de trabalho: </strong>
                                                    {item.work_type}
                                                </label>
                                            </Col>

                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Local: </strong>
                                                    {item.location}
                                                </label>
                                            </Col>

                                            {item.description&&<Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Descrição do serviço: </strong>
                                                    <p>
                                                        {item.description ? item.description : 'Não informado'}
                                                    </p>
                                                </label>
                                            </Col>}

                                            {(item.work_type !== 'Freelance' && item.work_type !== 'Autônomo') ?
                                                <Col xs={24} md={24}>
                                                    <span>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Formal: </strong>
                                                        <span>{convertDate(item.end_date, item.start_date)}</span>
                                                        <span>( Inicio - {moment(item.start_date, 'YYYY-MM').format('MM/YYYY')}) </span>
                                                        <span>{item.end_date ? <span>( Fim - {moment(item.end_date, 'YYYY-MM').format('MM/YYYY')})</span> : <span style={{color: "#1b429c", fontWeight: 600}}>Trabalhando atualmente</span>}</span>
                                                    </span>
                                                    </Col> :
                                                    <Col xs={24} md={24}>
                                                        <span>
                                                            <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Informal: </strong>
                                                            <span>{convertDate(item.end_date, item.start_date)}</span>
                                                            <span>( Inicio - {moment(item.start_date, 'YYYY-MM').format('MM/YYYY')}) </span>
                                                        <span>{item.end_date ? <span>( Fim - {moment(item.end_date, 'YYYY-MM').format('MM/YYYY')})</span> : <span style={{color: "#1b429c", fontWeight: 600}}>Trabalhando atualmente</span>}</span>
                                                        </span>
                                                    </Col>
                                            }
                                        </Row>
                                        </Col>
                                    }
                                })
                                : (profile?.occupations_details?.length > 0 ? profile.occupations_details.map((item, index) =>
                                    {if(item.formal_experience !== 'Nenhuma' || item.informal_experience !== 'Nenhuma') {
                                        return<Col span={12} key={index}>
                                            <Row  style={{marginBottom: 10, paddingBottom: 10}}>
                                                <Col xs={24}>
                                                    <label>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Cargo: </strong>
                                                        <strong style={{color: '#4caf50'}}>{item.name} </strong>
                                                    </label>
                                                </Col>

                                                <Col xs={24}>
                                                    <label>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Era estágio? </strong>
                                                        {item.internship? 'Sim' : 'Não'}
                                                    </label>
                                                </Col>

                                                <Col xs={24} md={24}>
                                                    <span>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Formal: </strong>
                                                        <span>{item.formal_experience}</span>
                                                    </span>
                                                </Col>

                                                <Col xs={24} md={24}>
                                                    <span>
                                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Informal: </strong>
                                                        <span>{item.informal_experience}</span>
                                                    </span>
                                                </Col>
                                            </Row>
                                            </Col>}})
                                        :
                                        <span> Não possui </span>
                                )
                            }
                            {isFirstJob&&
                                <Row type={'flex'}>
                                    <Col span={8}>
                                        <img src={Images.search_resumes} style={{maxWidth: '100%'}}/>
                                    </Col>
                                    <Col span={16} style={{alignSelf: 'center'}}>
                                        <h1 style={{fontSize: 23, textAlign: 'center'}}> Profissional em busca de sua primeira colocação </h1>
                                    </Col>
                                </Row>
                            }
                        </Row>
                        </>}

                        <Row type={'flex'} style={{marginTop: 30, marginBottom: 20, flexFlow: 'row'}}>
                            <div>
                                <span style={{color: "#0f75bc", fontSize: 18, whiteSpace: 'nowrap'}}>
                                   <MdClass/> Pretensões
                                </span>
                            </div>
                            <div style={{width: '100%', marginBottom: 6, marginLeft: 5, borderBottom: '1px solid #0f75bc'}} />
                        </Row>
                        <Row gutter={[12,12]}>
                            {profile?.extra?.work_experience?.length > 0? profile?.extra?.work_experience?.map((item,index) =>
                                <Col xs={12} key={index}>
                                    <label>
                                        <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> {item.occupation} </strong>
                                    </label>
                                </Col>
                                )
                                : (profile?.occupations_interest?.length > 0 ? profile.occupations_interest.map((item, index) =>
                                    <Col xs={12} key={index}>
                                        <label>
                                            <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> {item} </strong>
                                        </label>
                                    </Col>)
                                        :
                                    <span> Não possui </span>
                                )}
                        </Row>

                        {(profile?.extra.academic?.length > 0 || profile?.grads?.length > 0) &&
                            <Row type={'flex'} style={{marginTop: 30, marginBottom: 20, flexFlow: 'row'}}>
                                <div>
                                    <span style={{color: "#0f75bc", fontSize: 18, whiteSpace: 'nowrap'}}>
                                       <FaUserGraduate/> Graduações
                                    </span>
                                </div>
                            <div style={{width: '100%', marginBottom: 6, marginLeft: 5, borderBottom: '1px solid #0f75bc'}} />
                            </Row>
                        }

                        <Row gutter={[12,12]}>
                            {profile?.extra?.academic?.length > 0? profile?.extra.academic?.map((item, index) =>
                                <Col span={12}>
                                    <Row key={index} style={{marginBottom: 10, paddingBottom: 10}}>

                                        <Col xs={24}>
                                            <label>
                                                <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>
                                                <strong style={{color: '#4caf50'}}> {item.course} </strong>
                                            </label>
                                        </Col>

                                        <Col xs={24}>
                                            <label>
                                                <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Instituição: </strong>
                                                {item.university}
                                            </label>
                                        </Col>

                                        <Col xs={24}>
                                            <label>
                                                <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Nível: </strong>
                                                {item.academic_degree}
                                            </label>
                                        </Col>

                                        <Col xs={24}>
                                            <span>
                                                <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Inicio: </strong>
                                                {item.start_date ? moment(item.start_date, 'YYYY-MM').format('MM/YYYY') : 'Não informado'}
                                            </span>
                                        </Col>

                                        {item.end_date &&<Col xs={24}>
                                            <span>
                                                <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Fim: </strong>
                                                {item.end_date ? moment(item.end_date, 'YYYY-MM').format('MM/YYYY') : 'Não informado'}
                                            </span>
                                        </Col>}
                                    </Row>
                                </Col>)
                                : (profile?.grads?.length > 0 && profile.grads.map((item, index) =>
                                    <Col xs={24} key={index}>
                                        <label style={{fontWeight: 'bold', color: '#0072d6'}}>
                                            <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>
                                            {item}
                                        </label>
                                    </Col>)
                                )
                            }
                        </Row>

                        {(profile?.extra.courses?.length > 0 || profile?.courses?.length > 0) &&
                            <Row type={'flex'} style={{marginTop: 30, marginBottom: 20, flexFlow: 'row'}}>
                                <div>
                                    <span style={{color: "#0f75bc", fontSize: 18, whiteSpace: 'nowrap'}}>
                                        <RiToolsFill/>Cursos
                                    </span>
                                </div>
                                <div style={{width: '100%', marginBottom: 6, marginLeft: 5, borderBottom: '1px solid #0f75bc'}} />
                            </Row>
                        }

                        <Row  gutter={[12,12]}>
                            {profile?.extra.courses?.length > 0? profile?.extra.courses?.map((item, index) =>
                                <Col span={12}>
                                    <Row key={index} style={{marginBottom: 10, paddingBottom: 10}}>
                                        <Col xs={24}>
                                            <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong> <strong style={{color: '#0072d6'}}> {item.name} </strong></label>
                                        </Col>

                                        <Col xs={24}>
                                            <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Instituição: </strong>{item.institution}</label>
                                        </Col>

                                        {item.duration&&
                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Duração: </strong>
                                                    {item.duration ? <span> {item.duration} meses </span> : 'Não informado'}
                                                </label>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                )
                                : (profile?.courses?.length > 0 && profile.courses.map((item, index) =>
                                    <Col xs={24} key={index}>
                                        <label style={{fontWeight: 'bold', color: '#0072d6'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item}</label>
                                    </Col>
                                    )
                                )
                            }
                        </Row>

                        {profile?.extra?.knowledge?.length > 0 &&
                            <>
                                <Row type={'flex'} style={{marginTop: 30, marginBottom: 20, flexFlow: 'row'}}>
                                    <div>
                                      <span style={{color: "#0f75bc", fontSize: 18, whiteSpace: 'nowrap'}}>
                                        <FaMedal/> Habilidades
                                      </span>
                                    </div>
                                    <div style={{width: '100%', marginBottom: 6, marginLeft: 5, borderBottom: '1px solid #0f75bc'}} />
                                </Row>

                                <Row style={{marginBottom:-10}}>
                                    {profile?.extra.knowledge.map((item, index) => (
                                        <Col xs={12} md={8} style={{paddingBottom:10}}>
                                            <strong style={{fontWeight: 'bold', fontSize: 14, color: 'rgba(0,0,0,0.85)'}}>{item.title}</strong> - {item.level === '1' ? 'Básico' : item.level === '2' ? 'Intermediário' : 'Avançado' }
                                        </Col>
                                    ))}
                                </Row>
                            </>
                        }

                        <Row type={'flex'} style={{marginTop: 30, marginBottom: 20, flexFlow: 'row'}}>
                            <div>
                                <span style={{color: "#0f75bc", fontSize: 18, whiteSpace: 'nowrap'}}>
                                   <RiFileUserFill/> Outras Informações
                                </span>
                            </div>
                            <div style={{width: '100%', marginBottom: 6, marginLeft: 5, borderBottom: '1px solid #0f75bc'}} />
                        </Row>

                        <Row style={{display:"flex", flexWrap:"wrap"}}>
                            <Col span={8} className='min-height'>
                                <DescriptionItem title="Estudante" content={profile.is_student ? "Sim" : "Não"}/>
                            </Col>
                            <Col span={8} className='min-height'>
                                <DescriptionItem title="Habilitação" content={profile.driver_license || 'Não possuo'}/>
                            </Col>

                            {profile.gender &&<Col span={8} className='min-height'>
                                <DescriptionItem title="Gênero" content={genders[profile.gender]}/>
                            </Col>}
                            <Col  span={8} className='min-height' >
                                <div className="profile-description-item">
                                    <p style={{fontWeight: 'bold', marginBottom: 2}}>
                                        Disponibilidade para:
                                    </p>
                                    <ul style={{lineHeight: '22px', paddingLeft: 17}}>
                                        <li>Realizar viagens? {profile.available_travel ? "Sim" : "Não"}</li>
                                        <li>Dormir no local de trabalho? {profile.available_sleep_duty ? "Sim" : "Não"}</li>
                                        <li>Ausentar-se por longo período? {profile.available_leave_home ? "Sim" : "Não"} </li>
                                    </ul>
                                </div>
                            </Col>

                            <Col span={8} className='min-height'>

                                <div className="profile-description-item">
                                    <p style={{fontWeight: 'bold', marginBottom: 2}}>
                                        Outras Idiomas:
                                    </p>
                                    {profile.languages?.length !== 0 ?
                                        <ul style={{lineHeight: '22px', paddingLeft: 17}}>
                                            {profile.languages?.map((el, index) => <li key={index}>{el}</li>)}
                                        </ul>
                                        :
                                        'Nenhum'
                                    }
                                </div>

                            </Col>

                            <Col span={8} className='min-height'>

                                <div className="profile-description-item">
                                    <p style={{fontWeight: 'bold', marginBottom: 2}}>
                                        Veículos:
                                    </p>
                                    {profile.vehicles?.length !== 0 ?
                                        <ul style={{lineHeight: '22px', paddingLeft: 17}}>
                                            {profile.vehicles?.map((el, index) => <li key={index}>{el}</li>)}
                                        </ul> :
                                        'Nenhum'
                                    }
                                </div>
                            </Col>

                            <Col span={8} className='min-height'>

                                <div className="profile-description-item">
                                    <p style={{fontWeight: 'bold', marginBottom: 2}}>
                                        PcD:
                                    </p>
                                    {profile.disabilities?.length !== 0 ?
                                        <ul style={{lineHeight: '22px', paddingLeft: 17}}>
                                            {profile.disabilities?.map((el, index) => <li key={index}>{el}</li>)}
                                        </ul> :
                                        'Nenhuma'
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>}


            {/*
        <Modal visible={pdfTut} onCancel={() => setPdfTut(false)} footer={null} className={'registerModalWidth'}
               destroyOnClose={true}
               style={{maxWidth: '800px'}}>
            <Row>
                <Col xs={24} md={16}>
                    <img src={'assets/pdf.png'}/>
                </Col>
                <Col xs={24} md={8}>
                    <span> Clique no botão 'Imprimir/Salvar Cúrriculo'</span>
                </Col>
            </Row>

            <Row>
                <Col xs={24} md={24}>
                    <img style={{maxWidth: '100%'}} src={'assets/pdf1.png'}/>
                </Col>
                <Col xs={24} md={24}>
                    <span> Na opção <strong> destino </strong> escolha a opção <strong> Salvar como PDF</strong> e clique em <strong> Salvar </strong> </span>
                </Col>
            </Row>

        </Modal>*/}
        </Col>
    )
}

export default FullResumePage;
