import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AutoComplete, Avatar, Button, Checkbox, Col, Divider, Icon, Input, message, Modal, Row} from "antd";
import {Images} from "../../../../../Helpers/Images";
import {globalMaskReal, maskReal} from "../../../../../Helpers/Functions";
import moment from "moment/moment";
import {
    FaCodepen,
    FaDollarSign,
    FaGraduationCap,
    FaIdCardAlt,
    FaLanguage,
    FaRegAddressBook,
    FaRegCalendarAlt,
    FaRegFileAlt,
    FaRegMap,
    FaRegThumbsUp,
    FaUserAltSlash,
    FaUserCheck,
    FaWheelchair,
    MdMore,
    FaBookOpen, FaPause, FaPlay, FaLock, FaSmile, FaSadTear, FaSadCry, FaRegThumbsDown
} from "react-icons/all";
import {
    FaBookmark,
    FaBriefcase,
    FaCalendarCheck, FaCar,
    FaCheck,
    FaClock,
    FaPencilAlt, FaPhone,
    FaTools,
} from "react-icons/fa";
import useApi from "../../../../../Hooks/Api";
import Loading from "../../../../Generic/Loading";
import {debounce} from "lodash";
import CandidateSearchModal from "../CandidateSearchModal";
import {useHistory, useParams} from "react-router-dom";
import PausePublishModal from "./Modal/PausePublishModal";
import EndVacancyModal from "../EndVacancyModal";
const payType = [{label: 'Mensal', value:'Mês'}, {label:'Quinzenal', value:'Quinzena'}, {label: 'Diária', value:'Dia'}, {label:'Hora', value: 'Hora'}, {label: 'Por produção' , value: 'Por Produção'} , {label:'Por tarefa', value:'Por Tarefa'}]


const VacancyDetailsComponent = (props) => {
    const api = useApi();
    const [summary, setSummary] = useState(null);
    const [showEndVacancy, setShowEndVacancy] = useState('hidden');
    const [candidatesList, setCandidatesList] = useState([])
    const [candidatesModal, setCandidatesModal] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false);
    const [pausePublishModal, setPausePublishModal] = useState(false);
    const [detailsVacancy, setDetailsVacancy] = useState({});
    const [loadingButton, setLoadingButton] = useState(false)
    const paginate = useRef({
        page: 1,
        status: ["pending"]
    });
    const nextPage = useRef(false);
    const [loading, setLoading] = useState(true);
    const vacancy = useRef(null);
    const history = useHistory();
    const routerParams = useParams();


    const getVacancy = async (e) => {
        setLoading(true);
        api.get(`sine/user/${props.user.user_id}/job-opportunity/${routerParams.id}`, (res) => {
            let response = JSON.parse(JSON.stringify(res))

            if (response.desired_skills_categories !== null) {
                response.desired_skills_categories.forEach(el => response.desired_skills.push(el.title))
            }

            if ( response.incentives_categories !== null) {
                response.incentives_categories.forEach(el => response.incentives.push(el.title))
            }

            vacancy.current = response;
            setDetailsVacancy (response)

            vacancy.current.id = routerParams.id;
            setLoading(false)
        },(e) => {
            setLoading(false);
        });
    }

    const getCandidates = useCallback(debounce(async (more) => {
        setSearchLoading(true);

        if (more) {
            paginate.current.page = paginate.current.page + 1;
        }

        api.post(`sine/user/${props.user.user_id}/opportunity-interests/${routerParams.id}`, paginate.current, (res) => {
            res.next_page ? nextPage.current = true : nextPage.current = false;
            setSummary(res.summary);
            let auxArr = candidatesList

            if (more) {
                auxArr = auxArr.concat(res.candidates)
                // setCandidatesList(candidatesList.concat(res.candidates));
            } else {
                auxArr = res.candidates
                // setCandidatesList(res.candidates);
            }

            setCandidatesList(auxArr)

            setSearchLoading(false);
        }, (e) => {
            setSearchLoading(false);
        });

    }, 400), []);

    const handleEndVacancy = async (data) => {
        setLoading(true);
        api.post(`sine/user/${props.user.user_id}/close-opportunity/${vacancy.current.id}`, data,(res) => {
            message.success("Seu anúncio foi encerrado!");
            setShowEndVacancy('hidden');
            setCandidatesModal(false);
            setLoading(false);
            props.updateVacancyList();
            history.push('/painel/cliente/vagas/' + props.enterprise.id +'/vagas-abertas')
            },(e) => {
            if(e.message === "Oportunidade já encerrada") {
                setShowEndVacancy('hidden');
                setCandidatesModal(false);
                setLoading(false);
                history.push('/painel/cliente/vagas/' + props.enterprise.id + '/vagas-abertas')
            } else {
                console.log(e);
                message.warning(e.message || "Não foi possível encerrar a publicação tente novamente");
                setLoading(false)
            }
        });
    };

    const payPeriod = () => {
        let found = payType.find(el => el.value === vacancy.current.pay_period)
        return found?.label
    }

    const showFeedbackModal = (data) => {
        if(summary.pending > 0) {
            if(summary.approved === 0) {
                setCandidatesModal(true);
            } else {
                handleEndVacancy(data);
            }
        } else {
            handleEndVacancy(data);
        }
    }

    const handlePausePublish = () => {
        setLoadingButton(true)

        let sendApi = {
            hidden: !detailsVacancy?.hidden
        }

        api.post(`sine/user/${props.user.user_id}/hide-opportunity/${vacancy.current.id}`,sendApi ,
            () => {
                setDetailsVacancy({...detailsVacancy, hidden: !detailsVacancy?.hidden})
                if (!sendApi.hidden) {
                    message.success('Sua vaga está visível novamente!')
                }

                setPausePublishModal(false)
                setLoadingButton(false)
            }, (err) => {
                console.log(err)
                message.error('Parece que ocorreu um erro, tente novamente mais tarde.')
                setPausePublishModal(false)
                setLoadingButton(false)
            })
    }

    useEffect(() => {
        window.scrollToElement('body');
        getCandidates();
        getVacancy();
    }, []);

    return (
        <div>
            <PausePublishModal showModal={pausePublishModal}
                               closeModal={() => setPausePublishModal(false)}
                               handlePause={handlePausePublish}
            />
            <EndVacancyModal handleEndVacancy={(data) => handleEndVacancy(data)} setShowEndVacancy={(data) => setShowEndVacancy(data)} showEndVacancy={showEndVacancy} showFeedbackModal={(data) => showFeedbackModal(data)}/>

                {candidatesModal && <CandidateSearchModal infoOnly={true}
                                                          opportunity_id={vacancy.current?.id}
                                                          handleEndVacancy={() => handleEndVacancy({vacancy_filled_internal: true, informed_hiring_qtd: 1, vacancy_filled_external: false, vacancy_not_filled: false})}
                                                          visible={candidatesModal}
                                                          setVisible={() => {
                                                              setCandidatesModal(false);
                                                              handleEndVacancy({vacancy_filled_internal: true, vacancy_filled_external: false, informed_hiring_qtd: 1, vacancy_not_filled: false})
                                                          }}
                                                          enterprise={props.enterprise}
                />}

                <div className={'mainComponentPanel'} style={{
                    boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`,
                    padding: 5,
                    borderRadius: 5,
                    backgroundColor: "white"
                }}>
                    <Row style={{borderBottom: "1px solid gainsboro"}}>
                        <Col xs={24} style={{fontSize: 19, display: "flex", alignItems:'center', justifyContent:"space-between"}}>
                            <div style={{padding:10, display:"flex", alignItems:"center", gap:10}}>
                                <Avatar style={{width: 45, height: 45}}
                                        size='default'
                                        src={vacancy.current?.job_avatar || Images.user_place_holder}
                                />
                                <div>
                                    <div>
                                        <span style={{fontSize: 16, color: "#757575"}}> Detalhes da divulgação </span>
                                    </div>
                                    <div>
                                        <span>{vacancy.current?.occupation_name}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{textAlign: "right", padding:'5px 10px 0'}}>
                                    <span>#{vacancy.current?.id}</span>
                                </div>
                                <div style={{padding:10}}>
                                    {!detailsVacancy?.closed &&
                                        <div>
                                            <div style={{display: 'flex', gap:5}}>
                                                <button onClick={() => history.push('/painel/cliente/vagas/' + props.enterprise.id + '/editar-vaga/' + vacancy.current.id)}
                                                        className={"btn-reseted endVacancyButton"}>
                                                    <FaPencilAlt style={{fontSize: 11, marginRight: 5}}/>
                                                    <span> Editar </span>
                                                </button>
                                                {detailsVacancy?.hidden ?
                                                    <button style={{backgroundColor: '#0997ff', color: 'white'}} disabled={loadingButton} onClick={() => handlePausePublish()} className={"btn-reseted endVacancyButton"}>
                                                        <span>
                                                            <FaPlay size={11} style={{marginRight:5}}/>
                                                        </span>
                                                        <span>
                                                            Continuar divulgação
                                                        </span>
                                                    </button>:
                                                    <button disabled={loadingButton} onClick={() => setPausePublishModal(true)} className={"btn-reseted endVacancyButton"}>
                                                        <span>
                                                            <FaPause  size={11} style={{marginRight:5}}/>
                                                        </span>
                                                        <span>
                                                            Ocultar vaga
                                                        </span>
                                                    </button>
                                                }
                                            </div>
                                            <div style={{marginTop:8}}>
                                                <button onClick={() => setShowEndVacancy('initial')} className={"btn-reseted justify-content-center endVacancyButton w-100"}>
                                                    <FaLock style={{fontSize: 11, marginRight: 5}}/>
                                                    <span> Encerrar Divulgação  </span>
                                                </button>
                                            </div>
                                        </div>

                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {vacancy.current &&<Row gutter={[16, 32]} style={{margin: 0}}>
                        <Col xs={8} style={{paddingBottom: 0}}>
                            <div className="resumeCandidatesCard resumeBlue"
                                 onClick={() => {
                                     props.handleChangeStatus('pending')
                                     history.push('/painel/cliente/vagas/' + props.enterprise.id + '/candidatos-da-vaga/' + vacancy.current.id)
                                 }}>
                                <FaClock style={{marginRight: 10}}/>
                                <div>
                                    <span style={{fontSize: 15}}> Pendentes </span> <br/>
                                    <strong style={{fontSize: 20}}>{summary ? summary.pending : <Icon type={"loading"}/>}</strong>
                                </div>
                            </div>
                        </Col>
                        <Col xs={8} style={{paddingBottom: 0}}>
                            <div className="resumeCandidatesCard resumeCyan"
                                 onClick={() => {
                                     props.handleChangeStatus('selected')
                                     history.push('/painel/cliente/vagas/' + props.enterprise.id + '/candidatos-da-vaga/' + vacancy.current.id)
                                 }}>
                                <FaBookmark/>
                                <div>
                                    <span style={{fontSize: 15}}> Em Análise </span> <br/>
                                    <strong style={{fontSize: 20}}>{summary ? summary.selected : <Icon type={"loading"}/>}</strong>
                                </div>
                            </div>
                        </Col>
                        <Col xs={8} style={{paddingBottom: 0}}>
                            <div className="resumeCandidatesCard resumePurple"
                                 onClick={() => {
                                     props.handleChangeStatus('interview')
                                     history.push('/painel/cliente/vagas/' + props.enterprise.id + '/candidatos-da-vaga/' + vacancy.current.id)
                                 }}>
                                <FaPhone/>
                                <div>
                                    <span style={{fontSize: 15}}> Entrevista </span> <br/>
                                    <strong style={{fontSize: 20}}>{summary ? summary.interview : <Icon type={"loading"}/>}</strong>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} style={{paddingBottom: 0}}>
                            <div className="resumeCandidatesCard resumeGreen"
                                 onClick={() => {
                                     props.handleChangeStatus('approved')
                                     history.push('/painel/cliente/vagas/' + props.enterprise.id + '/candidatos-da-vaga/' + vacancy.current.id)
                                 }}>
                                <FaUserCheck/>
                                <div>
                                    <span style={{fontSize: 15}}> Aprovados </span> <br/>
                                    <strong
                                        style={{fontSize: 20}}>{summary ? (summary.approved ? summary.approved : 0) :
                                        <Icon type={"loading"}/>} </strong>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} style={{paddingBottom: 0}}>
                            <div className="resumeCandidatesCard resumeRed"
                                 onClick={() => {
                                     props.handleChangeStatus("rejected")
                                     history.push('/painel/cliente/vagas/' + props.enterprise.id + '/candidatos-da-vaga/' + vacancy.current.id)
                                 }}>
                                <FaUserAltSlash/>
                                <div>
                                    <span style={{fontSize: 15}}> Reprovados </span> <br/>
                                    <strong
                                        style={{fontSize: 20}}>{summary ? (summary.rejected ? summary.rejected : 0) :
                                        <Icon type={"loading"}/>} </strong>
                                </div>
                            </div>
                        </Col>
                    </Row>}

                    {(loading || !vacancy.current)? <Loading/>
                        :
                    <>
                        <div style={{padding: 15, width: "100%"}}>
                            <div><span style={{fontSize: 19}}> Informações da Vaga </span> <Divider
                                style={{marginTop: 10}}/></div>

                            {vacancy.current.job_image &&
                            <div style={{width: "100%", marginBottom: 20, display: 'flex', justifyContent: 'center'}}>
                                <img src={vacancy.current.job_image}
                                     style={{maxWidth: "100%", borderRadius: 5, boxShadow: "1px 1px 5px 0px #75757540"}}/>
                            </div>}

                            {vacancy.current.job_video && <div className='iframeDiv'>
                                <iframe src={vacancy.current.job_video}
                                        allow='autoplay; encrypted-media'
                                        allowFullScreen
                                        title='video'
                                />
                            </div>}

                            {vacancy.current.closed_at &&
                            <Row gutter={[16, 32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <FaCalendarCheck style={{color: 'red'}}/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Encerrado em  </span>
                                        <span className='tagAnswer'
                                              style={{color: 'red'}}> {moment(vacancy.current.closed_at).format("DD/MM/YYYY")} </span>
                                    </div>
                                </Col>
                            </Row>}

                            <Row gutter={[16, 32]} style={{margin: 0}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <FaIdCardAlt/>
                                    <div style={{display: 'flex', flexFlow: 'column', width: "100%"}}>
                                        <span className='tagQuestion'> Cargo </span>
                                        <span style={{
                                            textOverflow: 'ellipsis',
                                            color: "#52c41a"
                                        }}> {vacancy.current.occupation_title ? vacancy.current.occupation_title : vacancy.current.occupation_name} </span>
                                        <div style={{color: "gray"}}>
                                            {vacancy.current.occupation_title && "(" + vacancy.current.occupation_name + ")"}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                                <Col xs={24} lg={12} className='vacancyResumeTags'>
                                    <FaCodepen/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Tipo de cargo </span>
                                        <span className='tagAnswer'> {vacancy.current.job_type} </span>
                                    </div>
                                </Col>

                                <Col xs={24} lg={12} className='vacancyResumeTags'>
                                    <FaBriefcase/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Quantidade de vagas </span>
                                        <span className='tagAnswer'> {vacancy.current.job_openings} </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0}}>
                                <Col xs={24} lg={12} className='vacancyResumeTags'>
                                    <FaDollarSign/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Salário  </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.pay ? globalMaskReal(vacancy.current.pay) + ' / ' + payPeriod() : "Não informado"} </span>
                                    </div>
                                </Col>

                                <Col xs={24} lg={12} className='vacancyResumeTags'>
                                    <FaRegCalendarAlt/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Data da publicação </span>
                                        <span
                                            className='tagAnswer'> {moment(vacancy.current.start_date).format("DD/MM/YYYY")}</span>
                                    </div>
                                </Col>
                            </Row>

                            {vacancy.current?.apply_message && <Row gutter={[16, 32]} style={{margin: 0}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <MdMore/>
                                    <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Informação adicional da vaga </span>
                                        <span className='tagAnswer'> {vacancy.current?.apply_message} </span>
                                    </div>
                                </Col>
                            </Row>}

                            <Row style={{backgroundColor: "#fafafad4", margin: 0}} gutter={[16, 32]}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <FaRegThumbsUp/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Benefícios  </span>
                                        <div style={{display: "flex", flexWrap: 'wrap'}}>
                                            {vacancy.current.incentives.map((item, index) =>
                                                <div key={index} style={{
                                                    padding: 3,
                                                    paddingRight: 8,
                                                    paddingLeft: 8,
                                                    backgroundColor: "#e7f3fb ",
                                                    border: "1px solid #005c9f",
                                                    color: "#005c9f",
                                                    borderRadius: 5,
                                                    margin: 5
                                                }}>
                                                <span>
                                                    {item}
                                                </span>
                                                </div>
                                            )}
                                            {vacancy.current.incentives.length === 0 &&
                                            <div style={{
                                                padding: 4,
                                                paddingRight: 8,
                                                paddingLeft: 8,
                                                backgroundColor: "#d3d3d36e ",
                                                color: "#9e9e9e",
                                                borderRadius: 5,
                                                margin: 5
                                            }}>
                                                Nenhum beneficio informado
                                            </div>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <FaClock/>
                                    <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Horário de Trabalho </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.schedule ? vacancy.current.schedule : "Não inserido"} </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <FaRegFileAlt/>
                                    <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Descrição da vaga </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.job_description ? vacancy.current.job_description : "Nenhuma descrição inserida"} </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <FaRegMap/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Endereço a ser realizado </span>
                                            {vacancy.current.work_place === 'mobile'?
                                                <span> Trabalho Remoto / Home Office </span>
                                                :
                                                (vacancy.current.work_place === 'itinerant' ?
                                                    <span> Sem endereço fixo </span>
                                                    :
                                                    (vacancy.current.work_address?
                                                        <div>
                                                            <span> <strong> Rua - </strong> {vacancy.current?.work_address.street} </span> <br/>
                                                            <span> <strong> Nº - </strong> {vacancy.current?.work_address.street_number} </span><br/>
                                                            <span> <strong> Bairro - </strong> {vacancy.current?.work_address.district} </span><br/>
                                                            <span> <strong> Cidade - </strong> {vacancy.current?.work_address.city} </span>
                                                        </div>
                                                        : null
                                                    )
                                                )
                                            }
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div style={{padding: 15, width: "100%"}}>
                            <div><span style={{fontSize: 19}}> Informações de requisição aos candidatos </span> <Divider
                                style={{marginTop: 10}}/></div>

                            <Row style={{backgroundColor: "#fafafad4", margin: 0}} gutter={[16, 32]}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <FaTools/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Habilidades desejadas  </span>
                                        <div style={{display: "flex", flexWrap: 'wrap'}}>
                                            {vacancy.current.desired_skills?.map((item, index) =>
                                                <div key={index} style={{
                                                    padding: 3,
                                                    paddingRight: 8,
                                                    paddingLeft: 8,
                                                    backgroundColor: "#e7f3fb ",
                                                    border: "1px solid #005c9f",
                                                    color: "#005c9f",
                                                    borderRadius: 5,
                                                    margin: 5
                                                }}>
                                                <span>
                                                    {item}
                                                </span>
                                                </div>
                                            )}
                                            {vacancy.current.desired_skills?.length === 0 &&
                                            <div style={{
                                                padding: 4,
                                                paddingRight: 8,
                                                paddingLeft: 8,
                                                backgroundColor: "#d3d3d36e ",
                                                color: "#9e9e9e",
                                                borderRadius: 5,
                                                margin: 5
                                            }}>
                                                Nenhum habilidade informada
                                            </div>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0}}>
                                <Col xs={24} lg={12} className='vacancyResumeTags'>
                                    <FaGraduationCap/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Escolaridade mínima </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.schooling ? vacancy.current.schooling : "Nenhuma"} </span>
                                    </div>
                                </Col>

                                <Col xs={24} lg={12} className='vacancyResumeTags'>
                                    <FaWheelchair/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Vaga destinada a PcD? </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.disabilities.length > 0 ? "Sim" : "Não"} </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0, backgroundColor: "#fafafad4",}}>
                                <Col xs={24} lg={12} className='vacancyResumeTags'>
                                    <FaLanguage/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'>Deve possuir fluência em outro Idioma?  </span>
                                        <div style={{display: "flex", flexWrap: "wrap"}}>
                                            {vacancy.current.languages.map((item, index) => <span key={index}
                                                                                                className='tagAnswer'> {item}{index === (vacancy.current.languages.length - 1) ? '.' : ','}</span>)}
                                            {vacancy.current.languages.length === 0 && "Não"}
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={24} lg={12} className='vacancyResumeTags'>
                                    <FaRegAddressBook/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Deve possuir experiência no cargo </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.occupation_cbo_req ? "Sim" : "Não"}</span>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0}}>
                                <Col xs={24} lg={12} className='vacancyResumeTags'>
                                    <FaCar/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Deve possuir CNH? </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.driver_license ? vacancy.current.driver_license : "Não"} </span>
                                    </div>
                                </Col>
                                {vacancy.current?.desired_courses_categories &&
                                    <Col xs={24} lg={12} className='vacancyResumeTags'>
                                        <FaBookOpen/>
                                        <div style={{display: 'flex', flexFlow: 'column'}}>
                                            <span className='tagQuestion'> Curso de capacitação desejável em </span>
                                            <span className='tagAnswer'>
                                                {vacancy.current?.desired_courses_categories[0].name}
                                            </span>
                                        </div>
                                    </Col>
                                }
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <Icon type='caret-right'/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Candidato deve ter disponibilidade para realizar viagens? </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.available_travel ? "Sim" : "Não"} </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0, backgroundColor: "#fafafad4",}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <Icon type='caret-right'/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Candidato deve ter disponibilidade para dormir no local de trabalho? </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.available_sleep_duty ? "Sim" : "Não"} </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <Icon type='caret-right'/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Candidato deve ter disponibilidade para ausentar-se de seu domicílio por longos períodos? </span>
                                        <span
                                            className='tagAnswer'> {vacancy.current.available_leave_home ? "Sim" : "Não"} </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 32]} style={{margin: 0, backgroundColor: "#fafafad4",}}>
                                <Col xs={24} className='vacancyResumeTags'>
                                    <Icon type='caret-right'/>
                                    <div style={{display: 'flex', flexFlow: 'column'}}>
                                        <span className='tagQuestion'> Permitir candidatos que não se encaixam nos requisitos a se candidatar a vaga? </span>
                                        <span className='tagAnswer'
                                              style={{color: vacancy.current.accept_proposal ? "green" : "red"}}> {vacancy.current.accept_proposal ? "Sim" : "Não"} </span>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </>}
                </div>
        </div>
    );
};

VacancyDetailsComponent.propTypes = {};

export default VacancyDetailsComponent;
