import React, {useState, useEffect} from 'react';
import Icon from "antd/es/icon";
import {Col, Row} from "antd";
import {Link, useHistory} from "react-router-dom";
import useLocalStorage from "../Hooks/Storage";
import {StorageVariables} from "../Helpers/StoragesVariables";
import useApi from "../Hooks/Api";
import {Env} from "../Helpers/Env";
import {Images} from "../Helpers/Images";
import Loading from "../Components/Generic/Loading";

const PDFReader = props => {
    const storage = useLocalStorage(StorageVariables.routeEdital)
    const lastSlug = storage.getPrimitive(StorageVariables.routeEdital);
    const api = useApi();
    const history = useHistory();

    const [onGoing, setOnGoing] = useState([]);
    const [closed, setClosed] = useState([]);
    const [activeEdital, setActiveEdital] = useState({});

    const payload = {
        limit: 50,
        page: 1,
        application: Env.application_alias
    }

    const getActiveEdital = (route, notice) => {
        history.push(`/editais/${route}`)
        setActiveEdital(notice)
    }

    const handleEdital = (item) => {
        const { slug } = item
        setActiveEdital(item)

        history.push(`/editais/${slug}`)
        storage.setPrimitive(slug)
    }

    const getNotices = () => {
        api.post('courses/search-notice', {...payload, status:"ongoing"}, (res) => {
            setOnGoing(res.notices);

            const lastNoticeViewed = res.notices.find((item) => item.slug === (props.match.params?.id || lastSlug));
            if (lastNoticeViewed) {
                getActiveEdital(lastNoticeViewed.slug, lastNoticeViewed)
            } else {
                getActiveEdital(res.notices[0].slug, res.notices[0])
            }
        }, (e) => {
            console.log(e);
        });
        api.post('courses/search-notice', {...payload, status:"closed"}, (res) => {
            setClosed(res.notices);

        }, (e) => {
            console.log(e);
        });
    }

    useEffect(() => {
        getNotices();
        window.scrollToElement('body');
    }, []);

    return (
        <div style={{display: `flex`, justifyContent: `center`}}>
            <Col xs={23} lg={22} xl={18} xxl={15} style={{ justifyContent: 'space-around', paddingTop: 40}}>
                <div style={{marginBottom: 20}}>
                    <h1 style={{fontSize: 30, textAlign:'center'}}>Processos seletivos e editais</h1>
                </div>

                {api.loading ?
                    <div style={{minHeight:700}}>
                        <Loading/>
                    </div> :
                    <div className={'d-flex'} style={{width: `100%`, flexWrap: 'wrap'}}>
                        <Col xs={24} style={{flex: 1}} className={'order-1 order-md-0'}>
                            <div style={{border: `1px solid gainsboro`, borderRadius: 5}}>
                                <div style={{padding: 5, borderBottom: `1px solid gainsboro`}}>
                                    <h3 style={{fontWeight: "bold", margin: 0, color: `#1a5697`, textAlign: 'center'}}>
                                        {activeEdital.status === 'closed' ? <span>Encerrado - </span> :
                                            <span>Em andamento - </span>}
                                        {activeEdital.title}
                                    </h3>
                                </div>
                                {!api.loading &&
                                    <div style={{textAlign: 'center'}}>
                                        <img alt={'banner'} src={activeEdital.image || Images.edital2}
                                             style={{
                                                 objectFit: 'cover',
                                                 maxHeight: 200,
                                                 marginTop: 20,
                                                 marginBottom: 20
                                             }}
                                        />
                                    </div>
                                }


                                <div style={{display: `flex`, justifyContent: `center`}}>
                                    <div className={'p-ul-mb-0'}
                                         style={{fontSize: 15, margin: 0, maxWidth: 500, padding: '0 20px'}}
                                         dangerouslySetInnerHTML={{__html: activeEdital?.description}}
                                    />
                                </div>

                                <div style={{
                                    padding: 20,
                                    borderTop: `1px solid #f3f3f3`,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: 10,
                                    flexDirection: "column",
                                    gap: 20
                                }}>
                                    {activeEdital?.files?.map((item, index) =>
                                        <div key={index}>
                                            <a href={item.file} target="_blank">
                                                <Icon style={{color: `#497db7`, marginRight: 10}} type="file-pdf"/>
                                                <span style={{color: `#497db7`}}>
                                                {item.title}
                                            </span>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={7} style={{textAlign: 'center', position: "relative"}}
                             className={'ms-0 ms-md-2'}>

                            <div style={{position: "sticky", top: 80}}>
                                <div style={{
                                    border: `1px solid gainsboro`,
                                    borderRadius: 5,
                                    marginBottom: 20,
                                    color: `#939393`
                                }}>
                                    <div style={{padding: 5, borderBottom: `1px solid gainsboro`, marginBottom: 10}}>
                                        <h3 style={{fontWeight: "bold", margin: 0, color: `rgb(38 38 38)`}}> Em
                                            andamento </h3>
                                    </div>
                                    {onGoing?.map((item, index) =>
                                        <div key={index} style={{
                                            padding: 4,
                                            fontWeight: activeEdital.title === item.title && `bold`,
                                            color: activeEdital.title === item.title && `#1a5697`
                                        }} className={'limit-rows clamp-1'}>
                                            <Link onClick={() => handleEdital(item, item.slug)}>
                                                {item?.title}
                                            </Link>
                                        </div>
                                    )}
                                </div>

                                <div style={{border: `1px solid gainsboro`, borderRadius: 5, color: `#939393`}}
                                     className={'mb-2 mb-md-0'}>
                                    <div style={{padding: 5, borderBottom: `1px solid gainsboro`, marginBottom: 10}}>
                                        <h3 style={{fontWeight: "bold", margin: 0, color: `#aaa`}}> Encerrados </h3>
                                    </div>
                                    {closed?.map((item) =>
                                        <div style={{
                                            padding: 4,
                                            fontWeight: activeEdital.title === item.title && `bold`,
                                            color: activeEdital.title === item.title && `#1a5697`
                                        }}>
                                            <Link onClick={() => handleEdital(item, item.slug)}>
                                                {item?.title}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </div>
                }


            </Col>
        </div>
    );
};

export default PDFReader;
