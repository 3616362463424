import React, {useEffect, useRef, useState} from 'react';
import EmailComponent from "./EmailComponent";
import PhonesComponent from "./PhoneComponent";
import {Button, Carousel, Col, Row} from "antd";
import Modal from "antd/es/modal";
import {useHistory} from 'react-router-dom'
import useLocalStorage from "../../../../../Hooks/Storage";
import {FaBackward} from "react-icons/fa";
import {Images} from "../../../../../Helpers/Images";
import {StorageVariables} from "../../../../../Helpers/StoragesVariables";
import SocialMediaComponent from "./SocialMediaComponent";

const ContactMeComponent = (props) => {

    const carouselRef = useRef();
    const [visibleModal, setVisibleModal] = useState(false);
    const [step, setStep] = useState(0);
    const history = useHistory();
    const lastStep = useLocalStorage(StorageVariables.curriculumStep);

    useEffect(() => {
        window.scrollToElement('body')
    },[]);

    return (<div style={{marginBottom: 200}} className={'contactForm aboutCss'}>
        <h1 style={{textAlign: 'center', marginTop: 50, fontSize: 20, paddingBottom: 35, borderBottom: '1px solid gainsboro', color: '#9E9E9E'}}>
            <strong style={{color: '#242424', fontSize: 25}}> Quase lá </strong> <br/>
            Será através destes contatos que as empresas que se interessarem pelo seu cúrriculo irão te contatar, você pode adicionar também suas redes sociais!

        </h1>
        <Row>
            <Col xs={24} md={8}>
                <img style={{width: '100%'}} src={Images.add_resume.contact_resume_page}/>
            </Col>
            <Col xs={24} md={16}>
                <EmailComponent setHasProfile={(item) => props.setHasProfile(item)}/>
                <PhonesComponent setHasProfile={(item) => props.setHasProfile(item)}/>
                <SocialMediaComponent/>
            </Col>
        </Row>

        <div className={'buttonRow'} justify={'center'}>
            <Button shape={'round'} onClick={() => props.handleChangeStep(3)} type={'default'}> <FaBackward className={'exportedIconsLeft'}/>  Voltar </Button>
            <Button shape={'round'} className={'ant-btn-secondary'} onClick={() => setVisibleModal(true)}> Finalizar  </Button>
        </div>


        <Modal footer={
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                {step !== 0 && <Button shape={'round'} type={'default'} onClick={() => {setStep(step-1); carouselRef.current.prev()}}>  Anterior </Button>}
                {step !== 1 &&<Button shape={'round'} className={'ant-btn-sub-primary'} onClick={() =>{setStep(step + 1); carouselRef.current.next()}}> Próximo  </Button>}
                {step === 1 &&<Button shape={'round'} className={'ant-btn-secondary'} onClick={() => { lastStep.setPrimitive(0); history.push('/painel')}}> Finalizar </Button>}
            </div>
        } visible={visibleModal} destroyOnClose={true} onCancel={() => history.push('/painel')} style={{maxWidth: '900px'}} className={'registerModalWidth endResume'}>
            <Carousel ref={carouselRef}>
                <Row className={'endModalRow'}>
                    <Col span={24}>
                        <img style={{maxWidth: '100%'}} src={Images.add_resume.end1}/>
                    </Col>
                    <Col span={24}>
                        <h1> Parabéns! Você completou seu currículo com sucesso! </h1>

                        <span> Suas informações já foram salvas e estarão disponíveis para os empregadores Online! Fique à vontade para alterar qualquer informação caso seja necessário. </span> <br/>
                        <strong> Boa sorte! </strong>
                    </Col>
                </Row>

                <Row className={'endModalRow'}>
                    <Col span={24}>
                        <img style={{maxWidth: '100%'}} src={Images.add_resume.end2}/>
                    </Col>
                    <Col span={24}>
                        <h1> Mantenha seu celular por perto!</h1>

                        <span> Fique de olho em seu e-mail e celular, pois será por eles que as empresas entrarão em contato! </span>
                    </Col>
                </Row>
            </Carousel>
        </Modal>
    </div>)
};
export default ContactMeComponent;
