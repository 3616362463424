import React from 'react'
import {Dropdown, Menu, message} from "antd";
import { FaLinkedinIn, FaPaperclip, FaShare, FaTwitter, FaWhatsapp, MdShare} from "react-icons/all";
import {FaFacebookF} from "react-icons/fa";

const ShareTo = ({idVacancy, sizeIcon, type}) => {


    const menuOptions = () => {
        let link = encodeURIComponent(`${window.location.origin}/vagas-de-emprego/?id=${idVacancy}`)

        const handleSocialMedia = (media) => {
            let platform = ''

            let w = ((window.innerWidth * 50) / 100)
            let h = ((window.innerHeight * 50) / 100)
            let top = (window.screen.height / 2) - (h/2)
            let left = (window.screen.width / 2) - (w/2)

            if (media === 'copyToClipboard') {
                if (window.screen.width < 600) {
                    const elem = document.createElement('textarea');
                    elem.value = decodeURIComponent(link);
                    document.body.appendChild(elem);
                    elem.select();
                    document.execCommand('copy');
                    document.body.removeChild(elem);
                } else {
                    navigator.clipboard.writeText(decodeURIComponent(link));
                }
                return message.success('Copiado para a área de transferência!')
            }

            if (media === 'facebook') {
                platform = `https://www.facebook.com/sharer/sharer.php?u=${link}`
            } else if (media === 'linkedin') {
                platform = `https://www.linkedin.com/shareArticle?mini=true&url=${link}`
            } else if (media === 'twitter') {
                platform = `https://twitter.com/intent/tweet?url=${link}`
            } else if (media === 'whatsapp') {
                platform = `https://${window.screen.width < 762 ? 'api' : 'web'}.whatsapp.com/send?text=${link}`
                return window.open(platform, `blank`)
            }

            window.open(platform,'blank', `toolbar=no, location=no,status=no, menubar=no,scrollbars=yes,resizable=no,top=${top},left=${left}, width=${w},height=${h}`)
        }

        return (
            <Menu className='shareTo'>
                <Menu.Item className='shareTo-menu'>
                    <div className='options' onClick={() => handleSocialMedia('whatsapp')}>
                        <FaWhatsapp size={16}/> <span>WhatsApp</span>
                    </div>
                </Menu.Item>
                <Menu.Item className='shareTo-menu'>
                    <div className='options' onClick={() => handleSocialMedia('facebook')}>
                        <FaFacebookF/><span>Facebook</span>
                    </div>
                </Menu.Item>
                <Menu.Item className='shareTo-menu'>
                    <div className='options' onClick={() => handleSocialMedia('linkedin')}>
                        <FaLinkedinIn size={16}/><span>Linkedin</span>
                    </div>
                </Menu.Item>
                <Menu.Item className='shareTo-menu'>
                    <div className='options' onClick={() => handleSocialMedia('twitter')}>
                        <FaTwitter size={16}/><span>Twitter</span>
                    </div>
                </Menu.Item>
                <Menu.Item className='shareTo-menu'>
                    <div className='options' onClick={() => handleSocialMedia('copyToClipboard')}>
                        <FaPaperclip size={16}/><span>Copiar link</span>
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    return (

        <Dropdown overlay={menuOptions} trigger={['click']}>
            <div className='shareTo'>
                {type === 'jobInformation' ?
                    <button className='btn-reseted shareTo-label'>
                        {window.screen.width > 600 && <span>Compartilhar vaga</span>} <MdShare style={{margin:0}} size={sizeIcon ? sizeIcon : 14}/>
                    </button>
                    :
                    <button className='shareTo-icon btn-reseted'>
                        <FaShare style={{margin:0}} size={sizeIcon ? sizeIcon : 14}/>
                    </button>
                }
            </div>

        </Dropdown>

    )
}

export default ShareTo
