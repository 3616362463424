import React from 'react';
import {Avatar, Button, List, Rate, Skeleton,} from "antd";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {Images} from "../../../../../../Helpers/Images";


const PendingCard = props => {
    const history = useHistory();

    return (
        <List.Item key={props.index} style={{padding: 20}} actions={[<Button className={'ant-btn-blue-ghost'} onClick={() => history.push('/ordem-de-servico/'+props.item.work_order_id)} icon={'file'}> Ordem de serviço </Button>]}>
            <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                    avatar={
                        <Avatar style={{width: 64, height: 64}} size='default' src={props.item.user?.profile_picture?.full || Images.user_place_holder}/>
                    }
                    title={<>
                        <div style={{
                            display: 'flex',
                            marginTop: 5,
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                <span style={{fontWeight: "bold", fontSize: 15}}>
                                    {props.item.user.name}
                                </span>
                            </div>
                        </div>
                        <Rate style={{fontSize: 15}} className={'rating'} disabled defaultValue={props.item.rating}/>
                    </>}
                    description={<span>
                        <span style={{fontSize: 14, color: '#5f5f6f'}}>
                            Avalie o serviço prestado pelo profissional em <strong> {moment.utc(props.item.created_at).local().format('DD/MM/YYYY HH:mm')}, </strong>  na ordem de serviço <strong> {props.item.work_order_id} </strong>
                        </span>
                    </span>}
                />
            </Skeleton>
        </List.Item>

    );
};

PendingCard.propTypes = {};

export default PendingCard;
