import React, {useEffect} from 'react';
import Loading from "../Components/Generic/Loading";
import useLogout from "../Hooks/LogoutAuth";
import useApi from "../Hooks/Api";

const LogoutScreen = (props) => {

    const {logoutWithoutApi} = useLogout();

    const logoutApi = useApi();

    const doLogoutApi = () => {
        logoutApi.post('access/logout', {}, (res) => {
            console.log(res)
        }, (e) => {
            console.log(e)
        })
    }

    useEffect(() => {
        doLogoutApi();
        logoutWithoutApi(true);

    },[]);

    return (<div style={{display: 'flex', justifyContent: 'center', background: 'aliceblue', minHeight: window.innerHeight, flexDirection: 'column', alignItems: 'center', padding: 50, paddingBottom: 100, paddingTop: 150}}>
             <Loading/>
    </div>)
};
export default LogoutScreen;
