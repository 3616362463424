import useApiClub from "../../../Hooks/ApiClub";
import {Env} from "../../../Helpers/Env"
import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {FaStore, GiShop, RiUserFollowLine} from "react-icons/all";
import {FaBriefcase, FaBullhorn} from "react-icons/fa";

const Indicator = (props) => {

    const [dataOfDay, setDataOfDay] = useState();
    const apiNum = useApiClub();

    const getNumbers = () => {
        let qs
        if (window.location.pathname.includes('vagas-do-evento')) {
            qs = `?event=${Env.event_name}`
        }
        apiNum.get(`sum-info/${Env.application_alias}${qs || ""}`, (res) => {
            setDataOfDay(res.object)
        }, (e) => {
            console.log(e)
        })
    }

    useEffect(() => {
        getNumbers()
    }, [])

    return (
        <Row className='background-search-opportunities'>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                     flexWrap: "wrap"
                }}>
                    <Col xs={24} md={12} lg={12} xl={8} className={'col'}>
                        <div className="card">
                            <div className="container-icon">
                                <div className="background-icon-indicator">
                                    <FaBriefcase color="#01689C"/>
                                </div>
                                <div>
                                    <span className="number-card-indicator">
                                        {dataOfDay && (props?.jobOpenings  || dataOfDay?.opportunity_now?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."))}
                                    </span>
                                </div>
                            </div>
                            <div>
                            <span className="text-card-indicator">
                                Total de vagas abertas
                            </span>
                            </div>
                        </div>

                    </Col>
                    <Col xs={24} md={12} lg={12} xl={8} className={'col'}>
                        <div className="card">
                            <div className="container-icon">
                                <div className="background-icon-indicator">
                                    <FaStore color="#673ab7"/>
                                </div>
                                <div>
                                <span className="number-card-indicator">
                                    {dataOfDay && (dataOfDay?.company?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."))}
                                </span>
                                </div>
                            </div>
                            <div>
                            <span className="text-card-indicator">
                                Empresas cadastradas
                            </span>
                            </div>
                        </div>

                    </Col>
                    <Col xs={24} md={12} lg={12} xl={8} className={'col'} >
                        <div className="card">
                            <div className="container-icon">
                                <div className="background-icon-indicator">
                                    <FaBullhorn color="#00AD6F"/>
                                </div>
                                <div>
                                <span className="number-card-indicator">
                                {dataOfDay && dataOfDay?.opportunity_publish?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </span>
                                </div>
                            </div>
                            <div>
                            <span className="text-card-indicator">
                               Publicações já realizadas
                            </span>
                            </div>
                        </div>

                    </Col>
                </div>
            </Row>
    )
}
export default Indicator
