import React from 'react'

const SyTime = ({data, setValue, value}) => {

    return (
        <div>
            <div>
                <input type={'time'}
                       id={data.label_key}
                       value={value}
                       onChange={(e) => setValue(e.target.value)}
                       className='ant-input ant-input-lg'
                />
            </div>
        </div>
    )
}

export default SyTime
