import React, {useEffect, useRef, useState} from "react";
import {Button, Collapse, Icon, Radio, Row, Select, Checkbox, message, Tooltip} from "antd";
import {FaBackward, FaForward, FaMinus, FaPen, FaPlus} from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import Col from "antd/es/grid/col";
import useApi from "../../../../../Hooks/Api";
import {useSelector} from "react-redux";
import Loading from "../../../../Generic/Loading";

const SkillsComponent = (props) => {
  const user = useSelector((state) => state).authReducer;
  const api = useApi();
  const [categories, setCategories] = useState([])
  const [showCategories, setShowCategories] = useState(false)
  const [closeButton, setCloseButton] = useState(true)
  const [loading, setLoading] = useState(false)
  const [myHabilities, setMyHabilities] = useState()


  const getData = () => {
    let allGroups = {}
    props.setHasProfile(true)

    api.get(`sine/knowledge-groups`, (groups)=>{
      setLoading(true)

      api.get(`sine/knowledge-subgroups`, (subGroups)=>{
        allGroups = {categories: groups, subCategories:subGroups}
        let filteredArray =  concatArrays(allGroups)

        api.get(`/sine/user/${user.user_id}/knowledge`, (knowledgesSelected)=>{
          if(knowledgesSelected.length > 0) {
            let populateOptions = populateCheckBox(filteredArray, knowledgesSelected)
            setCategories({knowledge: populateOptions})

            let myKnowledge = populateMyKnowledge(JSON.parse(JSON.stringify(filteredArray)))
            setMyHabilities({habilities:myKnowledge})
            setCloseButton(false)
          } else  {
            setCategories({knowledge:filteredArray})
            setShowCategories(true)
            setCloseButton(true)
          }
          setLoading(false)
        }, ()=>{})

      }, () =>{})}, () =>{

    })
  }

  const populateMyKnowledge = (array) => {
    let aux = []

    for (let i = 0 ; i < array.length ; i++) {
      let filterArray = {
        subCategories: []
      };

      for (let y = 0 ; y < array[i].subCategories.length ; y++) {
        if (array[i].subCategories[y].checked) {
          filterArray.subCategories.push({title_subgroup: array[i].subCategories[y].title, level: array[i].subCategories[y].level})
        }
      }

      if(filterArray?.subCategories?.length > 0) {
        filterArray.title = array[i].title;
        aux.push({title_group: filterArray.title, subCategories: filterArray.subCategories})
      }
    }

    return aux
  }

  const populateCheckBox = (filteredArray, optionsSelected) => {
    for (let i = 0 ; i < filteredArray.length ; i++) {
      for (let y = 0 ; y < optionsSelected?.length ; y++) {
        for (let x = 0 ; x < filteredArray[i]?.subCategories?.length ; x++) {
          if (filteredArray[i].subCategories[x].knowledge_group_id === optionsSelected[y].group_id && filteredArray[i].subCategories[x].knowledge_subgroup_id === optionsSelected[y].subgroup_id) {
            filteredArray[i].subCategories[x] = {...filteredArray[i].subCategories[x], checked: true, level: optionsSelected[y].level }
          }
        }
      }
    }

    return filteredArray
  }

  const concatArrays = (filterArray) => {
    for (let i = 0 ; i < filterArray.categories.length ; i++) {
      filterArray.categories[i].subCategories = []

      for (let y = 0 ; y < filterArray.subCategories.length ; y++) {
        if (filterArray.categories[i].knowledge_group_id === filterArray.subCategories[y].knowledge_group_id) {
          filterArray.categories[i].subCategories.push(filterArray.subCategories[y])
        }
      }
    }

    return filterArray.categories
  }

  const handleSubmit = () => {
    let aux = {knowledge:[]};

    for (let i = 0 ; i < categories.knowledge.length ; i ++) {
      for (let y = 0 ; y < categories.knowledge[i].subCategories.length ; y++) {
        if (categories.knowledge[i].subCategories[y].checked) {
          aux.knowledge.push({
            subgroup_id: categories.knowledge[i].subCategories[y].knowledge_subgroup_id,
            level_id: categories.knowledge[i].subCategories[y].level
          })
        }
      }
    }

    api.put(`sine/user/${user.user_id}/knowledge`, aux, ()=>{
      setLoading(true)
      message.success('Alterações salvas!', 2)
      window.scrollToElement('body');
      setMyHabilities({habilities:populateMyKnowledge(JSON.parse(JSON.stringify(categories.knowledge)))})
      if (aux.knowledge.length === 0) {
        setShowCategories(true)
        setCloseButton(true)
      } else {
        setShowCategories(false)
        setCloseButton(false)
      }
      setLoading(false)

    },()=>{
      message.info('Selecione o seu nível de conhecimento!', 5)
    })

  }

  const handleSelectSubCategorie = (type, index, subIndex, level) => {
    if (type === 'checkbox') {
      let aux = [...categories.knowledge];
      aux[index].subCategories[subIndex].checked = !aux[index].subCategories[subIndex].checked;
      setCategories({knowledge: aux})
    } else {
      let aux = [...categories.knowledge];
      aux[index].subCategories[subIndex].level = level
      setCategories({knowledge: aux})
    }
  }

  const scrollTo = (id) => {
    if (id === 'scrollTo-categories') {
      document.getElementById(id).scrollIntoView({behavior:'smooth'})
    } else {
      document.getElementById(id).scrollIntoView({behavior:'smooth'})
    }
  }


  useEffect(() => {
      getData()
  },[])

  return (
      <div style={{marginBottom: 100}} id='scrollTo-selected' className={'experienceForm aboutCss skills-component'}>

        <div style={{ paddingBottom: 35, borderBottom: '1px solid gainsboro',textAlign: 'center', marginTop: 50 }}>
          <h1>
            <strong style={{color: '#242424', fontSize: 25}}> Suas habilidades e competências </strong>
          </h1>
          <span  style={{ fontSize: 20,color: '#9E9E9E'}}> Adicione aqui suas habilidades e competências profissionais </span> <br/>
          <span style={{fontSize: 16, color: '#1890ff9e'}}> Selecione todas as suas habilidades, competências e nível de conhecimento</span>
        </div>

        {loading ? <div style={{marginTop:20}}> <Loading/> </div>  :
          <div style={{marginTop:32}}>
            {(myHabilities?.habilities.length > 0) &&
              <div className='card-skills' >
                <div className='d-flex justify-content-center flex-wrap'>
                  {myHabilities?.habilities.map((item, index) => (
                    <Col xs={24} md={12} style={{padding:16}}>
                      <div className='sub-card-knowledge float-initial'>
                      <span>
                        <Col xs={24} md={24} className='title-knowledge float-initial'>{item.title_group}</Col>
                      </span>
                        <div className='d-flex flex-wrap justify-content-between'>
                          {item.subCategories.map((subItem)=> (
                            <Col className='float-initial' xs={24} lg={11}>
                              <span>{subItem.title_subgroup}</span> - {subItem.level === '1' ? 'Básico' :subItem.level === '2' ? 'Intermediário' : 'Avançado' }
                            </Col>
                          ))}
                        </div>
                      </div>
                    </Col>
                  ))}

                </div>
                <Col className='float-initial' span={24}  style={{display: 'flex', margin:'16px 0 0', justifyContent: 'flex-end'}}>
                  <Button className='ant-btn-green d-flex align-items-center' id='scrollTo-categories' onClick={() => {
                    setShowCategories(true);
                    scrollTo('scrollTo-categories')
                  }} style={{fontSize: 16}}>
                    Editar <FaPen style={{color: 'white', marginLeft: 5}}/>
                  </Button>
                </Col>
              </div>
            }

            {showCategories &&
                <Row className='card-skills d-flex flex-wrap' style={{borderLeft:'3px solid #0072d6'}} >
                  <>
                    {!closeButton &&
                        <Col style={{textAlign:"end", margin:'0 0 16px'}} span={24}>
                          <Tooltip placement='top' title='Fechar'>
                            <CgClose fontSize={18} onClick={() => {
                              setShowCategories(false)
                              scrollTo('scrollTo-selected')
                            }} style={{cursor:"pointer"}}/>
                          </Tooltip>
                        </Col>
                    }

                    {categories?.knowledge?.map((item, index) =>
                        <Col xs={24} md={24}>
                          <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} defaultActiveKey={item.knowledge_group_id} className='floatsCardsResume' expandIcon={({ isActive }) =>
                              <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                  <div className="horizontal"/>
                                  <div className="vertical"/>
                                </div>
                              </div> }>
                            <Collapse.Panel header={<span style={{fontWeight: 'bold',fontSize:17, color: '#0072d6'}}>{item?.title} </span>} key={item.knowledge_group_id}>
                              <div style={{display:"flex", flexWrap:"wrap"}}>
                                {item?.subCategories.map((subItem, subIndex) => (
                                    <Col style={{display:"flex", justifyContent:  "space-between" , flexWrap:"wrap", padding:10, gap:10}} className='float-initial' xs={12} md={8} xl={6}>
                                      <div>
                                        <Checkbox style={{fontSize:16}} checked={subItem?.checked} onChange={() => handleSelectSubCategorie('checkbox', index, subIndex)}>
                                          {subItem?.title}
                                        </Checkbox>
                                      </div>
                                      <div style={{fontSize:15}} className='d-flex flex-column' >
                                        {subItem?.checked && <>
                                          {item?.options_level.map((itemLevel, levelIndex)=>(
                                              <Radio checked={itemLevel.id === subItem.level}  onChange={() => handleSelectSubCategorie('radio',index, subIndex, itemLevel?.id)}>{itemLevel.title}</Radio>
                                          ))}
                                        </>}
                                      </div>
                                    </Col>
                                ))}
                              </div>
                            </Collapse.Panel>
                          </Collapse>
                        </Col>
                    )}
                    <Col span={24}  style={{display: 'flex', margin:'16px 0', justifyContent: 'flex-end'}}>
                      <Button className={'ant-btn-sub-primary d-flex align-items-center'} onClick={()=> handleSubmit()} style={{fontSize: 16}}>
                        Salvar <FaPlus style={{color: 'white', fontSize: 17, marginLeft: 5}}/>
                      </Button>
                    </Col>
                  </>
                </Row>
            }
            <div className={'buttonRow'} justify={'center'}>
              <Button shape={'round'} onClick={() => props.handleChangeStep(4)} type={'default'}>  <FaBackward className={'exportedIconsLeft'}/> Voltar </Button>
              <Button shape={'round'} onClick={() => props.handleChangeStep(6)} className={'ant-btn-sub-primary'}> Próximo <FaForward className={'exportedIcons'}/> </Button>
            </div>
          </div>

        }
      </div>
  )
}

export default SkillsComponent
