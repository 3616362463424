import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Badge, Button, Col, Divider, Dropdown, Icon, Layout, Menu, Row} from "antd";
import {useHistory} from "react-router-dom";

import useAuth from "../../../Hooks/Auth";
import Link from "react-router-dom/es/Link";
import useApi from "../../../Hooks/Api";
import {useDispatch, useSelector} from "react-redux";
import useVerifyServices from "../../../Hooks/VerifyServices";
import RegisterPageModal from "../../../Pages/RegisterPageModal";
import {Images} from "../../../Helpers/Images";
import {
    FaBook,
    FaBriefcase,
    FaBookOpen,
    FaFilePdf,
    FaBuilding,
    FaHome,
    FaSearch,
    FaTools,
    FaRocket
} from "react-icons/fa";
import {MdDashboard} from "react-icons/md";
import {AiTwotoneShop} from "react-icons/ai";
import { RiFolderUserFill} from "react-icons/ri";
import useLocalStorage from "../../../Hooks/Storage";
import {StorageVariables} from "../../../Helpers/StoragesVariables";
import {FiLogOut, FiRefreshCw} from 'react-icons/fi'
import useLogout from "../../../Hooks/LogoutAuth";
import {eventVisible, useVerifyShowPanel} from "../../../Hooks/Helpers";

const Header = props => {
    const history = useHistory();
    const {isLogged, getUser} = useAuth();
    const {logoutWithoutApi} = useLogout();
    const api = useApi();
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);
    const profile = useSelector(state => state).authReducer;
    const notificationCount = useSelector(state => state).notificationsReducer;
    const dispatch = useDispatch();
    const setSidebarCollapsed = () => {
        props.setSidebarCollapsed(!props.sidebarCollapsed)
    };
    const chat = useSelector(state => state).chatNotificationsReducer;
    const preferences = useSelector(state => state).preferencesReducer;
    const {changePanelProfile, textPanel, redirectToPanel, redirectToPanelByParam, typePanel} = useVerifyShowPanel(history ,preferences.panel_profile)

    const [menuSelected, setMenuSelected] = useState('1');
    const {hasSineService, hasCraftyService, hasClubService,hasOnlySine,hasOnlyCrafty} = useVerifyServices();
    const [registerModal, setRegisterModal] = useState(false);

    useEffect(() => {
        if (window.location.pathname === '/') {
            setMenuSelected('1');
        } else if(window.location.pathname === '/painel/cliente/vagas' || window.location.pathname === '/painel/cliente/vagas/') {
            setMenuSelected('6')
        }  else if (window.location.pathname.includes('/painel/prestador')) {
            setMenuSelected('2')
        } else if (window.location.pathname.includes('/painel/cliente')) {
            setMenuSelected('2.1')
        } else if (window.location.pathname.includes('/quero-contratar')) {
            setMenuSelected('3')
        } else if(window.location.pathname.includes('/estabelecimentos')) {
            setMenuSelected('4')
        } else if(window.location.pathname.includes('/curriculos')) {
            setMenuSelected('5')
        } else if(window.location.pathname.includes('/trabalhar')) {
            setMenuSelected('9')
        }  else if(window.location.pathname.includes('/vagas-de-emprego')) {
            setMenuSelected('7')
        } else if(window.location.pathname.includes('/buscar-cursos')) {
            setMenuSelected('8')
        } else if((window.location.pathname.includes('/cursos') || window.location.pathname.includes('/pdf') || window.location.pathname.includes(`/editais`)) && window.location.pathname !== '/cursos-do-evento' ) {
            setMenuSelected('9')
        } else if(window.location.pathname.includes('/vagas-do-evento')) {
            setMenuSelected('11')
        } else if(window.location.pathname.includes('/empresas')) {
            setMenuSelected('12')
        } else {
            setMenuSelected('')
        }
    },[window.location.pathname]);

    return (
        <Layout.Header className='headerMain' style={{ background: '#fff', padding: 0 }}>
            <Row className='headerPage' style={{display: 'flex', justifyContent: 'center'}}>
                {/*<Col xs={24} sm={24} md={24} lg={22} xl={23} xxl={17} className='right-side-col'>*/}
                <Col xs={24} md={23} className='right-side-col'>

                    <div>
                        <div className='logo' style={{padding: 1, paddingLeft: 5, paddingRight: 10}} onClick={() => redirectToPanel()}>
                            <img src={Images.logo_header_smallest} alt={'logo'}/>
                        </div>
                        <div className='navigation hideMobile1150'>
                            <Menu
                                selectedKeys={[menuSelected]}
                                mode="horizontal"
                                style={{ lineHeight: '64px', display: 'inline-block' }}
                            >
                                {!isLogged() &&
                                    <Menu.Item key="1" onClick={() =>{ history.push('/'); setMenuSelected('1')}} >
                                        <FaHome className={'exportedIconsLeft'}/> Home
                                    </Menu.Item>
                                }
                                {isLogged() && <Menu.SubMenu
                                    title={
                                        <span style={{fontSize:16}} className="submenu-title-wrapper">
                                            <MdDashboard className={'exportedIconsLeft'} /> Painel
                                        </span>
                                    }
                                >
                                    <Menu.ItemGroup title="Escolha o Painel">
                                        <Menu.Item onClick={() =>{ redirectToPanelByParam(); setMenuSelected('2')}}  key="2">Painel Trabalhador</Menu.Item>
                                        <Menu.Item onClick={() =>{ redirectToPanelByParam(true); setMenuSelected('2.1')}} key="2.1">Painel Contratante</Menu.Item>
                                    </Menu.ItemGroup>
                                </Menu.SubMenu>}

                                {(hasCraftyService()  && preferences?.panel_profile !== 'worker') &&
                                    <Menu.Item key="3" onClick={() => {history.push('/quero-contratar'); setMenuSelected('3')}}>
                                        <FaTools className={'exportedIconsLeft'}/> <span> Prestadores</span>
                                    </Menu.Item>
                                }
                                {hasClubService() &&
                                    <Menu.Item key="4" onClick={() => {history.push('/estabelecimentos'); setMenuSelected('4')}}>
                                        <AiTwotoneShop className={'exportedIconsLeft'}/> Buscar Estabelecimentos
                                    </Menu.Item>
                                }

                                {(hasSineService() && preferences?.panel_profile !== 'worker') &&
                                    <Menu.Item key="5" onClick={() => {history.push('/curriculos'); setMenuSelected('5')}}>
                                        <RiFolderUserFill className={'exportedIconsLeft'}/> Buscar currículos
                                    </Menu.Item>
                                }
                                {(!isLogged() || preferences?.panel_profile === 'worker')&&
                                    <Menu.Item  className={'workerHeader'} key="7" onClick={() => {history.push('/vagas-de-emprego');setMenuSelected('7')}}>
                                        <FaSearch className={'exportedIconsLeft'}/> Vagas
                                    </Menu.Item>
                                }
                                {(eventVisible() && (!isLogged() || preferences?.panel_profile === 'worker'))&&
                                    <Menu.Item  className={'event-header line'} key="11" onClick={() => {history.push(`/vagas-do-evento`);setMenuSelected('11')}}>
                                        <FaRocket className={'exportedIconsLeft'}/>
                                        <span>Mulheres</span>
                                    </Menu.Item>
                                }
                                {/*{(eventVisible() && (!isLogged() || preferences?.panel_profile === 'worker'))&&*/}
                                {/*    <Menu.Item className={'event-course'} key="11" onClick={() => {history.push(`/cursos-do-evento`);setMenuSelected('11')}}>*/}
                                {/*        <FaBookOpen className={'exportedIconsLeft'}/> Cursos do Feirão*/}
                                {/*    </Menu.Item>*/}
                                {/*}*/}
                                {(!isLogged() || preferences?.panel_profile === 'worker') &&
                                    <Menu.Item className={'workerHeaderBlue'} key="8" onClick={() => {history.push('/buscar-cursos');setMenuSelected('8')}}>
                                        <FaBook className={'exportedIconsLeft'}/>
                                        Cursos
                                    </Menu.Item>
                                }
                                <Menu.Item className={'workerHeaderBlue'} key="9" onClick={() => {
                                    window.gtag&& window.gtag('event', 'editalClick');
                                    history.push('/editais');
                                    setMenuSelected('9')
                                }}> <FaFilePdf className={'exportedIconsLeft'}/> Editais </Menu.Item>

                                <Menu.Item className={'workerHeaderBlue'} key="12" onClick={() => {history.push('/empresas');setMenuSelected('12')}}>
                                    <FaBuilding className={'exportedIconsLeft'}/>
                                    Empresas
                                </Menu.Item>
                            </Menu>
                        </div>
                        {profile !== null ?
                            <div className='right-side'>
                                <div className='header-widget hideMobile500'>
                                    {/*<Popover placement={"bottom"} content={'Perguntas Frequentes'}>
                                        <div style={{cursor: 'pointer'}} className='header-notifications' onClick={() => {history.push('/ajuda')}}>
                                           <Icon type='question-circle'/>
                                        </div>
                                    </Popover>*/}

                                    <div className='header-notifications' style={{cursor: "pointer"}}>
                                        <Link className={'iconHeader'} to={'/notificacoes'}>
                                            <Badge style={{fontSize: 10, height: 18}} count={notificationCount > 0? notificationCount : 0}>
                                                <Icon type='bell'/>
                                            </Badge>
                                        </Link>
                                    </div>

                                </div>
                                <div className='header-user-widget hideMobile500'>
                                    <Dropdown overlay={
                                        <div className='dropdown-profile'>
                                            <div style={{display: 'flex', padding:20}}>
                                                <Badge offset={[-15,37]} style={{height: 9, width: 9}} status="success">
                                                    <img className='user-avatar'  height={42} width={42} style={{backgroundSize: 'cover', borderRadius:21, marginRight: 10, backgroundImage: profile?.avatar? `url(${profile?.avatar})` : `url(${Images.user_place_holder_2})`}}/>
                                                </Badge>
                                                <div style={{display: 'flex', flexFlow: 'column', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    <span style={{fontSize: 16, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{profile?.name}</span>
                                                    <span style={{fontSize: 14, color: '#888', overflow: 'hidden', textOverflow: 'ellipsis'}}>{profile?.email}</span>
                                                </div>
                                            </div>

                                            <Divider style={{margin:0}}/>

                                            <Menu className='menu-options w-100' >
                                                <Menu.Item key="1" className='option' >
                                                    <Link to={`/painel/${typePanel === 'client' ? 'cliente' : 'prestador'}/perfil`}>
                                                        <Icon type={'user'}/> Visualizar Perfil
                                                    </Link>
                                                </Menu.Item>
                                                {hasClubService() &&
                                                    <Menu.Item  key="2" className='option' >
                                                        <Link to={'/painel/cartoes'}>
                                                            <Icon type={'credit-card'}/> Meus cupons
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                                {hasClubService() &&
                                                    <Menu.Item  key="3" className='option'>
                                                        <Link to={'/painel/compras'}>
                                                            <Icon type={'shop'}/> Minhas compras
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                                <Menu.Item key={'4'} className='de-flex option' onClick={() => {changePanelProfile()}}>
                                                    <FiRefreshCw size={16} style={{marginRight: 4}}/>
                                                    <span> Alterar p/ painel {textPanel} </span>
                                                </Menu.Item>

                                                <Menu.Item className='logout' onClick={() => {logoutWithoutApi(); dispatch({type: 'logout'});}}>
                                                    <FiLogOut size={18}/>
                                                    <span>
                                                        Sair
                                                    </span>
                                                </Menu.Item>
                                            </Menu>
                                        </div>
                                    }>
                                        <Badge offset={[-8,50]} style={{height: 9, width: 9}} status="success"><img className='user-avatar'
                                             style={{backgroundSize: 'cover', backgroundImage: profile?.avatar? `url(${profile?.avatar})` : `url(${Images.user_place_holder_2})`}}/>
                                        </Badge>
                                    </Dropdown>
                                </div>
                                <div className='menu-icon showMobile1151'>
                                    {!!props.setSidebarCollapsed &&
                                    <Icon
                                        className="sidebar-trigger"
                                        type={props.sidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
                                        onClick={setSidebarCollapsed}
                                    />
                                    }
                                </div>
                            </div>
                            :
                            <div className='right-side ' style={{display: 'flex', alignItems: 'center'}}>

                                {/*<div className='header-widget hideMobile500' style={{borderRight: '1px solid #e0e0e0'}}>
                                    <Popover placement={"bottom"} content={'Perguntas Frequentes'}>
                                        <div style={{cursor: 'pointer'}} className='header-notifications' onClick={() => {history.push('/ajuda')}}>
                                            <Icon type='question-circle'/>
                                        </div>
                                    </Popover>
                                </div>*/}
                                <div className={'headerAuthButtons'}>
                                    <Button type={"primary"} onClick={() => setRegisterModal('login')}  style={{borderRadius: 3, marginRight: 20}}> Entrar </Button>
                                    <Button className={'ant-btn-primary2 hideMobile500'} onClick={() => setRegisterModal('regType')} style={{borderRadius: 3, color: 'white'}}> Cadastre-se </Button>
                                </div>
                                <div className='menu-icon showMobile1151'>
                                    {!!props.setSidebarCollapsed &&
                                    <Icon
                                        className="sidebar-trigger"
                                        type={props.sidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
                                        onClick={setSidebarCollapsed}
                                    />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            <RegisterPageModal visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />
        </Layout.Header>
    );
};

Header.propTypes = {
    sidebarCollapsed: PropTypes.bool,
    setSidebarCollapsed: PropTypes.func,
    paddingLogo: PropTypes.any
};

export default Header;
