const authReducer = (state = null, action) => {
    switch (action.type) {
        case 'change_avatar' :
            return {...state, avatar: action.avatar};
        case 'init_login' :
            return {...action.data};
        case 'update_user' :
            return {...state, ...action.data};
        case 'logout' :
            return null;
        default :
            return state
    }
}

export default authReducer;
