import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Button,
    Col,
    DatePicker,
    Form,
    Icon,
    Input,
    List,
    message,
    Modal,
    Popover,
    Radio,
    Row,
    Select,
    Tag
} from "antd";
import useApi from "../../../../Hooks/Api";
import {useValidationShorts} from "../../../../Hooks/Helpers";
import moment from "moment";
import {Images} from "../../../../Helpers/Images";
import locale from 'antd/es/date-picker/locale/pt_BR';
import {FaBookmark, FaClock, FaPhone, FaThumbsDown} from "react-icons/fa";
import {FaThumbsUp} from "react-icons/all";
import {Env} from "../../../../Helpers/Env";
import useLocalStorage from "../../../../Hooks/Storage";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";
import useApiClub from "../../../../Hooks/ApiClub";
import {newFeedbackTypeRejected} from "../../../../Helpers/AuxVariables";



const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}

const FeedbackStepsModal = props => {
    const feedbackPreference = useLocalStorage(StorageVariables.userPreferencesFeedback);
    const {getFieldDecorator, getFieldError, getFieldsValue, setFieldsValue, validateFields} = props.form;
    const api = useApi();
    const apiClub = useApiClub();
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(undefined);
    const [intermediateStatus, setIntermediateStatus] = useState(props.profile.status || `none`);
    const [finalStatus, setFinalStatus] = useState((props.profile.status === `approved` || props.profile.status === `rejected`) ? props.profile.status : `none`);
    const [feedbackSent, setFeedbackSent] = useState((props.profile.status === `approved` || props.profile.status === `rejected`))

    const sendFeedback = async () => {
        validateFields(async (err, values) => {
            if(err) return 0;
            if(!selectedValue) {
                message.warning('Escolha um tipo de retorno');
                return 0;
            }
            message.loading('Enviando feedback');
            if (values.date_admission) {
                values.date_admission =  moment(values.date_admission, 'DD/MM/YYYY').format('YYYY-MM-DD');
            } else if(selectedValue === 1) {
                values.date_admission = moment().format('YYYY-MM-DD');
            }

            let apiUrl = '';
            setLoading(true);
            values.last_event = selectedValue;
            newFeedbackTypeRejected.forEach((item) => {
                if (item.type === selectedValue) values.feedback_result = item.value;
            })
            if(selectedValue === 1) {
                values.feedback_result = 'Trabalhador Contratado';
            }

            if(selectedValue !== 1) {
                feedbackPreference.setObject({last_event: values.last_event, feedback_result: values.feedback_result})
            }

            if(values.qualifications && !values.observation) {
                values.observation = values.qualifications;
            }
            if(props.external) {
                apiClub.post(`formal-working/job-opportunity/${props.id}/interest/${props.profile.interest_id}/feedback`, values, (res) => {
                    message.destroy();
                    message.success('Seu feedback foi enviado! ');
                    setLoading(false);
                    setFeedbackSent(true)
                    window.gtagService('event', 'externalFeedBackSuccess');
                    props.getData();
                }, (e) => {
                    if(e.message === 'Request failed') {
                        message.error('Não foi possível enviar esse feedback para o SINE, tente novamente.');
                    } else if(e.message === 'Você alcançou o limite de recrutamentos sem retorno. Conclua seus recrutamentos pendentes para voltar a consultar.') {
                        message.error('Você alcançou o limite de recrutamentos sem retorno. Envie o feedback dos currículos em GERENCIAR VAGAS para continuar consultando.');
                    } else {
                        message.error(e.message || 'Não foi possivel enviar o feedback deste profissional');
                    }
                    setLoading(false);
                    window.gtagService('event', 'externalFeedBackFail');
                })
            } else {
                if(props.vacancyFeedback) {
                    apiUrl = `sine/user/${props.id}/interest-status/${props.profile.interest_id}`;
                } else {
                    apiUrl = `sine/contact/${props.sine_id}/send-feedback`;
                }
                api.post(apiUrl, values, (res) => {
                    message.destroy();
                    message.success('Seu feedback foi enviado! ');
                    setLoading(false);
                    setFeedbackSent(true)
                    window.gtagService('event', 'feedBackSuccess');
                    props.getData();
                }, (e) => {
                    message.destroy();
                    if(e.message === 'Request failed') {
                        message.error('Não foi possível enviar esse feedback para o SINE, tente novamente.');
                    } else if(e.message === 'Você alcançou o limite de recrutamentos sem retorno. Conclua seus recrutamentos pendentes para voltar a consultar.') {
                        message.error('Você alcançou o limite de recrutamentos sem retorno. Envie o feedback dos currículos em GERENCIAR VAGAS para continuar consultando.');
                    } else {
                        message.error(e.message || 'Não foi possivel enviar o feedback deste profissional');
                    }
                    setLoading(false);
                    window.gtagService('event', 'feedBackFail');
                });
            }
        })
    }

    const handleFinalDecision = (item) => {
        if(item === `approved`) {
            setSelectedValue(1);
        } else {
            setSelectedValue(feedbackPreference.getObject()?.last_event || undefined)
        }
        setFinalStatus(item);
    }

    const handleBack = (item) => {
        setFinalStatus(item)
    }

    useEffect(() => {
        if(props.showFeedback) handleFinalDecision(props.showFeedback)
    },[props.showFeedback])

    useEffect(() => {
        if(intermediateStatus !== `none` && finalStatus !== `approved` && finalStatus !== `rejected`) props.handleStepChange(intermediateStatus)
    },[intermediateStatus])

    const {required} = useValidationShorts();

    return (
        <div className={`feedbackSideStep custom-scrollbar`}>
            {feedbackSent?
                <div style={{padding: 20, paddingTop: 20, background: 'white', height:'100%'}}>
                    <div>
                        <div style={{textAlign: 'center', fontSize: 20}}>
                            <span style={{color: '#424242', fontSize: 20}}> Retorno enviado com sucesso! </span> <br/>
                            <span style={{color: '#424242', fontSize: 20}}> Candidato <strong style={{color: finalStatus === `approved`? `#6bbe1d` : `#fe7979`}}> {finalStatus === `approved`? `APROVADO` : `REPROVADO`} </strong>  </span>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            <img style={{height: '100%', maxHeight: 320}} src={Images.manageResumes}/>
                        </div>
                        <div>
                            <span style={{fontSize: 11, color: 'grey'}}>
                                *Os Retornos fornecidos somente são utilizados para estatísticas de empregabilidade, não vinculando a empresa ou empregador a nenhum outro banco de dados público ou privado.
                            </span>
                        </div>
                    </div>
                </div>
                :
                <div style={{padding: 16, paddingTop: 16, background: 'white', borderRadius: 5, height:'100%'}} className='content-side-step'>
                    {finalStatus !== `none` &&<div style={{padding: 0, fontSize: 15, color: `#595959`}} className={'back-side-button'} onClick={()=>handleBack('none')}>
                        <Icon className={'craftyIcon'} type={'arrow-left'}/>
                        <span> Voltar </span>
                    </div>}
                    {(finalStatus === `none` && props.profile?.opportunity_id) &&
                        <>
                            <div>
                                <div style={{textAlign: "center", marginBottom: 20}}>
                                 <span style={{fontSize: 18, fontWeight: 'bold'}}>
                                    Etapa atual do Candidato
                                </span>
                                    <br/>
                                    {(props.profile.opportunity_name) && <span style={{fontWeight: "bold", color: '#9e9e9e'}}> {'(' + props.profile.opportunity_name} - #{props.profile.opportunity_id + ')'} <br/> </span>}
                                    <small style={{color: 'gray'}}> Toque em uma etapa para trocar </small>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <div onClick={() => setIntermediateStatus('pending')} className={intermediateStatus === 'pending' ? 'cardButton cardButtonBlueActive' : 'cardButton cardButtonBlue'} style={{padding: 10, width: 100, borderRadius: 5, backgroundColor: 'white', margin: 10, boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', flexFlow: 'column', justifyContent: 'center'}}>
                                        <div>
                                            <FaClock style={{fontSize: 30}}/>
                                        </div>
                                        <div>
                                            <span> Pendente </span>
                                        </div>
                                    </div>
                                    <div onClick={() => setIntermediateStatus('selected')} className={intermediateStatus === 'selected' ?  'cardButton cardButtonPurpleActive' : 'cardButton cardButtonPurple' } style={{padding: 10, width: 100, borderRadius: 5, backgroundColor: 'white', margin: 10, boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', flexFlow: 'column', justifyContent: 'center'}}>
                                        <div>
                                            <FaBookmark style={{fontSize: 30}}/>
                                        </div>
                                        <div>
                                            <span> Análise </span>
                                        </div>
                                    </div>
                                    <div onClick={() => setIntermediateStatus('interview')} className={intermediateStatus === 'interview' ? 'cardButton cardButtonPearlActive' : 'cardButton cardButtonPearl'} style={{padding: 10, width: 100, borderRadius: 5, backgroundColor: 'white', margin: 10, boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', flexFlow: 'column', justifyContent: 'center'}}>
                                        <div>
                                            <FaPhone style={{fontSize: 30}}/>
                                        </div>
                                        <div>
                                            <span> Entrevista </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div style={{height: 1, width: '100%', marginTop: 15, marginBottom: 15, background: 'gainsboro'}}/>
                        </>}
                    <div style={{paddingBottom:10}}>
                        <div style={{textAlign: "center", margin: "8px auto", maxWidth: 300}}>
                            {props.profile.opportunity_id ? <span style={{fontSize: 16, fontWeight: 'bold'}}>
                                Ou informe a decisão final sobre o candidato nesta vaga
                            </span>  : <span style={{fontSize: 16, fontWeight: 'bold'}}>
                             Nos envie o retorno da situação do candidato
                        </span>}<br/>
                        </div>

                        <div style={{display: "flex"}}>
                            <div className={finalStatus === `approved`&& `statusApprovedButton`} onClick={() => handleFinalDecision('approved')}
                                 style={{
                                     padding: 10, cursor: "pointer",
                                     flex: 1,
                                     borderRadius: 5,
                                     backgroundColor: 'white',
                                     margin: 10,
                                     boxShadow: '#ececec 1px 1px 3px 1px',
                                     borderRight: '2px solid rgb(107, 190, 29)',
                                     borderLeft: '2px solid rgb(107, 190, 29)',
                                     display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}
                            >
                                <div style={{marginRight: 10}}>
                                    <FaThumbsUp style={{color: '#6bbe1d', fontSize: 20}}/>
                                </div>
                                <div>
                                    <span> Contratado </span>
                                </div>
                            </div>
                            <div className={finalStatus === `rejected`&& `statusRejectedButton`} onClick={() => handleFinalDecision('rejected')} style={{padding: 10,  cursor: "pointer", flex: 1, borderRadius: 5, backgroundColor: 'white', margin: 10, boxShadow: '#ececec 1px 1px 3px 1px', borderLeft: '2px solid rgb(254, 121, 121)', borderRight: '2px solid rgb(254, 121, 121)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{marginRight: 10}}>
                                    <FaThumbsDown style={{color: '#fe7979', fontSize: 20}}/>
                                </div>
                                <div>
                                    <span> Reprovado </span>
                                </div>
                            </div>
                        </div>

                        <div style={{backgroundColor: 'white'}}>
                            {(finalStatus === 'rejected') &&<div>
                                <div style={{marginBottom: 10}}>
                                <span style={{fontSize: 15, marginLeft:5, fontWeight: 'bold'}}>
                                    Informe o motivo
                                </span>
                                    <div style={{display: 'flex', flexDirection: 'row', flexWrap : 'wrap'}}>
                                        {newFeedbackTypeRejected.map((item, index) =>
                                            <div style={{padding: 5,cursor: 'pointer', fontWeight: selectedValue === item.type ? 'bold' : 'normal', backgroundColor: selectedValue === item.type ? 'aliceblue' : 'white', color: selectedValue === item.type ? '#fe7979' : '#757575', borderRadius: 5, border: selectedValue === item.type ?  '1px solid #fe7979' : '1px solid gainsboro' , margin: 5, paddingRight: 10, paddingLeft: 10}} key={index}
                                                 onClick={() => {setFieldsValue();setSelectedValue(item.type)}}>{item.label || item.value}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {selectedValue === 11 &&
                                    <Form.Item required={false} style={{padding: 5}} label="Selecione a qualificação que não foi atendida">
                                        {getFieldDecorator('qualifications', {
                                            rules: [required()],
                                        })(
                                            <Select style={{width: `100%`}} placeholder="Selecione aqui">
                                                <Select.Option value={'Capacitação'}>Capacitação</Select.Option>
                                                <Select.Option value={'Distância entre residência e local de trabalho'}>Distância entre residência e local de trabalho</Select.Option>
                                                <Select.Option value={'Apresentação pessoal'}>Apresentação pessoal</Select.Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                }

                                {(selectedValue && selectedValue !== 1) &&
                                    <Form.Item style={{padding: 5}} label="Descreva com mais detalhes o motivo se necessário">
                                        {getFieldDecorator('observation',{

                                        })(
                                            <Input.TextArea rows={3} />
                                        )}
                                    </Form.Item>}

                            </div>}
                            <div style={{textAlign: 'right', color: `#9e9e9e`, fontSize: 12}}><span>
                            *Os Retornos fornecidos somente são utilizados para estatísticas de empregabilidade, não vinculando a empresa ou empregador a nenhum outro banco de dados público ou privado.
                        </span></div>
                            {finalStatus !== `none` &&<div style={{display: `flex`, marginTop: 10}}>
                                <Button onClick={() => sendFeedback()} className={'ant-btn-primary'} style={{marginLeft: `auto`}}> <Icon type='check' style={{margin: 0}}/> Confirmar </Button>
                            </div>}
                        </div>
                    </div>
                </div>}
        </div>
    );
};

FeedbackStepsModal.propTypes = {
    user: PropTypes.any,
    handleSave: PropTypes.any,
    addressToEdit: PropTypes.any,
    showFeedback: PropTypes.any,
    setShowFeedback: PropTypes.any
};

export default Form.create({name: 'feedbackStepForm'})(FeedbackStepsModal);
