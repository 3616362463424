import React, {memo, useState, useEffect} from 'react';
import {Col, Row, Input} from "antd";
import PropTypes from "prop-types";
import useLocalStorage from "../../Hooks/Storage";
import {StorageVariables} from "../../Helpers/StoragesVariables";

const WhatsNewComponent = (props) => {

    const [menuSelected, setMenuSelected] = useState(0);
    const helpStorage = useLocalStorage(StorageVariables.helpVacancy);

    useEffect(() => {
        if(!helpStorage.getPrimitive()) {
            helpStorage.setPrimitive(true)
        }
    },[])

    return (
        <Row style={{background: 'white', borderRadius: 5}}>
            <Col xs={24} md={6} style={{height: '100%', padding: 10}}>
                <div style={{padding: 10}}>
                    <span style={{fontWeight: "bold", fontSize: 30,}}> O que oferecemos!</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {props.list.map((item, index) =>
                        <div style={{color: index === menuSelected? '#007dff' : 'gray',padding: 10, }}>
                            <a onClick={() => setMenuSelected(index)} style={{fontWeight: index === menuSelected? 'bold': 'normal', }}>
                                {item.title}
                            </a>
                        </div>
                    )}
                </div>
            </Col>
            <Col xs={24} md={18} style={{height: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div  style={{flex: 1, display: "flex", justifyContent: "center"}}>
                        <img src={props.list[menuSelected].img} style={{ maxWidth: '100%', borderTopRightRadius: 5, objectFit: 'contain'}}/>
                    </div>
                    <div style={{padding: 20, background: '#f5f5f5', borderBottomRightRadius: 20}}>
                        <span style={{fontSize: 20}}>{props.list[menuSelected].subTitle}</span>
                        <p style={{color: '#737373'}}>{props.list[menuSelected].description}</p>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

WhatsNewComponent.propTypes = {
    list: PropTypes.array,
    form: PropTypes.any,
    help: PropTypes.string,
    rules: PropTypes.array,
    label: PropTypes.any,
    message: PropTypes.any,
    size: PropTypes.any,
    type: PropTypes.any,
    required: PropTypes.any,
    error: PropTypes.any,
    valueForMemo: PropTypes.any,
    initialValue: PropTypes.any,
    suffix: PropTypes.any,
    disabled: PropTypes.any
};

export default WhatsNewComponent;
