import React from "react";
import {RiAppsLine} from "react-icons/ri";
import {Col} from "antd";
import {FaExclamation, FaFirefoxBrowser, FaUser} from "react-icons/fa";
import {FaTrash} from "react-icons/all";

const RemoveAccount = () => {
    return (
        <div className="d-flex justify-content-center h-100 my-5" style={{minHeight: 600, background: '#f8f9fc'}}>
            <Col xs={24} md={12} style={{display: "flex", flexFlow: 'column', justifyContent: 'center',}}>
                <div className="d-flex justify-content-center my-3" style={{marginBottom: 20}}>
                    <span style={{fontSize: 30, color: "#00435c"}}>
                        Como <span style={{color: "#ff5f5f"}}>deletar</span> / <span style={{color: "#ff5f5f"}}>desativar</span> minha conta?
                    </span>
                </div>
                <div className="mb-5">
                    <div>
                        <span style={{fontSize: 20, color: "#ffffff", background: "#146e95", padding: "2px 10px", borderRadius: 5, fontWeight: 600}}>
                            Deletar / desativar minha conta no app :
                        </span>
                    </div>
                    <div>
                        <ul>
                            <li className="my-2" style={{fontSize: 20, color: "black"}}>
                                Na tela inicial do app, clique no botão no canto <span style={{color: "#146e95", fontWeight: 600}}> inferior direito</span>, clique em perfil <FaUser color="#146e95" style={{marginTop: -3}}/> .
                            </li>
                            <li className="my-2" style={{fontSize: 20, color: "black"}}>
                                Clique na categoria:<span style={{color: "#146e95", fontWeight: 600}}> > Meu perfil</span>.
                            </li>
                            <li className="my-2" style={{fontSize: 20, color: "black"}}>
                                Clique na opção:<span style={{color: "red", fontWeight: 600}}> <FaExclamation  style={{marginTop: -3}}/> Excluir minha conta</span>.
                            </li>
                            <li className="my-2" style={{fontSize: 20, color: "black"}}>
                                Insira seu <span style={{color: "#146e95", fontWeight: 600}}> CPF</span> e clique no botão para desabilitar sua conta.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="mt-5">
                    <div>
                        <span style={{fontSize: 20, color: "#ffffff", background: "#63b422", padding: "2px 10px", borderRadius: 5, fontWeight: 600}}>
                            Deletar / desativar minha conta no site :
                        </span>
                    </div>
                    <div>
                        <ul>
                            <li className="my-2" style={{fontSize: 20, color: "black"}}>
                                Clique na foto de perfil, no canto superior direito.
                            </li>
                            <li className="my-2" style={{fontSize: 20, color: "black"}}>
                                Clique na categoria:<span style={{color: "#146e95", fontWeight: 600}}>> Meu perfil</span>.
                            </li>
                            <li className="my-2" style={{fontSize: 20, color: "black"}}>
                                Clique no botão:<span style={{color: "red", fontWeight: 600}}> <FaTrash  style={{marginTop: -3}}/> Excluir minha conta</span>.
                            </li>
                        </ul>
                    </div>
                </div>
            </Col>
        </div>
    )
}
export default RemoveAccount
