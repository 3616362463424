import React, {useEffect, useRef} from 'react'

const InfiniteScroll = ({fetchMore, paginate}) => {

  const containerRef = useRef()
  useEffect(()=>{
    const options = {
      root:null,
      rootMargin: '0px',
      threshold: 1
    }

    const scrollObserver = new IntersectionObserver(([entry]) =>{
      if (entry.isIntersecting) {
        scrollObserver.disconnect()
        fetchMore()
      }
    }, options);
    scrollObserver.observe(containerRef.current)

    return () => {
      scrollObserver.disconnect()
    }
  },[paginate, fetchMore])


  return <div ref={containerRef}/>
}

export default InfiniteScroll
