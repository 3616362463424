import React, {useEffect, useRef, useState} from 'react';
import {Col, message, Row, Spin} from "antd";
import ServiceProviderHeader from "../Components/PageComponents/Public/ServicePage/ServiceProviderHeader";
import ServiceProviderMain from "../Components/PageComponents/Public/ServicePage/ServiceProviderMain";
import ServiceProviderExtras from "../Components/PageComponents/Public/ServicePage/ServiceProviderExtras";
import useApi from "../Hooks/Api";
import ServiceForm from "../Components/PageComponents/Public/ServicePage/ServiceForm";
import {Images} from "../Helpers/Images";
import {Env} from "../Helpers/Env";
import {useSelector} from "react-redux";


const ServicePage = (props) => {
    const user = useRef();
    const reviewsPaginate = useRef({page: 1});
    const [reviews,setReviews] = useState([]);
    const [reviewLoading, setReviewLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const api = useApi({customReturn: true, loadingControl: false ,debounceTimer: 0});
    const occupation = useRef({});
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [requestContactLoading, setRequestContactLoading] = useState(false);
    const occupationInfo = useRef();
    const profile = useSelector((state) => state).authReducer;

    const getData = async (id, cb) => {
        setLoading(true);
        api.get('user/'+id+'/full', (res) => {
            user.current = res;
            for(let i = 0; i < res.occupations.length; i++) {
                if (res.occupations[i].occupation_id.toString() === props.match.params.occupation) {
                    occupation.current = res.occupations[i];
                }
            }
            if(res.profile.requested_contact_id) {
                getUserContact(res.profile.requested_contact_id);
            }
            setLoading(false);
        },(e) => {
            setLoading(false);
            message.warning('Ops, não foi possível recuperar os dados do profissional, tente novamente mais tarde')
        });
    };

    const getReviews = async () => {
        setReviewLoading(true);
        api.get(`user/${props.match.params.id}/reviews?page=${reviewsPaginate.current?.page}&profile=worker&type=received&occupation_id=${props.match.params.occupation}`,(reviewsRes) => {
            reviewsPaginate.current = {nextPage: reviewsRes.next_page, page: reviewsRes.page + 1};
            let aux = reviews.concat(reviewsRes.reviews);
            setReviews(aux);
            setReviewLoading(false);
        },(e) => {
            message.warning('Não foi possível recuperar as avaliações deste profissional )=')
            setReviewLoading(false);
        });

    };

    const getUserContact = (id) => {
        setRequestContactLoading(true);
        api.get(`worker-contact/${id}`, (res) => {
            setPhoneNumber(res.worker.cellphone_number);
            setRequestContactLoading(false);
        }, (e) => {
            setRequestContactLoading(false);
            console.log(e)
        })
    }

    const requestContact = async () => {
        if (user) {
            window.gtagService('event', 'workerContactTouch');
            setRequestContactLoading(true);
            let sendObj = {
                application: Env.application_alias,
                occupation_id: props.match.params.occupation,
                worker_id: props.match.params.id
            };
            api.post(`worker-contact/request`, sendObj, (res) => {
                user.current.profile.requested_contact_id = res?.worker_contact_id;
                getUserContact(res?.worker_contact_id);
            }, (e) => {
                setRequestContactLoading(false);
                console.log(e)
            })
        }
    }

    useEffect(() => {
        if(props.match.params.id) {
            getData(props.match.params.id);
            getReviews();
        }
        window.scrollToElement('body');
    },[profile]);

    return (
        <Row style={{minHeight: '500px', display: 'flex', justifyContent: 'center'}}> {(loading)?
            <Spin style={{marginTop: '15%'}}/>
            :
            <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17} style={{backgroundColor: 'white'}}>
                <ServiceProviderHeader headerImg={occupationInfo.current?.group?.image?.android} actualOccupation={occupation.current} avatar={user.current?.profile?.profile_picture?.full || Images.user_place_holder}  userRating={user.current?.profile?.worker_ratings_avg} userRatingCount={user.current?.profile?.worker_ratings_count} name={user.current?.profile?.first_name + ' ' + user.current?.profile?.last_name}/>
                <Row gutter={[32,32]}>

                    <Col xs={24} sm={24} md={0}>
                        <ServiceProviderExtras requestContactLoading={requestContactLoading} phoneNumber={phoneNumber} requestContact={(item) => requestContact(item)} occupations={user.current?.occupations} actualOccupation={occupation.current}/>
                    </Col>

                    <Col xs={24} sm={24} md={16} xl={18} style={{padding: 20}}>
                        <ServiceProviderMain  actualOccupation={occupation.current} lastSeen={user.current?.profile?.last_seen} reviewsLoading={reviewLoading} loadMore={() => getReviews()} nextPage={reviewsPaginate.current?.nextPage} occupationName={occupation.current?.name} reviews={reviews} address={user.current?.addresses} bio={user.current?.profile?.bio}/>
                    </Col>

                    <Col xs={0} sm={0} md={8} xl={6}>
                        <ServiceProviderExtras requestContactLoading={requestContactLoading} phoneNumber={phoneNumber} requestContact={(item) => requestContact(item)} occupations={user.current?.occupations} actualOccupation={occupation.current}/>
                    </Col>
                </Row>
            </Col>
            }
        </Row>
    );
};

ServicePage.propTypes = {};

export default ServicePage;
