import React, { useEffect, useState } from 'react';
import useApiClub from '../../Hooks/ApiClub';
import {Button, Form, Progress} from 'antd';
import InputType from './Components/InputType';
import {FcFinePrint, MdClose, MdKeyboardArrowRight} from 'react-icons/all'
import {Fade} from "react-reveal";
import moment from "moment/moment";
import {Images} from "../../Helpers/Images";
import {FaArrowRight} from "react-icons/fa";

const Survey = (props) => {
    const api = useApiClub({ survey: true });
    const [loading, setLoading] = useState(false);
    const [formsOpen, setFormsOpen] = useState([]);
    const { getFieldDecorator, validateFields, setFieldsValue, getFieldsValue } = props.form;
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [allQuestions, setAllQuestions] = useState([]);
    const [showSurveyDialog, setShowSurveyDialog] = useState(false);
    const [percent, setPercent] = useState(0);
    const [loadingQuestion, setLoadingQuestion] = useState(false);

    const showPopUpSurvey = () => {
        setTimeout(() => {
            setActiveQuestion(true)
            setShowSurveyDialog(true)
        },[60000])
    }

    const isValidDate  = () => {
        return {
            validator: (rule, value) => moment(value, 'DD/MM/YYYY', true).isValid() !== false,
            message: <div style={{fontSize: 14, paddingTop: 5}}>Não é uma data válida.</div>
        }
    }

    const getData = () => {
        api.get('survey/request/unread/list', (res) => {
            if (res.object.length > 0) {
                showPopUpSurvey();
                setFormsOpen(res.object[0]);
            }
        },
        (e) => {
            console.log(e);
        });
    };

    const filterObjectForm = (e) => {
        let aux = [];
        let activeIndex = -1;
        e.object.form.form_sections.map((item) =>
            item.form_section_fields.map((sub) =>
                aux.push({
                    ...sub,
                    title_section: item.description,
                    index: (activeIndex = activeIndex + 1),
                    label_key: `field_${sub.form_section_field_id}`,
                    message_error: 'Campo obrigatório',
                })
            )
        );
        setAllQuestions(aux);
        setActiveQuestion(aux[0]);
    };

    const handleAnswerForm = () => {
        window.gtagService('event', 'survey_data', {
            'survey_step': `started`,
        })
        setLoadingQuestion(true)
        let request_id = formsOpen.request_id
        api.get(`survey/request/${request_id}`, (res) => {
            setShowSurveyDialog(false)
            filterObjectForm(res)
            setLoadingQuestion(false)
        }, (err) => {
            setLoadingQuestion(false)
            window.gtagService('event', 'survey_data', {
                'survey_error': err.message,
            })
            console.log(err);
        });
    };

    const handleIgnoreForm = () => {
        let request_id = formsOpen.request_id
        api.get(`survey/request/${request_id}`, (res) => {
                setActiveQuestion(null)
            }, (err) => {
                console.log(err);
            }
        );
        window.gtagService('event', 'survey_data', {
            'survey_step': `ignored`,
        })
    }

    const setValue = (label, value) => {
        setFieldsValue({ [label]: value });
        if(activeQuestion.type === 'SELECT' || activeQuestion.type === 'RADIO') {
            setTimeout(() => {
                handleNext();
            },[500])
        }
    };

    const handleBack = () => {
        if (activeQuestion.index > 0) {
            setActiveQuestion(allQuestions[activeQuestion.index - 1]);
        }
    }

    const handleNext = () => {
        validateFields([activeQuestion.label_key], (error) => {
            if (error) {
            } else {
                let lastIndex = allQuestions[activeQuestion.index].index + 1
                let resultPercent = (lastIndex / allQuestions.length).toFixed(2) * 100
                setPercent(resultPercent)
                setActiveQuestion(allQuestions[activeQuestion.index + 1]);
            }
        });
    };

    const handleSubmit = () => {
        validateFields((error, values) => {
            if (error) {
                window.gtagService('event', 'survey_data', {
                    'survey_form_error': error,
                })
            } else {
                setLoading(true)
                let request_id = formsOpen.request_id
                let filterValue = Object.values(values);

                for (let i = 0; i < Object.keys(values).length; i++) {
                    if (typeof filterValue[i] === 'object') {
                        let deleteKey = Object.keys(values)[i];
                        values = { ...values, ...filterValue[i] };

                        delete values[deleteKey];
                    }
                }

                api.post(`survey/request/${request_id}`, values, (res) => {
                    let lastIndex = allQuestions[activeQuestion.index].index + 1
                    let resultPercent = (lastIndex / allQuestions.length).toFixed(2) * 100
                    setPercent(resultPercent)
                    setShowSurveyDialog(true)
                    setLoading(false)
                    window.gtagService('event', 'survey_data', {
                        'survey_step': `done`,
                    })
                }, (err) => {
                    setLoading(false)
                    console.log(err)
                    window.gtagService('event', 'survey_data', {
                        'survey_error': err.message,
                    })
                })
            }
        });
    };

    useEffect(() => {
        getData();
    }, []);

    if (activeQuestion === null) return null;
    return (
        <div className="survey">
            <img style={{height: '100%', position: 'absolute', opacity: 0.1, right: 20, bottom: -30}} src={Images.survey}/>
            <span onClick={handleIgnoreForm}>
                <MdClose className='icon-close' size={20}/>
            </span>
            {showSurveyDialog ?
                (activeQuestion.index +1 === allQuestions.length) ?
                <Fade>
                    <div className='finished-screen'>
                        <div>
                            <Progress strokeLinecap="square" width={80} type="dashboard" percent={100} />
                        </div>
                        <div>
                            <h4>Muito obrigado por responder nossa pesquisa!</h4>
                            <span>Sua contribuição é importante para nos ajudar a melhorar cada vez mais sua experiência (;</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <button className='button-next' style={{zIndex:1}} onClick={() => handleIgnoreForm()}>Fechar</button>
                    </div>

                </Fade> :

                <Fade>
                    <div className="list-forms-open" style={{marginBottom: 10}}>
                        <h3>
                            <span className='d-flex align-items-center' style={{gap:10}}>
                                <img style={{height: 30}} src={Images.survey}/> {formsOpen?.form?.title}
                            </span>
                            <div style={{textAlign:"justify"}}>
                                <span>{formsOpen?.form?.description}</span>
                            </div>
                        </h3>
                    </div>

                    <div className='btns-dialog'>
                        <button className='accept-answer' onClick={handleAnswerForm}>
                            <span style={{marginRight: 5}}>
                             Começar
                            </span>
                            <FaArrowRight size={15}/>
                        </button>
                    </div>
                </Fade>
                :
                <Form onSubmit={(e) => e.preventDefault()}>
                    <div className='d-flex align-items-center ' style={{gap:10, marginRight: 15}}>
                        <Progress strokeLinecap="square" showInfo={false}  status={"active"} percent={percent} />
                    </div>
                    <div style={{marginTop: 5, marginBottom: 15}}>
                        <label style={{fontSize: 16, fontWeight: 'bold'}} htmlFor={activeQuestion.label_key}>
                            {activeQuestion.label} {activeQuestion.mandatory && <span style={{color:'red'}}>*</span>}
                        </label>
                    </div>
                    <Form.Item>
                        {activeQuestion.type === 'TEXT' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                    ],
                                })(
                                    <InputType.Text updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                    propertyField={activeQuestion}
                                                    setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'RADIO' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    id: activeQuestion.label_key,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                    ],
                                })(
                                    <InputType.Radio updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                      propertyField={activeQuestion}
                                                      setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'SELECT' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    id: activeQuestion.label_key,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                    ],
                                })(
                                    <InputType.Select updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                      propertyField={activeQuestion}
                                                      setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'CHECKBOX' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                    ],
                                })(
                                    <InputType.Checkbox updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                        propertyField={activeQuestion}
                                                        setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'TEXTAREA' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                    ],
                                })(
                                    <InputType.TextArea updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                        propertyField={activeQuestion}
                                                        setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'INTEGER' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                    ],
                                })(
                                    <InputType.NumberInteger updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                             propertyField={activeQuestion}
                                                             setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'IMAGE' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: 'Selecione uma imagem',
                                        },
                                    ],
                                })(
                                    <InputType.Image updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                     propertyField={activeQuestion}
                                                     setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'DATE' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                        isValidDate(),
                                    ],
                                })(
                                    <InputType.Date updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                    propertyField={activeQuestion}
                                                    setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'DATETIME' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                    ],
                                })(
                                    <InputType.DateTime updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                        propertyField={activeQuestion}
                                                        setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'TIME' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                    ],
                                })(
                                    <InputType.Time updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                    propertyField={activeQuestion}
                                                    setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'DECIMAL' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                    ],
                                })(
                                    <InputType.Decimal updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                       propertyField={activeQuestion}
                                                       setValue={(e) => setValue(activeQuestion.label_key, e)}
                                    />
                                )}
                            </>
                        )}

                        {activeQuestion.type === 'EMAIL' && (
                            <>
                                {getFieldDecorator(activeQuestion.label_key, {
                                    preserve: true,
                                    rules: [
                                        {
                                            required: activeQuestion.mandatory,
                                            message: activeQuestion.message_error,
                                        },
                                        {
                                            type: 'email',
                                            message: 'E-mail inválido',
                                        },
                                    ],
                                })(
                                    <InputType.Email updatedValueRunMemo={getFieldsValue()[activeQuestion.label_key]}
                                                     propertyField={activeQuestion}
                                                     setValue={(e) => setValue(activeQuestion.label_key, e.length > 0 ? e : undefined)}
                                    />
                                )}
                            </>
                        )}
                    </Form.Item>

                    {(activeQuestion.type !== 'SELECT' && activeQuestion.type !== 'RADIO') &&<div className="d-flex justify-content-between">
                        {activeQuestion.index > 0 ?
                            <Button onClick={() => handleBack()} type={"link"} style={{color:'#1680ce', paddingLeft: 0, zIndex:1}}>Voltar</Button> : <div/>
                        }
                        {activeQuestion.index === allQuestions.length - 1 ?
                            <Button onClick={handleSubmit} type={'secondary'} style={{zIndex:1}} loading={loading}>Finalizar Pesquisa</Button> :
                            <button className='button-next' style={{zIndex:1}} onClick={() => handleNext()}>Próximo</button>
                        }
                    </div>}
                </Form>
            }
        </div>
    );
};

export default Form.create({ name: 'surveyForm' })(Survey);
