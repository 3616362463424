import React, {useEffect, useState, useMemo, useRef} from "react";
import {Row, Select, Tooltip, Button, Radio} from 'antd';
import {AiFillAppstore, AiTwotoneShopping} from "react-icons/ai";
import {Images} from "../Helpers/Images";
import {Col} from "antd";
import {Input} from 'antd';
import useApi from "../Hooks/Api";
import {Spin} from 'antd';
import {useHistory} from "react-router-dom";
import {MdArrowForward} from "react-icons/all";
import useAuth from "../Hooks/Auth";
import {useSelector} from "react-redux";

const Companies = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingC, setLoadingC] = useState(false);
    const [text, setText] = useState("");
    const [companies, setCompanies] = useState([]);
    const history = useHistory();
    const isInitialMount = useRef(true);
    const user = useSelector((state) => state).authReducer;
    const api = useApi();

    const divColors = useMemo(() => generateRandomColors(), []);

    const getData = (pagination) => {
        setLoading(true);
        api.get(`/sine/search_companies?limit=20&page=${currentPage}&query=${text}`, (res) => {
                if (pagination === true) {
                    if(res.next_page === true) {
                        let aux = companies;
                        res.companies.forEach((item) => {aux.push(item);})
                        setCompanies(aux);
                        setLoading(false);
                    }
                } else {
                    setCurrentPage(1)
                    setCompanies(res.companies)
                }
                setLoading(false)
            },
            (e) => {
                setLoading(false);
            }
        );
    };


    function generateRandomColors() {
        const colors = ["#01689C", "#85BADA", "#86BCD8", "#0C3897", "#285A99", "#4FA4A8", "#007581"];
        const colorsArray = [];
        for (let i = 0; i < 1000; i++) {
            let index = Math.floor(Math.random() * colors.length);
            colorsArray.push(colors[index]);
        }
        return colorsArray;
    }

    function handleDivClick(event, pagination) {
        const clickedDivId = event.currentTarget.id;
        console.log(clickedDivId)
        setLoading(true);
        api.get(`/sine/search_companies?limit=20&order_by=${clickedDivId}&page=${currentPage}&query=${text}`, (res) => {
                if (pagination === true) {
                    if(res.next_page === true) {
                        let aux = companies;
                        res.companies.forEach((item) => {aux.push(item);})
                        setCompanies(aux);
                        setLoading(false);
                    }
                } else {
                    setCurrentPage(1)
                    setCompanies(res.companies)
                }
                setLoading(false)
            },
            (e) => {
                setLoading(false);
            }
        );
        const divs = document.getElementsByClassName("container-ordenation");
        for (let i = 0; i < divs.length; i++) {
            divs[i].classList.remove("selected");
        }
        event.currentTarget.classList.add("selected");
        localStorage.setItem("selectedDiv", clickedDivId);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingC(false);
        }, 1000);

        return () => {
            clearTimeout(timer);
            setLoadingC(true);
        };
    }, [currentPage]);

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(entries => {
            if (entries.some(entry => entry.isIntersecting)) {
                setCurrentPage((currentValue) => currentValue + 1);
            }
        })
        intersectionObserver.observe(document.querySelector('#scrollMore'));
        return () => intersectionObserver.disconnect();
    }, []);

    useEffect(() => {
        if (text !== "") {
            const timer = setTimeout(() => {
                setLoading(true);
                getData(false);
            }, 1000);

            return () => {
                clearTimeout(timer);
                setLoading(false);
            };
        }
    }, [text]);

    useEffect(() => {
        getData(true);
    }, [currentPage])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (text === "") {
                getData()
                setCompanies([]);
            }
        }
    }, [text]);

    useEffect(() => {
        const selectedDiv = localStorage.getItem("selectedDiv");
        if (selectedDiv) {
            const element = document.getElementById(selectedDiv);
            if (element) {
                element.classList.add("selected");
            }
        }
    }, []);

    return (
        <div>
            <div style={{marginTop: "50px", width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Row  style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Col md={22} lg={20} style={{borderBottom: '1px solid gainsboro', paddingBottom: 30}}>
                        <div style={{display: "flex"}}>
                            <span className="text-title-companies">
                                Busque por uma empresa em nossa plataforma
                            </span>
                        </div>
                        <span style={{color: 'gray'}}>
                            Procura alguma empresa específica? busque aqui e veja as vagas desta empresa!
                        </span>

                        <div className="container-search">
                            <div className="search">
                                <Input placeholder="Busque pelo nome" onChange={(e) => setText(e.target.value)} value={text}/>
                            </div>
                            {loading &&<div className="button-search">
                                <Spin spinning={loading === true}/>
                            </div>}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <Col md={22} lg={20} style={{ marginTop: 25 }}>
                    <div style={{margin: 5}}>
                        <span style={{fontSize: 20}}>
                            Ordernar por:
                        </span>
                    </div>

                    <div style={{display: "flex"}}>
                        <div onClick={handleDivClick} id="job_openings" className="container-ordenation">
                            Qtd. de vagas
                        </div>
                        {user &&
                            <div onClick={handleDivClick} id="distance" className="container-ordenation">
                                Distância
                            </div>
                        }
                    </div>
                </Col>
            </div>
            <div className="cards">
                <Col style={{display: companies.length === 0 ? "none" : "flex", }} md={22} lg={20} className="container-companies">
                    <Spin spinning={loadingC}>
                        {companies?.map((item, index) =>
                            <Col  span={24} md={12} lg={12} xl={8} style={{padding: 10}}  onClick={() => history.push(`/empresa/${item.id}`)}>
                                <div className='card'  style={{background: divColors[index]}}>
                                    <div className="infos">
                                        <div className="photo-perfil">
                                            <div style={{height: "80px", width: "80px"}}>
                                                <img src={item.category === null ? Images.companie : item.category.image} className="img"/>
                                            </div>
                                            <div className="container-name">
                                                <span className="text-name">{item.business_name}</span>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <div className="container-description">
                                                <p className="description">
                                                    {item.description === null ? "" : item.description}
                                                </p>
                                            </div>
                                            <div className="container-infos">
                                                <div>
                                                    <Tooltip title="Categoria">
                                                        <div className="info">
                                                            <div className="container-text-info">
                                                                <AiFillAppstore size={16} color="#2b5f8a"/>
                                                            </div>
                                                            <div className="container-text-info">
                                                            <span style={{color: "#2a5f8a"}}>
                                                                {item.category === null ? "Categoria não definida" : item.category.description}
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                    <Tooltip title="Quantidades de vagas abertas na empresa">
                                                        <div className="info" style={{background: item.total_job_openings === 0? '#f1f1f1' : "#edfff3", borderColor: item.total_job_openings === 0? 'lightgrey' : '#1fc659'}}>
                                                            <div className="container-text-info">
                                                                <AiTwotoneShopping size={16} color={item.total_job_openings === 0? '#939393' :"#1fc659"}/>
                                                            </div>
                                                            <div className="container-text-info">
                                                            <span style={{color: item.total_job_openings === 0? '#939393' : "#20c659"}}>
                                                                {item.total_job_openings === 0 ? "Nenhuma vaga" : item.total_job_openings === 1 ? "1 vaga" : `${item.total_job_openings} vagas`}
                                                            </span>

                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    <div onClick={() => history.push(`/empresa/${item.id}`)} style={{display: 'flex', backgroundColor: divColors[index], cursor: 'pointer', color: 'white', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '45px', height: '45px'}}>
                                                        <MdArrowForward size={16}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )}
                        <div id="scrollMore"></div>
                    </Spin>
                </Col>
                <Col style={{display: companies.length === 0 ? "flex" : "none", flexDirection: "column", alignItems: "center"}}>
                    <div style={{marginTop: "30px", fontSize: "30px"}}>
                        <span>
                            Nenhum resultado!
                        </span>
                    </div>
                    <div>
                        <img src={Images.resume_not_found} style={{width: "100%"}}/>
                    </div>
                </Col>
            </div>
        </div>
    )
}
export default Companies
