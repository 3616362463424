import React from 'react';
import {Modal,} from "antd";
import {Images} from "../../../../Helpers/Images";
import {FaRegThumbsDown, FaRegThumbsUp, FaSadCry, FaSmile} from "react-icons/all";


const EndVacancyModal = ({showEndVacancy, setShowEndVacancy, infoOnly, showFeedbackModal, handleEndVacancy}) => {
    return (
            <Modal visible={showEndVacancy !== 'hidden'} bodyStyle={{background: 'linear-gradient(178deg, #dff7e6, #ffffff 50%)'}} footer={null} onCancel={() => setShowEndVacancy('hidden')}>
                <div>
                    <h3 style={{color:'#212f35', marginBottom: 0, marginTop: -8}}> Você está prestes a encerrar sua vaga </h3>
                    <span style={{fontSize: 12, color: '#828282'}}>
                         Após encerrar, não será mais possível aceitar candidatos e todos os currículos que não foram aprovados serão reprovados para esta vaga.
                    </span>
                </div>
                <div style={{textAlign: "center"}}>
                    <div>
                        <img style={{maxHeight: 300}} src={Images.deal3}/>
                        <h3 style={{fontWeight: 'bold'}}>Conseguiu preencher sua vaga?</h3>
                        <span>E aí, conseguiu encontrar trabalhadores para sua vaga? </span>

                        <div>
                            <div style={{
                                paddingTop: 25,
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <div onClick={() => setShowEndVacancy('hired')}   className={showEndVacancy === 'hired'? 'cardButton cardButtonGreenActive' :  'cardButton cardButtonGreen cardButtonGreenIcon'} style={{padding: 10,  borderRadius: 5, backgroundColor: 'white', margin: 10, boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FaSmile style={{fontSize: 15, marginRight: 5}}/>
                                    <div>
                                        <span> Consegui preencher </span>
                                    </div>
                                </div>
                                <div onClick={() => handleEndVacancy({vacancy_not_filled: true, vacancy_filled_external: false, vacancy_filled_internal: false})}  className={'cardButton cardButtonRed cardButtonRedIcon'} style={{padding: 10,  borderRadius: 5, backgroundColor: 'white', margin: 10, boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FaSadCry style={{fontSize: 15, marginRight: 5}}/>
                                    <div>
                                        <span> Não consegui </span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {showEndVacancy === 'hired' && <div style={{paddingTop: 15, overflow: 'hidden', position: 'relative', paddingBottom: 10, marginTop: 20, backgroundColor: '#dff7e6', borderRadius: 5}}>
                            <h3 style={{color: '#08b039', fontWeight: 'bold'}}>Encontrou algum trabalhador pela nossa plataforma?</h3>
                            <span>Se ajudamos a preencher a vaga através de nossa plataforma diga pra gente, isso nos ajuda muito! </span>

                            <div>
                                <div style={{
                                    paddingTop: 25,
                                    display: "flex",
                                    justifyContent: "center"
                                }}>

                                    <div onClick={() => {
                                        showFeedbackModal({vacancy_filled_internal: true, informed_hiring_qtd: 1, vacancy_filled_external: false, vacancy_not_filled: false})
                                    }}  className={'cardButton cardButtonGreen cardButtonGreenIcon'} style={{padding: 10,  borderRadius: 5, backgroundColor: 'white', margin: 10, boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <FaRegThumbsUp style={{fontSize: 15, marginRight: 5}}/>
                                        <div>
                                            <span> Encontrei pela plataforma </span>
                                        </div>
                                    </div>

                                    <div onClick={() => {
                                        handleEndVacancy({vacancy_filled_internal: false, vacancy_filled_external: true, vacancy_not_filled: false})
                                    }}  className={'cardButton cardButtonRed cardButtonRedIcon'} style={{padding: 10,  borderRadius: 5, backgroundColor: 'white', margin: 10, boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <FaRegThumbsDown style={{fontSize: 15, marginRight: 5}}/>
                                        <div>
                                            <span>Não foi pela plataforma </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </Modal>
    );
};

EndVacancyModal.propTypes = {};

export default EndVacancyModal;
