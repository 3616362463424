import React, {useEffect, useState} from 'react'
import {Dropdown, Menu} from "antd";
import {FaAngleDown} from "react-icons/fa";

const SySelect = ({data, setValue, value}) => {
    const [selectedValue, setSelectedValue] = useState({})

    const getData = () => {
        if (data?.default_option === null) {
            let found = data?.form_section_field_options.find(el => el.form_section_field_option_id === value)
            let aux = {
                label: found?.answer,
                value: value
            }
            setSelectedValue(aux)
        } else {
            let found = data?.default_option?.form_section_field_options.find(el => el.form_section_field_option_id === value);
            let aux = {
                label: found?.answer,
                value: value
            }
            setSelectedValue(aux)
        }
    }

    const handleSelectOption = (e) => {
        let aux = {
            label: e.children,
            value: e.value
        }
        setValue(e.value)
        setSelectedValue(aux)
    }

    const MenuOptions = () => {
          return (
              <Menu style={{maxHeight:400, overflow:'auto'}}
                    selectedKeys={selectedValue?.value && `[${selectedValue?.value}]`}
                    onClick={(e) => handleSelectOption(e.item.props)}
              >
                  {data?.default_option === null ?
                      data?.form_section_field_options?.map((item) => (
                          <Menu.Item key={`${item.form_section_field_option_id}`} value={item.form_section_field_option_id}>
                              {item.answer}
                          </Menu.Item>
                      ))
                      :
                      data?.default_option?.form_section_field_options?.map((item) => (
                          <Menu.Item key={`${item.form_section_field_option_id}`} value={item.form_section_field_option_id}>
                              {item.answer}
                          </Menu.Item>
                      ))
                  }
              </Menu>
          )
    }

    useEffect(() => {
        if (value) {
            getData()
        } else {
            setSelectedValue({})
        }
    },[data])
    return (
        <div>
            <div>
                <Dropdown overlay={MenuOptions} trigger={['click']} placement={'topLeft'}>
                    <div className='select-custom'>
                        {selectedValue?.label ?  <>{selectedValue?.label}</> : <span style={{color:'#8f8e8e'}}>Selecione uma opção</span>} <FaAngleDown size={16}/>
                    </div>
                </Dropdown>
            </div>
        </div>
    )
}

export default SySelect
