import React from 'react'
import {Radio} from "antd";

const SyRadio = ({data, setValue, value}) => {
    return (
        <div>
            <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
                {data.default_option === null ?
                    <>
                        {data.form_section_field_options.map((item, index) => (
                            <Radio key={index} value={item.form_section_field_option_id}>{item.answer}</Radio>
                        ))}
                    </>
                    :
                    <>
                        {data.default_option.form_section_field_options.map((item, index) => (
                            <Radio key={index} value={item.form_section_field_option_id}>
                                {item.answer}
                            </Radio>
                        ))}
                    </>
                }
            </Radio.Group>
        </div>
    )
}

export default SyRadio
