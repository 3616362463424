import ReactGA from "react-ga4";
import {useState} from "react";
import {routesArray} from "../routes/routes";
import {Env} from "./Env";


const useEvents = () => {
    const [pathUser, setPathUser] = useState([]);

    const notificationRecommendedWorker = (type) => {
        let sendToGoogle = {}
        if (type === 'show') {
            sendToGoogle = {
                "ver_candidatos": "Clicado"
            }

        } else if (type === 'hidden-notification-show') {
            sendToGoogle = {
                "ver_candidatos": "Clicado na Tela de Fechamento"
            }

            console.log('hidden-notification-show')
        } else if (type === 'closed-icon') {
            sendToGoogle = {
                "fechar_notificacao": "X"
            }
            console.log('closed-icon')
        } else if (type === 'close-icon-confirm-notification') {
            sendToGoogle = {
                "fechar_notificacao" : "X - Clicado Novamente"
            }
            console.log('close-icon-confirm-notification')
        } else if (type === 'dontShow') {
            sendToGoogle = {
                "fechar_notificacao": "Nao Mostrar Novamente"
            }
            console.log('close-icon-confirm-notification')
        } else {
            sendToGoogle = {
                "evento": type
            }
        }

        ReactGA.event("Notificacao_Trabalhadores_Recomendados",
            {...sendToGoogle, ...Env.actual_page}
        )

    }

    const sendPath = () => {
        let aux = undefined;
        let previousPath = pathUser

        if (/\d+/.test(window.location.pathname)) {
            let removeNumberUrl = window.location.pathname.replace(/\/\d+\b/g, '')
            aux = routesArray.filter((route) => removeNumberUrl.includes(route.page_path));
        } else {
            aux = routesArray.filter((route) => window.location.pathname.includes(route.page_path));
        }

        if(aux) {
            aux = aux.pop();
            if (previousPath.length > 0) {
                aux.page_referrer =  previousPath.pop()
            }
            previousPath.push(aux.page_path)

            setPathUser(previousPath)

            Env.actual_page = aux;
            ReactGA.send({ hitType: "pageview", ...aux });
        }
    }

    const gaEvents = {
        notificationRecommendedWorker: (type) => notificationRecommendedWorker(type),
        sendPath: () => sendPath(),
    }

    return {gaEvents}
}


export default useEvents
