import React from 'react'
import {Images} from "../../Helpers/Images";
import {Env} from "../../Helpers/Env";
import {Tooltip} from "antd";

const ContactUs = () => {

  return (

        <Tooltip placement='topRight' title='Fale conosco'>
          <div className='contact-us-component' style={{cursor:"pointer"}}>
            <a target='_blank' href={window.innerWidth < 768? 'https://wa.me/' + Env.number : 'https://web.whatsapp.com/send?phone=' + Env.number}>
              <img className='icon-call' src={Images.callOperator}/>
            </a>
          </div>
        </Tooltip>
  )
}

export default ContactUs
