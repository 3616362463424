
import React, {useRef, useState, useCallback, useEffect} from "react";

import _ from "lodash";
import moment from "moment";
import useApi from "../../Hooks/Api";
import {Fade} from "react-reveal";
import {Images} from "../../Helpers/Images";
import {useSelector} from "react-redux";
import {globalMaskReal} from "../../Helpers/Functions";
import {Col, Drawer, Icon, Popover, Row} from "antd";
import Loading from "../../Components/Generic/Loading";
import WorkerOpportunityDrawer from "../Components/WorkerOpportunityDrawer";
import {useHistory} from "react-router-dom";
import WorkPageBanner from "./Components/WorkPageBanner";
import InfiniteScroll from "../Components/InfiniteScroll";

import FilterSearchOpportunityComponent from "./Components/FilterSearchOpportunityComponent";
import {FaFilter, FaChild, FaSearch, FaTimes, FaBook} from "react-icons/fa";
import Indicator from "./Components/Indicator";
import {BiRefresh, FaWheelchair} from "react-icons/all";
import {Env} from "../../Helpers/Env";
import {eventVisible} from "../../Hooks/Helpers";
import BannerEvent from "./Components/BannerEvent";
import bannerImage from "../../Img/EventImages/month_women/main-banner-cropped.jpeg"

export function SearchOpportunityPage (props) {
    const user = useSelector((state) => state).authReducer;
    const api = useApi();
    const history = useHistory();
    const qtdOp = useRef({});
    const [queryObj] = useState(window.queryStringToObject())

    //Vacancy
    const opportunities = useRef([]);
    const activeOpportunity = useRef(null);

    //DRAWERS
    const [showDetails, setShowDetails] = useState(false)
    const [showDrawerFilter, setShowDrawerFilter] = useState(false)

    //LOADINGS
    const [loading, setLoading] = useState(true);
    const [loadingOpportunity, setLoadingOpportunity] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false)

    //FILTERS
    const paginateMyApplications = useRef({
        page: 0,
        status: ["pending", 'approved', 'rejected'],
        order: "desc"
    });
    const filters = useRef({})
    const pageController = useRef({})

    //AUTOCOMPLETE
    const [searchText, setSearchText] = useState('');

    const getMyApplicationsData = (more) => {
        let payload = paginateMyApplications.current
        more ? setLoadingMore(true) : setLoading(true);

        if (more) {
            if (pageController.current.next_page) {
                payload.page = pageController.current.page + 1
            }
        } else {
            payload.page = 1
            if (opportunities.current.length > 0) {
                opportunities.current = []
            }
            if (Object.keys(pageController.current).length > 0) {
                pageController.current = {}
            }
        }

        api.post(`sine/user/${user.user_id}/worker-interests`, payload, (res) => {
            res.interests.forEach((item) => item.is_candidate = true);
            pageController.current = {
                next_page: res.next_page,
                page: res.page,
                is_my_applications: true
            }
            opportunities.current = opportunities.current.concat(res.interests);
            more ? setLoadingMore(false) : setLoading(false);
        }, (e) => {
            more ? setLoadingMore(false) : setLoading(false);
            console.log(e);
        });
    };

    const getData = (more) => {
        let payload = filters.current
        more ? setLoadingMore(true) : setLoading(true);

        if (more) {
            if (pageController.current.next_page) {
                payload.page = pageController.current.page + 1
            }
        } else {
            payload.page = 1
            if (Object.keys(pageController.current).length > 0) {
                pageController.current = {}
            }
        }

        if (window.location.pathname.includes('/vagas-do-evento')) payload.event = Env.event_name

        let qs = window.objectToQueryString(payload)
        history.push(`${window.location.pathname}${qs}`)
        api.get(`sine/search_opportunities${window.objectToQueryString(payload, true)}`, (res) => {
            let aux;
            pageController.current = {
                next_page: res.next_page,
                page: res.page,
                is_my_applications: false
            }

            if (more) {
                aux = opportunities.current.concat(res.opportunities);
            } else {
                aux = res.opportunities
            }

            qtdOp.current.total_jobs_openings = res.total_job_openings;
            opportunities.current = aux;
            more ? setLoadingMore(false) : setLoading(false);
        }, (e) => {
            console.log(e);
        });
    };

    const infiniteScroll = () => {
        if (pageController.current.is_my_applications) {
            if (pageController.current.next_page === true) {
                getMyApplicationsData(true)
            }
        } else {
            if (pageController.current.next_page === true) {
                getData(true)
            }
        }

    }

    const handleChangeQuerySearch = (e) => {
        setSearchText(e);
        autoSearch(e)
    };

    const autoSearch = useCallback(_.debounce((e) => {
        filters.current = {...filters.current, query: e}
        window.scrollToElement('body')
        getData()
    }, 1000), [])

    const getOpportunity = (id, index, candidate) => {
        activeOpportunity.current = null;
        setShowDetails(true);
        setLoadingOpportunity(true);
        api.get(user?.user_id ? `sine/user/${user.user_id}/job-opportunity/${id}` : `/sine/job-opportunity/${id}`, (res) => {

            if (res.desired_skills_categories !== null) {
                res.desired_skills_categories?.forEach(el => res.desired_skills.push(el.title))
            }

            if (res.incentives_categories !== null) {
                res.incentives_categories?.forEach(el => res.incentives.push(el.title))
            }

            activeOpportunity.current = res;
            activeOpportunity.current.id = id;
            activeOpportunity.current.index = index;
            activeOpportunity.current.is_candidate = candidate;
            setLoadingOpportunity(false);
        }, (e) => {
            console.log(e)
            setLoadingOpportunity(false);
            setShowDetails(false);
        });
    };

    const removeFromList = (e) => {
        setLoading(true);
        setShowDetails(false);
        if (activeOpportunity.current?.index) {
            opportunities.current[activeOpportunity.current.index].is_candidate = true;
        }
        setLoading(false);
    };

    const closeOpportunity = () => {
        if (queryObj.id) {
            delete queryObj.id
        }
        if (eventVisible() && window.location.pathname === "/vagas-do-evento") {
            if (queryObj) {
                const urlParams = new URLSearchParams(queryObj);
                history.push(`/vagas-do-evento/?${urlParams}`);
            } else {
                history.push(`/vagas-do-evento`);
            }

        } else {
            if (queryObj) {
                const urlParams = new URLSearchParams(queryObj);
                history.push(`/vagas-de-emprego/?${urlParams}`);
            } else {
                history.push(`/vagas-de-emprego`);
            }
        }




        setShowDetails(false);
    };

    const clearQuery = () => {
        delete filters.current.query
        setSearchText('');
    }

    const clearInputSearchVacancy = () => {
        clearQuery()
        getData()
    }

    const openOpportunity = (a, b, c) => {
        queryObj.id = a;
        const urlParams = new URLSearchParams(queryObj);
        if (eventVisible() && window.location.pathname.includes("vagas-do-evento")) {
            history.push(`/vagas-do-evento?${urlParams}`)

        } else {
            history.push(`/vagas-de-emprego?${urlParams}`);
        }

        getOpportunity(a, b, c);
    }

    const filtersComponent = () => {
        return (
            <FilterSearchOpportunityComponent
                getData={() => getData()}
                getMyApplications={() => getMyApplicationsData()}
                user={user}
                setShowDrawer={() => setShowDrawerFilter(!showDrawerFilter)}
                payload={filters}
                pageController={pageController}
                search={searchText}
                clearQuery={() => clearQuery()}
                clearPageController={() => pageController.current = {}}
            />
        )
    }

    const formatIncentives = (item) => {
        let aux = [];


        for (let i = 0; i < item.incentives?.length; i++) {
            if (aux.length < 3) {
                aux.push(
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: 'wrap',
                        minHeight: 25,
                        backgroundColor: '#34c38f2e',
                        borderRadius: 4,
                        textAlign: "center",
                        padding: 6,
                        fontSize: 12,
                        lineHeight: 1
                    }}>
                        <span style={{fontWeight: 500, color: '#34c38f'}}>{item.incentives[i]}</span>
                    </div>
                );
            } else {
                break;
            }
        }

        for (let y = 0; y < item.incentives_categories?.length; y++) {
            if (aux.length < 3) {
                aux.push(
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: 'wrap',
                        minHeight: 25,
                        backgroundColor: '#34c38f2e',
                        borderRadius: 4,
                        textAlign: "center",
                        padding: 6,
                        fontSize: 12,
                        lineHeight: 1
                    }}>
                        <span style={{fontWeight: 500, color: '#34c38f'}}>{item.incentives_categories[y].title}</span>
                    </div>
                )
            } else {
                break;
            }
        }


        if ((item?.incentives_categories?.length + item.incentives?.length) > 3) {
            aux.push(<div style={{
                display: "flex",
                alignItems: "center",
                flexWrap: 'wrap',
                minHeight: 25,
                backgroundColor: '#34c38f2e',
                borderRadius: 4,
                textAlign: "center",
                padding: 6,
                fontSize: 12,
                lineHeight: 1
            }}>
                <span style={{fontWeight: 500, color: '#34c38f'}}>...</span>
            </div>)
        }
        return aux;
    };


    useEffect(() => {
        if (queryObj?.id) {
            getOpportunity(queryObj?.id, undefined, false)
        }
    }, []);

    return (
        <Row>



            <WorkerOpportunityDrawer setVisible={() => closeOpportunity()}
                                     removeFromList={(e) => removeFromList(e)}
                                     visible={showDetails}
                                     applied={activeOpportunity.current?.is_candidate}
                                     loading={loadingOpportunity}
                                     vacancy={activeOpportunity.current}
            />

            <Drawer placement={"left"}
                    visible={showDrawerFilter}
                    onClose={() => setShowDrawerFilter(!showDrawerFilter)}
                    bodyStyle={{padding: 0, height: '100%'}}
                    width={320}
                    closable={false}
            >
                <div style={{position: 'absolute', right: 3, top: 8, zIndex: 1}}>
                    <i style={{padding: 8, display: 'flex'}} onClick={() => setShowDrawerFilter(false)}><FaTimes
                        size={16}/></i>
                </div>
                {filtersComponent()}
            </Drawer>

            <Col className='vacancies' style={{background: `url(${Images.background_4})`}}>
                <Col xs={24} md={24} lg={23} xl={23} xxl={20}
                     style={{display: 'flex', flexWrap: 'wrap', gap: 5, padding: 0}}>
                    <div className='search-opportunity-filter-md' style={{position: 'relative', maxWidth: 320}}>
                        {filtersComponent()}
                    </div>

                    <div style={{backgroundColor: 'white', flex:1}}>

                        {(eventVisible() && window.location.pathname.includes('/vagas-do-evento')) &&
                            <div style={{marginTop: window.innerWidth < 976 && 10, marginBottom:16}}>
                                <BannerEvent isModal={false}
                                             banner={bannerImage}
                                />
                                <div style={{padding:"0 16px", marginTop:10, display: 'flex', justifyContent: 'end'}}>
                                    <button className={`button-event `} style={{backgroundColor: '#efd9ff', display: 'flex', flexFlow: 'column', color: '#bb76eb', alignItems: 'center'}} onClick={() => history.push("/cursos-do-evento")}>
                                        <FaBook size={20}/>
                                        Participe de cursos e <br/>oficinas
                                    </button>
                                </div>
                            </div>
                        }
                        {window.location.pathname.includes('/vagas-de-emprego') &&
                            <Indicator jobOpenings={qtdOp.current?.total_jobs_openings}/>
                        }

                        <div className='search-opportunity-query'>
                            {loading &&
                                <div style={{color: '#212529', marginBottom: 10, padding: '0 10px'}}>
                                    <span style={{fontSize: 20}}> Buscando... </span>
                                </div>

                            }

                            {(!pageController.current.is_my_applications && opportunities.current.length > 0 && !loading) &&
                            <div style={{color: '#212529', marginBottom: 10, padding: '0 10px'}}>
                                <h1 style={{fontSize: 20, fontWeight: 300}}>Mostrando <span
                                    style={{fontWeight: 600}}>{qtdOp.current?.total_jobs_openings}</span> vagas {searchText && <>para <span
                                    style={{fontWeight: 600}}>'{searchText}'</span></>} </h1>
                            </div>
                            }

                            <div className='search-filter'>
                                <div className='input-search'>
                                <span>
                                   <i> <FaSearch/></i>
                                </span>
                                    <input value={searchText}
                                           onChange={(e) => handleChangeQuerySearch(e.target.value)}
                                           type={'text'}
                                           placeholder={'Digite qual serviço você busca'}
                                    />
                                    {searchText.length > 0 &&
                                    <span>
                                            <i onClick={clearInputSearchVacancy}><FaTimes/></i>
                                        </span>
                                    }
                                </div>

                                <div style={{paddingRight: 10}}>
                                    <button onClick={() => setShowDrawerFilter(true)}
                                            className={`icon-filter-mobile ${showDrawerFilter ? 'active' : ''}`}>
                                        <i><FaFilter/></i>
                                    </button>
                                </div>

                            </div>
                        </div>

                        {(opportunities.current.length === 0 && !loading) &&
                        <Fade>
                            <div className={'notFoundComponent'} style={{marginTop: 30}}>
                                <div style={{marginBottom: 0}}>
                                    <span style={{fontSize: 25}}>Nenhum resultado </span> <br/>
                                    <span style={{fontSize: 22}}>
                                            {pageController.current.is_my_applications ? 'Você não enviou seu currículo para nenhuma vaga.' : 'No momento não existem vagas abertas para esta busca'} </span>
                                </div>
                                <div className={'imgDiv'}>
                                    <img src={Images.resume_not_found} alt={'Não encontrado'}/>
                                </div>
                            </div>
                        </Fade>
                        }

                        {loading ?
                            <div style={{margin: '20px 0'}}>
                                <Loading/>
                            </div> :
                            <div className={`d-flex flex-wrap ${loadingMore ? 'opacity-loading' : ''}`}>
                                {opportunities.current.map((item, index) =>
                                    <Col xs={24} md={12} lg={12} xl={8} key={index}
                                         onClick={() => openOpportunity(pageController.current.is_my_applications ? item.opportunity_id : item.id, index, item.is_candidate)}
                                         style={{padding: 10,}}>
                                        <div className={'vacancyHover'} style={{display:"flex", justifyContent:"space-between", flexDirection:"column"}}>
                                            <div>
                                                <div className='d-flex justify-content-between'
                                                     style={{marginTop: 5, height: 20}}>


                                                    <div className='d-flex flex-column'>
                                                      <span
                                                          style={{flex: 1, letterSpacing: 1, fontSize: 12, color: '#9e9e9e'}}>
                                                        {item.work_place === 'mobile' ? "Trabalho remoto/Home office" : (item.work_place === 'itinerant' ? "Sem endereço fixo" : (item.work_address ? item.work_address.city : null))}
                                                    </span>

                                                        {item?.postponed &&<span style={{color: '#03A9F4', fontSize: 12}}>
                                                        <BiRefresh style={{fontSize: 13, marginBottom: -2}}/>
                                                        <span> Inscrições adiadas</span>
                                                    </span>}
                                                    </div>
                                                    <span style={{
                                                        textAlign: 'right',
                                                        flex: 1,
                                                        fontSize: 12,
                                                        color: '#9e9e9e'
                                                    }}>
                                                        Publicado em {moment(item.start_date, 'YYYY-MM-DD').format('DD/MM')}
                                                    </span>
                                                </div>

                                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                    {item.job_type.includes("Aprendiz") &&
                                                        <div style={{padding: "5px"}}>
                                                            <Popover content={'Vaga para jovem aprendiz'}>
                                                                <FaChild/>
                                                            </Popover>
                                                        </div>
                                                    }
                                                    {item.disabilities_opt &&
                                                        <div style={{padding: "5px"}}>
                                                            <Popover content={'Vaga para PcD (Pessoa com deficiência)'}>
                                                                <FaWheelchair/>
                                                            </Popover>
                                                        </div>
                                                    }
                                                </div>
                                                {item.is_candidate && <img style={{
                                                    position: 'absolute',
                                                    right: 20,
                                                    opacity: 0.1,
                                                    bottom: 20,
                                                    width: 80,
                                                    height: 80
                                                }} src={Images.checked}/>}
                                                <div className='de-flex flex-column' style={{height: 250}}>
                                                    <div className='d-flex flex-column align-items-center'
                                                         style={{minHeight: 50}}>
                                                        <div>
                                                            <img style={{
                                                                backgroundColor: 'white',
                                                                width: 60,
                                                                borderRadius: 5
                                                            }} src={item.job_avatar ? item.job_avatar : Images.case_img}/>
                                                        </div>
                                                        <div style={{
                                                            fontWeight: 'bold',
                                                            fontSize: 16,
                                                            paddingTop: 10,
                                                            textAlign: "center"
                                                        }}>
                                                            {item.occupation_title ? item.occupation_title : item.occupation_name}
                                                        </div>

                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between'
                                                         style={{gap: 16, minHeight: 30}}>
                                                        <div>
                                                            <span style={{color: 'green', fontWeight: "bold"}}>R$</span>
                                                            <span style={{
                                                                fontSize: 13,
                                                                color: 'green'
                                                            }}> {item.pay ? globalMaskReal(item.pay) : 'Não Informado'} </span>
                                                        </div>
                                                        {item.job_type.length > 0 &&
                                                            <div>
                                                                <Icon color={'grey'} type={'clock-circle'}/>
                                                                <span style={{
                                                                    fontSize: 14,
                                                                    color: 'grey',
                                                                    marginLeft: 5,
                                                                }}>{item.job_type[0]} </span>
                                                            </div>
                                                        }
                                                        <span style={{fontSize: 14, color: 'grey'}}><span
                                                            style={{fontWeight: 'bold'}}>{item.job_openings}</span> Vaga(s) </span>
                                                    </div>
                                                    {item.is_candidate &&
                                                        <div className='d-flex justify-content-between'>
                                                            <div style={{alignItems: 'center', flex: 1,}}>
                                                                <Icon color={'grey'} size={15} name={'check-all'}/>
                                                                <span style={{
                                                                    fontSize: 14,
                                                                    fontWeight: 'bold',
                                                                    color: 'grey',
                                                                    flex: 1,
                                                                }}>CURRÍCULO ENVIADO </span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(item.incentives.length > 0 || item.incentives_categories?.length > 0) &&
                                                        <Col span={24} className='d-flex flex-wrap justify-content-center'
                                                             style={{padding: '10px 0', gap: 2, fontSize: 10, minHeight: 40}}>
                                                            {formatIncentives(item)}
                                                        </Col>
                                                    }
                                                </div>

                                                {item.job_description && (
                                                    <div style={{
                                                        borderTop: '1px solid gainsboro',
                                                        width: '100%',
                                                        wordBreak: 'break-word',
                                                        borderTopColor: 'gainsboro',
                                                        paddingTop: 10,
                                                        textAlign: "justify"
                                                    }}>
                                                    <span className={'limit-rows clamp-3'} style={{
                                                        color: '#808080c7',
                                                        fontSize: 13,
                                                    }}>{item.job_description} </span>
                                                    </div>)
                                                }
                                            </div>

                                            {/*{item.end_date &&*/}
                                            {/*    <div style={{margin:"8px 0"}}>*/}
                                            {/*        <ExpirationBadge date={item.end_date}/>*/}
                                            {/*    </div>*/}
                                            {/*}*/}
                                        </div>
                                        {(!loading && opportunities.current.length - 3 === index) &&
                                        <div>
                                            <InfiniteScroll paginate={filters.current}
                                                            fetchMore={() => !loadingMore && infiniteScroll()}
                                            />
                                        </div>
                                        }
                                    </Col>
                                )}
                            </div>
                        }
                    </div>
                </Col>

            </Col>
        </Row>
    );
}

const ExpirationBadge = (props) => {

    const badgeExpire = () => {
        const today = moment();
        const expire = moment(props.date);

        let colors = {
            background: "#cfe2ff",
            text: "#1660cd"
        }

        const days = expire.diff(today, 'days');


        if (days <= 2) {
            colors = {
                background: "#ffebee",
                text: "#f44336"
            }
        } else if (days <= 5) {
            colors = {
                background: "#fff8e1",
                text: "#ffb300"
            }
        }

       return {background: colors.background, color: colors.text};
    };

    return (
        <p style={{fontSize: 12, marginBottom: 0, textAlign: "end"}}>
            <span style={{
                padding: "2px 6px",
                borderRadius: "16px",
                ...badgeExpire()
            }}>
                Encerra {moment(props.date).fromNow()}
            </span>
        </p>
    );
};
