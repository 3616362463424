import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Input, message} from "antd";
import {Fade} from "react-reveal";
import {Images} from "../../Helpers/Images";
import {Env} from "../../Helpers/Env";
import useApiClub from "../../Hooks/ApiClub";
import useLocalStorage from "../../Hooks/Storage";
import {StorageVariables} from "../../Helpers/StoragesVariables";
import {useHistory} from "react-router-dom";



const ExternAccess = props => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const api = useApiClub({apiUrl: Env.api_bluetrix})
    const externAccessUser = useLocalStorage(StorageVariables.externUserAccess)
    const history = useHistory()

    const handleLogin = () => {
        if(login && password) {
            api.post('access/auth', {
                login, password
            },(res) => {
                console.log(res);
                Env.header_club.userToken = res.userToken;
                let aux = {...res.object, ...{userToken : res.userToken}}
                externAccessUser.setObject(aux);
                history.push('/acesso-externo/vagas-da-minha-empresa')
            }, (e) => {
                message.error(e.message || 'Não foi possível efetuar o login com sua conta');
            })
        }
    }

    return (
        <div  style={{paddingTop: '70px', width: 600, maxWidth: '100%',margin: 'auto'}}>
            <Fade mountOnEnter>
                <div>
                    <div className='loginFormMobile' style={{maxWidth: '100%', margin: "auto", padding: '30px', borderRadius: '10px'}}>
                        <>
                            <div className='logo' style={{marginBottom: 50, textAlign: 'center'}}>
                                <img style={{maxWidth: 288}} src={Images.logo} />
                            </div>
                            <div style={{textAlign: "center", margin: 20, fontSize: 20}}>
                                <span> Informe as Credenciais de acesso. </span>
                            </div>
                            <div>
                                <label style={{fontSize: 17, fontWeight: 'bold'}}> Login </label>
                                <Input className='defaultInput' size={'large'}
                                       placeholder={'Login'} value={login} onChange={(e) => setLogin(e.target.value)} suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}/>
                            </div>

                            <div>
                                <label style={{fontSize: 17, fontWeight: 'bold'}}> Senha  </label>
                                <Input.Password placeholder={'Insira a senha'} className='defaultInput' size={'large'}
                                       value={password} onChange={(e) => setPassword(e.target.value)} suffix={<Icon type="mail" className={'primary-sub-color'} style={{ fontSize: '17px'}}/>}/>
                            </div>


                            <Button loading={loading} style={{width: '100%', marginTop: 25}} size={'large'} onClick={() => handleLogin()}> Realizar Acesso </Button>
                        </>
                    </div>
                </div>
            </Fade>
        </div>
    );
};

ExternAccess.propTypes = {
    doForgotPassword: PropTypes.any,
    setForgot: PropTypes.any
};

export default ExternAccess;
