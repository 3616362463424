import React, {useEffect,useRef, useState} from 'react';
import {Button, Col, Icon, Menu, message, Modal, notification, Row,} from "antd";
import SubMenu from "antd/es/menu/SubMenu";

import moment from "moment";
import {useSelector} from "react-redux";

import {
    FaBullhorn,
    FaClipboardCheck,
    FaClipboardList, FaClock, FaExclamation,
    FaFilePdf,
    FaFolderOpen,
    FaHandPointer,
    FaKey, FaPaperPlane,
    FaPlusSquare,
    FaQuestionCircle,
    FaStoreAlt,
    FaUser,
} from "react-icons/fa";
import useApi from "../../../../../Hooks/Api";
import {Images} from "../../../../../Helpers/Images";
import Loading from "../../../../Generic/Loading";
import VacancyFormPage from "./VacancyFormComponent";
import VacancyListComponent from "./VacancyListComponent";
import {maskCnpj, maskCpf} from "../../../../../Helpers/Functions";
import VacancyDetailsComponent from "./VacancyDetailsComponent";
import RegisterEnterpriseComponent from "../../../../../Pages/RegisterEnterpriseComponent";
import MyRecruitmentsPage from "../MyRecruitmentsPage";
import {useHistory} from 'react-router-dom'
import useAuth from "../../../../../Hooks/Auth";
import Popover from "antd/es/popover";
import AllCandidatesListComponent from "./AllCandidatesListComponent";
import CandidateSearchModal from "../CandidateSearchModal";
import {BiRefresh, BsServer, MdClose, MdThumbUp} from "react-icons/all";
import {PrivateRoute} from "../../../../../Helpers/PrivateRoute";
import UserAccessPage from "./UserAccessPage";
import WhatsNewComponent from "../../../../Generic/WhatsNewComponent";
import {Env} from "../../../../../Helpers/Env";
import useLocalStorage from "../../../../../Hooks/Storage";
import {StorageVariables} from "../../../../../Helpers/StoragesVariables";
import {eventVisible} from "../../../../../Hooks/Helpers";
import VacancyEndFormComponent from "./VacancyEndFormComponent";
import RecommendListWorkerDrawer from "./Drawer/RecommendListWorkerDrawer";
import {Fade} from "react-reveal";
import useEvents from "../../../../../Helpers/Events";
import BannerEvent from "../../../../../Pages/SearchOpportunity/Components/BannerEvent";
import bannerEvent1 from '../../../../../Img/EventImages/month_women/banner-create-vaga.png';
import bannerEvent2 from '../../../../../Img/EventImages/month_women/banner1.png';

import VacancyCloseToClosingModal from "../../../../../Pages/Components/VacancyCloseToClosingModal";

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}

const VacancyManagePage = (props) => {

    const api = useApi();
    const user = useSelector((state) => state).authReducer;
    const helpStorage = useLocalStorage(StorageVariables.helpVacancy);
    const [loading, setLoading] = useState(true);
    const [smallLoading, setSmallLoading] = useState(false);
    const [rightLoading, setRightLoading] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(!helpStorage?.getPrimitive());
    const enterprise = useRef(null)
    const vacancyListRef = useRef([]);
    const [menuSelected, setMenuSelected] = useState("todos-curriculos")
    const endedVacancies = useRef([]);
    const history = useHistory();
    const {storeEnterpriseOpportunities, storeEnterprise, storeBlackListNotification} = useAuth();
    const [showApproveModal, setShowApproveModal] = useState(false);
    const multipleCompanies = useRef([]);
    const [changeEnterpriseModal, setChangeEnterpriseModal] = useState(false);
    const [statusVacancyDetails, setStatusVacancyDetails] = useState(undefined)
    const [addBusiness, setAddBusiness] = useState(false);
    const [showListRecommendedDrawer, setShowListRecommendedDrawer] = useState(false);
    const [listWorkerRecommended, setListWorkerRecommended] = useState([]);
    const [observer, setObserver] = useState({});
    const [vacancyList, setVacancyList] = useState([]);
    const [activeVacancy, setActiveVacancy] = useState(undefined);
    const blackListID = useLocalStorage(StorageVariables.hide_notification).getObject() || []
    const {gaEvents} = useEvents()
    const [modalVisible, setModalVisible] = useState(false)
    const [vacancyObj, setVacancyObj] = useState()
    const [complianceModal, setComplianceModal] = useState(false);
    const [closingInFewDays, setClosingInFewDays] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const previousDay = useLocalStorage(StorageVariables.previousDay);
    const [vacanciesList, setVacanciesList] = useState([]);

    const getVacancies = async (id) => {
        setRightLoading(true);
        api.get(`sine/company_opportunities/${id}?page=1&limit=20&closed=false`,(res) => {

            if (blackListID?.length > 0) {
                let aux = res.opportunities
                let filtered = []
                aux.forEach((item) => {
                    if (blackListID.indexOf(item.id) === -1) {
                        filtered.push(item)
                    }
                })
                setVacancyList(filtered)
            } else {
                setVacancyList(res.opportunities)
            }
            storeEnterpriseOpportunities(res.opportunities);
            setRightLoading(false);
            vacancyListRef.current = res.opportunities;
            setVacanciesList(res.opportunities);
            showNotificationCloseVacancy();
        },(e) => {
            setRightLoading(false);
            console.log(e);
        });
    };

    const checkEndDateVacancies = () => {
        if (vacancyListRef.current.length > 0) {
            let vacanciesToCheck = []
            vacancyListRef.current.forEach((item) => {
                if (moment(item.end_date, "YYYY-MM-DD").diff(moment(), 'days') <= 3) {
                    vacanciesToCheck.unshift(item)
                }
            })

            if (vacanciesToCheck.length > 0) previousDay.setPrimitive(moment().format("YYYY-MM-DD"))
            setClosingInFewDays(vacanciesToCheck)
        }
    }

    const showNotificationCloseVacancy = () => {
        let aux = previousDay.getPrimitive();
        let today = moment().format("YYYY-MM-DD");

        if (!aux) {
            checkEndDateVacancies()
        } else if (aux && (aux !== today)) {
            checkEndDateVacancies()
        }

    }

    const getEnterprise = async () => {
        setLoading(true)

        api.post(`sine/user/${user.user_id}/companies`,{
            "page": 1,
            "order": "desc"
        },(res) => {
            if(res.entries === 0) {
                setLoading(false);
                history.push('/painel/cliente/vagas/')
            } else {
                let company = null
                let path = window.location.pathname;
                let id = '';
                let aux = path.indexOf('cliente/vagas/');
                if(aux !== -1) {
                    for(let i = aux + 14; i < path.length; i ++) {
                        if(path[i] !== '/') {
                            id = id.concat(path[i]);
                        } else {
                            break
                        }
                    }
                }
                if(id) {
                    res.user_companies.forEach((item, index) => {
                        if(item.id.toString() === id) {
                            company = item;
                        }
                    })

                    if(!company) {
                        message.error('Você não tem acesso a essa empresa! Para ter acesso é necessário ser convidado')
                        company = res.user_companies[0];
                        history.push('/painel/cliente/vagas/' + company.id + '/todos-os-candidatos')
                    }
                } else {
                    company = res.user_companies[0];
                }
                multipleCompanies.current = res.user_companies;
                storeEnterprise(company);
                enterprise.current = company;
                setLoading(false);
                getVacancies(company.id);
            }
        },(e) => {
            setLoading(false);
        });
    }

    const changeCompany = (item) => {
        setChangeEnterpriseModal(false);
        storeEnterpriseOpportunities([])
        storeBlackListNotification([])
        previousDay.setPrimitive("")
        history.push('/painel/cliente/vagas/' + item.id + '/todos-os-candidatos');
        window.location.reload()
    }

    const changeTab = (e) => {
        if(enterprise.current) {
            window.scrollToElement('body');
            switch (e) {
                case 'todos-curriculos':
                    history.push('/painel/cliente/vagas/' + enterprise.current.id + '/todos-os-candidatos')
                    break;
                case 'vagas-abertas':
                    history.push('/painel/cliente/vagas/' + enterprise.current.id + '/vagas-abertas')
                    break;
                case 'vagas-encerradas':
                    history.push('/painel/cliente/vagas/' + enterprise.current.id + '/vagas-encerradas')
                    break;
                case 'nova-vaga':
                    history.push('/painel/cliente/vagas/' + enterprise.current.id + '/criar-vaga')
                    break;
                case 'minha-empresa':
                    history.push('/painel/cliente/vagas/' +  (enterprise.current?.id || undefined) + '/editar-empresa')
                    break;
                case 'outros-curriculos':
                    history.push('/painel/cliente/vagas/outros-curriculos')
                    break;
                case 'usuarios':
                    history.push('/painel/cliente/vagas/' + enterprise.current.id + '/usuarios')
                    break;
                default:
                    break;
            }
        } else {
            if(e === "outros-curriculos") {
                history.push('/painel/cliente/vagas/outros-curriculos');
            } else {
                history.push('/painel/cliente/vagas')
            }
        }
    }

    const handleStatusVacancyDetails = (value) => {
        setStatusVacancyDetails(value)
    }

    const handleAddTime = async (item, index, isRefetch) => {
        if(!smallLoading) {
            setSmallLoading(true)
            setModalVisible(false)
            message.loading(`Adiando vaga`);

            api.post(`sine/user/${user.user_id}/postpone-opportunity/${item.id}`, {
                end_date: moment(item.end_date, "YYYY-MM-DD").add(15, 'days').format("YYYY-MM-DD")
            }, () => {
                window.gtagService('event', 'timeAddedSuccess');
                removePositionVacancyList(index)
                message.destroy();
                message.success(`Vaga Adiada`);
                setSmallLoading(false);
                if (isRefetch) {
                    setRefetch(true)
                    setRefetch(false)
                }

            }, (e) => {
                window.gtagService('event', 'timeAddedFail');
                message.error(e.message || 'Ops, não foi possível adiar a data de publicação');
                setSmallLoading(false);
            })
        }
    };

    const removePositionVacancyList = (index) => {
        vacancyListRef.current.splice(index, 1);
        console.log(vacancyListRef.current)
        setVacanciesList([...vacancyListRef.current])
    }

    const getRecommendedList = () => {
        if (listWorkerRecommended.length === 0 && vacancyList.length > 0) {
            let aux = null;
            for(let i = 0; i < vacancyList.length; i++) {
                if(vacancyList[i].audited_at) {
                    aux = vacancyList[i];
                    break;
                }
            }
            if(aux) {
                api.post(`sine/search_opportunity_workers/${aux.id}`, {
                    "page": 1,
                    "limit": 20,
                    "max_distance": 10000,
                    "min_total_experience": 0,
                }, (res) => {
                    if(res.workers?.length > 0) {
                        setActiveVacancy(aux)
                        setListWorkerRecommended(res)
                    }
                }, (err) => {
                    console.log(err);
                })
            }
        }
    }

    const handleClickNotificationRecommendedWorkers = (type) => {
        const showCandidates = () => {
            if (window.location.pathname.indexOf('/todos-os-candidatos') === -1) {
                history.push(`/painel/cliente/vagas/${enterprise.current.id}/candidatos-da-vaga/${activeVacancy?.id}`)
            }
            setShowListRecommendedDrawer(true);
            notification.close('nt1');
        }

        if (type === 'show') {
            showCandidates()

        } else if (type === 'hidden-notification-show') {
            showCandidates()

        } else if (type === 'closed-icon') {
            openNotification('hidden');

        }else if (type === 'close-icon-confirm-notification') {
            notification.close('nt1')

        } else if (type === 'dontShow') {
            let aux = blackListID
            notification.close('nt1');
            aux.push(activeVacancy.id)

            storeBlackListNotification(aux)
        }

        if (type !== 'hidden') gaEvents.notificationRecommendedWorker(type)
    }
    const openNotification = (type) => {
        let aux = activeVacancy

        notification.open({
            closeIcon: <i style={{position: "absolute", top: "-10px", right: "-14px", display: 'flex'}}><MdClose size={20}/></i>,
            onClose: () =>  type !== 'hidden' ? handleClickNotificationRecommendedWorkers('closed-icon') : handleClickNotificationRecommendedWorkers('close-icon-confirm-notification'),
            message: type === 'hidden' ?
                <Fade>
                    <div className='rlwn-message'>
                        <i className='rlwn-bg-blue'>
                            <FaExclamation/>
                        </i>
                        <span>Tem certeza !?</span>
                    </div>
                </Fade>
                 :
                <div className='rlwn-message'>
                    <i className='rlwn-bg-green'>
                        <FaBullhorn/>
                    </i>
                    <span>Temos uma novidade para você!</span>
                </div>,
            description: type ==='hidden' ?
                <Fade>
                    <div style={{textAlign:'justify'}}>
                        <span >Essa notificação <strong>não será mais exibida para essa vaga</strong>, você poderá ver sugestões de candidatos no menu <strong>"Vagas publicadas"</strong>.</span>
                    </div>
                    <div>
                        <div style={{marginTop:12, textAlign:'center', display:'flex', alignItems:'center', gap:10}}>
                            <div>
                                <Button className='rlwn-dont-show' onClick={() =>  handleClickNotificationRecommendedWorkers('dontShow')}>
                                    Não exibir novamente
                                </Button>
                            </div>
                            <div style={{ flex:1}}>
                                <Button className='btn-reseted w-100' style={{backgroundColor: "#1976d2",borderColor: "#1976d2", color:'white'}} onClick={() => handleClickNotificationRecommendedWorkers('hidden-notification-show')}>
                                    Ver candidatos
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fade>
                :
                <div>
                    <div style={{textAlign:'center'}}>
                        <span style={{fontSize:16}}>Separamos alguns currículos para a sua vaga:</span>
                        <br/>
                        <span style={{fontSize:13}}>Selecionamos alguns currículos que acreditamos que combinam com a sua vaga.</span>
                    </div>
                    <div style={{marginTop: "8px", textAlign: "center"}}>
                        <span style={{textTransform: "uppercase", background: "#cfe2ff", color: "#084298", padding: "2px 9px", borderRadius: "4px"}}>
                            {aux?.occupation_title}#{aux?.id}
                        </span>
                    </div>

                    <div style={{marginTop:12}}>
                        <Button className='ant-btn-secondary w-100' onClick={() => handleClickNotificationRecommendedWorkers('show')}>
                            Ver candidatos
                        </Button>
                    </div>
                </div>
            ,
            duration:0,
            key: 'nt1',
            style: {
                padding: '16px 12px'
            },
            className: `recommend-list-worker-notification`
        });
    }

    useEffect(() => {
        if(enterprise.current) {
            if (window.location.pathname === '/painel/cliente/vagas' || window.location.pathname === '/painel/cliente/vagas/') {
                history.push('/painel/cliente/vagas/' + enterprise.current.id + '/todos-os-candidatos')
                setMenuSelected('todos-curriculos');
            } else if (window.location.pathname.includes('/vagas-abertas')) {
                setMenuSelected('vagas-abertas')
            } else if (window.location.pathname.includes('/vagas-encerradas')) {
                setMenuSelected('vagas-encerradas')
            } else if(window.location.pathname.includes('/todos-os-candidatos')) {
                setMenuSelected('todos-curriculos');
            } else if(window.location.pathname.includes('/candidatos-da-vaga')) {
                setMenuSelected('')
            } else if(window.location.pathname.includes('/criar-vaga')) {
                setMenuSelected('nova-vaga')
            }  else if(window.location.pathname.includes('/editar-vaga')) {
                setMenuSelected('')
            } else if(window.location.pathname.includes('/editar-empresa')) {
                setMenuSelected('minha-empresa')
            }  else if(window.location.pathname.includes('/outros-curriculos')) {
                setMenuSelected('outros-curriculos')
            } else if(window.location.pathname.includes('/usuarios')) {
                setMenuSelected('usuarios')
            } else {
                setMenuSelected('')
            }

            window.scrollToElement('body');
        }
    },[window.location.pathname, enterprise.current]);

    const getVacancy = (item, index) => {
        setVacancyObj({...item, index: index});
        setModalVisible(true)
    }

    const deleteOpportunity = () => {
        api.post(`sine/user/${user.user_id}/close-opportunity/${vacancyObj.id}`, ({vacancy_filled_internal: false, vacancy_filled_external: false, vacancy_not_filled: false}),(res) => {},(e) => {
            console.log(e);
            message.warning(e.message || "Não foi possível encerrar a publicação tente novamente");
        });
    }

    const createOpportunity = () => {
        vacancyObj.user_company_id = enterprise.current?.id;
        vacancyObj.application = Env.application_alias;
        vacancyObj.end_date = moment().add(60, 'days').format('YYYY-MM-DD');
        api.post(`sine/user/${user.user_id}/job-opportunity`, vacancyObj , (res) => {
            getVacancies(enterprise.current?.id);
            history.push('/painel/cliente/vagas/' + enterprise.current?.id + '/detalhes-da-vaga/' + res.job_opportunity_id)
            message.success("Vaga recriada!");
        },(e) => {
            console.log(e);
            message.error(e.message || 'Não foi possivel salvar, tente novamente');
        });
    }

    const handleRecreate = () => {
        deleteOpportunity();
        createOpportunity();
        setModalVisible(false)
        setComplianceModal(false)
    }

    useEffect(() => {
        getEnterprise();
    },[]);

    useEffect(() => {
        if (vacancyList.length > 0 && !activeVacancy) {
            getRecommendedList();
        }
        if (activeVacancy) {
            openNotification()
        }
    },[vacancyList, activeVacancy]);


    return (<Row gutter={[16,16]} style={{minHeight: 500, margin:0}} className={'aboutCss'}>

        {closingInFewDays.length > 0 &&
            closingInFewDays.map((item, index) => (
                <VacancyCloseToClosingModal handleAddTime={() => handleAddTime(item, vacancyListRef.current.findIndex((find) => find.end_date === item.end_date), true)}
                                            removeBadge={() => removePositionVacancyList(vacancyListRef.current.findIndex((find) => find.id === item.id))}
                                            details={item}
                                            user={user}
                                            enterprise={enterprise.current}
                                            updateVacancyList={() => getVacancies(enterprise.current?.id)}
                                            setRefetch={(bool) => setRefetch(bool)}
                />
            ))

        }

        {(activeVacancy && vacancyList.length > 0) &&
            <RecommendListWorkerDrawer titleVacancy={`${activeVacancy?.occupation_title}#${activeVacancy?.id}`}
                                       idVacancy={activeVacancy?.id}
                                       addUserInList={(id) => setObserver({updateList:'updateList', id:id})}
                                       occupationId={activeVacancy?.occupation_id}
                                       workerList={listWorkerRecommended}
                                       genders={genders}
                                       visible={showListRecommendedDrawer}
                                       setVisible={() => setShowListRecommendedDrawer(false)}

            />
        }
        {addBusiness &&
            <Modal
                visible={addBusiness}
                footer={null}
                bodyStyle={{padding: 0}}
                width={'80%'}
                onCancel={() => {setAddBusiness(false)}}
                className={'registerModalWidth'}
                destroyOnClose={true}
                style={{maxWidth: '800px', top: 30}}
            >
                <RegisterEnterpriseComponent getData={() => getEnterprise()} newBusiness={'addBusiness'} setVisible={(e) => setAddBusiness(e)}/>
            </Modal>
        }

        {changeEnterpriseModal &&
            <Modal
                visible={changeEnterpriseModal}
                footer={null}
                closable={false}
                bodyStyle={{padding: 0}}
                width={'80%'}
                onCancel={() => {setChangeEnterpriseModal(false)}}
                className={'registerModalWidth'}
                destroyOnClose={true}
                style={{maxWidth: '550px'}}
            >
                <div className='d-flex justify-content-between align-items-center' style={{padding: 15, background: '#e4e4e44d'}}>
                    <h3 style={{color: '#5d5d5d', fontSize: 20, margin: 0}}> Selecione uma Empresa/Estabelecimento </h3>
                    <div onClick={() => {setChangeEnterpriseModal(false)}}>
                        <Icon type="close" className='hoverIcon'/>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'stretch', flexWrap: 'wrap', borderRadius: 10, overflow:'auto', maxHeight:530}}>
                    {multipleCompanies.current.map((item, index) =>
                        <div onClick={() => changeCompany(item) } key={index} className='select-business'>
                            <img src={item.company_logo? item.company_logo : Images.user_place_holder}
                                 style={{height: 80,  width: 80, borderRadius: 40}} alt='logo_enterprise'
                            />
                            <div style={{display: 'flex', flexFlow: 'column', marginLeft: 20, flex: 1}}>
                                <span style={{color: "#656262", fontSize: 19}}> {item.business_name} </span>
                                <span style={{color: "#9e9e9e"}}> {item.cnpj?  maskCnpj(item.cnpj) : maskCpf(item.cpf)}</span>
                                <div style={{marginTop: 5, paddingTop: 10, width: '100%', display: 'flex', alignItems: 'center', color: "gray", borderTop: '1px solid #f6f6f6'}}>
                                    <FaUser/>
                                    <span style={{marginLeft: 5}}>{item.is_owner ? 'Criador' : window.getCompanpanyPermission(item.permissions)}</span>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </Modal>
        }

        {showApproveModal &&<CandidateSearchModal visible={showApproveModal} setVisible={() => setShowApproveModal(false)} enterprise={enterprise.current}/>}

        <Col xs={24} sm={7}>
            {(loading) ? <div style={{height: 400, display: "flex"}}>
                {rightLoading &&<Loading/>}
            </div>
                :
                <div>
                    <VacancyEndFormComponent user={user} enterpriseId={enterprise.current?.id}/>

                    <div style={{boxShadow: "0 2px 8px rgb(0 0 0 / 8%)", borderRadius: 5, backgroundColor: "white"}}>
                        <div onClick={() => enterprise.current&& changeTab('3')} style={{alignItems: "center", textAlign: "center", marginBottom: 5, borderRadius: 5, padding: 20, display: "flex", flexDirection: "column", justifyContent: "center", borderBottom: "1px solid gainsboro"}}>
                            <div style={{display: 'flex', justifyContent: Env.show_client_panel ?  "space-between" : "end", marginBottom:10, width:'100%'}}>
                                {Env.show_client_panel ?
                                    <Icon  onClick={() => history.push('/painel')} style={{fontSize: 17, cursor: 'pointer'}} className={'craftyIcon'} type={'arrow-left'}/> :
                                    <></>
                                }
                                {multipleCompanies.current.length > 1 &&
                                    <div style={{display: 'flex', alignItems: 'center', color: '#a0a0a0'}}>
                                        <a onClick={() => setChangeEnterpriseModal(true)} style={{ fontSize: 12}}> Trocar de empresa</a>
                                    </div>
                                }
                            </div>
                            {enterprise.current? <>
                                    {(!enterprise.current?.company_logo && enterprise.current?.permissions?.enterprise_permission) ?
                                        <div onClick={() => enterprise.current&& changeTab('minha-empresa')} style={{padding: 5, cursor: 'pointer', marginBottom: 10, paddingTop: 15, paddingBottom: 15, backgroundColor: '#fff4f4',borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                                            <img src={Images.noPhotoRed} style={{height: 50, width: 50}} />
                                            <div style={{flex: 1, marginTop: 10}}>
                                                <h3 style={{color: '#ec1414', marginBottom: 5, fontSize: 15}}>Insira a Logo de sua Empresa </h3>
                                                <small>Suas vagas criadas geram mais credibilidade e mais engajamento por parte dos candidatos!</small> <br/>
                                            </div>
                                        </div>
                                        :
                                        <img src={enterprise.current?.company_logo? enterprise.current?.company_logo : Images.user_place_holder}
                                             style={{height: 80, cursor: 'pointer', width: 80, borderRadius: 40}}/>
                                    }
                                    <span style={{color: "#212020", fontSize: 20}}> {enterprise.current?.business_name} </span>
                                    <span style={{color: "#9e9e9e"}}> {maskCnpj(enterprise.current?.cnpj)}</span>
                                    <div style={{display: 'flex', color: '#6b93b6', fontSize: 12}}>
                                        <a onClick={() => history.push('/empresa/' + enterprise.current.id)} style={{marginRight: 5}}>Ver página do Estabelecimento / Empresa</a>
                                    </div>
                                </>
                                :
                                <>
                                    <img src={Images.logo_icon}
                                         style={{height: 80, width: 80, borderRadius: 40, marginBottom: 10}}/>
                                    <span style={{color: "#212020", fontSize: 20}}> Gerenciar currículos e vagas </span>
                                    <span style={{color: "#9e9e9e"}}> Se sua empresa já foi cadastrada na plataforma, solicite o acesso para sua conta</span>
                                </>
                            }
                        </div>

                        <div>
                            <Menu
                                onClick={(e) => changeTab(e.key)}
                                selectedKeys={[menuSelected]}
                                mode="inline"
                            >
                                <Menu.ItemGroup key="g1"  title="Minha empresa">
                                    {enterprise.current &&<Menu.Item key="todos-curriculos" className={'vacancyMenuItem'} style={{display: 'flex', alignItems: 'center'}}> <FaFolderOpen style={{fontSize: 16, color: '#9e9e9e', marginRight: 5}}/>Todos os currículos</Menu.Item>}
                                    {enterprise.current && <Menu.Item className={'vacancyMenuItem'} key="vagas-abertas" onTitleClick={() => changeTab('sub')}> <div style={{display: 'flex', alignItems: 'center'}}><FaClipboardList style={{fontSize: 16, color: '#9e9e9e', marginRight: 5}}/><span>Vagas publicadas</span></div> </Menu.Item>}
                                    {enterprise.current && <Menu.Item className={'vacancyMenuItem'} key="vagas-encerradas" style={{display: 'flex', alignItems: 'center'}}> <FaClipboardCheck style={{fontSize: 16, color: '#9e9e9e', marginRight: 5}}/>Vagas encerradas</Menu.Item>}
                                    {(!enterprise.current || enterprise.current?.permissions?.opportunity_permission) &&<Menu.Item key="nova-vaga" className={'vacancyMenuItem'} style={{display: 'flex', alignItems: 'center'}}> <FaPlusSquare style={{fontSize: 16, color: '#9e9e9e', marginRight: 5}}/>Publicar vaga</Menu.Item>}
                                    {(enterprise.current && enterprise.current?.permissions?.enterprise_permission) &&
                                        <SubMenu title={
                                            <div className={'vacancyMenuItem'}  style={{display: 'flex', alignItems: 'center'}}>
                                                <FaStoreAlt style={{fontSize: 16, color: '#9e9e9e',marginRight: 5}}/>
                                                <span>Empresa</span>
                                            </div>
                                        }>
                                            <Menu.Item key="minha-empresa" className={'vacancyMenuItem'} >
                                                <div  style={{display: 'flex', alignItems: 'center', gap:7}}>
                                                    <BsServer size={20} color={'#9e9e9e'}/> Dados da Empresa
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <div  style={{display: 'flex', alignItems: 'center'}} onClick={() => setAddBusiness(true)}>
                                                    <img style={{marginRight: 5, height:22}} src={Images.add_business} alt=''/> Cadastrar nova empresa
                                                </div>
                                            </Menu.Item>
                                        </SubMenu>

                                    }
                                    {(enterprise.current && enterprise.current?.permissions?.enterprise_permission) &&<Menu.Item className={'vacancyMenuItem'} key={"usuarios"} style={{display: 'flex', alignItems: 'center'}}> <FaKey style={{fontSize: 16, color: '#9e9e9e', marginRight: 5}}/>Usuários</Menu.Item>}
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="g2" title={<span> Currículos não vinculado a vagas <Popover content={'Currículos que você selecionou que não foram vinculados a vagas'}> <Icon  style={{color: "#9e9e9e"}}  type='question-circle'/> </Popover> </span>}>
                                    <Menu.Item className={'vacancyMenuItem'} key="outros-curriculos" style={{display: 'flex', alignItems: 'center'}}>
                                        <FaHandPointer style={{fontSize: 16, color: '#9e9e9e', marginTop: -4, marginRight: 5}}/> Outros currículos
                                    </Menu.Item>
                                </Menu.ItemGroup>
                            </Menu>
                        </div>

                    </div>

                    <div style={{marginTop: 5, textAlign: "right", color: '#02a9f4', fontSize: 12}}>
                        <a onClick={() => setShowHelpModal(true)}>O que oferecemos </a>
                        <FaQuestionCircle style={{cursor: `pointer`}} onClick={() => setShowHelpModal(true)}/>
                        <Modal
                            visible={showHelpModal}
                            footer={null}
                            bodyStyle={{padding: 0}}
                            width={'80%'}
                            onCancel={() => {setShowHelpModal(false)}}
                            className={'registerModalWidth'}
                            destroyOnClose={true}
                            style={{maxWidth: '1000px'}}
                        >
                            <WhatsNewComponent module={'vacancy'} list={[
                                {title: 'Divulgue vagas', img: Images.whatsNew.new6, subTitle: 'Divulgue vagas para todo o estado', description: 'Milhares de trabalhadores de todo o estado podem ver suas vaga públicadas, então capriche ao publicar, informe os requisitos mínimos dos candidatos e só veja os currículos dos que se encaixarem!'},
                                {title: 'Analise os currículos', img: Images.whatsNew.new1, subTitle: 'Currículos categorizados e organizados', description: 'Selecione currículos de seu interesse, com informações padronizadas e organizadas para rápida visualização, entre em contato com os candidatos que mais interessar.'},
                                {title: 'Faça marcações', img: Images.whatsNew.new2, subTitle: 'Organização de currículos', description: 'Mude candidatos de etapas, favorite, imprima os dados, anote as informações relevantes para você e sua equipe sobre o candidato, tudo em poucos cliques!'},
                                {title: 'Informe o retorno', img: Images.whatsNew.new3, subTitle: 'Ajude a plataforma', description: 'Ajude nossa plataforma informando dos candidatos que contratou, isso nos ajuda a continuar trabalhando para promover a empregabilidade no estado de ' + Env.state_restriction},
                                {title: 'Adicione usuários', img: Images.whatsNew.new4, subTitle: 'Outros usuários da empresa', description: 'Convide outras pessoas para ajudar na seleção de currículos, cadastramento de vagas e retorno da situação dos candidatos, com mais usuários fica mais fácil controlar os processos seletivos de seu negócio!'},
                                {title: 'Página da Empresa', img: Images.whatsNew.new5, subTitle: 'Página pública da sua empresa', description: 'Trabalhadores podem acessar a página de sua empresa e ver as vagas que estão disponíveis para inscrição, insira uma breve descrição e logo(imagem da empresa) para dar maior credibilidade a sua empresa/estabelecimento!'},
                                {title: 'Tire suas dúvidas', img: Images.workerPanel.funtrab, subTitle: 'Tem alguma dúvida?', description: <span>Tem alguma dúvida ou encontrou um problema? entre em contato conosco pelo whatsapp ou email, estamos a sua disposição! <br/><br/> <a target={'_blank'} href={'https://web.whatsapp.com/send?phone=' + Env.number + '&text=Olá, tenho uma dúvida com a plafatorma.'}> Whatsapp - {Env.number} </a> <br/> Email - {Env.support_email}</span>},
                            ]}/>
                        </Modal>
                    </div>

                    {(vacancyListRef.current.length > 0 || endedVacancies.current.length > 0) && <div style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                        <Button style={{boxShadow: `0px 2px 5px 1px #bbbbbb`, width: '100%'}} className={"ant-btn-green-gradient"}  onClick={() => setShowApproveModal(true)}>
                            <div style={{display: "flex", alignItems: "center", textAlign: 'center', justifyContent: 'center', width: '100%'}}>
                                <MdThumbUp style={{marginRight: 4, }}/>
                                <span>
                                 Informar Contratação
                            </span>
                            </div>
                        </Button>
                    </div>}

                    {/*{eventVisible()&&<div style={{marginTop: 15}}>*/}
                    {/*    <img style={{borderRadius: 5, width: '100%', cursor: 'pointer'}}*/}
                    {/*         className={'pulseWeak'} onClick={() => {*/}
                    {/*        Env.event = true;*/}
                    {/*        changeTab('nova-vaga')*/}
                    {/*    }} src={Images.home.bannerCarnaval}/>*/}
                    {/*</div>}*/}
                </div>
            }
        </Col>
        <Col xs={24} sm={17}>


            {vacanciesList.map((item, index) => {
                if(item.end_date && moment(item.end_date).diff(moment(), 'day') <= 15) {
                    return <div key={index} style={{padding: 5, paddingLeft: 10, alignItems: 'center', paddingRight: 10, borderRadius: 5, display: 'flex', justifyContent: 'space-between', color: '#03a9f4', marginBottom: 5, backgroundColor: "#fff4f4", border: '1px solid #ffc7c7'}}>
                        <span style={{color: '#595959'}}>
                            Vaga - &nbsp;
                            <strong>
                                {item.occupation_title? item.occupation_title : item.occupation_name}
                            </strong> com encerramento próximo - &nbsp;
                            <strong style={{color: '#d48c8c'}}>
                                {moment(item.end_date).format("DD/MM/YYYY")}
                            </strong>
                        </span>
                        <a onClick={() => getVacancy(item, index)} style={{fontSize: 12}}>Adiar encerramento da vaga por 15 dias</a>
                    </div>
                } else {
                    return <></>
                }
            })}

            <>
                {eventVisible() &&
                    <div  style={{marginBottom: 10}}>
                        <BannerEvent banner={bannerEvent2} pulseNone={true}
                                     style={{borderRadius:5, cursor:'pointer'}}
                                     onClick={() => {
                                             Env.event = true;
                                             changeTab('nova-vaga')
                                     }}
                        />
                    </div>
                }
                <PrivateRoute exact path={'/painel/cliente/vagas/:company_id/todos-os-candidatos'}> <AllCandidatesListComponent user={user} enterprise={enterprise.current} observer={observer} cleanObserver={() => setObserver({})}/> </PrivateRoute>
                <PrivateRoute exact path={'/painel/cliente/vagas/:company_id/vagas-abertas'}> <VacancyListComponent user={user} refetch={refetch} smallLoading={smallLoading} handleAddTime={(item, index) => getVacancy(item, index)} enterprise={enterprise.current}/></PrivateRoute>
                <PrivateRoute exact path={'/painel/cliente/vagas/:company_id/vagas-encerradas'}> <VacancyListComponent ended={true} user={user} smallLoading={smallLoading} handleAddTime={(item, index) => getVacancy(item, index)} enterprise={enterprise.current}/></PrivateRoute>
                <PrivateRoute exact path={'/painel/cliente/vagas/:company_id/detalhes-da-vaga/:id'}> <VacancyDetailsComponent handleChangeStatus={(value) => handleStatusVacancyDetails(value)} updateVacancyList={() => getVacancies(enterprise.current?.id)} match={props.match} user={user} enterprise={enterprise.current}/> </PrivateRoute>
                <PrivateRoute exact path={'/painel/cliente/vagas/:company_id/criar-vaga'}> <VacancyFormPage updateVacancyList={() => getVacancies(enterprise.current?.id)} enterprise={enterprise.current}/> </PrivateRoute>
                <PrivateRoute exact path={'/painel/cliente/vagas/:company_id/editar-vaga/:id'}>  <VacancyFormPage updateVacancyList={() => getVacancies(enterprise.current?.id)} edit={true} enterprise={enterprise.current}/> </PrivateRoute>
                <PrivateRoute path={'/painel/cliente/vagas/:id/editar-empresa'}>  <div className={'mainComponentPanel'} style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 5, borderRadius:5, backgroundColor: "white"}}><RegisterEnterpriseComponent getData={() => getEnterprise()} enterprise={enterprise.current} edit={true} setVisible={(e) => setShowRegisterModal(e)}/></div></PrivateRoute>
                <PrivateRoute exact path={'/painel/cliente/vagas/outros-curriculos'}> <MyRecruitmentsPage/> </PrivateRoute>
                <PrivateRoute exact path={'/painel/cliente/vagas/:company_id/candidatos-da-vaga/:id'}> <AllCandidatesListComponent statusVacancyDetails={statusVacancyDetails} user={user} enterprise={enterprise.current} observer={observer} cleanObserver={() => setObserver({})}/> </PrivateRoute>
                <PrivateRoute exact path={'/painel/cliente/vagas/:company_id/usuarios'}> <UserAccessPage user={user} enterprise={enterprise.current}/> </PrivateRoute>
                {!loading &&<PrivateRoute exact path={'/painel/cliente/vagas'}>
                    <div style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 0, borderRadius:5, backgroundColor: "white"}}>
                        <RegisterEnterpriseComponent enterprise={enterprise.current} changeMenu={(e) => changeTab(e)} getData={() => getEnterprise()} setVisible={(e) => setShowRegisterModal(e)}/>
                    </div>
                </PrivateRoute>}
            </>


            <Modal visible={modalVisible} footer={null}  onCancel={() => {setModalVisible(false)}}>
                <div>
                    <h2 style={{fontWeight: 'bold'}}>Divulgação perto do encerramento</h2>
                    <div>
                        <div>
                            <div style={{margin: "15px 5px"}}>
                                <span>Sua vaga será encerrada em <strong style={{color: '#d48c8c'}}>{moment(vacancyObj?.end_date).format("DD/MM/YYYY")}</strong>,
                                    <br/>Mas fique tranquilo(a), você pode <strong>Adiar</strong> a data de encerramento em 15 dias ou se preferir você pode <strong>Recriar</strong> a divulgação desta vaga.</span>
                            </div>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <img src={Images.panel.concluded_resumes_img} style={{maxHeight: 200}}/>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", margin: "5px", marginTop: 20}}>
                                <div style={{flex: 1, marginRight: 10}}>
                                    <Button style={{alignItems: 'center', display: 'flex', width: '100%', justifyContent: 'center'}} type="primary" onClick={() => handleAddTime(vacancyObj, vacancyObj?.index)}>
                                        <FaClock size={15}/> &nbsp;
                                        <span>Adiar vaga</span>
                                    </Button>
                                </div>
                                <div style={{flex: 1, marginLeft: 10}} >
                                    <Button style={{alignItems: 'center', display: 'flex', width: '100%', justifyContent: 'center'}} className={'ant-btn-secondary'} onClick={() => setComplianceModal(true)}>
                                        <BiRefresh size={18}/> &nbsp;
                                        <span>Recriar vaga</span>
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
            <Modal
                visible={complianceModal}
                footer={null}
                bodyStyle={{padding: 0}}
                width={'95%'}
                onCancel={() => {setComplianceModal(false)}}
                destroyOnClose={true}
                style={{maxWidth: '1000px'}}
            >
                <div>
                    <div className='publish-vacancy-modal'>
                        <h3 style={{padding: 15, fontSize: 23, color: '#393939', fontWeight: 'bolder'}}>O que vai acontecer agora? </h3>
                        <div className='striped'>
                            <div className={'arrow'} style={{width: '5%', display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                            </div>
                            <div className='card-vacancy' style={{  background: 'linear-gradient(45deg, #e4e7f9, transparent)'}}>
                                <div>
                                    <img src={Images.step1vacancy}/>
                                </div>

                                <div style={{maxWidth: 400}}>
                                    <div>
                                        <h3 style={{color: '#565656'}}> <strong style={{color: '#7f56c4'}}>1.</strong> Sua vaga será publicada</h3>
                                    </div>
                                    <div>
                                        <p>Você está a publicando <strong style={{color: '#7f56c4', fontSize:16}}> {vacancyObj?.job_openings} vaga(s)</strong> para o cargo <strong> - {vacancyObj?.occupation_name} </strong>
                                            <br/>
                                            Revise os dados e veja se está tudo conforme o que você precisa!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='striped'>
                            <div className={'arrow'} style={{width: '20%', display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                                <img style={{height: 100, width: 100}} src={Images.rightArrow2}/>
                            </div>
                            <div className='card-vacancy ' style={{ background: 'linear-gradient(45deg, #fbf8f0, transparent)'}}>
                                <div>
                                    <img src={Images.step2vacancy}/>
                                </div>
                                <div style={{maxWidth: 400}}>
                                    <div>
                                        <h3 style={{color:'#565656'}}><strong style={{color: '#caa629'}}>2.</strong> Vamos ver se está tudo certo</h3>
                                    </div>
                                    <div>
                                        <p>
                                            Os detalhes da publicação serão analisados por nossa equipe antes de ser divulgada na plataforma para garantir que a publicação se enquadra a nossas diretrizes, <strong>é bem rápido</strong>.  Em pouco tempo sua vaga já será liberada para todo o estado!
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='striped'>
                            <div className={'arrow'} style={{width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                                <img style={{height: 100, width: 100}} src={Images.rightArrow3}/>
                            </div>
                            <div className='card-vacancy' style={{  background: 'linear-gradient(45deg, #ecf3ff, transparent)'}}>
                                <div>
                                    <img src={Images.step3vacancy}/>
                                </div>
                                <div style={{maxWidth: 400}}>
                                    <div>
                                        <h3 style={{color:'#565656'}}><strong style={{color: '#2955b5'}}>3.</strong> Você vai receber currículos de trabalhadores interessados</h3>
                                    </div>
                                    <div>
                                        <p>Assim que sua vaga for liberada para divulgação, ela ficará disponível para <strong style={{color: '#565656'}}>milhares de trabalhadores</strong> em todo estado. Agora é só filtrar os melhores currículos, entrar em contato e escolher o profissional ideal para sua vaga!</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='striped'>
                            <div className={'arrow'} style={{width: '40%', display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                                <img style={{height: 100, width: 100}} src={Images.rightArrow4}/>
                            </div>
                            <div className='card-vacancy' style={{background: 'linear-gradient(45deg, #eaf9ee, transparent)'}}>
                                <div>
                                    <img src={Images.step4vacancy}/>
                                </div>

                                <div style={{maxWidth: 400}}>
                                    <div style={{marginBottom:10,}}>
                                        <h3 style={{color: '#565656'}}><strong style={{color: '#4ed374'}}>4.</strong> Nos conte se encontrar um profissional</h3>
                                    </div>
                                    <div>
                                        <p style={{marginBottom:10}}>
                                            Conta pra gente! Essa informação é muito importante, pois além de aumentar a credibilidade do <strong style={{color:'#4ed374'}}>{Env.application_title}</strong>; ajuda no fomento à empregabilidade do estado do {Env.state_full}.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-vacancy-modal'>

                        <div style={{display: 'flex' , alignItems: 'center', justifyContent: 'space-between'}}>
                            <div className='images-gov' style={{padding:0}}>
                                <div style={{marginBottom:5}}>
                                    <img src={Images.logoModalVacancy.logoGov} alt='logo gov' style={{width:'200px'}}/>
                                </div>
                            </div>

                            <Button size={'large'} onClick={() => handleRecreate()} className={'ant-btn-secondary'} >
                                      <span style={{display:"flex", alignItems:"center", gap:8}}>
                                        <FaPaperPlane/>  Continuar
                                      </span>
                            </Button>

                        </div>
                    </div>
                </div>

            </Modal>
        </Col>
    </Row>)
};
export default VacancyManagePage;

