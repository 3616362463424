import React, {useMemo} from 'react';
import {Link, Route, withRouter} from "react-router-dom";
import ExternAccess from "../../Pages/ExternallyAccess/ExternAccess";
import {ExternallyPrivateRoute} from "../../Helpers/ExternallyPrivateRoute";
import ExternalVacancyManagePage from "./VacancyPage/ExternalVacancyManagePage";
import useLocalStorage from "../../Hooks/Storage";
import {StorageVariables} from "../../Helpers/StoragesVariables";
import {Env} from "../../Helpers/Env";
import Header from "../../Components/Layout/External/HeaderExternal";
import Footer from "../../Components/Layout/Default/Footer";
import {Col, Icon, Layout, Row} from "antd";
import {Images} from "../../Helpers/Images";
import ExternalLogout from "./ExternalLogout";
import ExternalFullResumePage from "./VacancyPage/ExternalFullResumePage";
const { Content } = Layout;

const ExternalAccessLayoutManager = props => {
    const externAccessUser = useLocalStorage(StorageVariables.externUserAccess)

    useMemo(() => {
        const userObj = externAccessUser.getObject();
        if(userObj?.userToken) {
            Env.header_club.userToken = userObj.userToken;
        }
    }, []);

    return (
        <Layout>
            <Header/>
            <Content style={{marginTop: '66px', position: 'relative', zIndex: 2}}>
                <Route path={'/acesso-externo/acesso-da-empresa'} component={ externAccessUser.getObject() ? ExternalVacancyManagePage : ExternAccess}/>
                <ExternallyPrivateRoute exact path={'/acesso-externo/vagas-da-minha-empresa'} component={ExternalVacancyManagePage}/>
                <ExternallyPrivateRoute exact path={'/acesso-externo/logout-externo'} component={ExternalLogout}/>
                <ExternallyPrivateRoute exact path={'/acesso-externo/imprimir/:id/:vacancy_id'} component={ExternalFullResumePage}/>
            </Content>

            <Layout.Footer style={{ textAlign: 'center', position: 'relative', padding: 0, width: '100%', backgroundColor: 'transparent' }} className='footerMain'>
                <img className={'bannerApps'} src={Images.footer_bg}/>
                <Row className={'primary-sub-color-bg'} style={{display: 'flex', justifyContent: 'center', paddingBottom: 32}}>
                    <Col sm={24} md={24} lg={20} xl={18} xxl={16}>
                        <Row gutter={[0, 32]} className={'rowFooter'}>
                            <Col xs={24} md={8}  style={{display: 'flex',alignSelf: 'center', justifyContent: 'center'}}>
                                <div style={{width: 'fit-content', display: 'flex', flexFlow: 'column'}}>
                                    <span style={{fontSize: '18px', marginBottom: '15px', fontWeight: 'bold'}}>  Sobre </span>
                                    <span style={{marginBottom: 8}}> <Icon type='right'/><Link to={'/termos'}> Termos de Uso </Link> </span>
                                    <span> <Icon type='right'/> <Link to={'/termos'}> Políticas de Privacidade </Link> </span>
                                </div>
                            </Col>

                            <Col xs={24} md={8} style={{display: 'flex',alignSelf: 'center',  justifyContent: 'center'}}>
                                <div style={{width: 'fit-content', display: 'flex', flexFlow: 'column'}}>
                                    <span style={{fontSize: '16px', marginBottom: '15px', fontWeight: 'bold'}}> Ajuda </span>
                                    <span style={{marginTop: 8}}>
                                    <a target={'_blank'} href={window.innerWidth < 768? 'https://wa.me/' + Env.number : 'https://web.whatsapp.com/send?phone=' + Env.number}>
                                        <img style={{height: 20, marginBottom: 5, cursor: 'pointer'}}  src={Images.whats}/> Clique aqui para entrar em contato conosco.  </a>
                                </span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className={'primary-sub-color-bg '} style={{fontWeight: 'bold', color: 'white'}}>Icones feitos por <a href="https://www.flaticon.com/authors/inipagistudio" title="inipagistudio">inipagistudio</a> em <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                <Row style={{textAlign: 'center', display: 'flex', height: 100, backgroundImage: "url(" + Images.footerGov + ")"}}>
                    {/*<Col xs={12}  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img className={'imagesFooter'} style={{maxHeight: '80%'}} src={Images.logoGov}/>
                    </Col>*/}
                </Row>
            </Layout.Footer>
        </Layout>
    )

};

ExternalAccessLayoutManager.propTypes = {};

export default ExternalAccessLayoutManager;
