import React from 'react'
import {Col} from "antd";

const StepCustom = ({icon, title, chevron, md, className, stepId, useStep, changeStep, hasProfile}) => {

  return (
      <div onClick={() => hasProfile && changeStep(stepId)} className={`${className ? className : ''} ${stepId === useStep ? 'item-active' : stepId > useStep ? 'item-pending' : 'item-finish'}`}>
          {icon}
          <span style={{fontSize:18}} className={`${stepId === useStep ? '' : ''}`}>{title}</span>
          {chevron}
      </div>
  )
}

export default StepCustom
