import React, {useEffect, useState} from 'react';
import {Col, Icon, Layout, Row} from 'antd';
import {Link} from "react-router-dom";
import {Env} from "../../../Helpers/Env";
import useVerifyServices from "../../../Hooks/VerifyServices";
import {Images} from "../../../Helpers/Images";
import ModalFormContactUs from "../../PageComponents/Public/HomePage/ModalFormContactUs";

const Footer = props => {
    const {hasSineService, hasCraftyService, hasClubService} = useVerifyServices();
    const [visible, setVisible] = useState(false)

    const showModal = () => {
        setVisible(true)
    }

    const handleClose = () => {
        setVisible(false)
    }

    return (
        <Layout.Footer style={{ textAlign: 'center', position: 'relative', padding: 0, width: '100%', backgroundColor: '#f8f9fc' }} className='footerMain'>
            <img className={'bannerApps'} src={Images.footer_bg}/>
            <Row className={'primary-color-bg'} style={{display: 'flex', justifyContent: 'center', paddingBottom: 32}}>
                <Col sm={24} md={24} lg={20} xl={18} xxl={16}>
                    <Row gutter={[0, 32]} className={'rowFooter'}>
                        <Col xs={24} md={6}  style={{display: 'flex',alignSelf: 'center', justifyContent: 'center'}}>
                            <div style={{width: 'fit-content', display: 'flex', flexFlow: 'column'}}>
                                <span style={{fontSize: '16px', marginBottom: '10px', fontWeight: 'bold'}}>  Sobre </span>
                                <span style={{marginBottom: 8}}> <Icon type='right'/><Link to={'/termos'}> Termos de Uso </Link> </span>
                                <span style={{marginBottom: 8}}> <Icon type='right'/><Link to={'/termos'}>Políticas de Privacidade</Link> </span>
                                <span style={{marginBottom: 8}}> <Icon type='right'/><Link to={'/faq'}>Perguntas Frequentes</Link> </span>
                                {/*<span> <Icon type='right'/> <Link to={'/termos'}> Políticas de Privacidade </Link> </span>*/}
                            </div>
                        </Col>
                        <Col xs={24} md={6} style={{display: 'flex',alignSelf: 'center',  justifyContent: 'center'}}>
                            <div style={{width: 'fit-content', display: 'flex', flexFlow: 'column'}}>
                                <span style={{fontSize: '16px', marginBottom: '10px', fontWeight: 'bold'}}> Serviços </span>
                                {hasCraftyService() && <span style={{marginBottom: 8}}> <Icon type='right'/> <Link to={'/quero-contratar'}> Quero contratar </Link> </span>}
                                {/*{hasSineService() && <span style={{marginBottom: 8}}> <Icon type='right'/> <Link to={'/trabalhar'}> Quero trabalhar </Link> </span>}*/}
                                {hasSineService() && <span  style={{marginBottom: 8}}> <Icon type='right'/> <Link to={'/curriculos'}> Quero currículos </Link> </span>}
                                {hasClubService() && <span> <Icon type='right'/> <Link to={'/estabelecimentos'}> Quero ver estabelecimentos </Link> </span>}
                            </div>
                        </Col>

                        <Col xs={24} md={6}  style={{display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: 'fit-content', display: 'flex', flexFlow: 'column'}}>
                                <span style={{fontSize: '16px', fontWeight: 'bold', marginBottom: 10}}> Redes Sociais </span>
                                <div style={{marginBottom: 8, marginTop:-2}}>
                                    <a target='_blank' href={Env.instagram}>
                                        <img src={Images.insta} alt="Instagram" style={{width: 24, height:24}}/> Instagram
                                    </a>
                                </div>

                                <div>
                                    <a target='_blank' href={Env.facebook}>
                                        <img src={Images.face} alt="Facebook" style={{width: 24, height:24}}/>Facebook
                                    </a>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={6} style={{display: 'flex',alignSelf: 'center',  justifyContent: 'center'}}>
                            <div style={{width: 'fit-content', display: 'flex', flexFlow: 'column'}}>
                                <span style={{fontSize: '16px', marginBottom: '10px', fontWeight: 'bold'}}> Ajuda </span>
                                 {/* {hasCraftyService() && <span style={{marginBottom: 8}}> <Icon type='right'/>  <Link to={'/ajuda'}> Perguntas frequentes - Contratante </Link> </span>}
                                {hasCraftyService() && <span style={{marginBottom: 8}}> <Icon type='right'/>  <Link to={'/ajuda'}> Perguntas frequentes - Prestador </Link> </span>}
                                {hasCraftyService() && <span style={{marginBottom: 8}}> <Icon type='right'/> <Link to={'/quero-contratar'}> Como contratar </Link> </span>}
                                {hasCraftyService() && <span  style={{marginBottom: 8}}> <Icon type='right'/> <Link to={'/trabalhar'}> Quero trabalhar </Link> </span>}*/}
                                {/*{hasSineService() && <span  style={{marginBottom: 8}}> <Icon type='right'/> <Link to={'/curriculos'}> Como ver currículos </Link> </span>}*/}
                                <span>
                                   {/*<div style={{marginBottom:10, marginLeft:-3}}>
                                       <a onClick={showModal}>
                                            <img src={Images.gmailIcon} alt="gmail" style={{width: 24, height:24}}/> Nos envie um e-mail
                                       </a>
                                       <ModalFormContactUs visible={visible} handleClose={handleClose}/>
                                   </div>*/}
                                    <div>
                                        <a target={'_blank'} href={window.innerWidth < 768? 'https://wa.me/' + Env.configs?.contact_number : 'https://web.whatsapp.com/send?phone=+' + Env.configs?.contact_number}>
                                             <img style={{width: 16, height:16}}  src={Images.whats}/> Fale conosco pelo WhatsApp
                                        </a>
                                    </div>
                                </span>
                                <span style={{marginTop: 8}}>
                                    {Env.support_email}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className={'primary-sub-color-bg '} style={{fontWeight: 'bold', color: 'white'}}>Icones feitos por <a href="https://www.flaticon.com/authors/inipagistudio" title="inipagistudio">inipagistudio</a> em <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
            {/*<Row style={{textAlign: 'center', display: 'flex', height: 100, backgroundImage: "url(" + Images.footerGov + ")"}}>
                <Col xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img className={'imagesFooter'} style={{maxHeight: '80%', maxWidth: '80%'}} src={Images.logoFuntrab}/>
                </Col>

                <Col xs={12}  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img className={'imagesFooter'} style={{maxHeight: '80%'}} src={Images.logoGov}/>
                </Col>
            </Row>*/}
        </Layout.Footer>
    );
};

Footer.propTypes = {};

export default Footer;

