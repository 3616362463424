export const cities = [
    {
        "label": "Alta Floresta D'Oeste",
        "value": "Alta Floresta D'Oeste"
    },
    {
        "label": "Alto Alegre dos Parecis",
        "value": "Alto Alegre dos Parecis"
    },
    {
        "label": "Alto Paraíso",
        "value": "Alto Paraíso"
    },
    {
        "label": "Alvorada D'Oeste",
        "value": "Alvorada D'Oeste"
    },
    {
        "label": "Ariquemes",
        "value": "Ariquemes"
    },
    {
        "label": "Buritis",
        "value": "Buritis"
    },
    {
        "label": "Cabixi",
        "value": "Cabixi"
    },
    {
        "label": "Cacaulândia",
        "value": "Cacaulândia"
    },
    {
        "label": "Cacoal",
        "value": "Cacoal"
    },
    {
        "label": "Campo Novo de Rondônia",
        "value": "Campo Novo de Rondônia"
    },
    {
        "label": "Candeias do Jamari",
        "value": "Candeias do Jamari"
    },
    {
        "label": "Castanheiras",
        "value": "Castanheiras"
    },
    {
        "label": "Cerejeiras",
        "value": "Cerejeiras"
    },
    {
        "label": "Chupinguaia",
        "value": "Chupinguaia"
    },
    {
        "label": "Colorado do Oeste",
        "value": "Colorado do Oeste"
    },
    {
        "label": "Corumbiara",
        "value": "Corumbiara"
    },
    {
        "label": "Costa Marques",
        "value": "Costa Marques"
    },
    {
        "label": "Cujubim",
        "value": "Cujubim"
    },
    {
        "label": "Espigão do Oeste",
        "value": "Espigão do Oeste"
    },
    {
        "label": "Governador Jorge Teixeira",
        "value": "Governador Jorge Teixeira"
    },
    {
        "label": "Guajará-Mirim",
        "value": "Guajará-Mirim"
    },
    {
        "label": "Itapuã do Oeste",
        "value": "Itapuã do Oeste"
    },
    {
        "label": "Jaru",
        "value": "Jaru"
    },
    {
        "label": "Ji-Paraná",
        "value": "Ji-Paraná"
    },
    {
        "label": "Machadinho D'Oeste",
        "value": "Machadinho D'Oeste"
    },
    {
        "label": "Ministro Andreazza",
        "value": "Ministro Andreazza"
    },
    {
        "label": "Mirante da Serra",
        "value": "Mirante da Serra"
    },
    {
        "label": "Monte Negro",
        "value": "Monte Negro"
    },
    {
        "label": "Nova Brasilândia D'Oeste",
        "value": "Nova Brasilândia D'Oeste"
    },
    {
        "label": "Nova Mamoré",
        "value": "Nova Mamoré"
    },
    {
        "label": "Nova União",
        "value": "Nova União"
    },
    {
        "label": "Novo Horizonte do Oeste",
        "value": "Novo Horizonte do Oeste"
    },
    {
        "label": "Ouro Preto do Oeste",
        "value": "Ouro Preto do Oeste"
    },
    {
        "label": "Parecis",
        "value": "Parecis"
    },
    {
        "label": "Pimenta Bueno",
        "value": "Pimenta Bueno"
    },
    {
        "label": "Pimenteiras do Oeste",
        "value": "Pimenteiras do Oeste"
    },
    {
        "label": "Porto Velho",
        "value": "Porto Velho"
    },
    {
        "label": "Presidente Médici",
        "value": "Presidente Médici"
    },
    {
        "label": "Primavera de Rondônia",
        "value": "Primavera de Rondônia"
    },
    {
        "label": "Rio Crespo",
        "value": "Rio Crespo"
    },
    {
        "label": "Rolim de Moura",
        "value": "Rolim de Moura"
    },
    {
        "label": "Santa Luzia D'Oeste",
        "value": "Santa Luzia D'Oeste"
    },
    {
        "label": "São Felipe D'Oeste",
        "value": "São Felipe D'Oeste"
    },
    {
        "label": "São Francisco do Guaporé",
        "value": "São Francisco do Guaporé"
    },
    {
        "label": "São Miguel do Guaporé",
        "value": "São Miguel do Guaporé"
    },
    {
        "label": "Seringueiras",
        "value": "Seringueiras"
    },
    {
        "label": "Teixeirópolis",
        "value": "Teixeirópolis"
    },
    {
        "label": "Theobroma",
        "value": "Theobroma"
    },
    {
        "label": "Urupá",
        "value": "Urupá"
    },
    {
        "label": "Vale do Anari",
        "value": "Vale do Anari"
    },
    {
        "label": "Vale do Paraíso",
        "value": "Vale do Paraíso"
    },
    {
        "label": "Vilhena",
        "value": "Vilhena"
    }
]
