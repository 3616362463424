export const ethnicityList = {
    'Pakaanova': ['Sagarana','Igarapé Lage', 'Igarapé Ribeirão', 'Pacaas Novas', 'Rio Negro Ocaia', 'Sagarana', 'Outra'],
    'Oro Wari': ['Igarapé Lage', 'Igarapé Ribeirão', 'Pacaas Novas', 'Rio Guaporé','Rio Negro Ocaia', 'Sagarana', 'Outra'],
    'Gaviao': ['Igarapé Lourdes', 'Outra'],
    'Karipuna': ['Karipuna', 'Outra'],
    'Karitiana': ['Karitiana', 'Outra'],
    'Kaxarari': ['Kaxarari ', 'Outra'],
    'Aikana': ['Kwazá do Rio São Pedro', 'Tubarão Latundê', 'Outra'],
    'Kwaza': ['Kwazá do Rio São Pedro', 'Outra'],
    'Isolados': ['Massaco', 'Tanaru', 'Outra'],
    'Cinta Larga': ['Roosevelt', 'Parque Aripuanã', 'Outra'],
    'Arikapu': ['Rio Branco', 'Outra'],
    'Macurap': ['Rio Branco', 'Rio Guaporé', 'Outra'],
    'Tupari': ['Rio Branco', 'Outra'],
    'Jabuti': ['Rio Guaporé', 'Outra'],
    'Sakirabiar': ['Rio Mequens', 'Outra'],
    'Akuntsu e Canoe': ['Rio Omerê', 'Outra'],
    'Surui': ['Sete de Setembro', 'Outra'],
    'Massaka': ['Tubarão Latundê', 'Outra'],
    'Uru-Eu Wau-Wau': ['Uru-Eu Wau-Wau', 'Outra'],
    'Amondawa': ['Uru-Eu Wau-Wau', 'Outra'],
    'Jupau': ['Uru-Eu Wau-Wau', 'Outra'],
    'Cassupa': ['Área de Referência do Povo Cassupá e Salamãi', 'Outra'],
    'Salamai': ['Área de Referência do Povo Cassupá e Salamãi', 'Outra'],

}
