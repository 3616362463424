import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Input,
    List,
    Menu,
    Popover,
    Select,
    Spin,
    message,
    Checkbox, Row, Col
} from "antd";
import Loading from "../../../../Generic/Loading";
import useApi from "../../../../../Hooks/Api";
import {Images} from "../../../../../Helpers/Images";
import {
    FaBookmark, FaBriefcase, FaClock, FaMapMarkedAlt,
    FaMapMarkerAlt,
    FaPhone,
    FaThumbsDown, FaUserGraduate,
} from "react-icons/fa";
import {
    BiCommentDetail,
    FaThumbsUp,
    FaUserCheck, GoEyeClosed,
    IoMdEye, MdArrowDownward, MdBook,
    MdSearch, MdStar,
    MdStarBorder, MdStarHalf, MdThumbDown, MdThumbUp,
} from "react-icons/all";
import {Badge} from "antd/es";
import Icon from "antd/es/icon";
import {Env} from "../../../../../Helpers/Env";
import {CityList} from "../../../../../Helpers/CityList";
import ResumeDrawer from "../../../Public/SearchResumePage/ResumeDrawer";
import FeedbackStepsMultipleModal from "../FeedbackStepsMultipleModal";
import {useHistory, useParams} from "react-router-dom";
import {genders as gendersArray} from "../../../../../Helpers/AuxVariables";
import moment from "moment";
import bronze from "../../../../../Img/badges/bronze.png"
import prata from "../../../../../Img/badges/prata.png"
import ouro from "../../../../../Img/badges/ouro.png"

const schoolingList = ['Analfabeto', 'Fundamental Incompleto', 'Fundamental Completo', 'Médio Incompleto', 'Médio Completo', 'Superior Incompleto', 'Superior Completo', 'Mestrado', 'Doutorado', 'Especialização'];

Spin.setDefaultIndicator(<Loading/>);

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}

const statusTypes = {
    pending: ['pending'],
    selected: ['selected'],
    interview: ['interview'],
    approved: ['approved'],
    rejected: ['rejected'],
    all_not_finalized: ['selected', 'pending', 'interview'],
    finished_approved_rejected: ['approved', 'rejected']
    }
const statusInterpreter = {
    pending: 'Pendentes',
    selected: 'Analise',
    interview: 'Entrevista',
    approved: 'Aprovados',
    rejected: 'Reprovados',
}



const AllCandidatesListComponent = (props) => {
    const routerParams = useParams();
    const defaultFilters = {
        "page": 1,
        "order": "asc",
        "order_by": 'distance',
        "worker_cpf": undefined,
        "worker_name": undefined,
        "status": props.statusVacancyDetails || 'pending',
        "viewed": undefined,
        "favorite": undefined,
        "opportunity_id": routerParams.id || undefined,
        "schooling": [],
        "city": undefined,
        "state_abbrev": undefined,
        'open_opportunity': undefined
    }
    const [filters,setFilters] = useState(defaultFilters);
    const [cityListState, setCityListState] = useState(CityList);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const nextPage = useRef(false);
    const candidatesList = useRef([]);
    const api = useApi();
    const [starHover, setStarHover] = useState(false);
    const [smallLoading, setSmallLoading] = useState(false);
    const [searchText, setSearchText] = useState({
        type: undefined,
        value: ""
    });
    const loadingRef = useRef(true);
    const filtersRef = useRef(defaultFilters); // Para controle dentro do infinite scroll
    const feedbackUser = useRef(null);
    const [showCandidateDetails, setShowCandidateDetails] = useState(false);
    const showFeedback = useRef(undefined);
    const [checkedCount, setCheckedCount] = useState(0);
    const [checkedList, setCheckedList] = useState([]);
    const [allowSelect, setAllowSelect] = useState(false);
    let queryObj = useRef(window.queryStringToObject())
    const history = useHistory();
    const summary = useRef();

    const getCandidates = (label, id) => {
        filters.page > 1? setLoadingMore(true) : setLoading(true);
        loadingRef.current = true;

        api.post(`sine/user/${props.user.user_id}/company-interests/${routerParams.company_id}`, {...filters, status: statusTypes[filters.status]}, (res) => {
            if (label === 'updateList') {
                let aux = res.candidates
                let index = aux.findIndex(e => e.user_id === id);
                handleShowCandidate(aux[index], index, undefined)
                candidatesList.current = res.candidates;
                setLoading(false)
                props.cleanObserver()

            } else {
                res.next_page? nextPage.current = true : nextPage.current = false;
                summary.current = res.summary;

                if(filters.page > 1) {
                    candidatesList.current = candidatesList.current.concat(res.candidates);
                } else {
                    candidatesList.current = res.candidates;
                }
                filters.page > 1? setLoadingMore(false) : setLoading(false);
            }
            loadingRef.current = false;
        }, (e) => {
            filters.page > 1? setLoadingMore(false) : setLoading(false);
            loadingRef.current = false;
        });
    }

    const changeFilter = (field, value, reset) => {
        let aux = filters;
        aux.page = 1;
        if(field === 'status' && value === 'finished_approved_rejected') aux.open_opportunity = undefined;
        if(reset) {
            aux.worker_cpf = undefined;
            aux.worker_name = undefined;
        }
        if(field === 'city' && !value) {
            aux.state_abbrev = undefined;
        } else if(field === 'city') {
            aux.state_abbrev = Env.state_restriction;
        }

        if(field === 'order_by' && value === 'distance') {
            aux.order = 'asc'
        } else if (value === 'total_experience' || value === 'age') {
            aux.order = 'desc'
        }
        let google = ''

        if (field === 'favorite') {
            if (value === undefined) {
                google = `ft - ${field}: all`
            } else if (value === true) {
                google = `ft - ${field}: favorites`
            } else {
                google = `ft - ${field}: not favorites`
            }
        } else if (field === 'viewed') {
            if (value === undefined) {
                google = `ft - ${field}: all`
            } else if (value === true) {
                google = `ft - ${field}: viewed`
            } else {
                google = `ft - ${field}: not viewed`
            }
        } else if (field === 'status') {
            google = `ft - ${field}: ${value}`
        } else if (field === 'order_by') {
            google = `ft - ${field}: ${value}`
        } else {
            google = `ft - ${field}`
        }

        setFilters({...aux, [field]: value});
        filtersRef.current = {...aux, [field]: value};

        window.gtagService('event', 'panel_filter_events', {
            'filter_type': google,
        })
    }

    const handleFavorite = (id, index) => {
        setSmallLoading(true);
        setStarHover(index);
        let safeGuard = Object.assign([], candidatesList.current);
        api.post(`sine/user/${props.user.user_id}/interest-favorite/${id}`, {favorite: !candidatesList.current[index].favorite}, (res) => {
            setStarHover(false);
            setSmallLoading(false);
        }, (err) => {
            setSmallLoading(false);
            setStarHover(index);
            candidatesList.current = safeGuard;
            setStarHover(false);
        })

        candidatesList.current[index].favorite = !candidatesList.current[index].favorite;
        if(candidatesList.current[index].favorite) {
            let aux = candidatesList.current[index];
            candidatesList.current.splice(index, 1);
            let listAux = [aux];
            candidatesList.current = listAux.concat(candidatesList.current);
        }
        setStarHover(false);
    }

    const infiniteScroll = () => {
        let bodyAux = document.getElementById(`candidatesScrollList`);
        if(nextPage.current && !loadingRef.current) {
            if (bodyAux.scrollTop + bodyAux.clientHeight >= bodyAux.scrollHeight - 20) {
                setFilters({...filtersRef.current, page: filtersRef.current.page + 1});
                filtersRef.current = {...filtersRef.current, page: filtersRef.current.page + 1};
            }
        }
    }

    const handleShowCandidate = (item,index, mode) => {
        showFeedback.current = mode;
        setSmallLoading(true);
        feedbackUser.current = Object.assign({}, item);
        feedbackUser.current.index = index;
        if(candidatesList.current?.length > 0 && candidatesList.current[index]) candidatesList.current[index].viewed = true;
        setShowCandidateDetails(true);
        const urlParams = new URLSearchParams({interest: feedbackUser.current.interest_id, contact: feedbackUser.current.contact_id, opportunity: feedbackUser.current.opportunity_id, occupation: feedbackUser.current.occupation_title});
        let pathAux = window.location.pathname;
        pathAux.charAt(pathAux.length - 1) === '/' &&  (pathAux = pathAux.substring(0, pathAux.length - 2))
        history.push(`${pathAux}?${urlParams}`);
        setTimeout(() => {
            setSmallLoading(false);
        },[1000])
    }

    const closeCurriculum = () => {
        if(queryObj) {
            queryObj = null;
        }
        history.push(window.location.pathname);
        setShowCandidateDetails(false);
    }
    const disregardCurriculum = (item, index) => {
        setSmallLoading(true);
        let objToSend = {};
        objToSend.feedback_result = "Não aprovado no processo seletivo";
        objToSend.last_event = 3;
        objToSend.observation = 'Desconsiderado na primeira etapa';
        let safeGuard = Object.assign([], candidatesList.current);

        candidatesList.current.splice(index, 1);
        setShowCandidateDetails(false);

        api.post(`sine/user/${props.user.user_id}/interest-status/${item.interest_id}`, objToSend, (res) => {
            setSmallLoading(false);
        }, (e) => {
            candidatesList.current = safeGuard;
            setSmallLoading(false);
            message.destroy();
            if(e.message === 'Request failed') {
                message.error('Não foi possível enviar esse feedback para o SINE, tente novamente.');
            } else {
                message.error(e.message || 'Não foi possivel enviar o feedback deste profissional');
            }
        });
    }

    const handleStepChange = (item,status) => {
        if(status !== item.status && !smallLoading) {
            updateCurriculumStatus(item,status);
            setSmallLoading(true);
            api.post(`sine/user/${props.user.user_id}/intermediate-status/${item.opportunity_id}`, {
                "interests_ids": [item.interest_id],
                "status": status
            }, (e) => {
                    feedbackUser.current&& (feedbackUser.current.status = status)
                    setSmallLoading(false);
                    window.gtagService('event', 'stepChangeSuccess');
                },
                (err) => {
                    message.error('Ocorreu um problema ao mover o currículo de etapa, tente novamente');
                    window.gtagService('event', 'stepChangeFail');
                    console.log(err);
                    setSmallLoading(false);
                })

        }
    }

    const handleSingleCheck = (item) => {
        let aux = checkedList;
        if(aux.indexOf(item) !== -1) {
            aux.splice(aux.indexOf(item), 1);
        } else {
            aux.push(item)
        }
        setCheckedList(aux);
        setCheckedCount(checkedList.length);
    }


    const updateCurriculumStatus = (item, status) => {
        message.destroy();
        let itemFound = candidatesList.current.findIndex((indexItem) => indexItem.interest_id === item?.interest_id);
        if(itemFound !== -1) {
            candidatesList.current[itemFound].status = status;
            if((candidatesList.current[itemFound].status !== filters.status) && filters.status !== `all_not_finalized`) {
                candidatesList.current.splice(itemFound, 1);
            }
        }  else {
            if(status === filters.status) {
                candidatesList.current.unshift(feedbackUser.current);
            }
        }
        message.success('Curriculo movido para - ' + statusInterpreter[status]);
    }

    const handleSelectAll = () => {
        if(allowSelect) {
            setAllowSelect(false);
            setCheckedList([]);
            setCheckedCount(0);
        } else {
            setAllowSelect(true);
            let aux = candidatesList.current.map((item, index) => item.gender !== null && item.interest_id);
            setCheckedList(aux);
            setCheckedCount(aux.length);
        }
    }

    useEffect(() => {
        if(routerParams.company_id) getCandidates();
    },[filters]);

    useEffect(() => {
        if (props.observer?.updateList && props.observer?.id) {
            getCandidates(props.observer?.updateList, props.observer?.id)
        }
    },[props.observer]);

    useEffect(() => {
        if(queryObj.current?.interest) {
            setSmallLoading(true);
            feedbackUser.current = {};
            feedbackUser.current.index = undefined;
            feedbackUser.current.contact_id = queryObj.current.contact;
            feedbackUser.current.interest_id = queryObj.current.interest;
            feedbackUser.current.opportunity_id = queryObj.current.opportunity;
            feedbackUser.current.occupation_title = queryObj.current.occupation?.replaceAll('+', ' ');
            setShowCandidateDetails(true);
            setTimeout(() => {
                setSmallLoading(false);
            },[1000])
        }
        if(document.getElementById(`candidatesScrollList`)) document.getElementById(`candidatesScrollList`).addEventListener('scroll', infiniteScroll)
        return () => {
            if(document.getElementById(`candidatesScrollList`)) document.getElementById(`candidatesScrollList`).removeEventListener(`scroll`, infiniteScroll);
        }
    },[])

    function toNormalForm(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    return (
        <div className={'mainComponentPanel'} style={{padding: 0, marginBottom: 10, borderRadius:5, }}>


            {showCandidateDetails &&<ResumeDrawer
                handleStepChange={(item,status) => handleStepChange(item,status)}
                arrowShortCut={true}
                currentIndex={feedbackUser.current?.index}
                candidatesList={candidatesList?.current}
                updateFeedbackUser={(item) => handleShowCandidate(item, item.index, undefined)}
                handleFavorite={() => handleFavorite(feedbackUser.current?.interest_id, feedbackUser.current?.index)}
                favorite={feedbackUser.current?.favorite}
                viewed={feedbackUser.current?.viewed}
                getData={() => getCandidates()}
                vacancyFeedback={true}
                interest_id={feedbackUser.current?.interest_id}
                id={feedbackUser.current?.contact_id}
                opportunity_id={feedbackUser.current?.opportunity_id}
                status={feedbackUser.current?.status}
                opportunity_name={feedbackUser.current?.occupation_title || feedbackUser.current?.occupation_name}
                disregardCurriculum={() => disregardCurriculum(feedbackUser?.current, feedbackUser.current?.index)}
                recruited={true}
                cboId={false}
                company_id={routerParams?.company_id}
                worker_id={feedbackUser?.current?.user_id}
                visible={showCandidateDetails}
                showFeedback={showFeedback?.current}
                setVisible={(item) => closeCurriculum()}/>
            }

            <div style={{background: 'white', padding: 10, margin: 5,  borderRadius: 5, marginBottom: 20, boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px` }}>

                {smallLoading &&<div style={{position: "absolute", right: 20, top: 20}}>
                    <Icon style={{marginLeft: 10, marginTop: 5, color: '#1680ce'}} type={'loading'}/>
                </div>}

                <div style={{width: '100%', padding: 10, paddingTop: 0}}>
                    <strong className={'primary-color'}>Filtros</strong>
                </div>

                <Row className={'selectionFilter'} gutter={[16,8]}>
                    <Col xs={24} md={12} lg={8}>
                        <Select mode={'default'}
                            onChange={(value) => {changeFilter("city", value);}}
                            allowClear={true}
                            showSearch
                            onSearch={(e) => setCityListState(CityList.filter((item) => item?.toLowerCase().search(e.toLowerCase()) !== -1 ))}
                            placeholder={<span> <FaMapMarkerAlt/> {Env.state_full} </span>}>
                            {cityListState?.map((item, index) =>
                                <Select.Option key={index} value={item}><FaMapMarkerAlt/> {item || Env.state_full}</Select.Option>
                            )}
                        </Select>
                    </Col>

                    <Col xs={24} md={12} lg={8}>
                        <Select
                            allowClear={true}
                            mode={'multiple'}
                            onChange={(value) => {changeFilter("schooling", value, false)}}
                            placeholder={<span><MdBook style={{marginBottom: -2}}/> Escolaridade </span>}>
                            {schoolingList.map((item, index) =>
                                <Select.Option key={index} value={item}>{item}</Select.Option>
                            )}
                        </Select>
                    </Col>

                    <Col xs={24} md={12} lg={8}>
                        <Select mode={'default'}
                            onChange={(value) => {changeFilter("favorite", value, false)}}
                            placeholder={<span><MdStarHalf style={{marginBottom: -2}}/> Todos </span>}>
                            <Select.Option value={undefined}><MdStarHalf style={{marginBottom: -2}}/> Todos</Select.Option>
                            <Select.Option value={true}><MdStar style={{marginBottom: -2}}/> Favoritos </Select.Option>
                            <Select.Option value={false}><MdStarBorder style={{marginBottom: -2}}/> Não Favoritos </Select.Option>
                        </Select>
                    </Col>

                    <Col xs={24} md={12} lg={8}>
                        <Select
                            mode={'default'}
                            onChange={(value) => {changeFilter("viewed", value, false)}}
                            placeholder={<span><IoMdEye style={{marginBottom: -2}}/> Todos </span>}>
                            <Select.Option value={true}><IoMdEye style={{marginBottom: -2}}/> Já visualizados </Select.Option>
                            <Select.Option value={false}><GoEyeClosed style={{marginBottom: -2}}/> Não visualizados </Select.Option>
                            <Select.Option value={undefined}><IoMdEye style={{marginBottom: -2}}/> Todos </Select.Option>
                        </Select>
                    </Col>

                    <Col xs={24} md={12} lg={10}>
                        <Select mode={'multiple'}
                            onChange={(value) => {changeFilter("gender", value, false)}}
                            placeholder={<span> Gênero </span>}>

                            {gendersArray.map((item, index) => (
                                <Select.Option value={item.value} key={index}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>

                    <Col xs={24} style={{marginTop:10}}>
                        <form style={{display:'flex', alignItems:'center'}} onSubmit={(event) => {
                            event.preventDefault();
                            changeFilter(searchText.type, searchText.value, true)
                        }}>
                            <Input  value={searchText.value}
                                    onChange={(e) => setSearchText({value: e.target.value, type: isNaN(e.target.value[0]) ? 'worker_name' : 'worker_cpf' })}
                                    style={{flex: 1, borderColor: 'gainsboro', height: 35}}
                                    placeholder={'Busque pelo nome'}
                            />
                            <div onClick={() => changeFilter(searchText.type, searchText.value, true)}
                                 style={{cursor: 'pointer', display: 'flex', alignItems:'center', backgroundColor: '#ebebeb', marginLeft: 5, borderRadius: 20, padding: 5}}>
                                <MdSearch style={{fontSize: 23, color: '#737373'}}/>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>

            <div style={{ backgroundColor: "white", margin: 5, borderRadius: 5, overflow: "hidden", boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`}}>
                <Menu selectedKeys={filters.status} onClick={(e) => changeFilter('status',e.key)} mode="horizontal" style={{display: "flex", marginBottom: 5, justifyContent: "space-between"}}>
                    {/*<Menu.Item key="all_not_finalized">*/}
                    {/*    <div style={{display: "flex", alignItems: "center"}}>*/}
                    {/*        <FaUsers style={{marginRight: 10}}/>*/}
                    {/*        <span>Não finalizados </span>*/}
                    {/*        {summary.current && <Badge style={{marginLeft: 5}} count={summary.current.selected + summary.current.pending + summary.current.interview}/>}*/}
                    {/*    </div>*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item key="pending">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FaClock style={{marginRight: 10}}/>
                            <span>Pendentes </span>
                            {summary.current  && <Badge style={{marginLeft: 5}} count={summary.current.pending}/>}
                        </div>
                    </Menu.Item>
                    <Menu.Item key="selected">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FaBookmark style={{marginRight: 10}}/>
                            <span>Em análise </span>
                            {summary.current  && <Badge style={{marginLeft: 5}} count={summary.current.selected}/>}
                        </div>
                    </Menu.Item>
                    <Menu.Item key="interview">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FaPhone style={{marginRight: 10}}/>
                            <span>Entrevista </span>
                            {summary.current  && <Badge style={{marginLeft: 5}} count={summary.current.interview}/>}
                        </div>
                    </Menu.Item>
                    <Menu.Item key="finished_approved_rejected" ke>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <FaUserCheck style={{marginRight: 10}}/>
                            <span>Finalizados </span>
                        </div>
                    </Menu.Item>
                </Menu>


                <div id={`candidatesScrollList`} style={{maxHeight: window.innerHeight - 270, overflowY: `auto`, padding: 5}}>
                    <div style={{display: 'flex', alignItems: 'center', flexWrap:"wrap", padding: 5, justifyContent: "space-between", paddingRight: 0, gap:16}}>

                        {(filters.status === 'finished_approved_rejected' || filters.status  === 'approved' || filters.status  === 'rejected') ?<div style={{display: 'flex'}}>
                                <div onClick={() => changeFilter('status','approved')} style={{padding: 3, cursor: 'pointer', borderRadius: 20, marginRight: 10, fontSize: 12, display: 'flex', paddingLeft: 10, paddingRight: 10, alignItems: 'center', border:  '1px solid #89c189', background:   (filters.status === 'approved' || filters.status === 'finished_approved_rejected')&& '#e9ffdb'}}>
                                    <MdThumbUp style={{marginRight: 2}}/>
                                    <span> Aprovados </span>
                                </div>

                                <div onClick={() => changeFilter('status','rejected')} style={{padding: 3, cursor: 'pointer', borderRadius: 20, marginRight: 10, fontSize: 12, display: 'flex', paddingLeft: 10, paddingRight: 10, alignItems: 'center', border: '1px solid #ff8383', background: (filters.status === 'rejected' || filters.status === 'finished_approved_rejected') && 'rgb(255 230 228)'}}>
                                    <MdThumbDown style={{marginRight: 2}}/>
                                    <span> Reprovados </span>
                                </div>

                            </div>
                            :
                            <div style={{display: `flex`, alignItems: `center`}}>
                                {routerParams.id ?
                                    <>
                                        <div onClick={() => handleSelectAll()}
                                             style={{borderRadius: 3, marginRight: 5, cursor: 'pointer', marginLeft: 5, height: 15, width: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: allowSelect ? '#1680ce' : 'white', border: allowSelect ? '1px solid #1680ce' : '1px solid #9e9e9e'}}>
                                            {allowSelect &&<Icon style={{color: 'white', fontSize: 12 }} type="check" />}
                                        </div>
                                        <span>{checkedCount > 0 ? `${checkedCount} Currículos selecionados` : 'Selecionar todos da página'}</span>
                                        <div style={{margin:`10px 0px 10px 15px`}}>
                                            <Checkbox
                                                onChange={()=> changeFilter("never_rejected", filters?.never_rejected ?  undefined : true   )}>
                                                Somente currículos nunca reprovados
                                            </Checkbox>
                                            <Popover content={'Mostrar somente os currículos de profissionais que não foram reprovados em nenhuma de suas vagas'}><Icon  style={{color: "#9e9e9e"}}  type='question-circle'/> </Popover>
                                        </div>
                                    </>
                                    :
                                    <div style={{marginLeft: 3}}>
                                        <div>
                                            <Checkbox
                                                onChange={()=> changeFilter("open_opportunity", filters.open_opportunity? undefined : true  )}>
                                                Somente currículos de vagas abertas
                                            </Checkbox>
                                        </div>
                                        <div style={{margin:`10px 0`}}>
                                            <Checkbox
                                                onChange={()=> changeFilter("never_rejected", filters?.never_rejected ?  undefined : true   )}>
                                                Somente currículos nunca reprovados
                                            </Checkbox>
                                            <Popover content={'Mostrar somente os currículos de profissionais que não foram reprovados em nenhuma de suas vagas'}><Icon  style={{color: "#9e9e9e"}}  type='question-circle'/> </Popover>
                                        </div>
                                    </div>

                                }
                            </div>}

                        <div className='badge-ordination'>
                            <div onClick={() => changeFilter("order_by", filters.order_by === 'distance' ? undefined : 'distance', false)}
                                 className={`${filters.order_by === 'distance' ? 'active' : ''}`}>
                                <MdArrowDownward />
                                <span> Distância da Empresa </span>
                            </div>

                            <div onClick={() =>changeFilter("order_by", filters.order_by === 'total_experience' ? undefined : 'total_experience', false)}
                                 className={`${filters.order_by === 'total_experience' ? 'active' : ''}`}>
                                <MdArrowDownward/>
                                <span> Experiência </span>
                            </div>

                            <div onClick={() => changeFilter("order_by", filters.order_by === 'age' ? undefined : 'age', false)}
                                 className={`${filters.order_by === 'age' ? 'active' : ''}`}>
                                <MdArrowDownward />
                                <span> Idade </span>
                            </div>
                        </div>

                    </div>

                    {(candidatesList.current?.length > 0) &&<List
                        className='listAnt listAntCandidates'
                        style={{overflow: 'auto', width: '100%'}}
                        itemLayout="horizontal"
                        dataSource={candidatesList.current}
                        loading={loading || loadingMore}
                        renderItem={(item, index) => (
                            <List.Item key={index} style={{padding: 0, position: 'relative', backgroundColor: 'white', border: checkedList.includes(item.interest_id)? '1px solid #1680ce' : 'unset'}} >
                                {(item.gender !== null && allowSelect) &&<div onClick={() => handleSingleCheck(item.interest_id)} style={{borderRadius: 3, cursor: 'pointer', marginLeft: 5, height: 18, width: 18, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: checkedList.includes(item.interest_id) ? '#1680ce' : 'white', border: checkedList.includes(item.interest_id) ? '1px solid #1680ce' : '1px solid #9e9e9e'}}>
                                    {checkedList.includes(item.interest_id) &&<Icon style={{color: 'white' }} type="check" />}
                                </div>}
                                <div style={{ position: 'absolute', top: 0, left: 0, display: "flex", alignItems: "center", gap:5}}>
                                    <div style={{
                                        backgroundColor: '#ededed',
                                        border: '1px solid #f7f7f7',
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        fontSize: 12,
                                        borderRadius: 4,
                                        borderBottomLeftRadius: 0,
                                        borderTopRightRadius: 0
                                    }}>
                                        {item.occupation_title || item.occupation_name} - #{item.opportunity_id}
                                    </div>
                                    {item.has_notes &&
                                        <div  onMouseLeave={() => setStarHover(false)} onMouseEnter={() => setStarHover(index)} >
                                            <BiCommentDetail style={{color: "gray", marginTop: 2}} size={18}/>
                                        </div>
                                    }
                                </div>
                                <span onClick={() => handleFavorite(item.interest_id, index)}
                                     style={{position:"absolute", right: 4, top: 0, zIndex:3, cursor:"pointer", height:24, width:24}}
                                     onMouseLeave={() => setStarHover(false)}
                                     onMouseEnter={() => setStarHover(index)}
                                >
                                    {(starHover === index || item.favorite) ? <MdStar style={{color: "gold"}} className={'candidateStar'} size={24}/> : <MdStarBorder style={{color: 'gainsboro'}} size={24}/>}
                                </span>
                                <List.Item.Meta onClick={() => {handleShowCandidate(item, index, undefined)}}
                                                className={'align-items-center'}
                                                style={{padding: 10, paddingTop: 25, cursor: 'pointer', position: 'relative'}}
                                                avatar={
                                                    <div>
                                                        {item.profile_picture?
                                                            <img style={{width: 64, height: 64, borderRadius: '50%', objectFit: "cover", backgroundColor: '#f5f5f5'}} src={item.profile_picture}/>
                                                            :
                                                            <Avatar style={{width: 64, height: 64, padding: 10, backgroundColor: '#f5f5f5'}}
                                                                    size='default'
                                                                    src={item?.name?.length > 0 ? Images?.lettersPack[toNormalForm(item?.name[0].toLowerCase())] : Images.user_place_holder_2 }
                                                            />}
                                                        {item.badges?.length> 0 &&<div style={{display: 'flex', width: '100%', position: 'absolute', left: 22, bottom: 8}}>
                                                            {item.badges?.length >= 10 ? <div  >
                                                                <Popover placement={"topRight"} content={'Este currículo possui selo Ouro, representando a conclusão de mais de 11 cursos através do Geração Emprego!'} title={'Selo Ouro'}>
                                                                    <img src={ouro} style={{borderRadius: '50%', height: 41}}/>
                                                                </Popover>
                                                            </div> : item.badges?.length >= 6 ? <div  >
                                                                <Popover placement={"topRight"} content={'Este currículo possui selo Prata, representando a conclusão de mais de 5 cursos através do Geração Emprego! '} title={'Selo Prata'}>
                                                                    <img src={prata} style={{borderRadius: '50%', height: 41}}/>
                                                                </Popover>
                                                            </div> : <div  style={{marginRight: 5}}>
                                                                <Popover placement={"topRight"} content={'Este currículo possui selo Bronze, representando a conclusão de ao menos 1 curso através do Geração Emprego!'} title={'Selo Bronze'}>
                                                                    <img src={bronze} style={{borderRadius: '50%', height: 41}}/>
                                                                </Popover>
                                                            </div>}
                                                        </div>}

                                                    </div>

                                                }
                                                title={
                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                        <div style={{display: `flex`, alignItems:'center'}}>
                                                            <span style={{fontWeight: "bold"}}>
                                                                {item.name}
                                                            </span>
                                                            {!item.viewed &&
                                                                <div style={{ marginLeft: 10, fontSize: 10, borderRadius: 5, paddingLeft: 4, paddingRight: 4, background: `#c3ffc3`}}>
                                                                    <span style={{color: "#6bbe1d"}}>
                                                                        Novo
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div style={{marginBottom: "6px", marginTop: "-4px"}}>
                                                            <span className={'ant-badge'} style={{fontSize: 11, color:'#aeaeae'}}>
                                                                 Recebido em: {moment(item.date).format('L')}
                                                            </span>
                                                        </div>
                                                        <div className='d-flex align-items-center' style={{gap: 20}}>
                                                            {item.schooling &&
                                                                <span className='d-flex align-items-center' style={{color: "rgba(0, 0, 0, 0.45)", fontSize: 14, fontWeight: 400, gap: 8}}>
                                                                    <FaUserGraduate style={{color: "darkgray"}} size={14}/> {item.schooling}
                                                                </span>
                                                            }
                                                            {item.age &&
                                                                <span style={{color: "darkgray", fontSize: 14, fontWeight: 400}}>
                                                                    {genders[item.gender]} - {item.age + ' Anos'}
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                    description={
                                        <div style={{display: 'flex', flexDirection: 'column',}}>
                                            <div>
                                                <span className='d-flex align-items-center' style={{gap:8}}>
                                                    <FaBriefcase style={{color: "darkgray"}} size={14}/> {item.occupation} {(item.total_experience > 11)? <span style={{fontWeight: 'bold'}}>- {(item.total_experience/12).toFixed(0)} ano(s) de experiência</span> : (item.total_experience > 0) && <span style={{fontWeight: 'bold'}}>- {item.total_experience} meses de experiencia</span> }
                                                </span>
                                            </div>

                                            <div>
                                                <span className='d-flex align-items-center' style={{gap:8}}>
                                                    <FaMapMarkedAlt style={{color: "darkgray"}} size={14}/> {window.getDistance(item.distance)} <span style={{color: 'darkgray', fontSize: 12}}>({item.city})</span>
                                                </span>
                                            </div>


                                        </div>
                                    }
                                />

                                {(item.status !== 'approved' && item.status !== 'rejected') &&
                                    <div style={{display: 'flex', flexFlow: 'column', marginRight: 20}}>
                                        <div style={{marginBottom: 10, textAlign: "center"}}>
                                            <span style={{fontWeight: "bold", color: '#9e9e9e'}}>Etapa</span>
                                        </div>
                                        <div style={{display: `flex`, justifyContent: `center`}}>
                                            <Popover placement={"topRight"} content={'Enviar currículo para a etapa CONTRATADOS'} title={'Contratado Candidato'}>
                                                <div className={`miniStatusButtons`} onClick={() => {
                                                    handleShowCandidate(item, index, 'approved')
                                                }} style={{padding: 7, cursor: `pointer`, marginRight: 10, borderRadius: '50%', backgroundColor: item.status === `approved`? `#1680ce` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                                    <FaThumbsUp style={{color: 'rgb(134 214 95)'}}/>
                                                </div>
                                            </Popover>
                                            <Popover placement={"topRight"} content={'Enviar currículo para a etapa REPROVADOS'} title={'Reprovar Candidato'}>
                                                <div className={`miniStatusButtons`} onClick={() => {
                                                    handleShowCandidate(item, index, 'rejected')
                                                }} style={{padding: 7, cursor: `pointer`, borderRadius: '50%', backgroundColor: item.status === `rejected`? `#1680ce` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                                    <FaThumbsDown  style={{color: 'rgb(243 135 114)', transform: 'rotateY(180deg)'}}/>
                                                </div>
                                            </Popover>
                                        </div>
                                        <div style={{display: 'flex', marginTop: 10 }}>
                                            <Popover placement={"topRight"} content={'Enviar currículo para a etapa PENDENTES'} title={'Enviar para PENDENTES'}>
                                                <div className={`miniStatusButtons`} onClick={() => handleStepChange({...item, index},`pending`)} style={{padding: 7, cursor: `pointer`, borderRadius: 5, backgroundColor: item.status === `pending`? `#1680ce` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                                    <FaClock style={{color: item.status === 'pending' ? `white` : '#bdbdbd'}}/>
                                                </div>
                                            </Popover>
                                            <Popover placement={"topRight"} content={'Enviar currículo para a etapa EM ANÁLISE'} title={'Enviar para EM ANÁLISE'}>
                                                <div className={`miniStatusButtons`} onClick={() => handleStepChange({...item, index},`selected`)} style={{padding: 7, cursor: `pointer`, borderRadius: 5, marginLeft: 5, marginRight: 5, backgroundColor: item.status === `selected`? `#673ab7` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                                    <FaBookmark  style={{color: item.status === 'selected' ? `white` : '#bdbdbd'}}/>
                                                </div>
                                            </Popover>
                                            <Popover placement={"topRight"} content={'Enviar currículo para a etapa ENTREVISTA'} title={'Enviar para ENTREVISTA'}>
                                                <div className={`miniStatusButtons`} onClick={() => handleStepChange({...item, index},`interview`)} style={{padding: 7, cursor: `pointer`, borderRadius: 5, backgroundColor: item.status === `interview`? `#009688` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                                                    <FaPhone  style={{color: item.status === 'interview' ? `white` : '#bdbdbd'}}/>
                                                </div>
                                            </Popover>
                                        </div>
                                    </div>
                                }

                            </List.Item>
                        )}
                    />}
                </div>
            </div>


            {(candidatesList.current.length === 0 && !loading) &&
                <div className={'empty-card'} style={{boxShadow: "unset"}}>
                    <div style={{marginLeft: '20px', color: '#545a5d'}}>
                        <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                            <img src={Images.resume_not_found} style={{height: 300, width: '100%', objectFit: "contain"}}/>
                            <span> Você não possui nenhum candidato nesta etapa ou com o filtro informado </span>
                        </div>
                    </div>
                </div>
            }

            {checkedCount > 0 &&<FeedbackStepsMultipleModal getCandidates={() => getCandidates()} setCheckedList={() => {
                setCheckedList([])
                setCheckedCount(0);
            }} userId={props.user.user_id} vacancyId={defaultFilters.opportunity_id} checkedCount={checkedCount} checkedList={checkedList}/>}
        </div>
    );
};

AllCandidatesListComponent.propTypes = {
    loading: PropTypes.any,
    resumesList: PropTypes.any,
    cboId: PropTypes.any,
    showDrawer: PropTypes.any
};

export default AllCandidatesListComponent;
