import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    AutoComplete,
    Avatar,
    Button,
    Input,
    List,
    message,
    Modal,
    Popover,
} from "antd";
import {Images} from "../../../../Helpers/Images";
import moment from "moment/moment";
import useApi from "../../../../Hooks/Api";
import Loading from "../../../Generic/Loading";
import {debounce} from "lodash";
import {FaBook, FaBookmark, FaClock, FaMapMarkerAlt, FaPhone, FaRocket, FaThumbsDown} from "react-icons/fa";
import {BiBriefcaseAlt, FaRegThumbsUp, FaThumbsUp, MdStar, MdStarBorder} from "react-icons/all";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}


const CandidateSearchModal = (props) => {

    const api = useApi();
    const [candidatesList, setCandidatesList] = useState([])
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(true);
    const paginate = useRef({
        page: 1,
        status:  ['selected', 'pending', 'interview', 'rejected'],
        opportunity_id: props.opportunity_id || undefined
    });
    const nextPage = useRef(false);
    const [activeCurriculum, setActiveCurriculum] = useState(null);
    const user = useSelector((state) => state).authReducer;
    const routerParams = useParams();

    const getCandidates = useCallback(debounce(async () => {
        setSearchLoading(true);

        api.post(`sine/user/${user.user_id}/company-interests/${routerParams?.company_id || props?.enterprise?.id}`, paginate.current, (res) => {
            res.next_page ? nextPage.current = true : nextPage.current = false;
            let auxArr = res.candidates
            setCandidatesList(auxArr);
            setSearchLoading(false);
        }, (e) => {
            setSearchLoading(false);
        });

    }, 400), []);


    const onSearch = (e) => {
        paginate.current.page = 1;
        paginate.current[isNaN(e[0]) ? 'worker_name' : 'worker_cpf'] = e;
        getCandidates();
    }


    const sendFeedback = async (item) => {
            message.loading('Enviando feedback');
            let values = {};
            values.date_admission = moment().format('YYYY-MM-DD');
            let apiUrl = '';
            setLoading(true);
            values.last_event = 1;
            values.feedback_result = 'Trabalhador Contratado';

            apiUrl = `sine/user/${user.user_id}/interest-status/${item.interest_id}`;

            api.post(apiUrl, values, (res) => {
                message.destroy();
                message.success('Seu feedback foi enviado! ');
                setLoading(false);
                window.gtagService('event', 'fastPositiveFeedBackSuccess');
                setActiveCurriculum(false);
                removeFromList(item);
            }, (e) => {
                setActiveCurriculum(false);
                message.destroy();
                if(e.message === 'Request failed') {
                    message.error('Não foi possível enviar esse feedback para o SINE, tente novamente.');
                } else if(e.message === 'Você alcançou o limite de recrutamentos sem retorno. Conclua seus recrutamentos pendentes para voltar a consultar.') {
                    message.error('Você alcançou o limite de recrutamentos sem retorno. Envie o feedback dos currículos em GERENCIAR VAGAS para continuar consultando.');
                } else {
                    message.error(e.message || 'Não foi possivel enviar o feedback deste profissional');
                }
                setLoading(false);
                window.gtagService('event', 'fastPositiveFeedBackFail');
            });
    }

    const removeFromList = (item) => {
        setCandidatesList(candidatesList.filter(i => i.interest_id !== item.interest_id))
    }

    const returnList = (list) => {
        return <List
            className='listAnt listAntCandidates'
            style={{overflow: 'auto', width: '100%'}}
            itemLayout="horizontal"
            dataSource={list}
            loading={searchLoading}
            renderItem={(item, index) => (
                <List.Item key={index} style={{padding: 0, position: 'relative', backgroundColor: 'white', }} >
                    <div style={{
                        backgroundColor: '#ededed',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        border: '1px solid #f7f7f7',
                        paddingLeft: 10,
                        paddingRight: 10,
                        fontSize: 12,
                        borderRadius: 4,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 0
                    }}>
                        {item.occupation_title || item.occupation_name} - #{item.opportunity_id}
                    </div>
                    <List.Item.Meta
                        style={{alignItems: 'center', padding: 10, paddingTop: 25, position: 'relative'}}
                        avatar={
                            item.profile_picture?
                                <img style={{width: 64, height: 64, borderRadius: '50%', objectFit: "cover", backgroundColor: '#f5f5f5'}} src={item.profile_picture}/>
                                :
                                <Avatar style={{width: 64, height: 64, padding: 10, backgroundColor: '#f5f5f5'}} size='default' src={Images.lettersPack[item.name[0].toLowerCase()] || Images.user_place_holder_2 }/>
                        }
                        title={<div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: `flex`}}>
                                <span style={{fontWeight: "bold"}}>{item.name}  </span>
                                {!item.viewed &&<div style={{ marginLeft: 10, fontSize: 10, borderRadius: 5, paddingLeft: 4, paddingRight: 4, background: `#c3ffc3`}}>
                                    <span style={{color: "#6bbe1d"}}> Novo </span>
                                </div>}
                            </div>
                            <div>
                                {item.age && <span style={{color: "darkgray", fontSize: 13, fontWeight: 400, marginRight: 20}}> {genders[item.gender]} - {item.age + ' Anos'} </span>}
                                {item.schooling && <span style={{color: "darkgray", fontSize: 13, fontWeight: 400, marginRight: 20}}> <FaBook style={{color: "darkgray", marginBottom: -2}}/> {item.schooling} </span>}

                            </div>
                        </div>}
                        description={
                            <div>
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <span>
                                            <BiBriefcaseAlt style={{color: "darkgray", marginBottom: -2}}/> {item.occupation} {(item.total_experience > 11)? ` - ${(item.total_experience/12).toFixed(0)} ano(s) de experiência` : (item.total_experience > 0) && ` - ${item.total_experience} meses de experiencia` }
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <span>
                                        <FaMapMarkerAlt style={{color: "darkgray", marginBottom: -2}}/> {item.city}
                                    </span>
                                </div>
                            </div>}
                    />
                    <Popover placement={"topRight"} content={'Enviar currículo para a etapa CONTRATADOS'} title={'Contratado'}>
                        <div className={`miniStatusButtons`} onClick={() => {
                            sendFeedback(item)
                            setActiveCurriculum(item);
                        }} style={{padding: 12, cursor: `pointer`, marginRight: 10, borderRadius: '50%', backgroundColor: item.status === `approved`? `#1680ce` : '#f5f5f5',boxShadow: '#ededed 0px 1px 2px 1px', display: 'flex', alignItems: 'center'}}>
                            <FaThumbsUp style={{color: 'rgb(134 214 95)', fontSize: 16}}/>
                        </div>
                    </Popover>
                </List.Item>
            )}
        />
    }

    useEffect(() => {
        window.scrollToElement('body');
        getCandidates()
    }, []);

    return (
        <div>
            <Modal bodyStyle={{padding: 0}} style={{top: 50}} width={800} visible={props.visible} footer={null} closable={!props.infoOnly} onCancel={() => !props.infoOnly && props.setVisible(false)}>
                <div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: 'column'
                    }}>
                        <div style={{display: "flex", justifyContent: "center", flexDirection: 'column'}}>
                            <div style={{padding: 20, boxShadow: '0px 2px 3px 0px #ededed', background: 'linear-gradient(45deg, #deffdf, white)'}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <img src={Images.rocket2}/>
                                    <h3 style={{color: `#000000a6`, marginLeft: 5, fontSize: 20, fontWeight: 'bold'}}>Ficamos feliz de ter ajudado <strong style={{color: 'green'}}>(:</strong> se puder poderia selecionar abaixo o trabalhador que encontrou?</h3>
                                </div>
                                <span style={{color: `#9e9e9e`, fontSize: 12}}>
                                    *Fique tranquilo(a), essas informações são utilizadas somente para estatísticas de empregabilidade, não vinculando a empresa ou empregador a nenhum outro banco de dados público ou privado.
                                </span>
                            </div>

                            <div style={{paddingTop: 15, paddingLeft: 25, paddingRight: 20}}>
                                <AutoComplete
                                    options={candidatesList}
                                    style={{width: '100%', borderRadius: 0}}
                                    className={'inputSearchCandidates'}
                                    onSearch={(e) => onSearch(e)}
                                >
                                    <Input.Search size="large" placeholder="Busque pelo nome ou CPF" />

                                </AutoComplete>

                            </div>

                        </div>

                        <div style={{paddingLeft: 20, paddingRight: 20, maxHeight: window.innerHeight - 330, minHeight: 400, paddingTop: 10, overflowY: "scroll"}}>

                            {searchLoading ?
                                <Loading/>
                                :
                                <>
                                    {returnList(candidatesList)}
                                </>
                            }

                        </div>
                        <div style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingRight: 20,
                            borderTop: "1px solid gainsboro",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            <div onClick={() => {
                                if(props.handleEndVacancy) props.handleEndVacancy();
                                if(props.setVisible) props.setVisible();
                            }}  className={'cardButton cardButtonGreen cardButtonGreenIcon'} style={{padding: 10, marginRight: 10, borderRadius: 5, backgroundColor: 'white', boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <div>
                                    <span> Informar Depois </span>
                                </div>
                            </div>
                            {props.handleEndVacancy &&
                            <div onClick={() => {
                                props.handleEndVacancy();
                                props.setVisible();
                            }}  className={'cardButton cardButtonGreen cardButtonGreenIcon'} style={{padding: 10,  borderRadius: 5, backgroundColor: 'white', boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FaRocket style={{fontSize: 15, marginRight: 5}}/>
                                <div>
                                    {props.infoOnly? <span> Fechar </span> : <span> Encerrar Publicacão </span>}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
};

CandidateSearchModal.propTypes = {};

export default CandidateSearchModal;
