import React, {useEffect, useRef, useState} from 'react';
import {Fade} from "react-reveal";
import {Button, Icon, Input, message} from "antd";
import useApi from "../Hooks/Api";
import {useHistory} from "react-router-dom";

import {Images} from "../Helpers/Images";
import RegisterPageModal from "./RegisterPageModal";

const PasswordRecovery = (props) => {
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const token = useRef(props.match.params.token);
    const api = useApi();
    let md5 = require('md5');
    const [registerModal, setRegisterModal] = useState(false);

    const doRecovery = (async (e) => {
        if(e) e.preventDefault();

        if(password !== "" && confirmPassword !== "") {
            if (password === confirmPassword) {
                if (password.length >= 8) {
                    api.post('access/change-password',{password: md5(password), token: token.current} ,(res) => {
                        message.info('Senha redefinida com sucesso!');
                        setRegisterModal(true)
                    }, (e) => {
                        message.error(e.message || 'Ocorreu um erro tente novamente');
                    });
                } else {
                    message.info(<span>  - Mínimo de 8 caracteres </span>, 10)
                }
            } else {
                message.info('As senhas devem ser iguais nos campos abaixo')
            }
        }
    });

    useEffect(() => {
        if(!props.match.params.token) {
            message.warning('Token invalido!');
            history.push('/');
        }
    },[]);

    return ( <div style={{display: 'flex', justifyContent: 'center', minHeight: 600}}>
        <div  style={{paddingTop: '70px'}}>
            <RegisterPageModal visible={registerModal} type={'login'} profile={'client'} setVisible={() => setRegisterModal(false)}/>
            <Fade mountOnEnter>
                <form>
                    <div className='loginFormMobile' style={{maxWidth: 500, margin: "auto", boxShadow: '0 0 10px 0 rgba(0,24,128,0.1)', padding: '30px', borderRadius: '10px'}}>

                        <div className='logo' style={{marginBottom: 50, textAlign: 'center'}}>
                            <img style={{maxWidth: 288}} src={Images.logo} />
                        </div>

                        <div>
                            <label style={{fontSize: 17, fontWeight: 'bold'}}> Nova senha </label>
                            <Input className='defaultInput' size={'large'}
                                   type={'password'}  placeholder={'Sua nova senha'} value={password} onChange={(e) => setPassword(e.target.value)} suffix={<Icon type="lock" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}/>
                        </div>

                        <div>
                            <label style={{fontSize: 17, fontWeight: 'bold'}}> Confirme sua nova senha  </label>
                            <Input className='defaultInput' size={'large'}
                                   type={'password'} placeholder={'Confirme sua nova senha'} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} suffix={<Icon type="lock" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}/>
                        </div>


                        <Button htmlType={'submit'} loading={api.loading} style={{width: '100%', marginTop: 25}} size={'large'} onClick={doRecovery}> Confirmar </Button>
                    </div>
                </form>
            </Fade>
        </div>
    </div>)
};
export default PasswordRecovery;
