import React from 'react';
import {Carousel, Col, Row} from "antd";
import {Fade} from "react-reveal";
import {Texts} from "../../../../Helpers/Texts";
import ListHeader from "./ListHeader";


const HireServicesBanner = (props) => {


    return (
        <div className='bannerAppsBackgroundService'>
            <div className="stars"></div>
            <div className="stars2"></div>


            <div  className="d-flex justify-content-center align-items-center">
                <ListHeader worker={props.worker} occupation_id={props.occupation_id} location={props.location} servicesTreeList={props.servicesTreeList} form={props.form} searchAction={props.searchAction}/>
            </div>
        </div>
    );
};

HireServicesBanner.propTypes = {};

export default HireServicesBanner;
