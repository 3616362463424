import React, {useEffect, useRef, useState} from 'react';
import {Col, Icon, List, message, Row} from "antd";
import useApi from "../../../../Hooks/Api";
import useAuth from "../../../../Hooks/Auth";
import Loading from "../../../Generic/Loading";
import SubmittedCard from "./CraftyPage/ComplimentsPage/SubmittedCard";
import NotFound from "../../../Generic/NotFound";
import ReceivedCard from "./CraftyPage/ComplimentsPage/ReceivedCard";
import PendingCard from "./CraftyPage/ComplimentsPage/PendingCard";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {Images} from "../../../../Helpers/Images";


const ComplimentsPage = props => {
    const api = useApi();
    const replyApi = useApi();
    const auth = useSelector((state) => state).authReducer;
    const complimentsList = useRef([]);
    const [loading,setLoading] = useState(true);
    const complimentType = useRef();
    const pagination = useRef({page: 0});
    const [user,setUser] = useState(auth);
    const [commentToReply, setCommentToReply] = useState(false);
    const history = useHistory();

    const getData = async () => {
        setLoading(true);
        api.get(`user/${auth.user_id}/reviews?page=${pagination.current.page + 1}&order=desc&type=${complimentType.current}`, (res) => {
            pagination.current = {nextPage: res.next_page, page: res.page};
            let auxCompliments = complimentsList.current.concat(res.reviews);
            complimentsList.current = auxCompliments;
            setLoading(false);
        }, (e) => {
            message.error('Ops, não foi possível recuperar seus endereços, tente novamente');
            setLoading(false);
        });
    };

    const doReply = async (item, value, index) => {
        if (value) {
            message.loading('Enviando resposta...');
            replyApi.post(`work-order/${item.work_order_id}/review/${item.rating_id}/reply`,{reply: value}, (res) => {
                message.destroy();
                message.success('Resposta enviada! ');
                complimentsList.current[index].reply = {content: value, created_at: moment()};
                setCommentToReply(false);
            }, (e) => {
                message.warning(e.message || 'Não foi possivel enviar sua resposta, tente novamente');
            });
        }
    }

    useEffect(() => {
        pagination.current.page = 0;
        complimentsList.current = [];
        if (props.match.params.type === 'submitted' || props.match.params.type === 'pending' || props.match.params.type === 'received') {
            complimentType.current = props.match.params.type;
        } else {
            complimentType.current = 'submitted';
        }
        getData();
        window.scrollToElement('body')
    },[props.match?.params?.type]);

    return (
        <>
            <Row style={{fontSize: 20, paddingLeft: 5, marginBottom: 15}}>
                {complimentType.current === 'submitted' ?
                    <div>
                        <Icon type={'like'}/>
                        <span > Suas avaliações realizadas </span> <br/>
                        <span style={{fontSize: 14, color: 'gray'}}> Avaliações realizadas por você de prestadores solicitados </span>
                    </div>
                    : (complimentType.current === 'received')?
                        <div>
                            <Icon type={'trophy'}/>
                            <span > Avaliações recebidas </span> <br/>
                            <span style={{fontSize: 14, color: 'gray'}}> Avaliações direcionadas à você de prestadores de serviços </span>
                        </div>
                        :
                        <div>
                            <Icon type={'clock-circle'}/>
                            <span > Avaliações pendentes </span>
                            <br/>
                            <span style={{fontSize: 14, color: 'gray'}}> Prestadores que você pode avaliar </span>
                        </div>
                }
            </Row>
            {loading?
                <div style={{margin: "auto"}}> <Loading/> </div>
                :
                <Row gutter={[10,20]}>
                    {(complimentType.current === 'submitted') ?
                        <List
                            className='listAnt listAntCompliments'
                            style={{overflow: 'auto', minHeight: '600px'}}
                            itemLayout="horizontal"
                            dataSource={complimentsList.current}
                            loading={api.loading}
                            renderItem={(item, index) => (
                                <SubmittedCard item={item} index={index} user={user}/>
                            )}
                        >
                            {complimentsList.current.length === 0 &&
                            <List.Item>
                                <div className={'empty-card empty-card-blue'}>
                                    <div style={{display: 'flex'}}>
                                        {complimentType.current === 'submitted' ?
                                            <NotFound img={Images.no_ratings_img} title={<span style={{fontSize: 25, fontWeight: 'bold'}}> Você não realizou nenhuma avaliação </span>} subTitle={'Aqui serão mostradas as suas avaliações realizadas.'}/>
                                            :
                                            <NotFound img={Images.no_ratings_img} title={<span style={{fontSize: 25, fontWeight: 'bold'}}>Não há nenhuma avaliação pendente </span>} subTitle={'Avalie os prestadores de serviço contratados por você.'}/>
                                        }
                                    </div>
                                </div>
                            </List.Item>
                            }
                        </List>
                        :(complimentType.current === 'received')?
                            <List
                                className='listAnt listAntCompliments'
                                style={{overflow: 'auto', minHeight: '600px'}}
                                itemLayout="horizontal"
                                dataSource={complimentsList.current}
                                loading={api.loading}
                                renderItem={(item, index) => (
                                    <ReceivedCard doReply={(item, value, index) => doReply(item,value, index)} item={item} index={index} user={user} setCommentToReply={(value) => setCommentToReply(value)} commentToReply={commentToReply}/>
                                )}
                            >
                                {complimentsList.current.length === 0 &&
                                <List.Item>
                                    <NotFound img={Images.no_ratings_img} title={<span style={{fontSize: 25, fontWeight: 'bold'}}> Você não recebeu nenhuma avaliação </span>} subTitle={'Aqui serão mostradas as suas avaliações recebidas'}/>
                                </List.Item>
                                }
                            </List>
                            :
                            <List
                                className='listAnt listAntCompliments'
                                style={{overflow: 'auto', minHeight: '600px'}}
                                itemLayout="horizontal"
                                dataSource={complimentsList.current}
                                loading={api.loading}
                                renderItem={(item, index) => (
                                    <PendingCard item={item} index={index} user={user}/>
                                )}
                            >
                                {complimentsList.current.length === 0 &&
                                <List.Item>
                                    <NotFound img={Images.no_ratings_img} title={ <span style={{fontSize: 25}}> Você não tem nenhuma avaliação pendente </span> }/>
                                </List.Item>
                                }
                            </List>
                    }
                    {pagination.current.nextPage&& <div style={{width: '100%', marginTop: 25, paddingBottom: 10}}>  <div className='loadMore2' onClick={()=> getData()}> <Icon type={'reload'}/> Carregar Mais </div> </div>}
                </Row>
            }
        </>
    );
};

ComplimentsPage.propTypes = {};

export default ComplimentsPage;
