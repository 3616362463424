import React from 'react'
import {Col, Modal, Row} from "antd";
import {Images} from "../../Helpers/Images";
import {Fade} from "react-reveal";
import {useHistory} from "react-router-dom";

const ModalAlertRegisterCurriculum = ({visible, close}) => {

    const history = useHistory()

    return (
        <div>
            <Modal visible={visible} onCancel={close} destroyOnClose style={{top:40}} footer={null} className='alert-curriculum' bodyStyle={{padding:0}} okText='Cadastrar meu currículo' cancelText='Cancelar'>
                <div>
                    <Row>
                        <Col xs={24} className={'sideLogin header-alert'}>
                            <div style={{padding: 20}}>
                                <Fade left>
                                    <div className={'logTitle'} style={{fontSize: 18}}>
                                        <span><strong>Vamos cadastrar seu currículo!</strong></span>
                                        <div className={'line'} style={{backgroundColor: "#173277", marginBottom: 10, marginTop: 10}}/>
                                    </div>
                                </Fade>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Fade>
                            <Col className='d-flex justify-content-center'>
                                <img style={{width:300}} src={Images.alertCurriculum} alt='Alert Curriculum'/>
                            </Col>
                        </Fade>

                        <div className='content-alert-text'>
                            <Fade right delay={500}>
                                <div>
                                    <span>Antes de se candidatar é necessário realizar o cadastro do seu currículo, é rapidinho!</span>
                                </div>
                            </Fade>
                        </div>
                        <div className='content-alert-button'>
                            <button onClick={() => history.push('/painel/prestador/editar-meu-curriculo')}>
                                Cadastrar agora
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}
export default ModalAlertRegisterCurriculum
