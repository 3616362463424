import React, {useEffect, useRef, useState} from 'react';
import {Icon, message, Modal,} from "antd";
import CandidateSearchModal from "../CandidateSearchModal";
import useApi from "../../../../../Hooks/Api";
import {useHistory, useParams} from "react-router-dom";
import EndVacancyModal from "../EndVacancyModal";
import moment from "moment";
import {FaCalendarAlt, FaCalendarDay, FaRegThumbsDown, FaRegThumbsUp, FaSadCry, FaSmile} from "react-icons/all";
import {FaUsers} from "react-icons/fa";
import {Images} from "../../../../../Helpers/Images";



const NumberList = ( n ) => {
    // Cria um array de inteiros de 1 até n
    const numbers = Array.from({ length: n }, (_, index) => index + 1);
    return numbers;
};

const VacancyEndFormComponent = ({enterpriseId, user}) => {
    const api = useApi();
    const [candidatesModal, setCandidatesModal] = useState(false)
    const paginate = useRef({
        page: 1,
    });
    const [loading, setLoading] = useState(true);
    const [showCards, setShowCards] = useState(null);
    const [step, setStep] = useState(0);

    //pego a ultima vaga vejo se tem curriculo e vejo se o cara ja respndeu a enquete se nao tiver respondido eu vou TRAVAR!
    const checkVacancies = (data) => {
        if(data.length > 0) {
            if(data[0].summary.approved === 0 && (data[0].summary.interview || data[0].summary.pending || data[0].summary.rejected || data[0].summary.selected)) {
                if(data[0].vacancy_filled_external === null && data[0].vacancy_filled_internal === null) {
                    setStep(0);
                    setShowCards(data[0]);
                }
            }
        }
    };

    const getVacancies = async () => {
        paginate.current.page = 1;
        api.get(`sine/company_opportunities/${enterpriseId}?page=${paginate.current.page}&limit=20&closed=true&order_by=newest`,(res) => {
            checkVacancies(res.opportunities);
        },(e) => {
            console.log(e);
        });
    };

    const sendData = (data) => {
        setLoading(true);
        api.post(`sine/user/${user.user_id}/results-opportunity/${showCards.id}`, data,(res) => {
            setLoading(false);
        },(e) => {
            setLoading(false);
            setShowCards(false);
        });
    }

    const handleEndForm = (data, showCandidateList) => {
        if(showCandidateList) {
            setCandidatesModal(true);
        } else {
            message.success("Agradecemos pela contribuição!")
            setCandidatesModal(false);
            setShowCards(null);
        }
        sendData(data);
    }

    const handleEndVacancy = async (data) => {
        if(data.vacancy_filled_external || data.vacancy_not_filled) {
            handleEndForm(data);
        } else {
            data.informed_hiring_qtd = 1; //JA SETA 1 no PELO (pq se o cara falo q encontrou alguem na vaga pela plataforma eu ja sei q foi pelo menos 1)
            sendData(data);
        }
    };

    const showFeedbackModal = (data) => {
        handleEndVacancy(data);
        if((showCards.summary.interview > 0 || showCards.summary.pending > 0 || showCards.summary.rejected > 0 || showCards.summary.selected > 0)) {
            if(showCards.summary?.approved === 0) { //VAI SEMPRE ENTRAR AQUI PELA VALIDACAO INICIAL DO ARQUIVO
                if(showCards.job_openings > 1) { //se tem mais de uma vaga vamo perguntar a quantidade pelo menosss
                    setStep(2);
                } else {
                    setCandidatesModal(true); //pergunto quem!
                }
            } else { //SE POR ALGUM MOTIVO DO ALEM QUE EH IMPOSSIVEL ENTRAR NESSE ELSE, SO POR GARANTIA DO ALEM EU TRATO TBEM... Redundancia da redundancia da redundancia programada.
                message.success("Agradecemos pela contribuição!")
                setCandidatesModal(false);
                setShowCards(null);
            }
        } else { // se nao tiver curriculo na vaga nao tem nem pq listar quais sao a gaelra e tbem nunca vai entrar aqui dado a validacao inicial
            message.success("Agradecemos pela contribuição!")
            setCandidatesModal(false);
            setShowCards(null);
        }
    }


    useEffect(() => {
        window.scrollToElement('body');
        if(enterpriseId) {
            getVacancies()
        }
    }, [enterpriseId]);


    return (
        <>
            {showCards ?
                <Modal
                       width={600}
                       visible={showCards}
                       footer={null}
                       closable={false}
                       bodyStyle={{background: 'linear-gradient(178deg, rgb(223, 247, 230), rgb(255, 255, 255) 50%)'}}
                       className={'fade-in'} style={{backgroundColor: 'white', transition: '200ms', paddingBottom: 0, paddingTop: 0, borderRadius: 5}}>


                    <div style={{ paddingBottom: 10, marginBottom: 20, transition: '200ms', textAlign: 'center', display: step === 0? 'block' : 'none'}}>
                        <img style={{maxHeight: 300}} src={Images.deal3}/>
                        <h2 style={{marginBottom: 4,  marginTop: 10, color: '#76b889'}}>Conseguiu preencher sua vaga? </h2>
                        <span style={{color: '#858585'}}>Conta pra gente, seu feedback é muito importante para nós!</span>
                    </div>

                    <div style={{ paddingBottom: 10, marginBottom: 20, transition: '200ms', textAlign: 'center', display: step === 1? 'block' : 'none'}}>
                        <div className={'back-side-button'} style={{display: 'flex', alignItems: 'center'}} onClick={() => {
                            setStep(0);
                        }}>
                            <Icon className={'craftyIcon'} style={{marginRight: 4}} type={'arrow-left'}/>
                            <span> Voltar </span>
                        </div>
                        <img style={{maxHeight: 300}} src={Images.highFive}/>
                        <h2 style={{marginBottom: 4, marginTop: 10, color: '#76b889'}}>Que legal! </h2>
                        <span style={{color: '#858585'}}>Encontrou algum trabalhador pela nossa plataforma?</span>
                    </div>

                    <div style={{ paddingBottom: 10, marginBottom: 20, transition: '200ms', textAlign: 'center', display: step === 2? 'block' : 'none'}}>
                        <img style={{maxHeight: 300}} src={Images.deal3}/>
                        <h2 style={{marginBottom: 4,  marginTop: 10, color: '#76b889'}}>Conseguiu preencher todas as vagas? </h2>
                        <span style={{color: '#858585'}}>Consegue nos informar quantas vagas você conseguiu preencher?</span>
                    </div>

                    <div className={'fade-in'} style={{
                        justifyContent: "center", marginTop: 20, flexWrap: 'wrap',  transition: '200ms', display: step === 2? "flex" : 'none',
                    }}>
                        {NumberList(showCards.job_openings).map((item, index) => <div key={index} onClick={() => handleEndForm({vacancy_filled_internal: true, informed_hiring_qtd: item, vacancy_filled_external: false, vacancy_not_filled: false}, true)}
                              className={'cardButton cardButtonGreen2 cardButtonGreenIcon'}
                              style={{padding: 5, paddingRight: 10, marginRight: 10, maxWidth: 100, margi: 3, paddingLeft: 10,  borderRadius: 5, borderTopLeftRadius: 0, borderBottomRightRadius: 0, backgroundColor: 'white', flex: 1,  display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div>
                                <span> {item} </span>
                            </div>
                        </div>)}
                    </div>
                    <div className={'fade-in'} style={{
                        justifyContent: "center", marginTop: 20,  transition: '200ms', display: step === 1? "flex" : 'none',
                    }}>
                        <div onClick={() => showFeedbackModal({vacancy_filled_internal: true, vacancy_filled_external: false, vacancy_not_filled: false})}   className={'cardButton cardButtonGreen2 cardButtonGreenIcon'} style={{padding: 5, paddingRight: 10, marginRight: 10, paddingLeft: 10,  borderRadius: 5, borderTopLeftRadius: 0, borderBottomRightRadius: 0, backgroundColor: 'white', flex: 1, minWidth: 200,  display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <FaRegThumbsUp style={{fontSize: 15, marginRight: 5}}/>
                            <div>
                                <span> Encontrei pela plataforma </span>
                            </div>
                        </div>
                        <div onClick={() => handleEndVacancy({vacancy_filled_internal: false, vacancy_filled_external: true, vacancy_not_filled: false})}  className={'cardButton cardButtonRed2 cardButtonRedIcon'} style={{padding: 5,  paddingRight: 10,  marginLeft: 10, paddingLeft: 10,  borderRadius: 5, borderTopRightRadius: 0, borderBottomLeftRadius: 0, backgroundColor: 'white', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <FaRegThumbsDown style={{fontSize: 15, marginRight: 5}}/>
                            <div>
                                <span> Não encontrei </span>
                            </div>
                        </div>
                    </div>

                    {step === 0 && <>
                    <h3 style={{color: '#595959'}}>Publicação:</h3>
                    <div style={{boxShadow: 'rgb(234 234 234 / 52%) 0px 2px 4px 2px', borderRadius: 10, textAlign: 'center',  padding: 20}}>
                        <span style={{fontSize: '18px', fontWeight: 'bold'}}>{showCards.occupation_title? showCards.occupation_title : showCards.occupation_name}  </span> <br/>
                        <span style={{fontSize: 13, color: "#858585"}}> {showCards.occupation_title? (showCards.occupation_name + " / " + showCards.job_type[0]) : showCards.job_type[0] }</span> <br/>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <FaUsers style={{fontSize: 15, color: "#9e9e9e", marginRight: 3}}/>
                            <span style={{fontSize: 14, color: "#9e9e9e"}}> {showCards.summary.approved + showCards.summary.interview + showCards.summary.selected + showCards.summary.rejected + showCards.summary.pending} inscritos</span>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center', marginRight: 10, justifyContent: 'center'}}>
                                <FaCalendarDay style={{fontSize: 13, color: "#9e9e9e", marginRight: 3}}/>
                                <span style={{fontSize: 13, marginTop: 2, color: "#9e9e9e"}}> Iniciada em <strong>{moment(showCards.start_at).format('DD/MM')}</strong></span>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', marginLeft: 10, justifyContent: 'center'}}>
                                <FaCalendarAlt style={{fontSize: 13, color: "#9e9e9e", marginRight: 3}}/>
                                <span style={{fontSize: 13, marginTop: 2, color: "#9e9e9e"}}> Encerrada em <strong>{moment(showCards.closed_at).format('DD/MM')}</strong></span>
                            </div>

                        </div>

                        <div className={'fade-in'} style={{
                            justifyContent: "center", marginTop: 20,  transition: '200ms', display: step === 0? "flex" : 'none',
                        }}>

                            <div onClick={() =>  setStep(1)}
                                 className={'cardButton cardButtonGreen cardButtonGreenIcon'}
                                 style={{padding: 10,  borderRadius: 5, backgroundColor: 'white', marginRight: 10, boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FaSmile style={{fontSize: 15, marginRight: 5}}/>
                                <div>
                                    <span> Consegui preencher </span>
                                </div>
                            </div>
                            <div onClick={() => handleEndVacancy({vacancy_not_filled: true, vacancy_filled_external: false, vacancy_filled_internal: false})}
                                 className={'cardButton cardButtonRed cardButtonRedIcon'}
                                 style={{padding: 10,  borderRadius: 5, backgroundColor: 'white', marginLeft: 10, boxShadow: '#e8e8e8 1px 1px 6px 1px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FaSadCry style={{fontSize: 15, marginRight: 5}}/>
                                <div>
                                    <span> Não consegui </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>}
                </Modal>
                :
                null
            }
            {candidatesModal && <CandidateSearchModal infoOnly={true} opportunity_id={showCards.id} handleEndVacancy={() => {
                message.success("Agradecemos pela contribuição!");
                setCandidatesModal(false);
                setShowCards(null);
            }} visible={candidatesModal} setVisible={() => {
                setCandidatesModal(false);
                setShowCards(false);
            }} enterprise={{id:enterpriseId}}/>}
        </>
    );
};

VacancyEndFormComponent.propTypes = {};

export default VacancyEndFormComponent;
