import React ,{useState} from 'react'
import {Col} from "antd";
import PropTypes from "prop-types";

const InputCustom = ({xs,sm,md,lg, maxLength, nameField, textField, error , mb, valueField }) => {

  return (
      <Col xs={xs} sm={sm} md={md} lg={lg} style={{marginBottom: mb}} className="form__div">
        <input type='text'
               name={nameField}
               className={`${error} form__input`}
               maxLength={maxLength}
               onChange={(e)=> valueField(e.target.value) }
               placeholder=" "
        />
        <label htmlFor="" className="form__label">{textField}</label>
      </Col>
  )
}

//Props que meu componente recebe:
// maxLength: tamanho máximo do campo,
// textField: nome do campo,
// valueField: valor do campo,
// error: insere a classe de erro,
// mb: margin Bottom,
// mask: qual mascara,
// xs,sm,md,lg: tamanho do campo (precisa de pelo menos 1)


export default InputCustom

InputCustom.propTypes = {
  textField: PropTypes.string,
  nameField:PropTypes.string,
  valueField:PropTypes.string,
  maxLength: PropTypes.number,
  xs:PropTypes.number,
  sm:PropTypes.number,
  md:PropTypes.number,
  lg: PropTypes.number,
}