import React, { useState} from 'react'
import {message, Modal} from "antd";
import moment from "moment";
import {FaBullhorn} from 'react-icons/fa'
import EndVacancyModal from "../../Components/PageComponents/Restrict/ClientPanelPage/EndVacancyModal";
import {useHistory} from "react-router-dom";
import useApi from "../../Hooks/Api";
import CandidateSearchModal from "../../Components/PageComponents/Restrict/ClientPanelPage/CandidateSearchModal";

const VacancyCloseToClosingModal = ({details, handleAddTime, removeBadge, ...props}) => {
    const [showModal, setShowModal] = useState(true);
    const history = useHistory();
    const api = useApi();

    const [showEndVacancy, setShowEndVacancy] = useState('hidden');
    const [candidatesModal, setCandidatesModal] = useState(false);

    const handleEndVacancy = (data) => {
        api.post(`sine/user/${props.user.user_id}/close-opportunity/${details.id}`, data,(res) => {
            removeBadge();
            props.setRefetch(true)
            message.success("Seu anúncio foi encerrado!");
            setShowEndVacancy('hidden');
            props.setRefetch(false);
            setShowModal(!showModal);
            history.push('/painel/cliente/vagas/' + props.enterprise.id +'/vagas-abertas')
        },(e) => {
            if(e.message === "Oportunidade já encerrada") {
                props.setRefetch(true);
                setShowEndVacancy('hidden');
                setCandidatesModal(false);
                props.setRefetch(false);
                history.push('/painel/cliente/vagas/' + props.enterprise.id + '/vagas-abertas');
                setShowModal(false)
            } else {
                console.log(e);
                message.warning(e.message || "Não foi possível encerrar a publicação tente novamente");
            }
        });
    };

    const showFeedbackModal = (data) => {
        if(details.summary.pending > 0) {
            if(details.summary.approved === 0) {
                setCandidatesModal(true);
            } else {
                handleEndVacancy(data);
            }
        } else {
            handleEndVacancy(data);
        }
    }

    return (
        <>

            {candidatesModal && <CandidateSearchModal infoOnly={true}
                                                      opportunity_id={details?.id}
                                                      handleEndVacancy={() => handleEndVacancy({vacancy_filled_internal: true, informed_hiring_qtd: 1, vacancy_filled_external: false, vacancy_not_filled: false})}
                                                      visible={candidatesModal}
                                                      setVisible={() => {
                                                          setCandidatesModal(false);
                                                      }}
                                                      enterprise={props.enterprise}
            />}

            <Modal visible={showModal}
                   closable={true}
                   onCancel={() => setShowModal(!showModal)}
                   width={window.innerWidth < 767 ? '80%' : 400}
                   destroyOnClose={true}
                   footer={false}
            >
                <div>

                    <h4 className={'da-flex flex-column justify-content-center'} style={{ fontSize:26, color:'#333', marginBottom:0}}>
                       <span className={'da-flex justify-content-center'} style={{gap: "10px", color: "#dc3646", padding: "4px 15px", borderRadius: "30px"}}>
                           <FaBullhorn/>
                           Atenção!
                       </span>
                    </h4>
                    <p style={{textAlign:'center', color:'#dc3646'}}>Divulgação próxima de expirar</p>
                    <div className={'d-flex flex-column'} style={{  gap: "10px", marginBottom: "1em", border: "1px solid gainsboro", borderRadius: "5px", padding: "8px", background: "#f5f5f5"}}>
                        <p className={'da-flex'} style={{marginBottom:0, gap:5}}>
                            <strong>Vaga:</strong>{details.occupation_title}
                        </p>
                        {details.summary.pending + details.summary.interview + details.summary.selected > 0 &&
                            <p className={'da-flex'} style={{marginBottom:0, gap:5}}>
                                <strong>Currículos pendentes:</strong> <span style={{color: '#dc3545'}}>{details.summary.pending + details.summary.interview + details.summary.selected}</span>
                            </p>
                        }


                        <p className={'da-flex'} style={{margin:-9, marginTop:0, gap:5, color: '#dc3545', background: "rgb(220 54 70 / 20%)", padding:'4px 8px', borderRadius:4}}>
                            <strong>Encerramento em:</strong> <span>{moment(details.end_date).format("DD/MM/YYYY")} ({moment(details.end_date).format("dddd")})</span>
                        </p>
                    </div>
                    <p style={{textAlign:'justify'}}>
                        A divulgação da vaga de emprego será encerrada em breve. <strong>Todos</strong> os candidatos não finalizados serão reprovados.
                    </p>
                    <div style={{display:'flex', flexWrap:"wrap", gap:10}}>
                        <button className={'ant-btn ant-btn-primary'} onClick={() => {
                            setShowModal(!showModal)
                            handleAddTime();
                        }} style={{flex:1}}>
                            Prorrogar encerramento em 15 dias
                        </button>
                        <div style={{margin:'auto'}}>
                            <button className={'ant-btn ant-btn-link'} onClick={() => {
                                setShowEndVacancy('initial');
                            }} style={{flex:1, color:'#dc3545', border:'1px solid'}}>
                                Encerrar vaga
                            </button>
                        </div>

                    </div>
                </div>
            </Modal>
            <EndVacancyModal handleEndVacancy={(data) => handleEndVacancy(data)}
                             setShowEndVacancy={(data) => setShowEndVacancy(data)}
                             showEndVacancy={showEndVacancy}
                             showFeedbackModal={(data) => showFeedbackModal(data)}
            />
        </>
    )

}

export default VacancyCloseToClosingModal
