import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, DatePicker, Form, Input, List, Menu, message, Modal, Popover, Select, Spin} from "antd";

import useApi from "../../../../Hooks/Api";
import moment from "moment";
import locale from "antd/es/date-picker/locale/pt_BR";
import {newFeedbackType} from "../../../../Helpers/AuxVariables";


const FeedbackStepsMultipleModal = ({checkedList, checkedCount, userId, vacancyId, setCheckedList, getCandidates}) => {

    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(undefined);
    const [observation, setObservation] = useState(undefined);
    const [qualifications, setQualifications] = useState(undefined);
    const [date_admission, setDateAdmission] = useState(moment());
    const [showConfirmation, setShowConfirmation] = useState(false);


    const getMotive = (value) => {
        for(let i = 0; i < newFeedbackType.length; i++) {
            if(newFeedbackType[i].type === value) {
                return newFeedbackType[i].value;
            }
        }
        return '';
    }

    const sendMultipleFeedback = async () => {
        let objToSend = {};
        if(selectedValue === 11 && !qualifications) {
            message.warning('Informe a qualificação');
            return 0;
        }
        if(qualifications && !observation) {
            objToSend.observation = qualifications
        } else if(observation) {
            objToSend.observation = observation;
        }

        if (selectedValue === 1 && !date_admission) {
            message.warning('Informe a data');
            return 0;
        } else if(selectedValue === 1 && date_admission) {
            objToSend.date_admission =  moment(date_admission).format('YYYY-MM-DD');
        }

        objToSend.last_event = selectedValue;
        objToSend.qualifications = qualifications;
        objToSend.interests_ids = checkedList;
        objToSend.feedback_result = getMotive(selectedValue);

        setLoading(true);
        message.loading('Enviando feedback');

        api.post(`sine/user/${userId}/interest-batch-status/${vacancyId}`, objToSend, (res) => {
            message.destroy();
            message.success('Seu feedback foi enviado! ');
            setCheckedList();
            setLoading(false);
            window.gtagService('event', 'multipleFeedBackSuccess');
            getCandidates();
        }, (e) => {
            message.destroy();
            if(e.message === 'Request failed') {
                message.error('Não foi possível enviar esse feedback para o SINE, tente novamente.');
            } else if(e.message === 'Você alcançou o limite de recrutamentos sem retorno. Conclua seus recrutamentos pendentes para voltar a consultar.') {
                message.error('Você alcançou o limite de recrutamentos sem retorno. Envie o feedback dos currículos em GERENCIAR VAGAS para continuar consultando.');
            } else {
                message.error(e.message || 'Não foi possivel enviar o feedback deste profissional');
            }
            setLoading(false);
            window.gtagService('event', 'multipleFeedBackFail');
        });
    }

    return (<div style={{padding: 20, boxShadow: '-1px -1px 4px #eeeeee', position: 'fixed', bottom: 10, right: 10, maxWidth: 500, borderRadius: 5, backgroundColor: 'white'}}>
            {!showConfirmation ? <>
                    <h3 style={{borderBottom: '1px solid', color: '#005c9f'}}>Informe a situação de {checkedCount} currículo(s)</h3>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {newFeedbackType.map((item, index) =>
                            <div style={{
                                padding: 5,
                                cursor: 'pointer',
                                fontWeight: selectedValue === item.type ? 'bold' : 'normal',
                                backgroundColor: selectedValue === item.type ? 'aliceblue' : 'white',
                                color: selectedValue === item.type ? '#005c9f' : '#757575',
                                borderRadius: 5,
                                border: selectedValue === item.type ? '1px solid #005c9f' : '1px solid gainsboro',
                                margin: 5,
                                paddingRight: 10,
                                paddingLeft: 10
                            }} key={index}
                                 onClick={() => {
                                     setSelectedValue(item.type)
                                 }}>{item.label || item.value}
                            </div>
                        )}
                    </div>

                    {selectedValue === 11 &&
                        <Form.Item required={false} style={{padding: 5}}
                                   label="Selecione a qualificação que não foi atendida">
                            <Select style={{width: '100%'}} value={qualifications} onChange={(e) => setQualifications(e)}
                                    placeholder="Selecione aqui">
                                <Select.Option value={'Capacitação'}>Capacitação</Select.Option>
                                <Select.Option value={'Distância entre residência e local de trabalho'}>Distância entre
                                    residência e local de trabalho</Select.Option>
                                <Select.Option value={'Apresentação pessoal'}>Apresentação pessoal</Select.Option>
                            </Select>
                        </Form.Item>
                    }

                    {selectedValue === 1 &&
                        <Form.Item style={{padding: 5}} label="Data de admissão" required={false}>
                            <DatePicker value={date_admission} onChange={(e) => setDateAdmission(e)} locale={locale}
                                        style={{width: "100%"}} format={"DD/MM/YYYY"}/>
                        </Form.Item>
                    }

                    {(selectedValue && selectedValue !== 1) &&
                        <Form.Item style={{padding: 5}} label="Descreva com mais detalhes o motivo se necessário">
                            <Input.TextArea value={observation} onChange={(e) => setObservation(e.target.value)} rows={3}/>
                        </Form.Item>}

                    {selectedValue && <div style={{textAlign: 'center'}}>
                        <Button type={"primary"} onClick={(e) => setShowConfirmation(true)}> Enviar Retorno </Button>
                    </div>}
                </>
                :
                <>
                    <div style={{textAlign: 'center', fontSize: 20}}>
                        <span style={{color: '#424242', fontSize: 20}}> Voce está prestes a enviar o retorno de <strong> {checkedCount} </strong> currículo(s). </span>
                        <br/>
                        <span style={{color: '#424242', fontSize: 16}}> <strong>Motivo - </strong> {getMotive(selectedValue)} </span>

                        <div style={{display: 'flex', marginTop: 15, justifyContent: "space-around"}}>
                            <Button type={"default"} onClick={(e) => setShowConfirmation(false)}> Voltar </Button>
                            <Button loading={loading} type={"primary"} onClick={(e) => sendMultipleFeedback()}> Enviar Retorno </Button>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

FeedbackStepsMultipleModal.propTypes = {
    loading: PropTypes.any,
    resumesList: PropTypes.any,
    cboId: PropTypes.any,
    showDrawer: PropTypes.any
};

export default FeedbackStepsMultipleModal;
