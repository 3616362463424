import React from 'react'
import {Input} from "antd";

const SyEmail = ({data, setValue, value}) => {

    return (
        <div>
            <div>
                <Input id={data.label_key}
                       value={value}
                       onChange={(e) => setValue(e.target.value)}
                       placeholder={"exemplo@email.com"}
                />
            </div>
        </div>
    )
}

export default SyEmail
