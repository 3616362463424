const genders = [
    {
        "label": "Masculino",
        "value": "male"
    },
    {
        "label": "Feminino",
        "value": "female"
    },
    {
        "label": "Homem transgênero",
        "value": "transgender_male"
    },
    {
        "label": "Mulher transgênero",
        "value": "transgender_female"
    },
    {
        "label": "Homem Transexual",
        "value": "transsex_male"
    },
    {
        "label": "Mulher Transexual",
        "value": "transsex_female"
    },
    {
        "label": "Não soube responder",
        "value": "unknown"
    },
    {
        "label": "Preferiu não responder",
        "value": "not_anwswered"
    },
    {
        "label": "Outros",
        "value": "other"
    }
]
const newFeedbackType = [
    {type: 1, value: 'Trabalhador Contratado'},
    /*{type: 2, value: 'Não foi contatado', label: 'Não entrei em contato'},*/
    {type: 7, value: 'Vaga preenchida de outra forma'},
    {type: 8, value: 'Falta de interesse do trabalhador em ocupar o cargo'},
    /*{type: 9, value: 'Currículo desconsiderado pois a vaga foi encerrada'},*/
    {type: 11, value: 'Não possui as qualificações exigidas para a ocupação'},
    /*{type: 12, value: 'O candidato mora longe do local de trabalho'},*/
]

const newFeedbackTypeRejected = [
    /*{type: 2, value: 'Não foi contatado'},*/
    /*{type: 3, value: 'Não aprovado no processo seletivo'},*/
    {type: 7, value: 'Vaga preenchida de outra forma'},
    {type: 8, value: 'Falta de interesse do trabalhador em ocupar o cargo'},
    /*{type: 9, value: 'Currículo desconsiderado pois a vaga foi encerrada'},*/
    {type: 11, value: 'Não possui as qualificações exigidas para a ocupação'},
   /* {type: 12, value: 'O candidato mora longe do local de trabalho'},*/
]
export {genders, newFeedbackType, newFeedbackTypeRejected}


/*
const newFeedbackType = [
    {type: 1, value: 'Trabalhador Contratado'},
    {type: 2, value: 'Não foi contatado', label: 'Não entrei em contato'},
    {type: 7, value: 'Não foi contratado, vaga preenchida de outra forma'},
    {type: 8, value: 'Não foi contratado, falta de interesse do trabalhador em ocupar o cargo'},
    {type: 11, value: 'Não foi contratado. Não possui as qualificações exigidas para a ocupação'},
]*/
