import React, {useEffect, useState} from 'react';
import {Col, Row} from 'antd';
import {Texts} from "../../../../Helpers/Texts";
import {Images} from "../../../../Helpers/Images";
import {Env} from "../../../../Helpers/Env";
import { RiBook2Fill, RiStore2Fill, RiFileUserFill, RiFileTextFill } from "react-icons/ri";
import useApiClub from "../../../../Hooks/ApiClub";
import {FaGraduationCap} from "react-icons/all";


const CounterComponent = () => {
    const [data, setData] = useState(null);
    const api = useApiClub();

    const getData = () => {
        api.get(`sum-info/${Env.application_alias}`, (res) => {
            console.log(res.object)
            setData(res.object)
        }, (e) => {
            console.log(e)
        })
    }

    useEffect(() => {
        getData()
    },[])

    return (

        <Row style={{minHeight: '300px', display: 'flex', justifyContent: 'center', backgroundSize: 'cover', overflow: 'hidden', marginTop: '150px', backgroundImage: `url(${Images.home.counter_img})`}}>
            <div className='bannerAppsBackground' style={{height: "100%", opacity: '0.9'}}/>
            <Col style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Row style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap"}}>
                    <Col span={4} className='numbersCountUp'>
                        <div className="container-icons">
                            <RiFileUserFill style={{fontSize: 65, color: "#0d6efd"}}/>
                        </div>
                        <div>
                            <div style={{width: "100%", marginLeft: 10}}>
                                <span className="number-counter">
                                    {data?.curriculum}
                                </span>
                            </div>
                            <div style={{marginLeft: 10}}>
                                <span>
                                    {Texts.home_page.counter.title1}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={4} className='numbersCountUp'>
                        <div className="container-icons">
                            <RiStore2Fill style={{fontSize: 65, color: "#6f42c1"}}/>
                        </div>
                        <div>
                            <div style={{width: "100%", marginLeft: 10}}>
                                <span className="number-counter">
                                    {data?.company}
                                </span>
                            </div>
                            <div style={{marginLeft: 10}}>
                                <span>
                                    {Texts.home_page.counter.title2}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={4} className='numbersCountUp'>
                        <div className="container-icons">
                            <RiFileTextFill style={{fontSize: 65, color: "#ffc107"}}/>
                        </div>
                        <div>
                            <div style={{width: "100%", marginLeft: 10}} >
                                <span className="number-counter">
                                    {data?.opportunity}
                                </span>
                            </div>
                            <div style={{marginLeft: 10}}>
                                <span>
                                    {Texts.home_page.counter.title4}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={4} className='numbersCountUp'>
                        <div className="container-icons">
                            <RiBook2Fill style={{fontSize: 65, color: "#20c997"}}/>
                        </div>
                        <div>
                            <div style={{width: "100%", marginLeft: 10}} >
                                <span className="number-counter">
                                    {data?.course?.total_courses}
                                </span>
                            </div>
                            <div style={{marginLeft: 10}}>
                                <span>
                                    CURSOS JÁ DISPONIBILIZADOS
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

CounterComponent.propTypes = {};

export default CounterComponent;
