import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Col, DatePicker, Form, Icon, message, Row, Select} from "antd";
import GenericInput from "../../../../Generic/GenericInput";
import NumberFormat from "react-number-format";
import locale from "antd/lib/date-picker/locale/pt_BR";
import {useValidationShorts} from "../../../../../Hooks/Helpers";
import moment from "moment";
import GenericCropImage from "../../../../Generic/GenericCropImage";
import useApi from "../../../../../Hooks/Api";
import useAuth from "../../../../../Hooks/Auth";
import {useDispatch} from "react-redux";


const ProfilePersonalForm = props => {
    const {getFieldDecorator, getFieldError, getFieldsValue} = props.form;
    const {numMinWithMask,isValidDate, required, minAge} = useValidationShorts();
    const api = useApi();
    const [loadingImage, setLoadingImage] = useState(false);
    const {changeAvatar} = useAuth();
    const dispatch = useDispatch();

    const sendImage = async (item) => {
        setLoadingImage(true);
        let auxFile = item.slice(23);
        api.post('register/upload/profile-picture', {profile_picture: auxFile, filename: 'avatar.jpeg'}, (res) => {
            setLoadingImage(false);
            props.getData();
            changeAvatar(res.profile_picture);
            dispatch({type: 'change_avatar', avatar: 'https://crafty-api-dev.s3.amazonaws.com/public/thumbs/'+res.profile_picture});
        }, (e) => {
            setLoadingImage(false);
            message.error('Nao foi possivel trocar sua imagem, tente novamente');
        });
    };

    return (
        <>
            <Row className={'primary-sub-color'} style={{fontSize: 17, marginBottom: 20}}>
                <Icon type={'user'}/>
                <span > Dados Pessoais </span>
            </Row>
            <Row style={{padding: 20, display: 'flex', flexFlow: window.innerWidth <= 768&& 'column', backgroundColor: 'white', borderRadius: 10, boxShadow: '0 2px 8px rgba(0,0,0,.08)'}}>
               {/* <GenericCropImage loading={loadingImage} image={() => props.user?.profile_picture?.full} setCropedImg={(item) => sendImage(item)} height={360} width={360} circularCrop={true}/>
                <div style={{width: 1, background: "gainsboro", marginLeft: 15, marginRight: 15}}/>*/}
                <Row style={{width: '100%'}}>
                    <Col span={24}>
                        <Row gutter={[24,6]}>
                            <Col xs={24} sm={12} md={12} >
                                <GenericInput initialValue={props.user.first_name} suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: 16}}/>} required={true} form={props.form} error={getFieldError('first_name')} name='first_name' label='Nome' placeholder='Digite seu Nome' valueForMemo={getFieldsValue().first_name} />
                            </Col>
                            <Col xs={24} sm={12} md={12}>
                                <GenericInput initialValue={props.user.last_name} suffix={<Icon type="team" className={'primary-sub-color'} style={{ fontSize: 16}}/>} required={true} form={props.form} error={getFieldError('last_name')} name='last_name' label='Sobrenome' placeholder='Digite seu Sobrenome' valueForMemo={getFieldsValue().last_name} />
                            </Col>

                        </Row>
                        <Row gutter={[24,6]}>
                            <Col xs={24} sm={24} md={12}>
                                <GenericInput initialValue={props.user.nickname} suffix={<Icon type="font-colors" className={'primary-sub-color'} style={{fontSize: 16}}/>} required={false} form={props.form} error={getFieldError('nickname')} name='nickname' label='Apelido' placeholder='Digite seu Apelido' valueForMemo={getFieldsValue().nickname} />
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    className={'hideDots'}
                                    label={<span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 16}}><strong > Seu CPF </strong> </span>}>
                                    <NumberFormat value={props.user.cpf} suffix={<Icon type="number" style={{fontSize: 16}}/>} disabled={true} style={{width: '100%'}} className='ant-input ant-input-lg primary-sub-color' format={'###.###.###-##'} mask="_"/>
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row gutter={[24,6]}>

                            <Col xs={24} sm={12} md={12}>
                                <Form.Item required={true} className='hideDots' validateStatus={getFieldError('gender')&& 'error'} help={getFieldError('gender')} label={<span style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> Seu gênero</span>}>
                                    {getFieldDecorator('gender', {
                                        initialValue: props.user.gender,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Campo Obrigatório',
                                            },
                                        ],
                                    })(<Select
                                        mode="default"
                                        style={{ width: '100%' }}
                                        placeholder={"Escolha um Gênero"}
                                        allowClear={false}
                                        size={'large'}
                                        suffixIcon={<Icon type="down" className={'primary-sub-color'} style={{fontSize: 16}}/>}
                                    >

                                        <Select.Option value={'male'}> Masculino </Select.Option>
                                        <Select.Option value={'female'}> Feminino </Select.Option>
                                        <Select.Option value={'transgender_male'}> Homem transgênero </Select.Option>
                                        <Select.Option value={'transgender_female'}> Mulher transgênero </Select.Option>
                                        <Select.Option value={'transsex_male'}> Homem Transexual </Select.Option>
                                        <Select.Option value={'transsex_female'}> Mulher Transexual </Select.Option>
                                        <Select.Option value={'unknown'}> Não sei responder </Select.Option>
                                        <Select.Option value={'not_anwswered'}> Prefiro não responder </Select.Option>
                                        <Select.Option value={'other'}> Outros </Select.Option>
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12}>
                                <Form.Item required={true} className={'datePickerWidth hideDots'} validateStatus={getFieldError('birthday')&& 'error'} help={getFieldError('birthday')} label={<span style={{fontSize: 16, color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}> Data de nascimento </span>}>
                                    {getFieldDecorator('birthday', {
                                        initialValue: moment(props.user.birthday).format('DD/MM/YYYY'),
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Campo Obrigatório',
                                            },
                                            isValidDate()
                                        ],
                                    })(
                                        <NumberFormat className={'ant-input ant-input-lg'} style={{marginTop: 5}} format="##/##/####" placeholder="DD/MM/AAAA"/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
            </>
    );
};

ProfilePersonalForm.propTypes = {
    user: PropTypes.any
};

export default ProfilePersonalForm;
