import React, {useEffect, useState} from 'react';
import {Button, Col, Icon, Row} from "antd";
import {Images} from "../../../../Helpers/Images";
import {useHistory} from "react-router-dom";
import useVerifyServices from "../../../../Hooks/VerifyServices";
import useLocalStorage from "../../../../Hooks/Storage";
import { FaFacebookF, FaUserAlt} from "react-icons/fa";
import {Popover} from "antd/es";
import {Texts} from "../../../../Helpers/Texts";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";
import midiabg from "../../../../Img/midiaBg.png";
import {Env} from "../../../../Helpers/Env";
import {useVerifyShowPanel} from "../../../../Hooks/Helpers";
import {useSelector} from "react-redux";



const WorkerPanelComponent = () => {
    const history = useHistory();
    const {hasSineService} = useVerifyServices();
    const fullCurriculum = useLocalStorage(StorageVariables.updatedProfile)?.getObject();
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile)?.getPrimitive();
    const [hasSineProfile, setHasSineProfile] = useState(fullCurriculum?.user_id || hasProfileStorage === 'true');
    const preferences = useSelector(state => state).preferencesReducer;
    const { redirectToPanelByParam} = useVerifyShowPanel(history ,preferences.panel_profile)

    useEffect(() => {
        window.scrollToElement('body');
        if(fullCurriculum?.user_id || hasProfileStorage === 'true') {
            setHasSineProfile(true);
        } else {
            setHasSineProfile(false);
        }
    },[fullCurriculum?.user_id, hasProfileStorage]);

    return (<Row gutter={[24, 24]}  style={{margin: 0, display: 'flex', flexWrap: 'wrap'}}>
            <Col span={24} style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 3, paddingTop: 0}}>
                <h1 style={{margin: 0, paddingLeft: 10, fontSize: 25,color: "#757575"}}> Painel de Trabalhador </h1>
                <Popover placement={"bottom"} title={'Painel Contratante'} content={Texts.popover_client_panel}> <Button className={'ant-btn-gray'} style={{borderRadius: 15}}  onClick={() => redirectToPanelByParam(true)}> Ver painel contratante <FaUserAlt  className={'exportedIcons'}/> </Button> </Popover>

            </Col>
            {hasSineService() && <>
                {!hasSineProfile ?
                    <Col xs={24} md={24} xl={24}>
                        <Row className={'profileCol borderBottomRed'} onClick={() => {
                            history.push('/painel/prestador/editar-meu-curriculo')
                        }}>
                            <Col span={12}>
                                <h1 className={'red'} style={{fontWeight: 'bold'}}> Currículo não cadastrado </h1>
                                <span> Seu currículo não está completo. Clique abaixo para adicionar as informações necessárias para divulgar seu currículo e se candidatar a vagas de emprego</span> <br/> <br/>
                            </Col>
                            <Col span={12}>
                                <img src={Images.workerPanel.incomplete_resume}/>
                            </Col>
                        </Row>
                    </Col>
                    :
                    <>
                        <Col xs={24} md={12} xl={12}>
                            <Row className={'profileCol borderBottomTertiary'} onClick={() => {
                                history.push('/painel/prestador/curriculo-completo')
                            }}>
                                <Col span={12}>
                                    <h1 className={'tertiary-color'} style={{fontWeight: 'bold'}}> Visualize ou edite seu currículo </h1>
                                    <span> Visualize seu currículo em uma única página! Tudo já organizado para a impressão. </span> <br/> <br/>
                                </Col>
                                <Col span={12}>
                                    <img src={Images.workerPanel.view_resume}/>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Row className={'profileCol borderBottomSecondary'} onClick={() => {
                                history.push('/vagas-de-emprego')
                            }}>
                                <Col span={12}>
                                    <h1 className={'secondary-color'} style={{fontWeight: 'bold'}}> Vagas </h1>
                                    <span> Veja aqui todas as vagas disponíveis na região de {Env.state_restriction} e envie seu currículo para as vagas que tiver interesse! </span> <br/> <br/>
                                </Col>
                                <Col span={12}>
                                    <img src={Images.workerPanel.vacancy_painel}/>
                                </Col>
                            </Row>
                        </Col>
                    </>
                }
            </>}

        <Col xs={24} lg={12} xl={8}  >
            <Row className={'profileCol borderBottomPrimary'} onClick={() => {history.push('/buscar-cursos')}} >
                <Col span={12}>
                    <h1 className={'primary-color'} style={{fontWeight: 'bold'}}> Cursos </h1>
                    <span> Veja os cursos que estão disponíveis na plataforma. </span> <br/> <br/>
                </Col>
                <Col span={12}>
                    <img src={Images.panel.profile_card}/>
                </Col>
            </Row>
        </Col>



        {/* {hasCraftyService() && <Col xs={24} md={24} xl={12}>
                <Row className={'profileCol borderBottomFifth'} onClick={() => history.push('/painel/cliente/avaliacoes/pending')}>
                    <Col span={12}>
                        <h1 style={{color: '#e0c532'}}> Ver Avaliações </h1>
                        <span> Realize aqui as avaliações dos prestadores que você contratou, veja o histórico das avaliações realizadas por você de outros profissionais, assim como as avaliações recebidas! </span> <br/> <br/>
                    </Col>
                    <Col span={12}>
                        <img  src={Images.panel.likes_card_gold}/>
                    </Col>
                </Row>
            </Col>}*/}

        {/*{hasCraftyService() && <Col xs={24} md={12} xl={8} >
            <Row className={'profileCol borderBottomPrimary'} onClick={() => {history.push('/painel/prestador/enderecos')}}>
                <Col span={12}>
                    <h1 style={{color: '#3362cc', fontWeight: 'bold'}}> Meus Endereços </h1>
                    <span> Adicione e organize aqui os endereços aonde deseja que sejam prestados os serviços solicitados por você! </span> <br/> <br/>
                </Col>
                <Col span={12}>
                    <img src={Images.panel.address_card}/>
                </Col>
            </Row>
        </Col>}*/}

        <Col xs={24} lg={12} xl={8} >
            <Row className={'profileCol borderBottomPrimary'} onClick={() => {
                window.gtagService('event', 'panelNotificationClick');
                history.push('/painel/prestador/notificacoes')
            }}>
                <Col span={12}>
                    <h1 className={'primary-color'} style={{fontWeight: 'bold'}}> Notificações </h1>
                    <span> Manteremos você informado de tudo que for de seu interesse aqui! </span> <br/> <br/>
                </Col>
                <Col span={12}>
                    <img  src={Images.panel.notification_card}/>
                </Col>
            </Row>
        </Col>

        <Col xs={24} lg={12} xl={8}  >
            <Row className={'profileCol'} style={{cursor: 'default'}} >
                <img src={midiabg} style={{position: 'absolute', bottom: 0, left: 0}}/>
                <Col span={24} style={{textAlign: 'center', marginBottom: 20}}>
                    <h1 style={{fontWeight: 'bold'}}> Fique por dentro </h1>
                    <span> Novas vagas e novidades são publicadas diariamente em nossas redes sociais </span>
                </Col>
                <Col span={24}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <a target={'_blank'} href={Env.instagram} style={{margin: 5, cursor: 'pointer', padding: 5, borderRadius: 5, paddingRight: 15, paddingLeft: 15, display: 'flex', alignItems: 'center', backgroundColor: '#df4674', color: 'white'}}>
                            <Icon style={{marginRight: 5, fontSize: 20, color: 'white'}} type={'instagram'}/> <span style={{fontSize: 16,  color: 'white'}}> Instagram </span>
                        </a>
                        <a target={'_blank'} href={Env.facebook} style={{margin: 5, padding: 5, cursor: 'pointer',  borderRadius: 5,display: 'flex', paddingRight: 15, paddingLeft: 15, alignItems: 'center', backgroundColor: '#4867aa', color: 'white'}}>
                            <FaFacebookF style={{marginRight: 5, fontSize: 20, color: 'white'}}/> <span style={{fontSize: 16,  color: 'white'}}> Facebook </span>
                            {/*<Icon style={{marginRight: 5, fontSize: 20, color: 'white'}} type={'facebook'}/> */}
                        </a>
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>)
};
export default WorkerPanelComponent;
