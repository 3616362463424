import React, {useEffect, useState} from 'react';
import {Avatar, Button, Col, Divider, Icon, message, Modal, Row} from "antd";
import moment from "moment/moment";
import {
    FaCodepen,
    FaDollarSign,
    FaGraduationCap,
    FaIdCardAlt,
    FaLanguage,
    FaRegAddressBook,
    FaRegCalendarAlt,
    FaRegFileAlt,
    FaRegMap,
    FaRegThumbsUp,
    FaUserAltSlash,
    FaUserCheck,
    FaWheelchair
} from "react-icons/all";
import {FaBriefcase, FaCalendarCheck, FaCheck, FaClock, FaPencilAlt, FaTools, FaUsers} from "react-icons/fa";
import useApi from "../../../Hooks/Api";
import {Images} from "../../../Helpers/Images";
import VacancyFormComponent
    from "../../../Components/PageComponents/Restrict/ClientPanelPage/VacancyPage/VacancyFormComponent";
import {globalMaskReal, maskReal} from "../../../Helpers/Functions";
import useApiClub from "../../../Hooks/ApiClub";



const ExternalVacancyDetailsComponent = (props) => {

    const api = useApiClub();
    const [showEndVacancy, setShowEndVacancy] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const handleEndVacancy = async () => {
        setLoading(true);
        api.get(`formal-working/job-opportunity/${props.vacancy.id}/finish`,(res) => {
            message.success("Seu anúncio foi encerrado!");
            setShowEndVacancy(false);
            setLoading(false);
            props.changeMenu('5');
        },(e) => {
            console.log(e);
            message.warning(e.message || "Não foi possível encerrar a publicação tente novamente");
            setLoading(false)
        });
    };

    useEffect(() => {
        //getCandidates()
    },[]);

    return (
        <div>
            <Modal visible={showEndVacancy} footer={null} onCancel={() => setShowEndVacancy(false)}  >
                <div style={{textAlign: "center"}}>
                    <h2> Tem certeza que deseja encerrar essa publicação?</h2>
                    <span> Ao encerrar uma publicação ela não será mais visível para os trabalhadores e não será mais possível aceitar candidatos </span>

                    <div style={{marginTop: 25, paddingTop: 25, borderTop: "1px solid gainsboro", display: "flex", justifyContent: "center"}}>
                        <Button onClick={() => setShowEndVacancy(false)} type={"ghost"} style={{marginRight: 10}}> Cancelar </Button>
                        <Button loading={loading} disabled={loading} onClick={() => handleEndVacancy()} type={"primary"}> Encerrar Publicação </Button>
                    </div>
                </div>
            </Modal>
            {showEdit?
            <VacancyFormComponent edit={true} changeMenu={(item) => props.changeMenu(item)} hideEdit={() => {setShowEdit(false)}} vacancy={props.vacancy} enterprise={props.enterprise}/>
            :
            <div className={'mainComponentPanel'} style={{boxShadow: `rgb(0 0 0 / 8%) 0px 2px 8px`, padding: 5, borderRadius:5, backgroundColor: "white"}}>
                <Row style={{borderBottom: "1px solid gainsboro", padding: 10}}>
                    <Col xs={24} style={{fontSize: 19, display: "flex"}}>
                        <Avatar style={{width:45, height: 45, marginRight: 10}} size='default' src={props.companyLogo? `https://crafty-api.s3.amazonaws.com/public/logos/${props.companyLogo}` : Images.user_place_holder}/>
                        <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                            <span style={{fontSize: 16, color: "#757575"}}> Detalhes da divulgação </span>
                            <span>{props.vacancy.occupation_name}</span>
                        </div>
                        <div>
                            <div style={{textAlign: "right"}}>
                                <span>#{props.vacancy.id}</span>
                            </div>
                            {!props.vacancy.closed &&<div style={{display: 'flex'}}>
                                <div onClick={() => setShowEndVacancy(true)} className={"endVacancyButton"}>
                                    <FaCheck style={{fontSize: 11, marginRight: 5}}/>
                                    <span> Encerrar Divulgação  </span>
                                </div>
                            </div>}
                        </div>
                    </Col>
                </Row>

                <Row gutter={[16,32]} style={{margin: 0}}>
                    <Col xs={8}>
                        <div  className="resumeCandidatesCard resumeBlue" onClick={() => props.changeMenu("subcandidates"+props.vacancy.id)}>
                            <FaUsers/>
                            <div>
                                <span style={{fontSize: 15}}> Pendentes </span> <br/>
                                <strong style={{fontSize: 20}}>{props.vacancy.pending_count + props.vacancy.selected_count + props.vacancy.interview_count}</strong>
                            </div>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div  className="resumeCandidatesCard resumeGreen" onClick={() => props.changeMenu("subcandidates"+props.vacancy.id)}>
                            <FaUserCheck/>
                            <div>
                                <span style={{fontSize: 15}}> Aprovados </span> <br/>
                                <strong style={{fontSize: 20}}>{props.vacancy.approved_count} </strong>
                            </div>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div  className="resumeCandidatesCard resumeRed" onClick={() => props.changeMenu("subcandidates"+props.vacancy.id)}>
                            <FaUserAltSlash/>
                            <div>
                                <span style={{fontSize: 15}}> Reprovados </span> <br/>
                                <strong style={{fontSize: 20}}>{props.vacancy.rejected_count}</strong>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div style={{padding: 15, width: "100%"}}>
                    <div> <span style={{fontSize: 19}}> Informações da Vaga </span> <Divider style={{marginTop: 10}}/> </div>

                    {props.vacancy.job_image && <div style={{width: "100%", marginBottom: 20, display: 'flex', justifyContent: 'center'}}>
                        <img src={"https://crafty-api.s3.amazonaws.com/public/job_image/"+props.vacancy.job_image} style={{maxWidth: "100%", borderRadius: 5, boxShadow: "1px 1px 5px 0px #75757540"}}/>
                    </div>}

                    {props.vacancy.job_video && <div className='iframeDiv'>
                        <iframe src={props.vacancy.job_video}
                                allow='autoplay; encrypted-media'
                                allowFullScreen
                                title='video'
                        />
                    </div>}

                    {props.vacancy.closed_at &&<Row gutter={[16,32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                        <Col xs={24} className='vacancyResumeTags' >
                            <FaCalendarCheck style={{color: 'red'}}/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Encerrado em  </span>
                                <span className='tagAnswer' style={{color: 'red'}}> {moment(props.vacancy.closed_at).format("DD/MM/YYYY")} </span>
                            </div>
                        </Col>
                    </Row>}

                    <Row gutter={[16,32]} style={{margin: 0}}>
                        <Col xs={24}  className='vacancyResumeTags'>
                            <FaIdCardAlt/>
                            <div style={{display: 'flex', flexFlow: 'column', width: "100%"}}>
                                <span className='tagQuestion'> Cargo </span>
                                <span style={{textOverflow: 'ellipsis', color: "#52c41a"}}> {props.vacancy.occupation_title? props.vacancy.occupation_title : props.vacancy.occupation_name} </span>
                                <div style={{color: "gray"}}>
                                    {props.vacancy.occupation_title&& "(" + props.vacancy.occupation_name + ")" }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                        <Col xs={24} lg={12} className='vacancyResumeTags' >
                            <FaCodepen/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Tipo de cargo </span>
                                <span className='tagAnswer'> {props.vacancy.job_type} </span>
                            </div>
                        </Col>

                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                            <FaBriefcase/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Quantidade de vagas </span>
                                <span className='tagAnswer'> {props.vacancy.job_openings} </span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{margin: 0}}>
                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                            <FaDollarSign/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Salário  </span>
                                <span className='tagAnswer'> {props.vacancy.pay? globalMaskReal(props.vacancy.pay) + `${props.vacancy.pay_period !== null && ' / ' + props.vacancy.pay_period}` : "Não informado"} </span>
                            </div>
                        </Col>

                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                            <FaRegCalendarAlt/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Data da publicação </span>
                                <span className='tagAnswer'> {moment(props.vacancy.start_date).format("DD/MM/YYYY")}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{backgroundColor: "#fafafad4", margin: 0}} gutter={[16,32]}>
                        <Col xs={24} className='vacancyResumeTags'>
                            <FaRegThumbsUp/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Benefícios  </span>
                                <div style={{display: "flex", flexWrap: 'wrap'}}>
                                    {props.vacancy.incentives&& props.vacancy.incentives.split("|").map((item, index) =>
                                        <div key={index} style={{padding: 3, paddingRight: 8, paddingLeft: 8, backgroundColor: "#e7f3fb ", border: "1px solid #005c9f", color: "#005c9f", borderRadius: 5, margin: 5}}>
                                            <span>
                                                {item}
                                            </span>
                                        </div>
                                    )}
                                    {!props.vacancy.incentives&&
                                    <div style={{padding: 4, paddingRight: 8, paddingLeft: 8, backgroundColor: "#d3d3d36e ", color: "#9e9e9e", borderRadius: 5, margin: 5}}>
                                        Nenhum beneficio informado
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{margin: 0}}>
                        <Col xs={24}  className='vacancyResumeTags'>
                            <FaClock/>
                            <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                <span className='tagQuestion'> Horário de Trabalho </span>
                                <span className='tagAnswer'> {props.vacancy.schedule? props.vacancy.schedule : "Não inserido" } </span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{margin: 0}}>
                        <Col xs={24}  className='vacancyResumeTags'>
                            <FaRegFileAlt/>
                            <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                <span className='tagQuestion'> Descrição da vaga </span>
                                <span className='tagAnswer'> {props.vacancy.job_description? props.vacancy.job_description : "Nenhuma descrição inserida" } </span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{backgroundColor: "#fafafad4", margin: 0}}>
                        <Col xs={24} className='vacancyResumeTags'>
                            <FaRegMap/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Endereço a ser realizado </span>
                                {props.vacancy?.work_place === 'mobile'?
                                    <span> Trabalho Remoto / Home Office </span>
                                    :
                                    (props.vacancy?.work_place === 'itinerant' ?
                                        <span> Sem endereço fixo </span>
                                        :
                                        (props.vacancy?.work_address?
                                            <div>
                                                <span> <strong> Rua - </strong> {props.vacancy?.work_address.street_name} </span> <br/>
                                                <span> <strong> Nº - </strong> {props.vacancy?.work_address.street_number} </span><br/>
                                                <span> <strong> Bairro - </strong> {props.vacancy?.work_address.district} </span><br/>
                                                <span> <strong> Cidade - </strong> {props.vacancy?.work_address.city.name} </span>
                                            </div>
                                            : null))}
                            </div>
                        </Col>
                    </Row>
                </div>

                <div style={{padding: 15, width: "100%"}}>
                    <div> <span style={{fontSize: 19}}> Informações de requisição aos candidatos </span> <Divider style={{marginTop: 10}}/> </div>

                    <Row style={{backgroundColor: "#fafafad4", margin: 0}} gutter={[16,32]}>
                        <Col xs={24} className='vacancyResumeTags'>
                            <FaTools/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Habilidades desejadas  </span>
                                <div style={{display: "flex", flexWrap: 'wrap'}}>
                                    {props.vacancy.desired_skills&& props.vacancy.desired_skills.split("|").map((item, index) =>
                                        <div key={index} style={{padding: 3, paddingRight: 8, paddingLeft: 8, backgroundColor: "#e7f3fb ", border: "1px solid #005c9f", color: "#005c9f", borderRadius: 5, margin: 5}}>
                                            <span>
                                                {item}
                                            </span>
                                        </div>
                                    )}
                                    {!props.vacancy.desired_skills &&
                                    <div style={{padding: 4, paddingRight: 8, paddingLeft: 8,backgroundColor: "#d3d3d36e ", color: "#9e9e9e", borderRadius: 5, margin: 5}}>
                                        Nenhum habilidade informada
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{margin: 0}}>
                        <Col xs={24} lg={12} className='vacancyResumeTags' >
                            <FaGraduationCap/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Escolaridade mínima </span>
                                <span className='tagAnswer'> {props.vacancy.schooling? props.vacancy.schooling : "Nenhuma"} </span>
                            </div>
                        </Col>

                        <Col xs={24} lg={12}  className='vacancyResumeTags'>
                            <FaWheelchair/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Vaga destinada a PcD? </span>
                                <span className='tagAnswer'> {props.vacancy.disabilities? "Sim" : "Não"} </span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{margin: 0, backgroundColor: "#fafafad4", }}>
                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                            <FaLanguage/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'>Deve possuir fluência em outro Idioma?  </span>
                                <div style={{display: "flex", flexWrap: "wrap"}}>
                                    {props.vacancy.languages&& props.vacancy.languages.split("|").map((item, index) => <span key={index} className='tagAnswer'> {item}{index === (props.vacancy.languages.length - 1)? '.' : ','}</span>)}
                                    {!props.vacancy.languages&& "Não"}
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                            <FaRegAddressBook/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Deve possuir experiência no cargo </span>
                                <span className='tagAnswer'> {props.vacancy.occupation_cbo_req ? "Sim" : "Não"}</span>
                            </div>
                        </Col>
                    </Row>


                    <Row gutter={[16,32]} style={{margin: 0}}>
                        <Col xs={24}  className='vacancyResumeTags'>
                            <Icon type='caret-right'/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Candidato deve ter disponibilidade para realizar viagens? </span>
                                <span className='tagAnswer'> {props.vacancy.available_travel === "S"? "Sim" : "Não"} </span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{margin: 0, backgroundColor: "#fafafad4", }}>
                        <Col xs={24}  className='vacancyResumeTags'>
                            <Icon type='caret-right'/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Candidato deve ter disponibilidade para dormir no local de trabalho? </span>
                                <span className='tagAnswer'> {props.vacancy.available_sleep_duty  === "S"? "Sim" : "Não"} </span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{margin: 0}}>
                        <Col xs={24}  className='vacancyResumeTags'>
                            <Icon type='caret-right'/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Candidato deve ter disponibilidade para ausentar-se de seu domicílio por longos períodos?? </span>
                                <span className='tagAnswer'> {props.vacancy.available_leave_home === "S"? "Sim" : "Não"} </span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={[16,32]} style={{margin: 0, backgroundColor: "#fafafad4", }}>
                        <Col xs={24}  className='vacancyResumeTags'>
                            <Icon type='caret-right'/>
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <span className='tagQuestion'> Permitir candidatos que não se encaixam nos requisitos a se candidatar a vaga? </span>
                                <span className='tagAnswer' style={{color: props.vacancy.accept_proposal? "green" : "red"}}> {props.vacancy.accept_proposal? "Sim" : "Não"} </span>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>}
        </div>
    );
};

ExternalVacancyDetailsComponent.propTypes = {};

export default ExternalVacancyDetailsComponent;
