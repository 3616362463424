import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    AutoComplete,
    Button,
    Checkbox,
    Col, Collapse,
    Form,
    Icon,
    Input, InputNumber,
    message, Modal, Popover,
    Radio,
    Row,
    notification,
    Select,
} from "antd";
import moment, {duration} from "moment";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";

import {
    FaArrowLeft,
    FaArrowRight, FaBriefcase,
    FaBullhorn,
    FaCopy, FaGavel, FaPaperPlane,
    FaPlusSquare, FaUsers
} from "react-icons/fa";

import useApi from "../../../../../Hooks/Api";
import {eventVisible, useValidationShorts} from "../../../../../Hooks/Helpers";
import TextArea from "antd/lib/input/TextArea";
import {MdPrint, MdWork} from "react-icons/md";
import * as urlParser from "js-video-url-parser/lib/provider/youtube";
import {Images} from "../../../../../Helpers/Images";
import {debounce, isEmpty} from "lodash";
import {Env} from "../../../../../Helpers/Env";
import {useHistory, useParams} from "react-router-dom";
import {
    AiOutlineCalendar,
    AiOutlineRetweet,
    BsChevronDown,
    FaCodepen,
    FaDollarSign
} from "react-icons/all";
import {encode, globalMaskReal, maskRealNu} from "../../../../../Helpers/Functions";
import useNotifications from "../../../../../Hooks/Notifications";
import BannerEvent from "../../../../../Pages/SearchOpportunity/Components/BannerEvent";
import bannerEvent1 from '../../../../../Img/EventImages/month_women/banner1.png';


const schoolingList = ['Analfabeto', 'Fundamental Incompleto', 'Fundamental Completo', 'Médio Incompleto', 'Médio Completo', 'Superior Incompleto', 'Superior Completo', 'Mestrado', 'Doutorado'];
const driverList = ['A', 'B', 'C', 'D', 'E', 'AB', 'AC', 'AD', 'AE'];
const languagesList = [{label: 'Alemão', value: 'Alemão'}, {label: 'Espanhol', value: 'Espanhol'}, {label: 'Francês', value: 'Francês'}, {label: 'Inglês', value: 'Inglês'}, {label: 'Italiano', value: 'Italiano'}];
const incentivesList = ['Vale-transporte', 'Vale-alimentação', 'Plano de saúde', 'Plano Odontológico', 'Descontos na empresa', 'Auxílio-creche', 'Auxílio-educação', 'Planos de previdência privada', 'Viagens'];
const requisitesList = ['Saber lidar com Clientes', 'Conhecimento básico em Informática', 'Trabalhar em Equipe', 'Ser comunicativo', 'Estar disposto a serviços braçais', 'Conhecimento da ferramenta Excell', 'Saber dirigir'];
const payType = [{label: 'Mensal', value:'Mês'}, {label:'Quinzenal', value:'Quinzena'}, {label: 'Diária', value:'Dia'}, {label:'Hora', value: 'Hora'}, {label: 'Por produção' , value: 'Por Produção'} , {label:'Por tarefa', value:'Por Tarefa'}]

const workTypeList = ['Tempo integral', 'Meio período','Primeiro emprego',
    'Autônomo', 'Freelance', 'Temporário','Estágio', 'Aprendiz'];

const VacancyFormComponent = (props) => {

    const {getFieldDecorator, getFieldsValue,setFieldsValue, validateFields, getFieldsError} = props.form;
    const api = useApi();
    const autocompleteApi = useApi();
    const user = useSelector((state) => state).authReducer;
    const {isValidDate,minDate, numMinWithMask} = useValidationShorts();
    const [loading, setLoading] = useState(false);
    const [vacancyLoading, setVacancyLoading] = useState(true);
    const [showLanguages, setShowLanguages] = useState(false);
    const [showSchooling, setShowSchooling] = useState(false);
    const [showDriver, setShowDriver] = useState(false);
    const [cepLoading, setCepLoading] = useState(false);

    const [vacancyImg, setVacancyImg] = useState(undefined)
    const [autocompleteSelected, setAutoCompleteSelected] = useState(false);
    const [occupationsList, setOccupationsList] = useState();
    const [complianceModal, setComplianceModal] = useState(false);
    const [embedVideo, setEmbedVideo] = useState(undefined);
    const [allCategories, setAllCategories] = useState(null)
    const vacancy = useRef(null);
    const routerParams = useParams();
    const history = useHistory();
    const {stateRestrictionNotification} = useNotifications()

    const [closedVacancy, setClosedVacancy] = useState([]);
    const [collapseVacancy, setCollapseVacancy] = useState([])

    const getVacancy = (id) => {
        setVacancyLoading(true);
        if (id !== vacancy.current?.id || routerParams.id) {
            api.get(`sine/user/${user.user_id}/job-opportunity/${id ? id : routerParams.id}`, (res) => {
                let response = JSON.parse(JSON.stringify(res))

                if (response.desired_skills_categories !== null) {
                    response.desired_skills_categories.forEach(el => response.desired_skills.push(el.title))
                }

                if (response.incentives_categories !== null) {
                    response.incentives_categories.forEach(el => response.incentives.push(el.title))
                }

                vacancy.current = response;
                vacancy.current.id = id ? id : routerParams.id;
                let aux = vacancy.current;
                if (aux.languages.length > 0) {
                    setShowLanguages(true)
                }
                if(aux.schooling) {
                    setShowSchooling(true)
                }
                if(aux.driver_license) {
                    setShowDriver(true)
                }
                if(aux.pay) {
                    aux.pay = globalMaskReal(aux.pay);
                }

                if (aux.pay_period === null) {
                    aux.pay_period = 'Mês'
                }

                if(aux.disabilities.length > 0) {
                    aux.disabilities = true;
                } else {
                    aux.disabilities = false;
                }

                if(aux.job_image) {
                    setVacancyImg(aux.job_image)
                }

                if (id) {
                    message.success(`Campos preenchidos!`)
                    aux.start_date = moment().format('DD/MM/YYYY')
                    aux.end_date = moment().add(60, 'days').format("DD/MM/YYYY")
                } else {
                    if(aux.start_date) aux.start_date = moment(aux.start_date).format('DD/MM/YYYY');
                    if(aux.end_date) aux.end_date = moment(aux.end_date).format('DD/MM/YYYY');
                }

                aux.job_type = aux.job_type[0];
                setFieldsValue(aux);
                setVacancyLoading(false);
            },(e) => {
                setVacancyLoading(false);
            });
        }
    }

    const showNotification = () => {
        stateRestrictionNotification()
        setComplianceModal(false);
    }

    const handleSubmit = async () => {
        validateFields(async (error, values) => {
            if (error) { message.warning('Preencha os campos corretamente'); return 0;}

            let sendApi = JSON.parse(JSON.stringify(values))
            if (sendApi.desired_skills?.length > 0) {
                sendApi.desired_skills_ids = []
                for (let i = 0 ; i < allCategories.skills?.length ; i++) {
                    for (let y = 0 ; y < sendApi?.desired_skills.length ; y++) {
                        if (sendApi?.desired_skills[y] === allCategories.skills[i].label) {
                            sendApi.desired_skills_ids.push(allCategories.skills[i].id)
                            sendApi?.desired_skills.splice(y, 1)
                        }
                    }
                }
                if (sendApi.desired_skills_ids.length === 0) {
                    delete sendApi.desired_skills_ids
                }
            }

            if (sendApi.incentives?.length > 0) {
                sendApi.incentives_ids = []
                for (let i = 0 ; i < allCategories.incentives?.length ; i++) {
                    for (let y = 0 ; y < sendApi?.incentives.length ; y++) {
                        if (sendApi?.incentives[y] === allCategories.incentives[i].label) {
                            sendApi.incentives_ids.push(allCategories.incentives[i].id)
                            sendApi?.incentives.splice(y, 1)
                        }
                    }
                }
                if (sendApi.incentives_ids.length === 0) {
                    delete sendApi.incentives_ids
                }
            }

            if (sendApi?.work_place === 'same') {
                if (props.enterprise?.address.state_abbrev !== Env.state_restriction ) {
                    return showNotification();
                }
            } else if (sendApi?.work_place === 'other') {
                if (sendApi?.work_address.state_abbrev !== Env.state_restriction) {
                    return showNotification();
                }
            }
            if(vacancyImg) {
                sendApi.job_image = {
                    filename: "img.jpg",
                    base64_picture: vacancyImg.slice(23)
                }
            }
            setLoading(true);

            message.loading('Anunciando Vaga...');
            if(!sendApi.pay || sendApi.pay === "" || sendApi.pay === undefined) {
                delete sendApi.pay;
            } else {
                sendApi.pay = sendApi.pay.replace(/\./g,"").replace(",", ".").replace('R$',"").replace(' ','');
            }
            if(sendApi.pay === '0.00') sendApi.pay = undefined;
            if(!showLanguages) sendApi.languages = [];
            if(!showSchooling) sendApi.schooling = undefined;
            if(!showDriver) sendApi.driver_license = undefined;

            if(sendApi.driver_license && !sendApi.accept_proposal) {
                sendApi.driver_license_req = true;
            }
            if(sendApi.schooling && !sendApi.accept_proposal) {
                sendApi.schooling_req = true;
            }
            if(sendApi.disabilities) {
                sendApi.disabilities = ["Alguma"];
                if(!sendApi.accept_proposal) {
                    sendApi.disabilities_opt = 'required';
                }
            } else {
                sendApi.disabilities = [];
            }
            if(sendApi.apply_message === "") delete sendApi.apply_message;

            sendApi.start_date = moment(sendApi.start_date, 'DD/MM/YYYY').format("YYYY-MM-DD");
            sendApi.end_date = moment(sendApi.end_date, 'DD/MM/YYYY').format("YYYY-MM-DD");
            sendApi.job_type = [sendApi.job_type];

            if(sendApi.work_address?.postal_code) {
                sendApi.work_address.postal_code = window.getNumbers(sendApi.work_address.postal_code);
            }
            sendApi.user_company_id = routerParams.company_id;
            sendApi.application = Env.application_alias;

            if(sendApi.job_description?.trim() === '') {
                sendApi.job_description = undefined;
            }

            if(sendApi.job_video) {
                if (urlParser.prototype.parseVideoUrl(sendApi.job_video)) {
                    let auxEmbedVideo = urlParser.prototype.parseVideoUrl(sendApi.job_video);
                    sendApi.job_video = `https://www.youtube.com/embed/${auxEmbedVideo}`;
                } else {
                    message.error('Link do video inválido');
                    setLoading(false);
                    return 0;
                }
            } else {
                sendApi.job_video = undefined;
            }

            if(props.edit) {
                if(sendApi.job_image && (vacancyImg === vacancy.current.job_image)) {
                    delete sendApi.job_image;
                }
                api.put(`sine/user/${user.user_id}/job-opportunity/${routerParams.id}`,sendApi, (res) => {
                    message.destroy();
                    message.success('Sucesso!');
                    setComplianceModal(false);
                    props.updateVacancyList();
                    history.push('/painel/cliente/vagas/' + props.enterprise.id + '/vagas-abertas')
                    setLoading(false);
                }, (e) => {
                    console.log(e);
                    setLoading(false);
                    message.error(e.message || 'Não foi possivel salvar, tente novamente');
                });
            }  else {
                if (Env.event && eventVisible()) {

                    sendApi.gender = ['female', 'transgender_female','transsex_male', 'transsex_female', 'unknown', 'not_anwswered', 'other']
                    sendApi.gender_req = true
                    sendApi.event = Env.event_name;
                }
                setLoading(false);
                api.post(`sine/user/${user.user_id}/job-opportunity`,sendApi, (res) => {
                    message.destroy();
                    message.success('Sucesso!');
                    setComplianceModal(false);
                    props.updateVacancyList();
                    history.push('/painel/cliente/vagas/' + props.enterprise.id + '/vagas-abertas')
                    setLoading(false);
                },(e) => {
                    console.log(e);
                    setLoading(false);
                    message.error(e.message || 'Não foi possivel salvar, tente novamente');
                });
            }
        })
    };

    const onCepChange = async (e) => {
        const cep = window.getNumbers(e.target.value);
        if (cep?.length === 8) {
            setCepLoading(true);

            api.get(`search/postal-code?cep=${cep}`, (res) => {
                if(res) {
                    setFieldsValue({work_address: { district: res.district, street: res.street_name, city: res.city, state_abbrev: res.state}});
                }
                setCepLoading(false);
            },(e) => {
                message.warning('CEP inválido');
                setFieldsValue({work_address: {postal_code: undefined}});
                console.log(e);
                setCepLoading(false);
            });
        }
    }

    const autoCompleteSearch = useCallback(debounce(async (query) => {
        autocompleteApi.setLoading(true);
        let searchQuery = `?query=${query}&limit=25`;

        if (!autocompleteApi.loading) {
            autocompleteApi.get(`sine/cbo-occupations/autocomplete${searchQuery}`, (res) => {
                setOccupationsList(res);
            }, (e) => {
                console.log(e);
            });
        }
    }, 700), []);

    const handleSelect = (value, opt) => {
        let aux = {}
        setFieldsValue({occupation_id : opt.props.label});
        setAutoCompleteSelected(true);

        for (let i = 0; i < occupationsList?.length ; i ++) {
            if (occupationsList[i].name === value) {
                aux.description = occupationsList[i].description
                aux.occupation_profile = occupationsList[i].occupation_profile
                aux.string_filtered = aux.occupation_profile?.map((item) => '- ' + item.area_name.charAt(0).toUpperCase() + item.area_name.substr(1).toLowerCase()).join('\n')

                if (isEmpty(getFieldsValue().job_description) && occupationsList[i].description) {
                    setFieldsValue({job_description: `Descrição da vaga:\n\n${aux.description}\n\nAtribuições:\n${aux.string_filtered}`})
                }
            }
        }
    };

    const handleFinishModal = () => {
        validateFields(async (error, values) => {
            if (error) { message.warning('Preencha os campos corretamente'); return 0;}
            setComplianceModal(true)
        })
    };

    const clearAutocomplete = () => {
        setFieldsValue({occupation_id: undefined});
        setFieldsValue({occupation_name: undefined});
        setFieldsValue({job_description: undefined});
        setAutoCompleteSelected(false);
    };

    useEffect(() => {
        setFieldsValue({pay: maskRealNu(getFieldsValue().pay)})
    },[getFieldsValue().pay])

    useEffect(() => {
        if(getFieldsValue().job_video) {
            if (urlParser.prototype.parseVideoUrl(getFieldsValue().job_video)) {
                let auxEmbedVideo = urlParser.prototype.parseVideoUrl(getFieldsValue().job_video);
                setEmbedVideo(`https://www.youtube.com/embed/${auxEmbedVideo}`);
            } else {
                setEmbedVideo(undefined);
            }
        }
    },[getFieldsValue().job_video]);

    const inputLimitText = () => { return <input maxLength={150} autoComplete="off"/> }


    const checkClosedVacancies  =  () => {
        api.get(`sine/company_opportunities/${routerParams.company_id}?page=1&limit=20`, (res) => {
            if (res.opportunities.length > 0) {
                setClosedVacancy(res.opportunities)
            }
        },(err) => {
            console.log(err)
        })
    }

    const handleArrow = (side) => {
        if (side === 'left') {
            document.getElementById(`closedVacancy`).scrollBy({
                left: -200,
                behavior:'smooth',
            })
        } else if (side === 'right') {
            document.getElementById(`closedVacancy`).scrollBy({
                left: 200,
                behavior:'smooth'
            })
        }
    }

    const getCategories = () => {
        let aux = {}
        api.get(`courses/categories`, (res) => {
            aux.courses = res
            setAllCategories({...aux})
        }, (err) => {})

        api.get('sine/search_incentives', (res) => {
            aux.incentives = [];
            res.incentives.forEach(el => aux.incentives.push({label: el.title, id: el.id, phonetic: encode(el.title)}))
            setAllCategories({...aux})
        }, (err) =>{})

        api.get('sine/search_skills', (res) => {
            aux.skills = [];
            res.skills.forEach(el => aux.skills.push({label: el.title, id: el.id, phonetic: encode(el.title)}))
            setAllCategories({...aux})
        }, (err) =>{
            console.log(err)
        })
    }

    useEffect(() => {
        getCategories()
        if(props.edit) {
            getVacancy();
        } else {
            checkClosedVacancies();
            setVacancyLoading(false);
        }
        window.scrollToElement('body');
    },[]);

    return (
        <div>
            {(closedVacancy.length > 0 && !props.edit) &&
                <Collapse activeKey={collapseVacancy} onChange={(e) => setCollapseVacancy(e)} style={{marginBottom:14, borderColor: '#ebebeb', backgroundColor: 'white'}} expandIconPosition={"right"}>
                    <Collapse.Panel className={'collapseHeaderCustom'} key={1} header={
                        <div>
                            <div style={{fontSize:20, display: 'flex', alignItems: 'center', gap: 5, marginBottom:5}}>
                                <AiOutlineRetweet size={20} className={'primary-color'}/>
                                <span style={{margin:0, color: '#595959', fontSize: 16}}>
                                    Pegar dados de publicação a partir de outra já criada
                                </span>
                            </div>
                            <p style={{color:'#9e9e9e', margin:0, fontSize:12}}>
                                Preencha automaticamente os campos a partir de uma vaga criada anteriormente!
                            </p>
                        </div>}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button onClick={() => handleArrow('left')} style={{border: 'none', boxShadow:'none',padding:0, paddingLeft: 5, paddingRight:5, display:"flex", alignItems:'center'}}>
                                <FaArrowLeft size={20}/>
                            </Button>

                            <Row style={{display:'flex', gap:5, overflow:'auto', flex:1}} id='closedVacancy' className='without-bg-scroll' gutter={[8,8]}>
                                {closedVacancy?.map((item, index) =>
                                    <Col xs={20} md={12} lg={10} style={{margin:'4px 0'}}>
                                        <div key={index} onClick={() => {
                                            getVacancy(item.id);
                                            setCollapseVacancy([]);
                                        }} style={{display:'flex', flexDirection:'column', justifyContent:'space-between', background:'#fff', borderRadius:5, boxShadow: `0 2px 8px rgba(0 0 0 / 8%)`,  height:'100%'}} className='cursor-pointer-with-shadow'>
                                            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between',padding:16,}}>
                                                <div style={{marginBottom:10}}>
                                                    <div style={{fontSize:18}}>
                                                        {item.occupation_title}
                                                    </div>
                                                    <div style={{color:'#9e9e9e', fontSize:14}}>
                                                        {item.occupation_name} / {item.job_type}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{display: 'flex',}}>
                                                        <div style={{display:'flex', marginRight: 15, alignItems:'center', gap:6}}>
                                                            <FaCodepen size={16}/>
                                                            <div>
                                                                {item.job_type}
                                                            </div>
                                                        </div>
                                                        <div style={{display:'flex', alignItems:'center', gap:6}}>
                                                            <FaDollarSign size={16}/>
                                                            <div>
                                                                {item.pay ? `R$ ${globalMaskReal(item.pay)}` : 'Não Informado'}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div style={{display:'flex', marginTop: 5, alignItems:'center', gap:6}}>
                                                        <AiOutlineCalendar size={16}/>
                                                        <div>
                                                            Publicado em <span style={{color:'#1f84a2'}}>{moment(item?.created_at).format("DD/MM/YYYY")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{borderTop: '1px solid gainsboro', borderBottomRightRadius: 5, borderBottomLeftRadius: 5, display: 'flex', alignItems: 'center', padding: 5, paddingLeft: 10, paddingRight: 10, backgroundColor: '#fafafa'}}>
                                                <FaCopy color={'#5785ae'}/>
                                                <span className={'helpText'} style={{color: "gray", marginLeft: 4}}> Toque aqui para copiar os dados dessa vaga </span>
                                            </div>
                                        </div>
                                    </Col>

                                )}
                            </Row>

                            <Button onClick={() => handleArrow('right')} style={{border: 'none', boxShadow:'none',padding:0, paddingRight: 5, paddingLeft:5, display:"flex", alignItems:'center'}}>
                                <FaArrowRight size={20}/>
                            </Button>

                        </div>

                    </Collapse.Panel>
                </Collapse>
            }

            <div style={{minHeight: 500, backgroundColor: 'white', borderRadius: 4, boxShadow: "0 2px 8px rgb(0 0 0 / 8%)"}} >

                    <Modal
                        visible={complianceModal}
                        footer={null}
                        bodyStyle={{padding: 0}}
                        width={'95%'}
                        onCancel={() => {setComplianceModal(false)}}
                        destroyOnClose={true}
                        style={{maxWidth: '1000px'}}
                    >
                        <div>
                            <div className='publish-vacancy-modal'>
                                <h3 style={{padding: 15, fontSize: 23, color: '#393939', fontWeight: 'bolder'}}>O que vai acontecer agora? </h3>
                                <div className='striped'>
                                    <div className={'arrow'} style={{width: '5%', display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                                    </div>
                                    <div className='card-vacancy' style={{  background: 'linear-gradient(45deg, #e4e7f9, transparent)'}}>
                                        <div>
                                            <img src={Images.step1vacancy}/>
                                        </div>

                                        <div style={{maxWidth: 400}}>
                                            <div>
                                                <h3 style={{color: '#565656'}}> <strong style={{color: '#7f56c4'}}>1.</strong> Sua vaga será publicada</h3>
                                            </div>
                                            <div>
                                                <p>Você está a publicando {!props.edit ? 'publicando' : 'editando'} <strong style={{color: '#7f56c4', fontSize:16}}> {getFieldsValue().job_openings} vaga(s)</strong> para o cargo <strong> - {getFieldsValue().occupation_name} </strong>
                                                <br/>
                                                    Revise os dados e veja se está tudo conforme o que você precisa!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='striped'>
                                    <div className={'arrow'} style={{width: '20%', display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                                        <img style={{height: 100, width: 100}} src={Images.rightArrow2}/>
                                    </div>
                                    <div className='card-vacancy ' style={{ background: 'linear-gradient(45deg, #fbf8f0, transparent)'}}>
                                        <div>
                                            <img src={Images.step2vacancy}/>
                                        </div>
                                        <div style={{maxWidth: 400}}>
                                            <div>
                                                <h3 style={{color:'#565656'}}><strong style={{color: '#caa629'}}>2.</strong> Vamos ver se está tudo certo</h3>
                                            </div>
                                            <div>
                                                <p>
                                                    Os detalhes da publicação serão analisados por nossa equipe antes de ser divulgada na plataforma para garantir que a publicação se enquadra a nossas diretrizes, <strong>é bem rápido</strong>.  Em pouco tempo sua vaga já será liberada para todo o estado!
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className='striped'>
                                    <div className={'arrow'} style={{width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                                        <img style={{height: 100, width: 100}} src={Images.rightArrow3}/>
                                    </div>
                                    <div className='card-vacancy' style={{  background: 'linear-gradient(45deg, #ecf3ff, transparent)'}}>
                                        <div>
                                            <img src={Images.step3vacancy}/>
                                        </div>
                                        <div style={{maxWidth: 400}}>
                                            <div>
                                                <h3 style={{color:'#565656'}}><strong style={{color: '#2955b5'}}>3.</strong> Você vai receber currículos de trabalhadores interessados</h3>
                                            </div>
                                            <div>
                                                <p>Assim que sua vaga for liberada para divulgação, ela ficará disponível para <strong style={{color: '#565656'}}>milhares de trabalhadores</strong> em todo estado. Agora é só filtrar os melhores currículos, entrar em contato e escolher o profissional ideal para sua vaga!</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='striped'>
                                    <div className={'arrow'} style={{width: '40%', display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                                        <img style={{height: 100, width: 100}} src={Images.rightArrow4}/>
                                    </div>
                                    <div className='card-vacancy' style={{background: 'linear-gradient(45deg, #eaf9ee, transparent)'}}>
                                        <div>
                                            <img src={Images.step4vacancy}/>
                                        </div>

                                        <div style={{maxWidth: 400}}>
                                            <div style={{marginBottom:10,}}>
                                                <h3 style={{color: '#565656'}}><strong style={{color: '#4ed374'}}>4.</strong> Nos conte se encontrar um profissional</h3>
                                            </div>
                                            <div>
                                                <p style={{marginBottom:10}}>
                                                    Conta pra gente! Essa informação é muito importante, pois além de aumentar a credibilidade do <strong style={{color:'#4ed374'}}>{Env.application_title}</strong>; ajuda no fomento à empregabilidade do estado do {Env.state_full}.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='footer-vacancy-modal'>

                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div className='images-gov' style={{padding: 0}}>
                                        <div style={{marginBottom: 5}}>
                                            <img src={Images.logoModalVacancy.logoGov} alt='logo gov' style={{width:'200px'}}/>
                                        </div>
                                    </div>

                                    <Button size={'large'} onClick={() => !loading&& handleSubmit()} className={'ant-btn-secondary'} >
                                      <span style={{display:"flex", alignItems:"center", gap:8}}>
                                        <FaPaperPlane/>  Continuar
                                      </span>
                                    </Button>

                                </div>
                            </div>
                        </div>

                    </Modal>

                    <div style={{ padding: 10, borderBottom: "1px solid gainsboro", alignItems: "center"}}>

                        <div style={{display: "flex",alignItems: "center"}}>
                            <FaPlusSquare style={{marginRight: 8, fontSize: 18}} className={"secondary-color"}/>
                            {!props.edit ? <span style={{fontSize: 18}}> Formulário de nova divulgação {Env.event? <span>de vaga para o <strong>Mês da Mulher</strong></span> : ''}</span> : <span style={{fontSize: 18}}> Editando divulgação</span> }

                            {Env?.event && <div style={{marginLeft:'auto'}}>
                                <Button size={'large'} onClick={() => {Env.event = false;  window.location.reload();}} className={'ant-btn-secondary'} >
                                      <span style={{display:"flex", alignItems:"center", gap:8}}>
                                        <FaPaperPlane/>  Criar vaga padrão
                                      </span>
                                </Button>
                            </div>}
                        </div>
                    </div>

                    {Env.event &&
                        <div style={{display: 'flex', justifyContent: 'end', marginTop: 10, marginRight: 10}}>
                                    <span className={'text-right'} style={{
                                        color: "#bb76eb",
                                        marginBottom: "0"
                                    }}>
                                        Em celebração ao mês das mulheres, estamos dedicando um espaço exclusivo para a divulgação de oportunidades voltadas ao público feminino. Ao publicar essa vaga ela será incluida nesta iniciativa
                                    </span>
                        </div>
                    }
                    <Row gutter={[16,16]} style={{display: "flex", flexWrap:"wrap", margin: 0, padding: 15,}}>
                        <Col xs={24} md={12} lg={24} className={'formItem'}>
                            <div className='resumeLabel'> <label> <span>*</span> Título da publicação <Popover content={'Caso ache necessário, informe um título para a vaga, caso contrario será exibido o nome do cargo.'}> <Icon  style={{color: "gray"}}  type='question-circle'/> </Popover> </label> </div>
                            {getFieldDecorator('occupation_title',
                                {
                                    rules: [{
                                        required: true,
                                        message: 'Nome é obrigatório'
                                    }],
                                    initialValue: undefined,
                                }
                            )(
                                <Input placeholder={'Informe o titulo principal de sua vaga'} maxLength={100} size={'large'}/>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().occupation_title} </span> </div>
                        </Col>

                        <Col xs={24} md={12} className={'formItem'}>
                            <div className='resumeLabel'><label><span>*</span> Cargo da vaga </label> </div>
                            {getFieldDecorator(`occupation_name`,
                                {
                                    rules: [{ required: true, message: 'Ocupação é obrigatório'}],
                                    initialValue: "",
                                }
                            )(
                                <AutoComplete
                                    disabled={props.edit}
                                    size="large"
                                    onSelect={(value, option) => handleSelect(value, option)}
                                    style={{ width: '100%', display: autocompleteSelected&& 'none'}}
                                    dataSource={occupationsList?.map(item => {
                                        return (<AutoComplete.Option key={item.name} label={item.occupation_id} value={item.name}>
                                                {item.name}
                                            </AutoComplete.Option>
                                        )
                                    })}
                                    onSearch={(query) => autoCompleteSearch(query)}
                                    optionLabelProp="text"
                                >
                                    <Input suffix={autocompleteApi.loading ? <Icon type="loading"/> : <MdWork/> } size={'large'} placeholder={'Selecione a ocupação/cargo da vaga'} />
                                </AutoComplete>)}

                            <div className={'ant-input ant-input-lg'} style={{ width: '100%', display: !autocompleteSelected? 'none' : 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                {getFieldsValue().occupation_name}
                                <Icon className={'hoverIcon'} onClick={() => clearAutocomplete()} type={'close'}/>
                            </div>
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().occupation_id} </span> </div>

                            {getFieldDecorator(`occupation_id`,
                                {
                                    initialValue: '',
                                    rules: [{ required: true, message: 'Escolha um cargo na busca'}],
                                }
                            )(
                                <Input type={'hidden'}/>
                            )}
                        </Col>

                        <Col xs={24} md={12} lg={12} xl={12} className={'formItem'}>
                            <div className='resumeLabel'><label> <span>*</span> Tipo de trabalho </label> </div>
                            {getFieldDecorator(`job_type`,
                                {
                                    rules: [{required: true, message: 'Tipo de trabalho é obrigatório'}],
                                    initialValue: undefined
                                }
                            )(
                                <Select
                                    placeholder="Selecione o tipo de trabalho"
                                    size={'large'}
                                >
                                    {workTypeList.map((item, index) =>
                                        <Select.Option key={index} value={item}>{item}</Select.Option>
                                    )}
                                </Select>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().job_type} </span> </div>
                        </Col>

                        <Col xs={24} md={12} lg={8} xl={8} className={'formItem'}>
                            <div className='resumeLabel'> <label style={{display:"flex", alignItems:"center", gap: 5, marginBottom: 5}}>
                                <span>* </span>
                                Número de vagas
                                <Popover content={'Ao aprovar candidatos equivalente a quantidade de vagas, a divulgação será marcada como finalizada.'}>
                                    <Icon  style={{color:"gray"}}  type='question-circle'/>
                                </Popover>
                            </label>

                                {getFieldDecorator('job_openings',
                                    {
                                        rules: [{
                                            required: true,
                                            message: 'Número de vagas é obrigatório'
                                        }],
                                        initialValue: 1,
                                    }
                                )(
                                    <InputNumber
                                        type={"number"}
                                        placeholder={'Quantidade de vagas em aberto'} style={{width: "100%"}} size={'large'} min={1}/>
                                )}
                                {getFieldsError().job_openings &&<div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().job_openings} </span> </div>}
                                {getFieldsValue().job_openings > 9&& <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: '#ff8522', fontSize: 13}}> Serão disponibilizadas <strong>{getFieldsValue().job_openings} </strong>nesta publicação </span> </div>}
                            </div>
                        </Col>

                        <Col xs={24} md={12} lg={8} xl={8} className={'formItem'}>
                            <div className='resumeLabel'> <label> Salário </label> </div>
                            {getFieldDecorator('pay',
                                {
                                    rules: [],
                                    initialValue: undefined,
                                }
                            )(
                                <Input addonBefore={'R$ '}  placeholder={'Salário da vaga'} maxLength={15} size={'large'}/>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}></span> </div>
                        </Col>

                        <Col xs={24} md={12} lg={8} xl={8} className={'formItem'}>
                            <div className='resumeLabel' style={{marginBottom:5}}>
                                <label>
                                    Tipo de salário
                                </label>
                            </div>
                            <div>
                                {getFieldDecorator('pay_period',
                                    {
                                        rules: [{
                                            required: false,
                                            message: 'Tipo de salário é obrigatório',
                                        }],
                                        initialValue: 'Mês'
                                    }
                                )(
                                    <Select style={{width:'100%'}}>
                                        {payType.map((item, index) => (
                                            <Select.Option key={index} value={item.value}>
                                                {item.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().pay_period} </span> </div>
                            </div>

                        </Col>

                        <Col xs={24} md={12} lg={12} xl={12}  className={'formItem'}>
                            <div className='resumeLabel'><label> <span>*</span> Publicar em </label> </div>
                            {getFieldDecorator('start_date', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Campo Obrigatório',
                                    },
                                    isValidDate(),
                                    !props.edit && minDate(moment())
                                ],
                                initialValue: moment().format('DD/MM/YYYY')
                            })(
                                <NumberFormat disabled={(props.edit || Env?.event)} className={'ant-input ant-input-lg'} format="##/##/####" placeholder="DD/MM/YYYY"/>
                            )}
                            <div style={{textAlign: 'left'}}> <span style={{color: '#ccddea', fontSize: 12}}>A publicação ficará visível a partir deste dia</span> </div>
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().start_date} </span> </div>
                        </Col>

                        <Col xs={24} md={12} lg={12} xl={12}  className={'formItem'}>
                            <div className='resumeLabel'><label> <span>*</span> Encerrar publicação em </label> </div>
                            {getFieldDecorator('end_date', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Campo Obrigatório',
                                    },
                                    isValidDate(),
                                    minDate(moment(getFieldsValue().start_date, 'DD/MM/YYYY').add(60, 'days') || moment().add(60, 'days'))
                                ],
                                initialValue: moment().add(60, 'days').format("DD/MM/YYYY")
                            })(
                                <NumberFormat className={'ant-input ant-input-lg'} format="##/##/####" placeholder="DD/MM/AAAA"/>
                            )}
                            <div style={{textAlign: 'right'}}> <span style={{color: '#ccddea', fontSize: 12}}>A vaga sera encerrada automaticamente neste dia, o encerramento poderá ser adiado ou adiantado caso necessário</span> </div>
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().end_date} </span> </div>
                        </Col>


                        <Col xs={24} className={'formItem'}>
                            <div className='resumeLabel'> <label> Horário de Trabalho </label> </div>
                            {getFieldDecorator('schedule',
                                {
                                    rules: [{
                                        required: false,
                                        message: 'Nome é obrigatório'
                                    }],
                                    initialValue: undefined,
                                }
                            )(
                                <TextArea maxLength={300} style={{minHeight: 50}} placeholder={'Informe caso necessário os horários de trabalho, Ex: Das 07:00 às 18:00 de segunda a sexta.'} size={'large'}/>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().schedule} </span> </div>
                        </Col>

                        <Col xs={24} className={'formItem'}>
                            <div className='resumeLabel'> <label> Benefícios </label> </div>
                            {getFieldDecorator('incentives',
                                {
                                    rules: [{
                                        required: false,
                                        message: 'Nome é obrigatório'
                                    }],
                                    initialValue: undefined,
                                }
                            )(
                                <Select
                                    mode="tags"
                                    getInputElement={inputLimitText}
                                    notFoundContent={"Digite para inserir"}
                                    size={'large'}
                                    tokenSeparators={[',']}
                                    className={"tagsInput"}
                                    placeholder="Incentivos da vaga, Ex. 'Plano de saúde', 'Vale transporte' e etc..."
                                    style={{ width: '100%' }}
                                    filterOption={(data, opt) => {
                                        return opt.props.phonetic.toLowerCase().match(encode(data).toLowerCase()) !== null
                                    } }
                                >
                                    {allCategories?.incentives?.map((item, index) => <Select.Option value={item.label} label={item.label} key={index} phonetic={item.phonetic} >{item.label}</Select.Option>)}
                                </Select>
                            )}
                            <div style={{textAlign: 'right'}}> <span style={{color: '#ccddea', fontSize: 12}}>Se há um benefício não inserido na listagem, digite manualmente para inserir</span> </div>
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().incentives} </span> </div>
                        </Col>


                        <Col xs={24} className={'formItem'}>
                            <div className='resumeLabel'> <label> Descrição da Vaga </label> </div>
                            {getFieldDecorator('job_description',
                                {
                                    rules: [{
                                        required: false,
                                        message: 'Nome é obrigatório'
                                    }],
                                    initialValue: undefined,
                                }
                            )(
                                <TextArea style={{minHeight: 200}} maxLength={1000} placeholder={'Descreva aqui sobre a vaga e o serviço que será realizado'} size={'large'}/>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().job_description} </span> </div>
                        </Col>


                        {/*<Col xs={24} lg={24} style={{textAlign: 'center', display: "flex", flexFlow: "column", justifyContent: "center", flexWrap:"wrap"}}>*/}
                        {/*    <span style={{fontSize: 16, fontWeight: "bold", marginBottom: 10}}> Imagem destaque da vaga <Popover content={'A imagem de destaque aparecerá nos detalhes da vaga (formato banner)'}> <Icon  style={{color: "gray"}}  type='question-circle'/> </Popover> </span>*/}
                        {/*    <div style={{display: 'flex', flexDirection: 'row', flexWrap:"wrap"}}>*/}
                        {/*        <div style={{display: 'flex', alignItems: 'center', justifyContent:"center"}}>*/}
                        {/*            <img src={Images.img_upload} style={{maxWidth: 200}}/>*/}
                        {/*            <div style={{maxWidth: 200, textAlign: 'left'}}>*/}
                        {/*                <h3>Insira uma imagem destaque</h3>*/}
                        {/*                <span> Adicione uma imagem para ser exibida junto a sua publicação de vaga.</span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div style={{flex: 1, justifyContent: 'center', display: 'flex', alignItems: 'center',flexWrap:"wrap"}}>*/}
                        {/*            <GenericCropImage aspectRatio={2} banner={true} googleFaceImage={vacancyImg} image={vacancyImg}*/}
                        {/*                              setCropedImg={(item) => setVacancyImg(item)} height={300} width={600}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}


                        <Col xs={24} className={'formItem'}>
                            <div className='resumeLabel'> <label> Link de video <Popover content={'O vídeo aparecerá nos detalhes da vaga. (Link do youtube)'}> <Icon  style={{color: "gray"}}  type='question-circle'/> </Popover> </label> </div>
                            {getFieldDecorator('job_video',
                                {
                                    rules: [{
                                        required: false,
                                        message: 'Nome é obrigatório'
                                    }],
                                    initialValue: undefined,
                                }
                            )(
                                <Input placeholder={'Cole aqui o link do video (Ex. https://youtube.com.br/812yu9ds8aid)'} size={'large'}/>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().job_video} </span> </div>
                        </Col>

                        <Col xs={24} className={'formItem'}>
                            {embedVideo && <div className='iframeDiv'>
                                <iframe src={embedVideo}
                                        allow='autoplay; encrypted-media'
                                        allowFullScreen
                                        title='video'
                                />
                            </div>}
                        </Col>

                        <Col xs={24} className={'formItem'}>
                            <div className='resumeLabel'> <label> Mensagem aos candidatos (opcional) </label> </div>
                            {getFieldDecorator('apply_message',
                                {
                                    rules: [{
                                        required: false,
                                        message: 'Nome é obrigatório'
                                    }],
                                    initialValue: undefined,
                                }
                            )(
                                <TextArea style={{minHeight: 100}} maxLength={150} placeholder={'Informe aqui caso deseja informar alguma informação adicional para os candidatos de sua vaga'} size={'large'}/>
                            )}

                        </Col>

                        <Col xs={24} style={{display: "flex", alignItems: "center"}}>
                            <Icon type={"user"} style={{fontSize: 18, marginRight: 5}}  className={"primary-color"}/>
                            <span style={{fontSize: 18, marginRight: 5}}> Requisitos ao candidato <Popover content={'Informe requisitos que deseja dos candidatos para preencher a vaga, você pode optar por permitir a inscrição de candidatos que não se aplicam ao seus requisitos.'}> <Icon  style={{color: "gray"}}  type='question-circle'/> </Popover> </span>
                            <div style={{height: 1, flex: 1, backgroundColor: "gainsboro"}}/>
                        </Col>


                        <Col xs={24} className={'formItem'}>
                            <div className='resumeLabel'> <label>  Habilidades ou Requisitos desejados </label> </div>
                            {getFieldDecorator('desired_skills',
                                {
                                    rules: [{
                                        required: false,
                                        message: 'Nome é obrigatório'
                                    }],
                                    initialValue: undefined,
                                }
                            )(
                                <Select
                                    mode="tags"
                                    getInputElement={inputLimitText}
                                    notFoundContent={"Digite para inserir"}
                                    size={'large'}
                                    tokenSeparators={[',']}
                                    className={"tagsInput"}
                                    placeholder="Habilidades desejadas para o cargo"
                                    style={{ width: '100%' }}
                                    filterOption={(data, opt) => {
                                        return opt.props.phonetic.toLowerCase().match(encode(data).toLowerCase()) !== null
                                    } }
                                >
                                    {allCategories?.skills?.map((item, index) => <Select.Option value={item.label} label={item.label} key={index} phonetic={item.phonetic}>
                                        {item.label}
                                    </Select.Option>)}
                                </Select>
                            )}
                            <div style={{textAlign: 'right'}}> <span style={{color: '#ccddea', fontSize: 12}}>Se há uma habilidade ou requisto não inserido na listagem, digite manualmente para inserir</span> </div>
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().desired_skills} </span> </div>
                        </Col>
                        {!vacancyLoading &&
                            <Col xs={24} style={{paddingTop:0}}>
                                <div className='resumeLabel'> <label style={{fontSize:16}}>Gostaria que o candidato tenha realizado algum curso de capacitação?</label> </div>
                                {getFieldDecorator('desired_courses_categories',
                                    {
                                        rules: [],
                                    }
                                )(
                                    <>
                                        <Select placeholder='Selecione a área do curso'
                                                allowClear
                                                defaultValue={vacancy.current?.desired_courses_categories ? vacancy.current?.desired_courses_categories[0].id : undefined}
                                                style={{width:'100%', marginTop:10}}
                                                onChange={(e) => setFieldsValue({desired_courses_categories: e === undefined ? e : [e]})}
                                        >
                                            {allCategories?.courses?.map((item) => (
                                                <Select.Option value={item?.id}>
                                                    {item?.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </>
                                )}
                            </Col>
                        }
                        <Col xs={24} style={{padding: 5}}>
                            <Row style={{borderBottom: '1px solid gainsboro', margin: 0}} gutter={[16,16]}>
                                <Col xs={24} lg={12} className={'formItem'} >
                                    <div className='resumeLabel' style={{marginBottom: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Deve possuir escolaridade mínima? </label>
                                        <div>
                                            <Radio.Group className={'radio'} name="languageRadio" value={showSchooling}>
                                                <Radio value={true} onChange={() => {setShowSchooling(true)}}>
                                                    Sim
                                                </Radio>
                                                <Radio value={false} onChange={() => {setShowSchooling(false)}}>
                                                    Não
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    {getFieldDecorator('schooling',
                                        {
                                            rules: [],
                                            initialValue: undefined,
                                        }
                                    )(
                                        <Radio.Group
                                            className={"radioGroupCustom"}
                                            style={{display: !showSchooling&& "none"}}
                                            options={schoolingList}
                                        />
                                    )}
                                </Col>

                                <Col xs={24} lg={12} className={'formItem'}>
                                    <div className='resumeLabel' style={{marginBottom: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Deve falar outro idioma? </label>
                                        <div>
                                            <Radio.Group className={'radio'} name="languageRadio" value={showLanguages}>
                                                <Radio value={true} onChange={() => {setShowLanguages(true)}}>
                                                    Sim
                                                </Radio>
                                                <Radio value={false} onChange={() => {setShowLanguages(false)}}>
                                                    Não
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    {getFieldDecorator('languages',
                                        {
                                            rules: [],
                                            initialValue: undefined,
                                        }
                                    )(
                                        <Checkbox.Group
                                            style={{display: !showLanguages&& "none",}}
                                            options={languagesList}
                                        />
                                    )}
                                </Col>
                            </Row>


                            <Row style={{borderBottom: '1px solid gainsboro', margin: 0}} gutter={[16,16]}>
                                <Col xs={24} lg={12} className={'formItem'} >
                                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Deve possuir experiência na ocupação? </label>
                                        <div>
                                            {getFieldDecorator('occupation_cbo_req',
                                                {
                                                    rules: [],
                                                    initialValue: false,
                                                }
                                            )(
                                                <Radio.Group className={'radio'} name="languageRadio">
                                                    <Radio value={true}>
                                                        Sim
                                                    </Radio>
                                                    <Radio value={false}>
                                                        Não
                                                    </Radio>
                                                </Radio.Group>
                                            )}
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={24} lg={12} className={'formItem'} >
                                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> As vagas são destinadas somente a PcD? </label>
                                        <div>
                                            {getFieldDecorator('disabilities',
                                                {
                                                    rules: [],
                                                    initialValue: false,
                                                }
                                            )(
                                                <Radio.Group className={'radio'} name="languageRadio">
                                                    <Radio value={true}>
                                                        Sim
                                                    </Radio>
                                                    <Radio value={false}>
                                                        Não
                                                    </Radio>
                                                </Radio.Group>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row  style={{borderBottom: '1px solid gainsboro', margin: 0}} gutter={[16,16]}>
                                <Col xs={24} lg={12} className={'formItem'} >
                                    <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label> Deve possuir CNH(Carteira de motorista)? </label>
                                        <div>
                                            <Radio.Group className={'radio'} name="languageRadio" value={showDriver}>
                                                <Radio value={true} onChange={() => {setShowDriver(true)}}>
                                                    Sim
                                                </Radio>
                                                <Radio value={false} onChange={() => {setShowDriver(false)}}>
                                                    Não
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    {getFieldDecorator('driver_license',
                                        {
                                            rules: [],
                                            initialValue: undefined,
                                        }
                                    )(
                                        <Radio.Group
                                            className={"radioGroupCustom"}
                                            style={{display: !showDriver&& "none"}}
                                            options={driverList}
                                        />
                                    )}
                                </Col>
                            </Row>

                        </Col>

                        <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                            <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Candidato deve ter disponibilidade para realizar viagens? </label>
                                <div>
                                    {getFieldDecorator('available_travel',
                                        {
                                            rules: [],
                                            initialValue: false,
                                        }
                                    )(
                                        <Radio.Group className={'radio'} name="languageRadio" >
                                            <Radio value={true}>
                                                Sim
                                            </Radio>
                                            <Radio value={false}>
                                                Não
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} className={'formItem'} style={{borderBottom: '1px solid gainsboro'}}>
                            <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Candidato deve ter disponibilidade para dormir no local de trabalho? </label>
                                <div>
                                    {getFieldDecorator('available_sleep_duty',
                                        {
                                            rules: [],
                                            initialValue: false,
                                        }
                                    )(
                                        <Radio.Group className={'radio'} name="languageRadio" >
                                            <Radio value={true}>
                                                Sim
                                            </Radio>
                                            <Radio value={false}>
                                                Não
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} className={'formItem'} >
                            <div className='resumeLabel' style={{marginBottom: 10, marginTop: 10, display: 'flex', flexWrap: 'wrap'}}> <label>Candidato deve ter disponibilidade para ausentar-se de seu domicílio por longos períodos? </label>
                                <div>
                                    {getFieldDecorator('available_leave_home',
                                        {
                                            rules: [],
                                            initialValue: false,
                                        }
                                    )(
                                        <Radio.Group className={'radio'} name="languageRadio" >
                                            <Radio value={true}>
                                                Sim
                                            </Radio>
                                            <Radio value={false}>
                                                Não
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} style={{borderRadius: 5, border: "1px solid gainsboro", }}>
                            {getFieldDecorator('accept_proposal',
                                {
                                    rules: [],
                                    initialValue: false,
                                    valuePropName: "checked"
                                }
                            )(
                                <Checkbox style={{fontSize: 13}}>Permitir candidatos que não se encaixam nos requisitos a se candidatar à vaga mesmo assim</Checkbox>
                            )}
                        </Col>

                        <Col xs={24} style={{display: "flex", alignItems: "center", marginTop: 30}}>
                            <Icon type={"environment"} style={{fontSize: 18, marginRight: 5}}  className={"primary-color"}/>
                            <span style={{fontSize: 18, marginRight: 5}}> Endereço da vaga </span>
                            <div style={{height: 1, flex: 1, backgroundColor: "gainsboro"}}/>
                        </Col>


                        <Col xs={24}  className={'formItem'}>
                            <div className='resumeLabel'> <label> <span> * </span> Selecione o local da vaga </label> </div>
                            {getFieldDecorator('work_place',
                                {
                                    rules: [{
                                        required: true,
                                        message: 'Local é obrigatório'
                                    }],
                                    initialValue: undefined
                                }
                            )(
                                <Select
                                    showSearch
                                    placeholder="Selecione o local"
                                    size={'large'}
                                >
                                    <Select.Option value={'same'}> Mesmo endereço da empresa previamente cadastrado </Select.Option>
                                    <Select.Option value={'other'}> Outro endereço </Select.Option>
                                    <Select.Option value={'mobile'}> Trabalho remoto / Home Office </Select.Option>
                                    <Select.Option value={'itinerant'}> Sem endereço fixo (Ex. Porta a porta, itinerante etc) </Select.Option>
                                </Select>
                            )}
                            <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_place} </span> </div>
                        </Col>

                        <Col xs={24} style={{display: (getFieldsValue().work_place === "same" || getFieldsValue().work_place === "other") ? "block" : "none", paddingTop: 0}}>
                            <div style={{borderRadius: 5, border: "1px solid gainsboro", padding: 10 }}>
                                {getFieldDecorator('hide_address',
                                    {
                                        rules: [],
                                        initialValue: false,
                                        valuePropName: "checked"
                                    }
                                )(
                                    <Checkbox style={{fontSize:13}}>
                                        Ocultar endereço na divulgação da vaga
                                    </Checkbox>
                                )}
                            </div>

                        </Col>


                        {getFieldsValue().work_place === "other" &&
                            <>
                                <Col xs={24} lg={24} className={'formItem'}>
                                    <div className='resumeLabel'> <label><span> * </span> CEP </label> </div>
                                    {getFieldDecorator('work_address.postal_code', {
                                        rules: [
                                            numMinWithMask(),
                                            {
                                                required:  true,
                                                message: 'CEP Obrigatório',
                                            },
                                        ],
                                        initialValue: vacancy.current?.work_address?.postal_code || undefined,
                                    })(
                                        <NumberFormat type={"tel"} disabled={cepLoading} onChange={(e)=> onCepChange(e)} style={{width: '100%', textAlign: 'center'}} placeholder='Digite seu CEP para buscar' className='ant-input ant-input-lg' format={'## ###-###'} mask="_"/>
                                    )}
                                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_address?.postal_code} </span> </div>


                                </Col>
                                <Col xs={24} lg={12} className={'formItem'}>
                                    <div className='resumeLabel'> <label> <span> * </span> Cidade </label> </div>
                                    {getFieldDecorator('work_address.city',
                                        {
                                            rules: [{
                                                required: true,
                                                message: 'Cidade é obrigatório'
                                            }],
                                            initialValue:  vacancy.current?.work_address?.city || undefined,
                                        }
                                    )(
                                        <Input prefix={cepLoading&& <Icon type="loading"/>} disabled={true} placeholder={'Sua cidade'} size={'large'}/>
                                    )}
                                </Col>
                                <Col xs={24} lg={12} className={'formItem'}>
                                    <div className='resumeLabel'> <label> <span> * </span> Estado </label> </div>
                                    {getFieldDecorator('work_address.state_abbrev',
                                        {
                                            rules: [{
                                                required: true,
                                                message: 'Sigla Estado é obrigatório'
                                            }],
                                            initialValue:  vacancy.current?.work_address?.state_abbrev || undefined,
                                        }
                                    )(
                                        <Input prefix={cepLoading&& <Icon type="loading"/>} disabled={true} placeholder={'Informe seu estado'} size={'large'}/>
                                    )}
                                </Col>

                                <Col xs={24} lg={12} className={'formItem'}>
                                    <div className='resumeLabel'> <label> <span> * </span> Rua </label> </div>
                                    {getFieldDecorator('work_address.street',
                                        {
                                            rules: [{
                                                required: true,
                                                message: 'Rua é obrigatório'
                                            }],
                                            initialValue:  vacancy.current?.work_address?.street || undefined,
                                        }
                                    )(
                                        <Input prefix={cepLoading&& <Icon type="loading"/>} placeholder={'Sua rua'} size={'large'}/>
                                    )}
                                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_address?.street} </span> </div>

                                </Col>
                                <Col xs={24} lg={8} className={'formItem'}>
                                    <div className='resumeLabel'> <label> Bairro </label> </div>
                                    {getFieldDecorator('work_address.district',
                                        {
                                            rules: [],
                                            initialValue: vacancy.current?.work_address?.district || undefined,
                                        }
                                    )(
                                        <Input prefix={cepLoading&& <Icon type="loading"/>} placeholder={'Seu bairro'} size={'large'}/>
                                    )}
                                </Col>
                                <Col xs={24} lg={4} className={'formItem'}>
                                    <div className='resumeLabel'>   <label>  <span> * </span> Número </label> </div>
                                    {getFieldDecorator('work_address.street_number',
                                        {
                                            rules: [{
                                                required: true,
                                                message: 'Número é obrigatório'
                                            }],
                                            initialValue:  vacancy.current?.work_address?.street_number || undefined,
                                        }
                                    )(
                                        <Input type={'number'} placeholder={'Número da residência'} size={'large'}/>
                                    )}
                                    <div style={{minHeight: 15, marginTop: 5}}> <span style={{color: 'red', fontSize: 13}}> {getFieldsError().work_address?.street_number} </span> </div>

                                </Col>
                            </>
                        }
                    </Row>

                    <Row style={{ padding: 20, textAlign: 'center'}}>
                        <Button shape={'round'} loading={loading} disabled={loading} onClick={() => handleFinishModal()} className={'ant-btn-secondary'}> {props.edit ? 'Editar Vaga' : ('Divulgar Vaga' + (Env.event ? ' para o evento' : ''))} </Button>
                    </Row>
                </div>
        </div>
    )

};
export default Form.create({name: 'vacancyForm'})(VacancyFormComponent);

