import {Env} from "../Helpers/Env";

const routesArray =
    [
        {
            page_path: "/",
            page_title: `Home | ${Env.application_title}`
        },
        {
            page_path: "/painel/prestador",
            page_title: `Painel Trabalhador | ${Env.application_title}`
        },
        {
            page_path: "/painel/prestador/curriculo-completo",
            page_title: `Currículo Completo | ${Env.application_title}`
        },
        {
            page_path: "/painel/prestador/editar-meu-curriculo",
            page_title: `Editar Meu Currículo | ${Env.application_title}`
        },
        {
            page_path: "/painel/prestador/perfil",
            page_title: `Trabalhador - Meu Perfil | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente",
            page_title: `Painel Contratante | ${Env.application_title}`
        },
        {
            page_path: "/acesso-externo",
            page_title: `Acesso externo | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/perfil",
            page_title: `Contratante - Meu Perfil | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/vagas/todos-os-candidatos",
            page_title: `Todos os Candidatos | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/vagas/detalhes-da-vaga",
            page_title: `Detalhes da Vaga | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/vagas/candidatos-da-vaga",
            page_title: `Candidatos da Vaga | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/vagas/vagas-abertas",
            page_title: `Vagas Abertas | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/vagas/vagas-encerradas",
            page_title: `Vagas Encerradas | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/vagas/criar-vaga",
            page_title: `Criar Vaga | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/vagas/editar-empresa",
            page_title: `Editar Empresa | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/vagas/usuarios",
            page_title: `Usuários | ${Env.application_title}`
        },
        {
            page_path: "/painel/cliente/vagas/outros-curriculos",
            page_title: `Outros Currículos | ${Env.application_title}`
        },
        {
            page_path: "/como-funciona",
            page_title: `Como Funciona | ${Env.application_title}`
        },
        {
            page_path: "/termos",
            page_title: `Termos | ${Env.application_title}`
        },
        {
            page_path: "/politica-de-privacidade",
            page_title: `Política de Privacidade | ${Env.application_title}`
        },
        {
            page_path: "/redefinir-senha",
            page_title: `Redefinir Senha | ${Env.application_title}`

        },
        {
            page_path: "/trabalhar",
            page_title: `Trabalhar | ${Env.application_title}`
        },
        {
            page_path: "/recuperar-senha",
            page_title: `Recuperar Senha | ${Env.application_title}`
        },
        {
            page_path: "/empresa",
            page_title: `Perfil da Empresa | ${Env.application_title}`
        },
        {
            page_path: "/buscar-vagas",
            page_title: `Buscar Vagas | ${Env.application_title}`
        },
        {
            page_path: "/vagas-de-emprego",
            page_title: `Vagas de Emprego | ${Env.application_title}`
        },
        {
            page_path: "/buscar-cursos",
            page_title: `Buscar Cursos | ${Env.application_title}`
        },
        {
            page_path: "/quero-contratar",
            page_title: `Encontrar Prestador de Serviço | ${Env.application_title}`
        },
        {
            page_path: "/prestador-de-servico",
            page_title: `Detalhes Prestador de Serviço | ${Env.application_title}`
        },
        {
            page_path: "/curriculos",
            page_title: `Currículos | ${Env.application_title}`
        },
        {
            page_path: "/recrutamentos",
            page_title: `Recrutamentos | ${Env.application_title}`
        },
        {
            page_path: "/curriculo/privado",
            page_title: `Currículo Privado | ${Env.application_title}`
        },
        {
            page_path: "/notificacoes",
            page_title: `Notificações | ${Env.application_title}`
        },
        {
            page_path: "/perguntas-frequentes",
            page_title: `Perguntas frequentes | ${Env.application_title}`
        }
    ]


export {routesArray}

