import React, {useEffect, useState} from "react";
import { Icon} from "antd";
import Slider from "react-slick";
import useApi from "../../../../Hooks/Api";
import { AiOutlineClockCircle} from "react-icons/all";
import moment from "moment";
import {Images} from "../../../../Helpers/Images";
import {FaBullhorn} from "react-icons/fa";
import DividerCustom from "../../../../Pages/Components/DividerCustom";
import {globalMaskReal} from "../../../../Helpers/Functions";
import {Col} from "antd";

const VacanciesOfTheDay = () => {
    const formatIncentives = (item) => {
        let aux = [];


        for (let i = 0; i < item?.incentives?.length; i++) {
            if (aux.length < 3) {
                aux.push(
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: 'wrap',
                        minHeight: 25,
                        backgroundColor: '#34c38f2e',
                        borderRadius: 4,
                        textAlign: "center",
                        padding: 6,
                        fontSize: 12,
                        lineHeight: 1
                    }}>
                        <span style={{fontWeight: 500, color: '#34c38f'}}>{item?.incentives[i]}</span>
                    </div>
                );
            } else {
                break;
            }
        }

        for (let y = 0; y < item?.incentives_categories?.length; y++) {
            if (aux.length < 3) {
                aux.push(
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: 'wrap',
                        minHeight: 25,
                        backgroundColor: '#34c38f2e',
                        borderRadius: 4,
                        textAlign: "center",
                        padding: 6,
                        fontSize: 12,
                        lineHeight: 1
                    }}>
                        <span style={{fontWeight: 500, color: '#34c38f'}}>{item?.incentives_categories[y]?.title}</span>
                    </div>
                )
            } else {
                break;
            }
        }


        if ((item?.incentives_categories?.length + item?.incentives?.length) > 3) {
            aux.push(<div style={{
                display: "flex",
                alignItems: "center",
                flexWrap: 'wrap',
                minHeight: 25,
                backgroundColor: '#34c38f2e',
                borderRadius: 4,
                textAlign: "center",
                padding: 6,
                fontSize: 12,
                lineHeight: 1
            }}>
                <span style={{fontWeight: 500, color: '#34c38f'}}>...</span>
            </div>)
        }
        return aux;
    };
    const [opportunities, setOpportunities] = useState([])
    const api = useApi();
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 2560,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }, {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    function SampleNextArrow(props) {
        const {className, onClick} = props;
        return (
            <Icon style={{fontSize:24}} className={className + ' de-flex'} onClick={onClick} type="arrow-right" />
        );
    }

    function SamplePrevArrow(props) {
        const {className, onClick} = props;
        return (
            <Icon style={{fontSize:24}} className={className + ' de-flex'} onClick={onClick} type="arrow-left" />
        );
    }

    const getOpportunities = () => {
        api.get(`sine/search_opportunities`, (res) => {
            const dateAtual = moment().format('YYYY-MM-DD')
            let aux = []
            for (let i = 0; i < res.opportunities.length; i++) {
                if (res.opportunities[i].start_date === dateAtual) {
                    aux.push(res.opportunities[i])
                }
            }
            setOpportunities(aux)
        }, (e) => {

        })
    }
    const handleClick = (index) => {
        let id = opportunities[index].id
        window.location.assign(`/vagas-de-emprego/?id=${id}`);
    }

    useEffect(() => {
        getOpportunities()
    }, [])

    return (
        <div>
            {opportunities.length > 0 &&
                <>
                    <div style={{marginTop:82, textAlign:'center'}}>
                        <div>
                            <i>
                                <FaBullhorn size={40} color="#333333"/>
                            </i>
                        </div>
                        <div>
                            <h1 style={{fontSize:25, margin:'16px 0'}}>Vagas publicadas recentemente</h1>
                        </div>
                        <div style={{marginBottom:24}}>
                            <DividerCustom/>
                        </div>
                    </div>
                    <div className="bg-cards" style={{position: 'relative', padding: 20, backgroundImage: `url(${Images.bgWomanWork})`, display: opportunities.length === 0 ? "none" : "block"}}>
                        <div style={{background: "#0d2867", position: "absolute", height: '100%', width: '100%', left: 0, bottom: 0, opacity: "0.8", padding:'42px 0'}}>
                        </div>

                        <Slider {...settings}>
                            {opportunities?.map((item, index) =>
                                <div className='carrousel-item' key={index} onClick={() => handleClick(index)}>
                                    <div style={{height: "198px"}}>
                                        <div className="container-title">
                                        <span className="title-card limit-rows clamp-2">
                                            {item?.occupation_name}
                                        </span>
                                        </div>

                                        <div className="container-image">
                                            <img src={item?.job_avatar ? item?.job_avatar : Images.case_img} width={50} height={50} className="image-avatar"/>
                                        </div>
                                        <div style={{textAlign: 'center', marginBottom: 10}}>
                                            <strong className="text-city">
                                                {item?.job_openings} Vaga{item?.job_openings !== 1 ? 's' : ''}
                                            </strong>
                                        </div>

                                        <div className="container-pay">
                                        <span>
                                            <strong>R$</strong> {item?.pay ? `${globalMaskReal(item?.pay)}` : 'Combinar'}
                                        </span>
                                            <div className="de-flex" style={{gap:4}}>
                                                <AiOutlineClockCircle size={15} color={"#083e6e"}/>
                                                <span> {item?.job_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{minHeight: "235px", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                        <div className="container-incentives">
                                            {(item?.incentives?.length > 0 || item?.incentives_categories?.length > 0) &&
                                                <Col span={24} className='d-flex flex-wrap justify-content-center'
                                                     style={{padding: '10px 0', gap: 2, fontSize: 10, minHeight: 40}}>
                                                    {formatIncentives(item)}
                                                </Col>
                                            }
                                            <div className="container-text limit-rows clamp-3">
                                                <span>{item?.job_description}</span>
                                            </div>
                                        </div>
                                        <div className="container-description">

                                            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                                        <span className="text-city d-flex" style={{alignItems: 'center'}}>
                                        <Icon  type="environment" /> &emsp;
                                            {item?.work_address?.city}
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </>
            }
        </div>

    )
}
export default VacanciesOfTheDay




