import React from 'react'
import {Icon, Row} from "antd";
import Col from "antd/es/grid/col";
import Button from "antd/es/button";
import {Images} from "../../Helpers/Images";
import CounterComponent from "../../Components/PageComponents/Public/HomePage/CounterComponent";
import Slider from "react-slick";
import {FaChevronRight, FaChevronLeft} from 'react-icons/fa'

export const LandingPage = () => {

  const NextArrow = ({onClick}) => {
    return <Row onClick={onClick}>
      <FaChevronRight className='arrow-landing' size={28} />
    </Row>

  }

  const PrevArrow = ({onClick}) => {
    return <Row onClick={onClick}>
      <FaChevronLeft className='arrow-landing' size={28} />
    </Row>
  }

  const settings = {
    infinite: true,
    slidesToShow:1,
    slidesToScroll:1,
    centerMode: true,
    centerPadding:0,
    speed: 500,
    autoplaySpeed:5000,
    pauseOnHover: true,
    nextArrow: <NextArrow/>,
    prevArrow:<PrevArrow/>,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          pauseOnHover: true,
          centerPadding:0,
          centerMode: true,
          nextArrow: <NextArrow/>,
          prevArrow:<PrevArrow/>,
        }
      }
    ]

  };

  return (
      <Row className='landing-page'>
        <Row style={{minHeight:500}} className='d-flex flex-wrap justify-content-between align-items-center bg-header'>
          <Col md={12} lg={12} style={{padding:'2rem', position:"relative"}} className={`main-title ${window.innerWidth <= 450 ? 'text-center' : 'text-unset'}`}>
            <h1 style={{marginBottom:12, color:'#5e62ff', fontWeight:700}} className='text-white'>
              Recrutamento e seleção de forma simples
            </h1>
            <p className='text-muted'>
              Receba currículos qualificados para sua vaga de emprego
            </p>
            <Col xs={24} md={14} lg={12} xl={8} style={{height:50}}>
              <Button className='btn-header w-100'>
                <span className='text-white'>Anunciar vagas de graça</span>
              </Button>
            </Col>
          </Col>
          <Col md={12} lg={8}>
            <img className='w-100' src={Images.capaBusiness}/>
          </Col>
        </Row>

        <Row style={{marginTop:'4rem'}} className='d-flex justify-content-center'>
            <Col  xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
              <Row className='d-flex justify-content-center align-items-center flex-column service-title'>
                <h4 className='text-uppercase'>Nossa solução</h4>
                <div>
                  <img src={Images.lineLanding}/>
                </div>
                <p className='text-center'>
                  Ferramentas para ajudar a sua empresa a contratar de forma fácil,<br/> rápida e gratuita!
                </p>
              </Row>
              <Row className='d-flex flex-wrap'>
                <Col xs={24} md={12} lg={8} className='service-box'>
                  <div className='p-1'>
                    <div className='service-icon'>
                      <img style={{height:54, width:54}} src={Images.publishLanding}/>
                    </div>
                    <div className='service-title'>
                      <h4>Publique vagas</h4>
                      <p className='text-justify'>
                        Milhares de trabalhadores podem ver sua vaga disponível, seus requisitos e se candidatar a ela, você receberá currículos padronizados para sua seleção!
                      </p>
                    </div>
                  </div>
                </Col>

                <Col xs={24} md={12} lg={8} className='service-box'>
                  <div className='p-1'>
                    <div className='service-icon'>
                      <img style={{height:54, width:54}} src={Images.searchLanding}/>
                    </div>
                    <div className='service-title'>
                      <h4>
                        Busque currículos
                      </h4>
                      <p className='text-justify'>
                        Busque diretamente currículos de seu interesse e entre em contato com os que mais que interessarem. Temos mais de 15 filtros de qualificação!
                      </p>
                    </div>

                  </div>
                </Col>
                <Col xs={24} md={12} lg={8} className='service-box'>
                  <div className='p-1'>
                    <div className='service-icon'>
                      <img style={{height:54, width:54}} src={Images.freeLanding}/>
                    </div>
                    <div className='service-title'>
                      <h4>
                        100% Gratuito
                      </h4>
                      <p className='text-justify'>
                        A Plataforma é totalmente gratuita para divulgar vagas de emprego ou buscar quantos currículos desejarem!
                      </p>
                    </div>

                  </div>

                </Col>
                <Col xs={24} md={12} lg={8} className='service-box'>
                  <div className='p-1'>
                    <div className='service-icon'>
                      <img style={{height:54, width:54}} src={Images.locationLanding}/>
                    </div>
                    <div className='service-title'>
                      <h4>
                        Geolocalização
                      </h4>
                      <p className='text-justify'>
                        Quando se tem uma vaga publicada, priorizamos os candidatos que residem mais próximo de sua empresa!
                      </p>
                    </div>
                  </div>
                </Col>

                <Col xs={24} md={12} lg={8} className='service-box'>
                  <div className='p-1'>
                    <div className='service-icon'>
                      <img style={{height:54, width:54}} src={Images.securityLanding}/>
                    </div>
                    <div className='service-title'>
                      <h4>Segurança</h4>
                      <p className='text-justify'>
                        As informações e dados cadastrais de sua empresa são protegidos e não são revelados a terceiros em nenhuma hipótese sem sua permissão!
                      </p>
                    </div>
                  </div>

                </Col>
                <Col xs={24} md={12} lg={8} className='service-box'>
                  <div className='p-1'>
                    <div className='service-icon'>
                      <img style={{height:54, width:54}} src={Images.responsiveLanding}/>
                    </div>
                    <div className='service-title'>
                      <h4>
                        Plataforma totalmente responsiva
                      </h4>
                      <p className='text-justify'>
                        Publique vagas pelo nosso site e veja os currículos de todos os seus candidatos , tanto no computador quanto no celular e entre em contato com ele.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        <Row className='d-flex justify-content-center'>
          <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
            <Slider {...settings}>
              <Row className={`d-flex justify-content-${window.innerWidth <= 425 ? 'center' : 'between'} flex-wrap`}>
                <Col xs={20} md={9} lg={10} style={{background:'#f2f3ff', borderRadius:16, gap:16}} className='p-1'>
                  <iframe width="100%" height={window.innerWidth <=450 ? 500 : 600} style={{borderRadius:8}} src="https://www.youtube.com/embed/F0yK6e7AEcM"
                          title="Carlos-proprietário da ENTECH,parceiros da Claro { conseguiu fechar o quadro de funcionários }."
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen/>
                </Col>

                <Col xs={20} md={10} lg={6} className='d-flex flex-column justify-content-center service-title'>
                  <h3>Carlos Eduardo, proprietário da ENTECH, parceiros da operadora Claro</h3>
                  <p className='text-muted text-justify'>
                    "Uma ferramenta de grande valia para fechar o quadro de funcionários da empresa"
                  </p>
                </Col>
              </Row>

              <Row className={`d-flex justify-content-${window.innerWidth <= 425 ? 'center' : 'between'} flex-wrap`}>
                <Col xs={20} md={9} lg={10} style={{background:'#f2f3ff', borderRadius:16, gap:16}} className='p-1'>
                  <iframe width="100%" height={window.innerWidth <=450 ? 500 : 600} style={{borderRadius:8}} src="https://www.youtube.com/embed/2sg5eCZ_x_8"
                          title="Amanda-Recrutadora do Empório Germânia Campo Grande { 2 contratações de sucesso ao publicar vagas }."
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen/>
                </Col>

                <Col xs={20} md={10} lg={6} className='d-flex flex-column justify-content-center service-title'>
                  <h3>
                    Amanda, recrutadora da Empório Germânia Campo Grande
                  </h3>
                  <p className='text-muted text-justify'>
                    "Consegui contratar 2 funcionários para a empresa, contratações de sucesso!"
                  </p>
                </Col>
              </Row>

              <Row className={`d-flex justify-content-${window.innerWidth <= 425 ? 'center' : 'between'} flex-wrap`}>
                <Col xs={20} md={9} lg={10} style={{background:'#f2f3ff', borderRadius:16, gap:16}} className='p-1'>
                  <iframe width="100%" height={window.innerWidth <=450 ? 500 : 600} style={{borderRadius:8}} src="https://www.youtube.com/embed/EBrvA6gj1B8"
                          title="Thiago- Gestor de unidades do inoffice coworking { Ultilizou recomenda o MS Contrata mais }."
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen/>
                </Col>

                <Col xs={20} md={10} lg={6} className='d-flex flex-column justify-content-center service-title'>
                  <h3>Thiago, Gestor de unidades do Inoffice coworking</h3>
                  <p className='text-muted text-justify'>
                    "No MS Contrata mais dão todo o suporte necessário para que tudo seja bem alinhado entre empresa e colaborador"
                  </p>
                </Col>
              </Row>

              <Row className={`d-flex justify-content-${window.innerWidth <= 425 ? 'center' : 'between'} flex-wrap`}>
                <Col xs={20} md={9} lg={10} style={{background:'#f2f3ff', borderRadius:16, gap:16}} className='p-1'>
                  <iframe width="100%" height={window.innerWidth <=450 ? 500 : 600} style={{borderRadius:8}} src="https://www.youtube.com/embed/j-ylqgm8nLI"
                          title="Jéssica- Recrutadora do Burguer King { Todas as vezes que precisa, publica no MS Contrata Mais }."
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen/>
                </Col>

                <Col xs={20} md={10} lg={6} className='d-flex flex-column justify-content-center service-title'>
                  <h3>Jéssica, recrutadora do Burguer King</h3>
                  <p className='text-muted text-justify'>
                    "Todas as vezes que preciso, publico no MS Contrata Mais"
                  </p>
                  <small>Proprietário da ENTECH</small>
                </Col>
              </Row>
            </Slider>
          </Col>
        </Row>

        <Row style={{backgroundColor:'#fbfbfb', marginTop:'4rem'}}>
          <Row className='d-flex justify-content-center'>
            <Col style={{marginTop:'4rem'}} xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
              <Row  className='d-flex justify-content-center text-center'>
                <Col lg={12} md={14} className='service-title'>
                  <h4 className='text-uppercase'>
                    Quem somos nós
                  </h4>
                  <div>
                    <img src={Images.lineLanding}/>
                  </div>
                  <p>
                    Plataforma de empregabilidade totalmente gratuita oferecida pelo Governo do Estado do Mato Grosso do Sul em parceria com a FUNTRAB.
                  </p>
                </Col>
              </Row>
              <Row style={{gap:8}} className='d-flex justify-content-center align-items-center flex-wrap'>
                <Col xs={20} md={10} lg={8}>
                  <img className='w-100' src={Images.aboutLanding}/>
                </Col>

                <Col xs={20}  md={12} lg={9} className='d-flex flex-column service-title offset-lg-1'>
                  <h2>
                    Nosso objetivo é promover a empregabilidade no estado de Mato Grosso do Sul, fazendo o papel de conectar as empresas aos trabalhadores mais qualificados para elas.
                  </h2>
                  <p className='text-justify'>
                    Desde dezembro de 2020, o MS Contrata Mais trabalha para ter o máximo de trabalhadores contratados para as empresas que disponibilizam vagas, sempre otimizando nossa plataforma e atribuindo novas funcionalidades que melhor atendem às necessidades de nossos usuários.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>

        <Row style={{marginTop:'-4rem'}}>
          <CounterComponent/>
        </Row>

        <Row style={{marginTop:'4rem'}} className='d-flex justify-content-center'>
          <Col className='d-flex flex-column justify-content-center' xs={24} sm={24} md={24} lg={22} xl={20} xxl={17} >
            <Row className='service-title text-center text-uppercase'>
              <h4>Clientes que usam o MS Contrata+</h4>
            </Row>
            <div className='text-center'>
              <img src={Images.lineLanding}/>
            </div>
            <Row className='d-flex justify-content-between align-items-center flex-wrap'>
              <div>
                <img style={{height:100}} src={Images.americanas} alt="Logo Americanas"/>
              </div>
              <div>
                <img style={{height:100}} src={Images.anita} alt="Logo Anita"/>
              </div>
              <div>
                <img style={{height:100}} src={Images.cometa} alt="Logo Cometa"/>
              </div>
              <div>
                <img style={{height:100}} src={Images.girafas} alt="Logo Girafas"/>
              </div>
              <div>
                <img style={{height:100}} src={Images.safari} alt="Logo Safari"/>
              </div>
              <div>
                <img style={{height:100}} src={Images.niura} alt="Logo Safari"/>
              </div>
            </Row>
          </Col>
        </Row>
      </Row>

  )
}
