import React, {useCallback, useEffect, useRef, useState} from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {debounce} from "lodash";
import {Icon} from "antd";
import {BsEye, BsEyeSlash, BsCheck, BsPinFill} from 'react-icons/bs'
import {Images} from "../../../../Helpers/Images";
import {BiCommentDetail, FaExpand, FaMinusCircle} from "react-icons/all";
import useApi from "../../../../Hooks/Api";

const RichTextEditor = (props) => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [messageSave, setMessageSave] = useState(false)
  const [loading, setLoading] = useState(true);
  const [hideNote, setHideNote] = useState(false)
  const api = useApi();
  const savedValue = useRef(null)

  const getData = () => {
    api.get(`sine/profile/${props.sine_id}/notes/${props.company_id}`, (res) => {
      if(res.notes) {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(res.notes))))
        savedValue.current = res.notes;
      } else {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(``))))
        savedValue.current = ``;
      }
      setLoading(false);
    },(e) => console.log(e))
  }

  const handleNote = (item) => {
    setHideNote(item)
  }

  const autoSaveContent =  useCallback(debounce(async (e) => {
    let toHTML = draftToHtml(e);
    toHTML = toHTML.trim()
    if (toHTML.length === 7) {
      toHTML = ``
    } else if (toHTML !== savedValue.current) {
      api.post(`sine/profile/${props.sine_id}/notes/${props.company_id}`, {notes: toHTML}, (res) => {
        console.log(res)
        setMessageSave(true)
        hideMessage()
      }, (e) => console.log(e))
    }

    savedValue.current = toHTML
  }, 1000),[])

  const hideMessage = useCallback(debounce(async () => {
    setMessageSave(false)
  }, 4000),[])

  useEffect(()=> {
    getData()
  },[])

  return (
      <div style={{display: loading? 'none' : 'unset'}} className={`rich-text custom-scrollbar ${props?.finalStatus !== 'none' && hideNote ? 'flex-unset overflowY-unset' : hideNote ? 'flex-unset h-auto overflowY-unset' : '' }`}>

          <div style={{ height:'100%', minHeight: '95px',}}>
            <div className="top" onClick={() => handleNote(!hideNote)}>
              <div style={{marginRight: 20}}>
                <BiCommentDetail style={{color: 'gray', fontSize: 25}}/>
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{fontWeight: 'bold', color: '#5a5a5a'}}>Seu bloco de anotações</span>
                {messageSave === true ?
                    <div className={`d-flex align-items-center`} style={{fontSize:12, color:'#9b9b9b', opacity: 0.5, fontStyle:"italic",}}>
                      <BsCheck/>
                      <span>
                        Alterações salvas
                      </span>
                    </div>
                    :
                    <span style={{fontSize: 12, color: '#9b9b9b'}}>Guarde aqui informações relevantes sobre o candidato</span>
                }
              </div>
              <div style={{position: 'absolute', right: 10, top: 10, cursor: 'pointer'}}>
                {!hideNote? <FaMinusCircle/> : <FaExpand/>}
              </div>
            </div>
            <div style={{height: hideNote ? '0px' : 'calc(100% - 59px)', lineHeight: 1, background:'#fffef0'}}>
              <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  onChange={autoSaveContent}
                  className={'paper'}
                  toolbar={{
                    options: ['inline','list'],
                    inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough']},
                    list: { inDropdown: false, options:['unordered', 'ordered'] },
                  }}
                  onFocus={(event) => {
                    props.setDisableArrows(true)
                  }}
                  onBlur={(event, editorState) => {
                    props.setDisableArrows(false)
                  }}
              />
            </div>

          </div>
      </div>
  )
}

export default RichTextEditor
