import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Icon, message, Modal, Popover, Row, Select} from "antd";
import {Fade} from "react-reveal";
import NumberFormat from "react-number-format";
import useApi from "../Hooks/Api";
import {Texts} from "../Helpers/Texts";
import {useHistory} from "react-router-dom";

import {Env} from "../Helpers/Env";
import {Images} from "../Helpers/Images";
import GenericCropImage from "../Components/Generic/GenericCropImage";
import GenericInput from "../Components/Generic/GenericInput";
import {useValidationShorts} from "../Hooks/Helpers";
import CepInput from "../Components/Generic/CepInput";
import {useSelector} from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import useApiClub from "../Hooks/ApiClub";
import VerificationCodeComponent from "./Components/VerificationCodeComponent";
import useLocalStorage from "../Hooks/Storage";
import {StorageVariables} from "../Helpers/StoragesVariables";


const RegisterEnterpriseComponent = (props) => {
    const {Option} = Select;
    const userStorage = useLocalStorage(StorageVariables.userProfile);
    const [imageVisible, setImageVisible] = useState(undefined)
    const [step, setStep] = useState('registerForm'); // login, regNumber, regNumberAgain, regCode, regFinal
    const api = useApi();
    const apiClub = useApiClub();
    const imageApi = useApi();
    const [loading, setLoading] = useState(false);
    const {getFieldError, getFieldsValue, setFieldsValue, getFieldDecorator, validateFields} = props.form;
    const {isEmail, required} = useValidationShorts();
    const disableDistrict = useRef(true);
    const disableStreet = useRef(true);
    const [addressVisible, setAddressVisible] = useState(false);
    const user = useSelector(state => state).authReducer;
    const [alertLogo, setAlertLogo] = useState(false);
    const [enterpriseBanner, setEnterpriseBanner] = useState(undefined);
    const [employerType, setEmployerType] = useState('legal_entity'); //legal_entity individual
    const [establishmentCategories, setEstablishmentCategories] = useState([]);
    const history = useHistory();
    const [phoneValidation, setPhoneValidation] = useState(false);
    const errorMessagesSerpro = useRef([]);

    const checkPhoneValidation = (ignore) => {
        validateFields(async (error) => {
            if (error) {
                return;
            }
            if (imageVisible || ignore) {
                setAlertLogo(false);
                if (!user.mobile_verified) {
                    setPhoneValidation(true);
                } else {
                    setAlertLogo(false);
                    doRegister()
                }
            } else {
                setAlertLogo(true);
            }

        })
    };

    const doRegister = (e) => {
        if (e) e.preventDefault();
        window.scrollToElement('body');
        setAlertLogo(false);
        validateFields(async (error, values) => {
            if (error) return;
            setLoading(true);
            let objToSend = values;
            objToSend.address.postal_code = objToSend.address.postal_code.replace(/\D/ig, '');

            if (objToSend.cnpj) {
                objToSend.cnpj = objToSend.cnpj.replace(/\D/ig, '');
            }
            if (objToSend.cpf) {
                objToSend.cpf = objToSend.cpf.replace(/\D/ig, '');
            }

            objToSend.employer_type = employerType;
            if (imageVisible) {
                if (props.edit && imageVisible !== props.enterprise.company_logo) {
                    objToSend.company_logo = {
                        base64_picture: imageVisible.replace('data:image/jpeg;base64,', ''),
                        filename: "logo.png"
                    }
                } else if (!props.edit) {
                    objToSend.company_logo = {
                        base64_picture: imageVisible.replace('data:image/jpeg;base64,', ''),
                        filename: "logo.png"
                    }
                }
            }
            if (enterpriseBanner) {
                if (props.edit && enterpriseBanner !== props.enterprise.company_banner) {
                    objToSend.company_banner = {
                        base64_picture: enterpriseBanner.replace('data:image/jpeg;base64,', ''),
                        filename: "banner.png"
                    }
                } else if (!props.edit) {
                    objToSend.company_banner = {
                        base64_picture: enterpriseBanner.replace('data:image/jpeg;base64,', ''),
                        filename: "banner.png"
                    }
                }
            }
            objToSend.application = Env.application_alias;

            if (props.edit) {
                message.loading("Atualizando Empresa...");
                api.put(`sine/user/${user.user_id}/company/${props.enterprise.id}`, objToSend, (res) => {
                    message.success("Dados Atualizados com sucesso");
                    props.getData();
                    setLoading(false);
                }, (e) => {
                    message.error(e.message || 'Não foi possível criar a empresa, tente novamente');
                    setLoading(false);
                });

            } else {
                fakeLoadingSerpro();
                setStep('serproLoading')
                api.post(`sine/user/${user.user_id}/company`, objToSend, (res) => {
                    history.push(`/painel/cliente/vagas/${res.user_company_id}/todos-os-candidatos`)
                    setStep('regEnd');
                    props.getData();
                    if (userStorage.getObject().mobile_verified === false) {
                        let updateValueInStorage = userStorage.getObject()

                        updateValueInStorage.mobile_verified = true
                        userStorage.setObject(updateValueInStorage)
                    }
                }, (e) => {
                    validateErrorTypeFromSerpro(e)
                });

            }
        })
    }

    const fakeLoadingSerpro = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 6800)
    }

    const getCategories = () => {
        apiClub.get('public/club/company/category', (res) => {
            setEstablishmentCategories(res.object)
        }, (e) => {
            console.log(e)
        })
    }

    const checkCepInfoAvailability = (res) => {
        disableDistrict.current = true;
        disableStreet.current = true;
        const auxObj = {};
        if (res.district) {
            if (res.district === res.street_name) {
                disableDistrict.current = false;
            }
            auxObj.district = res.district;
        } else {
            auxObj.district = "";
            disableDistrict.current = false;
        }
        if (res.street_name) {
            if (res.district === res.street_name) {
                disableStreet.current = false;
            }
            auxObj.street_name = res.street_name;
        } else {
            auxObj.street_name = "";
            disableStreet.current = false;
        }
        setFieldsValue({
            address: {
                district: auxObj.district,
                street: auxObj.street_name,
                city: res.city,
                state_abbrev: res.state,
                country: res.country
            }
        });
    };

    const validateErrorTypeFromSerpro = (res) => {
        let errorMessages = [];

        if (res.data?.cpf_disponivel === false) {
            window.gtagService('event', 'cpf_disponivel_false');
            errorMessages.push('CPF indisponível na base do Governo Federal')
        }
        if (res.data?.situacao_cpf === false) {
            window.gtagService('event', 'situacao_cpf_false');
            errorMessages.push('CPF Cancelado, Nulo ou Suspenso')
        }
        if (res.data?.data_nascimento === false) {
            window.gtagService('event', 'data_nascimento_false');
            errorMessages.push(<div style={{display: 'flex', justifyContent: 'space-between'}}><span>Data de nascimento não corresponde com o CPF informado</span>
                <span onClick={() => history.push('/painel/cliente/perfil')}
                      style={{color: '#3d8bfd', cursor: 'pointer'}}>Atualizar data de nascimento</span></div>);
        }

        if (res.data?.cnpj_disponivel === false) {
            window.gtagService('event', 'cnpj_disponivel_false');
            errorMessages.push('CNPJ Indisponível na base do Governo Federal')
        }
        if (res.data?.situacao_cadastral?.codigo === false) {
            window.gtagService('event', 'cnpj_situacao_cadastral_false');
            errorMessages.push('Situação cadastral do CNPJ - Suspensa, Inapta, Baixada ou Nula')
        }

        errorMessagesSerpro.current = errorMessages;
        if (errorMessages.length > 0)
            setStep('errorSerpro')
        else {
            message.error(res.message || 'Não foi possível criar a empresa, tente novamente');
            setStep('registerForm')
        }
    }

    useEffect(() => {
        if (props.edit && props.enterprise) {
            setAddressVisible(true);
            setFieldsValue(props.enterprise);
            setEmployerType(props.enterprise?.employer_type);
            setImageVisible(props.enterprise.company_logo);
            setEnterpriseBanner(props.enterprise.company_banner);
        }
    }, [props.enterprise]);

    useEffect(() => {
        getCategories();
        if (props.newBusiness) {
            setStep(props.newBusiness)
        }
    }, [])


    return (<div>
        <Modal
            visible={alertLogo}
            footer={null}
            bodyStyle={{padding: 0}}
            width={'80%'}
            onCancel={() => {
                setAlertLogo(false)
            }}
            className={'registerModalWidth'}
            destroyOnClose={true}
            style={{maxWidth: '800px'}}
        >
            <div style={{padding: 30}}>
                <div>
                    <div style={{textAlign: 'center', fontSize: 20}}>
                        <span style={{
                            color: imageVisible ? '#68bb23' : '#424242',
                            fontSize: 20,
                            fontWeight: 'bold'
                        }}> {imageVisible ? 'Logo Adicionada!' : 'Vimos que você não inseriu uma Logo para sua Empresa'} </span>
                        <br/>
                        <span style={{color: '#424242', fontSize: 16}}> Ter uma logo é muito importante, pois aumenta o destaque de suas vagas publicadas no aplicativo além de dar maior credibilidade.  </span>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                        {imageApi.loading ?
                            <Icon type={'loading'} style={{fontSize: 100}}/>
                            :
                            <GenericCropImage googleFaceImage={imageVisible} image={imageVisible}
                                              setCropedImg={(item) => setImageVisible(item)} height={360} width={360}
                                              circularCrop={true}/>
                        }
                    </div>
                    <div style={{
                        padding: 20,
                        paddingBottom: 0,
                        display: "flex",
                        justifyContent: 'center',
                        flexWrap: 'wrap'
                    }}>
                        <Button loading={loading} onClick={() => props.edit ? doRegister() : checkPhoneValidation(true)}
                                className={'ant-btn-secondary'}>{!imageVisible ? 'Continuar Cadastro mesmo assim' : 'Continuar Cadastro'} </Button>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
            visible={phoneValidation}
            footer={null}
            bodyStyle={{padding: 0, display: 'flex', flexDirection: 'column'}}
            width={'80%'}
            onCancel={() => {
                setPhoneValidation(false)
            }}
            className={'registerModalWidth'}
            destroyOnClose={true}
            style={{maxWidth: '800px'}}
        >
            <Col xs={24} className={'sideLogin'} style={{backgroundColor: "#315dc3"}}>
                <div style={{padding: 20}}>
                    <Fade left spy={step}>
                        <div className={'logTitle'} style={{fontSize: 18}}>
                            <span> <strong> Quase lá!! </strong> </span>
                            <div className={'line'} style={{backgroundColor: "#173277", marginBottom: 10}}/>
                            <span style={{fontSize: 16}}> Para finalizar o cadastro de sua empresa só falta confirmar seu número de telefone</span>
                        </div>
                    </Fade>
                </div>
            </Col>
            <VerificationCodeComponent showScreen={phoneValidation}
                                       setShowScreen={() => setPhoneValidation(false)}
                                       resumeRegister={() => {
                                           setPhoneValidation(false)
                                           doRegister()
                                       }}
            />
        </Modal>
        <Row style={{display: phoneValidation ? 'none' : 'flex', alignItems: 'stretch', flexWrap: 'wrap'}}>
            {!props.edit &&
            <Col xs={24} className={'sideLogin'} style={{backgroundColor: "#315dc3"}}>
                <div style={{padding: 20}}>
                    <Fade left spy={step}>
                        {step === "registerForm" ?
                            <div className={'logTitle'}
                                 style={{fontSize: 18}}> {Texts.auth_page.register_enterprise_title}
                                <div className={'line'}
                                     style={{backgroundColor: "#173277", marginBottom: 10, marginTop: 10}}/>
                                <span style={{fontSize: 16}}> Caso deseje publicar vagas é necessário realizar o cadastro de sua empresa</span>
                            </div>
                            :
                            step === 'addBusiness' ?
                                <div className={'logTitle'} style={{fontSize: 18}}>
                                    <span> <strong> Vamos cadastrar uma nova empresa? </strong> </span>
                                    <div className={'line'}
                                         style={{backgroundColor: "#173277", marginBottom: 10, marginTop: 10}}/>
                                    <span style={{fontSize: 16}}> Adicione uma nova empresa ao seu perfil</span>
                                </div>
                                : step === 'regEnd' ?
                                <div className={'logTitle'}
                                     style={{fontSize: 18}}> {Texts.auth_page.register_enterprise_end}
                                    <div className={'line'} style={{backgroundColor: "#173277"}}/>
                                </div>
                                : step === 'errorSerpro' && !loading ?
                                    <div className={'logTitle'} style={{fontSize: 18}}>
                                        <span> <strong> Sua Empresa não pode ser criada </strong>  </span>
                                        <div className={'line'} style={{backgroundColor: "#173277"}}/>
                                    </div>
                                    :
                                    <div className={'logTitle'} style={{fontSize: 18}}>
                                        <span> <strong> Validando . . .</strong>  </span>
                                        <div className={'line'} style={{backgroundColor: "#173277"}}/>
                                    </div>
                        }
                    </Fade>
                </div>
            </Col>
            }
            {(!props.edit || props.enterprise) &&
            <Col xs={24} style={{padding: 30, paddingBottom: 0, transition: '400ms'}}>
                {(step === 'registerForm' || step === 'addBusiness') &&
                <div>
                    <Row gutter={16} style={{
                        borderBottom: "1px solid gainsboro",
                        paddingBottom: 20,
                        marginBottom: 20,
                        display: 'flex',
                        alignItems: 'stretch',
                        flexWrap: 'wrap'
                    }}>
                        <Col xs={24} lg={9} style={{
                            textAlign: 'center',
                            display: "flex",
                            flexFlow: "column",
                            justifyContent: "center"
                        }}>
                            <span style={{fontSize: 16, fontWeight: "bold"}}> Logo da empresa  </span>
                            <span style={{fontSize: 12, lineHeight: '12px', color: 'gray', marginBottom: 10}}> O logo da empresa aparecerá tanto na listagem de vagas como na página da vaga </span>
                            {imageApi.loading ?
                                <Icon type={'loading'} style={{fontSize: 100}}/>
                                :
                                <GenericCropImage googleFaceImage={imageVisible} image={imageVisible}
                                                  setCropedImg={(item) => setImageVisible(item)} height={360}
                                                  width={360}
                                                  circularCrop={true}/>
                            }
                        </Col>
                        <Col span={15}
                             style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                            <GenericInput
                                suffix={<Icon type="shop" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}
                                required={true} form={props.form} error={getFieldError('business_name')}
                                name='business_name' label='Nome da empresa' placeholder='Nome fantasia'
                                valueForMemo={getFieldsValue().business_name}/>
                            <Col xs={24} style={{padding: 0, marginTop: 10}} className={'genericInput'}>
                                {employerType === 'individual' ?
                                    <Form.Item required={true} className='hideDots'
                                               validateStatus={getFieldError('cpf') && 'error'}
                                               help={getFieldError('cpf')} label={<span style={{
                                        fontSize: '16px',
                                        color: 'rgba(0, 0, 0, 0.65)',
                                        fontWeight: 'bold'
                                    }}>CPF</span>}>
                                        {getFieldDecorator(`cpf`,
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: 'Campo Obrigatório',
                                                }],
                                                initialValue: props.enterprise ? props.enterprise?.cpf : user?.cpf,
                                            }
                                        )(
                                            <NumberFormat
                                                disabled={true}
                                                placeholder={'Informe o CPF do representante'}
                                                className='ant-input ant-input-lg'
                                                format={'###.###.###-##'} mask="_"/>
                                        )}
                                    </Form.Item>
                                    :
                                    <Form.Item required={true} className='hideDots'
                                               validateStatus={getFieldError('cnpj') && 'error'}
                                               help={getFieldError('cnpj')} label={<span style={{
                                        fontSize: '16px',
                                        color: 'rgba(0, 0, 0, 0.65)',
                                        fontWeight: 'bold'
                                    }}>CNPJ</span>}>
                                        {getFieldDecorator(`cnpj`,
                                            {
                                                rules: [{
                                                    required: true,
                                                    message: 'Campo Obrigatório',
                                                }],
                                                initialValue: props.enterprise ? props.enterprise?.cnpj : undefined
                                            }
                                        )(
                                            <NumberFormat
                                                disabled={!!props.edit}
                                                placeholder={'Informe o CNPJ da empresa'}
                                                className='ant-input ant-input-lg'
                                                format={'##.###.###/####-##'} mask="_"/>
                                        )}
                                    </Form.Item>
                                }
                                {(!props.edit && employerType === 'individual') ?
                                    <div style={{textAlign: "right", color: '#6b93b6'}}>
                                        <a onClick={() => setEmployerType('legal_entity')} style={{fontSize: 12}}> Meu
                                            estabelecimento possui CNPJ </a>
                                    </div>
                                    : (!props.edit &&
                                        <div style={{textAlign: "right", color: '#6b93b6'}}>
                                            <a onClick={() => setEmployerType('individual')} style={{fontSize: 12}}> Meu
                                                estabelecimento não possui CNPJ </a>
                                        </div>)
                                }

                            </Col>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} className={'genericInput'}>
                            <Form.Item required={true} className='hideDots'
                                       validateStatus={getFieldError('category_id') && 'error'}
                                       help={getFieldError('category_id')} label={<span
                                style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}>Ramo de atuação da empresa</span>}>
                                {getFieldDecorator('category_id', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Obrigatório',
                                        },
                                    ],
                                })(<Select
                                    mode="default"
                                    style={{width: '100%', marginTop: 5}}
                                    placeholder={"Selecione a categoria de seu estabelecimento"}
                                    allowClear={false}
                                    showSearch
                                    filterOption={(input, option) => option.props.children.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(input.toLowerCase()) >= 0}
                                    size={'large'}
                                    loading={establishmentCategories.length === 0}
                                    suffixIcon={<Icon type="down" className={'primary-sub-color'}
                                                      style={{fontSize: '17px'}}/>}
                                >
                                    {establishmentCategories.map((item, index) =>
                                        <Select.Option key={index}
                                                       value={item.category_id}>{item.description}</Select.Option>
                                    )}
                                </Select>)}
                            </Form.Item>
                        </Col>

                        <Col xs={24} className={'genericInput'}>

                            <Form.Item
                                required={true}
                                className='hideDots'
                                validateStatus={getFieldError('company_size') && 'error'}
                                help={getFieldError('company_size')}
                                label={<span
                                    style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold', width: '100%', marginTop: 5}}>Porte da empresa</span>}>
                                {getFieldDecorator('company_size', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Campo Obrigatório',
                                        },
                                    ],
                                })(<Select
                                    defaultValue=''
                                    style={{width: '100%'}}
                                    placeholder='Selecione o tamanho da empresa'
                                >
                                    <Option value="Microempreendedor Individual (MEI)">Microempreendedor Individual (MEI)</Option>
                                    <Option value="Microempresa (ME)">Microempresa (ME)</Option>
                                    <Option value="Empresa de Pequeno Porte (EPP)">Empresa de Pequeno Porte (EPP)</Option>
                                    <Option value="Empresa de Médio Porte">Empresa de Médio Porte</Option>
                                    <Option value="Grande Empresa">Grande Empresa</Option>
                                    <Option value="Demais">Demais</Option>
                                </Select>)}
                            </Form.Item>
                        </Col>

                        <Col xs={24} className={'formItem'}>
                            <div className='resumeLabel' style={{marginBottom: 10}}><strong
                                style={{fontSize: 16}}> Descrição da empresa </strong></div>
                            {getFieldDecorator('description',
                                {
                                    rules: [{
                                        required: false,
                                    }],
                                    initialValue: undefined,
                                }
                            )(
                                <TextArea style={{minHeight: 100}} maxLength={1000}
                                          placeholder={'Informe aqui detalhes de sua empresa que possa ser informativo aos candidatos'}
                                          size={'large'}/>
                            )}
                        </Col>

                        <Col xs={24} lg={24} style={{
                            textAlign: 'center',
                            display: "flex",
                            flexFlow: "column",
                            justifyContent: "center",
                            flexWrap: "wrap"
                        }}>
                            <span style={{fontSize: 16, fontWeight: "bold", marginBottom: 10}}> Banner da empresa (1200px x 300px) <Popover
                                content={'Um banner para ficar em destaque na página da sua empresa'}> <Icon
                                style={{color: "gray"}} type='question-circle'/> </Popover> </span>
                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: "wrap"}}>
                                {!enterpriseBanner &&
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                                    <img src={Images.img_upload} style={{maxWidth: 200}}/>
                                    <div style={{maxWidth: 200, textAlign: 'left'}}>
                                        <h3>Insira uma imagem</h3>
                                        <span> Adicione uma imagem para ser exibida na página de sua empresa.</span>
                                    </div>
                                </div>}
                                <div style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: "wrap"
                                }}>
                                    <GenericCropImage autoHeight={true} aspectRatio={4} banner={true}
                                                      googleFaceImage={enterpriseBanner} image={enterpriseBanner}
                                                      setCropedImg={(item) => setEnterpriseBanner(item)} height={300}
                                                      width={1200}/>
                                </div>
                            </div>
                        </Col>


                        <Col span={24}>
                            <GenericInput
                                suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}
                                required={true} form={props.form} error={getFieldError('employer_name')}
                                name='employer_name' label='Nome do empregador / Razão Social'
                                placeholder='Nome do empregador' valueForMemo={getFieldsValue().employer_name}/>
                        </Col>

                        <Col xs={24}>
                            <GenericInput rules={[isEmail(), required()]}
                                          suffix={<Icon type="mail" className={'primary-sub-color'}
                                                        style={{fontSize: '17px'}}/>} required={true} form={props.form}
                                          error={getFieldError('emails[0]')} name='emails[0]' label='Email empresarial'
                                          placeholder='Digite o Email empresarial'
                                          valueForMemo={getFieldsValue().emails}/>
                        </Col>

                        <Col xs={24} className={'genericInput'}>
                            <Form.Item required={true} className='hideDots'
                                       validateStatus={getFieldError('phone_numbers[0]') && 'error'}
                                       help={getFieldError('phone_numbers[0]')} label={<span
                                style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold'}}>Telefone empresarial</span>}>
                                {getFieldDecorator(`phone_numbers[0]`,
                                    {
                                        rules: [{
                                            required: true,
                                            message: 'Campo Obrigatório'
                                        }],
                                        initialValue: ''
                                    }
                                )(
                                    <NumberFormat
                                        type={"tel"}
                                        placeholder={'Informe o telefone empresarial'}
                                        className='ant-input ant-input-lg'
                                        format={'(##) ####-#####'} mask="_"/>
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={24} style={{display: "flex", alignItems: "center", marginBottom: 15, marginTop: 20}}>
                            <span style={{fontSize: 20, color: "#315dc3"}}> Endereço da empresa </span>
                            <div style={{height: 1, flex: 1, backgroundColor: "gainsboro", marginLeft: 10}}/>
                        </Col>
                        <Col xs={24}>

                            <Row>
                                <CepInput ignoreRestriction={true}
                                          checkCepInfoAvailability={(item) => checkCepInfoAvailability(item)}
                                          setAddressVisible={(e) => setAddressVisible(true)} required={true}
                                          form={props.form} name='address.postal_code' label='CEP'/>
                                <Col span={24} style={{
                                    marginTop: '-20px',
                                    fontSize: 12,
                                    color: '#6b93b6',
                                    textAlign: 'right',
                                    width: '100%'
                                }}>
                                    <a href={'http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm'}
                                       target={'_blank'}> Não sei meu CEP </a>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} style={{display: addressVisible ? "block" : "none"}}>
                                <Col xs={24} sm={12} md={24}>
                                    <GenericInput disabled={disableStreet.current} form={props.form}
                                                  error={getFieldError('address.street')} name='address.street'
                                                  label='Rua' placeholder='Digite a Rua'
                                                  valueForMemo={getFieldsValue().address?.street}/>
                                </Col>
                                <Col xs={24} sm={12} md={16}>
                                    <GenericInput disabled={disableDistrict.current} form={props.form}
                                                  error={getFieldError('address.district')} name='address.district'
                                                  label='Bairro' placeholder='Digite o Bairro'
                                                  valueForMemo={getFieldsValue().address?.district}/>
                                </Col>
                                <Col xs={24} sm={12} md={8}>
                                    <GenericInput required={true} form={props.form}
                                                  error={getFieldError('address.street_number')}
                                                  name='address.street_number' type='number' label='Nº' placeholder='Nº'
                                                  valueForMemo={getFieldsValue().address?.street_number}/>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} style={{display: addressVisible ? "block" : "none"}}>
                                <Col xs={24} sm={14} md={12}>
                                    <GenericInput disabled={true} form={props.form}
                                                  error={getFieldError('address.city')} name='address.city'
                                                  label='Cidade' placeholder='Cidade'
                                                  valueForMemo={getFieldsValue().address?.city}/>
                                </Col>
                                <Col xs={24} sm={16} md={12}>
                                    <GenericInput disabled={true} form={props.form}
                                                  error={getFieldError('address.state_abbrev')}
                                                  name='address.state_abbrev' label='Estado' placeholder='Estado'
                                                  valueForMemo={getFieldsValue().address?.state_abbrev}/>
                                </Col>
                                <Col span={24}>
                                    <GenericInput form={props.form} error={getFieldError('address.complement')}
                                                  name='address.complement' label='Complemento'
                                                  placeholder='Complemento adicional do endereço'
                                                  valueForMemo={getFieldsValue().address?.complement}/>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} style={{padding: 15, marginTop: 10}}>
                            <Button loading={loading} style={{width: "100%"}} className={"ant-btn-primary"}
                                    onClick={() => props.edit ? doRegister() : checkPhoneValidation()}> {props.edit ? "Atualizar Dados" : "Cadastrar"} </Button>
                        </Col>
                    </Row>
                </div>
                }
                {(!props.edit && (step === 'serproLoading' || loading)) ? <div>
                        <div style={{
                            fontSize: 20,
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            paddingTop: 20,
                            paddingBottom: 20
                        }}>
                            <div style={{textAlign: 'center'}}>
                                <p>Um momento, estamos validando os dados informados...</p>
                            </div>
                            <div className='serpro-loader'>
                                <div className='spinner'/>
                                <div className='icons'>
                                    <img width={100} src={Images.serpro} alt="serpro"/>
                                    <img width={80} src={Images.biometric} alt="biometric"/>
                                    <img width={70} src={Images.db} alt="data-base"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    step === 'errorSerpro' ? <div style={{minHeight: 350}}>
                            <div>
                                <div style={{textAlign: 'center'}}>
                                    <img style={{height: '100%', maxHeight: 320}} src={Images.serproError}/>
                                </div>
                                <span style={{fontSize: 16, marginTop: 15, paddingLeft: 15,}}> Resolva estas pendências para conseguir cadastrar uma empresa </span>
                                <div style={{
                                    borderRadius: 5,
                                    backgroundColor: '#fff4f4',
                                    padding: 10,
                                    paddingLeft: 15,
                                    margin: 15,
                                    display: 'flex',
                                    flexDirection: "column"
                                }}>
                                    {errorMessagesSerpro.current.map((item, index) => <div key={index} style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{height: 8, width: 8, borderRadius: 20, backgroundColor: '#ec1414',}}/>
                                        <span style={{color: '#ec1414', marginLeft: 8, flex: 1}}>{item}</span>
                                    </div>)}
                                </div>

                                <div style={{display: 'flex', justifyContent: 'end', margin: 10}}>
                                    <a target={'_blank'} rel="noreferrer"
                                       href={window.innerWidth < 768 ? 'https://wa.me/' + Env.number : 'https://web.whatsapp.com/send?phone=' + Env.number}>
                                        <p style={{color: '#3d8bfd', margin: 0, textAlign: "end", fontSize: 13}}>
                                            Precisa de ajuda? Entre em contato conosco!
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        : step === 'regEnd' && <div style={{minHeight: 350}}>
                        <div>
                            <div style={{textAlign: 'center', fontSize: 20}}>
                                <span style={{
                                    color: '#424242',
                                    fontSize: 22
                                }}> Gostaria de divulgar agora uma vaga? </span>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <img style={{height: '100%', maxHeight: 320}} src={Images.vacancy_img}/>
                            </div>
                            <div style={{padding: 20, display: "flex", justifyContent: 'center', flexWrap: 'wrap'}}>
                                <Button style={{marginRight: 20, borderColor: 'lightgray'}} onClick={() => {
                                    props.setVisible(false);
                                }}> Depois </Button>
                                <Button onClick={() => {
                                    history.push('/painel/cliente/vagas/' + props.enterprise.id + '/criar-vaga');
                                    props.setVisible(false);
                                }} className={'ant-btn-secondary'}> Divulgar vaga </Button>
                            </div>
                        </div>
                    </div>}
            </Col>}
        </Row>
    </div>)
};

export default Form.create({name: 'regEnterpriseForm'})(RegisterEnterpriseComponent);


