import React, { useEffect, useState} from 'react'
import {
    FaAngleDown, FaCalendar, FaFilter, FaList, FaSearchLocation, FaSortAmountDown, FaSuitcase
} from "react-icons/fa";
import {Select} from "antd";
import {Env} from "../../../Helpers/Env";
import {CityList} from "../../../Helpers/CityList";
import {BiCurrentLocation, FaDollarSign, FaGraduationCap, FaRedo} from "react-icons/all";
import useLocalStorage from "../../../Hooks/Storage";
import {StorageVariables} from "../../../Helpers/StoragesVariables";
import _ from "lodash";
import {CgClose} from "react-icons/cg";

const FilterSearchOpportunityComponent = ({getData, payload,user, clearPageController,clearQuery, pageController, search, getMyApplications}) => {
    const fullDataCurriculum = useLocalStorage(StorageVariables.updatedProfile);
    const saveFilterStorage = useLocalStorage(StorageVariables.filters_search_opportunity);
    const userLocation = fullDataCurriculum.getObject() && fullDataCurriculum.getObject()?.user_id !== null ?
        (fullDataCurriculum.getObject()?.profile?.address?.state_abbrev === Env.state_restriction ?
            {city:fullDataCurriculum.getObject()?.profile?.address?.city, state_abbrev:fullDataCurriculum.getObject()?.profile?.address?.state_abbrev } : undefined) : undefined

    const filters = {
        order_by: [{label:'Mais recentes', value:'newest'}, {label:'Mais próximos de mim', value: 'distance'}],
        city: CityList.map((item) => ({label:item, value: item})),
        disabilities:[{label:'Todas', value: null, show: true}, {label:'Enviei meu currículo', value:'applications', show: user?.user_id && true }, {label:'Não enviei meu currículo', value: false, show: user?.user_id && true}, {label:'Para PcD', value:'Alguma', show: true}],
        job_type: [{label:'Tempo integral', value:'Tempo integral'},{label:'Meio período', value:'Meio período'},{label:'Estágio', value:'Estágio'},{label:'Freelance', value:'Freelance'},{label:'Temporário', value:'Temporário'},{label:'Aprendiz', value:'Aprendiz'},],
        pay: [{label:'1.000,00', value:"1000"},{label:'2.000,00', value:"2000"},{label:'3.000,00', value:"3000"},{label:'4.000,00', value:"4000"},],
        pay_period: [{label:'Mensal', value:'Mês'},{label:'Quinzenal', value:'Quinzena'},{label:'Diária', value:'Dia'},{label:'Hora', value:'Hora'},{label:'Por produção', value:'Por Produção'},{label:'Por tarefa', value:'Por Tarefa'},],
        schooling: [{label:'Analfabeto', value:'Analfabeto'},{label:'Fundamental incompleto', value:'Fundamental Incompleto'},{label:'Fundamental completo', value:'Fundamental Completo'},{label:'Médio incompleto', value:'Médio Incompleto'},{label:'Médio completo', value:'Médio Completo'},{label:'Superior incompleto', value:'Superior Incompleto'},{label:'Superior completo', value:'Superior Completo'},{label:'Mestrado', value:'Mestrado'},{label:'Doutorado', value:'Doutorado'},],
        work_place: [{label:'Remoto', value:'mobile'},{label:'Presencial', value:'same'},]
    }

    const defaultFilters = {
        order_by: 'newest',
        application: Env.application_alias,
        city: userLocation?.city,
        state_abbrev: userLocation?.state_abbrev
    }

    const defaultBadges = {
        order_by: filters.order_by[0],
        city: {label:userLocation?.city, value: userLocation?.city} ,
        disabilities: filters.disabilities[0],
    }

    const [filtersData, setFiltersData] = useState(defaultBadges)

    const handleSelectFilter = (filter, items) => {
        let updateFilter = JSON.parse(JSON.stringify(filtersData))

        const newValueInFilter = (key, isArray) => {
            if (key) {
                updateFilter = {...updateFilter, [filter]:items, ...key}
                return updateFilter
            } else {
                updateFilter = {...updateFilter, [filter]: isArray ? [items] : items}

                return updateFilter
            }
        }

        if (Object.keys(updateFilter).length === 1) {
            updateFilter = defaultBadges
        }

        if (filter === 'order_by') {
            if (updateFilter.order_by) {
                if (_.isEqual(items?.value, filtersData?.order_by?.value)) {
                    newValueInFilter({order_by:filters.order_by[0]})
                } else {
                    newValueInFilter()
                }
            } else {
                newValueInFilter()
            }

        }else if (filter === 'disabilities') {
            if (items.value === 'applications') {
                updateFilter = {disabilities:items}
                if (search.length > 0) clearQuery()
            } else {
                if (updateFilter.disabilities) {
                    if (_.isEqual(items?.value, filtersData?.disabilities?.value)) {
                        newValueInFilter({disabilities: filters?.disabilities[0]})
                    } else {
                        newValueInFilter()
                    }
                }
            }


        } else if (filter === 'pay') {
            if (updateFilter.pay) {
                if (_.isEqual(items.value, filtersData?.pay?.value)) {
                    delete updateFilter?.pay
                } else {
                    newValueInFilter()
                }
            } else {
                newValueInFilter()
            }

        }else if (filter === 'pay_period') {
            if (updateFilter.pay_period) {
                if (_.isEqual(items.value, filtersData?.pay_period?.value)) {
                    delete updateFilter?.pay_period
                } else {
                    newValueInFilter()
                }
            } else {
                newValueInFilter()
            }

        } else if (filter === 'job_type') {
            if (updateFilter.job_type) {
                if (_.isEqual(items.value, filtersData?.job_type.value)) {
                    delete updateFilter?.job_type
                } else {
                    newValueInFilter()
                }
            } else {
                newValueInFilter()
            }

        } else if (filter === 'schooling') {

            if (updateFilter.schooling && updateFilter.schooling.length > 0) {
                if (updateFilter.schooling.some(option => option.value === items.value)) {
                    // A opção já existe no array, então é removida
                    updateFilter.schooling.splice(updateFilter.schooling.findIndex(option => option.value === items.value), 1);
                } else {
                    // A opção não existe no array, então é adicionada
                    updateFilter.schooling.push(items);
                }

            } else {
                newValueInFilter(false, true)
            }

        } else if (filter === 'work_place') {
            if (updateFilter.work_place) {
                if (_.isEqual(items.value, filtersData.work_place.value)) {
                    delete updateFilter.work_place
                } else {
                    newValueInFilter()
                }
            } else {
                if (items.value === 'mobile') {
                    newValueInFilter({city: {label: undefined, value: undefined}})
                } else {
                    newValueInFilter({city:{label: userLocation?.city, value: userLocation?.city}})
                }
            }

        } else {
            newValueInFilter()
        }
        setFiltersData(updateFilter)
        sendApi(updateFilter)
    }

    const sendApi = (obj) => {
        saveFilterStorage.setObject(obj)
        if (obj?.disabilities?.value === 'applications') {
            getMyApplications()
            payload.current = defaultFilters
            window.scrollToElement('body')
        } else {
            let aux = {
                ...payload.current,
                ...defaultFilters,
                order_by: (obj?.order_by?.value || "newest"),
                is_candidate: obj?.disabilities?.value === false ? false : null,
                city: obj?.city?.value,
                state_abbrev: obj?.city?.value ? userLocation ? userLocation?.state_abbrev : Env.state_restriction : undefined,
                disabilities: obj?.disabilities?.value === 'Alguma' ? 'Alguma' : null,
                job_type: obj?.job_type?.value,
                pay: obj?.pay?.value || undefined,
                pay_period: obj?.pay_period?.value || undefined,
                schooling: obj?.schooling ? obj?.schooling.length > 0 ? obj?.schooling.map((item) => item.value) : undefined : undefined,
                work_place: obj?.work_place?.value || undefined,
                query: payload.current?.query ? payload.current?.query : obj?.query ? obj?.query : undefined
            }

            if(Object.keys(pageController.current).length > 0) {
                window.scrollToElement('body')
                clearPageController()
            }
            payload.current = aux
            getData()
        }
    }

    const resetFilter = () => {
        setFiltersData(defaultBadges)
        saveFilterStorage.remove()
        if (payload.current.page) delete payload.current.page

        if (pageController.current.is_my_applications) {
            clearQuery()
            window.scrollToElement('body')
            payload.current = defaultFilters
            getData()
        } else {
            if (!_.isEqual(payload.current, defaultFilters)) {
                clearQuery()
                window.scrollToElement('body')
                payload.current = defaultFilters
                getData()
            }
        }
    }

    const handleRemoveSingleFilter = (label) => {
        let updateFilter = JSON.parse(JSON.stringify(filtersData))
        if (Object.keys(updateFilter).length === 1) {
            updateFilter = defaultBadges
        }

        if (label === 'order_by') {
            updateFilter = {...updateFilter, order_by: filters.order_by[0]}

        } else if (label === 'city') {
            updateFilter = {...updateFilter, city: {label: undefined, value: undefined}}

        } else if (label === 'disabilities') {
            updateFilter = {...updateFilter, disabilities: filters.disabilities[0]}

        } else if (label === 'query') {
            clearQuery()

        } else {
            if (label === 'schooling') {
                updateFilter.schooling = []
            } else {
                delete updateFilter[label]
            }
        }
        setFiltersData(updateFilter)
        sendApi(updateFilter)
    }

    const defaultFiltersQueryString = () => {
        let qs = window.queryStringToObject()
        let aux = {}

        for (const [key, value] of Object.entries(qs)) {
            if (_.isArray(qs[key])) {
                qs[key].forEach((item) => {
                    if (filters[key].find(find => find.value === item)) {
                        if (aux[key]?.length > 0) {
                            aux[key].push(filters[key].find(find => find.value === item))
                        } else {
                            aux[key] = [filters[key].find(find => find.value === item)]
                        }
                    }
                })

            } else {
                if (filters[key]?.length > 0) {
                    aux[key] = filters[key].find(item => item.value === value)
                }
            }

        }
        setFiltersData({...filtersData, ...aux})
        return aux
    }

    const badgeFilterApplied = (label, text) => {
        const isClearable = () => {
            if (label === 'city') {
                if (filtersData?.city?.label === undefined) {
                    return <></>
                } else {
                    return (
                        <i onClick={() => handleRemoveSingleFilter(label)}>
                            <CgClose/>
                        </i>
                    )
                }
            } else if (label === 'schooling') {
                return (
                    <i onClick={() => handleRemoveSingleFilter(label)}>
                        <CgClose/>
                    </i>
                )
            } else {
                if (filtersData[label]?.label === defaultBadges[label]?.label) {
                    return <></>
                } else {
                    return (
                        <i onClick={() => handleRemoveSingleFilter(label)}>
                            <CgClose/>
                        </i>
                    )
                }
            }
        }
        return (
            <span>
                {label === 'pay' && 'R$ '}{text} {label === 'schooling' && filtersData?.schooling.length >= 2 && <>+{filtersData?.schooling.length}</>}
                {isClearable()}
            </span>
        )

    }

    useEffect(() => {
        if (window.location.search) {
            sendApi(defaultFiltersQueryString())
        } else {
            if (_.isObject(saveFilterStorage.getObject())) {
                if (Object.keys(saveFilterStorage.getObject()).length > 0 ) {
                    setFiltersData({...filtersData, ...saveFilterStorage.getObject()})
                    sendApi({...filtersData, ...saveFilterStorage.getObject()})
                } else {
                    sendApi()
                }
            } else {
                payload.current = defaultFilters
                getData()
            }
        }

    },[])

    useEffect(() => {
        if (search?.length > 0 && Object.keys(filtersData).length === 1) {
            setFiltersData(defaultBadges)
        }
    },[payload.current?.query])


    return (
        <div className='search-opportunity-filter'>
            <div className='filter-content'>
                <div className='title'>
                    <i><FaFilter/></i>
                    <h3>Filtros</h3>
                </div>
                <div style={{borderBottom:'1px solid #d1d9e0'}}>
                    <div className='filters-applied'>
                        <div className='filters-applied-title'>
                            <strong>
                                Filtros aplicados</strong>
                            <button onClick={resetFilter}>
                                Redefinir filtros
                                <i><FaRedo size={14}/></i>
                            </button>
                        </div>
                        {payload.current?.event &&
                            <p className={'event'}>
                                Mês das mulheres
                            </p>
                        }

                        <div className='filters-applied-content'>
                            {payload.current.query &&
                                <span style={{background:'#212529', color:'white'}}>
                                    {search}
                                    <i onClick={() => handleRemoveSingleFilter('query')}>
                                        <CgClose/>
                                    </i>
                                </span>

                            }

                            {Object?.keys(filtersData)?.map((label) => (
                               label === 'city' ? badgeFilterApplied(label, filtersData[label]?.label ? filtersData[label]?.label  : Env.state_full)
                                   : label === 'schooling' ?  filtersData[label].map((item, index) => index === 0 && badgeFilterApplied(label, item.label))
                                       : badgeFilterApplied(label, filtersData[label]?.label)

                            ))}
                        </div>
                    </div>
                </div>

                <div className='filters-options'>
                    <div className='details'>
                        <div className='filter-type-text'>
                            <i><FaSortAmountDown/></i>
                            <h4>
                                Ordenar por
                            </h4>
                        </div>

                        <section className='options'>
                            {filters.order_by.map((item) =>
                                <span className={`${filtersData?.order_by?.value === item.value ? 'active' : ''}`}
                                      onClick={() => handleSelectFilter('order_by', item)}>
                                    {item.label}
                                </span>
                            )}
                        </section>
                    </div>

                    <div className='details'>
                        <div className='filter-type-text'>
                            <i><FaSearchLocation/></i>
                            <h4>
                                Cidade
                            </h4>
                        </div>

                        <section>
                            <Select style={{width: "100%"}}
                                    showSearch
                                    suffixIcon={<i><FaAngleDown/></i>}
                                    dropdownClassName={'ccv'}
                                    notFoundContent={<div className='text-center'>Nenhum resultado</div>}
                                    value={filtersData?.city?.label ? filtersData?.city?.label : Env.state_full}
                                    filterOption={(input, option) => option.props.children.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(input.toLowerCase()) >= 0}
                                    defaultValue={filtersData?.city?.value ? filtersData?.city?.value : Env.state_full}
                            >
                                {filters.city.map((item, index) =>
                                    <Select.Option key={index} value={item.value} onClick={() => handleSelectFilter('city', item)}>
                                        {item.label || Env.state_full}
                                    </Select.Option>
                                )}
                            </Select>
                        </section>


                    </div>

                    <div className='details'>
                        <div className='filter-type-text'>
                            <i><FaList/></i>
                            <h4>
                                Mostrar vagas
                            </h4>
                        </div>

                        <section className='options'>
                            {filters.disabilities.map((item) =>
                                item.show &&
                                <span className={`${filtersData?.disabilities?.value === item.value ? 'active' : ''}`}
                                      onClick={() => handleSelectFilter('disabilities', item)}>
                                    {item.label}
                                </span>
                            )}
                        </section>
                    </div>

                    <div className='details'>
                        <div className='filter-type-text'>
                            <i><FaSuitcase/></i>
                            <h4>
                                Tipo de vaga
                            </h4>
                        </div>

                        <section className='options'>
                            {filters.job_type.map((item) =>
                                <span className={`${filtersData?.job_type?.value === item.value ? 'active' : ''}`}
                                      onClick={() => handleSelectFilter('job_type', item)}
                                >
                                    {item.label}
                                </span>
                            )}
                        </section>
                    </div>

                    <div className='details'>
                        <div className='filter-type-text'>
                            <i><FaDollarSign/></i>
                            <h4>
                                Salário a partir de
                            </h4>
                        </div>

                        <section className='options'>
                            {filters.pay.map((item) =>
                                <span className={`${filtersData?.pay?.value === item.value ? 'active' : ''}`}
                                      onClick={() => handleSelectFilter('pay', item)}>
                                    R$ {item.label}
                                </span>
                            )}
                        </section>
                    </div>

                    <div className='details'>
                        <div className='filter-type-text'>
                            <i><FaCalendar/></i>
                            <h4>
                                Tipo de salário
                            </h4>
                        </div>

                        <section className='options'>
                            {filters.pay_period.map((item) =>
                                <span className={`${filtersData?.pay_period?.value === item.value ? 'active' : ''}`}
                                      onClick={() => handleSelectFilter('pay_period', item)}>
                                    {item.label}
                                </span>
                            )}
                        </section>
                    </div>

                    <div className='details'>
                        <div className='filter-type-text'>
                            <i><FaGraduationCap/></i>
                            <h4>
                                Escolaridade
                            </h4>
                        </div>

                        <section className='options'>
                            {filters.schooling.map((item) =>
                                <span className={`${(filtersData?.schooling && filtersData?.schooling.length > 0) ? filtersData?.schooling.map(el => el.value).includes(item.value) ? 'active' : '':''}`}
                                      onClick={() => handleSelectFilter('schooling', item)}>
                                    {item.label}
                                </span>
                            )}
                        </section>
                    </div>

                    <div className='details'>
                        <div className='filter-type-text'>
                            <i><BiCurrentLocation/></i>
                            <h4>
                                Local da vaga
                            </h4>
                        </div>

                        <section className='options'>
                            {filters.work_place.map((item) =>
                                <span className={`${filtersData?.work_place?.value === item.value ? 'active' : ''}`}
                                      onClick={() => handleSelectFilter('work_place', item)}>
                                    {item.label}
                                </span>
                            )}
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterSearchOpportunityComponent
