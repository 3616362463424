import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Icon, Input, message, Row, Select, Spin} from "antd";
import {useSelector} from "react-redux";
import useApi from "../../../../../Hooks/Api";
import {FaBackward, FaEdit, FaForward, FaPlus, FaTrashAlt, FaUniversity} from "react-icons/fa";
import TextArea from "antd/lib/input/TextArea";

const AboutMeComponent = (props) => {
    const user = useSelector((state) => state).authReducer;

    const [about_me, setAboutMe] = useState('');
    const [loading, setLoading] = useState(true);
    const api = useApi({initialLoading: false});
    const objToSend = useRef(null);

    const getData = async () => {
        setLoading(true);
        api.get(`sine/user/${user.user_id}/full`, (res) => {
            objToSend.current = res.profile;
            setAboutMe(res.profile.about_me || "")
            setLoading(false);
        }, (e) => {
            setLoading(false);
            console.log(e);
        });
    };


    const handleSave = async () => {

        objToSend.current.about_me = about_me;
        setLoading(true);

        api.post(`sine/user/${user.user_id}/profile`, objToSend.current, async (res) => {
            setLoading(false);
            message.success('Informação salva no currículo!');
        }, (e) => {
            console.log(e);
            message.warning(e.message || 'Não foi possível salvar seus dados tente novamente.');
            setLoading(false);
        });
    }


    useEffect(() => {
        getData();
        window.scrollToElement('body')

    },[]);

    return (<div style={{marginBottom: 200}} className={'experienceForm aboutCss'}>

        <div style={{ paddingBottom: 35, borderBottom: '1px solid gainsboro',textAlign: 'center', marginTop: 50 }}>
            <h1>
                <strong style={{color: '#242424', fontSize: 25}}> Sobre mim </strong>
            </h1>
            <span  style={{ fontSize: 20,color: '#9E9E9E'}}> Escreva um pouco mais sobre você e suas experiências, se achar necessário </span> <br/>
        </div>


        <Row  gutter={[24,24]} style={{marginBottom: 30, backgroundColor: 'white'}} className={'formRow'}>
            <Col xs={24} md={24} className={'formItem'}>
                <TextArea style={{minHeight: 200}}
                          value={about_me}
                          onChange={(e) => setAboutMe(e.target.value)}
                          placeholder={'Escreva aqui o que considerar necessário para completar seu currículo'} size={'large'}
                />

                <div style={{display: "flex", justifyContent: about_me.length > 1000 ? "space-between" : "end", marginTop:10}}>
                    {about_me.length > 1000 && <span style={{color:'red'}}>O limite são de 1000 caracteres.</span>}
                    <div><span style={{color:about_me.length > 1000 && 'red'}}>{about_me.length}</span> / (1000)</div>
                </div>

            </Col>

            <Col className={'listButtons'} span={24}>
                 <Button loading={loading} style={{color:'white'}} disabled={loading || about_me.length > 1000} className={`ant-btn-secondary ${about_me.length > 1000 ? 'opacity-loading' : ''} `} onClick={() => handleSave()}> Salvar  </Button>
            </Col>
        </Row>

        <div className={'buttonRow'} justify={'center'}>
            <Button shape={'round'} onClick={() => props.handleChangeStep(5)} type={'default'}> <FaBackward className={'exportedIconsLeft'}/> Voltar  </Button>
            <Button shape={'round'} onClick={() => props.handleChangeStep(7)} className={'ant-btn-sub-primary'}> Próximo <FaForward className={'exportedIcons'}/> </Button>
        </div>

    </div>)
};
export default Form.create({name: 'aboutMeForm'})(AboutMeComponent);
