import React from 'react'
import NumberFormat from "react-number-format";

const SyDecimal = ({data, setValue, value}) => {
    let str = value || ""
    str = str.replace(".", ",")

    return (
        <div>
            <div>
                <NumberFormat className={'ant-input ant-input-lg'}
                              id={data.label_key}
                              maxLength={15}
                              decimalSeparator=','
                              value={value !== undefined && str}
                              onValueChange={(e) => setValue(e.value)}
                />
            </div>

        </div>
    )

}

export default SyDecimal
