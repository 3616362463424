import React, {useRef, useState, useEffect} from "react";

import useApi from "../Hooks/Api";
import {Env} from "../Helpers/Env";
import {Fade} from "react-reveal";
import {Images} from "../Helpers/Images";
import {useSelector} from "react-redux";
import {globalMaskReal} from "../Helpers/Functions";
import { Col, Dropdown, Icon, Menu, Row, Select} from "antd";
import {CityList} from "../Helpers/CityList";
import Loading from "../Components/Generic/Loading";
import moment  from "moment";
import WorkerCourseDrawer from "../Components/PageComponents/Restrict/WorkerPanelPage/WorkerCourseDrawer";
import {useHistory} from "react-router-dom";
import InfiniteScroll from "./Components/InfiniteScroll";
import useLocalStorage from "../Hooks/Storage";
import {StorageVariables} from "../Helpers/StoragesVariables";
import BannerEvent from "./SearchOpportunity/Components/BannerEvent";

export function SearchCoursesPage ({navigation, route}){
    const [loading, setLoading] = useState(true);
    const [loadingCourse, setLoadingCourse] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false)
    const api = useApi({navigation});
    const courses = useRef([]);
    const [showDetails, setShowDetails] = useState(false)
    const qtdOp = useRef(undefined);
    const qtdTotal = useRef(undefined);
    const activeCourse = useRef(null);
    const fullDataCurriculum = useLocalStorage(StorageVariables.updatedProfile);
    const paginate = useRef({page: 1, application_alias: Env.application_alias, application: Env.application_alias});
    const selectedCity = useRef(fullDataCurriculum.getObject()?.user_id !== null ? fullDataCurriculum.getObject()?.profile?.address?.city : undefined);
    const paginateMyApplications = useRef({page: 0,order: "desc", status: ['interested', 'waiting', 'confirmed']});
    const module = useRef('all');
    const user = useSelector((state) => state).authReducer;
    const [queryObj, setQueryObj] = useState(window.queryStringToObject())
    const history = useHistory();

    //AUTOCOMPLETE
    const [coursesCategoriesFiltered, setCoursesCategoriesFiltered] = useState([]);
    const coursesCategories = useRef([]);

    const getMyApplicationsData = async (more) => {
        setLoading(true);

        if(!more) {
            paginateMyApplications.current.page = 0;
            courses.current = [];
        }
        paginateMyApplications.current.page++;

        api.post(`courses/student-courses`, paginateMyApplications.current, (res) => {

            courses.current = courses.current.concat(res.courses);
            paginateMyApplications.current.nextPage = res.next_page;
            paginateMyApplications.current.page = res.page;
            qtdOp.current = res.entries;
            setLoading(false);
        }, (e) => {
            setLoading(false);
            console.log(e);
        });
    };

    const getData = async (more) => {
        more ? setLoadingMore(true) : setLoading(true);
        if(selectedCity.current) {
            paginate.current.city = selectedCity.current;
            paginate.current.state_abbrev = fullDataCurriculum.getObject()?.profile?.address?.state_abbrev || Env.state_restriction;
        } else {
            delete paginate.current.city;
            delete paginate.current.state_abbrev;
        }

        if(queryObj.category_id) {
            paginate.current.category_id = queryObj.category_id
        } else {
            delete paginate.current.category_id;
        }

        //if (window.location.pathname === '/cursos-do-evento') paginate.current.event = Env.event_name


        api.post(`courses/search`, paginate.current, (res) => {
            let aux;
            if (more) {
                aux = courses.current.concat(res.courses);
            } else {
                aux = res.courses;
            }
            courses.current = aux
            paginate.current.nextPage = res.next_page;
            qtdOp.current = res?.total_count;
            qtdTotal.current = res?.total_courses_count;

            more ? setLoadingMore(false) : setLoading(false);
        }, (e) => {
            console.log(e);
        });
    };

    const infiniteScroll = () => {
        if (paginate.current.nextPage === true) {
            paginate.current.page++
            getData(true)
        }
    }

    const handleChange = (e) => {
        queryObj.category_id = e
        const urlParams = new URLSearchParams(queryObj);
        if (e) {
            history.push(`${window.location.pathname}?${urlParams}`);
        } else {
            history.push(`${window.location.pathname}`);
        }
        getData();

    };

    const getCourse = async (item,index, id) => {
        activeCourse.current = null;
        setShowDetails(true);
        setLoadingCourse(true);
        api.get(`courses/course/${id}`, (res) => {
            activeCourse.current = res;
            activeCourse.current.subscription_status = item?.subscription_status;
            activeCourse.current.id = id
            activeCourse.current.index = index;
            setLoadingCourse(false);
        }, (e) => {
            console.log(e)
            setLoadingCourse(false);
            setShowDetails(false);
        });
    };

    const openCourse = (a,b,c) => {
        queryObj.category_id === undefined && delete queryObj.category_id
        queryObj.id = a;
        const urlParams = new URLSearchParams(queryObj);
        history.push(`${window.location.pathname}?${urlParams}`);
        getCourse(b,c,a);
    }

    const closeCourse = () => {
        if(queryObj.id) {
            delete queryObj.id
        }

        if(queryObj) {
            const urlParams = new URLSearchParams(queryObj);
            history.push(`${window.location.pathname}?${urlParams}`);
        } else {
            history.push(`${window.location.pathname}`);
        }

        setShowDetails(false);
    };

    const removeFromList = () => {
        //courses.current.splice(activeCourse.current.index, 1);
        module.current === 'all' ? getData() : getMyApplicationsData();
    };


    const getCategories = () => {
        api.get('courses/categories', (res) => {
            let auxList = [];
            res.forEach((item) =>
                auxList.push({value: item.id, label: item.name})
            );
            coursesCategories.current = auxList;
            setCoursesCategoriesFiltered(auxList);
        }, (err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getCategories();
        if (queryObj.category_id) {
            handleChange(Number(queryObj.category_id));
        } else {
            getData();
        }

        if(queryObj?.id) {
            openCourse(queryObj?.id, undefined, false)
        }
    },[]);

    return (
        <>

            {(window.location.pathname === '/cursos-do-evento' && window.innerWidth < 768) &&
                <div style={{margin:'0'}}>
                    <BannerEvent style={{borderRadius:0, cursor:'pointer', objectFit:"contain"}}/>
                </div>
            }

            <div style={styles.container}>
                <WorkerCourseDrawer setVisible={() => closeCourse()} removeFromList={(e) => removeFromList(e)} visible={showDetails}  loading={loadingCourse} course={activeCourse.current}/>
                <Row style={{display: 'flex', marginTop: 35, justifyContent: 'center', backgroundSize: '100%', backgroundRepeat: 'repeat-y', background: `url(${Images.background_4})`}}>
                    <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17}>
                        <div style={{padding: 10}}>
                            <div style={{flexDirection: "row", justifyContent: 'space-between', marginBottom: 10}}>
                                {loading ? <span style={{fontSize: 20, color: "black"}}> Buscando... </span> : <span style={{fontSize: 20, color: "black"}}>Mostrando {qtdOp.current} publicacações {qtdTotal.current && <> <br/> <small style={{color: 'gray'}}> de um total de <strong>{qtdTotal.current}</strong> cursos</small> </>} </span>}
                            </div>

                            <div style={{marginBottom: 20}}>
                                <div style={{width: '100%', position: 'relative'}}>
                                    <Select
                                        mode={'default'}
                                        defaultValue={queryObj.category_id ? Number(queryObj.category_id) : undefined}
                                        style={{width: '100%', border: 5}}
                                        onChange={(value) => {handleChange(value)}}
                                        placeholder="Todas as categorias">
                                        <Select.Option value={undefined}> Todas as categorias </Select.Option>
                                        {coursesCategoriesFiltered?.map((item, index) =>
                                            <Select.Option value={item.value} key={index}>  {item.label}</Select.Option>
                                        )}
                                    </Select>
                                </div>
                                <div style={{display: 'flex', marginTop: 15}}>
                                    <Select showSearch
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(input.toLowerCase()) >= 0}
                                            style={{minWidth: 150, marginRight: 10}}
                                            className={'filter-select-search'}
                                            defaultValue={selectedCity.current ? selectedCity.current : Env.state_full}>
                                        {CityList.map((item, index) =>
                                            <Select.Option key={index} onClick={() => { selectedCity.current = item; getData();}}>
                                                {item || Env.state_full}
                                            </Select.Option>
                                        )}
                                    </Select>

                                    {user?.user_id &&<Dropdown overlay={<Menu>
                                        <Menu.Item>
                                            <a rel="noopener noreferrer" onClick={() => {module.current = 'all'; getData();}}>
                                                Cursos que não sou inscrito
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <a rel="noopener noreferrer" onClick={() => {module.current = 'applications'; getMyApplicationsData();}}>
                                                Cursos que sou inscrito
                                            </a>
                                        </Menu.Item>
                                    </Menu>}>
                                        <div style={{padding: 5, paddingRight: 10, paddingLeft: 10, cursor: 'pointer', marginRight: 5, backgroundColor: '#f5f5f5', borderRadius: 20, display: "flex", alignItems: 'center'}}>
                                    <span style={{fontSize: 12, marginLeft: 2, marginRight:2}}>
                                         {module.current === 'all' ? 'Cursos que não sou inscrito' : 'Cursos que sou inscrito'}
                                    </span>
                                            <Icon style={{color: '#9e9e9e', fontSize: 15}} type={'caret-down'}/>
                                        </div>
                                    </Dropdown>}
                                </div>
                            </div>

                            {loading &&<div style={{minHeight: 600}}>
                                <Loading/>
                            </div>}

                            {(courses.current.length === 0 && !loading) &&
                                <Fade>
                                    <div className={'notFoundComponent'} style={{marginTop: 30}}>
                                        <div style={{marginBottom: 0}}>
                                            <span style={{fontSize: 25}}>Nenhum resultado </span> <br/>
                                            <span style={{fontSize: 22}}>{module.current === 'all'? 'No momento não existem cursos abertos para esta busca': 'Você não se inscreveu em nenhum curso.'} </span>
                                        </div>
                                        <div className={'imgDiv'}>
                                            <img src={Images.resume_not_found}/>
                                        </div>
                                    </div>
                                </Fade>}

                            <Row style={{minHeight: 600, display: 'flex', flexWrap: 'wrap'}}>
                                {courses.current.map((item, index) =>
                                    <Col xs={24} lg={12} xl={8} key={index} onClick={() => openCourse(item.course_id, item, index)} style={{ padding: 10,}}>
                                        <div className={'vacancyHover'} style={{backgroundColor: 'white', cursor: 'pointer', overflow: 'hidden', display: "flex", flexDirection: 'column', justifyContent: 'space-between', borderRadius: 5, position: 'relative', paddingBottom: 5, border: '1px solid #e9e9e999' }}>
                                            {(item.subscription_status && item.subscription_status !== 'unsub' && item.subscription_status !== 'canceled') && <img style={{position: 'absolute', right: 10, opacity: 0.1, bottom: 10, width: 80, height: 80}} src={Images.checked}/>}

                                            {item.image&&<div style={{alignItems: 'center', width: '100%'}}>
                                                <img style={{width: '100%', height: 200, objectFit: 'contain', borderTopLeftRadius: 5, borderTopRightRadius: 5}} src={item.image}/>
                                            </div>}
                                            <div style={{padding: 10, paddingTop: 5}}>
                                                <div style={{marginBottom: 5, display: 'flex', flexFlow: 'column'}}>
                                                    <span style={{fontSize: 16, fontWeight: 'bold'}}>{item.title}</span>
                                                    <span style={{fontSize: 13}}>{item.category_name || 'Categoria do curso'}</span>
                                                </div>
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    {item.fee?
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <span style={{color: 'grey', fontWeight: "bold", marginRight: 2}}>R$</span>
                                                            <span style={{color: 'grey'}}> {globalMaskReal(item.fee)}</span>
                                                        </div>
                                                        :
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <span style={{fontWeight: 'bold',marginRight: 2, color: 'green'}}>$$</span>
                                                            <span style={{color: 'green'}}>Grátis</span>
                                                        </div>
                                                    }
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <Icon style={{marginRight: 2, marginBottom: 1}} type={'environment'}/>
                                                        <span style={{color: 'grey'}}>{item.course_type === 'online'? 'Online' : 'Presencial'} </span>
                                                    </div>

                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <Icon style={{marginRight: 2, marginBottom: 1}} type={'clock-circle'}/>
                                                        <span style={{color: 'grey'}}>{item.hours_total || '10'} horas </span>
                                                    </div>
                                                </div>
                                                {item.start_at !== '2000-01-01' &&
                                                    <div style={{display: 'flex', marginTop: 5, width: '100%', justifyContent: 'space-between'}}>
                                                        {((!item.subscription_limit_date || moment(item.subscription_limit_date).isAfter(moment())) && !item.closed) ? <div style={{display: 'flex', alignItems: 'center'}}>
                                                                <Icon theme={"filled"} style={{marginRight: 2, marginBottom: 1}} type={'calendar'}/>
                                                                <span style={{color: 'grey'}}>Inscrições até {item.subscription_limit_date ? moment(item.subscription_limit_date).format("DD/MM/YYYY") : 'Sem limite'}</span>
                                                            </div>
                                                            :
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                <Icon theme={"filled"} style={{marginRight: 2, color: '#c33434', marginBottom: 1}} type={'calendar'}/>
                                                                <span style={{color: '#c33434'}}>Inscrições Encerradas</span>
                                                            </div>
                                                        }
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <Icon style={{marginRight: 2, marginBottom: 1}}  type={'calendar'}/>
                                                            <span style={{color: 'grey'}}>Previsão de início {moment(item.start_at).format('DD/MM/YY')} </span>
                                                        </div>
                                                    </div>}
                                            </div>
                                            {(item.subscription_status && item.subscription_status !== 'unsub' && item.subscription_status !== 'canceled') &&<div style={{flexDirection: 'row', marginLeft: 10, alignItems: 'center', flex: 1, }}>
                                                <Icon color={'grey'} size={15}  name={'check-all'}/>
                                                <span  style={{fontSize: 14, fontWeight: 'bold', color: '#9cbd7a', flex: 1,}}>INSCRIÇÃO REALIZADA </span>
                                            </div>}
                                            {item.description &&<div style={{borderTop: '1px solid #e9e9e999', width: '100%', marginTop: 5, padding: 10, paddingTop: 5}}>
                                                <span style={{color: '#808080c7', fontSize: 13,}} >{item.description?.length > 200? item.description?.slice(0, 200) + ' ... ' : item.description} </span>
                                            </div>}
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </div>
                        {!loading && (
                            <div>
                                {loadingMore && <Loading/>}
                                <InfiniteScroll paginate={paginate.current} fetchMore={() => !loadingMore && infiniteScroll()}/>
                            </div>
                        )}
                        {(paginate.current.nextPage === false && !loading) && (
                            <div style={{display: "flex", justifyContent:"center"}}>
                                <div style={{fontSize:20}}>
                                    <span>Não há mais resultados de busca.</span>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    campaignImg: {
        width: 100,
        height: 90,
        borderRadius: 4,
        marginRight: 10
    },
    itemList: {
        marginVertical: 10,
        elevation: 2,
        padding: 10,
        paddingBottom: 5,
        backgroundColor: 'white',
        alignItems: "center",
        borderRadius: 5,
    },
    listTitle: {
        flex: 1,
        fontSize: 16,
        marginBottom: 3
    },
    listText: {
        fontSize: 13,
        color: 'grey',
        flex: 1,
        maxHeight: 86
    },
    listSubText: {
        fontSize: 14,
        color: 'grey',
        flex: 1,
    },
    icon: {
        height: 40,
        width: 40
    },
};
