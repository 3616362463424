import React, {forwardRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Col, Collapse, Divider, Form, Icon, message, Row, Spin} from 'antd';

import {Fade} from 'react-reveal';
import * as moment from "moment";
import {useSelector} from "react-redux";
import useApiClub from "../../../Hooks/ApiClub";
import {useValidationShorts} from "../../../Hooks/Helpers";
import useAuth from "../../../Hooks/Auth";
import {Env} from "../../../Helpers/Env";
import {Images} from "../../../Helpers/Images";
import {maskPhone} from "../../../Helpers/Functions";

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}

const pStyle = {
    fontSize: 16,
    color: 'rgba(0,0,0,0.85)',
    lineHeight: '24px',
    display: 'block',
    marginBottom: 16,
};

const DescriptionItem = ({ title, content }) => (
    <div className="profile-description-item">
        <p>
            {title}:
        </p>
        {content}
    </div>
);

const ExternalFullResumePage = forwardRef((props, ref) => {
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const api = useApiClub({loadingControl: false});
    const [privateProfile, setPrivateProfile] = useState(false);
    const [isFirstJob, setIsFirstJob] = useState(false);
    let S3LINK = process.env.NODE_ENV  === 'production' ? "https://crafty-api.s3.amazonaws.com/" : "https://crafty-api-dev.s3.amazonaws.com/"

    const getData = async (id, cboId, recruited) => {

        setLoading(true);
        let endpointAux = '';

        endpointAux = `formal-working/job-opportunity/${props.match.params.vacancy_id}/interest/${id}`;
        setPrivateProfile(true);


        api.get(endpointAux, (res) => {
            res.object.sine_user.status = res.object.status;
            setProfile(res.object.sine_user);
            setLoading(false);
        }, (e) => {
            setProfile({});
            setLoading(false);
            message.warning('Ops, não foi possível recuperar os dados do currículo, tente novamente mais tarde')
        });
    };

    const convertDate = (end, start) => {
        let years;
        let months;
        if(end) {
            years = moment(end,'YYYY-MM').diff(start, 'years');
            months = moment(end,'YYYY-MM').diff(start, 'months');
        } else {
            years = moment().diff(start, 'years');
            months = moment().diff(start, 'months');
        }

        if(years < 1) {
            return <span> {months % 12 + ' meses'} </span>
        } else if (months % 12 > 0){
            return <span> {years + ' ano(s) e ' + months % 12 + ' mês(es)'} </span>
        } else {
            return <span> {years + ' ano(s)'} </span>
        }

    };

    useEffect(() => {
        setProfile({});
        setPrivateProfile(false);
        setIsFirstJob(false);
        getData(props.match.params.id);

    },[props.match.params.id]);

    return (
        <div>
            <div style={{overflow: 'auto', padding: 24}} id={'scrollDivDrawer'}>
                <Spin spinning={loading} tip="Carregando...">
                    <React.Fragment>

                        <Row style={{ marginBottom: 24, marginTop: 20}}>
                            <Col span={24} style={{display: "flex", justifyContent: 'space-between', flexWrap: "wrap"}}>
                                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                    {profile.name&&<div style={{width: 'fit-content', display: 'flex', flex: 1}}>
                                        {profile?.profile_picture ?
                                            <img src={`${S3LINK}public/worker_full/${profile?.profile_picture}`}
                                                 alt="picture"
                                                 style={{width:70, height:70, borderRadius:"50%", objectFit:"cover"}}
                                            />
                                        :
                                            <Avatar style={{minWidth: 50, minHeight: 50}}
                                                    src={(Images.lettersPack[profile.name[0].toLowerCase()] || Images.user_place_holder_2)}
                                                    size="large"
                                            />
                                        }
                                        <div>
                                            <h2 style={{verticalAlign: "middle", display: 'inline-block', margin: '0 0 0 12px'}}> {profile.name} </h2>
                                        </div>
                                    </div>}
                                </div>
                            </Col>
                        </Row>

                        {profile.name?
                            <Fade left>
                                <div>
                                    <p style={{...pStyle, color: "#2593fc"}}><Icon type="lock" /> Informações Privadas</p>
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="Nome" content={profile.name} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="CEP" content={profile.postal_code} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <DescriptionItem title="Endereço" content={profile.street + '/' + profile.district + ' ' + profile.city + '/' + profile.state_abbrev} />
                                        </Col>
                                    </Row>
                                    {profile.emails_array?.length > 0 &&<Row>
                                        <Col span={24}>
                                            <DescriptionItem title="Emails" content={profile.emails_array?.join(", ")} />
                                        </Col>
                                    </Row>}
                                    {profile.phone_numbers_array?.length > 0 &&<Row>
                                        <Col span={24}>

                                            <div className="profile-description-item">
                                                <p>
                                                    Telefones:
                                                </p>
                                                {profile.phone_numbers_array.map((item, index) => {
                                                    let aux = item.replace(/\D/g, "");
                                                    return <span key={index} style={{marginRight: 10}}> {maskPhone(item)} <a target={'_blank'} href={'https://web.whatsapp.com/send?phone=+' + aux + '&text=Olá, ' + profile.name + '! Selecionei seu currículo pelo ' + Env.application_title + ', tudo bem?'}> <img style={{height: 20, marginBottom: 5, cursor: 'pointer'}}  src={Images.whats}/> </a> </span>
                                                })}
                                            </div>
                                        </Col>
                                    </Row>}
                                    <Divider />
                                </div>
                            </Fade>
                            :
                            null
                        }
                        {profile?.about_me &&<Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greyBg'} header={<span style={{fontWeight: 'bold', color: '#1a1a1a'}}> <Icon type="align-left" /> Sobre mim </span>} key="1">
                                <span>
                                    {profile.about_me}
                                </span>
                            </Collapse.Panel>
                        </Collapse>}

                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel header={<span style={{fontWeight: 'bold', color: '#0072d6'}}> <Icon type="audit" /> Experiências </span>} key="1">
                                {profile?.user_work_experiences?.length > 0?
                                    profile?.user_work_experiences?.map((item,index) =>
                                    {if((item.start_date !== item.end_date)) {
                                        return <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.user_work_experiences?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#4caf50'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Cargo: </strong>{item.occupation.name}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Empresa: </strong> {privateProfile? item.company : 'Selecione este currículo para ver'}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Último salário: </strong>{privateProfile? (item.last_wage? <span>R$ {item.last_wage}</span> : 'Não informado') : 'Selecione este currículo para ver' }</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Tipo de trabalho: </strong>{item.work_type}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Local: </strong>{item.location}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Descrição do serviço: </strong>
                                                    <p>
                                                        {item.description? item.description : 'Não informado'}
                                                    </p>
                                                </label>
                                            </Col>

                                            {(item.work_type !== 'Freelance' && item.work_type !== 'Autônomo') ?
                                                <Col xs={24} md={24}>
                                                        <span>
                                                            <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Formal: </strong>
                                                            <span>{convertDate(item.end_date, item.start_date)}</span>
                                                            ( Inicio - {moment(item.start_date, 'YYYY-MM').format('MM/YYYY')} ) &nbsp; {item.end_date&& <span> {'('}Fim - {moment(item.end_date, 'YYYY-MM').format('MM/YYYY')} {')'}</span>}
                                                        </span>
                                                </Col>
                                                :
                                                <Col xs={24} md={24}>
                                                        <span>
                                                            <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Experiencia Informal: </strong>
                                                            <span>{convertDate(item.end_date, item.start_date)}</span>
                                                            ( Inicio - {moment(item.start_date, 'YYYY-MM').format('MM/YYYY')} ) &nbsp; {item.end_date&& <span>{'('} Fim - {moment(item.end_date, 'YYYY-MM').format('MM/YYYY')} {')'}</span>}
                                                        </span>
                                                </Col>
                                            }
                                        </Row>
                                    }
                                    })
                                    :
                                    <span> Não possui </span>
                                }

                                {isFirstJob&&
                                <Row type={'flex'}>
                                    <Col span={8}>
                                        <img src={Images.search_resumes} style={{maxWidth: '100%'}}/>
                                    </Col>
                                    <Col span={16} style={{alignSelf: 'center'}}>
                                        <h1 style={{fontSize: 23, textAlign: 'center'}}> Profissional em busca de sua primeira colocação </h1>
                                    </Col>
                                </Row>
                                }
                            </Collapse.Panel>
                        </Collapse>


                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel header={<span style={{fontWeight: 'bold', color: '#0072d6'}}> <Icon type="audit" /> Outros Interesses </span>} key="1">
                                {profile?.user_work_clains?.length > 0? profile?.user_work_clains?.map((item,index) =>
                                        <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.user_work_clains?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label>
                                                    <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> {item.occupation.name} </strong>
                                                </label>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    <span> Não possui </span>
                                }
                            </Collapse.Panel>
                        </Collapse>


                        <Collapse style={{marginBottom: 20}} defaultActiveKey={['1']} expandIconPosition={'right'} className='floatsCardsResume' expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="book" /> Graduações ({profile?.user_academics?.length})</span>} key="1">
                                {profile?.user_academics?.length > 0? profile?.user_academics?.map((item,index) =>
                                        <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.user_academics?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#0072d6'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item.course}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Instituição: </strong>{item.university}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Nível: </strong>{item.academic_degree}</label>
                                            </Col>

                                            <Col xs={24} md={12}>
                                                <span> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Inicio: </strong> {item.start_date? moment(item.start_date, 'YYYY-MM').format('MM/YYYY'): 'Não informado' }</span>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <span> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Fim: </strong> {item.end_date? moment(item.end_date, 'YYYY-MM').format('MM/YYYY'): 'Não informado' }</span>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    <span> Não possui </span>
                                }
                            </Collapse.Panel>
                        </Collapse>



                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} defaultActiveKey={['1']} className='floatsCardsResume' expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="control" /> Cursos ({profile?.user_courses?.length}) </span>} key="1">

                                {profile?.user_courses?.length > 0 ? profile?.user_courses?.map((item,index) =>
                                        <Row key={index} style={{marginBottom: 10, paddingBottom: 10, borderBottom: profile?.user_courses?.length - 1 === index ? 'unset' : '1px solid gainsboro'}}>
                                            <Col xs={24}>
                                                <label style={{fontWeight: 'bold', color: '#0072d6'}}> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Curso: </strong>{item.name}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Instituição: </strong>{item.institution}</label>
                                            </Col>

                                            <Col xs={24}>
                                                <label> <strong style={{fontSize: 14, color: 'rgba(0,0,0,0.85)'}}> Duração: </strong>{item.duration? <span> {item.duration} meses </span> : 'Não informado'} </label>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    <span> Não possui </span>
                                }
                            </Collapse.Panel>
                        </Collapse>

                        <Collapse style={{marginBottom: 20}} expandIconPosition={'right'} className='floatsCardsResume' defaultActiveKey={['1']} expandIcon={({ isActive }) =>
                            <div className={isActive? "circle-plus closed": "circle-plus opened" }>
                                <div className="circle">
                                    <div className="horizontal"/>
                                    <div className="vertical"/>
                                </div>
                            </div> }>
                            <Collapse.Panel className={'greenBg'} header={<span style={{fontWeight: 'bold', color: '#259a90'}}> <Icon type="contacts" /> Outras Informações</span>} key="1">
                                <Row>
                                    {profile.birthday &&<Col span={12}>
                                        <DescriptionItem title="Idade" content={moment(profile.birthday).fromNow(true)} />
                                    </Col>}
                                    {profile.gender &&<Col span={12}>
                                        <DescriptionItem title="Gênero" content={genders[profile.gender]} />
                                    </Col>}
                                </Row>
                                {profile.wage_claim &&<Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Pretensão salarial" content={privateProfile? 'R$ ' + profile.wage_claim: 'Selecione este currículo para ver'} />
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col span={12}>
                                        <DescriptionItem title="Estudante" content={profile.is_student === 'S' ? "Sim" : "Não"} />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem title="Habilitação" content={profile.driver_license || 'Não tem'} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Escolaridade" content={profile.schooling} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Endereço" content={profile.street + '/' + profile.district + ' ' + profile.city + '/' + profile.state_abbrev} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Disponibilidade para realizar viagens" content={profile.available_travel === 'S' ? "Sim" : "Não"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Disponibilidade para dormir no local de trabalho" content={profile.available_sleep_duty === 'S' ? "Sim" : "Não"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Disponibilidade para ausentar-se do domicílio por longo período" content={profile.available_leave_home === 'S' ? "Sim" : "Não"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>

                                        <div className="profile-description-item">
                                            <p>
                                                Outras Idiomas:
                                            </p>
                                            {profile.languages?
                                                <ul style={{ lineHeight: '22px' }}>
                                                    { profile.languages?.split("|").map((el, index) => <li key={index}>{el}</li>) }
                                                </ul>
                                                :
                                                'Nenhum'
                                            }
                                        </div>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24}>

                                        <div className="profile-description-item">
                                            <p>
                                                Veículos:
                                            </p>
                                            {profile.vehicles?
                                                <ul style={{lineHeight: '22px'}}>
                                                    {profile.vehicles?.split("|").map((el, index) => <li key={index}>{el}</li>)}
                                                </ul> :
                                                'Nenhum'
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>

                                        <div className="profile-description-item">
                                            <p>
                                                Deficiências:
                                            </p>
                                            {profile.disabilities?
                                                <ul style={{lineHeight: '22px'}}>
                                                    {profile.disabilities?.split("|").map((el, index) => <li key={index}>{el}</li>)}
                                                </ul> :
                                                'Nenhuma'
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>

                    </React.Fragment>
                </Spin>

            </div>
        </div>
    );
});

ExternalFullResumePage.propTypes = {
    cboId: PropTypes.any,
    id: PropTypes.any,
    setVisible: PropTypes.any,
    visible: PropTypes.any,
    recruited: PropTypes.any,
    favorite: PropTypes.any,
    handleFavorite: PropTypes.any,
    disregardCurriculum: PropTypes.any
};

export default Form.create({name: 'requestExternalFormExternal'})(ExternalFullResumePage);
