import React, {useEffect, useState} from 'react'
import {Images} from "../../../../../../Helpers/Images";
import {Drawer} from "antd";
import ResumeDrawer from "../../../../Public/SearchResumePage/ResumeDrawer";

const RecommendListWorkerDrawer = ({titleVacancy, workerList, visible, setVisible, genders, idVacancy, addUserInList, occupationId}) => {
    const [workerDetailsDrawer, setWorkerDetailsDrawer] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const [cbo, setCbo] = useState({});
    const showDetailsWorker = (id, cboId, username, photo) => {
        setWorkerDetailsDrawer(true)
        setCbo({id:id, cboId:cboId, username: username, avatar:photo})
    }

    const handleViewContact = () => {
        let aux = candidates
        setWorkerDetailsDrawer(false)
        addUserInList(cbo.id)
        aux.splice(aux.findIndex(e => e.user_id === cbo.id), 1)
        setCandidates(aux)
    };


    useEffect(() => {
        if (workerList.workers) {
            setCandidates(workerList.workers)
        }
    }, [workerList]);

    return (
        <>
            <ResumeDrawer visible={workerDetailsDrawer}
                          id={cbo.id}
                          idVacancy={idVacancy}
                          cboId={cbo.cboId}
                          username={cbo.username}
                          avatar={cbo.avatar}
                          handleRecruited={() => handleViewContact()}
                          setVisible={(e) => setWorkerDetailsDrawer(e)}
                          recruited={false}
                          titleVacancy={titleVacancy}
            />
            <Drawer visible={visible}
                    onClose={setVisible}
                    className={'recommend-list-worker-drawer'}
                    placement="left"
                    width={420}
                    bodyStyle={{padding:0}}
            >
                <div>
                    <div className='rlwd-title'>
                        <div style={{padding:'16px 16px 8px'}}>
                            <h2 style={{fontSize:'1.25rem', color:'#595959', marginBottom:0}}>
                                Candidatos recomendados para vaga:
                            </h2>

                            <span style={{textTransform: "uppercase", fontSize:'1.2rem', background: "#cfe2ff", color: "#084298", padding: "2px 9px", borderRadius: "4px"}}>
                                {titleVacancy}
                            </span>
                        </div>
                    </div>
                    <div className='worker-list-recommended'>
                        {candidates?.map((item) => (
                            <div className='details-worker' onClick={() => showDetailsWorker(item.user_id, occupationId, item.name, item.profile_picture)}>
                                <div>
                                    <div>
                                        <img src={item.profile_picture ? item.profile_picture : Images.user_place_holder_2} alt="profile image"/>
                                    </div>
                                    <div>
                                        <div style={{textTransform: "uppercase"}}>
                                            <h4>
                                                {item.name}
                                            </h4>
                                        </div>
                                        <div>
                                            <span>
                                                {genders[item.gender]} - {item.age} Anos
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                {item.distance && `${window.getDistance(item.distance)}`}
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                {item.city} - {item.state_abbrev}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default RecommendListWorkerDrawer