import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import "./custom.less";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {Env} from "./Helpers/Env";
import ReactGA from "react-ga4";

process.env.NODE_ENV  === 'production' &&Sentry.init({
    dsn: "https://d1aac1a53430412db8b4d8d122bbdb39@o516750.ingest.sentry.io/5868895",
    debug: !process.env.NODE_ENV  === 'production',
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV  === 'production'? 'production' : 'development',
    release: 'geracaoemprego-web@' + Env.version,
    beforeBreadcrumb(breadcrumb, hint) {
        return breadcrumb.category === "console" ? null : breadcrumb;
    },
    ignoreErrors: ["ResizeObserver loop limit exceeded"]
});
ReactGA.initialize(Env.TRACKING_ID)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start m
// easuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*reportWebVitals();*/
