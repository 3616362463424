import React, {useState, useEffect} from 'react';
import {Row} from "antd";
import {Texts} from "../../../../Helpers/Texts";

const AboutUsHomeComponent = () => {

    return (<Row className='headerHowTo' style={{padding: 5,  marginTop: 200, marginBottom: 0, textAlign: 'center'}}>
        <h1 className={'title title-line'}> {Texts.home_page.howTo.title1} </h1>
        <div style={{maxWidth: 600, margin: 'auto'}}>
            {Texts.home_page.howTo.subTitle1}
        </div>

    </Row>)
};
export default AboutUsHomeComponent;
