import React, {useEffect, useRef, useState} from 'react';
import HowToHireServices from "../Components/PageComponents/Public/HowItWorksPage/HowToHireServices";
import HowToRecruit from "../Components/PageComponents/Public/HowItWorksPage/HowToRecruit";
import HowToDiscounts from "../Components/PageComponents/Public/HowItWorksPage/HowToDiscounts";
import HowToClub from "./HowToClub";
import {Env} from "../Helpers/Env";
import useApi from "../Hooks/Api";
import useLocalStorage from "../Hooks/Storage";
import {StorageVariables} from "../Helpers/StoragesVariables";
import {parseJwt} from "../Helpers/Functions";
import {Col, message, Row} from "antd";
import {Fade} from "react-reveal";
import {Texts} from "../Helpers/Texts";
import RegisterFinalForm from "../Components/PageComponents/Public/RegisterPage/RegisterFinalForm";
import EndRegisterStep from "../Components/PageComponents/Public/Auth/EndRegisterStep";
import {Images} from "../Helpers/Images";
import Loading from "../Components/Generic/Loading";
import useAuth from "../Hooks/Auth";
import {useHistory} from "react-router-dom";

const GovAccessConfigPage = (props) => {
    const history = useHistory();
    let filtersQuery = window.queryStringToObject();
    const api = useApi();
    const code_verifier = useLocalStorage(StorageVariables.access_gov_verifier);
    const [step, setStep] = useState('loading')
    const panelStorage = useLocalStorage(StorageVariables.lastPanel);
    const data = useRef();
    const fcm_token = useRef();
    const {login, storeSineProfile, storeEnterpriseOpportunities} = useAuth();
    const hasProfileStorage = useLocalStorage(StorageVariables.hasSineProfile);

    const checkLogin = () => {

        api.post(`access/login`, { application_name: Env.application_name, govbr_id: data.current.dec_data.sub,  govbr_token: data.current.session_token,  fcm_token: fcm_token.current}, (res) => {
            message.success({
                icon: <> </>,
                content: <div style={{display: 'flex', fontWeight: 'bold', flexFlow: 'column'}}>
                    <img style={{height: 50, marginBottom: 10}} src={Images.logo_header}/> <span> Seja bem-vindo(a). </span>
                </div>
            });
            res.govbr_id = true;

            const obj = {
                token: res.api_key,
                user_id: res.user_id,
                email: res.profile.email,
                nickname: res.profile.nickname,
                mobile_verified: res.profile.mobile_verified,
                avatar: res.profile.profile_picture?.thumbnail,
                name: (res.profile.first_name + ' ' + res.profile.last_name),
                gender: res.profile.gender,
                phone: res.profile.cellphone_number,
                cpf: res.profile.cpf,
                addresses: res.addresses,
                birthday: res.profile.birthday,
                govbr_id: res.govbr_id,
            };
            Env.header[`X-Api-Key`] = res.api_key;

            api.get(`sine/user/${res.user_id}/full`, (sine) => {
                if(sine.user_id !== null) {
                    hasProfileStorage.setPrimitive(true)
                } else {
                    hasProfileStorage.setPrimitive(false);
                }
                storeSineProfile(sine);
                login(obj);
                api.post(`sine/user/${res.user_id}/job-opportunities`, {
                    "order": "desc",
                    "page": 1,
                    "status": 'open'
                },(opportunitiesList) => {
                    if(opportunitiesList.job_opportunities) {
                        storeEnterpriseOpportunities(opportunitiesList.job_opportunities)
                    }
                },(e) => {});
                history.push('/')
            }, ()=> {
                login(obj);
                history.push('/')
            })

        },(e) => {
            setStep('regForm')
        });

    }

    const sendAccessGovData = () => {
        if(filtersQuery.state === Env.application_alias && filtersQuery.code) {
            api.get('access/govbr-authorize?code=' + filtersQuery.code + '&application=' + Env.application_alias + '&code_verifier=' + code_verifier.getPrimitive(), (res) => {
                let dataRef = parseJwt(res.session_token);
                data.current = {
                    dec_data: dataRef,
                    session_token: res.session_token
                }
                checkLogin();
            }, (e) => console.log(e))
        } else {
            message.success({
                icon: <> </>,
                content: <div style={{display: 'flex', fontWeight: 'bold', flexFlow: 'column'}}>
                    <img style={{height: 50, marginBottom: 10}} src={Images.logo_header}/> <span> Ops, algo deu errado =/, tente Novamente </span>
                </div>
            });
        }
    }

    useEffect(() => {
        window.getMessagingTokenService((currentToken) => {
            fcm_token.current = currentToken;
        });
        sendAccessGovData();
    },[]);

    return (<div style={{display: 'flex', justifyContent: 'center', background: 'aliceblue', minHeight: window.innerHeight, flexDirection: 'column', alignItems: 'center', padding: 50, paddingBottom: 100, paddingTop: 150}}>
        {step === 'loading' ?

             <Loading/>
            :
            <>
                <Row style={{display: 'flex', alignItems: 'stretch', flexWrap: 'wrap', maxWidth: '800px', boxShadow: '0px 2px 4px 1px #e8e8e8', borderRadius: 10}}>
                    <Col xs={24} sm={8} style={{boxShadow: '0px 2px 4px 1px #e8e8e8', borderRadius: 10}} className={'sideLogin'}>
                        <div className={'sideDiv'}>
                            <Fade left spy={step}>
                                {(step === 'regForm') ? <div className={'logTitle'}>
                                        <span> Complete as informações para se registrar! </span>
                                        <div className={'line'}/>
                                    </div>
                                    :
                                    <div className={'logTitle'}>
                                        <span> Tudo certo! </span>
                                        <div className={'line'}/>
                                    </div>}
                            </Fade>

                            <Fade right spy={step}>
                                {(step === 'regForm')?
                                    <span className={'logSubTitle'}> {Texts.auth_page.reg_subForm}  </span>
                                    :
                                    <span className={'logSubTitle'}>  {Texts.auth_page.reg_subEnd} </span>
                                }
                            </Fade>
                        </div>
                    </Col>
                    <Col xs={24} sm={16} style={{padding: 30, paddingBottom: 0, transition: '400ms', background: 'white'}}>
                        {step === 'regForm'&&<RegisterFinalForm userCell={window.maskViewPhone(data.current.dec_data.phone_number)} session_token={data.current.session_token} initialData={data.current.dec_data} profile={panelStorage.getPrimitive()} setStep={() => {setStep('regEnd');}} mobileVerified={false}/>}

                        {step === 'regEnd'&&<EndRegisterStep setVisible={(e) => console.log('')} lastProfileAux={panelStorage.getPrimitive()}/>}
                    </Col>
                </Row>

                <div style={{display: 'flex', justifyContent: 'space-evenly', maxHeight: '15%', marginTop: 40, maxWidth: '800px',}}>
                    <div style={{margin: 10}}>
                        <img style={{maxWidth: 300}} src={Images.logo}/>
                    </div>
                    <div style={{margin: 10}}>
                        <img style={{maxWidth: 300}} src={Images.logo}/>
                    </div>
                </div>
            </>
        }
    </div>)
};
export default GovAccessConfigPage;
