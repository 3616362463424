import logo_header from '../Img/logo_header_small.png'
import logo_header_smallest from '../Img/logo_small.png'
import logo from '../Img/logo_header.png'
import number_step from '../Img/phone.png'
import number_code from '../Img/phone.png'
import end_step from '../Img/phone.png'
import bannerHome1 from '../Img/banner1.jpg'
import bannerHome2 from '../Img/home3.jpg'
import bannerHome3 from '../Img/home4.jpg'
import bannerHome4 from '../Img/banner3.jpg'
import resume_card_icon from '../Img/homeCards1.svg'
import resume_icon from '../Img/search_contrata.png'
import establishment_icon from '../Img/vacancyHome.png'
import counter_img from '../Img/contratar_01.jpg'
import services_banner1 from '../Img/contratar_01.jpg'
import how_to1 from '../Img/howTo3.svg'
import how_to2 from '../Img/howTo2.svg'
import how_to3 from '../Img/howTo1.svg'
import how_to_worker_1 from '../Img/howTo1.svg'
import how_to_worker_2 from '../Img/howTo1.svg'
import how_to_worker_3 from '../Img/howTo1.svg'
import service_icon from '../Img/settings.svg'
import noPhotoRed from '../Img/noPhoto.png'
import case_img from '../Img/case.png'
import enterprise_compliance from '../Img/enterpriseCompliance.png'
import endVacancy from '../Img/endVacancy.png'
import client from '../Img/client.png'
import worker from '../Img/worker.png'
import gplay_icon from '../Img/gplay.png'
import apple_icon from '../Img/iosIcon.png'
import app_img from '../Img/craftyCell.png'
import service_not_found from '../Img/serviceNotFound.svg'
import establishment_not_found from '../Img/serviceNotFound.svg'
import resume_not_found from '../Img/empty.png'
import background_1 from '../Img/bgCheck.png'
import background_2 from '../Img/bg2.svg'
import background_3 from '../Img/worker_panel_bg.svg'
import background_4 from '../Img/bgCheck.png'
import about_1 from '../Img/about1.jpg'
import about_2 from '../Img/about2.jpg'
import about_3 from '../Img/about3.jpg'
import app_club_img from '../Img/clubPhoneImg.png'
import reg_img_step1 from '../Img/howItWorksHire1.png'
import search_img_step2 from '../Img/howItWorksHire2.png'
import confirm_img_step3 from '../Img/howItWorksHire3.png'
import await_img_step4 from '../Img/howItWorksHire4.png'
import rate_img_step5 from '../Img/howItWorksHire5.png'
import img_1 from '../Img/contactBanner.png'
import img_2 from '../Img/contactBanner.png'
import img_3 from '../Img/contactBanner.png'
import establishment_banner1 from '../Img/trabalhar_03.jpg'
import establishment_banner2 from '../Img/contratar_02.jpg'
import services_banner2 from '../Img/contratar_02.jpg'
import services_banner3 from '../Img/contratar_03.jpg'
import user_place_holder from '../Img/photoPlaceholder.png'
import house from '../Img/house.svg'
import apartment from '../Img/apartment.svg'
import condominium from '../Img/condominium.svg'
import commercial from '../Img/shop.svg'
import farm from '../Img/mill.svg'
import bottom_waves from '../Img/wavesLight.svg'
import requested from '../Img/requested.svg'
import clarifying from '../Img/requested.svg'
import proposal from '../Img/share.svg'
import negotiation from '../Img/negotiating.svg'
import accepted from '../Img/accepted.svg'
import completed from '../Img/accepted.svg'
import rejected from '../Img/rejected.svg'
import broadcast from '../Img/thunder.svg'
import canceled from '../Img/cancelled.svg'

import load_more from '../Img/seeMore.svg'
import cancel from '../Img/mail.svg'
import accept from '../Img/checked.svg'
import negotiate from '../Img/negotiation.svg'
import reject from '../Img/unchecked.svg'
import cancel_negotiation from '../Img/cancelNegotiation.svg'
import fav from '../Img/star.svg'
import received from '../Img/shareBlack.svg'
import stamp from '../Img/stamp.svg'
import clipboard from '../Img/clipboard.svg'
import file from '../Img/file.svg'
import handshake from '../Img/handshake.svg'
import club_img from '../Img/craftyClub.png'
import job_case from '../Img/case.svg'
import notification_card from '../Img/notification_main.png'
import resume_card from '../Img/resumes_main.png'
import likes_card from '../Img/likes_main.png'
import likes_card_gold from '../Img/likes_main_gold.png'
import profile_card from '../Img/profile_main.png'
import shop_card from '../Img/shop_main.png'
import address_card from '../Img/addresses_main.png'
import agenda_card from '../Img/agenda_card.png'
import edit_resume from '../Img/edit_resume.png'
import view_resume from '../Img/5.png'
import incomplete_resume from '../Img/5.gif'
import contact_resume_page from '../Img/contact.png'
import worker_banner_1 from '../Img/banner1.jpg'
import worker_banner_2 from '../Img/banner2.jpg'
import worker_banner_3 from '../Img/banner3.jpg'
import footer_bg from '../Img/footerBackground.svg'
import how_to_resume_client_1 from '../Img/howItWorksClient2.png'
import how_to_resume_client_2 from '../Img/howItWorksClient1.png'
import how_to_resume_client_3 from '../Img/howItWorksClient3.png'
import worker_register from '../Img/register_contrata.png'
import worker_register_crafty from '../Img/register_worker.png'
import selected_resumes from '../Img/selected_resumes.svg'
import concluded_resumes from '../Img/concluded_resumes.svg'
import concluded_resumes_img from '../Img/concluded_resumes.png'
import funtrab from '../Img/funtrab.png'
import search_resumes from '../Img/search_resumes.png'
import whats from '../Img/whatsapp.svg'
import insta from '../Img/icons_instagram.svg'
import face from '../Img/icons_facebook.svg'
import end1 from '../Img/end1img.png'
import end2 from '../Img/end2img.png'
import partner1 from '../Img/parceiros/1.jpg'
import partner2 from '../Img/parceiros/2.jpg'
import partner3 from '../Img/parceiros/3.jpg'
import partner4 from '../Img/parceiros/4.jpg'
import partner5 from '../Img/parceiros/5.jpg'
import partner6 from '../Img/parceiros/6.jpg'
import partner7 from '../Img/parceiros/7.jpg'
import partner8 from '../Img/parceiros/8.jpg'
import partner9 from '../Img/parceiros/9.jpg'
import pin from '../Img/pin.svg'
import checkedPin from '../Img/checkPin.svg'
import iconImported from '../Img/iconResumeImported.jpeg'
import iconNotImported from '../Img/iconResume.png'
import footerGov from '../Img/rodape.jpg'
import logoGov from '../Img/logo_header.png'
import storeIcon1 from '../Img/playstore.png'
import storeIcon2 from '../Img/logoPlayContrataMais2.png'
import search_service_icon from '../Img/search_worker.png'
import request_service_confirmation from '../Img/requestSent.svg'
import address_not_found from '../Img/noAddressFound.svg'
import no_service_requested from '../Img/noServicesRequested.svg'
import no_ratings_img from '../Img/likesBackground.svg'
import no_fast_search_result from '../Img/mapFast.svg'
import fast_search_result from '../Img/fastSearchImg.svg'
import fast_search_banner from '../Img/fastSearchBanner.jpg'
import fast_search_place_holder from '../Img/thunderColored.svg'
import no_notification_found from '../Img/noNotificationsFound.svg'
import bannerHomeCourse from '../Img/bannerHome.png'
import bannerHome from '../Img/bannerMaratona.png'
import vacancy_img from '../Img/vacancy_img.png'
import user_place_holder_2 from '../Img/user.svg'
import manageResumes from '../Img/manageResumes.png'
import jobSearch from '../Img/jobSearch.png'
import home_card_worker_alt from '../Img/homeCards1.png'
import megaphone from '../Img/megaphone.png'
import deal2 from '../Img/deal2.png'
import deal3 from '../Img/deal3.png'
import vacancy_painel from '../Img/vacancy-painel.svg'
import resume_new from '../Img/resumeNew.png'

import a from '../Img/lettersPack/a.png'
import b from '../Img/lettersPack/b.png'
import c from '../Img/lettersPack/c.png'
import d from '../Img/lettersPack/d.png'
import e from '../Img/lettersPack/e.png'
import f from '../Img/lettersPack/f.png'
import g from '../Img/lettersPack/g.png'
import h from '../Img/lettersPack/h.png'
import i from '../Img/lettersPack/i.png'
import j from '../Img/lettersPack/j.png'
import k from '../Img/lettersPack/k.png'
import l from '../Img/lettersPack/l.png'
import m from '../Img/lettersPack/m.png'
import n from '../Img/lettersPack/n.png'
import o from '../Img/lettersPack/o.png'
import p from '../Img/lettersPack/p.png'
import q from '../Img/lettersPack/q.png'
import r from '../Img/lettersPack/r.png'
import s from '../Img/lettersPack/s.png'
import t from '../Img/lettersPack/t.png'
import u from '../Img/lettersPack/u.png'
import v from '../Img/lettersPack/v.png'
import x from '../Img/lettersPack/x.png'
import w from '../Img/lettersPack/w.png'
import y from '../Img/lettersPack/y.png'
import z from '../Img/lettersPack/z.png'
import checked from '../Img/checked.png'
import img_upload from '../Img/imageUpload.png'
import edital1 from '../Img/edital1.png'
import edital2 from '../Img/edital2.png'
import menuWorker from '../Img/menuWorker.png'
import VacancyHomeCard from '../Img/vacancy-homecard.svg'
import SearchHomeCard from '../Img/search-curriculum.svg'
import FeedbackHomeCard from '../Img/feedback-homecard.svg'
import bestPlataform from '../Img/best-plataform.svg'
import searchService from '../Img/search-service.svg'
import matchService from '../Img/match-service.svg'
import waitingService from '../Img/waiting-service.svg'
import feedbackService from '../Img/feedback-service.svg'
import feedbackIconTestimonial from '../Img/ratingTestimonial.png'
import formModal from '../Img/forms-modal.svg'
import gmailIcon from '../Img/gmail-icon-footer.svg'
import commonQuestions from '../Img/common-questions.svg'
import notFoundSearch from '../Img/notFoundSearch.svg'
import fakeBanner from '../Img/banner-default-company.jpeg'
import publishLanding from '../Img/LandingPage/publique2.png'
import toolsLanding from '../Img/LandingPage/ferramenta3.png'
import locationLanding from '../Img/LandingPage/geoloc1.png'
import freeLanding from '../Img/LandingPage/graca1.png'
import responsiveLanding from '../Img/LandingPage/reponsiva.png'
import securityLanding from '../Img/LandingPage/seguranca2.png'
import solutionLanding from '../Img/LandingPage/solucao1.png'
import searchLanding from '../Img/LandingPage/busque3.png'
import lineLanding from '../Img/LandingPage/title-border.png'
import aboutLanding from '../Img/LandingPage/about-img.png'
import americanas from '../Img/LandingPage/logos/AMERICANAS200X200.png'
import anita from '../Img/LandingPage/logos/anita200x200.png'
import cometa from '../Img/LandingPage/logos/COMETA200X200.png'
import girafas from '../Img/LandingPage/logos/GIRAFAS200X200.png'
import niura from '../Img/LandingPage/logos/niura200x200.png'
import safari from '../Img/LandingPage/logos/safari200x200.png'
import capaBusiness from '../Img/LandingPage/undraw_business_deal_re_up4u.svg'
import oneLanding from '../Img/LandingPage/1.svg'
import note from '../Img/note.png'
import new1 from '../Img/wnew1.png'
import new2 from '../Img/wnew2.png'
import new3 from '../Img/wnew3.png'
import new4 from '../Img/wnew4.png'
import new5 from '../Img/wnew5.png'
import new6 from '../Img/wnew6.png'
import callOperator from '../Img/operador-de-telemarketing.png'
import rocket from '../Img/rocket.png'
import rocket2 from '../Img/rocket2.png'
import completProfile from '../Img/Personal data-cuate.svg'
import add_business from '../Img/add_business.svg'
import course from '../Img/Thesis-amico.svg'
import codeImg from '../Img/codeImg.png'
import serpro from '../Img/serpro.png'
import biometric from '../Img/biometric-icon.svg'
import db from '../Img/db-icon.svg'
import serproError from '../Img/serproError.gif'
import alertCurriculum from '../Img/resume-folder.svg'
import bannerCarnaval from '../Img/bannerFeiraoCarnaval.jpg'
import bannerCarnaval2 from '../Img/bannerFeiraoCarnaval2.png'
import bannerCarnaval3 from '../Img/bannerFeiraoCarnavalWorker.png'
import bannerCarnaval4 from '../Img/bannerFeiraoCarnaval4.jpg'
import survey from '../Img/survey.png'
import pause_image from '../Img/pauseImage.png'
import step1vacancy from '../Img/entriesStep.png'
import step2vacancy from '../Img/auditStep.png'
import step3vacancy from '../Img/peopleSearchStep.png'
import step4vacancy from '../Img/doneStep.png'
import rightArrow2 from '../Img/right-arrow2.png'
import rightArrow3 from '../Img/right-arrow3.png'
import rightArrow4 from '../Img/right-arrow4.png'
import companie from '../Img/companie_default.png'
import ImageStandardCourse from '../Img/ImageStandardCourse.png'
import bgWomanWork from '../Img/bgWomanWork.jpg'
import highFive from '../Img/highfive.png'
export const Images = {
    highFive,
    step1vacancy,
    step2vacancy,
    step3vacancy,
    step4vacancy,
    rightArrow2,
    rightArrow3,
    rightArrow4,
    rocket2,
    logoModalVacancy: {
        logoGov
    },
    whatsNew: {
      new1,
      new2,
      new3,
      new4,
      new5,
      new6,
    },
    pause_image,
    survey,
    alertCurriculum,
    codeImg,
    serpro,
    biometric,
    db,
    course,
    add_business,
    completProfile,
    callOperator,
    rocket,
    capaBusiness,
    oneLanding,
    americanas,
    anita,
    cometa,
    girafas,
    niura,
    safari,
    aboutLanding,
    lineLanding,
    searchLanding,
    publishLanding,
    toolsLanding,
    locationLanding,
    freeLanding,
    responsiveLanding,
    securityLanding,
    solutionLanding,
    note,
    logo_header_smallest,
    home_card_worker_alt,
    serproError,
    fakeBanner,
    vacancy_img,
    endVacancy,
    enterprise_compliance,
    img_upload,
    edital1,
    edital2,
    manageResumes,
    bannerHome,
    case_img,
    menuWorker,
    deal2,
    deal3,
    jobSearch,
    checked,
    noPhotoRed,
    bannerHomeCourse,
    logo_header,
    whats,
    insta,
    face,
    commonQuestions,
    notFoundSearch,
    VacancyHomeCard,
    SearchHomeCard,
    FeedbackHomeCard,
    bestPlataform,
    searchService,
    matchService,
    waitingService,
    feedbackService,
    feedbackIconTestimonial,
    formModal,
    gmailIcon,
    storeIcon1,
    storeIcon2,
    user_place_holder_2,
    logoGov,
    logo,
    footer_bg,
    footerGov,
    address_not_found,
    no_service_requested,
    no_ratings_img,
    no_fast_search_result,
    fast_search_result,
    fast_search_banner,
    fast_search_place_holder,
    no_notification_found,
    logo_footer: '',
    logo_icon: storeIcon1,
    user_place_holder,
    bottom_waves,
    search_resumes,
    auth: {
        number_step,
        number_code,
        end_step,
    },
    help: {
        resume_new,
    },

    resume_search_list: {
      iconNotImported,
      iconImported
    },
    partners: {
        partner1,
        partner2,
        partner3,
        partner4,
        partner5,
        partner6,
        partner7,
        partner8,
        partner9,
    },
    home: {
        bannerCarnaval,
        bannerCarnaval2,
        bannerCarnaval3,
        bannerCarnaval4,
        bannerHome1,
        bannerHome2,
        bannerHome3,
        bannerHome4,
        service_icon,
        search_service_icon,
        resume_icon,
        resume_card_icon,
        megaphone,
        establishment_icon,
        counter_img,
        how_to1,
        how_to2,
        how_to3
    },
    add_resume: {
      end1,
      end2,
      contact_resume_page
    },
    establishment: {
        establishment_banner1,
        establishment_banner2,
        about_1,
        about_2,
        about_3
    },
    services: {
        services_banner1,
        services_banner2,
        services_banner3,
        reg_img_step1,
        search_img_step2,
        confirm_img_step3,
        await_img_step4,
        rate_img_step5,
        request_service_confirmation
    },
    resume: {
        job_case
    },
    how_to_resume_client: {
        how_to_resume_client_1,
        how_to_resume_client_2,
        how_to_resume_client_3,
    },
    discount: {
        img_1,
        img_2,
        img_3,
        stamp
    },
    addresses: {
        house,
        apartment,
        condominium,
        commercial,
        farm
    },
    agenda: {
        requested,
        proposal,
        clarifying,
        negotiation,
        accepted,
        completed,
        rejected,
        broadcast,
        canceled,

        load_more,
        cancel,
        accept,
        negotiate,
        reject,
        cancel_negotiation,
        fav,

        received,
    },

    fast_search: {
        clipboard,
        file,
        handshake
    },
    panel: {
        notification_card,
        resume_card,
        profile_card,
        address_card,
        agenda_card,
        likes_card,
        likes_card_gold,
        shop_card,
        selected_resumes,
        concluded_resumes,
        concluded_resumes_img
    },
    workerPanel: {
        edit_resume,
        view_resume,
        incomplete_resume,
        funtrab,
        vacancy_painel
    },
    workerHomePage: {
        worker_banner_1,
        worker_banner_2,
        worker_banner_3,
        how_to_worker_1,
        how_to_worker_2,
        how_to_worker_3,
        worker_register,
        worker_register_crafty
    },
    lettersPack :{
      a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,x,w,y,z
    },
    client,
    worker,
    gplay_icon,
    apple_icon,
    app_img,
    service_not_found,
    establishment_not_found,
    resume_not_found,
    background_1,
    background_2,
    background_3,
    background_4,
    app_club_img,
    club_img,
    pin,
    checkedPin,
    bgWomanWork,
    companie,

    ImageStandardCourse,
    avatarLogo: 'https://crafty-api-dev.s3.amazonaws.com/public/thumbs/'
};
