import React, { useState} from 'react';
import Header from "./Default/Header";
import Footer from "./Default/Footer";
import { Layout} from 'antd';
import Sidebar from "./Default/Sidebar";
import ContactUs from "./ContactUs";
import useAuth from "../../Hooks/Auth";
import RegisterPageModal from "../../Pages/RegisterPageModal";
import Survey from "../Survey/Survey";
import BannerEvent from "../../Pages/SearchOpportunity/Components/BannerEvent";
import bannerEventFull from "../../Img/EventImages/month_women/main-banner.jpeg"

const { Content } = Layout;

const DefaultLayout = props => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const {isLogged} = useAuth();
    const [registerModal, setRegisterModal] = useState(false);


    return (
        <Layout>
            <RegisterPageModal visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />

            <Sidebar collapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />

            <Layout className="layout">

                <Header
                    setSidebarCollapsed={setSidebarCollapsed}
                    sidebarCollapsed={sidebarCollapsed}
                />

                <Content style={{ position: 'relative', zIndex: 2}}>
                    {props.children}
                </Content>
                <ContactUs/>
                <Footer />
                {(isLogged()) && <Survey />}
                {(!window.location.pathname.includes('/cliente') && !window.location.pathname.includes('evento')) &&
                    <BannerEvent isModal={true} banner={bannerEventFull}/>
                }


            </Layout>
        </Layout>

    );
};

DefaultLayout.propTypes = {};

export default DefaultLayout;
