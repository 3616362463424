import React from 'react'
import {Col} from "antd";

const DividerCustom = () => {

    return (
        <div className='de-flex'>
            <Col xs={6} md={4} lg={2} className='divider-line'>
                <div className='line'/>
                <div className='polygon'/>
            </Col>
        </div>
    )
}

export default DividerCustom