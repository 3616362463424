import Route from "react-router-dom/es/Route";
import React from "react";
import Redirect from "react-router-dom/es/Redirect";
import useLocalStorage from "../Hooks/Storage";
import {StorageVariables} from "./StoragesVariables";

export const ExternallyPrivateRoute = ({component: Component, ...obj}) => {

    const externAccessUser = useLocalStorage(StorageVariables.externUserAccess)


    return (
        <Route {...obj}
               render={props => externAccessUser.getObject() ?
                   (<Component {...props} />)
                   :
                   (<Redirect to='/acesso-externo/acesso-da-empresa'/>)
               }/>)
};