import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "antd";
import useLocalStorage from "../Hooks/Storage";
import useVerifyServices from "../Hooks/VerifyServices";
import ProfilePage from "../Components/PageComponents/Restrict/ClientPanelPage/ProfilePage";
import ShopListPage from "./ShopListPage";
import AddressesPage from "../Components/PageComponents/Restrict/ClientPanelPage/AddressesPage";
import ComplimentsPage from "../Components/PageComponents/Restrict/ClientPanelPage/ComplimentsPage";
import {PrivateRoute} from "../Helpers/PrivateRoute";
import NotificationPage from "../Components/PageComponents/Restrict/ClientPanelPage/NotificationPage";
import {Redirect} from "react-router-dom";
import {Images} from "../Helpers/Images";
import MyRecruitmentsPage from "../Components/PageComponents/Restrict/ClientPanelPage/MyRecruitmentsPage";
import WorkerPanelComponent from "../Components/PageComponents/Restrict/WorkerPanelPage/WorkerPanelComponent";
import WorkerResumeComponent from "../Components/PageComponents/Restrict/WorkerPanelPage/WorkerResumeComponent";
import WorkerResumeViewPage from "./WorkerResumeViewPage";
import {useDispatch, useSelector} from "react-redux";
import {StorageVariables} from "../Helpers/StoragesVariables";

const WorkerPanelPage = (props) => {

    const panelStorage = useLocalStorage(StorageVariables.lastPanel);
    const {hasSineService, hasCraftyService, hasClubService} = useVerifyServices();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollToElement('body');
        panelStorage.setPrimitive('worker');
        dispatch({type: 'panel_profile', data: 'worker'});
    },[]);


    return (<Row style={{display: 'flex', justifyContent: 'center', backgroundSize: '100%', backgroundRepeat: 'repeat-y', background: `url(${Images.background_3})`}}>
        <div style={{position: 'absolute', height: '100%', objectFit:'cover', width: '100%', zIndex: 0, background: 'linear-gradient(180deg, #e6e9ef2b 80%, white)'}}/>

        <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17} style={{paddingTop: 20}}>
            <PrivateRoute exact path={'/painel/prestador'} component={WorkerPanelComponent}/>
            <PrivateRoute exact path={'/painel/prestador/perfil'} component={ProfilePage}/>
            <PrivateRoute exact path={'/painel/prestador/notificacoes'} component={NotificationPage}/>
            {hasCraftyService() && <PrivateRoute exact path={'/painel/prestador/enderecos'} component={AddressesPage}/>}
            {hasCraftyService() && <PrivateRoute exact path={'/painel/prestador/avaliacoes/:type'} component={ComplimentsPage}/>}
            {hasSineService() && <PrivateRoute exact path={'/painel/prestador/editar-meu-curriculo'} component={WorkerResumeComponent}/>}
            <PrivateRoute exact path={'/painel/prestador/curriculo-completo'} component={WorkerResumeViewPage}/>
        </Col>

    </Row>)
};
export default WorkerPanelPage;
