import React from 'react'
import {Input} from "antd";

const SyNumberInteger = ({data, setValue, value}) => {

    return (
        <div className='integer'>
            <Input type={'number'}
                   id={data.label_key}
                   value={value}
                   onKeyPress={(e) => !(e.charCode >=48 && e.charCode <=57) && e.preventDefault()}
                   min={0}
                   onChange={(event) => {setValue(event.target.value.length > 0 ? Number(event.target.value) : '')}}
            />
        </div>
    )
}

export default SyNumberInteger
