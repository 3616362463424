import React, {useState} from 'react'
import {Env} from "../../../Helpers/Env";
import {eventVisible} from "../../../Hooks/Helpers";
import {useHistory} from "react-router-dom";
import {Icon} from "antd";
import bannerEvent from '../../../Img/EventImages/month_women/main-banner-cropped.jpeg';
import modalEvent from '../../../Img/EventImages/img_modal_event.png';
import bannerVacancy from '../../../Img/EventImages/month_women/main-banner-cropped.jpeg';

const BannerEvent = (props) => {
    const [showBanner, setShowBanner] = useState(eventVisible());
    const history = useHistory();

    const [activeEventPathname] = useState('/vagas-do-evento')
    const onBannerClick = () => {
        Env.event = true;
        setShowBanner(false);
        history.push(activeEventPathname);
    }

    if (showBanner) {
        return (
            props.isModal ?
                <>
                    {(showBanner && window.location.pathname !== activeEventPathname) &&
                        <div className={'modal-event'}>
                            <div  className={'bannerMain pulse-pink'}>
                                <Icon style={{zIndex: 99999}} className={'icon'} onClick={() =>{ setShowBanner(false) }} type={'close'}/>
                                <img alt={'banner'}
                                     onClick={onBannerClick}
                                     style={{maxHeight: window.innerHeight - 100, maxWidth: '100%'}}
                                     src={props.banner || bannerVacancy}
                                />
                            </div>
                        </div>
                    }
                </>
                :
                <div style={{padding:'0 10px'}} >
                    <img alt={'banner'}
                         onClick={props.onClick ? props.onClick : ((window.location.pathname !== activeEventPathname) && onBannerClick)}
                         style={{width: '100%',
                             objectFit:'cover',
                             borderRadius:10,
                             minHeight:150,
                             cursor: `${window.location.pathname !== '/vagas-do-evento' ? 'pointer' : 'default'}`,
                             ...props.style
                         }}
                         src={props.banner || bannerEvent}
                         className={`cursor-pointer ${props.pulseNone ? '' : 'pulse'}`}
                    />
                </div>
        )
    } else return <></>
}

export default BannerEvent
