import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AutoComplete, Button, Col, Dropdown, Icon, Input, Menu, message, Popover, Row, Tabs, TreeSelect} from 'antd';
import {Fade} from "react-reveal";
import useApi from "../../../../Hooks/Api";
import {useHistory} from "react-router-dom";

import _, {debounce} from "lodash";
import useLocalStorage from "../../../../Hooks/Storage";
import {Texts} from "../../../../Helpers/Texts";
import useVerifyServices from "../../../../Hooks/VerifyServices";
import {checkStateRestriction, showNotification} from "../../../../Hooks/Helpers";
import {Images} from "../../../../Helpers/Images";
import RegisterPageModal from "../../../../Pages/RegisterPageModal";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";
import {useSelector} from "react-redux";

const { TabPane } = Tabs;

const HomeMainComponent = props => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const autocompleteApi = useApi({loadingControl: true, customReturn: true});
    const {hasSineService, hasCraftyService, hasClubService, hasAtLeastTwo, hasOnlyCrafty, hasOnlySine, hasOnlyClub} = useVerifyServices();

    const [actualSubject, setActualSubject] = useState(hasOnlyCrafty() ? 'crafty' :(hasOnlyClub() ? 'club' : (hasOnlySine() ? '-1' : '-1')));
    const [geoLocation, setGeoLocation] = useState('');
    const [spinIcon, setSpinIcon] = useState(0);
    const storage = useLocalStorage(StorageVariables.geolocation);
    const campaign = useLocalStorage(StorageVariables.campaign);

    const [filterEstablishmentsList, setFilterEstablishmentsList] = useState([]);
    const [filterServicesList, setFilterServicesList] = useState([]);

    const [popUp, setPopUp] = useState(true);
    const [filter, setFilter] = useState();
    const api = useApi({customReturn: true});
    const geocode = useRef();
    let filtersQuery = window.queryStringToObject();

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [value, setValue] = useState(<span> <Icon type={'shop'} className={'primary-color'}/> Selecione o serviço Desejado </span>);
    const [auxFade, setAuxFade] = useState(false);

    const [registerModal, setRegisterModal] = useState(false);
    const profileType = useRef('');
    const user = useSelector(state => state).authReducer;

    const onExpand = (expandedKeys,  expanded, node ) => {
        setExpandedKeys(expandedKeys);
    };

    const handleFade = (value) => {
        setAuxFade(false);
        props.setModule(value);
        if(actualSubject !== '-1') {
            setTimeout(() => {
                handleTabs(value);
                setAuxFade(true);
            },[1000]);
        } else {
            handleTabs(value);
            setTimeout(() => setAuxFade(true) ,[500])
        }
    };

    const onSelect = (value, node) => {
        if (node.isLeaf()) {
            setValue(value);
            setIsDropdownVisible(false);
        } else {
            setIsDropdownVisible(true);
            setExpandedKeys(node.props.expanded
                ? expandedKeys.filter(k => k !== node.props.eventKey)
                : expandedKeys.concat(node.props.eventKey))
        }
    };

    const onDropdownVisibleChange = (visible) => {
        setIsDropdownVisible(visible)
    };

    const getGeo = (clicked) => {
        setSpinIcon(1);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(item => {
                let geocoder = new window.google.maps.Geocoder;
                geocoder?.geocode({'location': {lat: item.coords.latitude, lng: item.coords.longitude }}, function(results, status) {
                    if (results && checkStateRestriction(results[0])) {
                        setGeoLocation(results[0]?.formatted_address);
                        storage.setObject({lat: item.coords.latitude, long: item.coords.longitude, loc: results[0].formatted_address, userLat: storage.getObject()?.userLat});
                        setSpinIcon(2);
                    } else {
                        showNotification();
                        setGeoLocation('');
                        setSpinIcon(0);
                    }
                })
            }, () => {
                clicked&& message.info('Parece que seu navegador está bloqueando o acesso a sua localização! ', 5);
                setSpinIcon(0)
            });
        }
    };

    const setAddress = (item) => {
        storage.setObject({lat: item.lat, long: item.long, loc: item.name, userLat: storage.getObject()?.userLat});
        setGeoLocation(item.name)
    }

    const getGeolocation = () => {
        setTimeout(() => {
            const place = geocode.current.getPlace();
            if(checkStateRestriction(place)) {
                storage.setObject({lat: place.geometry.location.lat(), long: place.geometry.location.lng(), loc: place.formatted_address, userLat: storage.getObject()?.userLat});
                setGeoLocation(place.formatted_address);
            } else {
                showNotification();
                setGeoLocation('');
            }
        },[200]);
    }

    const search = () => {
        if (filter !== '' && storage.getObject()?.lat) {
            if (actualSubject === 'crafty') {
                const objToFilter = {occupation_id: filter, lat: storage.getObject().lat, long: storage.getObject().long, loc: storage.getObject().loc};
                filtersQuery = _.pickBy(objToFilter, _.identity);
                const urlParams = new URLSearchParams(filtersQuery);
                history.push(`/quero-contratar/?${urlParams}`);
            }
            else if(actualSubject === 'club') {
                const objToFilter = {groups: filter, lat: storage.getObject().lat, long: storage.getObject().long, loc: storage.getObject().loc};
                filtersQuery = _.pickBy(objToFilter, _.identity);
                const urlParams = new URLSearchParams(filtersQuery);
                history.push(`/estabelecimentos/?${urlParams}`)
            }
            else if(actualSubject === 'jobs') {
                let aux = filter.split(',');
                const objToFilter = {cbo_occupation_id: aux[0], cbo_name: aux[1] , lat: storage.getObject().lat, long: storage.getObject().long, loc: storage.getObject().loc};
                filtersQuery = _.pickBy(objToFilter, _.identity);
                const urlParams = new URLSearchParams(filtersQuery);
                history.push(`/curriculos/?${urlParams}`)
            }
        } else {
            message.info({icon: <Icon type={'exclamation'}/>, content: 'Digite um endereço e escolha uma categoria para continuar'})
        }
    };

    const getServices = async () => {
        api.get('search/occupations', (res) => {
            let treeData = [];

            res.forEach((item) => {
                if(item.occupations.length > 0){
                    treeData.push({
                        value: 'p' + item.group_occupation_id,
                        title: item.group_occupation_name,
                        children: item.occupations.map(subItem => {
                            return {title: subItem.name,isLeaf: true, value: subItem.id}
                        })
                    })
                }
            });
            setFilterServicesList(treeData);
        }, (e) => {});
    };

    const getEstablishments = async () => {
        api.get('crafty-club/search-filter', (res) => {
            let treeData = [];
            res.groups.forEach((item) => {
                treeData.push({
                    value: item.id,
                    title: item.label,
                })
            });
            setFilterEstablishmentsList(treeData);
        },(e) => {});
    }

    const handleTabs = (e) => {
        if(e !== "vacancy") {
            setActualSubject(e);
            setTimeout(() => {
                geocode.current = new window.google.maps.places.Autocomplete(document.querySelector('#geocodeField'+e), {types: ['geocode']});
                geocode.current.addListener('place_changed', getGeolocation);
                geocode.current.setComponentRestrictions({'country': ['br']});
            },[200])
        } else {
            if(user) {
                history.push(`/painel/cliente/vagas`);
            } else {
                setRegisterModal('login')
            }
        }

    };

    const onFilterChange = value => {
        setFilter(value);
    };

    const autoCompleteSearch = useCallback(debounce(async (query) => {
        autocompleteApi.setLoading(true);
        let searchQuery = `?query=${query}&limit=25`;

        if (!autocompleteApi.loading) {
            autocompleteApi.get(`sine/cbo-occupations/autocomplete${searchQuery}`, (res) => {
                setData(res);
            }, (e) => {
                console.log(e);
            });
        }
    }, 700), []);

    const checkCampaign = () => {
        let urlParams = new URLSearchParams(window.location.search)
        let valueCampaign = urlParams.get('campaign')

        if (valueCampaign) {
            campaign.setPrimitive(valueCampaign)
            //history.push('')
        }
    }

    useEffect(() => {
        setFilter('');
        if (actualSubject === 'crafty' && filterServicesList.length === 0) {
            getServices();
        } else if(actualSubject === 'club' && filterEstablishmentsList.length === 0) {
            getEstablishments()
        }
    },[actualSubject]);

    useEffect(() => {
        checkCampaign();
        if (storage.getObject()?.loc && storage.getObject()?.lat && storage.getObject()?.long) {
            setGeoLocation(_.replace(storage.getObject().loc,/\+/g," "));
            setPopUp(false);
        } else {
            getGeo();
        }

        if(actualSubject !== '-1') {
            setAuxFade(true);
        }
    },[]);

    return (
        <div style={{display: 'flex', flexFlow: 'column'}}>
            <RegisterPageModal profile={profileType.current} visible={registerModal !== false} type={registerModal} setVisible={() => setRegisterModal(false)} />
            <div className={'mainComponentHeader'}>
                <div className='transparentBanner'/>
                <Fade when={actualSubject === '-1'} mountOnEnter unmountOnExit> <div className='bannersImages' style={{backgroundImage: `url(${Images.home.bannerHome4})`, backgroundSize: 'cover', zIndex:1}}/> </Fade>
                <Fade when={actualSubject === 'crafty'} mountOnEnter unmountOnExit> <div className='bannersImages' style={{backgroundImage: `url(${Images.home.bannerHome2})`, backgroundSize: 'cover', zIndex:1}}/> </Fade>
                <Fade when={actualSubject === 'club'} mountOnEnter unmountOnExit> <div className='bannersImages' style={{backgroundImage: `url(${Images.home.bannerHome3})`, backgroundSize: 'cover', zIndex:1}}/> </Fade>
                <Fade when={actualSubject === 'jobs'} mountOnEnter unmountOnExit> <div className='bannersImages' style={{backgroundImage: `url(${Images.home.bannerHome1})`, backgroundSize: 'cover',zIndex:1}}/> </Fade>

                {actualSubject === '-1' ?<Col span={24} style={{zIndex: 30, minHeight: 350, alignItems: 'center', display: 'flex'}}>
                        <div className={'mainHomeText'}>
                            <Fade duration={1000} mountOnEnter> <span style={{color: "#333"}}>  {Texts.home_page.main.title1} </span> </Fade>
                            <Fade delay={1000} duration={2500} mountOnEnter> {Texts.home_page.main.subTitle1} </Fade>
                        </div>
                    </Col>
                :
                <Row className='mainComponentInputsContainer'>
                    <Col xs={23} lg={22} xl={18} xxl={15} style={{padding: '14px', borderRadius: '10px', minHeight: 318, backgroundColor: '#ffffffe6'}}>
                        <Col span={24} className={'mainComponentInputsContainerCol'} style={{textAlign: 'center', zIndex: 10}}>
                            {actualSubject === 'crafty' &&  <div className={'textFade'}> <Fade when={auxFade} duration={1000} mountOnEnter>  <img style={{maxHeight: 100, marginBottom: 20}} src={Images.home.search_service_icon}/><div><span style={{fontSize: 24, color: "#333"}}> {Texts.home_page.main.crafty_title} </span> </div></Fade> <Fade when={auxFade} delay={500} duration={1000} mountOnEnter> {Texts.home_page.main.crafty_subTitle} </Fade> </div>}
                            {actualSubject === 'club' &&  <div className={'textFade'}> <Fade when={auxFade} duration={1000} mountOnEnter>  <img style={{width: 70, height: 70, marginBottom: 20}} src={Images.home.resume_icon}/><div><span style={{fontSize: 24, color: "#333"}}> {Texts.home_page.main.club_title} </span></div></Fade> <Fade when={auxFade} delay={500} duration={1000} mountOnEnter> {Texts.home_page.main.club_subTitle} </Fade> </div>}
                            {actualSubject === 'jobs' &&  <div className={'textFade'}> <Fade when={auxFade} duration={1000} mountOnEnter>  <img style={{maxHeight: 100, marginBottom: 20}} src={Images.home.resume_icon}/><div><span style={{fontSize: 24, color: "#333"}}> {Texts.home_page.main.jobs_title} </span></div> </Fade> <Fade when={auxFade} delay={500} duration={1000} mountOnEnter> {Texts.home_page.main.jobs_subTitle} </Fade> </div>}
                        </Col>

                        {actualSubject === 'crafty' &&  <Fade when={auxFade} duration={500}>
                            <Row>
                                <Col xs={24} sm={24} md={11}>
                                    <Input id='geocodeFieldcrafty' style={{borderRadius: 0,textOverflow: 'ellipsis'}} type='text' onChange={(e) => setGeoLocation(e.target.value)} value={geoLocation} placeholder='Digite um Endereço'
                                        prefix={
                                            <Popover
                                                placement='bottomLeft'
                                                content={<span> {storage.getObject()?.userLat? 'Selecione aqui seus endereços cadastrados' : 'Clique aqui para usar minha localização Atual!' }  </span>}
                                                trigger="click"
                                                defaultVisible={storage.getObject()?.loc? false : true}
                                            >
                                                <Dropdown overlay={
                                                    <Menu>
                                                        <Menu.Item onClick={() => getGeo(true)} key="default"> Usar minha localização Atual </Menu.Item>
                                                        {storage.getObject()?.userLat?.map((item, index) =>
                                                            <Menu.Item onClick={() => setAddress(item)} key={index}> {item.name} </Menu.Item>
                                                        )}
                                                    </Menu>
                                                }>
                                                    <Icon onClick={() => getGeo(true)} type={spinIcon === 0? "environment": spinIcon === 1? "loading": "check"} className={'primary-sub-color'} />
                                                </Dropdown>
                                        </Popover>}
                                        className='customInputMainPage' size='large' />
                                </Col>
                                <Col xs={18} sm={16} md={10} style={{marginLeft: '-5pnx'}}>
                                    <TreeSelect
                                        showSearch
                                        style={{ width: '100%' }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        allowClear
                                        treeNodeFilterProp={'title'}
                                        className='customSelectInputMainPage'
                                        treeData={filterServicesList}
                                        searchPlaceholder='Busque pelo serviço'
                                        onChange={onFilterChange}
                                        treeExpandedKeys={expandedKeys}
                                        open={isDropdownVisible}
                                        value={value}
                                        onDropdownVisibleChange={onDropdownVisibleChange}
                                        onTreeExpand={onExpand}
                                        onSelect={onSelect}
                                    />
                                </Col>
                                <Col xs={6} sm={8} md={2}>
                                    <Button icon={'search'} onClick={() => search()} className="mainComponentButton">  Buscar </Button>
                                </Col>
                            </Row>
                         </Fade>}
                        {actualSubject === 'club' && <Fade when={auxFade} duration={500}>
                            <Row>
                                <Col xs={24} xl={24} sm={11} className='adjustPad'>
                                    <Input style={{textOverflow: 'ellipsis'}} id='geocodeFieldclub' type='text' onChange={(e) => setGeoLocation(e.target.value)} value={geoLocation} placeholder='Digite um Endereço'
                                        prefix={
                                            <Popover
                                                placement='bottomLeft'
                                                content={<span> {storage.getObject()?.userLat? 'Selecione aqui seus endereços cadastrados' : 'Clique aqui para usar minha localização Atual!' }  </span>}
                                                trigger="click"
                                                defaultVisible={storage.getObject()?.loc? false : true}
                                            >
                                                <Dropdown overlay={
                                                    <Menu>
                                                        <Menu.Item onClick={() => getGeo(true)} key="default"> Usar minha localização Atual </Menu.Item>
                                                        {storage.getObject()?.userLat?.map((item, index) =>
                                                            <Menu.Item onClick={() => setAddress(item)} key={index}> {item.name} </Menu.Item>
                                                        )}
                                                    </Menu>
                                                }>
                                                    <Icon type={spinIcon === 0? "environment": spinIcon === 1? "loading": "check"}  className={'primary-color'} />
                                                </Dropdown>
                                            </Popover>}
                                        className='customInputMainPage' size='large' />
                                </Col>
                                <Col xs={18} sm={16} md={10} style={{marginLeft: '-5px'}}>
                                    <TreeSelect
                                        showSearch
                                        style={{ width: '100%' }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder={<span> <Icon className={'primary-color'} type={'shop'}/> Selecione o tipo do estabelecimento </span>}
                                        allowClear
                                        treeNodeFilterProp={'title'}
                                        className='customSelectInputMainPage'
                                        treeData={filterEstablishmentsList}
                                        searchPlaceholder='Digite a categoria do Estabelecimento'
                                        onChange={onFilterChange}
                                    />
                                </Col>
                                <Col xs={6} sm={8} md={2}>
                                    <Button icon={'search'} onClick={() => search()} className="mainComponentButton">  Buscar </Button>
                                </Col>
                            </Row>
                         </Fade>}

                        {actualSubject === 'jobs' && <Fade when={auxFade} duration={500}>
                            <Row>
                                <Col xs={24} sm={24} md={11}   className='adjustPad'>
                                    <Input style={{textOverflow: 'ellipsis'}} id='geocodeFieldjobs' type='text' onChange={(e) => setGeoLocation(e.target.value)} value={geoLocation} placeholder='Digite um Endereço'
                                        prefix={<Popover
                                            placement='bottomLeft'
                                            content={<span> {storage.getObject()?.userLat&& 'Selecione aqui seus endereços cadastrados'}  </span>}
                                            trigger="click"
                                            defaultVisible={!!storage.getObject()?.userLat}
                                        >
                                            {!storage.getObject()?.userLat? <div style={{cursor: 'pointer'}} onClick={() => getGeo(true)}>
                                                <Icon type={spinIcon === 0? "environment": spinIcon === 1? "loading": "check"}  className={'primary-color'} />
                                            </div> :
                                                <Dropdown overlay={
                                                    <Menu>
                                                        <Menu.Item onClick={() => getGeo(true)} key="default"> Usar minha localização Atual </Menu.Item>
                                                        {storage.getObject()?.userLat?.map((item, index) =>
                                                            <Menu.Item onClick={() => setAddress(item)} key={index}> {item.name} </Menu.Item>
                                                        )}
                                                    </Menu>
                                                }>
                                                    <Icon type={spinIcon === 0? "environment": spinIcon === 1? "loading": "check"}  className={'primary-color'} />
                                                </Dropdown>
                                            }
                                        </Popover>}
                                        className='customInputMainPage' size='large' />
                                </Col>
                                <Col xs={18} sm={16} md={10} className={'marginLeftHide'} style={{marginLeft: -5, boxShadow: '-2px 3px 4px rgba(0,0,0,.09)'}}>
                                    <AutoComplete
                                        size="large"
                                        className='height100Autocomplete'
                                        style={{ width: '100%'}}
                                        dataSource={data.map(item => {
                                            return (
                                                <AutoComplete.Option key={item.occupation_id+","+item.name} text={item.name}>
                                                    {item.name}
                                                </AutoComplete.Option>
                                            )
                                        })}

                                        onSelect={onFilterChange}
                                        onSearch={autoCompleteSearch}
                                        optionLabelProp="text"
                                    >
                                        <Input className='customInputMainPage'  size={'large'} placeholder={'Digite qual serviço você busca'} prefix={<Icon className={'primary-color'} type={autocompleteApi.loading ? "loading" : "search"} />} />

                                    </AutoComplete>
                                </Col>
                                <Col xs={6} sm={8} md={2}>
                                    <Button icon={'search'} onClick={() => search()} className="mainComponentButton">  Buscar </Button>
                                </Col>
                            </Row>
                         </Fade>}
                    </Col>
                </Row>}

                <div style={{zIndex: 100, width: '100%', display: 'flex'}}>
                    <div style={{width: '100%', justifyContent: 'center', flexWrap: 'wrap', display: 'flex', alignItems: 'stretch', zIndex: 20}}>
                        {hasSineService() && <div className={'homeCards'} onClick={() => handleFade('vacancy')} >
                            <div>
                                <img src={Images.home.establishment_icon}/>
                            </div>
                            <h2> {Texts.home_page.main.card_jobs_title} </h2>
                        </div>}

                        <div className={'homeCards'} onClick={() => handleFade('vacancy')}>
                            <div>
                                <img src={Images.home.megaphone}/>
                            </div>
                            <h2> Quero divulgar uma vaga </h2>
                        </div>

                        <div className={'homeCards'} onClick={() => history.push('/trabalhar')}>
                            <div>
                                <img src={Images.home.resume_card_icon}/>
                            </div>
                            <h2> Quero trabalhar </h2>
                        </div>

                        {hasClubService() &&
                        <div className={'homeCards'} onClick={() => handleFade('club')}>
                            <div>
                                <img src={Images.home.establishment_icon}/>
                            </div>
                            <h2> {Texts.home_page.main.card_club_title} </h2>
                        </div>}
                    </div>
                </div>
            </div>
        </div>

    );
};

HomeMainComponent.propTypes = {};

export default HomeMainComponent;



