import React, {useState,useEffect}    from 'react';
import {Link} from "react-router-dom";
import {Col, Icon, Layout, List, Row} from "antd";
import Fade from "react-reveal/Fade";
import {Images} from "../Helpers/Images";

const PrivacyPage = props => {

    useEffect(() => {
        window.scrollToElement('body');
    },[]);

    return (
        <div>
            <div>
                <div style={{border: 0, boxShadow: 'unset', backgroundColor: 'white', padding: 35}}>
                    <div className="item" style={{textAlign: 'center'}}>
                        <Link to="/"><img style={{height: 100}} src={Images.logo} alt="crafty" className="logo"/></Link>
                    </div>

                    <List className='fatherList'>
                        <List.Item className='aboutMainItemList'> <span style={{fontSize: 22, fontWeight: 'bold'}}> TERMOS DE USO </span> <br/><br/>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> é um programa do Governo do Estado de Rondônia, cuja plataforma tecnológica é de propriedade da empresa Bluetrix Tecnologia Ltda (CNPJ: 25.076.424/0001-60) ("Bluetrix"), tendo por objetivo promover a aproximação entre propensos Usuários contratantes e Usuários candidatos possivelmente interessados em uma oportunidade de empregabilidade oferecida pelo Usuário contratante.
                            <br/>
                            <br/>
                            Os presentes Termos aplicam-se especificamente à plataforma <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , englobando todos os seus subsistemas.
                            <br/>
                            <br/>
                            Estes Termos poderão ser atualizados, a qualquer tempo, pela Bluetrix, mediante aviso no site e/ou por e-mail, se o Usuário tiver optado por receber comunicações da plataforma.
                        </List.Item>
                        <List.Item className='aboutMainItemList'> <span>1. CONDIÇÕES GERAIS DE ACESSO E UTILIZAÇÃO DO GERAÇÃO EMPREGO  </span> <br/>
                            <List className='aboutMainSubItemList' style={{marginTop: 20}} >
                                <List.Item>
                                    <span>1.1 Da utilização do GERAÇÃO EMPREGO   </span> <br/><br/>
                                    O acesso e a utilização do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> pelo Usuário têm, como regra, caráter tanto gratuito quanto oneroso, de acordo com as formas e valores previamente publicados e determinados.
                                </List.Item>
                                <br/>
                                <List.Item>
                                    <span>1.2 Registro de Usuário</span> <br/><br/>
                                    Para utilização da plataforma e funcionalidades do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , será exigida prévia inscrição ou prévio registro do Usuário para acesso. Nestes casos, o acesso à Plataforma <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> somente será liberado após o preenchimento de cadastro pelo Usuário com as informações requeridas.
                                    <br/>
                                    <br/>
                                    Toda informação fornecida pelo Usuário por meio do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> deverá ser verdadeira. Assim, o Usuário garante a autenticidade de todos os dados que informar durante o preenchimento dos respectivos formulários, de modo que o Usuário poderá exercer o seu direito de correção de dados incompletos, inexatos ou desatualizados, para manter toda a informação fornecida à plataforma permanentemente atualizada, de maneira que sempre reflita os dados reais do Usuário.
                                    <br/>
                                    <br/>
                                    Em qualquer circunstância, o Usuário será o único responsável por declarações falsas ou inexatas que prestar e que vierem a causar prejuízos ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> ou a terceiros
                                </List.Item>
                                <br/>
                                <List.Item>
                                    <span>1.3 Termos específicos para utilização dos subsistemas</span> <br/><br/>
                                    Para cada funcionalidade disponibilizada pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> é disponibilizado ao Usuário, de forma clara e objetiva, um termo de uso específico contendo as regras de utilização, documento este cuja aceitação (opt-in) será exigida do Usuário para que usufrua das funcionalidades, enquanto for de seu interesse.
                                </List.Item>
                                <br/>
                                <List.Item>
                                    <span>1.4 Menores de Idade</span><br/><br/>
                                    O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> é direcionado a pessoas capazes e suas funcionalidades e soluções não são direcionadas a e não devem ser utilizadas por Usuários absolutamente incapazes (menores de 16 anos). De modo a evitar o acesso indevido, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> avisa claramente que suas funcionalidades são voltadas a Usuários representantes legais maiores de 16 (dezesseis) anos, desde que emancipados, pelo que será solicitado ao Usuário que indique sua data de nascimento, para fins de verificação da classificação etária.
                                    <br/>
                                    O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , no entanto, sob hipótese alguma, se responsabiliza pelo acesso indevido a sua plataforma, decorrente de informações incorretas ou inverídicas fornecidas pelo Usuário ou do desrespeito à classificação etária indicada.

                                </List.Item>
                                <br/>
                                <List.Item>
                                    <span>1.5 Obrigação de utilizar a plataforma corretamente</span><br/><br/>
                                    Ao utilizar o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , o Usuário se compromete a respeitar a legislação brasileira vigente, bem como todo o quanto disposto nos presentes Termos, não devendo produzir, disponibilizar, divulgar ou transmitir qualquer conteúdo que:
                                    <List className='aboutItemSubList'>
                                        <List.Item value='a)'>
                                            a) Seja contrário a qualquer norma da legislação brasileira, bem como à moral e aos bons costumes geralmente aceitos;

                                        </List.Item>
                                        <List.Item value='b)'>
                                            b) Incentive a discriminação, o ódio ou a violência contra pessoas ou grupos em razão de orientação sexual, gênero, raça, religião, deficiência, nacionalidade ou por qualquer outro motivo;
                                        </List.Item>
                                        <List.Item value='c)'>
                                            c) Incorporem, ponham à disposição ou permitam acessar produtos, elementos, mensagens e/ou serviços ilegais, violentos, pornográficos, degradantes ou, em geral, contrários à lei, à moral e aos bons costumes geralmente aceitos ou à ordem pública;
                                        </List.Item>
                                        <List.Item  value='d)'>
                                            d) Sejam falsos, ambíguos, inexatos, exagerados ou extemporâneos, de forma que possam induzir a erro sobre seu objeto ou sobre as intenções ou propósitos do comunicante;
                                        </List.Item>
                                        <List.Item value='e)'>
                                            e) Sejam protegidos por quaisquer direitos de propriedade intelectual ou industrial pertencentes a terceiros, sem que o Usuário tenha obtido previamente dos seus titulares a autorização necessária para levar a cabo o uso que efetuar ou pretender efetuar;
                                        </List.Item>
                                        <List.Item value='f)'>
                                            f) Incorporem vírus ou outros elementos físicos ou eletrônicos que possam causar dano ou impedir o normal funcionamento da rede, do sistema ou de equipamentos informáticos (“hardware” e “software”) de terceiros, ou que possam causar dano aos documentos eletrônicos e arquivos armazenados nestes equipamentos informáticos;
                                        </List.Item>
                                        <List.Item value='g)'>
                                            g) Provoquem, por suas características (tais como forma, extensão etc.) dificuldades no normal funcionamento do Serviço;
                                        </List.Item>
                                        <List.Item value='g)'>
                                            h) Envios de materiais não solicitados às pessoas físicas, Usuários candidatos, sejam eles cadastrados no SINE (Sistema Nacional de Empregos), SINE Rondônia/SEDI (Superintendência estadual de Desenvolvimento Econômico e Infraestrutura) ou diretamente no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>, possivelmente interessadas em uma oportunidade de empregabilidade oferecida pelo Usuário contratante; ou,
                                        </List.Item>
                                        <List.Item value='g)'>
                                            i) Utilizar os dados de contato das pessoas físicas, Usuários candidatos, sejam eles cadastrados no SINE (Sistema Nacional de Empregos), SINE Rondônia/SEDI (Superintendência estadual de Desenvolvimento Econômico e Infraestrutura) ou diretamente no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>, possivelmente interessadas em uma oportunidade de empregabilidade oferecida pelo Usuário contratante, para utilização divergentes à que se propõe a plataforma <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>.
                                        </List.Item>
                                    </List>
                                    <br/>
                                    Em qualquer circunstância, o Usuário é o único responsável pelo uso que fizer do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , bem como por qualquer conteúdo por ele inserido.
                                </List.Item>
                                <br/>
                                <List.Item>
                                    <span>1.6 Utilização Correta do Conteúdo e Propriedade Intelectual</span><br/><br/>
                                    O Usuário contratante se compromete a utilizar o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , notadamente os dados de pessoas físicas possivelmente interessadas por oportunidades de empregabilidade por ele publicadas, de acordo com todo o ordenamento jurídico, com a moral e os bons costumes geralmente aceitos, com os presentes Termos e com as Condições Particulares de cada Página.
                                    <br/>
                                    <br/>
                                    Os conteúdos disponibilizados ao Usuário contratante que envolvam dados pessoais, sensíveis ou não, de Usuários candidatos possivelmente interessados em oportunidades de empregabilidade oferecidas por ele deverão ser utilizadas com esta estrita funcionalidade, vedado a replicação ou reprodução para integrar base de dados própria do Usuário contratante, podendo o Usuário estar sujeitos às sanções civis e criminais correspondentes, nos termos das Leis 9.610/98, 9.609/98 e 9.279/96.
                                    <br/>
                                    <br/>
                                    Os demais conteúdos, exceto os dados pessoais disponibilizados através do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>, são de propriedade do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , de seus titulares ou de terceiros que autorizaram sua utilização no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , e estão protegidos por leis e tratados internacionais, sendo vedada sua cópia, reprodução, ou qualquer outro tipo de utilização, ficando os infratores sujeitos às sanções civis e criminais correspondentes, nos termos das Leis 9.610/98, 9.609/98 e 9.279/96.
                                    <br/>
                                    <br/>
                                    O Usuário contratante deverá se abster de obter, ou de tentar obter, os Conteúdos por meios distintos daqueles que, em cada caso, tenham sido colocados à disposição para tais propósitos
                                </List.Item>
                                <br/>
                                <List.Item>
                                    <span>1.7 Política Anti-Spamming do GERAÇÃO EMPREGO  </span><br/><br/>
                                    Por meio deste documento o Usuário contratante se obriga a abster-se de:
                                    <List className='aboutItemSubList'>
                                        <List.Item  value='g)'>
                                            a) obter dados com finalidade publicitária e remeter publicidade de qualquer classe com finalidade de venda ou outras de natureza comercial sem a prévia solicitação e consentimento do Usuário candidato;
                                        </List.Item>
                                        <List.Item value='a)'>
                                            b) remeter a um grupo de pessoas quaisquer mensagens não solicitadas nem consentidas previamente;
                                        </List.Item>
                                        <List.Item value='b)'>
                                            c) enviar cadeias de mensagens eletrônicas não solicitadas nem previamente consentidas;
                                        </List.Item>
                                        <List.Item  value='c)'>
                                            d) utilizar listas de distribuição a que se pode ter acesso através do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> para a realização das atividades descritas nos itens acima; e
                                        </List.Item>
                                        <List.Item  value='d)'>
                                            e) colocar à disposição de terceiros, com qualquer finalidade, dados captados a partir do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>.
                                        </List.Item>
                                    </List>
                                    <br/>
                                    O Usuário candidato ou terceiros prejudicados pela recepção de mensagens não solicitados, relacionadas ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> e dirigidas a pluralidade de pessoas poderão efetuar sua reclamação por meio do e-mail contato@geracaoemprego.ro.gov.br.
                                </List.Item>

                                <List.Item>
                                    <span>1.8 Introdução de “hiperlinks” que permitam o acesso ao GERAÇÃO EMPREGO  </span><br/><br/>
                                    O Usuário que se propuser a estabelecer hiperlink entre sua página web ou ainda compartilhar links do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> (“Hiperlink”) deverá cumprir as seguintes condições:

                                    <List className='aboutItemSubList'>
                                        <List.Item  value='g)'>
                                            a) o “Hiperlink” permitirá única e exclusivamente o acesso à homepage (página de início do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> ) ou à página específica do conteúdo que se queira interligar, mas não poderá reproduzi-la de qualquer forma;
                                        </List.Item>
                                        <List.Item value='a)'>
                                            b) não declarar nem fazer entender que o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> teria autorizado o “Hiperlink” ou que teria supervisionado ou assumido, sob qualquer forma, responsabilidade sobre os serviços oferecidos ou colocados à disposição na página web em que for estabelecido o “Hiperlink”;
                                        </List.Item>
                                        <List.Item value='b)'>
                                            c) com exceção dos sinais que formarem parte do “Hiperlink”, a página web em que se estabelecer o “Hiperlink” não conterá nenhuma marca, nome comercial, logotipo, slogan, look and feel ou quaisquer outros sinais pertencentes ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> ;
                                        </List.Item>
                                        <List.Item  value='c)'>
                                            d) a página web em que estabelecer o “Hiperlink” não deverá conter informações ou conteúdos ilícitos, contrários à moral, aos bons costumes ou à ordem pública, bem como não conterá conteúdos contrários aos direitos de terceiros.
                                        </List.Item>
                                        <List.Item  value='d)'>
                                            e) não criar um frame sobre o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , exceto se houver autorização expressa que possibilite inclusive a interligação via interface de programação de aplicações (“API”);
                                        </List.Item>
                                        <List.Item  value='d)'>
                                            f) não realizar manifestações ou indicações falsas, inexatas ou incorretas sobre o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , seus administradores, empregados, ou Plataformas a ele vinculadas.
                                        </List.Item>
                                    </List>
                                    <br/>
                                    A disponibilização de um “Hiperlink” não implica, em qualquer hipótese, a existência de relações entre a <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> e o proprietário da página web que o contiver, nem a aceitação ou aprovação da <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> acerca de seus conteúdos ou serviços.
                                </List.Item>

                            </List>
                        </List.Item>
                        <br/>
                        <List.Item className='aboutMainItemList'><span>2 DURAÇÃO E FINALIZAÇÃO</span>
                            <br/><br/>
                            O acesso ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> é concedido aos Usuários por prazo indeterminado, podendo, no entanto, ser disponibilizado de maneira temporária, de acordo com as campanhas de empregabilidade lançadas pelo Poder Público. Ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , no entanto, é facultado dar por terminado, suspender ou interromper unilateralmente, a qualquer momento e sem necessidade de prévio aviso, o acesso à plataforma, sem que qualquer indenização seja devida ao Usuário.
                            <br/><br/>
                            A Bluetrix se reserva, ainda, no direito de recusar ou retirar o acesso ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , a qualquer momento e sem necessidade de prévio aviso, por iniciativa própria ou por exigência de um terceiro, àqueles Usuários que descumprirem estes Termos. Nesta hipótese, caso hajam créditos, valores pecuniários ou quaisquer outros ativos equivalentes em poder do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>, ainda que somente dados pessoas sensíveis ou não de acordo com a política de privacidade, estes serão devolvidos aos seus titulares, em até 30 dias úteis.
                        </List.Item>
                        <br/>
                        <List.Item  className='aboutMainItemList'><span>3 EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</span>
                            <br/><br/>
                            Em decorrência de questões técnicas e operacionais, a <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> não pode garantir a sua disponibilidade, infalibilidade e continuidade de funcionamento, nem garante a utilidade do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> para a realização de qualquer atividade em concreto. Desse modo, a Bluetrix não será, em hipótese alguma, responsável por quaisquer danos decorrentes da interrupção de seu acesso, ou por falhas em seu funcionamento, nem se responsabiliza pela defraudação da utilidade que os Usuários possam ter atribuído à plataforma, pela sua falibilidade, nem por qualquer dificuldade de acesso.
                            <br/><br/>
                            A Bluetrix utiliza as melhores práticas recomendadas de mercado para manter seguros todos os dados inseridos por seus Usuários, no entanto não garante que terceiros não autorizados não farão uso de meios ilícitos para obter indevidamente tais informações.
                            <br/><br/>
                            Assim, a Bluetrix se exime de responsabilidade pelos danos e prejuízos de toda natureza que possam decorrer do conhecimento que terceiros não autorizados possam ter de quaisquer das informações repassadas, em decorrência de falha exclusivamente atribuível aos Usuários ou a terceiros que fujam a qualquer controle razoável da Bluetrix.
                            <br/><br/>
                            A Bluetrix não garante a ausência de vírus, bem como de outros elementos nocivos que possam produzir alterações nos sistemas informáticos dos Usuários (software e hardware) ou nos documentos eletrônicos armazenados no sistema informático, eximindo-se de qualquer responsabilidade pelos danos e prejuízos que possam decorrer de quaisquer elementos nocivos.
                            <br/><br/>
                            A Bluetrix não verifica ou controla o uso que os Usuários fazem de sua plataforma. Dessa forma, a Bluetrix se exime de qualquer responsabilidade pelos danos e prejuízos de toda natureza que possam decorrer de sua utilização ou que possam ser devidos à falta de veracidade, vigência, completude e/ou autenticidade do conteúdo lá disponibilizado, bem como da informação que os Usuários proporcionam a outros Usuários sobre si mesmos e, em particular, ainda que não de forma exclusiva, pelos danos e prejuízos de toda a natureza que possam ser devidas à suplantação da personalidade efetuada por um Usuário em qualquer classe de comunicação realizada por meio do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> .
                        </List.Item>
                        <br/>
                        <List.Item  className='aboutMainItemList'>
                            <span>4 PROCEDIMENTO EM CASO DE REALIZAÇÃO DE ATIVIDADES DE CARÁTER ILÍCITO</span>
                            <br/><br/>
                            Na hipótese de um Usuário ou um terceiro considerar que existem informações, fatos ou circunstâncias que constituem atividade ilícita no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , especialmente em caso de violação de direitos de propriedade intelectual ou outros direitos, deverá enviar comunicação à Bluetrix contendo os seguintes dados:
                            <List className='aboutMainSubItemList'>
                                <List.Item value='a)'>
                                    a) dados pessoais: nome, endereço, número de telefone e endereço de correio eletrônico do reclamante;

                                </List.Item>
                                <List.Item  value='b)'>
                                    b) especificação da suposta atividade ilícita ocorrida no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> e, em particular, quando se tratar de suposta violação de direitos autorais, indicação precisa e completa dos conteúdos protegidos e supostamente infringidos;
                                </List.Item>
                                <List.Item   value='c)'>
                                    c) fatos ou circunstâncias que revelam o caráter ilícito de tal atividade; e
                                </List.Item>
                                <List.Item value='d)'>
                                    d) se for o caso, declaração expressa e clara de que a utilização dos conteúdos foi realizada sem o consentimento do titular dos direitos de propriedade intelectual supostamente infringidos.
                                </List.Item>
                            </List>
                            <br/>
                            Sempre de acordo com a legislação em vigor e com as circunstâncias técnicas de convênios entre o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> e parceiros, a partir da ciência, a Bluetrix poderá excluir conteúdo acusado como ilícito, assim como fornecer informações às autoridades competentes, mediante o competente requerimento, com a finalidade de viabilizar a apuração de condutas ilegais.
                        </List.Item>
                        <br/>
                        <List.Item className='aboutMainItemList'> <span>5 DISPOSIÇÕES GERAIS</span> <br/><br/>
                            Se qualquer parte destes Termos for considerada inválida ou inexequível, tal trecho deve ser interpretado de forma consistente com a lei aplicável, para refletir, na medida do possível, a intenção original das partes, e as demais disposições permanecerão em pleno vigor e efeito.
                            <br/><br/>
                            A falha do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> em exigir quaisquer direitos ou disposições dos presentes Termos não constituirá renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.
                        </List.Item>
                        <List.Item className='aboutMainItemList'> <span>6 NOTIFICAÇÕES</span> <br/><br/>
                            Todas as notificações e comunicações (doravante, as “Notificações”) por parte dos Usuários ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> são consideradas eficazes, para todos os efeitos, quando forem dirigidas por meio do e-mail contato@geracaoemprego.ro.gov.br, e suas respostas serão efetuadas ao e-mail remetente ou através das seguintes formas:
                            <br/><br/>
                            <List className='aboutMainSubItemList'>
                                <List.Item value='a)'>
                                    a) envio de carta ao domicílio do Usuário quando este tiver fornecido um endereço válido ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> ;
                                </List.Item>
                                <List.Item  value='b)'>
                                    b) envio de mensagem por correio eletrônico a qualquer dos endereços fornecidos pelo Usuário;
                                </List.Item>
                                <List.Item   value='c)'>
                                    c) comunicação telefônica ao número fornecido pelo Usuário; e,  </List.Item>
                                <List.Item value='d)'>
                                    d) mediante a inclusão de mensagens em qualquer área da plataforma do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> .
                                </List.Item>
                            </List>
                            Neste sentido, todas as Notificações que o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> realizar serão consideradas válidas quando efetuadas empregando os dados e através dos meios anteriormente destacados. Para estes efeitos, o Usuário declara que todos os dados fornecidos são válidos e corretos, comprometendo-se a comunicar ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> todas as mudanças relativas aos dados de notificação.
                        </List.Item>

                        <List.Item className='aboutMainItemList'> <span>7 LEGISLAÇÃO E FORO APLICÁVEIS </span> <br/><br/>
                            Esses Termos serão regidos, interpretados e executados de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro da Comarca de Santana do Parnaíba (SP), para dirimir qualquer dúvida decorrente deste instrumento.
                            <br/><br/><strong style={{fontWeight: 'bold'}}> Última atualização: 30 de Agosto de 2021. </strong>
                        </List.Item>
                    </List>
                </div>
            </div>

            <div>
                <div style={{border: 0, boxShadow: 'unset', backgroundColor: 'white', padding: 35}}>
                    <div className="item" style={{textAlign: 'center'}}>
                        <Link to="/"><img style={{height: 100}} src={Images.logo} alt="crafty" className="logo"/></Link>
                    </div>

                    <List className='fatherList'>
                        <List.Item className='aboutMainItemList'> <span style={{fontSize: 22, fontWeight: 'bold'}}> POLÍTICA DE PRIVACIDADE</span> <br/><br/>
                            A empresa Bluetrix Tecnologia Ltda (CNPJ: 25.076.424/0001-60), com sede em Santana de Parnaíba / SP (“Bluetrix”), detentora da Plataforma <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> (“<strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>”), entende como sensível e relevante os registros eletrônicos e dados pessoais deixados por você (“Usuário”) na utilização desta e de outros serviços e plataformas de sua propriedade (“Plataformas”), servindo a presente Política de Privacidade (“Política”) para regular, de forma simples, transparente e objetiva, quais dados e informações serão obtidos, assim como quando os mesmos poderão ser utilizados.
                            <br/>
                            <br/>
                            A presente Política se aplica especificamente ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , englobando todos os seus subsistemas.
                            <br/>
                            <br/>
                            Para melhor ilustrar a forma como realizamos o tratamento de dados, apresentamos um resumo de nossa Política de Privacidade e Proteção de Dados Pessoais (“Política”):
                            <br/>
                            <br/>

                            <div style={{marginLeft: 17}}>
                                <strong style={{fontWeight: 'bold', marginLeft: -14}}> QUADRO DE RESUMO </strong>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={8} style={{placeSelf: 'center'}}>
                                        Agente de tratamento
                                    </Col>
                                    <Col span={14} style={{borderLeft: '1px solid gainsboro', padding: 15}}>
                                        Bluetrix Tecnologia Ltda. <br/>
                                        CNPJ: 25.076.424/0001-60<br/>
                                        Endereço:  AV YOJIRO TAKAOKA, 4384, SALA 701 CJ 5605, na cidade de Santana do Parnaíba/SP<br/>
                                    </Col>
                                </Row>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={8} style={{placeSelf: 'center'}}>
                                        Papel no tratamento
                                    </Col>
                                    <Col span={14} style={{borderLeft: '1px solid gainsboro', padding: 15}}>
                                        Predominantemente Processadora
                                    </Col>
                                </Row>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={8} style={{placeSelf: 'center'}}>
                                        Natureza dos dados tratados
                                    </Col>
                                    <Col span={14} style={{borderLeft: '1px solid gainsboro', padding: 15}}>
                                        Dados pessoais, inclusive sensíveis, coletados diretamente do titular de dados ou compartilhados pelo sistema SINE/ME, através de convênio CODEFAT 826/2019.
                                    </Col>
                                </Row>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={8} style={{placeSelf: 'center'}} >
                                        Finalidade como processadora
                                    </Col>
                                    <Col span={14} style={{borderLeft: '1px solid gainsboro', padding: 15}}>
                                        Utilizar dados pessoais, sensíveis ou não, advindos da base do órgão público a que estiver vinculado ou do SINE/ME para fins de disponibilização aos recrutadores e empregadores cadastrados no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>, com a comprovação auditável do(s) interessados(s) e da justificativa do ciclo do dado acessado, desde o acesso, utilização, finalidade, anonimização e eliminação.
                                    </Col>
                                </Row>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={8} style={{placeSelf: 'center'}} >
                                        Base Legal
                                    </Col>
                                    <Col span={14} style={{borderLeft: '1px solid gainsboro', padding: 15}}>
                                        <strong> Contrato 553/PGE-2021 de 30/08/2021 </strong>
                                        <br/>
                                        Resolução CODEFAT 826/2019.
                                        Dados de identificação digital: Art. 15, Marco Civil da Internet.
                                        Dados cadastrais (Enquanto durar a relação e não houver pedido de apagamento ou revogação de consentimento): artigo 7º; III; art. 9, II; art. 26, inciso IV, §1º todos da Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados – LGPD”)
                                    </Col>
                                </Row>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={8} style={{placeSelf: 'center'}}>
                                        Compartilhamento
                                    </Col>
                                    <Col span={14} style={{borderLeft: '1px solid gainsboro', padding: 15}}>
                                        Pessoas Físicas e Jurídicas, públicas ou privadas, com finalidade restrita a oferecimento de oportunidades de empregabilidade e correlatas. Com outros programas de governo do Estado de Rondônia, visando otimizar e integrar os serviços públicos, respeitando as finalidades específicas de cada programa e conforme as normas da Lei Geral de Proteção de Dados Pessoais (LGPD), garantindo o consentimento do titular e a segurança e privacidade das informações.                                    </Col>
                                </Row>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={8} style={{placeSelf: 'center'}}>
                                        Proteção de Dados
                                    </Col>
                                    <Col span={14} style={{borderLeft: '1px solid gainsboro', padding: 15}}>
                                        Medidas de segurança, técnicas e administrativas adequadas, de acordo com as melhores práticas.
                                    </Col>
                                </Row>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={8} style={{placeSelf: 'center'}}>
                                        Seus direitos
                                    </Col>
                                    <Col span={14} style={{borderLeft: '1px solid gainsboro', padding: 15}}>
                                        Confirmação da existência de tratamento, acesso, correção e apagamento
                                    </Col>
                                </Row>
                            </div>
                            <br/>
                            Esta Política poderá ser atualizada, a qualquer tempo, pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , mediante aviso no site e/ou por e-mail, se o Usuário tiver optado por receber comunicações do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> .

                        </List.Item>

                        <List.Item className='aboutMainItemList'> <span>1.  QUAIS DADOS UTILIZAMOS</span> <br/> <br/>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> poderá coletar as informações inseridas ativamente pelo Usuário no momento do cadastro e, ainda, informações coletadas automaticamente quando da utilização do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> e seus subsistemas, bem como da rede, como, por exemplo, identificação do estabelecimento comercial utilizado, IP com data e hora da conexão, entre outras.
                            <br/><br/>
                            Há, assim, o tratamento de dois tipos de dados pessoais: (i) aqueles fornecidos pelo próprio Usuário; e (ii) aqueles coletados automaticamente, através de convênios realizados pelo Poder Público.
                            <br/><br/>
                            <strong style={{ fontWeight: 'bold'}}> (i) Informações fornecidas pelo Usuário: </strong> o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> coleta todas as informações inseridas ativamente pelo Usuário em sua plataforma, tais como e não se limitando a nome completo, CPF, endereço, e-mail, número de telefone celular, gênero, data de nascimento, cidade e estado, onde trabalha, cargo, pretensão salarial, informações sobre disponibilidade, histórico profissional e dados de saúde, quando do preenchimento de formulários no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> pelo Usuário. O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> fará uso dessas informações para viabilizar a comprovação ao Poder Público acerca da utilização dos dados disponíveis em sua base, possibilitando, com isso, a auditoria e comprovação de sua utilização, em todo o ciclo de usabilidade.
                            <br/><br/>
                            <strong style={{ fontWeight: 'bold'}}> (ii) Dados coletados automaticamente: </strong> o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> também coleta uma série de informações de modo automático, tais como, mas não se limitando a: características do dispositivo de acesso, do navegador, IP (com data e hora), porta lógica de origem do IP, informações sobre cliques, páginas acessadas, as páginas seguintes acessadas após a saída das Páginas, ou qualquer termo de procura digitado nos sites ou em referência a estes, dentre outros. Para tal coleta, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> fará uso de algumas tecnologias padrões, como cookies, pixel tags, beacons e local shared objects, que são utilizadas com o propósito de melhorar a experiência de navegação do Usuário no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , de acordo com seus hábitos e suas preferências.
                            <br/><br/>
                            É possível desabilitar, por meio das configurações de seu navegador de internet, a coleta automática de informações por meio de algumas tecnologias, como cookies e caches, bem como em nosso próprio website, especificamente quanto aos cookies. No entanto, o Usuário deve estar ciente de que, se desabilitadas estas tecnologias, alguns recursos oferecidos pelo site, que dependem do tratamento dos referidos dados, poderão não funcionar corretamente.
                            <br/><br/>
                            Além disso, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> poderá promover pesquisas de satisfação e de preferência durante sua utilização, coletando estas informações para melhorar cada dia mais os serviços, para compreender melhor as suas preferências e avaliar a qualidade e suas impressões sobre as soluções ofertadas, bem como para fins estatísticos e publicitários. Para esta última hipótese, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> disponibiliza funcionalidades para que o Usuário autorize (ou não) o tratamento de seus dados, sendo certo que tal aceitação não é condição para cadastramento do Usuário.
                            <br/><br/>
                            As informações coletadas poderão ser compartilhadas pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> nas seguintes hipóteses: (i) com demais órgãos públicos e parceiros, quando forem necessárias para a adequada prestação dos serviços objeto de suas atividades; (ii) para proteção dos interesses do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> em qualquer tipo de conflito; (iii) mediante decisão judicial ou requisição de autoridade competente.
                            <br/><br/>
                            Ainda, no âmbito interno esclarecemos que suas informações também poderão ser compartilhadas com empresas provedoras de infraestrutura tecnológica e operacional necessária para as atividades do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , como intermediadoras de pagamento e provedores de serviço de armazenamento de informações.
                            <br/><br/>
                            O Usuário poderá acessar, atualizar e adicionar seus dados, bem como poderá solicitar a exclusão dos seus dados coletados pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , através do formulário disponível em sua área logada no sistema e também através do e-mail contato@geracaoemprego.ro.gov.br. Iremos nos esforçar para respondê-lo no menor tempo possível, respeitando-se os prazos de guarda estabelecidos pela legislação.

                        </List.Item>
                        <br/>
                        <List.Item className='aboutMainItemList'><span>2 COMO UTILIZAMOS OS DADOS</span>
                            <br/><br/>
                            As informações coletadas pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> têm como finalidade o estabelecimento de vínculo contratual ou a gestão, administração, prestação, ampliação e melhoramento do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> tanto ao usuário, quanto perante este e o Poder Público e empresas eventualmente interessadas na contratação do Usuário, visando cumprir os princípios norteadores não só da administração pública, mas também do acesso à empregabilidade.
                            <br/><br/>
                            As informações coletadas poderão, ainda, ser utilizadas tanto para fins publicitários, como para o envio de informações de novas campanhas, produtos e benefícios do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , bem como a divulgação de eventos, ou para a realização de pesquisa de satisfação, mediante o consentimento do titular para tal.
                            <br/><br/>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> poderá centralizar as informações coletadas, as quais poderão ser utilizadas em seus respectivos subsistemas, respeitadas as finalidades ora dispostas e o consentimento do Usuário.
                            <br/><br/>
                            Caso não deseje mais receber informativos publicitários do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , ou de seus respectivos produtos, soluções e plataformas, a qualquer momento o Usuário pode contatar o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> através formulário disponível em sua área logada no sistema e também através do e-mail contato@geracaoemprego.ro.gov.br .
                            <br/><br/>
                            Nestes casos, o tratamento de dados é autorizado pelo inciso III do artigo 7º, bem como do inciso IV, §1º do art. 26, ambos da Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados – LGPD”).

                        </List.Item>
                        <br/>
                        <List.Item  className='aboutMainItemList'><span>3 COMO UTILIZAMOS OS COOKIES</span>
                            <br/><br/>
                            Cookies são arquivos ou informações que podem ser armazenadas em seus dispositivos, quando você visita o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> . Geralmente, um cookie contém o nome do site que o originou, seu tempo de vida e um valor, que é gerado aleatoriamente
                            <br/><br/>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> utiliza cookies para facilitar o uso e melhor adaptar o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> aos seus interesses e necessidades, bem como para compilarmos informações sobre a utilização de nossos subsistemas, sites e serviços, auxiliando a melhorar suas estruturas e seus conteúdos. Os cookies também podem ser utilizados para acelerar suas atividades e experiências futuras nas Páginas.
                            <br/><br/>

                            <div style={{marginLeft: 17}}>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={6} style={{placeSelf: 'center', fontWeight: 'bold'}}>
                                        Tipos de Cookies
                                    </Col>
                                    <Col span={12} style={{fontWeight: 'bold', borderLeft: '1px solid gainsboro', borderRight: '1px solid gainsboro', padding: 15}}>
                                        O que eles fazem?
                                    </Col>
                                    <Col span={6} style={{fontWeight: 'bold', placeSelf: 'center'}}>
                                        Espécies
                                    </Col>
                                </Row>

                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={6} style={{placeSelf: 'center'}}>
                                        Necessários
                                    </Col>
                                    <Col span={12} style={{borderLeft: '1px solid gainsboro', borderRight: '1px solid gainsboro', padding: 15}}>
                                        Esses cookies nos ajudam a entender como os visitantes interagem com o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , fornecendo informações sobre as áreas visitadas, o tempo de visita ao site e quaisquer problemas encontrados, como mensagens de erro.
                                    </Col>
                                    <Col span={6} style={{placeSelf: 'center'}}>
                                        _ga; _gid; _gat_UA-103613208-1; _gat_UA-11684720-3
                                    </Col>
                                </Row>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={6} style={{placeSelf: 'center'}}>
                                        Funcionais
                                    </Col>
                                    <Col span={12} style={{borderLeft: '1px solid gainsboro', borderRight: '1px solid gainsboro', padding: 15}}>
                                        Esses cookies permitem que o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> se lembre de suas escolhas, para proporcionar uma experiência mais personalizada. Também, possibilitam que os Usuários assistam a vídeos e utilizem ferramentas sociais, campos para comentários, fóruns, entre outros.
                                    </Col>
                                    <Col span={6} style={{placeSelf: 'center'}}>
                                        Bluetrix_idade_verificada; contrast-theme, increased-font
                                    </Col>
                                </Row>
                                <Row style={{border: '1px solid gainsboro', display: 'flex', width: '100%'}} gutter={32}>
                                    <Col span={6} style={{placeSelf: 'center'}}>
                                        Marketing
                                    </Col>
                                    <Col span={12} style={{borderLeft: '1px solid gainsboro', borderRight: '1px solid gainsboro', padding: 15}}>
                                        Esses cookies são utilizados para fornecer mais conteúdo relevante e do interesse dos Usuários. Podem ser utilizados para apresentar publicidade mais direcionada ou limitar o número que esta é veiculada, no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> . Também, permitem a medição da eficácia de uma campanha publicitária do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> .
                                        <br/><br/>
                                        Ainda, esses cookies podem ser utilizados para indicar ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> os sites que o Usuário visitou, podendo compartilhar estas informações com terceiros, tais como agências publicitárias contratadas
                                    </Col>
                                    <Col span={6} style={{placeSelf: 'center'}}>
                                        GPS; IDE; PREF; VISITOR_INFO1_LIVE; YSC; _fbp; __trf.src; _gcl_au; _kuid_; _td; _td_global; fr
                                    </Col>
                                </Row>
                            </div>
                            <br/>
                            Após o Usuário consentir para a utilização de cookies, quando do uso do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , será armazenado um cookie em seu dispositivo para lembrar disso na próxima sessão.
                            <br/><br/>
                            A qualquer momento, o Usuário poderá revogar seu consentimento quanto aos cookies, pelo que deverá apagar os cookies do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> utilizando as configurações de seu navegador de preferência. Para mais informações sobre como proceder em relação à gestão dos cookies nos navegadores:
                            <br/><br/>

                            <strong style={{fontWeight: 'bold'}}> Internet Explorer: </strong>
                            https://support.microsoft.com/pt-br/help/17442/windows-internetexplorer-delete-manage-cookies
                            <br/><br/>
                            <strong style={{fontWeight: 'bold'}}> Mozilla Firefox: </strong>
                            https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-queos-sites-usam
                            <br/><br/>
                            <strong style={{fontWeight: 'bold'}}> Google Chrome: </strong>
                            https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR
                            <br/><br/>
                            <strong style={{fontWeight: 'bold'}}> Safari: </strong>
                            https://support.apple.com/pt-br/guide/safari/sfri11471/mac

                            <br/><br/>
                            Por fim, lembramos que, caso o Usuário não aceite alguns cookies do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>, certos serviços poderão não funcionar de maneira ideal.

                        </List.Item>
                        <br/>
                        <List.Item  className='aboutMainItemList'>
                            <span>4 COM QUEM (OU DE QUEM) COMPARTILHAREMOS OS DADOS</span>
                            <br/><br/>
                            4.1 O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> atua mediante convênio com o Poder Público, utilizando-se inclusive como base o Sistema Nacional de Emprego – SINE/ME. Deste modo, poderá tanto obter dados diretamente das pessoas físicas interessadas em vagas de emprego ofertadas pelos Usuários contratantes/recrutadores, quanto das bases de dados dos órgãos e entidades públicas que realizem ações de empregabilidade, podendo ainda compartilhar as informações coletadas nas seguintes hipóteses:
                            <br/><br/>
                            <List className='aboutItemSubList'>
                                <List.Item value='a)'>
                                    i. Para proteção dos interesses do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> em qualquer tipo de conflito, incluindo ações judiciais;
                                </List.Item>
                                <List.Item value='b)'>
                                    ii. Para realização de novas campanhas, temporárias ou permanentes, promovidas pelo Poder Público, baseada em normas ou convênios específicos;
                                </List.Item>
                                <List.Item value='c)'>
                                    iii. Para continuidade dos serviços, no caso de transações e alterações societárias envolvendo o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>;
                                </List.Item>
                                <List.Item value='d)'>
                                    iv. Para pessoas jurídicas de direito privado, mediante o consentimento do titular do dado, para oferta de vagas de emprego, bem como de cursos profissionalizantes, visando viabilizar entrevistas, ofertar vagas de emprego, capacitação e eventuais outras vantagens ao Usuário. Nesta hipótese, uma vez consentido e autorizado o compartilhamento do dado pessoal com pessoas jurídicas de direito privado, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> não será responsável pelas Políticas de Privacidade e Tratamento de Dados de terceiros e nem pelo conteúdo de quaisquer websites, conteúdos ou serviços ligados a ambientes que não os do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>.
                                </List.Item>
                                <List.Item value='f)'>
                                    v. Para atender autoridades constituídas, mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para sua requisição.
                                </List.Item>
                                <List.Item value='g)'>
                                    vi. Para a portabilidade de dados pessoais, no caso de solicitação de portabilidade de dados solicitada por seu titular, assim entendido como o próprio Usuário, a outras plataformas de interesse deste, nos termos do art. 18, V da Lei 13.709/18.
                                </List.Item>
                            </List>
                            <br/><br/>
                            4.2 Processamento por terceiros sob diretriz do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>.  Caso empresas terceirizadas realizem, em nome do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>, o Tratamento de quaisquer Dados Pessoais que coletamos, elas respeitarão as condições aqui estipuladas e as normas de segurança da informação, obrigatoriamente.
                            <br/><br/>
                            4.3 Os dados dos usuários (pessoas físicas e jurídicas, públicas ou privadas) desta plataforma poderão ser compartilhados com outros programas de governo do Estado de Rondônia. Este intercâmbio de informações busca otimizar e integrar os serviços públicos, respeitando as finalidades específicas de cada programa e conforme as normas da Lei Geral de Proteção de Dados Pessoais (LGPD), garantindo o consentimento do titular e a segurança e privacidade das informações.
                        </List.Item>
                        <br/>
                        <List.Item className='aboutMainItemList'> <span>5 COMO MANTEMOS OS DADOS SEGUROS</span> <br/><br/>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> armazenará as informações coletadas pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> em servidores próprios ou por ela contratados.
                            <br/><br/>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> utiliza os meios razoáveis de mercado e legalmente requeridos para preservar a privacidade dos dados coletados através do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> . Desta forma, adota as seguintes precauções, em observância às diretrizes sobre padrões de segurança estabelecidas no Decreto nº 8.771/2016, tais como:
                            <br/><br/>
                            <List className='aboutItemSubList'>
                                <List.Item value='a)'>
                                    i. O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> utiliza os métodos padrão e de mercado para criptografar e anonimizar os dados coletados;
                                </List.Item>
                                <List.Item value='b)'>
                                    ii. O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> possui proteção contra acesso não autorizado a seus sistemas;
                                </List.Item>
                                <List.Item value='c)'>
                                    iii. O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> somente autoriza o acesso de pessoas previamente estabelecidas ao local onde são armazenadas as informações coletadas;
                                </List.Item>
                                <List.Item  value='d)'>
                                    iv. Aqueles que entrarem em contato com as informações deverão se comprometer a manter sigilo absoluto. A quebra do sigilo acarretará responsabilidade civil e o responsável será responsabilizado nos moldes da legislação brasileira; e

                                </List.Item>
                                <List.Item value='e)'>
                                    v. Manutenção do inventário indicando momento, duração, identidade do funcionário, ou do responsável pelo acesso e o arquivo objeto, com base nos registros de conexão e de acesso a aplicações, conforme determinado no artigo 13 do Decreto nº 8.771/2016.
                                </List.Item>
                            </List>

                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> adota os melhores esforços, no sentido de preservar a privacidade dos dados dos Usuários e da base de dados recebida mediante compartilhamento do SINE/ME. Entretanto, nenhum site é totalmente seguro e o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> não pode garantir integralmente que todas as informações que trafegam no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> não sejam alvo de acessos não autorizados perpetrados por meio de métodos desenvolvidos para obter informações de forma indevida. Por esse motivo, nós incentivamos os Usuários a tomar as medidas apropriadas para se proteger, como, por exemplo, mantendo confidenciais todos os nomes de usuário e senhas, bem como utilizando softwares seguros de armazenamento de senhas e informações sigilosas.
                        </List.Item>


                        <List.Item className='aboutMainItemList'> <span>6  RETENÇÃO DAS INFORMAÇÕES COLETADAS</span> <br/><br/>
                            As informações coletadas pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> serão automaticamente excluídas de seus servidores quando deixarem de ser úteis para os fins para os quais foram coletadas, ou quando o usuário solicitar a eliminação de seus dados pessoais.
                            <br/><br/>
                            Sem prejuízo, as informações poderão ser conservadas para cumprimento de obrigação legal ou regulatória, transferência a terceiro – desde que respeitados os requisitos de tratamento de dados – e uso exclusivo do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> , vedado seu acesso por terceiro, desde que anonimizadas ou pseudonimizadas.
                        </List.Item>

                        <List.Item className='aboutMainItemList'> <span>7 SEUS DIREITOS </span> <br/><br/>
                            <span> 7.1 Direitos Básicos do Usuário. O Usuário poderá solicitar a confirmação da existência tratamento de Dados Pessoais, além da exibição de seus Dados Pessoais, por meio do nosso Canal de Atendimento.</span> <br/> <br/>
                            Enquanto o Usuário mantiver conta na plataforma, a correção dos seus Dados Pessoais deverá ser realizada diretamente na respectiva área logada.
                            <br/> <br/>
                            Enquanto o Usuário mantiver conta ativa na plataforma, somente os pedidos de confirmação de existência de dados pessoais relacionados a outras espécies de relacionamento entre o Usuário e o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>, a exemplo de compartilhamento de dados com empresas ofertantes de vagas e demais vantagens, serão direcionados ao Encarregado de Dados Pessoais (Data Privacy Officer, ou “DPO”).
                            <br/><br/>
                            <span> 7.2. Limitação, oposição e exclusão de dados. Pelos Canais de Atendimento, o Usuário poderá também: </span> <br/><br/>

                            <List className='aboutMainSubItemList'>
                                <List.Item value='a)'>
                                    a) Manifestar sua oposição e/ou revogar o consentimento quanto ao uso de seus Dados Pessoais; ou
                                </List.Item>
                                <List.Item  value='b)'>
                                    b) Solicitar a exclusão de seus Dados Pessoais que tenham sidos coletados pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>.
                                </List.Item>
                            </List>
                            <br/><br/>
                            Se o Usuário retirar seu consentimento para finalidades fundamentais ao funcionamento adequado do site e dos serviços, os serviços tornar-se-ão indisponíveis.
                            <br/><br/>
                            Caso o Usuário solicite a exclusão de seus Dados Pessoais, situação em que imediatamente perderá acesso ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>, pode ocorrer que os Dados precisem ser mantidos por período superior ao pedido de exclusão, nos termos do artigo 16 da Lei Geral de Proteção de Dados Pessoais, para (i) cumprimento de obrigação legal ou regulatória, (ii) estudo por órgão de pesquisa, e (iii) transferência a terceiro (respeitados os requisitos de tratamento de dados dispostos na mesma Lei). Em todos os casos mediante a anonimização dos Dados Pessoais, desde que possível.
                            <br/><br/>
                            Findos o prazo de manutenção e a necessidade legal, os Dados Pessoais serão excluídos com uso de métodos de descarte seguro, ou utilizados de forma anonimizada para fins estatísticos.
                            <br/><br/>
                            Parte destes direitos poderá ser exercida diretamente pelo Usuário, a partir da gestão de informações sobre a conta, na página seus dados. As demais alterações dependerão do envio de solicitação para posterior avaliação e adoção de demais providências pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong>
                            <br/><br/>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> empreenderá todos os esforços para atender tais pedidos no menor espaço de tempo possível. No entanto, mesmo em caso de requisição de exclusão, será respeitado o prazo de armazenamento mínimo de informações de usuários de aplicações de Internet, determinado pela legislação brasileira.
                            <br/><br/>
                            Em caso de requisição de cancelamento de conta a pedido do Usuário, a qual esteja pendente de justificativa exigida para a visualização do dado da pessoa física solicitada, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> GERAÇÃO EMPREGO  </strong> poderá concluí-la somente após a providência adotada pelo Usuário.

                            <br/>
                            Dados de contato do Encarregado de Dados:

                            <strong> Reinaldo de Andrade Silva </strong>
                            <br/>
                            Endereço físico de contato:
                            <strong> reinaldo@bluetrix.com.br </strong>
                        </List.Item>

                        <List.Item className='aboutMainItemList'> <span>8 LEGISLAÇÃO E FORO</span> <br/><br/>
                            Esta Política será regida, interpretada e executada de acordo com as Leis da República Federativa do Brasil, especialmente a Lei nº 13.709/2018, independentemente das Leis de outros estados ou Países, sendo competente o foro de domicílio do Usuário para dirimir qualquer dúvida decorrente deste documento.
                            <br/>
                            <strong> Versão: 30 de Agosto de 2021. </strong>
                        </List.Item>
                    </List>
                </div>
            </div>

        </div>
    );
};

export default PrivacyPage;
