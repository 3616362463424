import React from 'react';

const useLocalStorage = (key) => {

    const setPrimitive = (value: string|boolean|number) => {
        window.localStorage.setItem(key, value);
    };

    const getPrimitive = () => {
        return window.localStorage.getItem(key);
    };

    const setObject = (value: Object) => {
        window.localStorage.setItem(key, JSON.stringify(value));
    };

    const getObject = () => {
        try {
            return JSON.parse(window.localStorage?.getItem(key));
        } catch (e) {
            remove();
            return null;
        }
    };

    const remove = () => {
        window.localStorage.removeItem(key);
    }

    return {setPrimitive, getPrimitive, getObject, setObject, remove}
};

useLocalStorage.propTypes = {};

export default useLocalStorage;
