import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {useSelector} from "react-redux";
import useApi from "../Hooks/Api";
import moment from "moment";
import Spin from "antd/lib/spin";

const genders = {male: 'Masculino', female: 'Feminino', transgender_male: 'Homem transgênero', transgender_female: 'Mulher transgênero', transsex_male: 'Homem Transexual', transsex_female: 'Mulher Transexual', unknown: 'Não soube responder', not_anwswered: 'Preferiu não responder', other: 'Outros'}
const WorkerResume = React.lazy(() => import('../Components/PageComponents/Restrict/WorkerPanelPage/WorkerResume'));

const DescriptionItem = ({ title, content }) => (
    <div className="profile-description-item">
        <p style={{marginBottom: 3, fontWeight: 'bold'}}>
            {title}:
        </p>
        {content}
    </div>
);

const WorkerResumeViewPage = (props) => {

    const [profile, setProfile] = useState(null);
    const user = useSelector((state) => state).authReducer;
    const api = useApi();
    const [isFirstJob, setIsFirstJob] = useState(false);

    const getFullData = async () => {
        api.get(`sine/user/${user.user_id}/full`, (res) => {
            for(let i = 0; i < res.work_experience?.length; i ++) {
                if(res.work_experience[i].start_date !== res.work_experience[i].end_date) {
                    break;
                }
                if(i + 1 === res.work_experience.length) {
                    setIsFirstJob(true);
                }
            }

            setProfile(res);
        }, (e) => {
            console.log(e);
        });
    };

    const convertDate = (end, start) => {
        let years;
        let months;
        if(end) {
            years = moment(end,'YYYY-MM').diff(start, 'years');
            months = moment(end,'YYYY-MM').diff(start, 'months');
        } else {
            years = moment().diff(start, 'years');
            months = moment().diff(start, 'months');
        }

        if(years < 1) {
            return <span> {months % 12 + ' meses'} </span>
        } else if (months % 12 > 0){
            return <span> {years + ' ano(s) e ' + months % 12 + ' mês(es)'} </span>
        } else {
            return <span> {years + ' ano(s)'} </span>
        }

    };

    useEffect(() => {
        getFullData();
    }, []);


    return (
        <Row style={{display: 'flex', justifyContent: 'center'}}>
            {!profile && <div style={{minHeight: 800}}> <Spin style={{marginTop: 50}}/> </div> }

            {profile &&
                <div style={{display:"flex", flexDirection:"column"}}>
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <WorkerResume data={profile} genders={genders} convertDate={convertDate} DescriptionItem={DescriptionItem} isFirstJob={isFirstJob}/>
                    </React.Suspense>
                </div>
            }
        </Row>
    )
}

export default WorkerResumeViewPage;
