
import React from "react"
import {Col, Collapse, Row} from "antd";
import {Env} from "../../Helpers/Env";
import {FaChevronDown, FaChevronUp} from "react-icons/all";
import {Texts} from "../../Helpers/Texts";
import DividerCustom from "./DividerCustom";


export default function ComponentsFaq(props) {
    const SYSTEM_NAME = Env.application_title;
    const SUPPORT_NUMBER = Env.number;

    const faq = [
        {
            title: `O que é o ${SYSTEM_NAME}?`,
            description: `O ${SYSTEM_NAME} é uma plataforma do Governo de Rondônia que facilita a conexão entre trabalhadores e empregadores, ajudando na busca por oportunidades de trabalho no estado.`
        },
        {
            title: `Como faço para me cadastrar no ${SYSTEM_NAME}?`,
            description: `Para se cadastrar, acesse o site, clique em "Cadastrar-se" e preencha o formulário com seus dados pessoais e profissionais.`
        },
        {
            title: `Preciso pagar para usar a plataforma?`,
            description: `Não, o uso da plataforma é gratuito tanto para trabalhadores quanto para empregadores.`
        },
        {
            title: `Como posso buscar vagas de emprego?`,
            description: `Após se cadastrar, você pode acessar a área de busca de vagas, onde encontrará oportunidades disponíveis de acordo com seu perfil.`
        },
        {
            title: `Quais são os requisitos para se cadastrar como empregador?`,
            description: `Os empregadores devem fornecer informações detalhadas sobre a empresa e as vagas disponíveis. A plataforma verificará a autenticidade dos dados.`
        },
        {
            title: `Posso acessar o ${SYSTEM_NAME} pelo celular?`,
            description: `Sim, a plataforma é compatível com dispositivos móveis, e há um aplicativo disponível para facilitar o acesso.`
        },
        {
            title: `Como faço para editar meu currículo na plataforma?`,
            description: `Após o login, acesse a seção "Perfil" e faça as alterações necessárias no seu currículo.`
        },
        {
            title: `Como funciona o processo seletivo para as vagas?`,
            description: `Após se candidatar a uma vaga, o empregador analisará seu currículo e entrará em contato caso você seja selecionado para as próximas etapas.`
        },
        {
            title: `O que devo fazer se esquecer minha senha?`,
            description: `Você pode redefinir sua senha clicando na opção "Esqueci minha senha" na página de login e seguindo as instruções.`
        },
        {
            title: `Como posso me inscrever em cursos de qualificação?`,
            description: `Acesse a seção de cursos no site, escolha o curso desejado e siga o processo de inscrição.`
        },
        {
            title: `Os cursos oferecidos são gratuitos?`,
            description: `Sim, todos os cursos de qualificação profissional oferecidos pela plataforma são gratuitos.`
        },
        {
            title: `Quem pode participar dos cursos de qualificação?`,
            description: `Os cursos são destinados a todos os usuários do ${SYSTEM_NAME} que desejam melhorar suas habilidades e aumentar suas chances de emprego.`
        },
        {
            title: `Como os empregadores são verificados?`,
            description: `Todos os empregadores são submetidos a um processo de verificação para garantir que as vagas ofertadas sejam legítimas.`
        },
        {
            title: `Como sei se fui selecionado para uma vaga?`,
            description: `Caso você seja selecionado, o empregador entrará em contato diretamente através dos dados fornecidos no seu cadastro.`
        },
        {
            title: `A plataforma oferece suporte técnico?`,
            description: `Sim, você pode entrar em contato com o suporte da plataforma em caso de dúvidas ou problemas técnicos através do whatsapp ${window.maskViewPhone(SUPPORT_NUMBER)}`
        },
        {
            title: `O que fazer se eu encontrar uma vaga falsa ou fraudulenta?`,
            description: `Entre em contato com o suporte da plataforma para denunciar a vaga.`
        },
        {
            title: `Posso me candidatar a mais de uma vaga ao mesmo tempo?`,
            description: `Sim, você pode se candidatar a quantas vagas desejar.`
        },
        {
            title: `Como funciona a filtragem de vagas na busca?`,
            description: `Você pode usar filtros como área de atuação, localização e requisitos para encontrar vagas que melhor se adequem ao seu perfil.`
        },
        {
            title: `Posso me inscrever em mais de um curso ao mesmo tempo?`,
            description: `Dependendo da disponibilidade e dos critérios dos cursos, pode ser possível se inscrever em mais de um.`
        },
        {
            title: `Há um limite de tempo para manter meu cadastro ativo?`,
            description: `Seu cadastro permanece ativo enquanto você continuar utilizando a plataforma.`
        },
        {
            title: `O que acontece se eu não atualizar meu currículo por muito tempo?`,
            description: `É recomendável atualizar seu currículo regularmente para garantir que suas informações estejam corretas e atuais.`
        },
        {
            title: `Como posso aumentar minhas chances de ser selecionado para uma vaga?`,
            description: `Certifique-se de que seu currículo esteja completo, atualizado e que você esteja se candidatando a vagas que se encaixam em seu perfil.`
        }
    ];




    return (
        <div className={"ant-container-faq"} style={{paddingTop:50}}>

            <div style={{textAlign: 'center', marginBottom:24}} >
                <h1 style={{fontSize: "25px", lineHeight: 1, color: "#333"}}>  Perguntas Frequentes  </h1>
            </div>
            <div style={{marginBottom: 24}}>
                <DividerCustom/>
            </div>
            <div className={"d-flex flex-wrap"}>
                {faq.map((item, key) => (
                    <div key={key} className={"question"}>
                        <Collapse style={{background: "#f0f0f0", textAlign: "start", border: "none", minHeight:70, display: "flex", alignItems:"center"}}
                                  expandIconPosition={'right'} expandIcon={({isActive}) => (
                            isActive ? <FaChevronUp size={16}/> : <FaChevronDown size={16}/>
                        )}>

                            <Collapse.Panel header={item.title} key={key}>
                                <p style={{marginBottom:0, fontSize:"1rem"}}>{item.description}</p>
                            </Collapse.Panel>
                        </Collapse>
                    </div>
                ))}
            </div>
        </div>
    )
}