import React, {useEffect, useState} from 'react'
import {BsUpload} from "react-icons/all";
import {CgClose} from "react-icons/cg";

const SyImage = ({data, setValue, value}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null)

    const handleClick = (e) => {
        e.preventDefault()
        document.getElementById('fileInput').click()
    }

    const fileChangedHandler = event => {
        setSelectedFile(event.target.files[0]);
    };

    const clearData = () => {
        setImagePreview(null);
        setSelectedFile(null);
        setValue(undefined);
        document.getElementById('fileInput').value = ''

    }

    const toBase64 = () => {
        if (!selectedFile) {
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = () => {
                setImagePreview(reader.result)
                const base64 = reader.result.split(',')[1];
                setValue(base64)
            };
        }
    }

    useEffect(() => {
        if (value !== undefined) {
            let img = `data:image/*;base64,${value}`
            setImagePreview(img)
        }
        toBase64()
    }, [selectedFile]);

    return (
        <div>
            <div>
                <input id={"fileInput"} type={"file"} accept={'image/*'} onChange={fileChangedHandler} style={{display:"none"}}/>
                <button className='btn-selected-image' type={"button"} onClick={(e) => handleClick(e)}>
                    <BsUpload size={18}/> Selecionar Imagem
                </button>
            </div>

            {imagePreview &&
                <div className='image-preview'>
                    <div className='overflow-text'>
                        <img src={imagePreview} width={50} alt={`${selectedFile?.name}`}/>
                        <span>{selectedFile?.name || 'Imagem selecionada'}</span>
                    </div>
                    <div style={{cursor:"pointer"}} onClick={clearData}>
                        <CgClose size={20} color={'#f5222d'}/>
                    </div>
                </div>
            }
        </div>
    );
};

export default SyImage
