import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Badge, Button, Col, Divider, Dropdown, Icon, Layout, Menu, notification, Row} from "antd";
import {useHistory} from "react-router-dom";

import useAuth from "../../../Hooks/Auth";
import Link from "react-router-dom/es/Link";
import useApi from "../../../Hooks/Api";
import {useDispatch, useSelector} from "react-redux";
import useVerifyServices from "../../../Hooks/VerifyServices";
import {Images} from "../../../Helpers/Images";
import {FaHome} from "react-icons/fa";
import {MdDashboard} from "react-icons/md";
import useLocalStorage from "../../../Hooks/Storage";
import {StorageVariables} from "../../../Helpers/StoragesVariables";
import {Env} from "../../../Helpers/Env";

const Header = props => {
    const history = useHistory();
    const externAccessUser = useLocalStorage(StorageVariables.externUserAccess)
    const dispatch = useDispatch();
    const [menuSelected, setMenuSelected] = useState('2');


    const handleHelp = () => {
        const key = 'notificationLocalHelpKey';
        notification.open({
            duration: 8,
            description: <div>
                <div style={{marginBottom: 20, textAlign: 'center'}}>
                    <img src={Images.logo} style={{height: 50}}/>
                </div>
                <div style={{marginBottom: 20}}>Caso encontre alguma dúvida, entre em contato com {Env.number}, ou pelo email {Env.support_email} </div>
            </div>,
            key,
            placement: 'topRight'
        });
    }

    const logout = () => {
        externAccessUser.remove();
        delete Env.header_club[`userToken`];
        history.push('/acesso-externo/acesso-da-empresa');
    }

    return (
        <Layout.Header className='headerMain' style={{ background: '#fff', padding: 0 }}>
            <Row className='headerPage' style={{display: 'flex', justifyContent: 'center'}}>
                <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17} className='right-side-col'>
                    <div>
                        <div className='logo' style={{padding: 1, paddingLeft: 5, paddingRight: 10}} onClick={() => history.push('/')}>
                            <img src={Images.logo_header} />
                        </div>
                        {externAccessUser.getObject()&&<div className='navigation hideMobile998'>
                            <Menu
                                selectedKeys={[menuSelected]}
                                mode="horizontal"
                                style={{ lineHeight: '64px', display: 'inline-block' }}
                            >
                                <Menu.Item key="2" onClick={() =>{ history.push('/acesso-externo/vagas-da-minha-empresa'); setMenuSelected('2')}} > <MdDashboard className={'exportedIconsLeft'} /> Painel </Menu.Item>
                            </Menu>
                        </div>}

                            <div className='right-side'>
                                <div className='header-widget hideMobile500'>

                                    <div onClick={() => handleHelp()} className='header-notifications' style={{cursor: "pointer"}}>
                                        <Badge style={{fontSize: 10, height: 18}}>
                                            <Icon type='question-circle'/>
                                        </Badge>
                                    </div>

                                </div>
                                {externAccessUser.getObject()&&<div className='header-user-widget hideMobile500'>
                                    <Dropdown  overlay={
                                        <div style={{display: 'flex',flexFlow: 'column', width: 250, borderRadius: 5, backgroundColor: 'white', padding: 20, boxShadow: '0 2px 12px 0 rgba(0,0,0,.12)'}}>
                                            <div style={{display: 'flex'}}>
                                                <div style={{display: 'flex', flexFlow: 'column', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    <span style={{fontSize: 16, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{externAccessUser?.getObject()?.login} </span>
                                                    <span style={{fontSize: 14, marginLeft: 2, color: '#888', overflow: 'hidden', textOverflow: 'ellipsis'}}>{externAccessUser?.getObject()?.person?.name} </span>
                                                </div>
                                            </div>
                                            <Divider style={{marginBottom: 10, marginTop: 20}}/>
                                            <Menu style={{borderRight: 0, width: 'fit-content', alignSelf: 'center'}} >
                                                <Menu.Item key="5" onClick={() => {logout(); dispatch({type: 'logout'});}} style={{padding: 0, textAlign: 'center', margin: 0, marginTop: 50, background: 'white'}}>
                                                    <Button className={'ant-btn-primary2'}>
                                                        <Icon style={{marginRight: 5}} type={'poweroff'}/> <span> Sair </span>
                                                    </Button>
                                                </Menu.Item>
                                            </Menu>
                                        </div>
                                    }>
                                        <div style={{display: 'flex', cursor: 'pointer', overflow: 'hidden', alignItems: 'center'}}>
                                            <span style={{fontSize: 16, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>  {externAccessUser?.getObject()?.login} </span>
                                            <Icon style={{marginLeft: 5}} type={'caret-down'}/>
                                        </div>
                                    </Dropdown>
                                </div>}
                            </div>

                    </div>
                </Col>
            </Row>
        </Layout.Header>
    );
};

Header.propTypes = {
    sidebarCollapsed: PropTypes.bool,
    setSidebarCollapsed: PropTypes.func,
    paddingLogo: PropTypes.any
};

export default Header;
