import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Form, Icon, Input, Popover, Row} from "antd";
import SideBarFilter from "./SideBarFilter";
import {Fade} from "react-reveal";
import {useState} from "react"
import useApi from "../../../../Hooks/Api";
import useLocalStorage from "../../../../Hooks/Storage";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";
import {useSelector} from "react-redux";
import moment from "moment";

const ListHeader = (props) => {
    const {getFieldDecorator, getFieldError} = props.form;
    let activeFilters = window.queryStringToObject();
    const [searchText, setSearchText] = useState('');

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        props.search(searchText)
    };

    useEffect(() => {
        if (searchText === "") {
            props.search(searchText)
        }
    }, [searchText])

    return (
        <Row className='listHeaderFather'>

            <div className={'listHeaderSearchRecruits'} style={{paddingBottom: 7, overflow: 'hidden'}}>
                <label style={{fontSize: 16, fontWeight: 'bold'}}> Busca </label>

                <Form.Item style={{marginBottom: 10, marginTop: 10}} validateStatus={getFieldError('locFilled')&& 'error'} help={getFieldError('locFilled')}>
                    <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                        <Input placeholder="Busque por um trabalhador, experiência etc..." onKeyDown={handleKeyPress}  id="campText" onChange={(e) => setSearchText(e.target.value)} />
                        <Button type="primary" shape="circle" icon="search" style={{marginLeft: 10}} onClick={() => handleSearch()} />
                    </div>
                </Form.Item>

                <Form.Item  required={true} help={''} style={{margin: 0}}>
                    {getFieldDecorator('locFilled', {
                        rules: [
                            {
                                required: true,
                                message:  'Escolha um Local',
                            },
                        ]
                    })( <Input style={{display: "none"}}/>)}
                </Form.Item>

                {window.innerWidth < 768 &&
                <div style={{paddingBottom: 15, marginTop: 20}}>
                    <SideBarFilter
                        cboName={props.cboName}
                        addLocToFilter={() => props.addLocToFilter()}
                        location={props.location}
                        clearFilters={() => props.clearFilters()} search={() => props.search()} loading={props.loading} filter={(name,value) => props.filter(name,value)}/>
                </div>
                }

                <div style={{display: 'flex', justifyContent: "space-between", flexWrap: 'wrap'}}>
                    <Fade bottom when={props.count !== undefined}>
                        <div>
                        <span>
                            <strong style={{fontSize: 15}}> {props.count} </strong> resultados encontrados.
                        </span>
                            <Popover content={'Quantidade de currículos que ainda não foram selecionados com estes filtros selecionados'}>
                                <Icon type={'question-circle'}/>
                            </Popover>
                        </div>
                    </Fade>

                    <div style={{display: 'flex', flexWrap: 'wrap', gap:10, alignItems: 'center'}}>
                        <div>
                            <Checkbox checked={activeFilters.exclude_imported} onChange={(e) => {props.filter("exclude_imported", e.target.checked ? true : null); props.search()}} />
                            <span>
                                &nbsp; Incluir currículos do SINE &nbsp;
                                <Popover content={'Selecione para retornar também os currículos da base nacional de emprego(Nestes currículos as informações são bem mais simplificadas)'}>
                                    <Icon type={'question-circle'}/>
                                </Popover>
                            </span>
                        </div>

                        <div style={{display: 'flex',}}>
                            <Button onClick={() => props.setToggle()} className='ant-btn-blue-ghost map-list-button'>
                                <span style={{display:"flex", alignItems:'center', gap:5}}>
                                     {props.toggle ?
                                        <>
                                            <Icon type={'unordered-list'}/>Ver lista
                                        </> :
                                         <>
                                             <Icon type={'environment'}/>Ver mapa
                                         </>
                                     }
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        </Row>
    );
};

ListHeader.propTypes = {
    categoriesTreeList: PropTypes.any,
    searchAction: PropTypes.any,
    toggle: PropTypes.any,
};

export default ListHeader;
