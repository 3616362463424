import React, {useEffect, useState} from 'react';

import {Images} from "../../../../Helpers/Images";
import {useHistory} from "react-router-dom";
import {Button} from "antd";
import {Env} from "../../../../Helpers/Env";

const EndRegisterStep = (props) => {

    const history = useHistory()

    return (<div style={{minHeight: 350, backgroundColor: 'white'}}>
        {props.lastProfileAux === 'worker'? <div>
                <div style={{textAlign: 'center', fontSize: 20}}>
                    <strong style={{color: '#0f75bc'}}> Agora é só cadastrar o seu currículo! </strong>
                </div>
                <div style={{textAlign: 'center'}}>
                    <img style={{height: '100%', maxHeight: 320}} src={Images.workerPanel.edit_resume}/>
                </div>
                <div style={{padding: 20, display: "flex", justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    <Button onClick={() =>{history.push('/painel'); props.setVisible(false);}} style={{borderColor: 'lightgray'}}> Depois </Button>
                    <Button onClick={() => {history.push('/painel/prestador/editar-meu-curriculo'); props.setVisible(false)}} className={'ant-btn-secondary'}> Cadastrar agora meu currículo </Button>
                </div>
            </div>
            :
            <div>
                <div style={{textAlign: 'center', fontSize: 20}}>
                    <strong style={{color: '#424242'}}> Tudo pronto </strong> <br/>
                    <span style={{color: '#616161', fontSize: 16}}>  Agora você está pronto para buscar por prestadores de serviço, por currículos, ou divulgar vagas para seu negócio em todo o estado de {Env.state_restriction}! </span>
                </div>
                <div style={{textAlign: 'center'}}>
                    <img style={{height: '100%', maxHeight: 320}} src={Images.search_resumes}/>
                </div>
                <div style={{padding: 10, display: "flex", justifyContent: 'space-between', flexDirection: "column"}}>
                    <Button onClick={() => {
                        window.gtagService('event', 'posRegWantToPublish');
                        history.push('/painel/cliente/vagas');
                        props.setVisible(false)}} style={{marginBottom: 10}} className={'ant-btn-primary'}> Divulgar Vaga </Button>
                    <Button onClick={() => {
                        window.gtagService('event', 'posRegSearchCurriculum');
                        if(!window.location.pathname.includes('/curriculos')) {
                            history.push('/curriculos');
                        }
                        props.setVisible(false)}} style={{marginBottom: 10}} className={'ant-btn-sub-primary'}> Buscar Currículos </Button>
                 {/*   <Button onClick={() => {
                        window.gtagService('event', 'posRegSearchWorker');
                        if(!window.location.pathname.includes('/quero-contratar')) {
                            history.push('/quero-contratar');
                        }
                        props.setVisible(false)}} className={'ant-btn-purple'}> Buscar Prestadores de Serviço </Button>*/}

                    <span onClick={() => {
                        window.gtagService('event', 'posRegSubmitCurriculum');
                        history.push('/painel/prestador/editar-meu-curriculo');
                        props.setVisible(false)}} style={{cursor: 'pointer', marginTop: 30, color: '#757575', fontWeight: 'bold', textAlign: 'center'}}>
                                    Quero divulgar meu currículo
                                </span>
                </div>
            </div>
        }

    </div>)
};
export default EndRegisterStep;
